<template>
  <v-container
    class="px-0 px-sm-3 pt-0"
    v-if="!$store.state.stylistsAccount.loading"
  >
    <router-view
  /></v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    methods: {
      ...mapActions(['fetchStylistAccount'])
    },
    async created() {
      // if stylist logged in, fetch stylist account
      if (this.$store.getters.stylistLoggedIn) {
        await this.fetchStylistAccount(this.$store.state.auth.user.stylistId)
      }
    }
  }
</script>
