<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      v-if="this.$store.getters.adminLoggedIn"
    >
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase">Hairdreams app</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" temporary left>
      <v-list nav dense height="100%">
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <!-- example pages -->
          <v-list-item :to="{ name: 'ExamplePages' }">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <!-- salon finder form -->
          <v-list-item exact :to="{ name: 'userType' }">
            <v-list-item-icon>
              <v-icon>mdi-checkbox-marked-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Salon Finder Form</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-card-text class="overline">consumer</v-card-text>

          <!-- consumers -->
          <v-list-item
            v-if="this.$store.getters.adminLoggedIn"
            :to="{ name: 'Consumers' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Consumer Referrals</v-list-item-title>
          </v-list-item>

          <!-- remove consumer -->
          <v-list-item
            v-if="this.$store.getters.adminLoggedIn"
            :to="{ name: 'RemoveConsumer' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Remove Consumer</v-list-item-title>
          </v-list-item>

          <!-- messages & calls -->
          <v-list-item
            :to="{ name: 'MessagesCalls' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon small>mdi-message</v-icon>
              <v-icon small>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Messages & Calls</v-list-item-title>
          </v-list-item>

          <!-- consumer messages by salon -->
          <v-list-item
            :to="{ name: 'ConsumerMessages' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon small>mdi-message</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Consumer Messages</v-list-item-title>
          </v-list-item>

          <!-- messages  -->
          <v-list-item
            :to="{ name: 'Messages' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-magnify</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Search Messages</v-list-item-title>
          </v-list-item>

          <!-- consultations -->
          <v-list-item
            :to="{ name: 'Consultations' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-account-switch</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Consultations</v-list-item-title>
          </v-list-item>

          <!-- logs -->
          <v-list-item
            :to="{ name: 'Logs' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-database</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logs</v-list-item-title>
          </v-list-item>

          <!-- analytics -->
          <v-list-item
            :to="{ name: 'Analytics' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Analytics</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-card-text class="overline">Referral Salons</v-card-text>

          <!-- pending salons -->
          <v-list-item
            :to="{ name: 'PendingSalons' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-timetable</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pending Salons</v-list-item-title>
          </v-list-item>

          <!-- salons -->
          <v-list-item
            :to="{ name: 'Salons' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-office-building-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Referral Salons</v-list-item-title>
          </v-list-item>

          <!-- edit salon home -->
          <v-list-item
            :to="{ name: 'EditSalonHome' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-home-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Edit Salon Home</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-card-text class="overline">Hairdreams</v-card-text>

          <!-- hairdreams -->
          <!-- stylists other inq -->
          <v-list-item
            :to="{ name: 'StylistsOtherInq' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-face</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Other Stylist Inq</v-list-item-title>
          </v-list-item>

          <!-- consumers other inq -->
          <v-list-item
            :to="{ name: 'ConsumersOtherInq' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-help</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Other Consumer Inq</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-card-text class="overline">Stylists</v-card-text>

          <!-- stylists -->
          <v-list-item
            :to="{ name: 'finished-applications' }"
            v-if="
              this.$store.getters.adminLoggedIn &&
              this.$store.getters.isUserFull
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-face</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Finished Applications</v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="{ name: 'pending-applications' }"
            v-if="
              this.$store.getters.adminLoggedIn &&
              this.$store.getters.isUserFull
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-face-profile</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pending Applications</v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="{ name: 'stylist-certifications' }"
            v-if="
              this.$store.getters.adminLoggedIn &&
              this.$store.getters.isUserFull
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-certificate</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stylists Certifications</v-list-item-title>
          </v-list-item>

          <v-list-item
            :to="{ name: 'stylist-certifications-canada' }"
            v-if="
              this.$store.getters.adminLoggedIn &&
              this.$store.getters.isUserFull
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-certificate</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Stylists Cert. Canada</v-list-item-title>
          </v-list-item>

          <!-- source salons -->
          <v-list-item
            :to="{ name: 'DistanceChecker' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-map-marker-distance</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Distance Checker</v-list-item-title>
          </v-list-item>

          <!-- stylists -->
          <v-list-item
            exact
            :to="{ name: 'Stylists' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-history</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Pre-12/2023 Stylist Inq</v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-card-text class="overline">Promotions</v-card-text>

          <!-- note: email promotions -->
          <v-list-item
            :to="{ name: 'ConsumersEmailPromotion' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-mail</v-icon>
            </v-list-item-icon>
            <v-list-item-title>E-mail Promotion</v-list-item-title>
          </v-list-item>

          <!-- note: sms promotions -->
          <v-list-item
            :to="{ name: 'ConsumersSmsPromotion' }"
            v-if="this.$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-message</v-icon>
            </v-list-item-icon>
            <v-list-item-title>SMS Promotion</v-list-item-title>
          </v-list-item>

          <!-- logout -->
          <v-divider></v-divider>
          <v-list-item
            @click="handleLogout"
            v-if="$store.getters.adminLoggedIn"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main class="mt-3">
      <v-snackbar
        v-if="errorMessage"
        centered
        top
        v-model="snackbar"
        color="red lighten-2"
        timeout="4000"
        >{{ errorMessage }}</v-snackbar
      >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        snackbar: false,
        drawer: false,
        group: null
      }
    },
    methods: {
      ...mapActions(['logout']),
      handleLogout() {
        this.logout()
      }
    },
    computed: {
      errorMessage() {
        if (this.$store.state.salons.error)
          return this.$store.state.salons.error
        else if (this.$store.state.messages.error)
          return this.$store.state.messages.error
        else if (this.$store.state.stylists.error)
          return this.$store.state.stylists.error
        else if (this.$store.state.consumers.error)
          return this.$store.state.consumers.error
        else return ''
      }
    },
    watch: {
      errorMessage(newValue, oldValue) {
        this.snackbar = true
      }
    },
    created() {
      document.title = 'Hairdreams'
    }
  }
</script>
