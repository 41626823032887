import api from '@/services/api.service'

// note: state
export const state = {
  list: [],
  loading: false,
  error: '',
  selectedSalon: null,
  imgUrl: '',
  imgUrlTemp: '',
  nearestSalon: null,
  totalCount: 0
}

// note: mutations
export const mutations = {
  // USER
  foundSalon: (state, salon) => (state.selectedSalon = salon),
  // ADMIN
  salonsFetched: (state, salons) => (state.list = salons),
  totalCountFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  salonFetched: (state, salon) => (state.selectedSalon = salon),
  nearestSalonFetched: (state, nearestSalon) =>
    (state.nearestSalon = nearestSalon),
  salonAdded: (state, salon) => state.list.unshift(salon),
  imgAdded: (state, url) => (state.imgUrl = url),
  imgTempAdded: (state, url) => (state.imgUrlTemp = url),
  salonEdited: (state, editedSalon) => {
    state.list = state.list.map(salon => {
      return salon._id === editedSalon._id ? { ...editedSalon } : { ...salon }
    })
  },
  salonRemoved: (state, id) =>
    (state.list = state.list.filter(s => s._id !== id)),
  loadingSalonsSet: state => (state.loading = true),
  loadingSalonsCleared: state => (state.loading = false),
  errorSalonsSet: (state, error) => (state.error = error),
  errorSalonsCleared: state => (state.error = '')
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorSalonsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingSalonsCleared')
  setTimeout(() => {
    commit('errorSalonsCleared')
  }, 10000)
}

// note: actions
export const actions = {
  getAgreement: async ({ commit }, id) => {
    try {
      const { data } = await api.getAgreement(id)
      return data
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  getSalonsCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postSalonsCsv(payload)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // USER
  sendCode: async ({ commit }, email) => {
    commit('loadingSalonsSet')
    try {
      const response = await api.sendCode(email)
      if (response.data.active === false) {
        commit('loadingSalonsCleared')
        return { salonActive: false }
      }
      commit('loadingSalonsCleared')
      return { salonActive: true }
    } catch (error) {
      // console.log(error)
      errorHandling(commit, error)
      commit('loadingSalonsCleared')
    }
  },
  validateCode: async ({ commit }, code) => {
    // commit('loadingSalonsSet')
    try {
      const response = await api.validateCode(code)
      commit('foundSalon', response.data.data)
      // commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchSalon: async ({ commit }, id) => {
    commit('loadingSalonsSet')
    try {
      const response = await api.getSalon(id)
      commit('salonFetched', response.data.data)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // only upload to get link, not connected to salon
  uploadImg: async ({ commit }, payload) => {
    const { form } = payload
    // commit('loadingSalonsSet')
    try {
      const res = await api.uploadImg(form)
      commit('imgTempAdded', res.data.data)
      // commit('loadingSalonsCleared')
    } catch (error) {
      commit(
        'errorSalonsSet',
        error?.response?.data?.message || 'Something went wrong...'
      )
      setTimeout(() => {
        commit('errorSalonsCleared')
      }, 10000)
      // commit('loadingSalonsCleared', false)
    }
  },
  addImg: async ({ commit }, payload) => {
    const { id, form } = payload
    // commit('loadingSalonsSet')
    try {
      const res = await api.sendImg(id, form)
      commit('imgAdded', res.data.data.imgUrl)
      // commit('loadingSalonsCleared')
    } catch (error) {
      commit(
        'errorSalonsSet',
        error?.response?.data?.message || 'Something went wrong...'
      )
      setTimeout(() => {
        commit('errorSalonsCleared')
      }, 10000)
      // commit('loadingSalonsCleared', false)
    }
  },
  addImgTemp: async ({ commit }, payload) => {
    const { id, form } = payload
    // commit('loadingSalonsSet')
    try {
      const res = await api.sendImgTemp(id, form)
      commit('imgTempAdded', res.data.data.imgUrl)
      // commit('loadingSalonsCleared')
    } catch (error) {
      commit(
        'errorSalonsSet',
        error?.response?.data?.message || 'Something went wrong...'
      )
      setTimeout(() => {
        commit('errorSalonsCleared')
      }, 10000)
      // commit('loadingSalonsCleared', false)
    }
  },
  editSalonTemp: async ({ commit }, payload) => {
    const { id, data, isAdmin } = payload
    // commit('loadingSalonsSet')
    try {
      await api.patchSalonTemp(id, data, isAdmin)
      // commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // ADMIN
  fetchSalons: async ({ commit }) => {
    commit('loadingSalonsSet')
    try {
      const response = await api.getSalons()
      commit('salonsFetched', response.data.data)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  clearSalons: ({ commit }) => {
    commit('salonsFetched', [])
  },
  fetchSalonsByNavid: async ({ commit }, navid) => {
    commit('loadingSalonsSet')
    try {
      const response = await api.getSalonsByNavid(navid)
      commit('salonsFetched', response.data.data)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchSalonsTableData: async ({ commit }, payload = {}) => {
    commit('loadingSalonsSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc
    const extra_zip = payload.extra_zip
    const extra_system = payload.extra_system

    try {
      const response = await api.getSalonsTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc,
        extra_zip,
        extra_system
      )

      commit('salonsFetched', response.data.data)
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchSalonsByZipAndSystem: async ({ commit }, payload) => {
    commit('loadingSalonsSet')
    try {
      const response = await api.getSalonsByZipAndSystem(
        payload.zip,
        payload.system
      )
      commit('salonsFetched', response.data.data)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // archive
  getNearestSalon: async ({ commit }, payload) => {
    commit('loadingSalonsSet')
    try {
      const response = await api.getNearestSalon(payload.zip, payload.system)
      commit('nearestSalonFetched', response.data.data[0])
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addSalon: async ({ commit }, salon) => {
    commit('loadingSalonsSet')
    try {
      const response = await api.postSalon(salon)
      commit('salonAdded', response.data.data)
      commit('loadingSalonsCleared')
      return response.data.data
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendTermsMail: async ({ commit }, data) => {
    commit('loadingSalonsSet')
    const { id, termsStatus } = data
    try {
      await api.sendTermsMail(id, termsStatus)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendSalonsInviteMail: async ({ commit }) => {
    try {
      await api.sendSalonsInviteMails()
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendSalonsInviteMailTest: async ({ commit }) => {
    try {
      await api.sendSalonsInviteMailsTest()
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendSalonsFile: async ({ commit }, file) => {
    commit('loadingSalonsSet')
    try {
      const response = await api.postSalonFile(file)
      commit('salonsFetched', response.data.data)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  editSalon: async ({ commit }, salon) => {
    commit('loadingSalonsSet')

    try {
      let data
      // flag for email
      if (salon.termsStatus) {
        const response = await api.patchSalon(salon, salon.termsStatus)
        data = response.data
      } else {
        const response = await api.patchSalon(salon)
        data = response.data
      }

      commit('salonEdited', data.data)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  editSalonAdminMessagesMemo: async ({ commit }, payload) => {
    // commit('loadingSalonsSet')

    const {
      salonId,
      consumerId,
      text,
      tooExpensive,
      neverGotInTouchWithSalon,
      salonNeverCalledBack,
      didNotLikeSystemProduct
    } = payload

    try {
      const response = await api.patchSalonAdminMessagesMemo(salonId, {
        text,
        consumerId,
        tooExpensive,
        neverGotInTouchWithSalon,
        salonNeverCalledBack,
        didNotLikeSystemProduct
      })

      commit('salonFetched', response.data.data)
      // commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  editSalonFeedback: async ({ commit }, payload) => {
    const { salonId, feedback } = payload
    try {
      await api.patchSalonFeedback(salonId, feedback)
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  confirmSalonEdit: async ({ commit }, payload) => {
    commit('loadingSalonsSet')
    const { salon, approveAll } = payload
    try {
      const { data } = await api.confirmSalonEdit(salon, approveAll)
      commit('salonEdited', data.data)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  removeSalon: async ({ commit }, id) => {
    commit('loadingSalonsSet')

    try {
      await api.deleteSalon(id)
      commit('salonRemoved', id)
      commit('loadingSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendVerifySms: async ({ commit }, id) => {
    try {
      await api.sendVerifySms(id)
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
