<template>
  <v-container class="px-0 px-sm-3 pt-0" v-if="!isLoading">
    <v-card class="elevation-12 mx-auto pa-0 pb-4" max-width="550">
      <!-- note: navigation -->
      <v-card-actions :class="actionsClass"
        ><v-btn
          text
          @click="handleBack"
          v-if="hasBack"
          :disabled="!hasBackScreens"
          ><v-icon x-small>mdi-arrow-left</v-icon> Back</v-btn
        ><v-spacer></v-spacer
        ><v-btn
          :text="isValid ? false : true"
          :color="isValid ? '#1976d2' : 'grey'"
          :dark="isValid ? true : false"
          :small="isValid ? true : false"
          elevation="0"
          :class="isValid ? 'font-weight-black' : ''"
          @click="handleNext"
          v-if="hasNext"
          :disabled="!isValid"
          >Next <v-icon x-small>mdi-arrow-right</v-icon></v-btn
        ></v-card-actions
      >
      <!-- note: main container -->
      <v-card-text class="pt-7">
        <v-slide-x-transition v-if="isBack" hide-on-leave>
          <router-view @next="handleNext"
        /></v-slide-x-transition>
        <v-slide-x-reverse-transition v-else hide-on-leave>
          <router-view
        /></v-slide-x-reverse-transition>
      </v-card-text> </v-card
  ></v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getCookie, setCookie } from '../../../../utils/helpers'

  export default {
    data() {
      return {
        isBack: true,
        isLoading: true
      }
    },
    computed: {
      stylistLoggedIn() {
        return this.$store.getters.stylistLoggedIn
      },
      route() {
        return this.$route.name
      },
      applicationFinished() {
        return !!this.$store.state.applications?.application?.finished
      },
      isValid() {
        return this.$store.state.applications.isValid
      },
      nextScreen() {
        return this.$store.state.applications.nextScreen
      },
      hasNext() {
        return !!this.$store.state.applications.nextScreen
      },
      hasBack() {
        return this.$store.state.applications.screenHistory.length > 0
      },
      application() {
        return this.$store.state.applications.application
      },
      // query params
      utm_campaign() {
        return (
          this.$route.query.utm_campaign ||
          getCookie('utm_campaign') ||
          getCookie('[utm_campaign]')
        )
      },
      utm_source() {
        return (
          this.$route.query.utm_source ||
          getCookie('utm_source') ||
          getCookie('[utm_source]')
        )
      },
      utm_medium() {
        return (
          this.$route.query.utm_medium ||
          getCookie('utm_medium') ||
          getCookie('[utm_medium]')
        )
      },
      utm_content() {
        return (
          this.$route.query.utm_content ||
          getCookie('utm_content') ||
          getCookie('[utm_content]')
        )
      },
      utm_term() {
        return (
          this.$route.query.utm_term ||
          getCookie('utm_term') ||
          getCookie('[utm_term]')
        )
      },
      gclid() {
        return (
          this.$route.query.gclid || getCookie('gclid') || getCookie('[gclid]')
        )
      },
      fbclid() {
        return (
          this.$route.query.fbclid ||
          getCookie('fbclid') ||
          getCookie('[fbclid]')
        )
      },
      msclkid() {
        return (
          this.$route.query.msclkid ||
          getCookie('msclkid') ||
          getCookie('[msclkid]')
        )
      },
      screenHistory() {
        return this.$store.state.applications.screenHistory
      },
      currentScreen() {
        return this.$store.state.applications.currentScreen
      },
      hasBackScreens() {
        if (this.$store.state.applications.screenHistory.length > 0) return true
        return false
      },
      actionsClass() {
        if (this.$vuetify.breakpoint.width < 550) {
          if (this.$route.name === 'stylist-final-status') return 'width-100'
          else return 'width-100 navigation'
        } else {
          if (this.$route.name === 'stylist-final-status') return 'width-550'
          else return 'width-550 navigation'
        }
      }
    },
    methods: {
      ...mapActions([
        'setCurrentScreen',
        'setScreenHistory',
        'fetchApplication',
        'setApplicationData'
      ]),
      handleUtmParams() {
        if (
          this.utm_campaign &&
          this.application?.utm_campaign !== this.utm_campaign
        )
          this.setApplicationData({
            field: 'utm_campaign',
            answer: this.utm_campaign
          })
        if (this.utm_source && this.application?.utm_source !== this.utm_source)
          this.setApplicationData({
            field: 'utm_source',
            answer: this.utm_source
          })
        if (this.utm_medium && this.application?.utm_medium !== this.utm_medium)
          this.setApplicationData({
            field: 'utm_medium',
            answer: this.utm_medium
          })
        if (
          this.utm_content &&
          this.application?.utm_content !== this.utm_content
        )
          this.setApplicationData({
            field: 'utm_content',
            answer: this.utm_content
          })
        if (this.utm_term && this.application?.utm_term !== this.utm_term)
          this.setApplicationData({
            field: 'utm_term',
            answer: this.utm_term
          })
        if (this.gclid && this.application?.gclid !== this.gclid)
          this.setApplicationData({ field: 'gclid', answer: this.gclid })
        if (this.fbclid && this.application?.fbclid !== this.fbclid)
          this.setApplicationData({ field: 'fbclid', answer: this.fbclid })
        if (this.msclkid && this.application?.msclkid !== this.msclkid)
          this.setApplicationData({ field: 'msclkid', answer: this.msclkid })
      },
      handleBack() {
        // 0. check if there is previous screen
        if (!this.hasBackScreens) return
        // 1. get previous screen
        const previousScreen = this.screenHistory[this.screenHistory.length - 1]
        // 2. set current screen
        this.setCurrentScreen(previousScreen)
        // 3. remove from screen history
        this.setScreenHistory(this.screenHistory.slice(0, -1))
        // 4. go to the previous screen
        this.$router.push({
          name: this.currentScreen,
          query: this.$route.query
        })
      },
      handleNext() {
        // 0. check if is valid
        if (!this.isValid) return
        // 1. add to screen history
        this.setScreenHistory([...this.screenHistory, this.currentScreen])
        // 2. set current screen
        this.setCurrentScreen(this.nextScreen)
        // 3. go to the next screen
        this.$router.push({
          name: this.currentScreen,
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.application._id
          },
          query: this.$route.query
        })
      }
    },
    watch: {
      stylistLoggedIn(newValue, oldValue) {
        if (!newValue) {
          this.$router.push({ name: 'stylist-login', query: this.$route.query })
        }
      },
      route() {
        // forbid to go back from FSP if finished
        if (this.applicationFinished && this.route !== 'stylist-final-status') {
          this.$router.push({
            name: 'stylist-final-status',
            query: this.$route.query
          })
        }
      }
    },

    async created() {
      // check if stylist is logged in
      if (this.stylistLoggedIn) {
        // load application from backend
        await this.fetchApplication(this.$route.params.applicationId)
        this.handleUtmParams()

        if (this.$route.name !== this.currentScreen) {
          this.$router.push({
            name: this.currentScreen,
            params: {
              stylistId: this.$route.params.stylistId,
              applicationId: this.application._id
            },
            query: this.$route.query
          })
        }
      }
      // push to login screen
      else {
        this.$router.push({ name: 'stylist-login', query: this.$route.query })
      }
      this.isLoading = false
    }
  }
</script>

<style scoped>
  .navigation {
    position: fixed !important;
    display: flex !important;
    z-index: 1000000 !important;
    background-color: white !important;
    /* width: 550px !important; */
  }
  .width-100 {
    width: 100% !important;
  }
  .width-550 {
    width: 550px !important;
  }
</style>
