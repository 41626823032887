<template>
  <div>
    <v-card-text class="mb-0 pb-0 mt-4">
      <v-row class="align-center mb-0 pb-0">
        <v-spacer></v-spacer>
        <v-col cols="6">
          <v-text-field
            outlined
            hide-details
            dense
            label="Enter Consumer Name"
            v-model="fullName"
          ></v-text-field
        ></v-col>
        <v-col cols="2" class="d-flex justify-start">
          <v-btn
            @click="handleFindConsumers"
            small
            block
            :loading="$store.state.consumers.loading"
            >Find</v-btn
          ></v-col
        >
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-switch
            class="pb-4"
            hide-details
            v-model="showAll"
            label="Show All"
        /></v-col>
      </v-row>
    </v-card-text>
    <v-card-text class="pt-0">
      <v-data-table
        :headers="headers"
        :items="consumers"
        item-key="id"
        :loading="$store.state.consumers.loading"
      >
        <!-- for edit action -->
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            rounded
            color="#ff5e6c"
            class="white--text text-capitalize"
            @click="selectConsumer(item)"
          >
            Select
          </v-btn>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn small @click="handleConsumersBack" rounded>Back</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card-text>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      salonId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        showAll: false,
        fullName: '',
        headers: [
          { text: 'Full Name', align: 'center', value: 'fullName' },
          { text: 'Email', align: 'start', value: 'email' },
          { text: 'Actions', value: 'actions', align: 'center' }
        ]
      }
    },
    computed: {
      consumers() {
        return this.$store.state.consumers.list
      }
    },
    methods: {
      ...mapActions(['fetchConsumersByFirstNameLastNameAndSalon']),
      handleFindConsumers() {
        const search = { fullName: this.fullName }
        if (!this.showAll) {
          search.salon = this.salonId
        }
        this.fetchConsumersByFirstNameLastNameAndSalon({
          sortBy: 'createdAt',
          sortDesc: true,
          search
        })
      },
      handleConsumersBack() {
        this.$emit('onBack')
      },
      selectConsumer(item) {
        this.$emit('onSelectConsumer', item._id)
      }
    },
    watch: {
      showAll(newValue, oldValue) {
        this.handleFindConsumers()
      }
    }
  }
</script>
