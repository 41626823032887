<template>
  <v-container class="pt-0">
    <div class="form">
      <v-card-text class="text-center title py-0"
        >About how long is your hair?</v-card-text
      >
      <v-card-text class="text-center subtitle">Choose one</v-card-text>
      <v-row>
        <v-col
          @click="handleSelect('a')"
          :dark="answer === 'a'"
          class="hair-img d-flex justify-center flex-column align-center"
          v-if="true"
        >
          <v-img
            width="140"
            src="@/assets/BEFORE SHORT.png"
            lazy-src="@/assets/BEFORE SHORT.png"
          ></v-img>
          <v-btn
            depressed
            :color="answer === 'a' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'a'"
            class="text-none"
            width="140"
            small
            >Above shoulders</v-btn
          ></v-col
        >
        <v-col
          @click="handleSelect('b')"
          :dark="answer === 'b'"
          class="hair-img d-flex justify-center flex-column align-center"
          v-if="true"
          ><v-img
            width="140"
            src="@/assets/BEFORE MID.png"
            lazy-src="@/assets/BEFORE MID.png"
          ></v-img
          ><v-btn
            depressed
            :color="answer === 'b' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'b'"
            class="text-none"
            width="140"
            small
            >Past shoulders</v-btn
          ></v-col
        >
        <v-col
          @click="handleSelect('c')"
          :dark="answer === 'c'"
          class="hair-img d-flex justify-center flex-column align-center"
          ><v-img
            width="140"
            src="@/assets/BEFORE LONG.png"
            lazy-src="@/assets/BEFORE LONG.png"
          ></v-img
          ><v-btn
            depressed
            :color="answer === 'c' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'c'"
            class="text-none"
            width="140"
            small
            >Mid back</v-btn
          ></v-col
        >
      </v-row>
    </div>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        screen: 'hairLongIs',
        nextScreen: 'hairLongWant',
        question: 'hairLongIs',
        answer: ''
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false) {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          goNext
        })
      },
      handleSelect(answer) {
        this.answer = answer
        this.emitSelect(true)
      }
    },
    created() {
      this.answer = this.$route.query[this.question] || ''
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    margin: 10px 0;
  }
  .hair-img {
    cursor: pointer;
  }
</style>
