<template>
  <v-sheet
    class="mx-auto d-flex justify-center highlights-wrapper"
    elevation="0"
    shaped
  >
    <v-slide-group v-model="model" class="pa-0" center-active show-arrows>
      <v-slide-item v-for="highlight in highlights" :key="highlight">
        <v-card class="mx-1 my-1" elevation="0" :width="detectXS() ? 69 : 120">
          <v-row
            class="fill-height flex-columns"
            align="center"
            justify="center"
          >
            <v-col class="d-flex justify-center" cols="12">
              <v-icon
                size="34"
                v-if="getHighlightIcon(highlight).startsWith('mdi')"
                color="yellow"
              >
                {{ getHighlightIcon(highlight) }}
              </v-icon>
              <v-icon v-else dark>
                {{ '$vuetify.icons.' + getHighlightIcon(highlight) }}
              </v-icon>
            </v-col>
            <v-col
              class="d-flex justify-center align-center pt-0"
              cols="12"
              sm="10"
            >
              <!-- capitalize if hairdreams educator -->
              <v-card-text
                v-if="highlight === HighlightOptions.HAIRDREAMS_EDUCATOR.text"
                class="pa-0 text-center text-sm-body-1 text-caption text-capitalize"
                >{{ highlight }}</v-card-text
              >
              <v-card-text
                v-else
                class="pa-0 text-center text-sm-body-1 text-caption"
                >{{ highlight }}</v-card-text
              >
            </v-col>
          </v-row>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
  import { HighlightOptions, AdditionalHighlightOptions } from '@/config/enums'
  import { detectXS } from '@/utils/helpers'

  export default {
    props: {
      highlights: {
        type: Array
      }
    },
    data: () => ({
      HighlightOptions,
      model: null,
      detectXS
    }),
    methods: {
      getHighlightIcon(text) {
        let hArr = []
        for (const key in HighlightOptions) {
          hArr.push(HighlightOptions[key])
        }
        for (const key in AdditionalHighlightOptions) {
          hArr.push(AdditionalHighlightOptions[key])
        }
        let h = hArr.find(item => item.text === text)
        return h.icon
      }
    }
  }
</script>
<style lang="scss" scoped>
  .highlights-wrapper {
    max-width: 100%;
  }
  .v-icon {
    border: 3px solid lightgrey;
    padding: 21px;
    border-radius: 50%;
  }

  @media (max-width: 600px) {
    .v-icon {
      border: 3px solid lightgrey;
      padding: 11px;
      border-radius: 50%;
    }
    .highlights-wrapper {
      max-width: 330px;
    }
  }
</style>
