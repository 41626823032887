<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" class="pl-0 pb-0">
        <v-card-title class="breakword pb-0"
          ><span class="font-weight-bold">Hair extensions clients</span>
          Considering all of your current clients, how many do you feel would
          need or want to get hair extensions?
        </v-card-title></v-col
      >
      <v-col
        v-for="option in options"
        :key="option.value"
        class="d-flex justify-center flex-column align-center pb-0"
        :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-3'"
        cols="6"
        @click="handleSelect(option.value)"
      >
        <v-btn
          depressed
          :color="answer === option.value ? 'user_cta' : 'user_btn'"
          :dark="answer === option.value"
          class="text-none mt-1"
          width="100%"
          >{{ option.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { SystemOptions } from '@/config/enums'

  export default {
    data() {
      return {
        answer: '',
        options: [
          {
            text: '0 to 1',
            value: '0 to 1'
          },
          {
            text: '1 to 2',
            value: '1 to 2'
          },
          {
            text: '3 to 5',
            value: '3 to 5'
          },
          {
            text: '6 to 9',
            value: '6 to 9'
          },
          {
            text: '10 to 20',
            value: '10 to 20'
          },
          {
            text: '20+',
            value: '20+'
          }
        ],
        field: 'extensionsClientNumber',
        screen: 'stylist-extensions3',
        nextScreen: ''
      }
    },
    computed: {
      application() {
        return this.$store.state.applications.application
      },
      vuexSystems() {
        return this.application['system']
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        // 1. if systems include microlines
        if (this.vuexSystems.includes(SystemOptions.MICROLINES))
          this.nextScreen = 'stylist-restoration1'
        // 2. if systems doesn't include microlines
        else this.nextScreen = 'stylist-ads1'

        this.setNextScreen(this.nextScreen)
      },
      handleSelect(answer) {
        this.answer = answer
        // 1. set data
        this.setApplicationData({ field: this.field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
        // 4. go to next screen
        this.$emit('next')
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field]) {
        this.answer = this.$store.state.applications.application[this.field]
        this.handleNextScreen()
        this.setIsValid(true)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    }
  }
</script>
<style scoped>
  .breakword {
    word-break: break-word;
  }
</style>
