<template>
  <div>
    <div
      class="d-flex justify-center align-center"
      v-if="isLoading"
      style="min-height: 600px"
    >
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <GChart :type="type" :data="chartData" :options="options"
    /></template>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  export default {
    components: {
      GChart
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true,
        type: 'PieChart',
        options: {
          colors: ['#808080', 'green'],
          title: 'Discount Used',
          height: 600,
          animation: { startup: true, duration: 500 },
          legend: { position: 'bottom' }
        }
      }
    },
    computed: {
      consultationsDiscountUsed() {
        return this.$store.state.analytics.consultationsDiscountUsed
      },
      chartData() {
        const data = [['Discount used', 'Count']]
        for (const key in this.consultationsDiscountUsed) {
          if (key !== 'total') {
            data.push([key, this.consultationsDiscountUsed[key]])
          }
        }

        return data
      }
    },
    methods: {
      ...mapActions(['fetchConsultationsDiscountUsed'])
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true
        await this.fetchConsultationsDiscountUsed({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true
        await this.fetchConsultationsDiscountUsed({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.isLoading = false
      }
    },
    async created() {
      await this.fetchConsultationsDiscountUsed({
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped></style>
