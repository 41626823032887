<template>
  <v-container class="ma-0 pa-0 mx-auto pt-0">
    <v-card class="elevation-12 mx-auto" max-width="400" max-height="200">
      <v-toolbar dark>
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Page Not Found</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="mx-auto text-center"
        ><v-icon large>mdi-alert</v-icon></v-card-text
      >
      <v-card-text class="mx-auto pt-0 text-center"
        >We're sorry, the page you requested could not be found.</v-card-text
      >
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'NotFound'
  }
</script>
