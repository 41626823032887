<template>
  <v-dialog v-model="show" max-width="600" @click:outside="close">
    <v-card>
      <v-toolbar elevation="0" height="100">
        <v-card-title class="breakword my-3"
          >MICROLINES START KIT INCLUDES:</v-card-title
        >
        <v-spacer></v-spacer>
        <v-icon @click="close" class="mb-auto mt-4">mdi-close</v-icon>
      </v-toolbar>

      <v-card-text>
        <ul>
          <li>FreeStyler Machine</li>
          <li>Hairdreams Color Ring</li>
          <li>Removal System</li>
          <li>Full Wet line Assortment</li>
          <li>MicroLines Brush</li>
          <li>Practice MicroLines Piece with Mannequin Head</li>
          <li>Extra MicroLines Bonding Rings</li>
          <li>Hairdreams Tapes Sampler</li>
          <li>Training Manual</li>
          <li>Advertising Material</li>
          <li>Style Books & Brochures</li>
          <li>One 7 Star Wide or Tight MicroLines</li>
          <li>Model Home Care Kit for your live model</li>
        </ul>
      </v-card-text>
      <v-card-text class="subtitle-1"
        >ONLINE TRAINING MODULE + 1 DAY CERTIFICATION CLASS:</v-card-text
      >
      <v-card-text
        >This class combines the convenience of an easy-to-use online training +
        an in person hands on class where you will apply, cut, and style the
        MicroLines hair integration piece on a live model of your choice.
        Training additionally covers removal, re-application, maintenance,
        consultation and marketing. See the
        <a
          class="link"
          href="https://iwanthairdreams.com/pages/training-calendar"
          >Training Calendar</a
        >
        for dates and locations. The Start Kit can certify up to two licensed
        cosmetologists. Inquire for details on certifying more than two stylists
        or in-salon training options.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: false
      }
    },
    methods: {
      close() {
        this.$emit('close', false)
      }
    }
  }
</script>
