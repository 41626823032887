<template>
  <v-container fluid class="pt-0">
    <v-card>
      <v-toolbar color="cyan" dark flat>
        <v-card-title>Analytics</v-card-title>
        <template v-slot:extension>
          <v-tabs touchless v-model="tab" align-with-title>
            <v-tabs-slider touchless color="yellow"></v-tabs-slider>

            <v-tab touchless v-for="item in items" :key="item.value">
              {{ item.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <DateFilter
        :start="defaultStartDate"
        :end="defaultEndDate"
        @change="handleDateChange"
        showBtn
        @onFilter="handleFilter"
      />

      <v-tabs-items
        :touch="{ left: handleTouch, right: handleTouch }"
        v-model="tab"
      >
        <v-tab-item v-for="item in items" :key="item.value">
          <v-card flat>
            <v-card-text class="pt-0">
              <component
                :tab="tab"
                :startDate="startDateProp"
                :endDate="endDateProp"
                :is="item.component"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
  import DateFilter from '@/components/DateFilter.vue'
  import ConsultationsAnalytics from '@/components/admin/analytics/ConsultationsAnalytics.vue'
  import ConsumersAnalytics from '@/components/admin/analytics/ConsumersAnalytics.vue'
  import SalonsAnalytics from '@/components/admin/analytics/SalonsAnalytics.vue'
  import TrafficAnalytics from '@/components/admin/analytics/TrafficAnalytics.vue'
  import moment from 'moment'

  export default {
    components: {
      TrafficAnalytics,
      SalonsAnalytics,
      ConsumersAnalytics,
      ConsultationsAnalytics,
      DateFilter
    },
    data() {
      return {
        startDate: null,
        endDate: null,
        startDateProp: null,
        endDateProp: null,
        tab: null,
        items: [
          {
            value: 'Sources of traffic',
            title: 'Sources of traffic',
            component: 'TrafficAnalytics'
          },
          {
            value: 'Salons',
            title: 'Salons',
            component: 'SalonsAnalytics'
          },
          {
            value: 'Consumers',
            title: 'Consumers',
            component: 'ConsumersAnalytics'
          },
          {
            value: 'Consultations',
            title: 'Consultations',
            component: 'ConsultationsAnalytics'
          }
        ]
      }
    },
    computed: {
      defaultStartDate() {
        return moment(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).format(
          'YYYY-MM-DD'
        )
      },
      defaultEndDate() {
        return moment(new Date()).format('YYYY-MM-DD')
      }
    },
    methods: {
      handleTouch() {},
      handleFilter() {
        this.startDateProp = this.startDate
        this.endDateProp = this.endDate
      },
      handleDateChange(dates) {
        this.startDate = dates.startDate
        this.endDate = dates.endDate
      }
    },
    created() {
      this.startDateProp = this.defaultStartDate
      this.endDateProp = this.defaultEndDate
    }
  }
</script>

<style lang="scss" scoped></style>
