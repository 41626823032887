<template>
  <v-container fluid>
    <v-data-table
      color="red"
      dense
      :headers="headers"
      :items="salons"
      item-key="id"
      sort-by="updatedAt"
      sort-desc
      class="elevation-5"
      :loading="$store.state.salons.loading"
    >
      <template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification }}</v-snackbar
        >

        <v-toolbar flat>
          <v-toolbar-title>PENDING SALONS</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <v-dialog v-model="dialog" max-width="1200px">
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title class="mx-auto">Edit Salon</v-toolbar-title>
              </v-toolbar>

              <v-card-text>
                <v-container>
                  <!-- note: ID -->
                  <input v-model="editedItem.id" type="hidden" />

                  <!-- agreement info -->
                  <v-row>
                    <v-col cols="6">
                      <v-card-text class="text-h6 pb-0 text-capitalize"
                        >Agreement Info:
                        <span class="font-weight-bold">{{
                          editedItem.terms
                        }}</span></v-card-text
                      >
                      <v-card-text class="py-1 font-weight-bold">
                        Accepted:
                        {{ getAcceptedDate }}
                      </v-card-text>
                      <v-card-text class="py-1 font-weight-bold">
                        Declined:
                        {{ getDeclinedDate }}
                      </v-card-text>
                    </v-col>
                    <v-col cols="6">
                      <v-textarea
                        background-color="white"
                        auto-grow
                        outlined
                        rows="3"
                        row-height="25"
                        filled
                        hide-details
                        @change="onChange($event, 'memo')"
                        label="Memo"
                        type="text"
                        v-model="editedItem.memoTemp"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <!-- note: headline -->
                  <v-row>
                    <v-col cols="6"
                      ><v-card-text class="headline text-uppercase"
                        >Approved</v-card-text
                      ></v-col
                    >
                    <v-col cols="6"
                      ><v-card-text class="headline text-uppercase"
                        >Not Approved</v-card-text
                      >
                      <v-btn @click="handleApproveAll">Approve All</v-btn>
                    </v-col>
                  </v-row>

                  <!-- note: IMG -->
                  <v-row>
                    <v-col cols="6">
                      <v-img
                        text-align="center"
                        :src="editedItem.imgUrl"
                        :lazy-src="editedItem.imgUrl"
                        max-height="150"
                        max-width="150"
                      ></v-img>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <template v-if="showTemp('imgUrl')">
                      <v-col cols="5">
                        <div class="bcc-yellow d-flex justify-center">
                          <v-img
                            text-align="center"
                            :lazy-src="editedItem.imgUrlTemp"
                            :src="editedItem.imgUrlTemp"
                            max-height="150"
                            max-width="150"
                          ></v-img>
                        </div>
                      </v-col>
                      <v-col cols="1" class="d-flex flex-column justify-center">
                        <v-btn
                          rounded
                          class="mb-1"
                          small
                          color="success"
                          @click="confirmTemp('imgUrl')"
                          ><v-icon small>mdi-check</v-icon></v-btn
                        >
                        <v-btn
                          rounded
                          small
                          color="error"
                          @click="denyTemp('imgUrl')"
                          ><v-icon small>mdi-close</v-icon></v-btn
                        >
                      </v-col>
                    </template>
                  </v-row>

                  <!-- note: NAME -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="name"
                    :showTemp="showTemp('name')"
                    label="Salon name"
                    @confirm="confirmTemp('name')"
                    @deny="denyTemp('name')"
                  />

                  <!-- note: CONTACT -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="contact"
                    :showTemp="showTemp('contact')"
                    label="Contact"
                    @confirm="confirmTemp('contact')"
                    @deny="denyTemp('contact')"
                  />

                  <!-- note: ADDRESS 1 -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="address1"
                    :showTemp="showTemp('address1')"
                    label="Address 1"
                    @confirm="confirmTemp('address1')"
                    @deny="denyTemp('address1')"
                  />

                  <!-- note: ADDRESS 2 -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="address2"
                    :showTemp="showTemp('address2')"
                    label="Address 2"
                    @confirm="confirmTemp('address2')"
                    @deny="denyTemp('address2')"
                  />

                  <!-- note: CITY -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="city"
                    :showTemp="showTemp('city')"
                    label="City"
                    @confirm="confirmTemp('city')"
                    @deny="denyTemp('city')"
                  />

                  <!-- note: STATE -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="state"
                    :showTemp="showTemp('state')"
                    label="State"
                    @confirm="confirmTemp('state')"
                    @deny="denyTemp('state')"
                  />

                  <!-- note: COUNTRY -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="country"
                    :showTemp="showTemp('country')"
                    label="country"
                    @confirm="confirmTemp('country')"
                    @deny="denyTemp('country')"
                  />

                  <!-- note: ZIP -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="zip"
                    :showTemp="showTemp('zip')"
                    label="Zip"
                    @confirm="confirmTemp('zip')"
                    @deny="denyTemp('zip')"
                  />

                  <!-- note: PHONE -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="phone"
                    :showTemp="showTemp('phone')"
                    label="Phone"
                    @confirm="confirmTemp('phone')"
                    @deny="denyTemp('phone')"
                  />

                  <!-- note: sms phones -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="smsPhone1"
                    :showTemp="showTemp('smsPhone1')"
                    type="object"
                    propToShow="phoneNumber"
                    label="SMS Phone 1"
                    @confirm="confirmTemp('smsPhone1')"
                    @deny="denyTemp('smsPhone1')"
                  />
                  <EditSalonItem
                    :item="editedItem"
                    prop="smsPhone2"
                    :showTemp="showTemp('smsPhone2')"
                    type="object"
                    propToShow="phoneNumber"
                    label="SMS Phone 2"
                    @confirm="confirmTemp('smsPhone2')"
                    @deny="denyTemp('smsPhone2')"
                  />
                  <EditSalonItem
                    :item="editedItem"
                    prop="smsPhone3"
                    :showTemp="showTemp('smsPhone3')"
                    type="object"
                    propToShow="phoneNumber"
                    label="SMS Phone 3"
                    @confirm="confirmTemp('smsPhone3')"
                    @deny="denyTemp('smsPhone3')"
                  />

                  <!-- note: EMAIL -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="email"
                    :showTemp="showTemp('email')"
                    label="Email"
                    @confirm="confirmTemp('email')"
                    @deny="denyTemp('email')"
                  />

                  <!-- note: WEBSITE -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="website"
                    :showTemp="showTemp('website')"
                    label="website"
                    @confirm="confirmTemp('website')"
                    @deny="denyTemp('website')"
                  />

                  <!-- note: REVIEW 1 -->
                  <v-row>
                    <v-col cols="6" class="pa-0">
                      <v-card-text
                        v-if="
                          editedItem.review1.content ||
                          editedItem.review1Temp.content
                        "
                        class="pb-0"
                        >Review 1</v-card-text
                      >
                      <div
                        v-if="showFbReview('review1')"
                        v-html="getPreview(editedItem.review1.content)"
                      ></div>
                      <CustomReview
                        v-if="showCustomReview('review1')"
                        :name="editedItem.review1.name"
                        :date="editedItem.review1.date"
                        :content="editedItem.review1.content"
                        :reviewType="editedItem.review1.reviewType"
                      />
                    </v-col>
                    <v-divider vertical></v-divider>
                    <template v-if="showTemp('review1')">
                      <v-col cols="6" class="pa-0 bcc-yellow">
                        <v-col class="pa-0 d-flex align-center">
                          <v-col class="pa-0"
                            ><v-card-text class="pb-0"
                              >Review 1</v-card-text
                            ></v-col
                          >
                          <v-col class="d-flex justify-center pa-0">
                            <v-btn
                              rounded
                              class="mb-1"
                              small
                              color="success"
                              @click="confirmTemp('review1')"
                              ><v-icon small>mdi-check</v-icon></v-btn
                            >
                            <v-btn
                              rounded
                              small
                              color="error"
                              @click="denyTemp('review1')"
                              ><v-icon small>mdi-close</v-icon></v-btn
                            >
                          </v-col>
                        </v-col>
                        <v-col>
                          <div
                            v-if="showFbReview('review1Temp')"
                            class="rew-box"
                            v-html="getPreview(editedItem.review1Temp.content)"
                          ></div>
                          <CustomReview
                            v-if="showCustomReview('review1Temp')"
                            :name="editedItem.review1Temp.name"
                            :date="editedItem.review1Temp.date"
                            :content="editedItem.review1Temp.content"
                            :reviewType="editedItem.review1Temp.reviewType"
                          />
                        </v-col>
                      </v-col>
                    </template>
                  </v-row>

                  <!-- note: REVIEW 2 -->
                  <v-row>
                    <v-col cols="6" class="pa-0">
                      <v-card-text
                        v-if="
                          editedItem.review2.content ||
                          editedItem.review2Temp.content
                        "
                        class="pb-0"
                        >Review 2</v-card-text
                      >
                      <div
                        v-if="showFbReview('review2')"
                        v-html="getPreview(editedItem.review2.content)"
                      ></div>
                      <CustomReview
                        v-if="showCustomReview('review2')"
                        :name="editedItem.review2.name"
                        :date="editedItem.review2.date"
                        :content="editedItem.review2.content"
                        :reviewType="editedItem.review2.reviewType"
                      />
                    </v-col>
                    <v-divider vertical></v-divider>
                    <template v-if="showTemp('review2')">
                      <v-col cols="6" class="pa-0 bcc-yellow">
                        <v-col class="pa-0 d-flex align-center">
                          <v-col class="pa-0"
                            ><v-card-text class="pb-0"
                              >Review 2</v-card-text
                            ></v-col
                          >
                          <v-col class="d-flex justify-center pa-0">
                            <v-btn
                              rounded
                              class="mb-1"
                              small
                              color="success"
                              @click="confirmTemp('review2')"
                              ><v-icon small>mdi-check</v-icon></v-btn
                            >
                            <v-btn
                              rounded
                              small
                              color="error"
                              @click="denyTemp('review2')"
                              ><v-icon small>mdi-close</v-icon></v-btn
                            >
                          </v-col>
                        </v-col>
                        <v-col>
                          <div
                            v-if="showFbReview('review2Temp')"
                            class="rew-box"
                            v-html="getPreview(editedItem.review2Temp.content)"
                          ></div>
                          <CustomReview
                            v-if="showCustomReview('review2Temp')"
                            :name="editedItem.review2Temp.name"
                            :date="editedItem.review2Temp.date"
                            :content="editedItem.review2Temp.content"
                            :reviewType="editedItem.review2Temp.reviewType"
                          />
                        </v-col>
                      </v-col>
                    </template>
                  </v-row>

                  <!-- note: REVIEW 3 -->
                  <v-row>
                    <v-col cols="6" class="pa-0">
                      <v-card-text
                        v-if="
                          editedItem.review3.content ||
                          editedItem.review3Temp.content
                        "
                        class="pb-0"
                        >Review 3</v-card-text
                      >
                      <div
                        v-if="showFbReview('review3')"
                        v-html="getPreview(editedItem.review3.content)"
                      ></div>
                      <CustomReview
                        v-if="showCustomReview('review3')"
                        :name="editedItem.review3.name"
                        :date="editedItem.review3.date"
                        :content="editedItem.review3.content"
                        :reviewType="editedItem.review3.reviewType"
                      />
                    </v-col>
                    <v-divider vertical></v-divider>
                    <template v-if="showTemp('review3')">
                      <v-col cols="6" class="pa-0 bcc-yellow">
                        <v-col class="pa-0 d-flex align-center">
                          <v-col class="pa-0"
                            ><v-card-text class="pb-0"
                              >Review 3</v-card-text
                            ></v-col
                          >
                          <v-col class="d-flex justify-center pa-0">
                            <v-btn
                              rounded
                              class="mb-1"
                              small
                              color="success"
                              @click="confirmTemp('review3')"
                              ><v-icon small>mdi-check</v-icon></v-btn
                            >
                            <v-btn
                              rounded
                              small
                              color="error"
                              @click="denyTemp('review3')"
                              ><v-icon small>mdi-close</v-icon></v-btn
                            >
                          </v-col>
                        </v-col>
                        <v-col>
                          <div
                            v-if="showFbReview('review3Temp')"
                            class="rew-box"
                            v-html="getPreview(editedItem.review3Temp.content)"
                          ></div>
                          <CustomReview
                            v-if="showCustomReview('review3Temp')"
                            :name="editedItem.review3Temp.name"
                            :date="editedItem.review3Temp.date"
                            :content="editedItem.review3Temp.content"
                            :reviewType="editedItem.review3Temp.reviewType"
                          />
                        </v-col>
                      </v-col>
                    </template>
                  </v-row>

                  <!-- note: SYSTEMS -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="systems"
                    :showTemp="showTemp('systems')"
                    label="Systems"
                    @confirm="confirmTemp('systems')"
                    @deny="denyTemp('systems')"
                    :options="systems"
                    multiple
                    type="select"
                    hint="Select all systems salon offers."
                  />

                  <!-- note: Highlights -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="highlights"
                    :showTemp="showTemp('highlights')"
                    label="Highlights"
                    @confirm="confirmTemp('highlights')"
                    @deny="denyTemp('highlights')"
                    :options="highlights"
                    type="select"
                    hint="Select all highlights."
                    multiple
                  />

                  <!-- note: Additional Highlight -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="additionalHighlight"
                    :showTemp="showTemp('additionalHighlight')"
                    label="Additional Highlight"
                    @confirm="confirmTemp('additionalHighlight')"
                    @deny="denyTemp('additionalHighlight')"
                    :options="additionalHighlights"
                    type="select"
                    hint="Select highlight."
                  />

                  <!-- note: HOURS -->
                  <v-row>
                    <v-col cols="6">
                      <ShowHours :hours="editedItem.hours" />
                    </v-col>
                    <v-divider vertical></v-divider>
                    <template v-if="showTemp('hours')">
                      <v-col cols="5">
                        <v-card-text class="pl-1">Business Hours</v-card-text>
                        <ShowHours
                          v-if="editedItem.hoursTemp"
                          :hours="editedItem.hoursTemp"
                        />
                      </v-col>
                      <v-col cols="1" class="d-flex flex-column justify-center">
                        <v-card-text class="pl-1"></v-card-text>
                        <v-btn
                          rounded
                          class="mb-1"
                          small
                          color="success"
                          @click="confirmTemp('hours')"
                          ><v-icon small>mdi-check</v-icon></v-btn
                        >
                        <v-btn
                          rounded
                          small
                          color="error"
                          @click="denyTemp('hours')"
                          ><v-icon small>mdi-close</v-icon></v-btn
                        >
                      </v-col>
                    </template>
                  </v-row>

                  <!-- note: by appointment only -->
                  <EditSalonItem
                    :item="editedItem"
                    prop="appointmentOnly"
                    :showTemp="showTemp('appointmentOnly')"
                    label="By appointment only"
                    @confirm="confirmTemp('appointmentOnly')"
                    @deny="denyTemp('appointmentOnly')"
                    type="checkbox"
                  />
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="white darken-1" @click="close"> Cancel </v-btn>
                <v-btn
                  color="green darken-1"
                  class="white--text"
                  id="save-btn"
                  @click="handleSave"
                  :disabled="editedItem && !isValid"
                  :loading="isSending"
                  >Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

        <!-- search by date -->
        <DateFilter @change="handleDateChange" />
      </template>
      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="value => (filters[header.value] = value)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td>
        </tr>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mx-3" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | localeDate(true) }}
      </template>
      <template v-slot:item.terms="{ item }">
        <v-chip
          :color="
            item.terms === 'accepted'
              ? 'green'
              : item.terms === 'declined'
              ? 'red'
              : 'yellow'
          "
          small
          :text-color="item.terms === 'no answer' ? 'grey' : 'white'"
          >{{ item.terms }}</v-chip
        >
        {{
          item.terms === 'accepted'
            ? moment(new Date(item.termsInfo.accept)).format('YYYY-MM-DD')
            : item.terms === 'declined'
            ? moment(new Date(item.termsInfo.decline)).format('YYYY-MM-DD')
            : ''
        }}
      </template>
      <template v-slot:item.active="{ item }">
        <v-chip
          :color="item.active ? 'success' : 'error'"
          small
          text-color="white"
          >{{ item.active ? 'yes' : 'no' }}</v-chip
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import { mapActions } from 'vuex'
  import {
    SystemOptions,
    AdditionalSystemOptions,
    AdditionalHighlightOptions,
    HighlightOptions,
    ReviewTypes
  } from '@/config/enums'
  import {
    getPendingSalonsTableInfo,
    getFilteredByDate
  } from '@/config/tables.helper'
  import { arraysEqual } from '@/utils/helpers'
  import EditSalonItem from '@/components/admin/EditSalonItem.vue'
  import CustomReview from '@/components/CustomReview'
  import ShowHours from '@/components/ShowHours'
  import { decode } from 'html-entities'
  import FilterField from '@/components/FilterField.vue'
  import DateFilter from '@/components/DateFilter.vue'
  import { getTermsStatus } from '@/utils/helpers'

  const isHtml = review => {
    return review.toString().startsWith('<iframe')
  }

  export default {
    components: {
      EditSalonItem,
      CustomReview,
      ShowHours,
      FilterField,
      DateFilter
    },

    data: () => ({
      moment,
      isSending: false,
      filters: getPendingSalonsTableInfo(this).filters,
      headers: [],
      approveAll: false,
      showEditItemModal: false,
      currentProp: '',
      currentValue: {},
      snackbar: false,
      snackbarTimeout: 2000,
      snackbarColor: 'blue',
      notification: '',
      dialog: false,
      editedIndex: -1,
      editedItem: getPendingSalonsTableInfo(this).item,
      defaultItem: getPendingSalonsTableInfo(this).item
    }),

    computed: {
      salons() {
        // filter date updated at
        const salons = getFilteredByDate(
          this.$store.state.salons.list,
          this.filters.startDate,
          this.filters.endDate,
          'updatedAt'
        )
        return salons
          .filter(s => !!s.temp)
          .map(salon => ({
            id: salon._id,
            memo: salon.memo,
            memoTemp: salon.temp?.memo,
            name: salon.name,
            nameTemp: salon.temp?.name,
            address1: salon.address1,
            address1Temp: salon.temp?.address1,
            address2: salon.address2,
            address2Temp: salon.temp?.address2,
            city: salon.city,
            cityTemp: salon.temp?.city,
            contact: salon.contact,
            contactTemp: salon.temp?.contact,
            state: salon.state,
            stateTemp: salon.temp?.state,
            country: salon.country,
            countryTemp: salon.temp?.country,
            zip: salon.zip,
            zipTemp: salon.temp?.zip,
            navid: salon.navid,
            // note: remove, this is only added on back
            systems: salon.systems.filter(
              s =>
                s !== AdditionalSystemOptions.BONDED_EXTENSIONS &&
                s !== AdditionalSystemOptions.EXTENSIONS
            ),
            systemsTemp: salon.temp?.systems?.filter(
              s =>
                s !== AdditionalSystemOptions.BONDED_EXTENSIONS &&
                s !== AdditionalSystemOptions.EXTENSIONS
            ),
            highlights: salon.highlights,
            highlightsTemp: salon.temp?.highlights,
            additionalHighlight: salon.additionalHighlight,
            additionalHighlightTemp: salon.temp?.additionalHighlight,
            cratedAt: salon.cratedAt,
            phone: salon.phone,
            phoneTemp: salon.temp?.phone,
            smsPhone1: salon.smsPhone1,
            smsPhone1Temp: salon.temp?.smsPhone1,
            smsPhone2: salon.smsPhone2,
            smsPhone2Temp: salon.temp?.smsPhone2,
            smsPhone3: salon.smsPhone3,
            smsPhone3Temp: salon.temp?.smsPhone3,
            email: salon.email,
            emailTemp: salon.temp?.email,
            website: salon.website || 'N/A',
            websiteTemp: salon.temp?.website,
            review1: salon.review1,
            review1Temp: salon.temp?.review1,
            review2: salon.review2,
            review2Temp: salon.temp?.review2,
            review3: salon.review3,
            review3Temp: salon.temp?.review3,
            hours: salon.hours,
            hoursTemp: salon.temp?.hours,
            imgUrl: salon.imgUrl,
            imgUrlTemp: salon.temp?.imgUrl,
            systemQuestions: salon.systemQuestions,
            systemQuestionsTemp: salon.temp?.systemQuestions,
            appointmentOnly: salon.appointmentOnly,
            appointmentOnlyTemp: salon.temp?.appointmentOnly,
            updatedAt: salon.temp?.updatedAt,
            terms: getTermsStatus(salon.terms),
            active: salon.active,
            termsInfo: salon.terms
          }))
      },
      getAcceptedDate() {
        return this.editedItem.termsInfo?.accept
          ? new Date(this.editedItem.termsInfo.accept).toLocaleDateString()
          : 'N/A'
      },
      getDeclinedDate() {
        return this.editedItem.termsInfo?.decline
          ? new Date(this.editedItem.termsInfo.decline).toLocaleDateString()
          : 'N/A'
      },
      isValid() {
        return (
          this.editedItem.name &&
          this.editedItem.navid &&
          this.editedItem.contact &&
          this.editedItem.address1 &&
          this.editedItem.city &&
          this.editedItem.state &&
          this.editedItem.zip &&
          this.editedItem.phone &&
          this.editedItem.email &&
          this.editedItem.systems.length > 0
        )
      },
      systems() {
        return Object.values(SystemOptions)
      },
      highlights() {
        return Object.values(HighlightOptions)
      },
      additionalHighlights() {
        return Object.values(AdditionalHighlightOptions)
      }
    },

    methods: {
      ...mapActions(['fetchSalons', 'confirmSalonEdit', 'sendVerifySms']),
      handleDateChange(dates) {
        this.filters.startDate = dates.startDate
        this.filters.endDate = dates.endDate
      },
      handleApproveAll() {
        const allProps = []
        for (const key in getPendingSalonsTableInfo(this).item) {
          if (!key.includes('Temp')) {
            allProps.push(key)
          }
        }
        // approve
        allProps.forEach(prop => {
          if (this.showTemp(prop)) {
            this.confirmTemp(prop)
          }
        })
        // for sending mail changes published
        this.approveAll = true
        window.location.hash = '#save-btn'
      },
      showCustomReview(prop) {
        return (
          this.editedItem[prop].content &&
          (this.editedItem[prop].reviewType === ReviewTypes.GOOGLE ||
            this.editedItem[prop].reviewType === ReviewTypes.YELP ||
            this.editedItem[prop].reviewType === ReviewTypes.OTHER)
        )
      },
      showFbReview(prop) {
        return (
          this.editedItem[prop].content &&
          this.editedItem[prop].reviewType === ReviewTypes.FB
        )
      },
      getPreview(preview) {
        if (preview) {
          if (isHtml(preview)) {
            return preview
          } else if (isHtml(decode(preview))) {
            return decode(preview)
          } else {
            return 'Format not supported.'
          }
        } else {
          return ''
        }
      },
      showTemp(prop) {
        const tempProp = prop + 'Temp'
        if (prop === 'systems' || prop === 'highlights') {
          return !arraysEqual(this.editedItem[tempProp], this.editedItem[prop])
        } else if (
          prop === 'hours' ||
          prop === 'smsPhone1' ||
          prop === 'smsPhone2' ||
          prop === 'smsPhone3'
        ) {
          return (
            JSON.stringify(this.editedItem[prop]) !==
            JSON.stringify(this.editedItem[tempProp])
          )
        } else if (
          prop === 'review1' ||
          prop === 'review2' ||
          prop === 'review3'
        ) {
          if (this.editedItem[prop].reviewType === ReviewTypes.FB) {
            return (
              this.editedItem[tempProp].content &&
              this.editedItem[prop].content !==
                this.editedItem[tempProp].content
            )
          } else {
            return (
              this.editedItem[tempProp].content &&
              (this.editedItem[prop].content !==
                this.editedItem[tempProp].content ||
                this.editedItem[prop].date !== this.editedItem[tempProp].date ||
                this.editedItem[prop].name !== this.editedItem[tempProp].name ||
                this.editedItem[prop].reviewType !==
                  this.editedItem[tempProp].reviewType)
            )
          }
        } else {
          if (
            this.editedItem[tempProp] &&
            this.editedItem[prop] !== this.editedItem[tempProp]
          ) {
            return true
          } else {
            return false
          }
        }
      },
      confirmTemp(prop) {
        const tempProp = prop + 'Temp'
        this.editedItem[prop] = this.editedItem[tempProp]
      },
      denyTemp(prop) {
        this.editedItem[prop + 'Temp'] = this.editedItem[prop]
      },
      editItem(item) {
        this.editedIndex = this.salons.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async handleSave() {
        if (this.editedIndex > -1) {
          if (this.isValid) {
            this.isSending = true
            Object.assign(this.salons[this.editedIndex], this.editedItem)

            // add system questions from temp automatically
            if (this.editedItem.systemQuestionsTemp.length > 0) {
              this.editedItem.systemQuestions =
                this.editedItem.systemQuestionsTemp
            }

            // add appointment only
            this.editedItem.appointmentOnly =
              this.editedItem.appointmentOnlyTemp

            // add additional highlight
            this.editedItem.additionalHighlight =
              this.editedItem.additionalHighlightTemp

            // remove terms
            delete this.editedItem.terms

            // memo
            this.editedItem.memo = this.editedItem.memoTemp

            // dispatch -> update salon and send sms
            await this.confirmSalonEdit({
              salon: this.editedItem,
              approveAll: this.approveAll
            })

            await this.sendVerifySms(this.editedItem.id)

            if (!this.$store.state.salons.error) {
              this.notification = 'Salon saved.'
              this.snackbarColor = 'green'
              this.snackbar = true
              this.approveAll = false
            }
          }
        }
        this.isSending = false
        this.close()
      }
    },

    async created() {
      this.headers = getPendingSalonsTableInfo(this).headers

      // if user full (or admin with no stylists access) add edit and delete options
      if (!this.$store.getters.adminLoggedIn) {
        this.headers = this.headers.filter(h => h.value !== 'actions')
      }

      if (this.$route.query.navid) this.filters.navid = this.$route.query.navid

      await this.fetchSalons()
      if (!this.$store.state.salons.error) {
        this.notification = 'Salons fetched.'
        this.snackbar = true
      }
    },

    watch: {
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      }
    }
  }
</script>

<style scoped>
  .bcc-yellow {
    background-color: yellow;
  }
  .rew-box {
    width: 350px;
  }
</style>
