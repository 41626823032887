<template>
  <v-container
    class="px-0 px-sm-3 pt-0"
    v-if="
      !$store.state.consumers.loading &&
      !$store.state.salons.loading &&
      consumer
    "
  >
    <v-card class="elevation-12 mx-auto pt-3" max-width="500">
      <!-- <v-toolbar dark color="user_primary">
      <v-toolbar-title class="text-sm-h5 mx-auto"
        >WF2.1: Sorry {{ consumer.firstName }}</v-toolbar-title
      >
    </v-toolbar> -->
      <!-- note: not found -->
      <v-card-text v-if="showNotFound" class="pb-5"
        >Oops! Something went wrong in finding you a salon. Please call
        toll-free at <a href="tel:888-434-2471">888-434-2471</a> Mon-Fri 9AM-5PM
        PST to speak to a Client Services representative, so we can assist you
        in finding a salon.</v-card-text
      >
      <!-- <v-card-text v-if="showNotFound"
        >Oops! Something went wrong in finding your referral salon. You don't
        need to resubmit the form, a Hairdreams Specialist will contact you
        within 1 business day.</v-card-text
      > -->
      <!-- <v-card-text v-if="showNotFound" class="pt-1"
        >Hairdreams specialist didn’t contact you?
        <v-btn
          color="blue"
          text
          small
          :to="{
            name: 'ConsumerContact',
            params: { consumerId: $route.params.consumerId },
            query: { source: '100_miles' }
          }"
          class="text-capitalize pl-0"
          >Fill out this form.</v-btn
        ></v-card-text
      > -->
      <!-- note: to found -->
      <template v-if="!showNotFound">
        <v-card-text class="text-center">
          It seems that there are no salons in the
          {{ consumer.chosenDistance || 25 }}mi radius from
          {{ consumer.zip }} that offer Low Maintanance Hairdreams extensions
        </v-card-text>

        <v-list-item three-line>
          <v-list-item-content class="mx-auto">
            <!-- note: find more salons card -->
            <FindMoreSalons
              :consumer="consumer"
              @found="onFound"
              @not-found="showNotFound = true"
            />

            <!-- note: contact card -->
            <v-card
              elevation="2"
              width="250"
              color="#eee"
              class="rounded-lg my-3 mt-2 py-3"
            >
              <v-card-text
                class="text-overline d-flex justify-center text-center pt-2"
              >
                I don't want to travel that far,<br />
                contact me for alternatives
              </v-card-text>
              <v-card-actions class="d-flex justify-center">
                <v-btn
                  min-width="150"
                  rounded
                  outlined
                  dark
                  color="black"
                  @click="onContactMe"
                >
                  Contact me
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-row>
        <v-col class="d-flex">
          <v-btn
            class="mx-auto"
            text
            link
            plain
            color="primary"
            :to="{ name: 'ExamplePages' }"
            >Back to previous page</v-btn
          >
        </v-col>
      </v-row> -->
      </template>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { ContactMessageSources } from '@/config/enums'
  import FindMoreSalons from '@/components/FindMoreSalons'

  export default {
    components: {
      FindMoreSalons
    },
    data() {
      return {
        showNotFound: false
      }
    },
    computed: {
      consumer() {
        return this.$store.state.consumers.consumer
      }
    },
    methods: {
      ...mapActions(['fetchConsumer']),
      onFound() {
        this.$router.push({
          name: 'GreatNews',
          params: {
            consumerId: this.consumer._id,
            salonId: this.consumer.salonsNear[0].salon._id
          }
        })
      },
      onContactMe() {
        this.$router.push({
          name: 'ConsumerContact',
          params: { consumerId: this.consumer._id },
          query: { source: ContactMessageSources.no_salon_found }
        })
      }
    },
    async created() {
      await this.fetchConsumer(this.$route.params.consumerId)

      if (!this.$store.state.consumers.consumer) {
        this.$router.push({ name: 'NotFound' })
      }

      this.showNotFound = this.consumer
        ? this.consumer.chosenDistance >= 100
        : false
    }
  }
</script>
