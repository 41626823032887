import SimpleModal from '@/components/user/SimpleModal'
import Vue from 'vue'
import cookieconsent from 'vue-cookieconsent-component'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import App from './App.vue'
import './assets/css/main.css'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import {
  ampmTime,
  decimalNumber,
  dollar,
  localeDate,
  miles,
  shortSystem,
  titleCase
} from './utils/filters'

import JsonCSV from 'vue-json-csv'
import vuescroll from 'vuescroll/dist/vuescroll-native'
import 'vuescroll/dist/vuescroll.css'

Vue.component('vue-google-autocomplete', VueGoogleAutocomplete)
Vue.component('downloadCsv', JsonCSV)
Vue.filter('miles', miles)
Vue.filter('decimalNumber', decimalNumber)
Vue.filter('shortSystem', shortSystem)
Vue.filter('localeDate', localeDate)
Vue.filter('titleCase', titleCase)
Vue.filter('ampm', ampmTime)
Vue.filter('dollar', dollar)
Vue.component('SimpleModal', SimpleModal)
Vue.component('VuePhoneNumberInput', VuePhoneNumberInput)
Vue.component('CookieConsent', cookieconsent)

Vue.use(vuescroll)

import VueHtmlToPaper from 'vue-html-to-paper'
const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title // override the window title
}

Vue.use(VueHtmlToPaper, options)

import BusinessHours from './components/business-hours/components/BusinessHours'

Vue.component('BusinessHours', BusinessHours)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created() {
    // 1. get user from LS
    const userString = localStorage.getItem('user')

    if (userString) {
      const userData = JSON.parse(userString)

      // 2. commit vuex action
      this.$store.commit('userSet', userData)
    }
  },
  render: h => h(App)
}).$mount('#app')
