<template>
  <v-container v-if="!$store.state.salons.loading">
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="400" class="mx-auto mt-5">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title>Set a new password</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="text-center"
        >If you use this Hairdreams account on more devices. you will have to
        log in using your new password.</v-card-text
      >
      <v-card-text class="pa-5 pt-0">
        <v-form>
          <v-text-field
            :rules="[rules.required, rules.email]"
            disabled
            label="Salon Email on the account"
            :value="email"
            prepend-icon="mdi-email"
          />
          <v-text-field
            :rules="[rules.required, rules.minLength]"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            label="New Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            :rules="[rules.required, rules.minLength]"
            v-model="passwordConfirm"
            :type="showPasswordConfirm ? 'text' : 'password'"
            label="Confirm Password"
            prepend-icon="mdi-lock"
            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
          />

          <v-card-actions>
            <v-btn
              block
              color="user_primary"
              class="white--text"
              rounded
              :disabled="disableBtn"
              @click="handleSetPassword"
              :loading="$store.state.auth.loading"
            >
              Set password</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    data() {
      return {
        password: '',
        passwordConfirm: '',
        rules: {
          required: value => !!value || 'Required.',
          minLength: value => value.length >= 6 || 'Min 6 characters',
          email: value => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },
        showPassword: false,
        showPasswordConfirm: false,
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red'
      }
    },
    computed: {
      email() {
        return this.$store.state?.salons?.selectedSalon?.email
      },
      disableBtn() {
        return !this.email || !this.password
      }
    },
    methods: {
      ...mapActions(['fetchSalon', 'changePassword']),
      async handleSetPassword() {
        if (this.password !== this.passwordConfirm) {
          this.errorMessage = 'Your password and confirm password do not match.'
          this.snackbar = true
          return
        }

        const userData = {
          email: this.email,
          password: this.password,
          passwordConfirm: this.passwordConfirm,
          salonId: this.$route.params.salonId
        }

        await this.changePassword(userData)

        if (!this.$store.state.auth.error) {
          if (this.$route.query.from) {
            this.$router.replace({
              name: 'LoginSalon',
              params: { salonId: this.$route.params.salonId },
              query: { from: this.$route.query.from }
            })
          }
        } else {
          this.errorMessage = this.$store.state.auth.error
          this.snackbar = true
        }
      }
    },
    async mounted() {
      await this.fetchSalon(this.$route.params.salonId)
    }
  }
</script>
