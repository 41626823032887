<template>
  <v-container class="pt-0">
    <v-card-text class="breakword title"
      >Would you like extra coverage and volume on top with
      extensions?</v-card-text
    >
    <v-card-text class="pt-0">
      <v-row class="justify-center">
        <v-col cols="12" class="d-flex justify-center"
          ><v-btn
            class="text-none"
            depressed
            width="100%"
            :color="answer === 'a' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'a'"
            @click="handleSelect('a')"
            >Yes</v-btn
          ></v-col
        >
        <v-col cols="12" class="d-flex justify-center pt-0"
          ><v-btn
            class="text-none"
            depressed
            width="100%"
            :color="answer === 'b' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'b'"
            @click="handleSelect('b')"
            >No</v-btn
          ></v-col
        >
      </v-row></v-card-text
    >
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        screen: 'extraCoverage',
        question: 'extraCoverage',
        answer: ''
      }
    },
    computed: {
      nextScreen() {
        if (this.$route.query['systemPickedBy'] === 'sf')
          return 'wearExtensionsBefore'
        else return 'consumerInfo'
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false) {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          goNext
        })
      },
      handleSelect(answer) {
        this.answer = answer

        this.emitSelect(true)
      }
    },
    created() {
      this.answer = this.$route.query[this.question] || ''
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped></style>
