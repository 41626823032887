<template>
  <v-carousel
    width="100%"
    height="90%"
    v-model="model"
    light
    :show-arrows="reviews.length > 1"
    :hide-delimiters="reviews.length <= 1"
    hide-delimiter-background
    show-arrows-on-hover
    :next-icon="detectXS() ? false : '$next'"
    :prev-icon="detectXS() ? false : '$prev'"
    class="carousel-wrapp"
  >
    <v-carousel-item
      v-for="review in reviews"
      :key="review.content"
      width="100%"
    >
      <v-sheet height="100%" width="100%" tile>
        <v-row
          class="fill-height carousel"
          :style="{ height: customHeight }"
          align="center"
          justify="center"
          width="100%"
        >
          <!-- fb -->
          <div
            width="100%"
            :class="detectXS() ? 'xs-width fb-review' : 'fb-review'"
            v-if="review.reviewType === ReviewTypes.FB"
            v-html="decodedContent(review)"
          ></div>
          <!-- other -->
          <div v-else class="custom-review">
            <CustomReview
              @changeHeight="handleHeight"
              :name="review.name"
              :date="review.date"
              :reviewType="review.reviewType"
              :content="review.content"
            />
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  import { ReviewTypes } from '@/config/enums'
  import CustomReview from '@/components/CustomReview'
  import { detectXS } from '@/utils/helpers'
  import { decode } from 'html-entities'

  export default {
    components: {
      CustomReview
    },
    props: {
      reviews: {
        type: Array
      }
    },
    data: () => ({
      customHeight: '100%',
      ReviewTypes,
      detectXS,
      model: 0
    }),

    methods: {
      decodedContent(review) {
        return decode(review.content)
      },
      handleHeight(value) {
        this.customHeight = value
      }
    }
  }
</script>

<style scoped>
  .carousel-wrapp {
    width: 100%;
  }
  .carousel {
    width: 100%;
    background: #8ba3fd21;
    min-height: 16rem;
    overflow-y: auto;
    margin: 0;
    margin-bottom: 45px;
  }

  .xs-width iframe {
    width: 100%;
    min-height: 400px;
  }
  .custom-review,
  .fb-review {
    width: 80%;
  }

  @media only screen and (max-width: 600px) {
    .custom-review,
    .fb-review {
      width: 85%;
    }
  }
</style>
