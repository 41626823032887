<template>
  <v-data-table
    dense
    :headers="headers"
    :items="messages"
    item-key="consumerId"
    sort-by="createdAt"
    sort-desc
    class="elevation-5"
    :loading="$store.state.messages.loading"
    :item-class="itemRowBackground"
  >
    <template v-slot:top>
      <v-snackbar
        centered
        top
        v-model="snackbar"
        :color="snackbarColor"
        :timeout="snackbarTimeout"
        >{{ notification }}</v-snackbar
      >
      <v-toolbar flat>
        <v-toolbar-title>Consumers: Other Inquires</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

        <v-spacer></v-spacer>

        <!-- export -->
        <v-btn class="mx-5" small>
          <download-csv :data="exportData" name="other_consumer_inquires.csv">
            <v-icon class="mx-1">mdi-download</v-icon>
            Export CSV
          </download-csv></v-btn
        >
      </v-toolbar>

      <v-row class="py-2 pr-5">
        <!-- search by date -->
        <DateFilter @change="handleDateChange" />
        <!-- filter by archive -->
        <v-switch v-model="showArchived" label="Show archived" />
      </v-row>
    </template>

    <!-- custom filters -->
    <template v-slot:body.prepend>
      <tr>
        <td v-for="header in headers" :key="header.value">
          <FilterField
            v-if="header.toFilter"
            :type="header.filterType"
            :options="header.filterOptions"
            @change="value => (filters[header.value] = value)"
            :value="filters[header.value]"
          />
          <span v-else />
        </td>
      </tr>
    </template>

    <template v-slot:item.createdAt="{ item }">
      {{ item.createdAt | localeDate(true) }}
    </template>

    <template v-slot:item.sendPromotion="{ item }">
      <v-chip
        small
        dark
        :color="item.sendPromotion === 'yes' ? 'green' : 'red'"
        >{{ item.sendPromotion }}</v-chip
      >
    </template>

    <template v-slot:item.system="{ item }">
      <template v-if="!item.system"></template>
      <v-chip v-else x-small class="ma-2" color="pink" label text-color="white"
        ><v-icon x-small left> mdi-label </v-icon>{{ item.system }}</v-chip
      >
    </template>

    <template v-slot:item.archiveBtn="{ item }">
      <v-btn
        small
        plain
        v-if="!item.archiveBtn"
        @click="handleArchiveMessage(item, true)"
        ><v-icon>mdi-archive-arrow-down</v-icon></v-btn
      >
      <v-btn plain small v-else @click="handleArchiveMessage(item, false)"
        ><v-icon>mdi-archive-arrow-up-outline</v-icon></v-btn
      >
    </template>

    <template v-slot:item.archive="{ item }">
      <v-chip small dark :color="item.archive === 'yes' ? 'blue' : 'grey'">{{
        item.archive
      }}</v-chip>
    </template>
  </v-data-table>
</template>
<script>
  import { mapActions } from 'vuex'
  import {
    getConsumersOtherInqTableInfo,
    getFilteredByDate
  } from '@/config/tables.helper'
  import DateFilter from '@/components/DateFilter.vue'
  import FilterField from '@/components/FilterField.vue'

  export default {
    components: { DateFilter, FilterField },
    data: () => ({
      showArchived: false,
      filters: getConsumersOtherInqTableInfo(this).filters,
      headers: [],
      snackbar: false,
      snackbarTimeout: 2000,
      snackbarColor: 'blue',
      notification: ''
    }),
    computed: {
      messages() {
        // filter date created at
        let messages = getFilteredByDate(
          this.$store.state.messages.c_h,
          this.filters.startDate,
          this.filters.endDate
        )
        if (!this.showArchived)
          messages = messages.filter(message => !message.archive)

        return messages.map(message => ({
          id: message._id,
          archiveBtn: message.archive,
          archive: message.archive ? 'yes' : 'no',
          consumerId: message.consumer._id,
          email: message.consumer.email,
          zip: message.consumer.zip,
          system: message.consumer.system,
          body: message.body,
          consumer: `${message?.consumer?.firstName} ${message?.consumer?.lastName}`,
          sendPromotion: message.consumer.sendPromotion ? 'yes' : 'no',
          source: message.source,
          topic: message.topic,
          createdAt: message.createdAt
        }))
      },
      exportData() {
        return this.messages.map(item => {
          let data = {}
          getConsumersOtherInqTableInfo(this).headers.forEach(h => {
            data[h.text] = item[h.value]
          })
          return data
        })
      }
    },
    methods: {
      ...mapActions(['fetchCHMessages', 'editCHMessage']),
      itemRowBackground(item) {
        if (item.archive) {
          return 'bcc-grey'
        }
      },
      async handleArchiveMessage(message, toArchive) {
        await this.editCHMessage({ id: message.id, archive: toArchive })
      },
      handleDateChange(dates) {
        this.filters.startDate = dates.startDate
        this.filters.endDate = dates.endDate
      }
    },
    async created() {
      this.headers = getConsumersOtherInqTableInfo(this).headers
      await this.fetchCHMessages()
      if (!this.$store.state.messages.error) {
        this.notification = `Messages fetched.`
        this.snackbar = true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bcc-grey {
    background: grey !important;
  }
</style>
