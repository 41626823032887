<template>
  <v-container>
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="400" class="mx-auto mt-5">
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>Forgot Password</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="text-center">
        Don't have an account?
        <v-btn
          plain
          link
          class="text-capitalize pa-0 ma-0"
          color="#19b3cc"
          @click="handleSignUp"
          >Sign Up</v-btn
        >
      </v-card-text>

      <v-card-text class="pa-5 pt-0 d-flex flex-column">
        <v-text-field
          prepend-icon="mdi-email"
          :rules="[rules.required, rules.email]"
          label="Email"
          v-model="email"
          class="pt-0 mt-0"
        />

        <v-card-actions>
          <v-btn
            block
            color="user_primary"
            class="white--text"
            rounded
            :disabled="disableBtn"
            @click="handleForgotPassword"
            :loading="this.$store.state.auth.loading"
          >
            Send Code</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    data() {
      return {
        email: '',
        rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red'
      }
    },
    computed: {
      disableBtn() {
        return !this.email
      }
    },
    methods: {
      ...mapActions(['forgotPasswordStylistsAccount']),
      handleSignUp() {
        this.$router.push({
          name: 'stylist-signup',
          query: this.$route.query
        })
      },

      async handleForgotPassword() {
        await this.forgotPasswordStylistsAccount({
          email: this.email
        })
        if (!this.$store.state.auth.error) {
          this.$router.replace({
            name: 'stylist-validate-forgot-password-code',
            query: this.$route.query
          })
        } else {
          this.errorMessage = this.$store.state.auth.error
          this.snackbarColor = 'red'
          this.snackbar = true
        }
      }
    }
  }
</script>
