<template>
  <div>
    <v-img
      height="250"
      lazy-src="@/assets/userType-header.webp"
      src="@/assets/userType-header.jpg"
    ></v-img>
    <v-container class="pt-0">
      <v-row class="justify-center">
        <v-col cols="12" class="ml-5 pl-5">
          <v-card-title class="pb-2">Client</v-card-title>
          <v-card-text class="pb-0"
            >To learn more about our systems, find a salon or message a
            Hairdreams specialist, choose your preference</v-card-text
          >
        </v-col>
        <v-col class="d-flex justify-center pt-0" cols="12"
          ><v-btn
            depressed
            class="text-none"
            :color="answer === 'extensions' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'extensions'"
            @click="handleSelect('extensions')"
            width="80%"
            >I want to wear hair extensions</v-btn
          ></v-col
        ><v-col class="d-flex justify-center pt-0" cols="12"
          ><v-btn
            depressed
            class="text-none"
            :color="answer === 'hairloss' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'hairloss'"
            @click="handleSelect('hairloss', SystemOptions.MICROLINES)"
            width="80%"
            >I need a hairloss solution</v-btn
          ></v-col
        >
        <v-col cols="12" class="ml-5 pl-5">
          <v-card-title class="pb-2 pt-1">Salon Pro</v-card-title>
          <v-card-text class="pb-0"
            >Inquire about Hairdreams system</v-card-text
          >
        </v-col>
        <!-- stylists v1 -->
        <!-- <v-col class="d-flex justify-center pt-0" cols="12"
          ><v-btn
            depressed
            class="text-none"
            :color="answer === 'pro' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'pro'"
            @click="handleSelect('pro')"
            width="80%"
            >Learn more</v-btn
          ></v-col
        > -->
        <!-- stylists v2 -->
        <v-col class="d-flex justify-center pt-0" cols="12"
          ><v-btn
            depressed
            class="text-none"
            color="user_btn"
            @click="handleStylistSelect"
            width="80%"
            >Learn more</v-btn
          ></v-col
        >
      </v-row></v-container
    >
  </div>
</template>

<script>
  import { SystemOptions } from '@/config/enums'

  export default {
    data() {
      return {
        SystemOptions,
        screen: 'userType',
        question: 'userType',
        answer: ''
      }
    },
    computed: {
      nextScreen() {
        if (this.answer === 'extensions') return 'extensions'
        else if (this.answer === 'hairloss') return 'systemInfo'
        else return 'pro'
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false, systemInfo = '') {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          goNext,
          systemInfo
        })
      },
      handleSelect(answer, systemInfo = '') {
        this.answer = answer
        this.emitSelect(true, systemInfo)
      },
      handleStylistSelect() {
        // go to stylist project
        this.$router.push({
          name: 'stylist-primary-system',
          query: { ...this.$route.query, source: this.$route.query.source }
        })
      }
    },
    created() {
      this.answer = this.$route.query[this.question] || ''
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped></style>
