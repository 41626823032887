import { render, staticRenderFns } from "./ConsumersAnalytics.vue?vue&type=template&id=2f948a2e&scoped=true&"
import script from "./ConsumersAnalytics.vue?vue&type=script&lang=js&"
export * from "./ConsumersAnalytics.vue?vue&type=script&lang=js&"
import style0 from "./ConsumersAnalytics.vue?vue&type=style&index=0&id=2f948a2e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f948a2e",
  null
  
)

export default component.exports