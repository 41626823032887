<template>
  <div class="d-flex justify-center align-center">
    <div class="item">
      <span>{{ active }}</span> Salons - Active
    </div>
    <div class="item">
      <span> {{ termsAccepted }} </span> Salons - Terms accepted
    </div>
    <div class="item">
      <span> {{ termsNotAccepted }} </span> Salons - Terms not accepted
    </div>
    <div class="item">
      <span> {{ sms }} </span> Salons - SMS
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      sms() {
        return this.$store.state.analytics?.salonsSmsPhones?.['Yes'] || 0
      },
      active() {
        return this.$store.state.analytics?.salonsActive?.['active'] || 0
      },
      termsAccepted() {
        return this.$store.state.analytics?.salonsActive?.['termsAccepted'] || 0
      },
      termsNotAccepted() {
        return (
          this.$store.state.analytics?.salonsActive?.['termsNotAccepted'] || 0
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    padding: 20px 35px;
    text-align: center;
  }
  .item span {
    line-height: 50px;
    font-size: 50px;
    font-weight: 700;
  }
</style>
