<template>
  <v-container fluid>
    <v-data-table
      dense
      :headers="headers"
      :items="stylists"
      item-key="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 ml-3"
      @page-count="pageCount = $event"
      :server-items-length="totalStylists"
      :loading="isLoading"
      @update:options="handleUpdateOptions"
    >
      <template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification }}</v-snackbar
        >
        <v-toolbar flat>
          <v-toolbar-title>Stylists</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="title"
            >Count: {{ $store.state.stylists.totalCount }}</span
          >
          <v-spacer></v-spacer>
          <v-btn @click="handleExportData" :loading="isExportingData">
            <v-icon class="mx-1">mdi-download</v-icon>
            Export CSV
          </v-btn>
          <!-- note: edit dialog -->
          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Stylist</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <input v-model="editedItem.id" type="hidden" />
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        hide-details
                        v-model="editedItem.memo"
                        label="Memo"
                        auto-grow
                        outlined
                        rows="3"
                        row-height="25"
                        shaped
                        filled
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.firstName"
                        label="First Name"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.lastName"
                        hide-details
                        label="Last Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.email"
                        hide-details
                        label="E-mail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.phone"
                        hide-details
                        label="Phone"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.salonZip"
                        hide-details
                        label="Salon Zip"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.salon"
                        hide-details
                        label="Salon"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.salonAddress"
                        hide-details
                        label="Salon Address"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        :items="[...Object.values(SystemOptions), 'Other']"
                        v-model="editedItem.system"
                        hide-details
                        label="System"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        rows="2"
                        filled
                        v-model="editedItem.message"
                        hide-details
                        label="Message"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.website"
                        hide-details
                        label="Website"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        :items="['Yes', 'No']"
                        v-model="editedItem.doHair"
                        hide-details
                        label="Hair Extensions"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.brands"
                        hide-details
                        label="Brands"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        v-model="editedItem.sendPromotion"
                        hide-details
                        label="Promotion Emails"
                        :items="['Yes', 'No']"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.source"
                        hide-details
                        label="Source"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="handleSave">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <!-- search by date -->
        <DateFilter @change="handleDateChange" />
      </template>
      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="handleFilterChange($event, header)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td>
        </tr>
      </template>
      <!-- for edit action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>

        <!-- <v-icon v-if="$store.getters.isUserFull" small @click="deleteItem(item)">
    mdi-delete
  </v-icon> -->
      </template>
      <!-- system -->
      <template v-slot:item.system="{ item }">
        <v-chip x-small class="ma-2" color="pink" label text-color="white"
          ><v-icon x-small left> mdi-label </v-icon>{{ item.system }}</v-chip
        >
      </template>
      <!-- promotion -->
      <template v-slot:item.sendPromotion="{ item }">
        <v-chip small :color="item.sendPromotion === 'Yes' ? 'green' : 'red'">{{
          item.sendPromotion
        }}</v-chip>
      </template>
      <!-- do hair -->
      <template v-slot:item.doHair="{ item }">
        <template v-if="!item.doHair"></template>
        <v-chip v-else small :color="item.doHair === 'yes' ? 'green' : 'red'">{{
          item.doHair
        }}</v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | localeDate(true) }}
      </template>
    </v-data-table>
    <!-- NOTE: PAGINATION -->
    <div class="text-center pt-2" v-if="stylists && stylists.length > 0">
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getStylistsTableInfo } from '@/config/tables.helper'
  import { saveAs } from 'file-saver'
  import DateFilter from '@/components/DateFilter.vue'
  import FilterField from '@/components/FilterField.vue'
  import { SystemOptions } from '@/config/enums'

  export default {
    data() {
      return {
        // pagintaion
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        // sorting
        sortBy: 'createdAt',
        sortDesc: true,
        // search
        search: {},
        // other
        isExportingData: false,
        filters: getStylistsTableInfo(this).filters,
        headers: [],
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        notification: '',
        isLoading: false,
        dialog: false,
        editedIndex: -1,
        editedItem: getStylistsTableInfo(this).item,
        defaultItem: getStylistsTableInfo(this).item,
        SystemOptions
      }
    },
    computed: {
      stylists() {
        return this.$store.state.stylists.list
      },
      totalStylists() {
        return this.$store.state.stylists.totalCount
      }
    },
    methods: {
      ...mapActions([
        'getStylistsCsv',
        'editStylist',
        'fetchStylistsTableData'
      ]),
      async handleUpdateOptions(options) {
        this.sortBy = options.sortBy[0] ? options.sortBy[0] : 'createdAt'
        this.sortDesc = options.sortDesc
        await this.updateData()
      },
      async updateData() {
        this.isLoading = true
        await this.fetchStylistsTableData({
          page: this.page,
          limit: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          search: this.search
        })
        this.pageCount = Math.ceil(
          this.$store.state.stylists.totalCount / this.itemsPerPage
        )
        this.isLoading = false
      },
      async handleDateChange(dates) {
        this.page = 1
        this.search['startDate'] = dates.startDate
        this.search['endDate'] = dates.endDate
        await this.updateData()
      },
      async handleFilterChange(value, header) {
        this.page = 1
        this.search[header.value] = value
        await this.updateData()
      },
      async handleExportData() {
        this.isExportingData = true
        let transform = {}
        getStylistsTableInfo(this).headers.forEach(h => {
          if (h.csv !== false) {
            transform[h.text] = h.value
          }
        })
        const data = await this.getStylistsCsv({ transform })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'stylists.csv')
        this.isExportingData = false
      },
      // CRUD
      editItem(item) {
        this.editedIndex = this.stylists.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async handleSave() {
        if (this.editedIndex > -1) {
          Object.assign(this.stylists[this.editedIndex], this.editedItem)
          // dispatch
          await this.editStylist({
            ...this.editedItem,
            sendPromotion: this.editedItem.sendPromotion === 'Yes'
          })
          if (!this.$store.state.stylists.error) {
            this.snackbarColor = 'green'
            this.notification = 'Stylist saved.'
            this.snackbar = true
          }
        }
        this.close()
      }
    },
    watch: {
      async page(newValue, oldValue) {
        await this.updateData()
      }
    },
    created() {
      this.headers = getStylistsTableInfo(this).headers
    },
    components: { DateFilter, FilterField }
  }
</script>
