<template>
  <v-card v-if="!isLoading" class="elevation-0 mx-auto" max-width="550">
    <!-- title: system -->
    <Title :text="source.title" />
    <!-- content -->

    <v-card-text
      class="text-center mt-5 pb-0 text-body-1 font-weight-bold"
      color="#a5a5a5"
      >Part 1: Prerequisite class #CT101</v-card-text
    >
    <v-card-text class="text-center text-h6 font-weight-bold pt-0 pb-1"
      >Topper & Extension Cutting</v-card-text
    >
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <ImageBox :options="source.items[1]" @action="handleImageAction" />
        </v-col>
        <v-col cols="12" md="6" class="">
          <ImageBox :options="source.items[2]" @action="handleImageAction" />
        </v-col>
      </v-row>
      <TextBox
        :isCanada="isCanada"
        :text="{
          ...source.textBox1,
          paragraphHTML: settings[StylistsCertifications.ML_CUTTING]?.paragraph1
        }"
        :promo-price="settings[StylistsCertifications.ML_CUTTING]?.promoPrice1"
        :list-price="settings[StylistsCertifications.ML_CUTTING]?.listPrice1"
      />
    </v-card-text>
    <v-divider class="my-5"></v-divider>

    <v-card-text
      class="text-center mt-5 pb-0 text-body-1 font-weight-bold"
      color="#a5a5a5"
      >Part 2: Main class #ML101</v-card-text
    >
    <v-card-text class="text-center text-h6 font-weight-bold pt-0 pb-1"
      >MicroLines Start Kit and Certification</v-card-text
    >
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <ImageBox :options="source.items[3]" @action="handleImageAction" />
        </v-col>
        <v-col cols="12" md="6" class="">
          <ImageBox :options="source.items[4]" @action="handleImageAction" />
          <ImageBox
            :options="source.items[5]"
            class="mt-4"
            @action="handleImageAction"
          />
        </v-col>
      </v-row>

      <TextBox
        :isCanada="isCanada"
        :text="{
          ...source.textBox2,
          paragraphHTML:
            settings[StylistsCertifications.ML_CUTTING]?.paragraph2,
          buttonText: settings[StylistsCertifications.ML_CUTTING]?.buttonText,
          buttonLink: settings[StylistsCertifications.ML_CUTTING]?.buttonLink
        }"
        :promo-price="settings[StylistsCertifications.ML_CUTTING]?.promoPrice2"
        :list-price="settings[StylistsCertifications.ML_CUTTING]?.listPrice2"
      />
    </v-card-text>

    <v-divider class="my-5"></v-divider>

    <!-- apple style system accordion -->
    <v-expansion-panels flat>
      <v-expansion-panel>
        <v-expansion-panel-header
          class="text-h5 font-weight-bold breakword color-blue px-0"
        >
          <v-spacer></v-spacer>
          <span class="color-blue text-center font-weight-medium"
            >Why MicroLines?</span
          >
          <v-spacer></v-spacer>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="accordion">
          <MicrolinesView
            :source="stylistSources[SystemOptions.MICROLINES]"
            :action="false"
            :certPage="StylistsCertifications.ML_CUTTING"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <CertPopUp :show="showPopUp" :options="options" @close="handleClosePopUp" />
  </v-card>
</template>

<script>
  import { StylistsCertifications, SystemOptions } from '@/config/enums'
  import { mapActions } from 'vuex'
  import ImageBox from '../ImageBox.vue'
  import TextBox from '../TextBox.vue'
  import Title from '../Title.vue'
  import MicrolinesView from '../stylists/MicrolinesView.vue'
  import stylistSources from '../stylists/source-stylists'
  import CertPopUp from './CertPopUp.vue'

  const defaultOptions = {
    img: '',
    hasAction: false,
    video: '',
    popUpImg: '',
    text: '',
    popUpBtnText: ''
  }

  export default {
    components: {
      ImageBox,
      TextBox,
      Title,
      CertPopUp,
      MicrolinesView
    },
    props: {
      source: {
        type: Object
      },
      isCanada: {
        type: Boolean
      }
    },
    data() {
      return {
        isLoading: true,
        StylistsCertifications,
        showPopUp: false,
        options: defaultOptions,
        stylistSources,
        SystemOptions
      }
    },
    computed: {
      settings() {
        if (this.isCanada) {
          return this.$store.getters.certificateSettingsCanada.settings
        }
        return this.$store.getters.certificateSettings.settings
      }
    },
    methods: {
      ...mapActions(['fetchSettings', 'editSettings']),
      handleImageAction(options) {
        this.options = options
        this.showPopUp = true
      },
      handleClosePopUp() {
        this.options = defaultOptions
        this.showPopUp = false
      },
      handleButtonClick() {
        this.$emit('btn-click')
      }
    },
    async created() {
      await this.fetchSettings()
      this.isLoading = false
    }
  }
</script>

<style scoped>
  .color-blue {
    color: #2179d2;
  }
  .accordion {
    margin-left: -48px;
    margin-right: -48px;
  }
</style>
