<template>
  <div>
    <div class="top">
      <v-img
        height="250"
        class="ma-1"
        :lazy-src="
          salon.imgUrl ||
          'https://storage.googleapis.com/hairdreams-v2/default-salon-img.jpg'
        "
        :src="
          salon.imgUrl ||
          'https://storage.googleapis.com/hairdreams-v2/default-salon-img.jpg'
        "
      ></v-img>

      <v-card elevation="24" class="ma-1 card-wrapper">
        <v-card-title class="pb-0 main-title">{{ salon.name }}</v-card-title>
        <v-row dense class="d-flex mobile-row">
          <v-col
            class="d-flex pl-2 justify-start align-center mobile-flex-50"
            v-if="bestMatch"
            cols="12"
            sm="8"
          >
            <v-icon left color="red"> mdi-heart </v-icon>
            <span class="text-red text-sm-h6 font-weight-bold fixed-height"
              >BEST MATCH</span
            >
          </v-col>
          <v-col
            class="d-flex align-center pr-2 justify-end mobile-flex-50"
            cols="12"
            sm="4"
          >
            <div class="text-subtitle-1 text-sm-h5 fixed-height">
              {{ (distance * 0.000621371192).toFixed(0) }}
              mi away
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <v-container class="px-0 px-sm-3">
      <!-- note: buttons -->
      <v-row class="d-flex justify-center pt-0 pt-sm-3 mt-3 px-3 px-sm-0">
        <!-- save for later -->
        <v-col class="px-0 px-sm-3 d-flex justify-start" cols="4" sm="6">
          <v-btn
            rounded
            outlined
            dark
            color="indigo"
            @click="handleSaveForLater"
            :loading="isSendingSave"
            :small="detect399()"
          >
            {{ detectXS() ? 'Save' : 'Save for later' }}
          </v-btn></v-col
        >

        <!-- call -->
        <v-col
          v-if="isMobile"
          class="px-0 px-sm-3 d-flex justify-center"
          cols="4"
          sm="3"
        >
          <v-btn
            @click="onCallMobile"
            rounded
            color="user_primary"
            dark
            class="mr-5"
            :small="detect399()"
          >
            <v-icon left dark> mdi-phone </v-icon>
            Call
          </v-btn>
        </v-col>
        <v-col v-else class="d-flex justify-center justify-sm-end" sm="3">
          <v-btn
            @click="onCallDesktop"
            @mouseleave="showPhone = false"
            rounded
            color="user_primary"
            dark
            class="mr-1"
          >
            <span v-if="showPhone">{{ salon.phone }}</span>
            <span v-else> <v-icon left dark> mdi-phone </v-icon> Call</span>
          </v-btn>
        </v-col>
        <!-- message -->
        <v-col
          v-if="!isCallOnly"
          class="px-0 px-sm-3 d-flex justify-end mobile-flex"
          cols="4"
          sm="3"
        >
          <v-btn
            rounded
            color="user_primary"
            dark
            @click="handleMessage('1')"
            :small="detect399()"
            :loading="isSendingMessage1"
          >
            <template v-if="isOpenSalon">
              <v-icon color="green" left dark>
                {{ isMobile ? 'mdi-circle-medium' : 'mdi-circle' }}
              </v-icon></template
            >
            Message</v-btn
          >
        </v-col>
      </v-row>

      <!-- note: Highlights -->
      <template v-if="salon.highlights.length > 0">
        <v-card-title class="pb-1 pl-3">Highlights</v-card-title>
        <HighlightsSlider
          v-if="salon.additionalHighlight"
          :highlights="[salon.additionalHighlight, ...salon.highlights]"
        />
        <HighlightsSlider v-else :highlights="salon.highlights" />
      </template>

      <!-- note: Salon pricing -->
      <template v-if="!isCallOnly">
        <v-card-title class="pb-3 pl-3">Get a quote</v-card-title>
        <div class="wrapper">
          <v-card class="px-5 py-3 pt-0" elevation="0">
            <v-card-text class="pa-5">
              Message {{ salon.name }} to schedule your consultation and get a
              customized price quote.
            </v-card-text>
            <v-row class="align-center">
              <!-- highlights -->
              <v-col cols="12" sm="6" class="py-5">
                <v-card-text
                  class="text-blue py-0 font-weight-bold"
                  v-for="highlight in importantHighlights"
                  :key="highlight"
                  ><v-icon color="#2c58f9" class="pr-2" small>mdi-check</v-icon
                  >{{ highlight }}</v-card-text
                >
              </v-col>
              <!-- message button -->
              <v-col class="d-flex justify-end pr-5">
                <v-btn
                  rounded
                  color="user_primary"
                  dark
                  @click="handleMessage('2')"
                  :loading="isSendingMessage2"
                >
                  <template v-if="isOpenSalon">
                    <v-icon left dark color="green">
                      {{ isMobile ? 'mdi-circle-medium' : 'mdi-circle' }}
                    </v-icon>
                  </template>
                  Message</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </div>
      </template>
      <!-- note: Reviews -->
      <template v-if="showReviews">
        <v-card-title class="pb-3 pl-3">Salon Reviews</v-card-title>
        <div class="wrapper">
          <ReviewsCarousel :reviews="reviewsArray" />
        </div>
      </template>

      <!-- note: MAP -->
      <v-card-title class="pb-1 pl-3">Location</v-card-title>
      <v-card elevation="0" class="mx-1">
        <Map :salon="salon" class="map" />
      </v-card>

      <!-- note: Hours -->
      <ShowHours v-if="salon.appointmentOnly !== true" :hours="salon.hours" />
    </v-container>

    <!-- MODALS -->
    <Wrapper
      :showDialog="showMessageC_SDialog"
      :salonId="$route.params.salonId"
      :system="system"
      :salonName="salon.name"
      @close="() => (showMessageC_SDialog = false)"
    />
    <SimpleModal
      :showDialog="showSaveLinkDialog"
      :message="saveLinkMessage"
      @closeSimpleModal="() => (showSaveLinkDialog = false)"
    />
  </div>
</template>

<script>
  import ShowHours from '@/components/ShowHours'
  import HighlightsSlider from '@/components/user/HighlightsSlider.vue'
  import Map from '@/components/user/Map'
  import ReviewsCarousel from '@/components/user/ReviewsCarousel.vue'
  import {
    detect399,
    detectMob,
    detectXS,
    getHours,
    getTermsStatus
  } from '@/utils/helpers'

  import { CallFrom, CallTypes, HighlightOptions } from '@/config/enums'
  import Wrapper from '@/views/user/WF2/messages/Wrapper.vue'
  import { mapActions } from 'vuex'

  export default {
    components: {
      Map,
      HighlightsSlider,
      ReviewsCarousel,
      ShowHours,
      Wrapper
    },
    props: {
      salon: {
        type: Object,
        required: true
      },
      distance: {
        type: Number,
        default: 0
      },
      bestMatch: {
        type: Boolean,
        default: false
      },
      system: {
        type: String
      }
    },
    data() {
      return {
        HighlightOptions,
        detectXS,
        detect399,
        model: [],
        getHours,
        showPhone: false,
        isMobile: false,
        isSendingSave: false,
        isSendingMessage1: false,
        isSendingMessage2: false,
        // save link dialog
        showSaveLinkDialog: false,
        saveLinkMessage: '',
        // message c_s dialog
        showMessageC_SDialog: false
      }
    },
    computed: {
      termsAccepted() {
        return getTermsStatus(this.salon.terms) === 'accepted'
      },
      isCallOnly() {
        return this.salon.callOnly === true
      },
      isOpenSalon() {
        // todo:
        return true
      },
      importantHighlights() {
        return this.salon.highlights.filter(
          h =>
            h === HighlightOptions.FREE_CONSULTATION.text ||
            h === HighlightOptions.VIRTUAL_ESTIMATES.text
        )
      },
      reviewsArray() {
        const arr = []
        if (this.salon.review1.content) arr.push(this.salon.review1)
        if (this.salon.review2.content) arr.push(this.salon.review2)
        if (this.salon.review3.content) arr.push(this.salon.review3)
        return arr
      },
      showHighlights() {
        return this.salon.highlights.length > 0
      },
      showReviews() {
        return this.reviewsArray.length > 0
      }
    },
    methods: {
      ...mapActions(['saveForLater', 'call_c_s', 'fetchCount_c_s']),

      async handleMessage(buttonNum) {
        this['isSendingMessage' + buttonNum] = true
        await this.fetchCount_c_s({
          salonId: this.$route.params.salonId,
          consumerId: this.$route.params.consumerId
        })

        if (this.$store.state.messages?.c_s_Count > 0) {
          this['isSendingMessage' + buttonNum.toString()] = false
          this.$router.push({
            name: 'Communication',
            params: {
              salonId: this.$route.params.salonId,
              consumerId: this.$route.params.consumerId
            }
          })
        } else {
          this.showMessageC_SDialog = true
          this['isSendingMessage' + buttonNum.toString()] = false
        }
      },
      async onCallDesktop() {
        this.showPhone = true
        await this.call_c_s({
          salon: this.salon._id,
          consumer: this.$route.params.consumerId,
          type: CallTypes.C_S,
          from: CallFrom.C
        })
      },
      async onCallMobile() {
        await this.call_c_s({
          salon: this.salon._id,
          consumer: this.$route.params.consumerId,
          type: CallTypes.C_S,
          from: CallFrom.C
        })
        document.location.href = 'tel:' + this.salon.phone
      },
      async handleSaveForLater() {
        const data = {
          salon: this.salon._id,
          consumer: this.$route.params.consumerId
        }
        this.isSendingSave = true
        await this.saveForLater(data)
        if (!this.$store.state.consumers.error) {
          this.saveLinkMessage = 'Saved for later! Email sent!'
          this.showSaveLinkDialog = true
        }
        this.isSendingSave = false
      },
      closeMessageC_SDialog() {
        this.showMessageC_SDialog = false
      }
    },
    created() {
      this.isMobile = detectMob()
    }
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    // background-color: #444444;
    border-radius: 15px;
    overflow: hidden;
    border: 2px solid #d5d5d58a;
  }

  .text-blue {
    color: #2c58f9;
  }
  .text-red {
    color: red;
  }
  .call-now {
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .map {
    margin-bottom: 30px;
  }
  .top {
    position: relative;
    margin-bottom: 60px;
  }
  .card-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 80%;
    padding: 20px 30px;
    border-radius: 50px !important;
  }
  .main-title {
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 12px;
    text-align: center;
    justify-content: center;
    word-break: break-word;
  }
  @media only screen and (max-width: 600px) {
    .main-title {
      font-size: 18px;
      line-height: 18px;
      font-weight: bold;
      margin-bottom: 8px;
      text-align: center;
    }
    .card-wrapper {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 92%;
      padding: 15px;
    }
    .mobile-row {
      display: flex;
      flex-wrap: nowrap;
    }
    .mobile-flex-50 {
      flex: 45%;
    }
    // .v-application .elevation-3 {
    //   box-shadow: none !important;
    // }
    .v-application .ma-1 {
      margin: 0 !important;
    }
  }
</style>
