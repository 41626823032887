<template>
  <v-container class="py-0">
    <v-form class="form py-0" ref="form" v-model="valid" lazy-validation>
      <!-- <v-card-text v-if="isMicrolines" class="pt-0 px-0">
        Fill in your details & we'll send you the closest salons. You’ll also
        get the results on the next page.
      </v-card-text> -->
      <v-row>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            dense
            :rules="[rules.required]"
            required
            outlined
            label="First Name"
            v-model="consumer.firstName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" class="py-0 pt-md-3">
          <v-text-field
            dense
            :rules="[rules.required]"
            required
            outlined
            label="Last Name"
            v-model="consumer.lastName"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-0">
          <vue-google-autocomplete
            :classname="zipError ? 'zip-field zip-field--error' : 'zip-field'"
            types="postal_code"
            class="form-control"
            id="address"
            placeholder="Enter Zip code"
            @placechanged="setAddress"
            @no-results-found="setNoAddressFound"
            @inputChange="() => (address = '')"
            :country="['us', 'ca']"
            ref="zipInput"
          />
          <v-card-text class="caption py-0 pt-1 error-color" v-if="zipError">{{
            zipErrorMessage
          }}</v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-text-field
            dense
            :rules="[rules.required, rules.email]"
            required
            outlined
            label="Email"
            v-model="consumer.email"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="align-center">
        <v-col cols="12" class="pt-0">
          <VuePhoneNumberInput
            :rules="[rules.required]"
            required
            show-code-on-list
            @update="handleSmsPhoneUpdate"
            v-model="consumer.smsPhone.phoneNumber"
            no-example
            default-country-code="US"
            :only-countries="['US', 'CA']"
            :size="detectMob() ? 'sm' : 'lg'"
            color="#222"
            valid-color="#222"
            error-color="#ff5252"
            :error="phoneError"
            height="100"
            :no-flags="detectMob() ? true : false"
          />
          <v-card-text
            class="caption py-0 pt-1 error-color"
            v-if="phoneError"
            >{{ phoneErrorMessage }}</v-card-text
          >
        </v-col>
      </v-row>
      <v-row class="justify-center py-0 align-start pt-3">
        <v-col cols="12" class="py-0 pb-3 d-flex align-start">
          <v-checkbox
            class="checkbox pt-0 mt-0"
            hide-details
            v-model="consumer.sendPromotion"
            label=""
          />
          <v-card-text class="pa-0 checkbox body-1 grey-color"
            >I want to receive promotional emails
          </v-card-text>
        </v-col>
      </v-row>
      <!-- <v-row class="justify-center py-0 align-start">
        <v-col cols="12" class="py-0 pb-3 d-flex align-start">
          <v-checkbox
            class="checkbox pt-0 mt-0"
            :error="termsError"
            v-model="terms"
            :ripple="false"
          />

          <v-col class="pa-0" style="line-height: 14px">
            <v-card-text
              class="pa-0 pb-1 checkbox body-1"
              :class="termsErrorMessage ? 'red-color' : 'grey-color'"
              >I understand the Notice of Collection of Personal Information
            </v-card-text>
            <p v-if="termsErrorMessage" class="error-message">
              {{ termsErrorMessage }}
            </p>
            <span class="grey-color-light"
              >You are about to provide personal information to us. To learn
              more about what types of personal information we may collect from
              you, the purposes for such collection, and our business practices
              when it comes to selling or sharing such information, please
              review our
              <a
                href="https://iwanthairdreams.com/pages/notice-of-collection-of-personal-information"
                target="_blank"
                >Notice of Collection of Personal Information</a
              >
              and
              <a
                href="https://iwanthairdreams.com/policies/privacy-policy"
                target="_blank"
                >Privacy Policy</a
              ></span
            >
          </v-col>
        </v-col>
      </v-row> -->

      <v-row>
        <v-card-text
          style="line-height: 14px"
          class="grey-color-light pb-0 pt-1"
          >By clicking SHOW ME SALONS you are agreeing to provide personal
          information to us. To learn more about what types of personal
          information we may collect from you, the purposes for such collection,
          and our business practices when it comes to sharing such information,
          please review our
          <a
            href="https://iwanthairdreams.com/pages/notice-of-collection-of-personal-information"
            target="_blank"
            >Notice of Collection of Personal Information</a
          >
          and
          <a
            href="https://iwanthairdreams.com/policies/privacy-policy"
            target="_blank"
            >Privacy Policy</a
          ></v-card-text
        >
        <!-- <v-card-text
          style="line-height: 14px"
          class="grey-color-light pb-0 pt-1"
          >By clicking SHOW ME SALONS you are agreeing to the
          <a
            href="https://iwanthairdreams.com/pages/notice-of-collection-of-personal-information"
            target="_blank"
            >Notice of Collection of Personal Information</a
          >
          and
          <a
            href="https://iwanthairdreams.com/policies/privacy-policy"
            target="_blank"
            >Privacy Policy</a
          ></v-card-text
        > -->
      </v-row>
      <v-card-actions class="justify-center mt-5 pt-3 pb-0 px-0">
        <v-btn
          color="user_cta"
          dark
          rounded
          depressed
          @click="handleSend"
          width="100%"
          :loading="isSending"
          >Show me salons
        </v-btn></v-card-actions
      >
    </v-form>
  </v-container>
</template>

<script>
  import { ConsumerSources, SystemOptions } from '@/config/enums'
  import { detectMob } from '@/utils/helpers'
  import { mapActions } from 'vuex'
  import { getCookie } from '../../../utils/helpers'

  export default {
    data() {
      return {
        address: '',
        setAddressTimeout: null,
        rules: {
          required: value => !!value || 'This field is required.',
          email: v =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'Please enter valid e-mail address.'
        },
        valid: true,
        screen: 'consumerInfo',
        detectMob,
        isSending: false,
        terms: false,
        termsError: false,
        termsErrorMessage: '',
        phoneError: false,
        phoneErrorMessage: '',
        zipError: false,
        zipErrorMessage: '',
        consumer: {
          source: this.$route.query['source'] || 'salon_finder',
          system: this.$route.query['systemInfo'],
          systemPickedBy: this.$route.query['systemPickedBy'] || 'c',
          wearExtensionsBefore: this.$route.query['wearExtensionsBefore'] || '',
          wantAgain: this.$route.query['wantAgain'] || '',
          lifestyleExtensions: this.$route.query['lifestyleExtensions'] || '',
          firstName: '',
          lastName: '',
          smsPhone: { phoneNumber: '', formattedNumber: '', validated: false },
          zip: '',
          longitude: '',
          latitude: '',
          email: '',
          question1:
            this.$route.query['moreHair'] || this.$route.query['hairLongIs'],
          question2: this.$route.query['hairLongWant'],
          question3: this.$route.query['extraCoverage'],
          question4: this.$route.query['question4'],
          sendPromotion: true,
          utm_source:
            this.$route.query['utm_source'] ||
            getCookie('utm_source') ||
            getCookie('[utm_source]'),
          utm_medium:
            this.$route.query['utm_medium'] ||
            getCookie('utm_medium') ||
            getCookie('[utm_medium]'),
          utm_campaign:
            this.$route.query['utm_campaign'] ||
            getCookie('utm_campaign') ||
            getCookie('[utm_campaign]'),
          utm_term:
            this.$route.query['utm_term'] ||
            getCookie('utm_term') ||
            getCookie('[utm_term]'),
          utm_content:
            this.$route.query['utm_content'] ||
            getCookie('utm_content') ||
            getCookie('[utm_content]'),
          gclid:
            this.$route.query['gclid'] ||
            getCookie('gclid') ||
            getCookie('[gclid]'),
          fbclid:
            this.$route.query['fbclid'] ||
            getCookie('fbclid') ||
            getCookie('[fbclid]'),
          mscid:
            this.$route.query['mscid'] ||
            getCookie('mscid') ||
            getCookie('[mscid]')
        }
      }
    },
    computed: {
      phone() {
        return this.consumer.smsPhone.phoneNumber
      },
      vuexLog() {
        return this.$store.getters.log
      },
      isMicrolines() {
        return this.$route.query['systemInfo'] === SystemOptions.MICROLINES
      }
    },
    methods: {
      ...mapActions(['addConsumer', 'editLog', 'clearLog', 'addLog']),
      setAddress(address) {
        this.setAddressTimeout = setTimeout(() => {
          this.address = address
        }, 100)
      },
      setNoAddressFound() {
        this.address = ''
      },
      handleSmsPhoneUpdate(obj) {
        this.consumer.smsPhone.formattedNumber = obj.formattedNumber
      },
      async handleSend() {
        // 1a) form validation
        this.$refs.form.validate()

        // 1b) custom validation
        // if (!this.terms) {
        //   this.termsErrorMessage = 'Please accept to proceed.'
        //   this.termsError = true
        // }
        if (!this.phone) {
          this.phoneErrorMessage = 'This field is required.'
          this.phoneError = true
        }
        if (!this.consumer.zip) {
          this.zipErrorMessage =
            'Please enter a valid Zip Code and choose an option from the dropdown.'
          this.zipError = true
        }

        if (
          !this.valid ||
          !this.phone
          // ||!this.terms
        ) {
          return
        }

        // 2) save to db
        this.isSending = true

        // samo ako je pogledao video saljemo, inace ce ili da ostane true ako je stari consumer, ili default false ako je nov
        if (this.$route.query['playVideo'])
          this.consumer.playVideo = this.$route.query['playVideo']
        await this.addConsumer(this.consumer)

        // 1c) backend validation
        if (this.$store.state.consumers.error) {
          return (this.isSending = false)
        }

        await this.editLog({
          id: this.vuexLog._id,
          submissionPage: true,
          consumer: this.$store.state.consumers.consumer._id
        })
        this.clearLog()

        // 3) next page
        if (this.$store.state.consumers.consumer?.salonsNear?.length > 0) {
          this.$router.push({
            name: 'GreatNews',
            params: {
              consumerId: this.$store.state.consumers.consumer._id,
              salonId:
                this.$store.state.consumers.consumer.salonsNear[0].salon._id
            }
          })
        } else {
          this.$router.push({
            name: 'NoSalonFound',
            params: {
              consumerId: this.$store.state.consumers.consumer._id
            }
          })
        }
        this.isSending = false
      }
    },
    watch: {
      consumer: {
        handler: function (newValue) {
          localStorage.setItem('consumer', JSON.stringify(newValue))
        },
        deep: true
      },
      terms(newValue, oldValue) {
        if (newValue) {
          this.termsError = false
          this.termsErrorMessage = ''
        }
      },
      phone(newValue, oldValue) {
        if (newValue) {
          this.phoneError = false
          this.phoneErrorMessage = ''
        }
      },
      address() {
        this.consumer.zip = this.address.postal_code
        this.consumer.longitude = this.address.longitude
        this.consumer.latitude = this.address.latitude
        if (this.consumer.zip) {
          localStorage.setItem('consumer', JSON.stringify(this.consumer))
          this.zipError = false
          this.zipErrorMessage = ''
        } else {
          this.zipError = true
          this.zipErrorMessage = 'Zip code is invalid.'
        }
      }
    },
    mounted() {
      if (localStorage.getItem('consumer')) {
        this.$refs.zipInput.update(this.consumer.zip)
      }
    },
    async created() {
      if (localStorage.getItem('consumer')) {
        this.consumer = JSON.parse(localStorage.getItem('consumer'))
      }
      this.$emit('select', {
        screen: this.screen
      })
      // note: ako je ML moramo da dodamo i priceVisible i pricePage true (ako je samo na next prosao price page, nece se ubeleziti)
      // 1. ML
      if (this.isMicrolines) {
        // 1a. ML and has log
        if (this.vuexLog) {
          await this.editLog({
            priceVisible: true,
            pricePage: true,
            id: this.vuexLog._id,
            system: this.$route.query['systemInfo']
          })
        } else {
          // 1b. ML and no log
          await this.addLog({
            priceVisible: true,
            pricePage: true,
            source: this.$route.query['source'] || ConsumerSources.salon_finder,
            lastQuestionPage: true,
            system: this.$route.query['systemInfo']
          })
        }
      }
      // 2. not ML
      else {
        // 2a. not ML has log
        if (this.vuexLog) {
          await this.editLog({
            priceVisible: false,
            pricePage: false,
            id: this.vuexLog._id,
            source: this.$route.query['source'],
            lastQuestionPage: true,
            system: this.$route.query['systemInfo']
          })
        }
        // 2b. not ML no log
        else {
          await this.addLog({
            priceVisible: false,
            pricePage: false,
            source: this.$route.query['source'] || ConsumerSources.salon_finder,
            lastQuestionPage: true,
            system: this.$route.query['systemInfo']
          })
        }
      }
    },
    destroyed() {
      clearTimeout(this.setAddressTimeout)
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    margin: 10px 0;
  }
  .checkbox {
    align-self: flex-start;
  }
  .grey-color {
    color: #696969;
    line-height: 20px;
  }
  .red-color {
    color: red;
  }
  .grey-color-light {
    color: #a8a8a8;
    font-size: 10px;
    line-height: 8px;
  }
  .error-message {
    text-align: left;
    font-size: 12px;
    margin-left: 10px;
    color: #ff5252;
  }
  .error-color {
    color: #ff5252;
  }

  .zip-field {
    width: 100%;
    min-height: 40px;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition-property: all;
    transition-property: height, min-height;
    width: 100%;
    margin-bottom: 4px;
    background: transparent;
    padding: 0 12px;
    cursor: text;
    align-items: stretch;
    caret-color: #ff5252 !important;
    font-size: 16px;
    letter-spacing: normal;
    text-align: left;
    tab-size: 4;
    word-break: normal;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    overflow-wrap: break-word;
    white-space: normal;
    color: #212121;
    border: 1px solid #9e9e9e;
    border-radius: 3px;
    outline: none;
  }

  .zip-field--error {
    border: 2px solid #ff5252;
    color: #ff5252;
  }
  .zip-field--error::-moz-placeholder {
    color: #ff5252;
    opacity: 1;
  }
  .zip-field--error::placeholder {
    opacity: 1;
    color: #ff5252;
  }
</style>
