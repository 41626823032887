<template>
  <v-img
    class="img"
    src="@/assets/Parking.svg"
    contain
    width="34"
    height="34"
  />
</template>
<script>
  export default {
    name: 'FreeParking'
  }
</script>
<style scoped>
  .img {
    color: white;
  }
</style>
