<template>
  <v-container class="pt-0">
    <v-row class="justify-center">
      <v-card-title class="breakword"
        >Do you need help finding the best extension type for you?</v-card-title
      >
      <v-col class="d-flex justify-center" cols="12"
        ><v-btn
          class="text-none"
          depressed
          @click="handleSelect('sf')"
          width="100%"
          :color="answer === 'sf' ? 'user_cta' : 'user_btn'"
          :dark="answer === 'sf'"
          >Help me find what's best for me</v-btn
        ></v-col
      ><v-col class="d-flex justify-center pt-0" cols="12"
        ><v-btn
          width="100%"
          class="text-none"
          depressed
          @click="handleSelect('c')"
          :color="answer === 'c' ? 'user_cta' : 'user_btn'"
          :dark="answer === 'c'"
          >I know what type I want</v-btn
        ></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        screen: 'extensions',
        question: 'systemPickedBy',
        answer: ''
      }
    },
    computed: {
      nextScreen() {
        if (this.answer === 'sf') return 'hairLongIs'
        else return 'consumerPick'
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false) {
        this.$emit('select', {
          // note:
          clearUrl: true,
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          goNext
        })
      },
      handleSelect(answer) {
        this.answer = answer
        this.emitSelect(true)
      }
    },
    created() {
      this.answer = this.$route.query[this.question] || ''
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped>
  .breakword {
    word-break: break-word;
  }
</style>
