import api from '@/services/api.service'

// state
export const state = { list: [], loading: false, error: '', c_s: '' }

// mutations
export const mutations = {
  callsFetched: (state, calls) => {
    state.list = calls
  },
  c_s_fetched: (state, c_s) => {
    state.c_s = c_s
  },
  callsEdited: (state, editedCall) => {
    state.list = state.list.map(call => {
      return call._id === editedCall._id ? { ...editedCall } : { ...call }
    })
  },
  callRemoved: (state, id) =>
    (state.list = state.list.filter(s => s._id !== id)),
  loadingCallsSet: state => (state.loading = true),
  loadingCallsCleared: state => (state.loading = false),
  errorCallsSet: (state, error) => (state.error = error),
  errorCallsCleared: state => (state.error = '')
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorCallsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingCallsCleared')
  setTimeout(() => {
    commit('errorCallsCleared')
  }, 10000)
}

// actions
export const actions = {
  fetchCalls: async ({ commit }) => {
    commit('loadingCallsSet')
    try {
      const response = await api.getCalls()
      commit('callsFetched', response.data.data)
      commit('loadingCallsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  call_c_s: async ({ commit }, data) => {
    commit('loadingCallsSet')
    try {
      await api.call_c_s(data)
      commit('loadingCallsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  call_c_h: async ({ commit }, data) => {
    commit('loadingCallsSet')
    try {
      await api.call_c_h(data)
      commit('loadingCallsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchCalls_c_s: async ({ commit }, payload) => {
    commit('loadingCallsSet')
    const { salonId, consumerId } = payload
    try {
      const res = await api.getCalls_c_s(salonId, consumerId)
      commit('c_s_fetched', res.data.data)
      commit('loadingCallsCleared')
    } catch (error) {
      commit(
        'errorCallsSet',
        error?.response?.data?.message || 'Something went wrong.'
      )
      setTimeout(() => {
        commit('errorCallsCleared')
      }, 10000)
      commit('loadingCallsCleared')
    }
  },
  // use it for deactivate also (send from component active:false)
  editCall: async ({ commit }, call) => {
    commit('loadingCallsSet')

    try {
      const { data } = await api.patchCall(call)
      // edit
      if (call.active === false) {
        commit('callRemoved', call.id)
      }
      // delete
      else {
        commit('callsEdited', data.data)
      }
      commit('loadingCallsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
