<template>
  <v-card class="elevation-12 mx-auto pa-3" max-width="450">
    <v-card-text>
      <v-row class="justify-center">
        <v-row>
          <v-col cols="12" class="pl-0 pb-0">
            <v-card-title class="breakword pb-0"
              >Select a system you are interested in</v-card-title
            ></v-col
          >
          <v-col
            class="d-flex justify-center flex-column align-center system-img pb-0"
            cols="6"
            @click="handleSelect(SystemOptions.LASERBEAMER)"
          >
            <v-img lazy-src="@/assets/NANO.jpg" src="@/assets/NANO.jpg" />
            <v-btn
              depressed
              :color="
                answer === SystemOptions.LASERBEAMER ? 'user_cta' : 'user_btn'
              "
              :dark="answer === SystemOptions.LASERBEAMER"
              class="text-none mt-1"
              width="100%"
              >Laserbeamer Nano</v-btn
            >
            <v-card-text class="text-center pt-0 px-0 btn-caption" caption
              >Automatic bonded extensions</v-card-text
            > </v-col
          ><v-col
            class="d-flex justify-center flex-column align-center system-img pb-0"
            cols="6"
            @click="handleSelect(SystemOptions.FREESTYLER)"
          >
            <v-img
              lazy-src="@/assets/FREESTYLER.jpg"
              src="@/assets/FREESTYLER.jpg"
            />
            <v-btn
              depressed
              :color="
                answer === SystemOptions.FREESTYLER ? 'user_cta' : 'user_btn'
              "
              :dark="answer === SystemOptions.FREESTYLER"
              class="text-none mt-1"
              width="100%"
              >FreeStyler</v-btn
            >
            <v-card-text class="text-center pt-0 px-0 btn-caption" caption
              >Custom bonded extensions</v-card-text
            >
          </v-col>
          <v-col
            class="d-flex justify-center flex-column align-center system-img py-0"
            cols="6"
            @click="handleSelect(SystemOptions.TAPE)"
          >
            <v-img lazy-src="@/assets/TAPES.jpg" src="@/assets/TAPES.jpg" />

            <v-btn
              depressed
              :color="answer === SystemOptions.TAPE ? 'user_cta' : 'user_btn'"
              :dark="answer === SystemOptions.TAPE"
              class="text-none mt-1"
              width="100%"
              >Tape-Ins</v-btn
            >
            <v-card-text class="text-center pt-0 px-0 btn-caption" caption
              >Featuring BABY SECRETS</v-card-text
            >
          </v-col>
          <v-col
            class="d-flex justify-center flex-column align-center system-img py-0"
            cols="6"
            @click="handleSelect(SystemOptions.MICROLINES)"
          >
            <v-img
              lazy-src="@/assets/MICROLINES.jpg"
              src="@/assets/MICROLINES.jpg"
            />
            <v-btn
              depressed
              :color="
                answer === SystemOptions.MICROLINES ? 'user_cta' : 'user_btn'
              "
              :dark="answer === SystemOptions.MICROLINES"
              class="text-none mt-1"
              width="100%"
              >MicroLines</v-btn
            ><v-card-text class="text-center pt-0 px-0 btn-caption" caption
              >Immediate hair loss solution</v-card-text
            ></v-col
          >
        </v-row>
      </v-row>
      <!-- 
      <v-row class="justify-center">
        <v-btn
          @click="handleOtherSystem"
          text
          color="blue"
          class="text-none mt-3 text-decoration-underline"
          >I am not inquiring about any systems</v-btn
        >
      </v-row> -->
    </v-card-text>
  </v-card>
</template>

<script>
  import { SystemOptions, getShortSystemForUrl } from '@/config/enums'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        SystemOptions,
        getShortSystemForUrl,
        nextScreen: 'stylist-apple-style',
        // nextScreen: 'stylist-system-info',
        answer: ''
      }
    },
    methods: {
      ...mapActions(['setPrimarySystem']),
      // handleOtherSystem() {
      //   this.$router.push({
      //     name: 'stylist-other-contact',
      //     query: this.$route.query
      //   })
      // },
      handleSelect(answer) {
        this.answer = answer
        // save to db primary system on stylist if stylist is already logged in
        this.setPrimarySystem({
          system: answer,
          id: this.$store.getters.stylistLoggedIn
            ? this.$store.state.auth.user.stylistId
            : undefined
        })
        this.$router.push({
          name: this.nextScreen,
          params: { system: this.getShortSystemForUrl(answer) },
          query: this.$route.query
        })
        // this.$router.push({ name: 'stylist-signup' })
      }
    },
    created() {
      this.answer = this.$store.getters.stylistPrimarySystem
    }
  }
</script>

<style lang="scss" scoped>
  .system-img {
    cursor: pointer;
  }
  .btn-caption {
    color: #7d7d7d;
    font-size: 10px;
  }
  .breakword {
    word-break: break-word;
  }
</style>
