<template>
  <v-container class="pa-0">
    <div
      class="d-flex justify-center"
      style="min-height: 1000px"
      v-if="isLoading"
    >
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <LbHalfView
        :isCanada="isCanada"
        :source="source"
        v-if="certification === StylistsCertifications.LB_HALF"
      />
      <LbFullView
        :isCanada="isCanada"
        :source="source"
        v-if="certification === StylistsCertifications.LB_FULL"
      />
      <MlRegView
        :isCanada="isCanada"
        :source="source"
        v-if="certification === StylistsCertifications.ML_REGULAR"
      />
      <MlCutView
        :source="source"
        :isCanada="isCanada"
        v-if="certification === StylistsCertifications.ML_CUTTING"
      />
      <FsOnlineView
        :source="source"
        :isCanada="isCanada"
        v-if="certification === StylistsCertifications.FS_ONLINE"
      />
      <FsInPersonView
        :source="source"
        :isCanada="isCanada"
        v-if="certification === StylistsCertifications.FS_IN_PERSON"
      />
    </template>
  </v-container>
</template>

<script>
  import { StylistsCertifications } from '@/config/enums'
  import { mapActions } from 'vuex'
  import { StylistCountry } from '../../../../../config/enums'
  import FsInPersonView from './FsInPersonView'
  import FsOnlineView from './FsOnlineView'
  import LbFullView from './LbFullView'
  import LbHalfView from './LbHalfView'
  import MlCutView from './MlCutView'
  import MlRegView from './MlRegView'
  import sources from './source-certifications.js'

  export default {
    components: {
      LbHalfView,
      LbFullView,
      MlRegView,
      MlCutView,
      FsOnlineView,
      FsInPersonView
    },
    data() {
      return { StylistsCertifications, sources, isLoading: true }
    },
    computed: {
      certification() {
        return this.$route.params.certification
      },
      source() {
        return this.sources[this.certification]
      },
      isCanada() {
        return (
          this.$store.state.stylistsAccount?.stylist?.country ===
          StylistCountry.CAN
        )
      }
    },
    methods: {
      ...mapActions(['fetchStylistAccount'])
    },
    async created() {
      const stylistId = this.$route.params.stylistId
      await this.fetchStylistAccount(stylistId)
      this.isLoading = false
    }
  }
</script>
