<template>
  <v-container class="container">
    <v-card class="pa-7">
      <v-row class="justify-center">
        <v-card-title class="breakword"
          >Salon Professionals can purchase QUIKKIES and SECRETS tape-ins
          without certification. US residents can set up an account with a
          current valid cosmetology license and shop online.</v-card-title
        >
      </v-row>
      <v-col
        v-for="option in options"
        :key="option.answer"
        class="d-flex justify-center pb-0 px-0"
        cols="12"
        ><v-btn
          class="text-none text-caption text-sm-body-2"
          depressed
          @click="handleSelect(option.answer)"
          width="100%"
          :color="answer === option.answer ? 'user_cta' : 'user_btn'"
          :dark="answer === option.answer"
          >{{ option.text }}</v-btn
        ></v-col
      ></v-card
    >
  </v-container>
</template>

<script>
  const options = [
    { text: 'Apply for All Hairdreams Systems', answer: 'all' },
    {
      text: 'Apply for Tapes Only (USA)',
      answer: 'tapes-usa'
    },
    { text: 'Apply for  Tapes Only (Canada)', answer: 'tapes-canada' }
  ]

  export default {
    data() {
      return {
        options,
        answer: ''
      }
    },
    methods: {
      handleSelect(answer) {
        if (answer === 'tapes-usa') {
          window.location.href = 'https://hairdreamstapes.com/account/register'
        } else if (answer === 'tapes-canada' || answer === 'all') {
          this.$router.push({ name: 'stylist-steps', query: this.$route.query })
        }
      }
    },
    created() {}
  }
</script>

<style lang="scss" scoped>
  .breakword {
    word-break: break-word;
  }

  .container {
    max-width: 550px;
  }
</style>
