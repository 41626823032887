<template>
  <v-container class="ma-0 pa-0 mx-auto pt-0">
    <v-card class="elevation-12 mx-auto" max-width="320">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-capitalize mx-auto"
          >Thank You!</v-toolbar-title
        >
      </v-toolbar>

      <v-card-text>
        <div class="text-subtitle-1 my-3 text-center" v-html="text"></div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    computed: {
      text() {
        let text = ''
        if (this.$route.params.source === 'contact')
          // text =
          //   'Thank you for your inquiry. You will be contacted by a Hairdreams Specialist within 2 business days.  If you have further questions or need immediate assistance, please feel free to contact us at <a href="mailto:Hairdreams@bht-usa.com?subject=Salon Finder Assistance Needed">Hairdreams@bht-usa.com</a> or call toll-free at 888-434-2471.'
          text =
            'Thank you for your inquiry. You will be contacted by a Hairdreams Specialist within 3 business days. If you have further questions or need immediate assistance, please call us toll-free at 888-434-2471.'
        if (this.$route.params.source === 'edit-salon')
          text =
            'Please allow up to 3 business days for updates to be reviewed by Hairdreams.'
        return text
      }
    }
  }
</script>
