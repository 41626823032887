<template>
  <v-container class="pt-0">
    <div class="form">
      <v-card-text class="px-0 breakword text-center title py-0"
        >About how long do you want it to be?</v-card-text
      >
      <v-card-text class="text-center subtitle">Choose one</v-card-text>
      <v-row>
        <v-col
          v-if="showOption1"
          @click="handleSelect('a')"
          :dark="answer === 'a'"
          class="hair-img d-flex justify-center flex-column align-center"
        >
          <v-img
            width="140"
            src="@/assets/AFTER SHORT.png"
            lazy-src="@/assets/AFTER SHORT.png"
          ></v-img>
          <v-btn
            depressed
            :color="answer === 'a' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'a'"
            class="text-none"
            width="140"
            small
            >At shoulders</v-btn
          ></v-col
        >
        <v-col
          @click="handleSelect('b')"
          :dark="answer === 'b'"
          class="hair-img d-flex justify-center flex-column align-center"
          v-if="showOption2"
          ><v-img
            width="140"
            src="@/assets/AFTER MID.png"
            lazy-src="@/assets/AFTER MID.png"
          ></v-img
          ><v-btn
            depressed
            :color="answer === 'b' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'b'"
            class="text-none"
            width="140"
            small
            >Past shoulders</v-btn
          ></v-col
        >
        <v-col
          @click="handleSelect('c')"
          :dark="answer === 'c'"
          class="hair-img d-flex justify-center flex-column align-center"
          ><v-img
            width="140"
            src="@/assets/AFTER LONG.png"
            lazy-src="@/assets/AFTER LONG.png"
          ></v-img
          ><v-btn
            depressed
            :color="answer === 'c' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'c'"
            class="text-none"
            width="140"
            small
            >Mid back or longer</v-btn
          ></v-col
        >
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import { SystemOptions, ConsumerSources } from '@/config/enums'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        screen: 'hairLongWant',
        question: 'hairLongWant',
        answer: '',
        isSendingLog: false
      }
    },
    computed: {
      chosenSystem() {
        return this.$route.query.systemInfo
      },
      vuexLog() {
        return this.$store.getters.log
      },
      nextScreen() {
        if (this.$route.query['systemInfo'] === SystemOptions.MICROLINES)
          return 'microlinesPrice'
        // return 'consumerInfo'
        else return 'extraCoverage'
      },
      showOption1() {
        // ako nema odgovora ili je odgovor a
        return (
          !this.$route.query['hairLongIs'] ||
          this.$route.query['hairLongIs'] === 'a'
        )
      },
      showOption2() {
        // ako nema odgovora ili je odgovor a ili b
        return (
          !this.$route.query['hairLongIs'] ||
          this.$route.query['hairLongIs'] === 'a' ||
          this.$route.query['hairLongIs'] === 'b'
        )
      }
    },
    methods: {
      ...mapActions(['addLog', 'editLog']),
      // helper
      emitSelect(goNext = false) {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          goNext
        })
      },
      async handleSelect(answer) {
        if (
          this.chosenSystem === SystemOptions.MICROLINES &&
          !this.isSendingLog
        ) {
          this.isSendingLog = true
          if (!this.vuexLog) {
            const log = {
              source:
                this.$route.query['source'] || ConsumerSources.salon_finder,
              system: this.chosenSystem,
              lastQuestionPage: true,
              priceVisible: true
            }
            await this.addLog(log)
          } else {
            await this.editLog({
              id: this.vuexLog._id,
              lastQuestionPage: true,
              priceVisible: true
            })
          }
          this.isSendingLog = false
        }
        this.answer = answer
        this.emitSelect(true)
      }
    },
    created() {
      this.answer = this.$route.query[this.question] || ''

      // reset answer
      if (!this.showOption1 && this.answer === 'a') this.answer = ''

      if (!this.showOption2 && (this.answer === 'a' || this.answer === 'b'))
        this.answer = ''

      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    margin: 10px 0;
  }
  .hair-img {
    cursor: pointer;
  }
</style>
