<template>
  <v-container class="px-0 px-sm-0">
    <v-card flat height="300">
      <!-- <pre>{{ item.salon }}</pre> -->
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        :center="end"
        :zoom="zoom"
        :interactive="detectMob() ? false : true"
      >
        <MglMarker :coordinates="end" color="blue"> </MglMarker>
        <MglNavigationControl position="top-right" />
        <MglAttributionControl />
        <MglGeolocateControl position="top-right" />
      </MglMap>
    </v-card>
    <v-row class="align-center">
      <v-col
        class="d-flex justify-center justify-sm-start mt-3"
        cols="12"
        sm="6"
      >
        <v-card-text
          class="font-weight-bold body-1 pa-0 ma-0 d-flex flex-column text-center text-sm-left"
          ><span>{{ salonAddress }}</span
          ><span>{{ salonCityState }}</span></v-card-text
        >
      </v-col>
      <v-col class="d-flex justify-center justify-sm-end" cols="12" sm="6">
        <v-btn
          @click="onGetDirections"
          rounded
          :small="detectXS() ? true : false"
          >Get Directions
          <!-- <v-icon>mdi-car</v-icon> -->
        </v-btn>
      </v-col>
    </v-row>
    <!-- modal -->
    <DirectionsMap
      :show="showDirectionsMap"
      @close="showDirectionsMap = false"
      :end="end"
      :route="route"
    />
  </v-container>
</template>

<script>
  import Mapbox from 'mapbox-gl'
  import {
    MglMap,
    MglMarker,
    MglAttributionControl,
    MglNavigationControl,
    MglGeolocateControl
  } from 'vue-mapbox'
  import 'mapbox-gl/dist/mapbox-gl.css'
  // import axios from 'axios'
  import DirectionsMap from '@/components/user/DirectionsMap'
  import { detectXS, detectMob } from '@/utils/helpers'

  export default {
    components: {
      MglMap,
      MglMarker,
      MglAttributionControl,
      MglGeolocateControl,
      MglNavigationControl,
      DirectionsMap
    },
    props: {
      salon: {
        type: Object
      },
      consumer: Object
    },
    data() {
      return {
        detectMob,
        detectXS,
        route: null,
        showDirectionsMap: false,
        mapbox: null,
        accessToken: process.env.VUE_APP_MAP_TOKEN,
        mapStyle: 'mapbox://styles/mapbox/streets-v11?optimize=true',
        directionMode: 'driving',
        // starting zoom
        zoom: 12
      }
    },
    computed: {
      salonAddress() {
        return `${this.salon.address1}${
          this.salon.address2 ? ', ' + this.salon.address2 : ''
        }, `
      },
      salonCityState() {
        return `${this.salon.city}, ${this.salon.state}`
      },
      end() {
        const [x, y] = this.salon.location.coordinates
        return [x, y]
      }
      // start() {
      //   const [x, y] = this.$store.state.consumers.consumer.location.coordinates
      //   return [x, y]
      // }
    },
    methods: {
      onGetDirections() {
        document.location.href = `https://www.google.com/maps/search/?api=1&query=${this.salon.location.coordinates[1]}, ${this.salon.location.coordinates[0]}`
        // note: archive - custom direction
        // const query = await axios(
        //   `https://api.mapbox.com/directions/v5/mapbox/${this.directionMode}/${this.start[0]},${this.start[1]};${this.end[0]},${this.end[1]}?steps=true&geometries=geojson&access_token=${this.accessToken}`
        // )

        // console.log(query)
        // this.route = query.data.routes[0].geometry.coordinates
        // if (this.route) {
        //   this.showDirectionsMap = true
        // }
      }
    },
    created() {
      this.mapbox = Mapbox
    }
  }
</script>

<style lang="scss" scoped>
  #map {
    width: 100%;
    height: 500px;
  }
  .mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
