<template>
  <v-dialog v-model="show" max-width="600" @click:outside="close">
    <v-card>
      <v-toolbar elevation="0" height="100">
        <v-card-title class="breakword my-3"
          >FREESTYLER START KIT INCLUDES:</v-card-title
        >
        <v-spacer></v-spacer>
        <v-icon @click="close" class="mb-auto mt-4">mdi-close</v-icon>
      </v-toolbar>

      <v-card-text>
        <ul>
          <li>FreeStyler Machine+Magnetic Stand</li>
          <li>Selector Clips</li>
          <li>Hairdreams Color Ring</li>
          <li>Removal System</li>
          <li>Full Wet line Assortment</li>
          <li>Extension Brush</li>
          <li>Training Manual and Materials</li>
          <li>Style Books & Brochures</li>
          <li>
            3 bundles 16" 5 Star Quality (1 bundle of each strand/bond size)
          </li>
          <li>Mannequin Head and Holder</li>
        </ul>
      </v-card-text>
      <v-card-text class="subtitle-1"
        >ONLINE TRAINING MODULE + 1 DAY CERTIFICATION CLASS:</v-card-text
      >
      <v-card-text
        >This class combines the convenience of an easy-to-use online training +
        an in person hands on class where you will apply, cut, and style
        Hairdreams extensions to one half of a mannequin head. Training
        additionally covers removal, maintenance, consultation and marketing.
        See the
        <a
          class="link"
          href="https://iwanthairdreams.com/pages/training-calendar-freestyler"
          >Training Calendar</a
        >
        for dates and locations. The Start Kit can only certify one licensed
        cosmetologist. Inquire for details on certifying more than one stylist.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: false
      }
    },
    methods: {
      close() {
        this.$emit('close', false)
      }
    }
  }
</script>
