<template>
  <v-card-title
    class="text-sm-h4 text-h5 justify-center breakword"
    v-html="text"
  ></v-card-title>
</template>

<script>
  export default {
    props: {
      text: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  .breakword {
    word-break: break-word;
  }
</style>
