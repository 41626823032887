<template>
  <v-container class="pt-0">
    <v-card
      class="elevation-12 mx-auto"
      max-width="500"
      v-if="
        !$store.state.consumers.loading &&
        !$store.state.messages.loading &&
        !$store.state.salons.loading
      "
    >
      <v-toolbar dark color="primary" height="50">
        <div @click="onMoreInfo" class="info-icon pt-5">
          <v-icon>mdi-phone</v-icon>
        </div>
        <v-toolbar-title class="text-caption text-sm-h5 mx-auto pt-5">
          WF6:
          {{ c_s.salonName }}
        </v-toolbar-title>

        <div @click="onMoreInfo" class="info-icon pt-5">
          <v-icon>mdi-information-outline</v-icon>
        </div>
      </v-toolbar>
      <v-toolbar dark color="primary" height="40">
        <v-btn
          @click="() => (showDiscountPopUp = true)"
          rounded
          light
          x-small
          class="mx-auto mb-sm-2 mb-0"
          >Add a New Consultation</v-btn
        >
      </v-toolbar>

      <!-- MESSAGES -->
      <Chat
        :isModerator="true"
        :messages="$store.state.messages.c_s.allMessages"
        :host="c_s.salonName"
        hostName="HairDreams"
        :guestName="c_s.firstName + ' ' + c_s.lastName"
      />
      <v-row class="mt-3 mx-3">
        <v-col cols="12" class="my-0 py-0">
          <v-checkbox
            label="Too expensive"
            v-model="tooExpensive"
            class="my-0 py-0"
          >
          </v-checkbox
        ></v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-checkbox
            label="Did not like system/product"
            v-model="didNotLikeSystemProduct"
            class="my-0 py-0"
          >
          </v-checkbox
        ></v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-checkbox
            label="Never got in touch with the salon"
            v-model="neverGotInTouchWithSalon"
            class="my-0 py-0"
          >
          </v-checkbox
        ></v-col>
        <v-col cols="12" class="my-0 py-0">
          <v-checkbox
            label="Salon never called back"
            v-model="salonNeverCalledBack"
            class="my-0 py-0"
          >
          </v-checkbox
        ></v-col>

        <v-col cols="12" sm="12" md="12">
          <v-textarea
            v-model="memoText"
            label="Memo"
            auto-grow
            outlined
            rows="3"
            row-height="25"
            shaped
            hide-details
          ></v-textarea>
        </v-col>
        <v-col class="d-flex justify-end"
          ><v-btn
            class="mr-5 green"
            @click="handleSaveMemo"
            :loading="isLoadingSaveMemo"
            :disabled="!showSaveMemoBtn"
            >Save Memo</v-btn
          ></v-col
        >
      </v-row>
    </v-card>

    <v-sheet color="white" class="pa-3" v-else>
      <v-skeleton-loader
        class="mx-auto"
        max-width="500"
        type="card, actions, article, card-avatar"
      ></v-skeleton-loader>
    </v-sheet>
    <DiscountPopUp
      :setMinDate="false"
      :addedBy="CONSULTATION_ADDED_BY.ADMIN"
      title="Add a New Consultation"
      color="primary"
      v-if="showDiscountPopUp"
      :showDialog="showDiscountPopUp"
      @close="() => (showDiscountPopUp = false)"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import Chat from '@/components/user/Chat'
  import DiscountPopUp from '@/components/DiscountPopUp.vue'
  import { DEFERRED_EMAIL, CONSULTATION_ADDED_BY } from '@/config/enums'

  export default {
    data() {
      return {
        memoText: '',
        tooExpensive: false,
        neverGotInTouchWithSalon: false,
        salonNeverCalledBack: false,
        didNotLikeSystemProduct: false,
        isLoadingSaveMemo: false,
        showDiscountPopUp: false,
        CONSULTATION_ADDED_BY,
        DEFERRED_EMAIL
      }
    },
    components: {
      Chat,
      DiscountPopUp
    },
    computed: {
      c_s() {
        return this.$store.state.messages.c_s
      },
      salonMemo() {
        return this.$store.state.salons.selectedSalon.adminMemosForMessages.find(
          memo => memo.consumer.toString() === this.$route.params.consumerId
        )
      },
      showSaveMemoBtn() {
        return (
          this.memoText !== this.salonMemo?.text ||
          this.tooExpensive !== this.salonMemo?.tooExpensive ||
          this.neverGotInTouchWithSalon !==
            this.salonMemo?.neverGotInTouchWithSalon ||
          this.salonNeverCalledBack !== this.salonMemo?.salonNeverCalledBack ||
          this.didNotLikeSystemProduct !==
            this.salonMemo?.didNotLikeSystemProduct
        )
      }
    },
    methods: {
      ...mapActions(['fetch_c_s', 'editSalonAdminMessagesMemo', 'fetchSalon']),
      async handleSaveMemo() {
        this.isLoadingSaveMemo = true
        await this.editSalonAdminMessagesMemo({
          salonId: this.$route.params.salonId,
          consumerId: this.$route.params.consumerId,
          text: this.memoText,
          tooExpensive: this.tooExpensive,
          neverGotInTouchWithSalon: this.neverGotInTouchWithSalon,
          salonNeverCalledBack: this.salonNeverCalledBack,
          didNotLikeSystemProduct: this.didNotLikeSystemProduct
        })
        this.isLoadingSaveMemo = false
      },
      onMoreInfo() {
        this.$router.push({
          name: 'GreatNews',
          params: {
            consumerId: this.$route.params.consumerId,
            salonId: this.$route.params.salonId
          }
        })
      }
    },
    async created() {
      const salonId = this.$route.params.salonId
      const consumerId = this.$route.params.consumerId
      await Promise.all([
        this.fetchSalon(salonId),
        this.fetch_c_s({ salonId, consumerId })
      ])
      this.memoText = this.salonMemo ? this.salonMemo.text : ''
      this.tooExpensive = this.salonMemo ? this.salonMemo.tooExpensive : false
      this.neverGotInTouchWithSalon = this.salonMemo
        ? this.salonMemo.neverGotInTouchWithSalon
        : false
      this.salonNeverCalledBack = this.salonMemo
        ? this.salonMemo.salonNeverCalledBack
        : false
      this.didNotLikeSystemProduct = this.salonMemo
        ? this.salonMemo.didNotLikeSystemProduct
        : false
      if (this.$store.state.messages.error) {
        this.$router.push({ name: 'NotFound' })
      }
    }
  }
</script>

<style scoped>
  .info-icon {
    cursor: pointer;
  }
</style>
