import { SystemOptions } from '../config/enums'

export const dollar = num => {
  return num.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })
}

export const miles = num => {
  const options = {
    style: 'unit',
    unit: 'mile'
  }
  return new Intl.NumberFormat('en-US', options).format(num)
}

export const titleCase = str => {
  str = str.toLowerCase().split(' ')
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

export const decimalNumber = num =>
  new Intl.NumberFormat(navigator.language || 'en-US', {
    minimumFractionDigits: 2
  }).format(num)

export const localeDate = (date, time = false) => {
  let options = {}
  if (time) {
    options = {
      // second: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }
  }

  date = new Date(date)
  const lang = navigator.language || 'en-US'

  return new Intl.DateTimeFormat(lang, options).format(date)
}

export const ampmTime = time => {
  // let minutes = ''
  if (time.length > 2) {
    // minutes = time.slice(2, 4)
    time = time.slice(0, 2) + ':' + time.slice(3, 5)
  }

  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ]

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1) // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  return time.join('') // return adjusted time or original string
}

export const shortSystem = (system, q4answer = '') => {
  switch (system) {
    case SystemOptions.FREESTYLER:
      return 'FreeStyler Hand Rolled Extensions'
    case SystemOptions.LASERBEAMER:
      return 'Laserbeamer Nano'
    case SystemOptions.MICROLINES:
      return 'MicroLines'
    case SystemOptions.TAPE:
      return 'Quikkies & Secrets Tape Ins'
    case 'Bonded Extensions':
      return 'Hairdreams Bonded Extensions'
    case 'Extensions':
      return 'Bonded or Tape Extensions'
    default:
      return ''
  }
}

export const ampmTimeForHours = time => {
  // let minutes = ''
  if (time.length > 2) {
    // minutes = time.slice(2, 4)
    time = time.slice(0, 2) + ':' + time.slice(2, 4)
  }

  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ]

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1) // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM' // Set AM/PM
    time[0] = +time[0] % 12 || 12 // Adjust hours
  }
  return time.join('') // return adjusted time or original string
}
