<template>
  <div>
    <v-container
      class="ma-0 pa-0 mx-auto pt-0"
      v-if="!$store.state.salons.loading"
    >
      <v-snackbar
        v-if="message"
        centered
        top
        v-model="snackbar"
        color="green lighten-2"
        timeout="3000"
        >{{ message }}</v-snackbar
      >
      <v-card class="elevation-12 mx-auto pb-5" max-width="400">
        <v-toolbar dark color="primary">
          <v-toolbar-title class="mx-auto">Remove Consumer</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            outlined
            hide-details
            v-model="consumerId"
            label="Consumer ID"
          />
        </v-card-text>
        <template v-if="consumer">
          <v-card-text class="pt-0 subtitle-1">
            Name: {{ consumer.firstName }} {{ consumer.lastName }}
          </v-card-text>
          <v-card-text class="pt-0 subtitle-1">
            Email: {{ consumer.email }}
          </v-card-text>
          <v-card-text class="pt-0 subtitle-1">
            Salons:
            {{
              consumer.salonsNear
                .map(salonObj => salonObj.salon.name)
                .toString() || '/'
            }}
          </v-card-text>
          <v-card-text class="pt-0 subtitle-1">
            Deferred Emails:
            {{
              consumer.deferredEmails.map(email => email.mail).toString() || '/'
            }}
          </v-card-text>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :loading="isSendingGetInfo"
            :disabled="!consumerId"
            color="primary"
            width="40%"
            @click="handleGetInfo"
            >Get Info</v-btn
          >
          <v-btn
            :disabled="!consumerId"
            :loading="isOpeningDialog"
            width="40%"
            color="red"
            @click="handleRemove"
            >Remove</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-container>
    <v-dialog
      top
      v-if="openDialog"
      v-model="openDialog"
      max-width="400"
      @click:outside="openDialog = false"
      @keydown.esc="openDialog = false"
    >
      <v-card>
        <v-card-title class="breakword">
          Are you sure that you want to delete {{ consumer.firstName }}
          {{ consumer.lastName }}?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :loading="isRemoving" @click="handleConfirm"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        consumerId: '',
        openDialog: false,
        isSendingGetInfo: false,
        isOpeningDialog: false,
        isRemoving: false,
        message: '',
        snackbar: false
      }
    },
    computed: {
      consumer() {
        return this.$store?.state?.consumers?.consumer
      }
    },
    methods: {
      ...mapActions(['removeConsumer', 'fetchConsumer', 'clearConsumer']),
      async handleGetInfo() {
        this.message = ''
        this.isSendingGetInfo = true
        await this.fetchConsumer(this.consumerId)
        if (this.$store.state.consumers.error) this.clearConsumer()

        this.isSendingGetInfo = false
      },
      async handleRemove() {
        this.message = ''
        this.isOpeningDialog = true
        if (!this.consumer) {
          await this.fetchConsumer(this.consumerId)
        }
        if (this.consumer) {
          this.openDialog = true
        }
        this.isOpeningDialog = false
      },
      async handleConfirm() {
        if (!this.consumer) return
        this.isRemoving = true
        const message = `${this.consumer.firstName} ${this.consumer.lastName} is successfully removed!`
        await this.removeConsumer(this.consumerId)
        if (!this.$store.state.consumers.error) {
          this.message = message
          this.consumerId = ''
        }
        this.openDialog = false
        this.clearConsumer()
        this.isRemoving = false
      }
    },
    created() {
      this.clearConsumer()
    },
    watch: {
      message(newValue, oldValue) {
        if (newValue) this.snackbar = true
      }
    }
  }
</script>
