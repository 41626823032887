<template>
  <v-container fluid>
    <v-data-table
      dense
      :headers="headers"
      :items="consumers"
      item-key="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 ml-3"
      @page-count="pageCount = $event"
      :server-items-length="totalConsumers"
      :loading="isLoading"
      @update:options="handleUpdateOptions"
      ><template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification }}</v-snackbar
        >
        <v-toolbar flat>
          <v-toolbar-title>CONSUMERS</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="title"
            >Count: {{ $store.state.consumers.totalCount }}</span
          >
          <v-spacer></v-spacer>

          <v-row>
            <!-- start date -->
            <v-col class="d-flex justify-end">
              <v-text-field
                outlined
                dense
                clearable
                label="Export Start Date"
                type="date"
                v-model="exportStartDate"
                hide-details
              />
            </v-col>
            <!-- end date -->
            <v-col class="d-flex justify-end">
              <v-text-field
                outlined
                dense
                clearable
                label="Export End Date"
                type="date"
                v-model="exportEndDate"
                hide-details
                :max="getTodayString()"
              />
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn block @click="handleExportData" :loading="isExportingData">
                <v-icon class="mx-1">mdi-download</v-icon>
                Export CSV
              </v-btn>
            </v-col>
          </v-row>
          <!-- edit memo, appointment booked, system booked -->
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Consumer</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <input v-model="editedItem.id" type="hidden" />
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="editedItem.memo"
                        label="Memo"
                        auto-grow
                        outlined
                        rows="3"
                        row-height="25"
                        shaped
                        filled
                        hide-details
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.firstName"
                        disabled
                        label="First Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.lastName"
                        disabled
                        label="Last Name"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.email"
                        disabled
                        label="E-mail"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.system"
                        disabled
                        label="System"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.phone"
                        disabled
                        label="Phone Number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.zip"
                        disabled
                        label="Zip Code"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedItem.salon1">
                      <v-text-field
                        v-model="editedItem.salon1"
                        disabled
                        label="Salon 1:"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedItem.salon2">
                      <v-text-field
                        v-model="editedItem.salon2"
                        disabled
                        label="Salon 2:"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" v-if="editedItem.salon3">
                      <v-text-field
                        v-model="editedItem.salon3"
                        disabled
                        label="Salon 3:"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="handleSave">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this consumer?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-row class="d-flex align-center">
          <!-- search by date -->
          <v-col>
            <DateFilter
              @change="handleDateChange"
              :start="startDate"
              :end="endDate"
          /></v-col>
          <!-- show utm columns -->
          <v-col class="d-flex justify-end mr-3" cols="2">
            <v-switch
              hide-details
              v-model="showUtmColumns"
              label="Show UTM"
            ></v-switch>
          </v-col>
        </v-row>
      </template>
      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="handleFilterChange($event, header)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td>
        </tr>
      </template>
      <!-- for edit action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>

        <!-- <v-icon v-if="$store.getters.isUserFull" small @click="deleteItem(item)">
              mdi-delete
            </v-icon> -->
      </template>
      <!-- wantAgain -->
      <template v-slot:item.wantAgain="{ item }">
        <template v-if="!item.wantAgain"></template>
        <v-chip
          v-else
          x-small
          class="ma-2"
          color="pink"
          label
          text-color="white"
          ><v-icon x-small left> mdi-label </v-icon>{{ item.wantAgain }}</v-chip
        >
      </template>
      <!-- lifestyleExtensions -->
      <template v-slot:item.lifestyleExtensions="{ item }">
        <template v-if="!item.lifestyleExtensions"></template>
        <v-chip
          v-else
          x-small
          class="ma-2"
          color="pink"
          label
          text-color="white"
          ><v-icon x-small left> mdi-label </v-icon
          >{{ item.lifestyleExtensions }}</v-chip
        >
      </template>
      <!-- system -->
      <template v-slot:item.system="{ item }">
        <v-chip x-small class="ma-2" color="pink" label text-color="white"
          ><v-icon x-small left> mdi-label </v-icon>{{ item.system }}</v-chip
        >
      </template>
      <!-- save for later -->
      <template v-slot:item.saveForLater="{ item }">
        <v-chip
          text-color="white"
          small
          :color="item.saveForLater === 'yes' ? 'green' : 'red'"
          >{{ item.saveForLater }}</v-chip
        >
      </template>
      <!-- promotion -->
      <template v-slot:item.sendPromotion="{ item }">
        <v-chip
          small
          text-color="white"
          :color="item.sendPromotion === 'yes' ? 'green' : 'red'"
          >{{ item.sendPromotion }}</v-chip
        >
      </template>
      <!-- watched video -->
      <template v-slot:item.playVideo="{ item }">
        <v-chip
          small
          text-color="white"
          :color="item.playVideo === 'yes' ? 'green' : 'red'"
          >{{ item.playVideo }}</v-chip
        >
      </template>
      <!-- question 3 -->
      <template v-slot:item.question3="{ item }">
        <template v-if="!item.question3"></template>
        <v-chip
          text-color="white"
          small
          v-else
          :color="item.question3 === 'yes' ? 'green' : 'red'"
          >{{ item.question3 }}</v-chip
        >
      </template>
      <!-- phone validated -->
      <template v-slot:item.phoneValidated="{ item }">
        <template v-if="!item.phoneValidated"></template>
        <v-chip
          text-color="white"
          small
          v-else
          :color="item.phoneValidated === 'yes' ? 'green' : 'red'"
          >{{ item.phoneValidated }}</v-chip
        >
      </template>
      <!-- system picked by -->
      <template v-slot:item.systemPickedBy="{ item }">
        <template v-if="!item.systemPickedBy"></template>
        <v-chip
          text-color="white"
          small
          v-else
          :color="item.systemPickedBy === 'c' ? 'blue' : 'grey'"
          >{{ item.systemPickedBy }}</v-chip
        >
      </template>
      <!-- type -->
      <template v-slot:item.wearExtensionsBefore="{ item }">
        <template v-if="!item.wearExtensionsBefore"></template>
        <v-chip
          text-color="white"
          small
          v-else
          :color="item.wearExtensionsBefore === 'First Time' ? 'blue' : 'grey'"
          >{{ item.wearExtensionsBefore }}</v-chip
        >
      </template>
      <!-- for salon links -->
      <template v-slot:item.salon1="{ item }">
        <v-btn
          color="blue"
          v-if="item.salon1 !== 'no salon'"
          :to="{ name: 'Salons', query: { navid: item.salon1 } }"
          x-small
          >{{ item.salon1 }}</v-btn
        >
        <span v-else>{{ item.salon1 }}</span>
      </template>
      <template v-slot:item.salon2="{ item }">
        <v-btn
          color="yellow"
          v-if="item.salon2 !== 'no salon'"
          :to="{ name: 'Salons', query: { navid: item.salon2 } }"
          x-small
          >{{ item.salon2 }}</v-btn
        >
        <span v-else>{{ item.salon2 }}</span>
      </template>
      <template v-slot:item.salon3="{ item }">
        <v-btn
          color="pink"
          v-if="item.salon3 !== 'no salon'"
          :to="{ name: 'Salons', query: { navid: item.salon3 } }"
          x-small
          >{{ item.salon3 }}</v-btn
        >
        <span v-else>{{ item.salon3 }}</span>
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | localeDate(true) }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | localeDate(true) }}
      </template>
      <!-- question1m -->
      <template v-slot:item.question1m="{ item }">
        <template v-if="!item.question1m"></template>
        <template v-else>
          <v-chip
            class="ma-1"
            v-for="(answer, index) in item.question1m
              .replace(' ', '')
              .split(',')"
            :key="index"
            :color="'blue'"
            x-small
            text-color="white"
            >{{ answer }}</v-chip
          ></template
        >
      </template>
      <!-- question1 -->
      <template v-slot:item.question1="{ item }">
        <template v-if="!item.question1"></template>
        <v-chip v-else :color="'blue'" x-small text-color="white">{{
          item.question1
        }}</v-chip>
      </template>
      <!-- question2 -->
      <template v-slot:item.question2="{ item }">
        <template v-if="!item.question2"></template>
        <v-chip v-else :color="'blue'" x-small text-color="white">{{
          item.question2
        }}</v-chip>
      </template>
      <!-- deferred emails -->
      <template v-slot:item.deferredEmails="{ item }">
        <v-tooltip
          top
          max-width="200"
          :color="
            mail === DEFERRED_EMAIL.FOLLOW_UP
              ? 'green'
              : mail === DEFERRED_EMAIL.DISCOUNT
              ? 'orange'
              : mail === 'none'
              ? 'grey'
              : 'blue'
          "
          v-for="(mail, index) in item.deferredEmails"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="ma-1"
              :color="
                mail === DEFERRED_EMAIL.FOLLOW_UP
                  ? 'green'
                  : mail === DEFERRED_EMAIL.DISCOUNT
                  ? 'orange'
                  : mail === 'none'
                  ? 'grey'
                  : 'blue'
              "
              x-small
              text-color="white"
              >{{ mail }}</v-chip
            >
          </template>
          <span>{{ tooltipText(mail) }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- NOTE: PAGINATION -->
    <div class="text-center pt-2" v-if="consumers && consumers.length > 0">
      <v-row class="mb-0 pb-0" justify="center">
        <v-col class="mb-0 pb-0" cols="2">
          <v-text-field
            @input="debounceSelectedPage"
            :value="page"
            dense
            outlined
            type="number"
            label="enter page number"
            class="pb-0 mb-0" /></v-col
      ></v-row>
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
  import _ from 'lodash'
  import { mapActions } from 'vuex'

  import {
    AdditionalSystemOptions,
    DEFERRED_EMAIL,
    SystemOptions
  } from '@/config/enums'
  import { getConsumersTableInfo } from '@/config/tables.helper'

  import DateFilter from '@/components/DateFilter.vue'
  import FilterField from '@/components/FilterField.vue'
  import { getThreeMonthsAgo, getTodayString } from '@/utils/helpers'
  import { saveAs } from 'file-saver'
  import moment from 'moment'

  export default {
    data() {
      return {
        showUtmColumns: false,
        getTodayString,
        exportStartDate: getThreeMonthsAgo(),
        exportEndDate: getTodayString(),
        // pagintaion
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        // selectedPage: null,
        // sorting
        sortBy: 'updatedAt',
        sortDesc: true,
        // search
        startDate: getThreeMonthsAgo(),
        endDate: getTodayString(),
        search: { startDate: getThreeMonthsAgo(), endDate: getTodayString() },
        // other
        isExportingData: false,
        filters: getConsumersTableInfo(this).filters,
        headers: [],
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        notification: '',
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: getConsumersTableInfo(this).item,
        defaultItem: getConsumersTableInfo(this).item,
        DEFERRED_EMAIL,
        isLoading: false
      }
    },
    computed: {
      consumers() {
        return this.$store.state.consumers.list
      },
      totalConsumers() {
        return this.$store.state.consumers.totalCount
      },
      systemBookedOptions() {
        return [
          ...Object.values(SystemOptions),
          ...Object.values(AdditionalSystemOptions)
        ]
      }
    },
    methods: {
      ...mapActions([
        'getConsumersCsv',
        'editConsumer',
        'fetchConsumersTableData'
      ]),
      tooltipText(mail) {
        switch (mail) {
          case DEFERRED_EMAIL.WF4S_CODE:
            return 'Salon created consultation.'
          case DEFERRED_EMAIL.NO_ACT_24:
            return "Consumer has salons, but didn't message or call within 24 hours. We sent an email to encourage them to message the salon."
          case DEFERRED_EMAIL.NO_ACT_48_DISCOUNT:
            return "Consumer has salons, but didn't message or call within 48 hours. We sent an email telling them they can get 10% on their 1st HD Application."
          case DEFERRED_EMAIL.DISCOUNT:
            return 'Consumer has messaged the salon and Salon messaged or called back within 24 hours. Alternatively, consumer called the salon. They received the email to tell us their consultation date and time and to generate their discount code. If there are multiple “discount” tags that means Consumer messaged more than 1 salon.'
          case DEFERRED_EMAIL.FOLLOW_UP:
            return "Consumer has messaged the salon, but Salon didn't message or call them through our app. If there are multiple “follow-up” tags that means Consumer messaged more than 1 salon."
          case DEFERRED_EMAIL.WF4H_CODE:
            return 'Consumer has generated a discount code from the WF6 chat page and told us their consultation date and time. Alternatively, We aka the Admin has generated the code for them from the Consultation database or admin chat page.'
          case 'none':
            return 'Consumer has no salons.'
          default:
            return ''
        }
      },
      async handleUpdateOptions(options) {
        this.sortBy = options.sortBy[0] ? options.sortBy[0] : 'updatedAt'
        this.sortDesc = options.sortDesc
        await this.updateData()
      },
      async updateData() {
        this.isLoading = true
        await this.fetchConsumersTableData({
          page: this.page,
          limit: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          search: this.search
        })
        this.pageCount = Math.ceil(
          this.$store.state.consumers.totalCount / this.itemsPerPage
        )
        this.isLoading = false
      },
      async handleDateChange(dates) {
        this.page = 1
        this.search['startDate'] = dates.startDate
        this.search['endDate'] = dates.endDate
        await this.updateData()
      },
      async handleFilterChange(value, header) {
        this.page = 1
        this.search[header.value] = value
        await this.updateData()
      },
      async handleExportData() {
        this.isExportingData = true
        let transform = {}

        getConsumersTableInfo(this)
          .headers.concat(getConsumersTableInfo(this).additionalHeaders)
          .forEach(h => {
            if (h.csv !== false) {
              transform[h.text] = h.value
            }
          })
        const data = await this.getConsumersCsv({
          transform,
          startDate: this.exportStartDate,
          endDate: this.exportEndDate
        })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'consumers.csv')
        this.isExportingData = false
      },
      editItem(item) {
        this.editedIndex = this.consumers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem(item) {
        this.editedIndex = this.consumers.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      async deleteItemConfirm() {
        this.consumers.splice(this.editedIndex, 1)
        this.closeDelete()
        // dispatch -> edit with active:false
        await this.editConsumer({ id: this.editedItem.id, active: false })
        if (!this.$store.state.consumers.error) {
          this.snackbarColor = 'red'
          this.notification = `Consumer deleted.`
          this.snackbar = true
        }
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async handleSave() {
        if (this.editedIndex > -1) {
          Object.assign(this.consumers[this.editedIndex], this.editedItem)
          // dispatch
          await this.editConsumer({
            id: this.editedItem.id,
            memo: this.editedItem.memo,
            applicationBooked: this.editedItem.applicationBooked,
            systemBooked: this.editedItem.systemBooked
          })
          if (!this.$store.state.consumers.error) {
            this.snackbarColor = 'green'
            this.notification = 'Memo saved.'
            this.snackbar = true
          }
        }
        this.close()
      },
      debounceSelectedPage: _.debounce(function (value) {
        // this.selectedPage = +value
        if (value && +value <= this.pageCount) this.page = +value
      }, 1000)
    },
    created() {
      this.headers = getConsumersTableInfo(this).headers
    },
    watch: {
      showUtmColumns() {
        if (this.showUtmColumns) {
          this.headers = getConsumersTableInfo(this).headers.concat(
            getConsumersTableInfo(this).additionalHeaders
          )
        } else {
          this.headers = getConsumersTableInfo(this).headers
        }
      },
      exportStartDate() {
        if (
          moment(this.exportEndDate).isAfter(
            moment(this.exportStartDate).add(3, 'M'),
            'day'
          )
        )
          this.exportEndDate = moment(this.exportStartDate)
            .add(3, 'M')
            .format('yyyy-MM-DD')
      },
      exportEndDate() {
        if (
          moment(this.exportStartDate).isBefore(
            moment(this.exportEndDate).subtract(3, 'M'),
            'day'
          )
        )
          this.exportStartDate = moment(this.exportEndDate)
            .subtract(3, 'M')
            .format('yyyy-MM-DD')
      },
      async page() {
        await this.updateData()
      }
    },

    components: { FilterField, DateFilter }
  }
</script>
