import api from '@/services/api.service'

// state
export const state = { list: [], loading: false, error: '' }

// mutations
export const mutations = {
  setupsFetched: (state, setups) => {
    state.list = setups
  },
  setupAdded: (state, setup) => state.list.unshift(setup),
  loadingSetupsSet: state => (state.loading = true),
  loadingSetupsCleared: state => (state.loading = false),
  errorSetupsSet: (state, error) => (state.error = error),
  errorSetupsCleared: state => (state.error = '')
}
// getters
export const getters = {
  lastAgreement: state => state.list[0]
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorSetupsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingSetupsCleared')
  setTimeout(() => {
    commit('errorSetupsCleared')
  }, 10000)
}

// actions
export const actions = {
  fetchSetups: async ({ commit }) => {
    commit('loadingSetupsSet')
    try {
      const response = await api.getSetups()
      commit('setupsFetched', response.data.data)
      commit('loadingSetupsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addSetup: async ({ commit }, setup) => {
    commit('loadingSetupsSet')
    try {
      const response = await api.postSetup(setup)
      commit('setupAdded', response.data.data)
      commit('errorSetupsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendCCPA: async ({ commit }, payload) => {
    commit('loadingSetupsSet')
    try {
      await api.sendCCPA(payload)
      commit('errorSetupsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
