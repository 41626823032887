<template>
  <v-container fluid>
    <v-data-table
      dense
      :headers="headers"
      :items="messagesAndCalls"
      item-key="consumerId + salonId"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 ml-3"
      @page-count="pageCount = $event"
      :server-items-length="totalMessagesAndCalls"
      :loading="isLoading"
      @update:options="handleUpdateOptions"
      @dblclick:row="handleOpen"
    >
      <template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification }}</v-snackbar
        >
        <v-toolbar flat>
          <v-toolbar-title>Messages And Calls</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="title"
            >Count: {{ $store.state.messages.totalMessagesAndCallsCount }}</span
          >
          <v-spacer></v-spacer>
          <!-- reset filters -->
          <v-btn
            color="secondary"
            class="mx-5"
            small
            @click="handleResetFilters"
          >
            <v-icon class="mx-1" small>mdi-reload</v-icon> Reset filters
          </v-btn>
          <!-- export -->
          <v-btn @click="handleExportData" :loading="isExportingData">
            <v-icon class="mx-1">mdi-download</v-icon>
            Export CSV
          </v-btn>
        </v-toolbar>
      </template>
      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="handleFilterChange($event, header)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td>
        </tr>
      </template>
      <!-- note: COLS DESIGN -->
      <!-- system -->
      <template v-slot:item.system="{ item }">
        <v-chip x-small class="ma-2" color="pink" label text-color="white"
          ><v-icon x-small left> mdi-label </v-icon>{{ item.system }}</v-chip
        >
      </template>
      <!-- terms -->
      <template v-slot:item.terms="{ item }">
        <v-chip
          :color="
            item.terms === 'accepted'
              ? 'green'
              : item.terms === 'declined'
              ? 'red'
              : 'yellow'
          "
          small
          :text-color="item.terms === 'no answer' ? 'grey' : 'white'"
          >{{ item.terms }}</v-chip
        >
        {{
          item.terms === 'accepted'
            ? moment(new Date(item.termsAcceptDate)).format('YYYY-MM-DD')
            : item.terms === 'declined'
            ? moment(new Date(item.termsDeclineDate)).format('YYYY-MM-DD')
            : ''
        }}
      </template>

      <!-- promotion -->
      <template v-slot:item.sendPromotion="{ item }">
        <v-chip
          small
          text-color="white"
          :color="item.sendPromotion === 'yes' ? 'green' : 'red'"
          >{{ item.sendPromotion }}</v-chip
        >
      </template>

      <!-- salon replied -->
      <template v-slot:item.salonReplied="{ item }">
        <v-chip
          :color="item.salonReplied === 'yes' ? 'green' : 'red'"
          text-color="white"
          >{{ item.salonReplied }}</v-chip
        >
      </template>
      <!-- salon replied in -->
      <template v-slot:item.salonRepliedTime="{ item }">
        <v-chip
          v-if="item.salonRepliedTime > 0"
          :color="getTimeColor(item.salonRepliedTime).bcg"
          :text-color="getTimeColor(item.salonRepliedTime).text"
          >{{ getTime(item.salonRepliedTime) + 'h' }}</v-chip
        >
      </template>
      <template v-slot:item.consumer1stMessage="{ item }">
        <span v-if="!item.consumer1stMessage"></span>
        <span v-else>{{ item.consumer1stMessage | localeDate(true) }}</span>
      </template>
      <template v-slot:item.consumer1stCall="{ item }">
        <span v-if="!item.consumer1stCall"></span>
        <span v-else>{{ item.consumer1stCall | localeDate(true) }}</span>
      </template>
      <template v-slot:item.salon1stMessage="{ item }">
        <span v-if="!item.salon1stMessage"></span>
        <span v-else>{{ item.salon1stMessage | localeDate(true) }}</span>
      </template>
      <template v-slot:item.salon1stCall="{ item }">
        <span v-if="!item.salon1stCall"></span>
        <span v-else>{{ item.salon1stCall | localeDate(true) }}</span>
      </template>

      <!-- deferred emails -->
      <template v-slot:item.deferredEmails="{ item }">
        <v-tooltip
          top
          max-width="200"
          :color="
            mail === DEFERRED_EMAIL.FOLLOW_UP
              ? 'green'
              : mail === DEFERRED_EMAIL.DISCOUNT
              ? 'orange'
              : mail === 'none'
              ? 'grey'
              : 'blue'
          "
          v-for="(mail, index) in item.deferredEmails"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="ma-1"
              :color="
                mail === DEFERRED_EMAIL.FOLLOW_UP
                  ? 'green'
                  : mail === DEFERRED_EMAIL.DISCOUNT
                  ? 'orange'
                  : mail === 'none'
                  ? 'grey'
                  : 'blue'
              "
              x-small
              text-color="white"
              >{{ mail }}</v-chip
            >
          </template>
          <span>{{ tooltipText(mail) }}</span>
        </v-tooltip>
      </template>

      <!-- consultation -->
      <template v-slot:item.hasDiscount="{ item }">
        <v-chip
          small
          v-if="item.hasDiscount === 'yes' && item.discountDateAndTime"
          color="green"
          text-color="white"
          >{{ item.discountDateAndTime | localeDate(true) }}</v-chip
        >
        <v-chip
          small
          v-else-if="item.hasDiscount === 'no'"
          color="yellow"
          text-color="black"
          >no answer</v-chip
        >
        <span v-else></span>
      </template>

      <!-- consultation completed  -->
      <template v-slot:item.consultationCompleted="{ item }">
        <span v-if="!item.consultationCompleted"></span>
        <span v-else>
          <v-chip
            class="ma-1"
            :color="
              item.consultationCompleted === CONSULTATION_COMPLETED_OPTIONS.YES
                ? 'green'
                : item.consultationCompleted ===
                  CONSULTATION_COMPLETED_OPTIONS.RESCHEDULED
                ? 'yellow'
                : 'red'
            "
            x-small
            :text-color="
              item.consultationCompleted ===
              CONSULTATION_COMPLETED_OPTIONS.RESCHEDULED
                ? 'grey'
                : 'white'
            "
            >{{ item.consultationCompleted }}</v-chip
          ></span
        >
      </template>

      <!-- application booked  -->
      <template v-slot:item.applicationBooked="{ item }">
        <span v-if="!item.applicationBooked"></span>
        <span v-else>
          <v-chip
            class="ma-1"
            :color="
              item.applicationBooked === APPLICATION_BOOKED_OPTIONS.YES
                ? 'green'
                : 'red'
            "
            x-small
            text-color="white"
            >{{ item.applicationBooked }}</v-chip
          ></span
        >
      </template>
    </v-data-table>
    <!-- NOTE: PAGINATION -->
    <div
      class="text-center pt-2"
      v-if="messagesAndCalls && messagesAndCalls.length > 0"
    >
      <v-row class="mb-0 pb-0" justify="center">
        <v-col class="mb-0 pb-0" cols="2">
          <v-text-field
            @input="debounceSelectedPage"
            :value="page"
            dense
            outlined
            type="number"
            label="enter page number"
            class="pb-0 mb-0" /></v-col
      ></v-row>
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
  import FilterField from '@/components/FilterField.vue'
  import {
    APPLICATION_BOOKED_OPTIONS,
    CONSULTATION_COMPLETED_OPTIONS,
    DEFERRED_EMAIL
  } from '@/config/enums'
  import { getMessagesAndCallsTableInfo } from '@/config/tables.helper'
  import { saveAs } from 'file-saver'
  import moment from 'moment'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        moment,
        DEFERRED_EMAIL,
        // pagintaion
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        // selectedPage: null,
        // sorting
        sortBy: 'consumer1stMessage',
        sortDesc: true,
        // search
        filters: getMessagesAndCallsTableInfo(this).filters,
        // other
        isExportingData: false,
        headers: [],
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        notification: '',
        isLoading: false,
        CONSULTATION_COMPLETED_OPTIONS,
        APPLICATION_BOOKED_OPTIONS
      }
    },
    computed: {
      messagesAndCalls() {
        return this.$store.state.messages.messagesAndCalls
      },
      totalMessagesAndCalls() {
        return this.$store.state.messages.totalMessagesAndCallsCount
      }
    },
    methods: {
      ...mapActions([
        'getMessagesAndCallsCsv',
        'fetchMessagesAndCallsTableData'
      ]),
      async handleUpdateOptions(options) {
        this.sortBy = options.sortBy[0]
          ? options.sortBy[0]
          : 'consumer1stMessage'
        this.sortDesc = options.sortDesc
        await this.updateData()
      },
      async updateData() {
        this.isLoading = true
        await this.fetchMessagesAndCallsTableData({
          page: this.page,
          limit: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          search: this.filters
        })
        this.pageCount = Math.ceil(
          this.totalMessagesAndCalls / this.itemsPerPage
        )
        this.isLoading = false
      },
      async handleFilterChange(value, header) {
        this.page = 1
        this.filters[header.value] = value
        await this.updateData()
      },
      async handleExportData() {
        this.isExportingData = true
        let transform = {}
        getMessagesAndCallsTableInfo(this).headers.forEach(h => {
          if (h.csv !== false) {
            transform[h.text] = h.value
          }
        })
        const data = await this.getMessagesAndCallsCsv({ transform })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'messages_calls.csv')
        this.isExportingData = false
      },
      handleResetFilters() {
        this.filters = getMessagesAndCallsTableInfo(this).filters
        this.page = 1
        localStorage.removeItem('mc_filters')
        localStorage.removeItem('mc_page')

        this.updateData()
      },
      handleOpen(event, row) {
        const { item } = row

        this.$router.push({
          name: 'AdminCommunication',
          params: {
            consumerId: item.consumerId,
            salonId: item.salonId
          }
        })
      },
      getTime(time) {
        return (+time / 60).toFixed(2)
      },
      getTimeColor(time) {
        if (time < 6 * 60) {
          return { bcg: 'green', text: 'white' }
        } else if (time < 24 * 60) {
          return { bcg: 'yellow', text: 'black' }
        } else if (time < 60 * 60) {
          return { bcg: 'orange', text: 'black' }
        } else {
          return { bcg: 'red', text: 'white' }
        }
      },
      tooltipText(mail) {
        switch (mail) {
          case DEFERRED_EMAIL.WF4S_CODE:
            return 'Salon created consultation.'
          case DEFERRED_EMAIL.NO_ACT_24:
            return "Consumer has salons, but didn't message or call within 24 hours. We sent an email to encourage them to message the salon."
          case DEFERRED_EMAIL.NO_ACT_48_DISCOUNT:
            return "Consumer has salons, but didn't message or call within 48 hours. We sent an email telling them they can get 10% on their 1st HD Application."
          case DEFERRED_EMAIL.DISCOUNT:
            return 'Consumer has messaged the salon and Salon messaged or called back within 24 hours. Alternatively, consumer called the salon. They received the email to tell us their consultation date and time and to generate their discount code. If there are multiple “discount” tags that means Consumer messaged more than 1 salon.'
          case DEFERRED_EMAIL.FOLLOW_UP:
            return "Consumer has messaged the salon, but Salon didn't message or call them through our app. If there are multiple “follow-up” tags that means Consumer messaged more than 1 salon."
          case DEFERRED_EMAIL.WF4H_CODE:
            return 'Consumer has generated a discount code from the WF6 chat page and told us their consultation date and time. Alternatively, We aka the Admin has generated the code for them from the Consultation database or admin chat page.'
          case 'none':
            return 'Consumer has no salons.'
          default:
            return ''
        }
      },
      debounceSelectedPage: _.debounce(function (value) {
        // this.selectedPage = +value
        if (value && +value <= this.pageCount) this.page = +value
      }, 1000)
    },
    watch: {
      async page(newValue, oldValue) {
        localStorage.setItem('mc_page', JSON.stringify(this.page))

        await this.updateData()
      }
    },
    created() {
      // filters LS
      const mc_filters = JSON.parse(localStorage.getItem('mc_filters'))
      if (mc_filters) this.filters = mc_filters
      const mc_page = JSON.parse(localStorage.getItem('mc_page'))
      this.page = mc_page ? +mc_page : 1

      // headers
      this.headers = getMessagesAndCallsTableInfo(this).headers
    },
    destroyed() {
      localStorage.setItem('mc_filters', JSON.stringify(this.filters))
    },
    components: { FilterField }
  }
</script>
