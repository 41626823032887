<template>
  <v-container class="pt-0">
    <v-card-text class="breakword title pb-0"
      >If you've worn any of the types below please select the type you would
      like to wear again</v-card-text
    >
    <v-card-text class="pt-0">
      <v-row class="justify-center">
        <v-card-text class="text-center pb-0">Choose one</v-card-text>

        <v-col class="d-flex justify-center flex-column align-center" cols="6">
          <div
            @click="handleSelect(NewSystemOptions.BONDED_EXTENSIONS)"
            :class="
              answer === NewSystemOptions.BONDED_EXTENSIONS
                ? 'custom-btn-selected'
                : 'custom-btn'
            "
          >
            <span
              :class="
                answer === NewSystemOptions.BONDED_EXTENSIONS
                  ? 'custom-btn-selected__main-txt'
                  : 'custom-btn__main-txt'
              "
              >Bonded</span
            >
            <span
              :class="
                answer === NewSystemOptions.BONDED_EXTENSIONS
                  ? 'custom-btn-selected__caption-txt'
                  : 'custom-btn__caption-txt'
              "
              >aka individual, fusion keratin</span
            >
          </div>
        </v-col>
        <v-col class="d-flex justify-center" cols="6">
          <div
            :class="
              answer === NewSystemOptions.TAPE
                ? 'custom-btn-selected'
                : 'custom-btn'
            "
            @click="handleSelect(NewSystemOptions.TAPE)"
          >
            <span
              :class="
                answer === NewSystemOptions.TAPE
                  ? 'custom-btn-selected__main-txt'
                  : 'custom-btn__main-txt'
              "
              >Tape-Ins</span
            >
          </div>
        </v-col>
        <v-col class="d-flex justify-center" cols="6">
          <div
            :class="answer === 'either' ? 'custom-btn-selected' : 'custom-btn'"
            @click="handleSelect('either')"
          >
            <span
              :class="
                answer === 'either'
                  ? 'custom-btn-selected__main-txt'
                  : 'custom-btn__main-txt'
              "
              >Either</span
            >
          </div>
        </v-col>
        <v-col class="d-flex justify-center" cols="6">
          <div
            :class="
              answer === 'i am not sure' ? 'custom-btn-selected' : 'custom-btn'
            "
            @click="handleSelect('i am not sure')"
          >
            <span
              :class="
                answer === 'i am not sure'
                  ? 'custom-btn-selected__main-txt'
                  : 'custom-btn__main-txt'
              "
              >I am not sure</span
            >
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-container>
</template>

<script>
  import { NewSystemOptions } from '@/config/enums'
  export default {
    data() {
      return {
        NewSystemOptions,
        screen: 'consumerPickOther',
        question: 'systemInfo',
        answer: ''
      }
    },
    computed: {
      nextScreen() {
        if (
          this.answer === NewSystemOptions.BONDED_EXTENSIONS ||
          this.answer === NewSystemOptions.TAPE
        )
          return 'systemInfo'
        else return 'lifestyleExtensions'
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false) {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          wantAgain: this.answer,
          goNext
        })
      },
      handleSelect(answer) {
        this.answer = answer
        this.emitSelect(true)
      }
    },
    created() {
      // only if system info is one of those 3 options
      if (
        this.$route.query[this.question] === this.NewSystemOptions.TAPE ||
        this.$route.query[this.question] ===
          this.NewSystemOptions.BONDED_EXTENSIONS ||
        this.$route.query[this.question] === 'either' ||
        this.$route.query[this.question] === 'i am not sure'
      ) {
        this.answer = this.$route.query[this.question] || ''
        this.emitSelect()
      }
    }
  }
</script>

<style scoped lang="scss">
  .custom-btn {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #cecece;
    height: 150px;
    width: 100%;
    transition: all 0.28s;
    padding-left: 5px;
    padding-right: 5px;

    &:hover {
      background-color: #a3a3a3;
    }

    &__main-txt {
      color: #000;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }

    &__caption-txt {
      color: #828282;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }

  .custom-btn-selected {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;
    width: 100%;
    background-color: #2b2b2b;
    transition: all 0.28s;
    padding-left: 5px;
    padding-right: 5px;

    &:hover {
      background-color: #5e5e5e;
    }

    &__main-txt {
      color: #fff;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }

    &__caption-txt {
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }
</style>
