import { render, staticRenderFns } from "./WF2_1_NoSalonFound.vue?vue&type=template&id=892112e6&"
import script from "./WF2_1_NoSalonFound.vue?vue&type=script&lang=js&"
export * from "./WF2_1_NoSalonFound.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports