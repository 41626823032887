<template>
  <v-container class="pt-0" v-if="!$store.state.consumers.loading">
    <v-card class="elevation-12 mx-auto" max-width="500">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Unsubscribe</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="mt-5">
        <v-row class="justify-center py-0 align-start">
          <v-col cols="12" class="py-0 pb-3 d-flex">
            <v-simple-checkbox
              v-model="notSendPromotion"
              class="checkbox"
              :ripple="false"
            />
            <v-card-text class="pa-0"
              >I want to opt out from Hairdreams promotional
              emails.</v-card-text
            >
          </v-col>
        </v-row>
        <v-row class="justify-center py-0 align-start">
          <v-col cols="12" class="py-0 pb-3 d-flex">
            <v-simple-checkbox
              class="checkbox"
              v-model="unsubscribe"
              :ripple="false"
            />
            <v-card-text class="pa-0"
              >I want to opt out of receiving messages from the salon,
              appointment reminders, personalized offers and other important
              emails related to the Hairdreams Salon Finder. I understand that
              opting out might affect my access to the Hairdreams Salon
              Finder.</v-card-text
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="handleSubmit"
          color="user_primary"
          dark
          :loading="isSending"
          >Submit</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        isSending: false,
        notSendPromotion: false,
        unsubscribe: false
      }
    },
    methods: {
      ...mapActions(['editConsumer', 'fetchConsumer']),
      async handleSubmit() {
        this.isSending = true
        await this.editConsumer({
          id: this.$route.params.consumerId,
          sendPromotion: !this.notSendPromotion,
          unsubscribed: this.unsubscribe
        })
        if (!this.$store.state.consumers.error) {
          this.isSending = false
          this.$router.replace({
            name: 'UnsubscribeThanks',
            params: { consumerId: this.$route.params.consumerId }
          })
          // if (this.$store.state.consumers.consumer.salonsNear.length > 0) {
          //   // ako consumer ima pronadjene salone salji ga na wf2
          //   this.$router.replace({
          //     name: 'GreatNews',
          //     params: {
          //       consumerId: this.$route.params.consumerId,
          //       salonId:
          //         this.$store.state.consumers.consumer.salonsNear[0].salon._id
          //     }
          //   })
          // }
          // // ako nema salji ga na website
          // else {
          //   window.location.href = 'https://iwanthairdreams.com'
          // }
        }
      }
    },
    async created() {
      await this.fetchConsumer(this.$route.params.consumerId)
    }
  }
</script>

<style scoped>
  .checkbox {
    align-self: flex-start;
  }
</style>
