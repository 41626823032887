<template>
  <v-container fluid
    ><v-data-table
      dense
      :headers="headers"
      :items="salons"
      item-key="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 ml-3"
      @page-count="pageCount = $event"
      :server-items-length="totalSalons"
      :loading="isLoading"
      @update:options="handleUpdateOptions"
    >
      <template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification }}</v-snackbar
        >
        <v-toolbar flat class="mt-5">
          <v-row align="center">
            <!-- search system -->
            <v-col cols="5" sm="5" md="5">
              <v-select
                v-model="searchSystem"
                :items="allSystemOptions"
                label="System"
                persistent-hint
              ></v-select>
            </v-col>
            <!-- search zip -->
            <v-col cols="3" sm="3" md="3">
              <v-text-field v-model="searchZip" label="Zip"></v-text-field>
            </v-col>
            <!-- find salons by system and zip -->
            <v-col cols="2" sm="2" md="2">
              <v-btn
                :disabled="!searchZip || !searchSystem"
                small
                class="ml-3"
                @click="handleFindSalons"
              >
                <v-icon class="mx-1">mdi-magnify</v-icon>
                Find Salons</v-btn
              >
            </v-col>
            <!-- get all salons -->
            <v-col cols="2" sm="2" md="2" class="d-flex justify-end">
              <v-btn small class="ml-3" @click="handleGetAllSalons">
                Get All Salons</v-btn
              ></v-col
            >
          </v-row>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>Salons</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="title">Count: {{ $store.state.salons.totalCount }}</span>

          <!-- CSV -->
          <template v-if="$store.getters.adminLoggedIn">
            <!-- import -->
            <!-- <v-col class="d-flex justify-end align-center">
              <v-spacer></v-spacer>
              <v-file-input
                class="mt-5"
                v-model="importFile"
                accept=".csv"
                dense
                small-chips
              ></v-file-input>
              <v-btn
                small
                class="ml-3"
                :disabled="!importFile"
                @click="handleImportFile"
              >
                <v-icon class="mx-1">mdi-upload</v-icon>
                Import CSV</v-btn
              >
            </v-col> -->
          </template>

          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- filter by archive -->
          <v-switch hide-details v-model="showHidden" label="Show hidden" />
          <v-spacer></v-spacer>
          <v-btn
            class="mx-5"
            small
            @click="handleExportData"
            :loading="isExportingData"
          >
            <v-icon class="mx-1">mdi-download</v-icon>
            Export CSV
          </v-btn>

          <!-- create new salon -->
          <v-btn color="user_secondary" dark small @click="handleCreateNew">
            Create New Salon
          </v-btn>

          <!-- activate/deactivate salon -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure that you want to
                {{ editedItem.active === 'yes' ? 'deactivate' : 'activate' }}
                {{ editedItem.name }}?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="handleSalonActivationConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="handleFilterChange($event, header)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td>
        </tr>
      </template>

      <!-- actions -->
      <template v-slot:item.actions="{ item }">
        <!-- call only -->
        <v-icon
          small
          class="mr-2"
          @click="handleCallOnly(item, item.callOnly ? false : true)"
          >{{ item.callOnly ? 'mdi-message-off' : 'mdi-message' }}</v-icon
        >

        <!-- edit salon -->
        <v-icon small class="mr-2" @click="handleEdit(item)">
          mdi-pencil
        </v-icon>
        <!-- deactivate -->
        <v-icon
          v-if="item.active === 'yes'"
          small
          @click="handleSalonActivation(item)"
        >
          mdi-delete
        </v-icon>
        <!-- activate -->
        <v-icon v-else small @click="handleSalonActivation(item)">
          mdi-account-reactivate
        </v-icon>
      </template>

      <!-- print agreement -->
      <template v-slot:item.agreement="{ item }">
        <v-icon
          v-if="item.terms === 'accepted' && !loadingPrint.includes(item.id)"
          @click="handleGetAgreement(item)"
          >mdi-printer</v-icon
        >
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="item.terms === 'accepted' && loadingPrint.includes(item.id)"
        ></v-progress-circular>
      </template>

      <!-- terms -->
      <template v-slot:item.terms="{ item }">
        <v-chip
          :color="
            item.terms === 'accepted'
              ? 'green'
              : item.terms === 'declined'
              ? 'red'
              : 'yellow'
          "
          small
          :text-color="item.terms === 'no answer' ? 'grey' : 'white'"
          >{{ item.terms }}</v-chip
        >
        {{
          item.terms === 'accepted'
            ? moment(new Date(item.termsInfo.accept)).format('YYYY-MM-DD')
            : item.terms === 'declined'
            ? moment(new Date(item.termsInfo.decline)).format('YYYY-MM-DD')
            : ''
        }}
      </template>

      <!-- sms phones -->
      <template v-slot:item.smsPhones="{ item }">
        <span v-for="(phone, index) in item.smsPhones" :key="index">
          <v-chip
            v-if="phone.phoneNumber"
            small
            class="ma-2"
            :color="phone.validated ? 'green' : 'red'"
            label
            text-color="white"
            >{{ phone.phoneNumber }}</v-chip
          >
          <template v-else></template>
        </span>
      </template>

      <!-- sms phone validated -->
      <template v-slot:item.smsPhonesValidated="{ item }">
        <v-chip
          small
          class="ma-2"
          :color="
            item.smsPhonesValidated === 'yes'
              ? 'green'
              : item.smsPhonesValidated === 'no'
              ? 'red'
              : 'blue'
          "
          label
          text-color="white"
          >{{ item.smsPhonesValidated }}</v-chip
        >
      </template>

      <!-- system -->
      <template v-slot:item.systems="{ item }">
        <v-chip
          x-small
          class="ma-2"
          color="pink"
          label
          text-color="white"
          v-for="cat in item.systems"
          :key="cat"
          ><v-icon x-small left> mdi-label </v-icon>{{ cat }}</v-chip
        >
      </template>

      <!-- distance -->
      <template v-slot:item.distance="{ item }">
        <span v-if="item.distance">{{ item.distance | miles }}</span>
        <span v-else></span>
      </template>
      <!-- website -->
      <template v-slot:item.website="{ item }">
        <span v-if="item.website">
          <v-btn
            v-if="item.website.startsWith('http')"
            color="blue"
            :href="item.website"
            target="_blank"
            x-small
            >{{ item.website }}</v-btn
          >
          <span v-else>{{ item.website }}</span></span
        >
        <span v-else></span>
      </template>
      <!-- active -->
      <template v-slot:item.active="{ item }">
        <v-chip
          :color="item.active === 'yes' ? 'success' : 'error'"
          small
          text-color="white"
          >{{ item.active }}</v-chip
        >
      </template>

      <!-- message allowed (call only) -->
      <template v-slot:item.callOnly="{ item }">
        {{ !item.callOnly ? 'yes' : 'no' }}
      </template>

      <!-- hide -->
      <template v-slot:item.hideBtn="{ item }">
        <v-btn
          small
          plain
          v-if="!item.hideBtn"
          @click="handleHideSalon(item, true)"
          ><v-icon>mdi-archive-arrow-down</v-icon></v-btn
        >
        <v-btn plain small v-else @click="handleHideSalon(item, false)"
          ><v-icon>mdi-archive-arrow-up-outline</v-icon></v-btn
        >
      </template>

      <template v-slot:item.hide="{ item }">
        <v-chip small dark :color="item.hide === 'yes' ? 'blue' : 'grey'">{{
          item.hide
        }}</v-chip>
      </template>
    </v-data-table>
    <!-- NOTE: PAGINATION -->
    <div class="text-center pt-2" v-if="salons && salons.length > 0">
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
  import FilterField from '@/components/FilterField.vue'
  import { AdditionalSystemOptions, SystemOptions } from '@/config/enums'
  import { getSalonsTableInfo } from '@/config/tables.helper'
  import { saveAs } from 'file-saver'
  import moment from 'moment'
  import { mapActions } from 'vuex'

  export default {
    components: { FilterField },
    data() {
      return {
        showHidden: false,
        // pagintaion
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        // sorting
        sortBy: 'navid',
        sortDesc: true,
        // search
        search: {},
        // other
        isExportingData: false,
        filters: getSalonsTableInfo(this).filters,
        headers: [],
        loadingPrint: [],
        moment,
        searchZip: '',
        searchSystem: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        notification: '',
        isLoading: false,
        importFile: null,
        // crud
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: getSalonsTableInfo(this).item,
        defaultItem: getSalonsTableInfo(this).item
      }
    },
    computed: {
      salons() {
        return this.$store.state.salons.list
      },
      totalSalons() {
        return this.$store.state.salons.totalCount
      },
      allSystemOptions() {
        return [
          ...Object.values(SystemOptions),
          ...Object.values(AdditionalSystemOptions)
        ]
      }
    },
    methods: {
      ...mapActions([
        'getSalonsCsv',
        'editSalon',
        'fetchSalonsTableData',
        'getAgreement'
      ]),
      async handleCallOnly(salon, toCallOnly) {
        // const foundSalon = this.salons.find(s => s.id === salon.id)
        // foundSalon.hide = toHide ? 'yes' : 'no'
        // foundSalon.hideBtn = toHide

        await this.editSalon({ id: salon.id, callOnly: toCallOnly })
        await this.updateData()
      },
      async handleHideSalon(salon, toHide) {
        // const foundSalon = this.salons.find(s => s.id === salon.id)
        // foundSalon.hide = toHide ? 'yes' : 'no'
        // foundSalon.hideBtn = toHide

        await this.editSalon({ id: salon.id, hide: toHide })
        await this.updateData()
      },
      async handleUpdateOptions(options) {
        this.sortBy = options.sortBy[0] ? options.sortBy[0] : 'createdAt'
        this.sortDesc = options.sortDesc
        await this.updateData()
      },
      async updateData() {
        this.isLoading = true

        const options = {
          page: this.page,
          limit: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          search: this.search
        }

        // search by system & zip
        if (this.searchSystem && this.searchZip) {
          options.extra_system = this.searchSystem
          options.extra_zip = this.searchZip
        }

        await this.fetchSalonsTableData(options)
        this.pageCount = Math.ceil(
          this.$store.state.salons.totalCount / this.itemsPerPage
        )
        this.isLoading = false
      },
      async handleDateChange(dates) {
        this.page = 1
        this.search['startDate'] = dates.startDate
        this.search['endDate'] = dates.endDate
        await this.updateData()
      },
      async handleFilterChange(value, header) {
        this.page = 1
        this.search[header.value] = value
        await this.updateData()
      },
      async handleExportData() {
        this.isExportingData = true
        let transform = {}
        getSalonsTableInfo(this).headers.forEach(h => {
          if (h.csv !== false) {
            transform[h.text] = h.value
          }
        })
        const data = await this.getSalonsCsv({ transform })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'salons.csv')
        this.isExportingData = false
      },
      async handleGetAgreement(item) {
        this.loadingPrint.push(item.id)
        const data = await this.getAgreement(item.id)
        const pdfBlob = new Blob([data], { type: 'application/pdf' })
        saveAs(pdfBlob, item.name + '_agreement.pdf')
        this.loadingPrint = this.loadingPrint.filter(
          itemId => itemId !== item.id
        )
      },
      // crud
      handleCreateNew() {
        this.$router.push({ name: 'CreateSalon' })
      },
      handleEdit(item) {
        this.$router.push({
          name: 'EditPendingSalon',
          params: { salonId: item.id }
        })
      },
      async handleFindSalons() {
        this.sortBy = 'distance'
        this.sortDesc = true
        await this.updateData()
      },
      async handleGetAllSalons() {
        this.searchSystem = ''
        this.searchZip = ''
        await this.updateData()
      },
      async handleImportFile() {
        // if (!this.importFile) {
        //   return
        // }
        // await this.sendSalonsFile(this.importFile)
        // if (!this.$store.state.salons.error) {
        //   this.snackbarColor = 'green'
        //   this.notification = 'Salons imported.'
        //   this.snackbar = true
        // }
        // this.importFile = null
      },
      handleSalonActivation(item) {
        this.editedIndex = this.salons.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      async handleSalonActivationConfirm() {
        const name = this.editedItem.name
        const active = this.editedItem.active
        Object.assign(this.salons[this.editedIndex], {
          ...this.editedItem,
          active: this.editedItem.active === 'yes' ? 'no' : 'yes'
        })
        this.closeDelete()
        await this.editSalon({
          id: this.editedItem.id,
          active: this.editedItem.active === 'yes' ? false : true
        })
        if (!this.$store.state.salons.error) {
          this.notification = `${name} ${
            active === 'yes' ? 'deactivated' : 'activated'
          }.`
          this.snackbarColor = 'green'
          this.snackbar = true
        }
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }
    },
    watch: {
      async page(newValue, oldValue) {
        await this.updateData()
      },
      async showHidden(newValue, oldValue) {
        if (newValue) delete this.search.hide
        else this.search.hide = 'no'

        await this.updateData()
      }
    },
    created() {
      this.search.hide = 'no'
      if (this.$route.query['navid']) {
        this.filters['navid'] = this.$route.query['navid'].toString()
        this.search['navid'] = this.$route.query['navid'].toString()
      }

      this.headers = getSalonsTableInfo(this).headers
    }
  }
</script>
