<template>
  <v-container class="px-0 px-sm-3 pt-0" v-if="!$store.state.salons.loading">
    <v-card class="elevation-12 mx-auto" max-width="550">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto">{{
          titleText
        }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-0 pa-sm-3">
        <v-container>
          <v-card-title>Security Code</v-card-title>
          <v-card-subtitle
            >To keep your information safe, please provide the security code we
            just sent to your email address.</v-card-subtitle
          >

          <v-col cols="12" md="8" class="pl-4"
            ><v-text-field
              label="Security Code"
              hide-details
              v-model="selectedCode"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-btn
              light
              text
              color="blue"
              small
              @click="showSimpleModal = true"
            >
              Trouble verifying?
            </v-btn>
          </v-col>

          <v-row>
            <v-col cols="6" class="pl-5 d-flex justify-start">
              <v-btn small rounded @click="changeEmail">Change email</v-btn>
            </v-col>
            <v-col sm="6" class="d-flex justify-end">
              <v-btn
                class="white--text"
                small
                rounded
                color="user_primary"
                @click="verifyCode"
                :disabled="!isValidCodeForm"
                :loading="isSending"
                >Next
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <SimpleModal
      max-width="400"
      :showDialog="showSimpleModal"
      title="Contact Us"
      :message="contactText"
      @closeSimpleModal="closeSimpleModal"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        isSending: false,
        selectedCode: '',
        showSimpleModal: false,
        contactText:
          'If you have further questions or need immediate assistance, please feel free to contact us at <a href="mailto:hairdreams@bht-usa.com?subject=Salon Finder Assistance Needed">hairdreams@bht-usa.com</a> or call toll-free at 888-434-2471.'
      }
    },
    computed: {
      isValidCodeForm() {
        return !!this.selectedCode
      },
      isFromSalonHome() {
        return this.$route.query.from === 'SalonHome'
      },
      titleText() {
        if (this.isFromSalonHome) return 'Hairdreams Partner Account'
        else return 'Edit Salon Finder Page'
      }
    },
    methods: {
      ...mapActions(['validateCode']),
      closeSimpleModal() {
        this.showSimpleModal = false
      },
      changeEmail() {
        this.$router.replace({ name: 'FindSalon' })
      },
      async verifyCode() {
        this.isSending = true
        await this.validateCode(this.selectedCode)
        if (
          this.$store.state.salons.selectedSalon &&
          !this.$store.state.salons.error
        ) {
          // 1. sa salon home -> salon messages dashboard
          if (this.$route.query.from === 'SalonHome') {
            this.$router.push({
              name: 'SalonMessages',
              params: { salonId: this.$store.state.salons.selectedSalon._id }
            })
          }
          // 2. sa edit salon -> edit salon
          else {
            this.$router.push({
              name: 'EditSalon',
              params: { salonId: this.$store.state.salons.selectedSalon._id }
            })
          }
        }
        this.isSending = false
      }
    }
  }
</script>
