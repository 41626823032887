<template>
  <v-container justify="center">
    <v-dialog
      v-model="showDialog"
      @click:outside="onClose"
      @keydown.esc="() => $emit('close')"
      max-width="420"
    >
      <v-card
        class="elevation-12 mx-auto pb-3"
        max-width="420"
        v-if="!$store.state.consumers.loading"
      >
        <v-toolbar dark :color="color">
          <v-toolbar-title class="text-sm-h5 mx-auto"
            >{{ title }}
          </v-toolbar-title>
          <v-icon @click="onClose" style="cursor: pointer">mdi-close</v-icon>
        </v-toolbar>
        <!-- note: 1. AKO NEMA VEC GENERISAN CODE -->
        <template v-if="!isRevealed">
          <!-- note: first screen -->
          <template v-if="showScreen1 && !showScreen2 && salonNear">
            <v-card class="mx-5 my-5" color="#eee">
              <v-card-text>
                <div class="text-body-1 text-sm-h6 mb-3">
                  <span
                    >Ask
                    <span class="text-h6 text-sm-headline text-uppercase">{{
                      salonNear.salon.name
                    }}</span>
                    to schedule your consultation, and we will give you 10% off
                    your first Hairdreams application. Once you have your
                    consultation date and time, come back here to reveal your
                    code.
                  </span>
                </div></v-card-text
              >
            </v-card>
            <v-card-actions>
              <v-btn
                block
                rounded
                :color="color"
                class="mx-auto white--text"
                @click="() => (showScreen2 = true)"
                >I booked - Reveal my code</v-btn
              >
            </v-card-actions>
          </template>
          <!-- note: second screen -->
          <template v-if="showScreen2">
            <v-card class="mx-5 my-5" color="#eee">
              <v-card-text>
                <div class="text-body-1 text-sm-h6 mb-3">
                  <span>When is your consultation? </span>
                </div>
                <v-row class="align-end">
                  <!-- Date -->
                  <v-col cols="12" sm="6"
                    ><v-text-field
                      label="Date"
                      type="date"
                      hide-details
                      v-model="date"
                      :min="setMinDate ? getTodayString() : ''"
                  /></v-col>
                  <!-- Time -->
                  <v-col cols="12" sm="6">
                    <v-dialog
                      ref="dialog"
                      v-model="modal2"
                      :return-value.sync="time"
                      persistent
                      width="290px"
                      color="white"
                      dark
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          hide-details
                          :value="ampmTime(time)"
                          label="Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        format="ampm"
                        v-if="modal2"
                        v-model="time"
                        full-width
                        dark
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="white" @click="modal2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="white"
                          @click="$refs.dialog.save(time)"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <!-- note: samo kad se dolazi iz consultations jer nema u url -->
                <v-row v-if="!consumer && !salonNear">
                  <v-card-text>
                    <v-text-field
                      v-model="consumerId"
                      label="Consumer Id"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="salonId"
                      label="Salon Id"
                      outlined
                      dense
                    ></v-text-field
                  ></v-card-text>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card-actions>
              <v-btn
                block
                class="mx-auto white--text"
                rounded
                :disabled="!isValid"
                :color="color"
                @click="() => (showConfirm = true)"
                :loading="isSending"
                >Get code</v-btn
              >
            </v-card-actions>
          </template>
        </template>
        <!-- 2. AKO IMA VEC GENERISAN CODE -->
        <template v-if="isRevealed && consumer">
          <v-card class="mx-5 my-5" color="#eee">
            <v-card-text>
              <v-col class="d-flex justify-end pa-0">
                <v-btn
                  :color="color"
                  dark
                  small
                  class="text-uppercase"
                  @click="handlePrint"
                  >Print</v-btn
                ></v-col
              >
              <v-row id="printMe">
                <v-card elevation="3" width="100%" class="pb-3 mt-5 mx-5">
                  <v-toolbar :color="color" class="code-toolbar" width="100%">
                    <v-spacer></v-spacer>
                    <v-toolbar-items
                      width="100%"
                      class="d-flex align-center justify-center"
                    >
                      <v-img
                        max-width="150"
                        contain
                        class="code-logo"
                        src="@/assets/hairdreams-logo-white.webp"
                        lazy-src="@/assets/hairdreams-logo-white.webp"
                        alt="hairdreams logo"
                      />

                      <v-img
                        max-width="50"
                        class="ml-5 code-gift"
                        contain
                        src="@/assets/gift.webp"
                        lazy-src="@/assets/gift.webp"
                        alt="gift"
                      />
                    </v-toolbar-items>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text class="text-uppercase text-center pb-0"
                    >Your 10% OFF discount code is</v-card-text
                  >
                  <v-card-text class="dark text-center headline pb-0"
                    ><span class="code-box">{{ discountObj.code }}</span>
                  </v-card-text>
                  <v-card-text class="dark text-center caption pb-0"
                    >Code expires:
                    {{
                      new Date(discountObj.expiringDate).toLocaleDateString()
                    }}</v-card-text
                  >
                </v-card>
                <v-card-text class="text-left px-0 mt-3 pb-0 subtitle-1"
                  >We have emailed your code. You have an option to print it as
                  well. Please present this discount code to your salon at the
                  time of your consultation.</v-card-text
                >
                <v-card-text class="text-left caption px-0"
                  >Disclaimer: This code can is only valid at
                  {{ getSalonName }}. This code can only be redeemed towards
                  your first Hairdreams application. It cannot be applied to
                  additional services i.e. color, smoothing treatments.
                </v-card-text>
              </v-row>
            </v-card-text></v-card
          >
        </template>
      </v-card>
    </v-dialog>
    <DiscountConfirm
      :addedBy="addedBy"
      v-if="showConfirm"
      :show="showConfirm"
      :consumerId="getConsumerId"
      :salonName="getSalonName"
      :salonId="getSalonId"
      :date="date"
      :time="time"
      :color="color"
      @close="handleCloseConfirm"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { ampmTime } from '@/utils/filters'
  import { getTodayString } from '@/utils/helpers'
  import DiscountConfirm from '@/components/user/DiscountConfirm.vue'

  export default {
    props: {
      setMinDate: { type: Boolean, default: true },
      showDialog: Boolean,
      showScreen1: {
        type: Boolean,
        default: false
      },
      title: String,
      color: String,
      addedBy: String
    },
    data() {
      return {
        hasCreatedDiscount: false,
        consumerId: null,
        salonId: null,
        showScreen2: false,
        getTodayString,
        isSending: false,
        ampmTime: ampmTime,
        menu2: false,
        modal2: false,
        date: null,
        time: '',
        showConfirm: false
      }
    },
    computed: {
      isRevealed() {
        let hasDiscount = false
        if (this.consumer?.discounts) {
          const salonId = this.$route.params.salonId || this.salonId
          this.consumer.discounts.forEach(d => {
            if (d?.salon?.toString() === salonId.toString() && d.code) {
              hasDiscount = true
            }
          })
        }
        return hasDiscount
      },
      isValid() {
        // ako dolazi sa consultaions pa nema u url
        if (!this.consumer && !this.salonNear) {
          return (
            !!this.date && !!this.time && !!this.consumerId && !!this.salonId
          )
        }
        // ako ima u url
        return !!this.date && !!this.time
      },
      consumer() {
        return this.$store.state.consumers.consumer
      },
      getConsumerId() {
        return this.consumer?._id || this.consumerId
      },
      salonNear() {
        const salonId = this.$route.params.salonId || this.salonId

        return this.$store.state.consumers.consumer?.salonsNear?.find(
          s => s.salon._id === salonId
        )
      },
      getSalonId() {
        return this.salonNear?.salon?._id || this.salonId
      },
      getSalonName() {
        return this.salonNear?.salon?.name || 'this salon'
      },
      discountObj() {
        return this.consumer?.discounts.find(d => d.salon === this.getSalonId)
      }
    },
    methods: {
      ...mapActions(['fetchConsumer']),
      async handleCloseConfirm(hasCreatedDiscount) {
        this.hasCreatedDiscount = hasCreatedDiscount
        this.showConfirm = false
      },
      async handlePrint() {
        // Pass the element id here
        await this.$htmlToPaper('printMe')
      },
      // onSaveAsPdf() {
      //   console.log('save as pdf')
      // }
      onClose() {
        this.$emit('close', this.hasCreatedDiscount)
      }
    },
    async created() {
      if (!this.showScreen1) this.showScreen2 = true
      // const salonId = this.$route.params.salonId
      const consumerId = this.$route.params.consumerId
      if (consumerId) await this.fetchConsumer(consumerId)
    },
    components: { DiscountConfirm }
  }
</script>

<style lang="scss" scoped>
  .call-now {
    color: blue;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gift {
    height: 80%;
    width: auto;
  }
  .code-toolbar {
    background: #444 !important;
  }
  .code-box {
    background: #bdbdbd;
    padding: 5px;
    border-radius: 5px;
  }
</style>
