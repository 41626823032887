<template>
  <v-container>
    <!-- note: image -->
    <v-card-title class="pa-0"
      >Salon Finder Cover Image (required)</v-card-title
    >
    <v-card-text class="pa-0 my-2">
      First impression matters! Upload an image of your salon. This will be your
      cover photo that people will see.
    </v-card-text>
    <v-row>
      <v-col
        class="flex-column mb-5 align-center justify-center"
        cols="12"
        sm="5"
      >
        <v-col class="pa-0 d-flex flex-column justify-center align-center">
          <v-card-text class="text-center">Upload your image</v-card-text>
          <v-btn
            rounded
            @click="onUploadBtn"
            color="user_primary"
            class="white--text"
            small
            max-width="100"
            >Upload</v-btn
          >
          <v-alert v-if="imgError" color="error" dense class="mt-2" outlined
            >Oops! The size limit for images is 5.0 MB. Reduce the file size and
            try again.</v-alert
          >
          <v-file-input
            v-show="false"
            ref="fileInput"
            label="Upload"
            accept="image/*"
            :rules="pictureRules"
            prepend-icon="mdi-camera"
            @change="onPictureChange"
            v-model="picture"
          ></v-file-input>

          <v-card-text class="text-center"
            >or, choose a stock image from our database</v-card-text
          >
          <v-btn
            max-width="100"
            rounded
            small
            @click="() => (showStockDialog = true)"
            >Choose</v-btn
          >
          <StockPhotos
            v-if="showStockDialog"
            @change="onImgChange"
            :open="showStockDialog"
            @close="() => (showStockDialog = false)"
          />
        </v-col>
      </v-col>
      <v-col
        :style="{ border: '1px solid grey', width: '170px' }"
        class="d-flex ml-5 mr-3 mt-5 align-center justify-center"
        justify="center"
        align="center"
      >
        <v-progress-circular
          v-if="pictureLoading"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <v-img
          class="mx-auto"
          v-else-if="salon.imgUrl"
          text-align="center"
          :src="salon.imgUrl"
          :lazy-src="salon.imgUrl"
        ></v-img>
        <v-card-text v-else>No image uploaded</v-card-text>
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>

    <!-- note: highlights -->
    <v-row class="mb-5">
      <v-col>
        <v-card-title class="pl-0">Salon Highlights (required)</v-card-title>
        <v-card-text class="pl-0"
          >Select Salon Highlight you want to show on your Hairdreams Salon
          Finder page. You can select all that apply.</v-card-text
        >
      </v-col>
      <v-row class="pl-sm-5 justify-center">
        <v-col
          cols="12"
          sm="6"
          class="d-flex jusify-center"
          v-for="h in highlights"
          :key="h.text"
        >
          <v-btn
            :width="detectXS() ? '80%' : '100%'"
            class="px-5 text-capitalize mx-auto"
            :small="detectXS() ? true : false"
            rounded
            @click="handleHighlights(h.text)"
            :dark="salon.highlights.includes(h.text)"
          >
            <v-icon left dark> {{ '$vuetify.icons.' + h.icon }} </v-icon>
            {{ h.text }}</v-btn
          >
        </v-col>
      </v-row>
    </v-row>

    <v-divider v-if="isCreateSalon || isAdminPage"></v-divider>

    <!-- note: additional highlights -->
    <v-row class="mb-5" v-if="isCreateSalon || isAdminPage">
      <v-col cols="12">
        <v-card-title class="pl-0">Additional Highlights</v-card-title>
        <v-card-text class="pl-0"
          >Select Additional Salon Highlight</v-card-text
        >
      </v-col>
      <v-row class="pl-sm-5 justify-center">
        <v-col
          cols="12"
          sm="6"
          class="d-flex jusify-center"
          v-for="h in additionalHighlights"
          :key="h.text"
        >
          <v-btn
            :width="detectXS() ? '80%' : '100%'"
            class="px-5 text-capitalize mx-auto"
            :small="detectXS() ? true : false"
            rounded
            @click="handleAdditionalHighlight(h.text)"
            :dark="salon.additionalHighlight === h.text"
          >
            <v-icon left color="yellow"> {{ h.icon }} </v-icon>

            {{ h.text }}</v-btn
          >
        </v-col>
      </v-row>
    </v-row>

    <!-- note: hours -->
    <v-divider class="my-5"></v-divider>
    <v-row>
      <v-card-title>Setup business hours (required)</v-card-title>
      <v-card-subtitle
        >Please add Salon’s business hours. If you work by appointment only -
        check the box below to hide business hours from your
        page.</v-card-subtitle
      >
    </v-row>
    <v-row v-if="!salon.appointmentOnly">
      <v-card class="mx-auto px-5" elevation="2" outlined shaped>
        <business-hours
          :days="salon.hours"
          @updated-hours="updateHours"
        ></business-hours>
      </v-card>
    </v-row>
    <v-row class="pl-5">
      <v-checkbox
        label="By Appointment Only"
        v-model="salon.appointmentOnly"
        @change="handleAppointmentOnly"
      ></v-checkbox>
    </v-row>

    <!-- note: terms -->
    <!-- <v-row v-if="!isCreateSalon && !isAdminPage">
      <v-col cols="12">
        <v-checkbox v-model="terms" hide-details>
          <template v-slot:label>
            <div>
              I agree with
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="https://iwanthairdreams.com"
                    @click.stop
                    v-on="on"
                  >
                    Hairdreams</a
                  >
                </template>
                Check terms
              </v-tooltip>
              terms!
            </div>
          </template>
        </v-checkbox>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
  import StockPhotos from '@/components/user/edit-salon/StockPhotos.vue'
  import { mapActions } from 'vuex'
  import { HighlightOptions, AdditionalHighlightOptions } from '@/config/enums'
  import { detectXS } from '@/utils/helpers'

  export default {
    components: {
      StockPhotos
    },
    props: ['salon'],
    data() {
      return {
        detectXS,
        showStockDialog: false,
        picture: null,
        pictureLoading: false,
        imgError: null,
        pictureRules: [
          value =>
            !value ||
            value.size < 5000000 ||
            'Image size should be less than 5 MB!'
        ]
      }
    },
    computed: {
      hours() {
        return this.salon.hours
      },
      isCreateSalon() {
        return this.$route.name === 'CreateSalon'
      },
      isAdminPage() {
        return this.$route.name === 'EditPendingSalon'
      },
      highlights() {
        let arr = []
        for (const key in HighlightOptions) {
          arr.push(HighlightOptions[key])
        }
        return arr
      },
      additionalHighlights() {
        return Object.values(AdditionalHighlightOptions)
        // let arr = []
        // for (const key in AdditionalHighlightOptions) {
        //   arr.push(AdditionalHighlightOptions[key])
        // }
        // return arr
      }
    },
    methods: {
      ...mapActions(['addImg', 'uploadImg']),
      isValid() {
        let setHours = false
        for (const day in this.salon.hours) {
          this.salon.hours[day].forEach(item => {
            if (item.isOpen && !!item.open) {
              setHours = true
            }
          })
        }

        return (
          !!this.salon.imgUrl &&
          (this.salon.appointmentOnly || setHours) &&
          this.salon.highlights.length > 0
        )
      },
      handleAppointmentOnly(value) {
        this.onChange(value, 'appointmentOnly')
      },
      updateHours(dayObj) {
        const key = Object.getOwnPropertyNames(dayObj)
        this.salon.hours[key] = dayObj[key]
        this.onChange(this.salon.hours, 'hours')
      },
      onUploadBtn() {
        this.$refs.fileInput.$refs.input.click()
      },
      onImgChange(url) {
        this.salon.imgUrl = url
        this.onChange(this.salon.imgUrl, 'imgUrl')
      },
      async onPictureChange() {
        if (this.picture?.size < 5000000) {
          // send to backend
          // 1. img temp - upload to get storage link
          const file = this.picture
          const form = new FormData()
          form.append('file', file)
          this.pictureLoading = true

          await this.uploadImg({ form })
          if (
            !this.$store.state.salons.error &&
            this.$store.state.salons.imgUrlTemp
          ) {
            this.salon.imgUrl = this.$store.state.salons.imgUrlTemp
            this.onChange(this.salon.imgUrl, 'imgUrl')
          }
          this.pictureLoading = false
          this.imgError = null
        } else {
          this.imgError = 'Image size should be less than 5 MB!'
        }
      },
      onChange(value, prop) {
        this.$emit('change', {
          prop,
          value
        })
        this.$emit('valid', { step: 3, isValid: this.isValid() })
      },
      handleHighlights(text) {
        if (this.salon.highlights.includes(text)) {
          this.salon.highlights = this.salon.highlights.filter(h => h !== text)
        } else {
          this.salon.highlights.push(text)
        }
        this.onChange(this.salon.highlights, 'highlights')
      },
      handleAdditionalHighlight(text) {
        // if same button remove text
        if (this.salon.additionalHighlight === text)
          this.onChange('', 'additionalHighlight')
        else this.onChange(text, 'additionalHighlight')
      }
    },
    created() {
      this.$emit('valid', { step: 3, isValid: this.isValid() })
    }
  }
</script>
