var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat"},[_c('v-container',{staticClass:"fill-height"},[_c('v-row',{staticClass:"fill-height pb-14",attrs:{"align":"end"}},[(_vm.isModerator)?_c('v-col',{staticClass:"mx-0 mx-sm-5"},_vm._l((_vm.chat),function(item,index){return _c('div',{key:index,class:[
            'd-flex flex-column justify-center my-2 ',
            item.from == _vm.MessageFrom.HD
              ? 'align-end ml-5'
              : 'align-start mr-5'
          ]},[_c('span',{staticClass:"mx-2 caption"},[_vm._v(_vm._s(item.from == _vm.MessageFrom.HD ? 'Hairdreams' : item.from == _vm.MessageFrom.S ? _vm.getTitleCase(_vm.host) : _vm.getTitleCase(_vm.guestName)))]),(item.from == _vm.MessageFrom.HD)?_c('span',{staticClass:"bubble blue--bubble px-5 text-caption text-sm-body-1"},[_vm._v(_vm._s(item.msg))]):_vm._e(),(item.from != _vm.MessageFrom.HD)?_c('span',{staticClass:"bubble px-5 text-caption text-sm-body-1",class:item.from == _vm.MessageFrom.S ? 'grey--bubble' : 'orange--bubble'},[_vm._v(_vm._s(item.msg))]):_vm._e(),(item.createdAt)?_c('span',{staticClass:"caption px-2"},[_vm._v(_vm._s(_vm._f("localeDate")(item.createdAt,true)))]):_vm._e()])}),0):_c('v-col',_vm._l((_vm.chat),function(item,index){return _c('div',{key:index,class:[
            'd-flex flex-column justify-center my-2 ',
            item.from == _vm.host
              ? 'align-end ml-5'
              : item.from === _vm.MessageFrom.HD
              ? 'align-center mx-5'
              : 'align-start mr-5'
          ]},[_c('span',{staticClass:"mx-2 mx-sm-5 caption"},[_vm._v(_vm._s(item.from == _vm.host ? _vm.getTitleCase(_vm.hostName) : item.from == _vm.MessageFrom.HD ? 'Hairdreams' : _vm.getTitleCase(_vm.guestName)))]),(item.from == _vm.host)?_c('span',{staticClass:"bubble blue--bubble px-5 mr-1 mr-sm-5 text-caption text-sm-body-1"},[_vm._v(_vm._s(item.msg))]):_vm._e(),(item.from != _vm.host)?_c('span',{staticClass:"bubble ml-2 ml-sm-5 px-5 text-caption text-sm-body-1",class:item.from == _vm.MessageFrom.HD ? 'orange--bubble' : 'grey--bubble'},[_vm._v(_vm._s(item.msg))]):_vm._e(),(item.createdAt)?_c('span',{staticClass:"caption px-5"},[_vm._v(_vm._s(_vm._f("localeDate")(item.createdAt,true)))]):_vm._e()])}),0)],1)],1),(_vm.showSend)?_c('v-footer',{staticClass:"chat-footer"},[_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('vue-scroll',[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-textarea',{ref:"input",staticClass:"text-caption text-sm-body-1",attrs:{"auto-grow":"","rows":"1","placeholder":"Type Message"},on:{"blur":function($event){return _vm.$emit('blurFocus')}},model:{value:(_vm.msg),callback:function ($$v) {_vm.msg=$$v},expression:"msg"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"icon":"","disabled":_vm.isDisabledSend,"loading":_vm.isSending},on:{"click":_vm.handleSend}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-send")])],1)],1)])],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }