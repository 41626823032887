<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" class="pl-0 pb-0">
        <v-card-title class="breakword pb-0"
          >How much on average do you spend per month on advertising?
        </v-card-title></v-col
      >
      <v-card-text class="text-center subtitle pt-0">Choose one</v-card-text>
      <v-col
        v-for="option in options"
        :key="option.value"
        class="d-flex justify-center flex-column align-center py-0"
        :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-3'"
        cols="6"
        @click="handleSelect(option.value)"
      >
        <v-btn
          depressed
          :color="answer === option.value ? 'user_cta' : 'user_btn'"
          :dark="answer === option.value"
          class="text-none mt-1"
          width="100%"
          >{{ option.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        answer: '',
        options: [
          {
            text: 'I don’t advertise',
            value: 'I do not advertise'
          },
          {
            text: '$100 or less',
            value: '$100 or less'
          },
          {
            text: '$100 to $300',
            value: '$100 to $300'
          },
          {
            text: '$300 to $500',
            value: '$300 to $500'
          },
          {
            text: '$500 to $1000',
            value: '$500 to $1000'
          },
          {
            text: '$1000 to $2500+',
            value: '$1000 to $2500+'
          }
          // {
          //   text: '$2500 to $5000',
          //   value: '$2500 to $5000'
          // },
          // {
          //   text: '$5000+',
          //   value: '$5000+'
          // }
        ],
        field: 'ads',
        screen: 'stylist-ads1',
        nextScreen: ''
      }
    },
    computed: {
      hasLicense() {
        return (
          !!this.$store.state.stylistsAccount.stylist?.cosmetologyLicenseFile ||
          !!this.$store.state.applications.application?.account
            ?.cosmetologyLicenseFile ||
          !!this.$store.state.stylistsAccount.licenseUrl
        )
      }
    },
    methods: {
      ...mapActions([
        'fetchStylistAccount',
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        if (this.hasLicense) this.nextScreen = 'stylist-final-status'
        else this.nextScreen = 'stylist-form-license'
        this.setNextScreen(this.nextScreen)
      },
      handleSelect(answer) {
        this.answer = answer
        // 1. set data
        this.setApplicationData({ field: this.field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
        // 4. go to next screen
        this.$emit('next')
      }
    },
    async created() {
      await this.fetchStylistAccount(this.$store.state.auth.user.stylistId)
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field]) {
        this.answer = this.$store.state.applications.application[this.field]
        this.handleNextScreen()
        this.setIsValid(true)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .system-img {
    cursor: pointer;
  }
  .btn-caption {
    color: #7d7d7d;
    font-size: 10px;
  }
  .breakword {
    word-break: break-word;
  }
</style>
