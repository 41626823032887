<template>
  <v-container class="pa-1 pa-sm-3 pb-sm-0">
    <v-card class="elevation-12 mx-auto" max-width="600">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Edit Salon Finder Page</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="pa-0 pa-sm-3">
        <!-- agreement -->
        <v-container v-html="content" class="mt-0 pt-2 content"></v-container>
      </v-card-text>
      <!-- first buttons -->
      <v-card-actions>
        <v-btn :x-small="detectXS()" rounded @click="handleBack">Back</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :x-small="detectXS()"
          :loading="isSending"
          @click="handleAccept"
          class="mr-1"
          rounded
          color="blue"
          :dark="!showModal"
          :disabled="showModal"
          >Accept the terms and submit</v-btn
        >
        <v-btn
          :x-small="detectXS()"
          rounded
          @click="showModal = true"
          :disabled="showModal"
          class="ml-1"
          >Decline</v-btn
        >
      </v-card-actions>
      <TermsConfirmModal
        @decline="handleConfirmDecline"
        v-if="salon"
        :salon="salon"
        @close="showModal = false"
        :showDialog="showModal"
        message="By declining Terms and Conditions you will be automatically opted
              out as a Hairdreams referral salon"
      />
    </v-card>
  </v-container>
</template>

<script>
  import { decode } from 'html-entities'
  import { mapActions } from 'vuex'
  import { ThankYouPageSources } from '@/config/enums'
  import { detectXS } from '@/utils/helpers'
  import TermsConfirmModal from '../TermsConfirmModal.vue'

  export default {
    components: { TermsConfirmModal },
    data() {
      return {
        detectXS,
        content: '',
        termsDate: null,
        showModal: false,
        salon: '',
        isSending: false
      }
    },
    computed: {
      isValid() {
        return true
      },
      isCreateSalon() {
        return this.$route.name === 'CreateSalon'
      },
      isAdminPage() {
        return this.$route.name === 'EditPendingSalon'
      }
    },
    methods: {
      ...mapActions([
        'fetchSetups',
        'fetchSalon',
        'editSalon',
        'sendTermsMail'
      ]),
      handleBack() {
        this.$router.push({
          name: 'EditSalon',
          params: { salonId: this.salon._id }
        })
      },
      async handleAccept() {
        this.isSending = true
        if (!this.salon.terms) {
          this.salon.terms = {}
        }
        // 1. update salon terms
        // 1a) terms text and date
        this.salon.terms.text = this.content
        this.salon.terms.date = this.termsDate
        // 1b) accept time
        this.salon.terms.accept = new Date()
        // set call only
        this.salon.callOnly = false
        // 3. send to backend
        await this.editSalon(this.salon)
        this.sendTermsMail({ id: this.salon.id, termsStatus: 'accepted' })
        // 4. clean localstorage
        localStorage.removeItem('salon')
        localStorage.removeItem('currentStepNum')

        this.isSending = false
        this.$router.replace({
          name: 'ThankYouPage',
          params: { source: ThankYouPageSources.edit_salon }
        })
      },
      async handleConfirmDecline() {
        this.isSending = true
        if (!this.salon.terms) {
          this.salon.terms = {}
        }
        // 1. update salon terms
        // 1a) terms text
        this.salon.terms.text = this.content
        // 1c) decline time
        this.salon.terms.decline = new Date()
        // 2. deactivate salon
        this.salon.active = false
        // set call only
        this.salon.callOnly = false

        // 3. send to backend
        await this.editSalon(this.salon)
        this.sendTermsMail({ id: this.salon.id, termsStatus: 'declined' })

        // 4. clean localstorage
        localStorage.removeItem('salon')
        localStorage.removeItem('currentStepNum')

        this.isSending = false
      }
    },
    async created() {
      await Promise.all([
        this.fetchSetups(),
        this.fetchSalon(this.$route.params.salonId)
      ])

      this.salon = this.$store.state.salons.selectedSalon
      this.content = decode(this.$store.getters['lastAgreement']?.terms)
      this.termsDate = this.$store.getters['lastAgreement']?.termsDate
    }
  }
</script>

<style scoped>
  .content {
    max-height: 500px;
    overflow-y: scroll;
  }
</style>
