<template>
  <v-dialog
    v-model="show"
    max-width="400"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="pr-3 pb-0">
        <v-spacer></v-spacer>

        <v-icon @click="handleClose" style="cursor: pointer"
          >mdi-close</v-icon
        ></v-card-title
      >

      <v-card-text class="pt-0 px-2">
        <!-- VIDEO -->
        <v-card-text v-if="showVideo">
          <iframe
            id="video"
            ref="video"
            :src="options.video + '&autoplay=1'"
            width="100%"
            height="460"
            title=" "
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture; encrypted-media"
            allowfullscreen
          ></iframe>
        </v-card-text>
        <!-- IMAGE -->
        <v-card-text v-if="options.popUpImg">
          <v-img
            contain
            :lazy-src="require(`@/assets/apple-style/${options.popUpImg}`)"
            :src="require(`@/assets/apple-style/${options.popUpImg}`)"
          />
        </v-card-text>
        <!-- TEXT -->
        <!-- if not inside cert page and doesn't have cert text, but has regular text -->
        <v-card-text
          v-if="options.text && !showCertText"
          v-html="options.text"
          class="text-body-1 pt-1"
        ></v-card-text>
        <!-- if inside certification page and has cert text -->
        <template v-if="showCertText">
          <v-card-text
            class="text-h6 font-weight-bold py-0"
            v-if="options.certificationText[certPage].title1"
            v-text="options.certificationText[certPage].title1"
          />
          <v-card-text
            class="pt-0"
            v-if="options.certificationText[certPage].title2"
            v-text="options.certificationText[certPage].title2"
          />
          <v-card-text
            class="font-weight-bold py-0"
            v-if="options.certificationText[certPage].subtitle1"
            v-text="options.certificationText[certPage].subtitle1"
          />
          <v-card-text
            class="font-weight-bold py-0"
            v-if="options.certificationText[certPage].subtitle2"
            v-text="options.certificationText[certPage].subtitle2"
          />
          <v-card-text
            v-if="options.certificationText[certPage].htmlText1"
            v-html="options.certificationText[certPage].htmlText1"
          />
          <v-card-text
            class="font-weight-bold pb-0"
            v-if="options.certificationText[certPage].subtitle3"
            v-text="options.certificationText[certPage].subtitle3"
          />
          <v-card-text
            class="font-weight-bold py-0"
            v-if="options.certificationText[certPage].subtitle4"
            v-text="options.certificationText[certPage].subtitle4"
          />
          <v-card-text
            v-if="options.certificationText[certPage].htmlText2"
            v-html="options.certificationText[certPage].htmlText2"
          />
        </template>
        <!-- BUTTON -->
        <v-card-text v-if="action && options.popUpBtnText">
          <v-btn
            rounded
            color="primary"
            class="white--text"
            @click="handleBtnClick"
            >{{ options.popUpBtnText }}</v-btn
          ></v-card-text
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        default: () => ({
          img: '',
          hasAction: false,
          video: '',
          popUpImg: '',
          text: '',
          popUpBtnText: '',
          certificationText: ''
        })
      },
      action: {
        type: Boolean,
        default: true
      },
      certPage: { type: String, default: '' }
    },
    computed: {
      showCertText() {
        // if is inside certification page and this img has certification text
        return !!this.certPage && !!this.options.certificationText
      },
      showVideo() {
        // do not show vide if no video or it is inside certification page if it is EXPLICITLY set to not show video on cert page
        return (
          !!this.options.video &&
          (!this.options.isCertPage ||
            this.options.showVideoOnCertPage !== false)
        )
      }
    },
    methods: {
      handleClose() {
        this.$emit('close', false)
        if (this.videoLink) {
          this.$refs.video.src = ''
          this.$refs.video.src = this.video
        }
      },
      handleBtnClick() {
        this.$emit('btn-click')
      }
    }
  }
</script>

<style lang="scss" scoped></style>
