<template>
  <v-container v-if="answer" class="pt-0 px-0 px-sm-3">
    <MicrolinesView
      v-if="hairloss"
      :source="sources[SystemOptions.MICROLINES]"
      @btn-click="handleSelect"
    />

    <LaserbeamerView
      v-else-if="answer === SystemOptions.LASERBEAMER"
      :source="sources[SystemOptions.LASERBEAMER]"
      @btn-click="handleSelect"
    />

    <FreestylerView
      v-else-if="answer === SystemOptions.FREESTYLER"
      :source="sources[SystemOptions.FREESTYLER]"
      @btn-click="handleSelect"
    />

    <TapeView
      v-else-if="answer === SystemOptions.TAPE"
      :source="sources[SystemOptions.TAPE]"
      @btn-click="handleSelect"
    />

    <!-- other systems -->
    <template v-else>
      <v-card-text
        class="breakword d-flex justify-center pb-0"
        v-if="getInfo().beforeTitle"
        >{{ getInfo().beforeTitle }}</v-card-text
      >
      <v-card-title class="breakword d-flex justify-center"
        >{{ getInfo().title }}
      </v-card-title>
      <v-card-text class="breakword d-flex justify-center">{{
        getInfo().subtitle
      }}</v-card-text>
      <v-card-text
        class="breakword d-flex justify-center"
        v-if="getInfo().subtitleCaption"
        >{{ getInfo().subtitleCaption }}</v-card-text
      >

      <v-row>
        <v-col class="px-0">
          <iframe
            ref="video"
            :src="getInfo().video"
            width="100%"
            height="400"
            title=" "
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-pict
          ure"
            allowfullscreen
          ></iframe
        ></v-col>
      </v-row>
      <v-card-text class="text-center pt-0" v-if="getInfo().caption">{{
        getInfo().caption
      }}</v-card-text>
      <v-row
        ><v-col class="d-flex justify-center pt-0"
          ><v-btn
            :width="detectMob() ? '90%' : '82%'"
            :color="!!this.answer ? 'user_cta' : ''"
            :dark="!!this.answer"
            rounded
            depressed
            @click="handleSelect"
            class="text-none"
            >{{ getInfo().btnText }}</v-btn
          ></v-col
        ></v-row
      >
      <v-row class="justify-center">
        <v-btn
          text
          color="blue"
          v-if="getInfo().specialLink"
          class="text-none"
          @click="handleSpecialLink"
          >{{ getInfo().specialLink }}</v-btn
        >
      </v-row>
    </template>
  </v-container>
</template>

<script>
  import {
    SystemOptions,
    NewSystemOptions,
    getShortSystemForUrl
  } from '@/config/enums'
  import { getQ4Info, detectMob } from '@/utils/helpers'
  import MicrolinesView from '../WF1/apple-style/consumers/MicrolinesView.vue'
  import LaserbeamerView from '../WF1/apple-style/consumers/LaserbeamerView.vue'
  import FreestylerView from '../WF1/apple-style/consumers/FreestylerView.vue'
  import TapeView from '../WF1/apple-style/consumers/TapeView.vue'
  import sources from '../WF1/apple-style/consumers/source-consumers.js'

  export default {
    components: {
      MicrolinesView,
      LaserbeamerView,
      FreestylerView,
      TapeView
    },
    data() {
      return {
        getShortSystemForUrl,
        sources,
        //
        showMicrolinesVideoDialog: false,
        microlinesVideoSrc:
          'https://player.vimeo.com/video/732572685?h=14e4e2940a',
        detectMob,
        toLookOther: false,
        SystemOptions,
        screen: 'systemInfo',
        question: 'systemInfo',
        answer: ''
      }
    },
    computed: {
      extensions() {
        return this.$route.query['userType'] === 'extensions'
      },
      hairloss() {
        return this.$route.query['userType'] === 'hairloss'
      },
      sf() {
        return this.$route.query['systemPickedBy'] === 'sf'
      },
      c() {
        return this.$route.query['systemPickedBy'] === 'c'
      },
      wearExtensionsBeforeYes() {
        return this.$route.query['wearExtensionsBefore'] === 'yes'
      },
      wearExtensionsBeforeNo() {
        return this.$route.query['wearExtensionsBefore'] === 'no'
      },
      lifestyleExtensions() {
        return !!this.$route.query['lifestyleExtensions']
      },
      nextScreen() {
        if (this.hairloss) return 'info'
        else if (this.toLookOther) return 'consumerPick'
        else if (this.extensions && this.c) return 'info'
        else if (this.extensions && this.sf) return 'consumerInfo'
        else return ''
      }
    },
    methods: {
      handleShowMicrolinesVideoDialog() {
        this.showMicrolinesVideoDialog = true
      },
      stopVideo() {
        this.showMicrolinesVideoDialog = false
        this.$refs.video.src = ''
        this.$refs.video.src = this.microlinesVideoSrc
      },
      // helper
      emitSelect(
        goNext = false,
        question4 = '',
        systemPickedBy = '',
        playVideo
      ) {
        this.$emit('select', {
          // note: brisem url samo ako je izabrao hairloss jer je to prvi screen posle pocetnog
          clearUrl: this.$route.query['userType'] === 'hairloss',
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          question4,
          systemPickedBy,
          goNext,
          playVideo
        })
      },
      handleSelect() {
        if (this.extensions) {
          this.emitSelect(true)
        } else if (this.hairloss) {
          this.emitSelect(true, getQ4Info().answers[0])
        }
      },
      getInfo() {
        let beforeTitle,
          title,
          subtitle,
          subtitleCaption,
          video,
          caption,
          btnText,
          link,
          specialLink

        // 1. extension
        if (this.extensions) {
          if (this.c) {
            switch (this.answer) {
              case SystemOptions.FREESTYLER:
                title = 'Hairdreams® FreeStyler'
                subtitle = 'QUALITY IN EVERY BOND'
                video = 'https://player.vimeo.com/video/732572600?h=998429f657'
                btnText = 'Find a Hairdreams salon'
                break
              case SystemOptions.LASERBEAMER:
                title = 'Hairdreams® Laserbeamer Nano'
                subtitle = 'QUALITY MEETS TECHNOLOGY'
                video = 'https://player.vimeo.com/video/732572798?h=97bae7e2da'
                btnText = 'Find a Hairdreams salon'
                link =
                  'https://iwanthairdreams.com/pages/laserbeamer-nano-online-certification'
                break

              case SystemOptions.TAPE:
                title = 'Hairdreams® Tape-Ins'
                subtitle = 'QUALITY MEETS TECHNOLOGY'
                video = 'https://player.vimeo.com/video/732572878?h=94bfd2f8c5'
                btnText = 'Find a Hairdreams salon'
                break
            }
          } else if (this.sf) {
            switch (this.answer) {
              case NewSystemOptions.BONDED_EXTENSIONS:
                beforeTitle = 'We suggest'
                title = 'Hairdreams® Bonded Extensions'
                subtitle = 'QUALITY IN EVERY BOND'
                video = 'https://player.vimeo.com/video/732572541?h=857184ec2b'
                btnText = 'Find a Hairdreams salon'
                // specialLink = 'Learn about other extensions types'
                break
              case NewSystemOptions.TAPE:
                beforeTitle = 'We suggest'
                title = 'Hairdreams® Tape-Ins'
                subtitle = 'FEATURING INVISIBLE SECRETS'
                // subtitleCaption = 'FEATURING INVISIBLE SECRETS'
                video = 'https://player.vimeo.com/video/732572878?h=94bfd2f8c5'
                btnText = 'Find a Hairdreams salon'
                // specialLink = 'Learn about other extensions types'
                break
            }
          }
        }
        // 2. hairless
        else if (this.hairloss) {
          // title = 'Hairdreams® MicroLines'
          title = 'MicroLines'
          // subtitle = 'QUALITY MEETS TECHNOLOGY'
          subtitle = 'Hair Loss System'
          video = 'https://player.vimeo.com/video/732572685?h=14e4e2940a'
          btnText = 'Find a Hairdreams salon'
        }

        return {
          beforeTitle,
          title,
          subtitle,
          subtitleCaption,
          video,
          btnText,
          caption,
          link,
          specialLink
        }
      }
    },
    created() {
      // if user is pro, redirect to stylists route
      if (this.$route.query.userType === 'pro') {
        this.$router.replace({
          name: 'stylist-apple-style',
          params: {
            system: this.getShortSystemForUrl(this.$route.query.systemInfo)
          },
          query: this.$route.query
        })
      }

      this.answer = this.$route.query[this.question] || ''
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped></style>
