<template>
  <v-card
    elevation="2"
    color="#eee"
    :class="toolbar ? '' : 'rounded-lg'"
    class="pb-3"
  >
    <v-toolbar v-if="toolbar" dark color="user_primary">
      <v-toolbar-title class="text-sm-h5">{{ toolbarTitle }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col class="pointer d-flex justify-end" @click="() => $emit('close')"
        ><v-icon>mdi-close</v-icon></v-col
      >
    </v-toolbar>
    <v-card-text class="text-overline d-flex text-center pt-4 justify-center">
      Choose a wider radius to find a Hairdreams Salon
    </v-card-text>
    <v-card-actions class="d-flex justify-center text-center">
      <v-btn
        disabled
        class="text-lowercase"
        rounded
        :small="detectXS() ? true : false"
      >
        {{ consumer.chosenDistance || 25 }} mi</v-btn
      >

      <v-btn
        :small="detectXS() ? true : false"
        rounded
        dark
        color="user_primary"
        class="text-lowercase"
        @click="onFindSalon(50)"
        v-if="!searched[50] && !searched[75] && !searched[100]"
        :loading="isSending[50]"
      >
        50 mi
      </v-btn>
      <v-btn
        :small="detectXS() ? true : false"
        :loading="isSending[75]"
        rounded
        dark
        color="user_primary"
        class="text-lowercase"
        @click="onFindSalon(75)"
        v-if="!searched[75] && !searched[100]"
      >
        75 mi
      </v-btn>
      <v-btn
        :small="detectXS() ? true : false"
        :loading="isSending[100]"
        rounded
        dark
        color="user_primary"
        class="text-lowercase"
        @click="onFindSalon(100)"
        v-if="!searched[100]"
        >100 mi
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import { detectXS } from '@/utils/helpers'

  export default {
    props: {
      consumer: {
        type: Object,
        required: true
      },
      toolbar: {
        type: Boolean,
        default: false
      },
      toolbarTitle: {
        type: String
      }
    },
    data() {
      return {
        detectXS,
        distance: 25,
        isSending: {
          50: false,
          75: false,
          100: false
        },
        searched: {
          50: false,
          75: false,
          100: false
        }
      }
    },
    methods: {
      ...mapActions(['addSalonsByDistance']),
      async onFindSalon(distance) {
        this.isSending[distance] = true
        const salonCount = await this.addSalonsByDistance({
          id: this.consumer._id,
          distance
        })
        this.isSending[distance] = false
        this.searched[distance] = true
        this.distance = distance

        if (salonCount > 0) {
          this.$emit('found')
        } else {
          if (this.searched[100]) {
            this.$emit('not-found')
          }
        }
      }
    },
    created() {
      this.distance = this.consumer?.chosenDistance || 25
      // hide buttons if consumer distance is >=
      for (const key in this.searched) {
        if (this.distance >= key) {
          this.searched[key] = true
        }
      }
    }
  }
</script>
<style scoped>
  .pointer {
    cursor: pointer;
  }
</style>
