<template>
  <v-card class="review-custom-card elevation-0">
    <div
      class="d-flex align-center mb-2 review-custom-info"
      :style="{ width: detectXS() ? '97%' : '95%' }"
    >
      <v-col class="logo-box pa-0">
        <v-avatar color="indigo" class="avatar">
          <v-icon dark class="avatar"> mdi-account-circle </v-icon>
        </v-avatar></v-col
      >
      <v-col class="d-flex flex-column pa-0 ml-2">
        <span class="name">{{ name }}</span>
        <span class="date">{{ new Date(date).toLocaleDateString() }}</span>
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        class="logo-box pa-0"
        v-if="
          reviewType === ReviewTypes.GOOGLE || reviewType === ReviewTypes.YELP
        "
        ><v-img
          contain
          class="mx-auto logo"
          :src="require('@/assets/' + reviewType.toLowerCase() + '.png')"
          :lazy-src="require('@/assets/' + reviewType.toLowerCase() + '.png')"
        ></v-img
      ></v-col>
    </div>
    <div
      class="mb-1 pa-0 review-custom-content"
      :style="{
        width: detectXS() ? '97%' : '95%',
        height: isTruncated ? '' : 'fit-content'
      }"
    >
      {{ isTruncated ? truncatedContent : content }}
      <span v-if="isTruncated"
        ><button class="link-btn" @click="handleSeeMore">See more</button>
      </span>
      <span v-if="!isTruncated"
        ><button class="link-btn" @click="handleSeeLess">See less</button>
      </span>
    </div>
  </v-card>
</template>

<script>
  import { ReviewTypes } from '@/config/enums'
  import { detectXS } from '@/utils/helpers'

  export default {
    props: {
      name: {
        type: String
      },
      date: {
        type: String
      },
      reviewType: {
        type: String,
        required: true
      },
      content: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isSeeMore: false,
        isTruncated: false,
        truncatedContent: '',
        detectXS,
        ReviewTypes
      }
    },
    methods: {
      getTruncated() {
        const max = this.detectXS() ? 30 : 35
        let contentArr = this.content.split(' ')

        this.isTruncated = contentArr.length > max
        if (this.isTruncated) {
          contentArr = contentArr.slice(0, max)
          this.truncatedContent = contentArr.join(' ') + '...'
        } else {
          contentArr = contentArr
        }
      },
      handleSeeMore() {
        this.isSeeMore = !this.isSeeMore
        if (this.isSeeMore) {
          this.isTruncated = false
          this.$emit('changeHeight', 'fit-height')
        }
      },
      handleSeeLess() {
        this.isSeeMore = !this.isSeeMore
        if (!this.isSeeMore) {
          this.isTruncated = true
          this.$emit('changeHeight', '100&')
        }
      }
    },
    created() {
      this.truncatedContent = this.content
      this.getTruncated()
    }
  }
</script>

<style lang="scss" scoped>
  .review-custom-card {
    padding: 12px;
    margin-top: 0px;
    border-radius: 2px !important;
    padding-bottom: 24px;
    width: 100%;
    height: 100%;
  }
  .v-avatar,
  .avatar {
    width: 100% !important;
    height: 100% !important;
    min-width: 40px !important;
  }
  .name {
    letter-spacing: 0.15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    font-family: Helvetica, Arial, sans-serif;
    color: #1c1e21;
  }
  .date {
    font-weight: 600;
    color: #90949c;
    font-size: 12px;
    line-height: 14px;
    font-family: Helvetica, Arial, sans-serif;
    word-wrap: break-word;
  }
  .review-custom-content {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.32px;
    word-wrap: break-word;
    font-family: Helvetica, Arial, sans-serif;
    color: #1c1e21;
    direction: ltr;
  }

  .logo-box {
    flex: 0 0 40px;
    height: 40px;
  }
  // .v-avatar {
  //   height: auto;
  //   width: 100%;
  //   min-width: 100%;
  // }
  .logo {
    height: 24px;
    width: 40px;
  }
  .link-btn {
    color: #385898;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
