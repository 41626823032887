<template>
  <v-container class="pt-0">
    <v-row>
      <v-card-title class="breakword d-flex justify-center"
        >Answer {{ microlines ? '2' : '3' }} questions to find the best salon
        for you</v-card-title
      >
      <v-col class="d-flex justify-center pt-0"
        ><v-btn
          width="100%"
          rounded
          depressed
          color="user_cta"
          dark
          @click="handleSelect"
          class="text-none"
          >Let's go!</v-btn
        ></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
  import { SystemOptions } from '@/config/enums'
  export default {
    data() {
      return {
        SystemOptions,
        screen: 'info',
        question: '',
        answer: 'go'
      }
    },
    computed: {
      microlines() {
        return this.$route.query['systemInfo'] === this.SystemOptions.MICROLINES
      },
      nextScreen() {
        if (this.microlines) return 'moreHair'
        else return 'hairLongIs'
      }
    },

    methods: {
      // helper
      emitSelect(goNext = false) {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          goNext
        })
      },
      handleSelect() {
        this.emitSelect(true)
      }
    },
    created() {
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped></style>
