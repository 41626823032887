<template>
  <v-container class="pt-0">
    <v-card class="elevation-12 mx-auto">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Certification</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="pa-5">
        <v-row class="justify-center px-0" v-if="!isLoading">
          <v-col cols="12">
            <VueEditor v-model="content" />
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="handleSubmit" :loading="isSending">Submit</v-btn>
          </v-col>
        </v-row>
        <v-card-text v-else class="d-flex justify-center">
          <v-progress-circular indeterminate color="dark">
          </v-progress-circular>
        </v-card-text>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { detectXS } from '@/utils/helpers'
  import { VueEditor } from 'vue2-editor'
  import { mapActions } from 'vuex'
  import { decode } from 'html-entities'

  export default {
    components: {
      VueEditor
    },
    data() {
      return {
        terms: '',
        isSending: false,
        isLoading: true,
        detectXS,
        content: ''
      }
    },
    methods: {
      ...mapActions(['addSetup', 'fetchSetups']),
      async handleSubmit() {
        this.isSending = true
        await this.addSetup({ certification: this.content })
        if (!this.$store.state.setups.error) {
          this.isSending = false
          this.$router.push({
            name: 'ExamplePages'
          })
        }
      }
    },
    async created() {
      await this.fetchSetups()

      this.content = decode(this.$store.getters['lastAgreement']?.certification)
      this.isLoading = false
    }
  }
</script>
