import api from '@/services/api.service'

// state
export const state = {
  list: [],
  selectedLog: null,
  loading: false,
  error: '',
  totalCount: 0
}

// mutations
export const mutations = {
  logsFetched: (state, logs) => {
    state.list = logs
  },
  totalCountFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  logAdded: (state, log) => {
    state.list.unshift(log)
    state.selectedLog = log
    localStorage.setItem('wf1_log', JSON.stringify(log))
  },
  logEdited: (state, editedLog) => {
    state.list = state.list.map(log => {
      return log._id === editedLog._id ? { ...editedLog } : { ...log }
    })
  },
  loadingLogsSet: state => (state.loading = true),
  loadingLogsCleared: state => (state.loading = false),
  errorLogsSet: (state, error) => (state.error = error),
  errorLogsCleared: state => (state.error = '')
}

// getters
export const getters = {
  log: state => state.selectedLog || JSON.parse(localStorage.getItem('wf1_log'))
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorLogsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingLogsCleared')
  setTimeout(() => {
    commit('errorLogsCleared')
  }, 10000)
}

// actions
export const actions = {
  getLogsCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postLogsCsv(payload)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchLogs: async ({ commit }) => {
    commit('loadingLogsSet')
    try {
      const response = await api.getLogs()
      commit('logsFetched', response.data.data)
      commit('loadingLogsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchLogsTableData: async ({ commit }, payload = {}) => {
    commit('loadingLogsSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getLogsTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('logsFetched', response.data.data)
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingLogsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addLog: async ({ commit }, log) => {
    commit('loadingLogsSet')
    try {
      const response = await api.postLog(log)
      commit('logAdded', response.data.data)
      commit('errorLogsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  clearLog: ({ commit }) => {
    commit('logAdded', null)
  },
  editLog: async ({ commit }, log) => {
    commit('loadingLogsSet')

    try {
      const { data } = await api.patchLog(log)

      commit('logEdited', data.data)

      commit('loadingLogsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
