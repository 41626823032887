import moment from 'moment'
import {
  AdditionalSystemOptions,
  APPLICATION_BOOKED_OPTIONS,
  ApplicationStatus,
  ApplicationTags,
  CONSULTATION_ADDED_BY,
  CONSULTATION_COMPLETED_OPTIONS,
  ContactMessageConsumerTopics,
  DEFERRED_EMAIL,
  FSClass,
  LBClass,
  MessageFrom,
  MLClass,
  NewSystemOptions,
  SalonSourceRating,
  SystemOptions
} from './enums'

const AllSystemOptionsArray = [
  ...Object.values(SystemOptions),
  ...Object.values(AdditionalSystemOptions)
]

// helper: filter data - START AND END DATE
export const getFilteredByDate = (data, start, end, dateProp = 'createdAt') => {
  let filteredData

  if (start && end)
    filteredData = data.filter(
      c =>
        moment(c[dateProp]).isSameOrAfter(start, 'day') &&
        moment(c[dateProp]).isSameOrBefore(end, 'day')
    )
  else if (start && !end)
    filteredData = data.filter(c =>
      moment(c[dateProp]).isSameOrAfter(start, 'day')
    )
  else if (!start && end)
    filteredData = data.filter(c =>
      moment(c[dateProp]).isSameOrBefore(end, 'day')
    )
  else filteredData = data

  return filteredData
}

// helper: filter data - TEXT AND SELECT
const handleFilter = (component, value, prop, type = 'text') => {
  if (!value || !component.filters[prop]) return true
  if (type === 'select') return value === component.filters[prop]
  if (type === 'number')
    return value.toString().includes(component.filters[prop])
  if (type === 'selectNumber') return value == component.filters[prop]
  if (type === 'multiple') return value.includes(component.filters[prop])
  return value.toLowerCase().includes(component.filters[prop].toLowerCase())
}

// 1. Consumers
export const getConsumersTableInfo = component => {
  // headers
  const headers = [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'id')
    },
    {
      text: 'First Name',
      align: 'start',
      value: 'firstName',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'firstName')
    },
    {
      text: 'Last Name',
      align: 'start',
      value: 'lastName',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'lastName')
    },
    {
      text: 'Save for later',
      align: 'start',
      value: 'saveForLater',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'saveForLater', 'select')
    },
    {
      text: 'Source',
      align: 'start',
      value: 'source',
      width: 150,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'source')
    },
    {
      text: 'E-mail',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Phone Number',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'Phone Validated',
      align: 'start',
      value: 'phoneValidated',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'phoneValidated')
    },
    {
      text: 'Zip Code',
      align: 'start',
      value: 'zip',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'zip')
    },
    {
      text: 'System',
      align: 'start',
      value: 'system',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'Salon 1',
      align: 'start',
      value: 'salon1',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon1')
    },
    {
      text: 'Salon 2',
      align: 'start',
      value: 'salon2',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon2')
    },
    {
      text: 'Salon 3',
      align: 'start',
      value: 'salon3',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon3')
    },
    {
      text: 'Chosen Distance',
      align: 'start',
      value: 'chosenDistance',
      dbType: 'number',
      width: 130,
      toFilter: true,
      sortable: false,
      filterType: 'select',
      filterOptions: ['25', '50', '75', '100'],
      filter: value =>
        handleFilter(component, value, 'chosenDistance', 'selectNumber')
    },
    {
      text: 'Promotion Emails',
      align: 'start',
      value: 'sendPromotion',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['Yes', 'No'],
      filter: value => handleFilter(component, value, 'sendPromotion', 'select')
    },
    {
      text: 'Watched video',
      align: 'start',
      value: 'playVideo',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['Yes', 'No'],
      filter: value => handleFilter(component, value, 'playVideo')
    },
    {
      text: 'Hair loss areas',
      align: 'start',
      value: 'question1m',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        { text: 'front', value: 'a' },
        { text: 'top', value: 'b' },
        { text: 'side', value: 'c' },
        { text: 'back', value: 'd' }
      ],
      filter: value => handleFilter(component, value, 'question1m', 'select')
    },
    {
      text: 'Length before',
      align: 'start',
      value: 'question1',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        { text: 'above shoulder', value: 'a' },
        { text: 'below shoulders', value: 'b' },
        { text: 'mid-back', value: 'c' }
      ],
      filter: value => handleFilter(component, value, 'question1', 'select')
    },
    {
      text: 'Length after',
      align: 'start',
      value: 'question2',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        { text: 'above shoulder', value: 'a' },
        { text: 'below shoulders', value: 'b' },
        { text: 'mid-back', value: 'c' }
      ],
      filter: value => handleFilter(component, value, 'question2', 'select')
    },
    {
      text: 'Top coverage',
      align: 'start',
      value: 'question3',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        { text: 'yes', value: 'a' },
        { text: 'no', value: 'b' }
      ],
      filter: value => handleFilter(component, value, 'question3', 'select')
    },
    {
      text: 'Profit',
      align: 'start',
      value: 'productPrice',
      dbType: 'number',
      width: 130,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'productPrice', 'number')
    },
    {
      text: 'System picked by',
      align: 'start',
      value: 'systemPickedBy',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['sf', 'c'],
      filter: value =>
        handleFilter(component, value, 'systemPickedBy', 'select')
    },
    {
      text: 'Type',
      align: 'start',
      value: 'wearExtensionsBefore',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['First Time', 'Conversion'],
      filter: value =>
        handleFilter(component, value, 'wearExtensionsBefore', 'select')
    },
    {
      text: 'Want again',
      align: 'start',
      value: 'wantAgain',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        { text: 'Bonded', value: NewSystemOptions.BONDED_EXTENSIONS },
        { text: 'Tape-Ins', value: NewSystemOptions.TAPE },
        { text: 'Either', value: 'either' },
        { text: 'Not sure', value: 'i am not sure' }
      ],
      filter: value => handleFilter(component, value, 'wantAgain', 'select')
    },
    {
      text: 'Lifestyle',
      align: 'start',
      value: 'lifestyleExtensions',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        { text: 'Bonded', value: NewSystemOptions.BONDED_EXTENSIONS },
        { text: 'Tape-Ins', value: NewSystemOptions.TAPE }
      ],
      filter: value =>
        handleFilter(component, value, 'lifestyleExtensions', 'select')
    },
    {
      text: 'Deferred Emails',
      align: 'start',
      value: 'deferredEmails',
      dbNested: 'mail',
      dbType: 'nested',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: [...Object.values(DEFERRED_EMAIL), 'none'],
      filter: value =>
        handleFilter(component, value, 'deferredEmails', 'multiple')
    },
    {
      text: 'Updated At',
      align: 'start',
      width: 200,
      value: 'updatedAt'
    },
    {
      text: 'Created At',
      align: 'start',
      width: 200,
      value: 'createdAt'
    },
    { text: 'Actions', value: 'actions', sortable: false, csv: false }
  ]

  // additional headers
  const additionalHeaders = [
    {
      text: 'utm_campaign',
      align: 'start',
      value: 'utm_campaign',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_campaign')
    },
    {
      text: 'utm_source',
      align: 'start',
      value: 'utm_source',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_source')
    },
    {
      text: 'utm_term',
      align: 'start',
      value: 'utm_term',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_term')
    },
    {
      text: 'utm_medium',
      align: 'start',
      value: 'utm_medium',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_medium')
    },
    {
      text: 'utm_content',
      align: 'start',
      value: 'utm_content',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_content')
    },
    {
      text: 'gclid',
      align: 'start',
      value: 'gclid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'gclid')
    },
    {
      text: 'fbclid',
      align: 'start',
      value: 'fbclid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'fbclid')
    },
    {
      text: 'msclkid',
      align: 'start',
      value: 'msclkid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'msclkid')
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  filters.startDate = null
  filters.endDate = null

  // item
  const item = {
    id: '',
    memo: '',
    firstName: '',
    lastName: '',
    email: '',
    system: '',
    phone: '',
    zip: '',
    salonZip: '',
    salon: '',
    salon1: '',
    salon2: '',
    salon3: ''
  }

  return { headers, additionalHeaders, filters, item }
}

// 2. Salon
export const getSalonsTableInfo = component => {
  // headers
  const headers = [
    {
      text: '',
      align: 'center',
      value: 'hideBtn',
      width: 20,
      toFilter: false,
      csv: false
      // filterType: 'text',
      // filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Hidden',
      align: 'center',
      value: 'hide',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'hide', 'select')
    },
    {
      text: 'ID',
      align: 'start',
      value: 'id',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'id')
    },
    {
      text: 'Navid',
      align: 'start',
      value: 'navid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'navid')
    },
    {
      text: 'Name',
      align: 'start',
      value: 'name',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'name')
    },
    {
      text: 'Terms',
      align: 'start',
      value: 'terms',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['accepted', 'no answer', 'declined'],
      filter: value => handleFilter(component, value, 'terms', 'select')
    },
    {
      text: 'Active',
      align: 'start',
      value: 'active',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'active', 'select')
    },
    // {
    //   text: 'Feedback',
    //   align: 'start',
    //   value: 'feedback'
    // },
    {
      text: 'Contact',
      align: 'start',
      value: 'contact',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'contact')
    },
    {
      text: 'Address 1',
      align: 'start',
      value: 'address1',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'address1')
    },
    {
      text: 'Address 2',
      align: 'start',
      value: 'address2',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'address2')
    },
    {
      text: 'City',
      align: 'start',
      value: 'city',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'city')
    },
    {
      text: 'State',
      align: 'start',
      value: 'state',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'state')
    },
    {
      text: 'Country',
      align: 'start',
      value: 'country',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'country')
    },
    {
      text: 'ZIP',
      align: 'start',
      value: 'zip',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'zip')
    },
    {
      text: 'Phone',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'SMS Phones',
      align: 'start',
      value: 'smsPhones',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'smsPhones', 'number')
    },
    {
      text: 'SMS validated (min 1)',
      align: 'start',
      value: 'smsPhonesValidated',
      width: 200,
      toFilter: true,
      filterOptions: ['yes', 'no', 'empty'],
      filterType: 'select',
      filter: value =>
        handleFilter(component, value, 'smsPhonesValidated', 'select')
    },
    {
      text: 'E-Mail',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Web Site',
      align: 'start',
      value: 'website',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'website')
    },
    {
      text: 'System',
      align: 'start',
      value: 'systems',
      sortable: false,
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'systems', 'multiple')
    },
    {
      text: 'Referred Total',
      align: 'center',
      value: 'consumersChoiceTotal',
      width: 80,
      toFilter: false,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumerCount')
    },
    {
      text: 'Referred 1',
      align: 'center',
      value: 'consumersChoice1',
      width: 80,
      toFilter: false,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumersChoice1')
    },
    {
      text: 'Referred 2',
      align: 'center',
      value: 'consumersChoice2',
      width: 80,
      toFilter: false,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumersChoice2')
    },
    {
      text: 'Referred 3',
      align: 'center',
      value: 'consumersChoice3',
      width: 80,
      toFilter: false,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumersChoice3')
    },
    {
      text: 'Distance',
      align: 'start',
      value: 'distance',
      csv: false
    },
    {
      text: 'Agreement',
      value: 'agreement',
      align: 'center',
      sortable: true,
      csv: false
    },
    {
      text: 'Message Allowed',
      align: 'center',
      value: 'callOnly',
      width: 20,
      toFilter: false,
      csv: false
      // filterType: 'text',
      // filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      width: 100,
      sortable: false,
      csv: false
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  const item = {
    id: '',
    name: '',
    navid: '',
    contact: '',
    systems: [],
    address1: '',
    address2: '',
    city: '',
    state: '',
    contry: '',
    zip: '',
    phone: '',
    smsPhones: '',
    email: '',
    website: '',
    review1: '',
    review2: '',
    review3: '',
    hours: '',
    terms: '',
    active: ''
  }

  return { headers, filters, item }
}

// 3a. Messages and Calls
export const getMessagesAndCallsTableInfo = component => {
  // headers
  const headers = [
    {
      text: 'Consumer ID',
      align: 'start',
      value: 'consumerId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumerId')
    },
    {
      text: 'Consumer',
      align: 'start',
      value: 'consumer',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Email',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Phone',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'Phone Validated',
      align: 'start',
      value: 'phoneValidated',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'phoneValidated')
    },
    {
      text: 'System',
      align: 'start',
      value: 'system',
      width: 200,
      sortable: false,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'Salon ID',
      align: 'start',
      value: 'salonId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonId')
    },
    {
      text: 'Salon',
      align: 'start',
      value: 'salon',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon')
    },
    {
      text: 'Salon Navid',
      align: 'start',
      value: 'navid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'navid')
    },
    {
      text: 'Terms',
      align: 'start',
      value: 'terms',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['accepted', 'no answer', 'declined'],
      filter: value => handleFilter(component, value, 'terms', 'select')
    },
    {
      text: 'Salon Replied',
      align: 'start',
      value: 'salonReplied',
      width: 130,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'salonReplied', 'select')
    },
    {
      text: '1st Msg Consumer',
      align: 'start',
      value: 'consumer1stMessage'
    },
    {
      text: '1st Msg Salon',
      align: 'start',
      value: 'salon1stMessage'
    },
    {
      text: '1st Call Consumer',
      align: 'start',
      value: 'consumer1stCall'
    },
    {
      text: '1st Call Salon',
      align: 'start',
      value: 'salon1stCall'
    },
    {
      text: 'Salon Replied back in',
      align: 'start',
      value: 'salonRepliedTime'
    },
    {
      text: 'Deferred Emails',
      align: 'start',
      value: 'deferredEmails',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: [...Object.values(DEFERRED_EMAIL), 'none'],
      filter: value =>
        handleFilter(component, value, 'deferredEmails', 'multiple')
    },
    {
      text: '# of Msgs',
      align: 'center',
      value: 'numberOfMessages',
      width: 100,
      // toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'numberOfMessages')
    },
    {
      text: 'Consultation',
      align: 'center',
      value: 'hasDiscount',
      width: 130,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        { text: 'yes', value: 'yes' },
        { text: 'no answer', value: 'no' }
      ],
      filter: value => handleFilter(component, value, 'hasDiscount', 'select')
    },
    {
      text: 'Consult completed',
      align: 'start',
      value: 'consultationCompleted',
      width: 130,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(CONSULTATION_COMPLETED_OPTIONS),
      filter: value =>
        handleFilter(component, value, 'consultationCompleted', 'select')
    },
    {
      text: 'Application booked',
      align: 'start',
      value: 'applicationBooked',
      width: 130,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(APPLICATION_BOOKED_OPTIONS),
      filter: value =>
        handleFilter(component, value, 'applicationBooked', 'select')
    },
    {
      text: 'Promotion Emails',
      align: 'start',
      value: 'sendPromotion',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'sendPromotion', 'select')
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) filters[h.value] = ''
  })

  return { headers, filters }
}

// 3b. Messages and Calls Simple
export const getConsumersMessagesTableData = component => {
  // headers
  const headers = [
    {
      text: 'Consumer ID',
      align: 'start',
      value: 'consumerId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumerId')
    },
    {
      text: 'Consumer',
      align: 'start',
      value: 'consumer',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Email',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Phone',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'System',
      align: 'start',
      value: 'system',
      width: 200,
      sortable: false,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'Salon ID',
      align: 'start',
      value: 'salonId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonId')
    },
    {
      text: 'Salon',
      align: 'start',
      value: 'salon',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon')
    },
    {
      text: 'Salon Navid',
      align: 'start',
      value: 'navid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'navid')
    },
    // {
    //   text: 'Terms',
    //   align: 'start',
    //   value: 'terms',
    //   width: 200,
    //   toFilter: true,
    //   filterType: 'select',
    //   filterOptions: ['accepted', 'no answer', 'declined'],
    //   filter: value => handleFilter(component, value, 'terms', 'select')
    // },
    // {
    //   text: 'Salon Replied',
    //   align: 'start',
    //   value: 'salonReplied',
    //   width: 130,
    //   sortable: true,
    //   toFilter: true,
    //   filterType: 'select',
    //   filterOptions: ['yes', 'no'],
    //   filter: value => handleFilter(component, value, 'salonReplied', 'select')
    // },
    {
      text: '1st Msg Consumer',
      align: 'start',
      value: 'consumer1stMessage'
    },
    // {
    //   text: '1st Msg Salon',
    //   align: 'start',
    //   value: 'salon1stMessage'
    // },
    // {
    //   text: '1st Call Consumer',
    //   align: 'start',
    //   value: 'consumer1stCall'
    // },
    // {
    //   text: '1st Call Salon',
    //   align: 'start',
    //   value: 'salon1stCall'
    // },
    // {
    //   text: 'Salon Replied back in',
    //   align: 'start',
    //   value: 'salonRepliedTime'
    // },
    // {
    //   text: 'Deferred Emails',
    //   align: 'start',
    //   value: 'deferredEmails',
    //   width: 130,
    //   toFilter: true,
    //   filterType: 'select',
    //   filterOptions: [...Object.values(DEFERRED_EMAIL), 'none'],
    //   filter: value =>
    //     handleFilter(component, value, 'deferredEmails', 'multiple')
    // },
    // {
    //   text: '# of Msgs',
    //   align: 'center',
    //   value: 'numberOfMessages',
    //   width: 100,
    //   // toFilter: true,
    //   filterType: 'text',
    //   filter: value => handleFilter(component, value, 'numberOfMessages')
    // },
    {
      text: '# of Msgs',
      align: 'center',
      value: 'messageCount',
      width: 100,
      // toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'messageCount')
    }
    // {
    //   text: 'Consultation',
    //   align: 'center',
    //   value: 'hasDiscount',
    //   width: 130,
    //   sortable: true,
    //   toFilter: true,
    //   filterType: 'select',
    //   filterOptions: [
    //     { text: 'yes', value: 'yes' },
    //     { text: 'no answer', value: 'no' }
    //   ],
    //   filter: value => handleFilter(component, value, 'hasDiscount', 'select')
    // },
    // {
    //   text: 'Consult completed',
    //   align: 'start',
    //   value: 'consultationCompleted',
    //   width: 130,
    //   sortable: true,
    //   toFilter: true,
    //   filterType: 'select',
    //   filterOptions: Object.values(CONSULTATION_COMPLETED_OPTIONS),
    //   filter: value =>
    //     handleFilter(component, value, 'consultationCompleted', 'select')
    // },
    // {
    //   text: 'Application booked',
    //   align: 'start',
    //   value: 'applicationBooked',
    //   width: 130,
    //   sortable: true,
    //   toFilter: true,
    //   filterType: 'select',
    //   filterOptions: Object.values(APPLICATION_BOOKED_OPTIONS),
    //   filter: value =>
    //     handleFilter(component, value, 'applicationBooked', 'select')
    // }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) filters[h.value] = ''
  })

  return { headers, filters }
}

// 3. messages cs only
export const getMessagesTableInfo = component => {
  // headers
  const headers = [
    {
      text: 'Consumer ID',
      align: 'start',
      value: 'consumerId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumerId')
    },
    {
      text: 'Sender',
      align: 'start',
      value: 'from',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: [MessageFrom.C, MessageFrom.HD, MessageFrom.S],
      filter: value => handleFilter(component, value, 'from', 'select')
    },
    {
      text: 'Consumer',
      align: 'start',
      value: 'consumer',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Salon ID',
      align: 'start',
      value: 'salonId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonId')
    },
    {
      text: 'Salon',
      align: 'start',
      value: 'salon',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon')
    },
    {
      text: 'Salon Navid',
      align: 'start',
      value: 'navid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'navid')
    },
    {
      text: 'System',
      align: 'start',
      value: 'system',
      width: 200,
      sortable: false,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'Message',
      align: 'start',
      value: 'message',
      width: 500,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'message')
    },
    {
      text: 'Created At',
      align: 'start',
      value: 'createdAt',
      width: 300
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  return { headers, filters }
}

// 4. Consultations
export const getConsultationsTableInfo = component => {
  // headers
  const headers = [
    {
      text: 'Consumer ID',
      align: 'start',
      value: 'consumerId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumerId')
    },
    {
      text: 'Consumer',
      align: 'start',
      value: 'consumer',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Email',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Source',
      align: 'start',
      value: 'source',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'source')
    },
    {
      text: 'Phone',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'Phone Validated',
      align: 'start',
      value: 'phoneValidated',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'phoneValidated')
    },
    {
      text: 'Initial System',
      align: 'start',
      value: 'system',
      width: 200,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'System booked',
      align: 'start',
      value: 'systemBooked',
      width: 200,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'systemBooked', 'select')
    },
    {
      text: 'Salon ID',
      align: 'start',
      value: 'salonId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonId')
    },
    {
      text: 'Salon',
      align: 'start',
      value: 'salon',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon')
    },
    {
      text: 'Salon Navid',
      align: 'start',
      value: 'navid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'navid')
    },
    {
      text: 'Salon State',
      align: 'start',
      value: 'state',
      width: 130,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'state')
    },
    // {
    //   text: 'Consultation date and time',
    //   align: 'start',
    //   value: 'dateAndTime',
    //   width: 200,
    //   sortable: true
    // },
    {
      text: 'Consultation Date',
      align: 'start',
      value: 'date',
      width: 200,
      sortable: true
    },
    {
      text: 'Consultation Time',
      align: 'start',
      value: 'time',
      width: 200,
      sortable: true
    },
    // {
    //   text: 'Date and Time 2',
    //   align: 'start',
    //   value: 'dateAndTime2',
    //   width: 200,
    //   sortable: true
    // },
    {
      text: 'Code Revealed Date',
      align: 'start',
      value: 'issuingDate',
      width: 200,
      sortable: true
    },
    {
      text: 'Application Date',
      align: 'start',
      value: 'applicationDate',
      width: 130,
      sortable: true
    },
    {
      text: 'Salon Redeemed Code',
      align: 'start',
      value: 'salonRedeemedCodeDate',
      width: 200,
      sortable: true
      // toFilter: true,
      // filterType: 'select',
      // filterOptions: AllSystemOptionsArray,
      // filter: value => handleFilter(component, value, 'systemBooked', 'select')
    },
    {
      text: 'Discount code (paste code)',
      align: 'start',
      value: 'code',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'code')
    },
    {
      text: 'Discount code expires',
      align: 'start',
      value: 'expiringDate',
      width: 130,
      sortable: true
    },
    {
      text: 'Consult completed',
      align: 'start',
      value: 'consultationCompleted',
      width: 130,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(CONSULTATION_COMPLETED_OPTIONS),
      filter: value =>
        handleFilter(component, value, 'consultationCompleted', 'select')
    },
    {
      text: 'Application booked',
      align: 'start',
      value: 'applicationBooked',
      width: 130,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(APPLICATION_BOOKED_OPTIONS),
      filter: value =>
        handleFilter(component, value, 'applicationBooked', 'select')
    },
    {
      text: 'Quote',
      align: 'start',
      value: 'quote',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'quote')
    },
    {
      text: 'Subtotal',
      align: 'start',
      value: 'subtotal',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'subtotal')
    },
    {
      text: 'Deferred Emails',
      align: 'start',
      value: 'deferredEmails',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: [...Object.values(DEFERRED_EMAIL), 'none'],
      filter: value =>
        handleFilter(component, value, 'deferredEmails', 'multiple')
    },
    {
      text: 'Added By',
      align: 'start',
      value: 'addedBy',
      width: 130,
      sortable: true,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(CONSULTATION_ADDED_BY),
      filter: value => handleFilter(component, value, 'addedBy', 'select')
    },
    {
      text: 'Promotion Emails',
      align: 'start',
      value: 'sendPromotion',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'sendPromotion', 'select')
    },
    { text: 'Actions', value: 'actions', sortable: false, csv: false }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  filters.startDate = null
  filters.endDate = null

  // item
  const item = {
    id: '',
    consumer: '',
    email: '',
    phone: '',
    source: '',
    zip: '',
    system: '',
    salon: '',
    salonId: '',
    navid: '',
    dateAndTime: '',
    date: '',
    time: '',
    code: '',
    expiringDate: '',
    systemBooked: '',
    applicationBooked: '',
    applicationDate: '',
    salonRedeemedCodeDate: '',
    customerType: '',
    quote: '',
    orderNumber: '',
    subtotal: '',
    consultationCompleted: ''
  }

  return { headers, filters, item }
}

// 5a. Other Inq Consumers
export const getConsumersOtherInqTableInfo = component => {
  // headers
  const headers = [
    {
      text: '',
      align: 'center',
      value: 'archiveBtn',
      width: 20,
      toFilter: false,
      csv: false
      // filterType: 'text',
      // filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Archived',
      align: 'center',
      value: 'archive',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'archive', 'select')
    },
    {
      text: 'Consumer',
      align: 'start',
      value: 'consumer',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Consumer ID',
      align: 'start',
      value: 'consumerId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumerId')
    },
    {
      text: 'E-mail',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Zip',
      align: 'start',
      value: 'zip',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'zip')
    },
    {
      text: 'System',
      align: 'start',
      value: 'system',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'system')
    },
    {
      text: 'Message Text',
      align: 'start',
      value: 'body',
      width: 300,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'body')
    },
    {
      text: 'Message Topic',
      align: 'start',
      value: 'topic',
      width: 300,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ContactMessageConsumerTopics),
      filter: value => handleFilter(component, value, 'topic', 'select')
    },
    {
      text: 'Source',
      align: 'start',
      value: 'source',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'source')
    },
    {
      text: 'Send Promotion',
      align: 'start',
      value: 'sendPromotion',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'sendPromotion', 'select')
    },
    {
      text: 'Created At',
      align: 'start',
      value: 'createdAt',
      width: 200
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  filters.startDate = null
  filters.endDate = null

  return { headers, filters }
}

//  5b. Other Inq Stylists
export const getStylistsOtherInqTableInfo = component => {
  // headers
  const headers = [
    {
      text: '',
      align: 'center',
      value: 'archiveBtn',
      width: 20,
      toFilter: false,
      csv: false
      // filterType: 'text',
      // filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Archived',
      align: 'center',
      value: 'archive',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'archive', 'select')
    },
    {
      text: 'Stylist',
      align: 'start',
      value: 'stylist',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'Stylist ID',
      align: 'start',
      value: 'stylistId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'stylistId')
    },
    {
      text: 'E-mail',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Zip',
      align: 'start',
      value: 'zip',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'zip')
    },
    {
      text: 'Message Text',
      align: 'start',
      value: 'body',
      width: 300,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'body')
    },
    {
      text: 'Message Topic',
      align: 'start',
      value: 'topic',
      width: 300,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ContactMessageConsumerTopics),
      filter: value => handleFilter(component, value, 'topic', 'select')
    },
    {
      text: 'Source',
      align: 'start',
      value: 'source',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'source')
    },
    {
      text: 'Send Promotion',
      align: 'start',
      value: 'sendPromotion',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'sendPromotion', 'select')
    },
    {
      text: 'Created At',
      align: 'start',
      value: 'createdAt',
      width: 200
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  filters.startDate = null
  filters.endDate = null

  return { headers, filters }
}

// 6. Pending Salon
export const getPendingSalonsTableInfo = component => {
  // headers
  const headers = [
    {
      text: 'Salon ID',
      align: 'start',
      value: 'id',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'id')
    },
    {
      text: 'Navid',
      align: 'start',
      value: 'navid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'navid')
    },
    {
      text: 'Name',
      align: 'start',
      value: 'name',
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'name')
    },
    {
      text: 'Terms',
      align: 'start',
      value: 'terms',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['accepted', 'no answer', 'declined'],
      filter: value => handleFilter(component, value, 'terms', 'select')
    },
    {
      text: 'Active',
      align: 'start',
      value: 'active',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'active', 'select')
    },
    {
      text: 'Address 1',
      align: 'start',
      value: 'address1',
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'address1')
    },
    {
      text: 'Address 2',
      align: 'start',
      value: 'address2',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'address2')
    },
    {
      text: 'Updated At',
      align: 'start',
      value: 'updatedAt',
      width: 200
    },
    {
      text: 'Actions',
      value: 'actions',
      sortable: false
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  filters.startDate = null
  filters.endDate = null

  const item = {
    id: '',
    name: '',
    nameTemp: '',
    navid: '',
    contact: '',
    contactTemp: '',
    systems: [],
    systemsTemp: [],
    address1: '',
    address1Temp: '',
    address2: '',
    address2Temp: '',
    city: '',
    cityTemp: '',
    state: '',
    stateTemp: '',
    country: '',
    countryTemp: '',
    zip: '',
    zipTemp: '',
    phone: '',
    phoneTemp: '',
    smsPhone1: '',
    smsPhone1Temp: '',
    smsPhone2: '',
    smsPhone2Temp: '',
    smsPhone3: '',
    smsPhone3Temp: '',
    email: '',
    emailTemp: '',
    website: '',
    websiteTemp: '',
    review1: '',
    review2: '',
    review3: '',
    review1Temp: '',
    review2Temp: '',
    review3Temp: '',
    hours: '',
    hoursTemp: '',
    imgUrl: '',
    imgUrlTemp: '',
    highlights: '',
    highlightsTemp: '',
    additionalHighlight: '',
    additionalHighlightTemp: '',
    systemQuestions: '',
    systemQuestionsTemp: '',
    appointmentOnly: '',
    appointmentOnlyTemp: '',
    termsInfo: ''
  }

  return { headers, filters, item }
}

// 6. Salon Advanced Analytics
export const getSalonAdvancedTableInfo = component => {
  // headers
  const headers = [
    {
      text: 'Salon Id',
      align: 'start',
      value: 'salonId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonId')
    },
    {
      text: 'Salon name',
      align: 'start',
      value: 'name',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'name')
    },
    {
      text: 'Salon city',
      align: 'start',
      value: 'city',
      width: 150,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'city')
    },
    {
      text: 'Salon navid',
      align: 'start',
      value: 'navid',
      width: 150,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'navid')
    },
    {
      text: 'Referrals',
      align: 'start',
      value: 'referrals',
      width: 150
    },
    {
      text: 'Consumers Contacted',
      align: 'start',
      value: 'consumersContacted',
      width: 150
    },
    {
      text: 'Contact rate',
      align: 'start',
      value: 'contactRate',
      width: 150
    },
    {
      text: 'Consultations',
      align: 'start',
      value: 'consultations',
      width: 150
    },
    {
      text: 'Consultation %',
      align: 'start',
      value: 'consultationPercentage',
      width: 150
    },
    {
      text: 'Applications',
      align: 'start',
      value: 'applications',
      width: 150
    },
    {
      text: 'Application %',
      align: 'start',
      value: 'applicationPercentage',
      width: 150
    },
    {
      text: '$ Orders',
      align: 'start',
      value: 'orders',
      width: 150
    },
    {
      text: '$ Order Average',
      align: 'start',
      value: 'ordersAvg',
      width: 150
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  return { headers, filters }
}

// 7. Stylists
export const getStylistsTableInfo = component => {
  const AdminStylistHeaders = []
  // headers
  const headers = [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'id')
    },
    {
      text: 'First Name',
      align: 'start',
      value: 'firstName',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'firstName')
    },
    {
      text: 'Last Name',
      align: 'start',
      value: 'lastName',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'lastName')
    },
    {
      text: 'E-mail',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Phone Number',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'Salon Name',
      align: 'start',
      value: 'salon',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon')
    },
    {
      text: 'Salon Address',
      align: 'start',
      value: 'salonAddress',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonAddress')
    },
    {
      text: 'Salon Zip Code',
      align: 'start',
      value: 'salonZip',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonZip')
    },
    {
      text: 'System',
      align: 'start',
      value: 'system',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'Message',
      align: 'start',
      value: 'message',
      width: 400,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'message')
    },
    {
      text: 'Website',
      align: 'start',
      value: 'website',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'website')
    },
    {
      text: 'Hair Extensions',
      align: 'start',
      value: 'doHair',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['Yes', 'No'],
      filter: value => handleFilter(component, value, 'doHair', 'select')
    },
    {
      text: 'Brands',
      align: 'startc',
      value: 'brands',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'brands')
    },
    {
      text: 'Promotion Emails',
      align: 'start',
      value: 'sendPromotion',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['Yes', 'No'],
      filter: value => handleFilter(component, value, 'sendPromotion', 'select')
    },
    {
      text: 'Source',
      align: 'start',
      value: 'source',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'source')
    },
    {
      text: 'Created At',
      align: 'start',
      value: 'createdAt',
      width: 200
    },
    { text: 'Actions', value: 'actions', sortable: false, csv: false }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  filters.startDate = null
  filters.endDate = null

  const item = {
    id: '',
    memo: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    salonZip: '',
    salon: '',
    salonAddress: '',
    system: '',
    message: '',
    website: '',
    doHair: '',
    brands: '',
    sendPromotion: '',
    source: ''
  }

  return { headers, filters, item }
}

// 8. Logs
export const getLogsTableInfo = component => {
  const AdminLogsHeaders = []
  // headers
  const headers = [
    {
      text: 'ID',
      align: 'start',
      value: 'id',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'id')
    },
    {
      text: 'Consumer ID',
      align: 'start',
      value: 'consumerId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumerId')
    },
    {
      text: 'Consumer',
      align: 'start',
      value: 'consumer',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'consumer')
    },
    {
      text: 'System',
      align: 'start',
      value: 'system',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: AllSystemOptionsArray,
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'Source',
      align: 'start',
      value: 'source',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'source')
    },
    {
      text: 'Price Visible',
      align: 'start',
      value: 'priceVisible',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'priceVisible')
    },
    {
      text: 'Last Question Page',
      align: 'start',
      value: 'lastQuestionPage',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'lastQuestionPage')
    },
    {
      text: 'Price Page',
      align: 'start',
      value: 'pricePage',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'pricePage')
    },
    {
      text: 'Submission Page',
      align: 'start',
      value: 'submissionPage',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'submissionPage')
    },
    {
      text: 'Consumer Created At',
      align: 'start',
      value: 'consumerCreatedAt',
      width: 200
    },
    {
      text: 'Updated At',
      align: 'start',
      value: 'updatedAt',
      width: 200
    },
    {
      text: 'Created At',
      align: 'start',
      value: 'createdAt',
      width: 200
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) {
      filters[h.value] = ''
    }
  })

  filters.startDate = null
  filters.endDate = null

  const item = {
    id: '',
    consumerId: '',
    consumer: '',
    system: '',
    source: '',
    priceVisible: '',
    lastQuestionPage: '',
    pricePage: '',
    submissionPage: ''
  }

  return { headers, filters, item }
}

// 9a. Finished Applications
export const getFinishedApplications = component => {
  // headers
  const headers = [
    {
      text: '',
      align: 'center',
      value: 'archiveBtn',
      width: 20,
      toFilter: false,
      csv: false
    },
    {
      text: 'To Export',
      align: 'center',
      value: 'toExport',
      width: 20,
      toFilter: false,
      csv: false
    },
    {
      text: 'Exported',
      align: 'center',
      value: 'exportedDate',
      width: 20,
      toFilter: false
    },
    // {
    //   text: 'Archived',
    //   align: 'center',
    //   value: 'archive',
    //   width: 130,
    //   toFilter: true,
    //   filterType: 'select',
    //   filterOptions: ['yes', 'no'],
    //   filter: value => handleFilter(component, value, 'hide', 'select')
    // },
    { text: 'Actions', value: 'actions', sortable: false, csv: false },
    {
      text: 'Link',
      align: 'center',
      value: 'fspBtn',
      sortable: false
    },
    {
      text: 'Application ID',
      align: 'start',
      value: 'id',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'id')
    },
    {
      text: 'Source',
      align: 'start',
      value: 'source',
      width: 100,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'source')
    },
    {
      text: 'First Name',
      align: 'start',
      value: 'firstName',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'firstName')
    },
    {
      text: 'Last Name',
      align: 'start',
      value: 'lastName',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'lastName')
    },
    {
      text: 'Salon Name',
      align: 'start',
      value: 'salon',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon')
    },
    {
      text: 'Email',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Primary System',
      align: 'start',
      value: 'primarySystem',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(SystemOptions),
      filter: value => handleFilter(component, value, 'primarySystem', select)
    },
    {
      text: 'Recent changes',
      align: 'start',
      value: 'tags',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationTags),
      filter: value => handleFilter(component, value, 'tags', 'select')
    },
    {
      text: 'Application Order',
      align: 'start',
      value: 'applicationOrder',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        'application-1',
        'application-2',
        'application-3',
        'application-4',
        'application-5'
      ],
      filter: value =>
        handleFilter(component, value, 'applicationOrder', 'select')
    },
    {
      text: 'All Systems',
      align: 'start',
      value: 'system',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(SystemOptions),
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'Status ML',
      align: 'start',
      value: 'statusML',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationStatus),
      filter: value => handleFilter(component, value, 'statusML', 'select')
    },
    {
      text: 'Status LB',
      align: 'start',
      value: 'statusLB',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationStatus),
      filter: value => handleFilter(component, value, 'statusLB', 'select')
    },
    {
      text: 'Status FS',
      align: 'start',
      value: 'statusFS',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationStatus),
      filter: value => handleFilter(component, value, 'statusFS', 'select')
    },
    {
      text: 'Status TA',
      align: 'start',
      value: 'statusTA',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationStatus),
      filter: value => handleFilter(component, value, 'statusTA', 'select')
    },
    {
      text: 'ML Class',
      align: 'start',
      value: 'mlClass',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(MLClass),
      filter: value => handleFilter(component, value, 'mlClass', 'select')
    },
    {
      text: 'LB Class',
      align: 'start',
      value: 'lbClass',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(LBClass),
      filter: value => handleFilter(component, value, 'lbClass', 'select')
    },
    {
      text: 'FS Class',
      align: 'start',
      value: 'fsClass',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(FSClass),
      filter: value => handleFilter(component, value, 'fsClass', 'select')
    },
    {
      text: 'No Star Salons Closer Than 8 Miles',
      align: 'start',
      value: 'noSSalonsCloserThan8Miles',
      width: 200
    },
    {
      text: 'No A Salons Closer Than 4 Miles',
      align: 'start',
      value: 'noASalonsCloserThan4Miles',
      width: 200
    },
    {
      text: 'No B Salons Closer Than 1 Mile',
      align: 'start',
      value: 'noBSalonsCloserThan1Mile',
      width: 200
    },
    {
      text: 'No C Salons Closer Than 1 Mile',
      align: 'start',
      value: 'noCSalonsCloserThan1Mile',
      width: 200
    },
    {
      text: 'Extensions',
      align: 'start',
      value: 'extensions',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensions')
    },
    {
      text: 'Extensions Brands',
      align: 'start',
      value: 'extensionBrands',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensionBrands')
    },
    {
      text: 'Extensions Services Frequency',
      align: 'start',
      value: 'extensionServicesFrequency',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value =>
        handleFilter(component, value, 'extensionServicesFrequency')
    },
    {
      text: 'Extensions Methods',
      align: 'start',
      value: 'extensionMethods',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensionMethods')
    },
    {
      text: 'Extensions Clients Fusion',
      align: 'start',
      value: 'extensionClientsFusion',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensionClientsFusion')
    },
    {
      text: 'Extensions Client Number',
      align: 'start',
      value: 'extensionsClientNumber',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensionsClientNumber')
    },
    {
      text: 'Toppers',
      align: 'start',
      value: 'toppers',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'toppers')
    },
    {
      text: 'Toppers Blend',
      align: 'start',
      value: 'toppersBlend',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'toppersBlend')
    },
    {
      text: 'Microlines Application Spend',
      align: 'start',
      value: 'microlinesApplicationSpend',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value =>
        handleFilter(component, value, 'microlinesApplicationSpend')
    },
    {
      text: 'Changed Microlines Application Spend Answer',
      align: 'start',
      value: 'changedApplicationSpendAnswer',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value =>
        handleFilter(component, value, 'changedApplicationSpendAnswer')
    },
    {
      text: 'Microlines Maintenance Spend',
      align: 'start',
      value: 'microlinesMaintenanceSpend',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value =>
        handleFilter(component, value, 'microlinesMaintenanceSpend')
    },
    {
      text: 'Microlines Client Number',
      align: 'start',
      value: 'microlinesClientNumber',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'microlinesClientNumber')
    },
    {
      text: 'Ads',
      align: 'start',
      value: 'ads',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'ads')
    },
    {
      text: 'Phone',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'Salon ID',
      align: 'start',
      value: 'stylistId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'stylistId')
    },
    {
      text: 'Full Address',
      align: 'start',
      value: 'salonAddress',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonAddress')
    },
    {
      text: 'Address',
      align: 'start',
      value: 'shortSalonAddress',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'shortSalonAddress')
    },
    {
      text: 'City',
      align: 'start',
      value: 'city',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'city')
    },
    {
      text: 'State',
      align: 'start',
      value: 'state',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'state')
    },
    {
      text: 'Country',
      align: 'start',
      value: 'country',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'country')
    },
    {
      text: 'Zip',
      align: 'start',
      value: 'salonZip',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonZip')
    },
    {
      text: 'Address Type',
      align: 'start',
      value: 'addressType',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'addressType')
    },
    {
      text: 'Mobile',
      align: 'start',
      value: 'mobile',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'mobile')
    },
    {
      text: 'Salon Type',
      align: 'start',
      value: 'salonType',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonType')
    },
    {
      text: 'Profession',
      align: 'start',
      value: 'profession',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'profession')
    },
    {
      text: 'HD Certified',
      align: 'start',
      value: 'hdCertified',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'hdCertified')
    },
    {
      text: 'Website',
      align: 'start',
      value: 'website',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'website')
    },
    {
      text: 'Instagram',
      align: 'start',
      value: 'instagram',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'instagram')
    },
    {
      text: 'Facebook',
      align: 'start',
      value: 'facebook',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'facebook')
    },
    {
      text: 'TikTok',
      align: 'start',
      value: 'tikTok',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'tikTok')
    },
    {
      text: 'Other',
      align: 'start',
      value: 'other',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'other')
    },
    {
      text: 'FS Online',
      align: 'start',
      value: 'fsOnline',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'fsOnline')
    },
    {
      text: 'Memo',
      align: 'center',
      value: 'memo',
      width: 200,
      toFilter: false,
      csv: true
    },
    {
      text: 'Send Promotion',
      align: 'center',
      value: 'sendPromotion',
      width: 20,
      toFilter: false,
      csv: true
    },
    {
      text: 'LB CTA clicked',
      align: 'center',
      value: 'lbClicked',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'lbClicked', 'select')
    },
    {
      text: 'FS CTA clicked',
      align: 'center',
      value: 'fsClicked',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'fsClicked', 'select')
    },
    {
      text: 'TA CTA clicked',
      align: 'center',
      value: 'taClicked',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'taClicked', 'select')
    },
    {
      text: 'ML CTA clicked',
      align: 'center',
      value: 'mlClicked',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'mlClicked', 'select')
    },
    {
      text: 'Updated At',
      align: 'start',
      value: 'updatedAt',
      width: 200
    },
    {
      text: 'Created At',
      align: 'start',
      value: 'createdAt',
      width: 200
    }
  ]

  // additonal headers
  const additionalHeaders = [
    {
      text: 'utm_campaign',
      align: 'start',
      value: 'utm_campaign',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_campaign')
    },
    {
      text: 'utm_source',
      align: 'start',
      value: 'utm_source',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_source')
    },
    {
      text: 'utm_term',
      align: 'start',
      value: 'utm_term',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_term')
    },
    {
      text: 'utm_medium',
      align: 'start',
      value: 'utm_medium',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_medium')
    },
    {
      text: 'utm_content',
      align: 'start',
      value: 'utm_content',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_content')
    },
    {
      text: 'gclid',
      align: 'start',
      value: 'gclid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'gclid')
    },
    {
      text: 'fbclid',
      align: 'start',
      value: 'fbclid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'fbclid')
    },
    {
      text: 'msclkid',
      align: 'start',
      value: 'msclkid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'msclkid')
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) filters[h.value] = ''
  })

  return { headers, additionalHeaders, filters }
}

// 9b. Pending Applications
export const getPendingApplications = component => {
  // headers
  const headers = [
    {
      text: '',
      align: 'center',
      value: 'archiveBtn',
      width: 20,
      toFilter: false,
      csv: false
    },
    {
      text: 'To Export',
      align: 'center',
      value: 'toExport',
      width: 20,
      toFilter: false,
      csv: false
    },
    {
      text: 'Exported',
      align: 'center',
      value: 'exportedDate',
      width: 20,
      toFilter: false
    },
    // {
    //   text: 'Archived',
    //   align: 'center',
    //   value: 'archive',
    //   width: 130,
    //   toFilter: true,
    //   filterType: 'select',
    //   filterOptions: ['yes', 'no'],
    //   filter: value => handleFilter(component, value, 'hide', 'select')
    // },
    { text: 'Actions', value: 'actions', sortable: false, csv: false },
    {
      text: 'Link',
      align: 'center',
      value: 'fspBtn',
      sortable: false,
      csv: true
    },
    {
      text: 'Application ID',
      align: 'start',
      value: 'id',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'id')
    },
    {
      text: 'Source',
      align: 'start',
      value: 'source',
      width: 100,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'source')
    },
    {
      text: 'First Name',
      align: 'start',
      value: 'firstName',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'firstName')
    },
    {
      text: 'Last Name',
      align: 'start',
      value: 'lastName',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'lastName')
    },
    {
      text: 'Salon Name',
      align: 'start',
      value: 'salon',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salon')
    },
    {
      text: 'Email',
      align: 'start',
      value: 'email',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'email')
    },
    {
      text: 'Primary System',
      align: 'start',
      value: 'primarySystem',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(SystemOptions),
      filter: value => handleFilter(component, value, 'primarySystem', select)
    },
    {
      text: 'Recent changes',
      align: 'start',
      value: 'tags',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationTags),
      filter: value => handleFilter(component, value, 'tags', 'select')
    },
    {
      text: 'Application Order',
      align: 'start',
      value: 'applicationOrder',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: [
        'application-1',
        'application-2',
        'application-3',
        'application-4',
        'application-5'
      ],
      filter: value =>
        handleFilter(component, value, 'applicationOrder', 'select')
    },
    {
      text: 'All Systems',
      align: 'start',
      value: 'system',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(SystemOptions),
      filter: value => handleFilter(component, value, 'system', 'select')
    },
    {
      text: 'Status ML',
      align: 'start',
      value: 'statusML',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationStatus),
      filter: value => handleFilter(component, value, 'statusML', 'select')
    },
    {
      text: 'Status LB',
      align: 'start',
      value: 'statusLB',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationStatus),
      filter: value => handleFilter(component, value, 'statusLB', 'select')
    },
    {
      text: 'Status FS',
      align: 'start',
      value: 'statusFS',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationStatus),
      filter: value => handleFilter(component, value, 'statusFS', 'select')
    },
    {
      text: 'Status TA',
      align: 'start',
      value: 'statusTA',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(ApplicationStatus),
      filter: value => handleFilter(component, value, 'statusTA', 'select')
    },
    {
      text: 'ML Class',
      align: 'start',
      value: 'mlClass',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(MLClass),
      filter: value => handleFilter(component, value, 'mlClass', 'select')
    },
    {
      text: 'LB Class',
      align: 'start',
      value: 'lbClass',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(LBClass),
      filter: value => handleFilter(component, value, 'lbClass', 'select')
    },
    {
      text: 'FS Class',
      align: 'start',
      value: 'fsClass',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(FSClass),
      filter: value => handleFilter(component, value, 'fsClass', 'select')
    },
    {
      text: 'No Star Salons Closer Than 8 Miles',
      align: 'start',
      value: 'noSSalonsCloserThan8Miles',
      width: 200
    },
    {
      text: 'No A Salons Closer Than 4 Miles',
      align: 'start',
      value: 'noASalonsCloserThan4Miles',
      width: 200
    },
    {
      text: 'No B Salons Closer Than 1 Mile',
      align: 'start',
      value: 'noBSalonsCloserThan1Mile',
      width: 200
    },
    {
      text: 'No C Salons Closer Than 1 Mile',
      align: 'start',
      value: 'noCSalonsCloserThan1Mile',
      width: 200
    },
    {
      text: 'Extensions',
      align: 'start',
      value: 'extensions',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensions')
    },
    {
      text: 'Extensions Brands',
      align: 'start',
      value: 'extensionBrands',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensionBrands')
    },
    {
      text: 'Extensions Services Frequency',
      align: 'start',
      value: 'extensionServicesFrequency',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value =>
        handleFilter(component, value, 'extensionServicesFrequency')
    },
    {
      text: 'Extensions Methods',
      align: 'start',
      value: 'extensionMethods',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensionMethods')
    },
    {
      text: 'Extensions Clients Fusion',
      align: 'start',
      value: 'extensionClientsFusion',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensionClientsFusion')
    },
    {
      text: 'Extensions Client Number',
      align: 'start',
      value: 'extensionsClientNumber',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'extensionsClientNumber')
    },
    {
      text: 'Toppers',
      align: 'start',
      value: 'toppers',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'toppers')
    },
    {
      text: 'Toppers Blend',
      align: 'start',
      value: 'toppersBlend',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'toppersBlend')
    },
    {
      text: 'Microlines Application Spend',
      align: 'start',
      value: 'microlinesApplicationSpend',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value =>
        handleFilter(component, value, 'microlinesApplicationSpend')
    },
    {
      text: 'Changed Microlines Application Spend Answer',
      align: 'start',
      value: 'changedApplicationSpendAnswer',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value =>
        handleFilter(component, value, 'changedApplicationSpendAnswer')
    },
    {
      text: 'Microlines Maintenance Spend',
      align: 'start',
      value: 'microlinesMaintenanceSpend',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value =>
        handleFilter(component, value, 'microlinesMaintenanceSpend')
    },
    {
      text: 'Microlines Client Number',
      align: 'start',
      value: 'microlinesClientNumber',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'microlinesClientNumber')
    },
    {
      text: 'Ads',
      align: 'start',
      value: 'ads',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'ads')
    },
    {
      text: 'Phone',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'Salon ID',
      align: 'start',
      value: 'stylistId',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'stylistId')
    },
    {
      text: 'Full Address',
      align: 'start',
      value: 'salonAddress',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonAddress')
    },
    {
      text: 'Address',
      align: 'start',
      value: 'shortSalonAddress',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'shortSalonAddress')
    },
    {
      text: 'City',
      align: 'start',
      value: 'city',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'city')
    },
    {
      text: 'State',
      align: 'start',
      value: 'state',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'state')
    },
    {
      text: 'Country',
      align: 'start',
      value: 'country',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'country')
    },
    {
      text: 'Zip',
      align: 'start',
      value: 'salonZip',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonZip')
    },
    {
      text: 'Address Type',
      align: 'start',
      value: 'addressType',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'addressType')
    },
    {
      text: 'Mobile',
      align: 'start',
      value: 'mobile',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'mobile')
    },
    {
      text: 'Salon Type',
      align: 'start',
      value: 'salonType',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'salonType')
    },
    {
      text: 'Profession',
      align: 'start',
      value: 'profession',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'profession')
    },
    {
      text: 'HD Certified',
      align: 'start',
      value: 'hdCertified',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'hdCertified')
    },
    {
      text: 'Website',
      align: 'start',
      value: 'website',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'website')
    },
    {
      text: 'Instagram',
      align: 'start',
      value: 'instagram',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'instagram')
    },
    {
      text: 'Facebook',
      align: 'start',
      value: 'facebook',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'facebook')
    },
    {
      text: 'TikTok',
      align: 'start',
      value: 'tikTok',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'tikTok')
    },
    {
      text: 'Other',
      align: 'start',
      value: 'other',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'other')
    },
    {
      text: 'FS Online',
      align: 'start',
      value: 'fsOnline',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'fsOnline')
    },
    {
      text: 'Memo',
      align: 'center',
      value: 'memo',
      width: 200,
      toFilter: false,
      csv: true
    },
    {
      text: 'Send Promotion',
      align: 'center',
      value: 'sendPromotion',
      width: 20,
      toFilter: false,
      csv: true
    },
    {
      text: 'LB CTA clicked',
      align: 'center',
      value: 'lbClicked',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'lbClicked', 'select')
    },
    {
      text: 'FS CTA clicked',
      align: 'center',
      value: 'fsClicked',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'fsClicked', 'select')
    },
    {
      text: 'TA CTA clicked',
      align: 'center',
      value: 'taClicked',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'taClicked', 'select')
    },
    {
      text: 'ML CTA clicked',
      align: 'center',
      value: 'mlClicked',
      width: 130,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['yes', 'no'],
      filter: value => handleFilter(component, value, 'mlClicked', 'select')
    },
    {
      text: 'Updated At',
      align: 'start',
      value: 'updatedAt',
      width: 200
    },
    {
      text: 'Created At',
      align: 'start',
      value: 'createdAt',
      width: 200
    }
  ]

  // additional headers
  const additionalHeaders = [
    {
      text: 'utm_campaign',
      align: 'start',
      value: 'utm_campaign',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_campaign')
    },
    {
      text: 'utm_source',
      align: 'start',
      value: 'utm_source',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_source')
    },
    {
      text: 'utm_term',
      align: 'start',
      value: 'utm_term',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_term')
    },
    {
      text: 'utm_medium',
      align: 'start',
      value: 'utm_medium',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_medium')
    },
    {
      text: 'utm_content',
      align: 'start',
      value: 'utm_content',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'utm_content')
    },
    {
      text: 'gclid',
      align: 'start',
      value: 'gclid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'gclid')
    },
    {
      text: 'fbclid',
      align: 'start',
      value: 'fbclid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'fbclid')
    },
    {
      text: 'msclkid',
      align: 'start',
      value: 'msclkid',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'msclkid')
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) filters[h.value] = ''
  })

  return { headers, additionalHeaders, filters }
}

// 10. Distance Checker
export const getDistanceChecker = component => {
  // headers
  const headers = [
    {
      text: 'Navid',
      align: 'start',
      value: 'navid',
      width: 150,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'navid')
    },
    {
      text: 'Rating',
      align: 'start',
      value: 'rating',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: Object.values(SalonSourceRating),
      filter: value => handleFilter(component, value, 'rating')
    },
    {
      text: 'Address',
      align: 'start',
      value: 'address',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'address')
    },
    {
      text: 'Zipcode',
      align: 'start',
      value: 'zipcode',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'zipcode')
    },
    {
      text: 'Country',
      align: 'start',
      value: 'country',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['United States', 'Canada'],
      filter: value => handleFilter(component, value, 'country', select)
    },
    {
      text: 'Country Code',
      align: 'start',
      value: 'countryCode',
      width: 200,
      toFilter: true,
      filterType: 'select',
      filterOptions: ['us', 'ca'],
      filter: value => handleFilter(component, value, 'countryCode', select)
    },
    {
      text: 'City',
      align: 'start',
      value: 'city',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'city')
    },
    {
      text: 'State',
      align: 'start',
      value: 'state',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'state')
    },
    {
      text: 'Company',
      align: 'start',
      value: 'company',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'company')
    },
    {
      text: 'Phone',
      align: 'start',
      value: 'phone',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'phone')
    },
    {
      text: 'Contact',
      align: 'start',
      value: 'contact',
      width: 200,
      toFilter: true,
      filterType: 'text',
      filter: value => handleFilter(component, value, 'contact')
    },
    {
      text: 'Created At',
      align: 'start',
      value: 'createdAt',
      width: 200
    }
  ]

  // filters
  const filters = {}

  headers.forEach(h => {
    if (h.toFilter) filters[h.value] = ''
  })

  return { headers, filters }
}
