<template>
  <v-container>
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="400" class="mx-auto mt-5">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Login form</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          label="Email"
          v-model="user.email"
          prepend-icon="mdi-account-circle"
        />
        <v-text-field
          v-model="user.password"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
        />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn
          color="info"
          :disabled="!user.password || !user.email"
          @click="handleLogin"
          :loading="loading"
        >
          Login</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    data() {
      return {
        showPassword: false,
        user: {
          email: '',
          password: ''
        },
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red'
      }
    },
    computed: {
      loading() {
        return this.$store.state.auth.loading
      }
    },
    methods: {
      ...mapActions(['login']),
      async handleLogin() {
        await this.login(this.user)

        if (!this.$store.state.auth.error) {
          if (this.$route.query.from) {
            this.$router.replace(this.$route.query.from)
          } else {
            this.$router.push({ name: 'Salons' })
          }
        } else {
          this.errorMessage = this.$store.state.auth.error
          this.snackbar = true
        }
      }
    }
  }
</script>
