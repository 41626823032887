import store from '@/store'
import axios from 'axios'
import { MessageTypes } from '../config/enums'
import { getDatesForDateFilter } from '../utils/helpers'

axios.defaults.baseURL = '/api/v2'
axios.defaults.withCredentials = false

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.dispatch('logout')
    }
    return Promise.reject(error)
  }
)

export default {
  // Source Salons
  importSourceSalons: sourcesalons => {
    let formData = new FormData()
    formData.append('file', sourcesalons)
    return axios.post('/source-salons/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  // ANALYTICS
  // analytics - traffic
  getTrafficConsumersSources(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/traffic/consumers/sources?' + query)
  },
  getTrafficTopConsumersSources(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/traffic/consumers/sources/top?' + query)
  },
  getTrafficTopConsumersIndividualSources(startDate, endDate, source) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (source) {
      query = query + `&source=${source}`
    }
    return axios.get('/analytics/traffic/consumers/sources/individual?' + query)
  },
  getTrafficTopStylistsSources(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/traffic/stylists/sources/top?' + query)
  },
  getTrafficTopStylistsIndividualSources(startDate, endDate, source) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (source) {
      query = query + `&source=${source}`
    }
    return axios.get('/analytics/traffic/stylists/sources/individual?' + query)
  },
  // analytics - salons
  getSalonsActive() {
    return axios.get('/analytics/salons/active')
  },
  getSalonsSmsPhones() {
    return axios.get('/analytics/salons/sms-phones')
  },
  getSalonsAdvancedAnalyticsReferrals(startDate, endDate, system) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (system) query += `&system=${system}`
    return axios.get('/analytics/salons/advanced/referrals?' + query)
  },
  getSalonsAdvancedAnalyticsMessages(startDate, endDate, system) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (system) query += `&system=${system}`
    return axios.get('/analytics/salons/advanced/messages?' + query)
  },
  getSalonsAdvancedAnalyticsCalls(startDate, endDate, system) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate,
      system
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (system) query += `&system=${system}`
    return axios.get('/analytics/salons/advanced/calls?' + query)
  },
  getSalonsAdvancedAnalyticsConsultations(startDate, endDate, system) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate,
      system
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (system) query += `&system=${system}`
    return axios.get('/analytics/salons/advanced/consultations?' + query)
  },
  postApplicationsCsv: (data, status) => {
    return axios.post(`/applications/send-csv/${status}`, data)
  },
  postSourceSalonsCsv: data => {
    return axios.post('/source-salons/send-csv', data)
  },
  getSalonsAdvancedAnalyticsApplications(startDate, endDate, system) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate,
      system
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (system) query += `&system=${system}`
    return axios.get('/analytics/salons/advanced/applications?' + query)
  },
  getSalonsAdvancedAnalyticsOrders(startDate, endDate, system) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate,
      system
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (system) query += `&system=${system}`
    return axios.get('/analytics/salons/advanced/orders?' + query)
  },
  getSalonsAdvancedConsumersContacted(startDate, endDate, system) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate,
      system
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (system) query += `&system=${system}`
    return axios.get('/analytics/salons/advanced/consumers-contacted?' + query)
  },
  getSalonsAdvancedSubtotalCount(startDate, endDate, system) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate,
      system
    )
    let query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    if (system) query += `&system=${system}`
    return axios.get('/analytics/salons/advanced/subtotal-count?' + query)
  },
  // analytics - consumers
  getConsumersNumbers(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/numbers?' + query)
  },
  getConsumersByZip(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/zip?' + query)
  },
  getConsumersBySystem(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/systems?' + query)
  },
  getConsumersWithoutSalons(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/without-salons?' + query)
  },
  getConsumersWithoutSalonsByGeo(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/without-salons/geo?' + query)
  },
  getConsumersContactedSalons(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/contacted-salons?' + query)
  },
  getConsumersMessagesAndCalls(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/messages/calls?' + query)
  },
  getConsumersSmsPhones(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/sms-phones?' + query)
  },
  getConsumersBySystemPie(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/systems/pie?' + query)
  },
  getConsumersLogsBySystem(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consumers/logs/systems?' + query)
  },
  // analytics - consultations
  getConsultationsNumbers(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consultations/numbers?' + query)
  },
  getConsultationsByCompletedAndBoooked(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consultations/completed/booked?' + query)
  },
  getConsultationsSystems(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consultations/systems?' + query)
  },
  getConsultationsDiscountUsed(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consultations/discount-used?' + query)
  },
  getConsultationsSystemsSubtotal(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get('/analytics/consultations/systems/subtotal?' + query)
  },
  getConsultationsApplicationYesBySystem(startDate, endDate) {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      startDate,
      endDate
    )
    const query = `startDate=${startLocal}&endDate=${endLocal}&offset=${offset}`
    return axios.get(
      '/analytics/consultations/application-yes/systems?' + query
    )
  },
  // note: documents
  getAgreement(id) {
    return axios.get('/documents/agreement/' + id, { responseType: 'blob' })
  },
  sendCCPA(data) {
    return axios.post('/documents/ccpa', data)
  },

  // note: applications
  getApplication: id => {
    return axios.get('/applications/' + id)
  },
  postApplication: application => {
    const { stylistId, primarySystem } = application
    return axios.post('/applications', {
      account: stylistId,
      primarySystem
    })
  },
  resetApplication: id => {
    return axios.patch(`/applications/${id}/reset`, {})
  },
  patchApplication: application => {
    return axios.patch('/applications/' + application._id, application)
  },
  calculateStatuses: (id, sendEmail) => {
    return axios.get(
      `/applications/calculate/${id}?sendEmail=${sendEmail ? true : false}`
    )
  },
  getApplicationsByAccount: accountId => {
    return axios.get('/applications/account/' + accountId)
  },

  getApplicationsTableData: (limit, page, search, sortBy, sortDesc, status) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }
    return axios.get(
      `/applications/table/${status}?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },

  getSourceSalonsTableData: (limit, page, search, sortBy, sortDesc) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/source-salons/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },

  // note: stylists
  postStylist: stylist => {
    return axios.post('/stylists', stylist)
  },

  getStylists: () => {
    return axios.get('/stylists')
  },
  postStylistsCsv: data => {
    return axios.post('/stylists/send-csv-data', data)
  },
  getStylistsTableData: (limit, page, search, sortBy, sortDesc) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/stylists/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },
  getSettings: settings => {
    return axios.get(`/settings`, settings)
  },
  patchSettings: settings => {
    return axios.patch(`/settings/${settings.id}`, settings)
  },

  // use for edit and for delete -> active: false
  patchStylist: stylist => {
    return axios.patch(`/stylists/${stylist.id}`, stylist)
  },

  // stylists account
  patchStylistAccount: stylist => {
    return axios.patch(`/stylists-account/${stylist.id}`, stylist)
  },
  getStylistAccount: id => {
    return axios.get('/stylists-account/' + id)
  },

  // // connect to stylist account
  // uploadLicense: (id, form) => {
  //   return axios.patch('/stylists-account/image/' + id, form)
  // },

  // user -> connect to application
  uploadLicense: (id, form) => {
    return axios.patch('/applications/image/' + id, form)
  },

  // admin -> connect to application
  uploadAdminLicense: (id, form) => {
    return axios.patch('/applications/admin-image/' + id, form)
  },

  // note: setups
  getSetups: () => {
    return axios.get('/setups')
  },

  postSetup: setup => {
    return axios.post('/setups', setup)
  },

  // note: logs
  getLogs: () => {
    return axios.get('/logs')
  },

  getLogsTableData: (limit, page, search, sortBy, sortDesc) => {
    const { start, end } = getDatesForDateFilter(
      search['startDate'],
      search['endDate']
    )
    search['startDate'] = start
    search['endDate'] = end

    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/logs/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },

  postLogsCsv: data => {
    return axios.post('/logs/send-csv-data', data)
  },

  postLog: log => {
    return axios.post('/logs', log)
  },

  patchLog: log => {
    return axios.patch(`/logs/${log.id}`, log)
  },

  // note: consumers
  // user
  sendVerifyConsumerSms: formattedPhone => {
    return axios.get(`/consumers/sms/verify/${formattedPhone}`)
  },

  getConsumer: id => {
    return axios.get('/consumers/' + id)
  },

  postConsumer: consumer => {
    return axios.post('/consumers', consumer)
  },

  saveForLater: data => {
    return axios.post('/consumers/save-link', data)
  },

  revealCode: (consumerId, salonId, data) => {
    return axios.post(`/consumers/discount/${consumerId}/${salonId}`, data)
  },

  createConsultationBySalon: data => {
    return axios.post(`/consumers/consultations/salon`, data)
  },
  // admin
  createConsultationByAdmin: data => {
    return axios.post(`/consumers/consultations/admin`, data)
  },
  deleteConsumer: id => {
    return axios.delete(`/consumers/${id}/messages/calls`)
  },
  postConsumersCsv: data => {
    const { start, end } = getDatesForDateFilter(
      data['startDate'],
      data['endDate']
    )

    data['startDate'] = start
    data['endDate'] = end

    return axios.post('/consumers/send-csv-data', data)
  },
  postConsumersEmailPromotionCsv: data => {
    const { start, end } = getDatesForDateFilter(
      data['startDate'],
      data['endDate']
    )

    data['startDate'] = start
    data['endDate'] = end

    return axios.post('/consumers/email-promotion/send-csv-data', data)
  },
  postConsumersSmsPromotionCsv: data => {
    const { start, end } = getDatesForDateFilter(
      data['startDate'],
      data['endDate']
    )

    data['startDate'] = start
    data['endDate'] = end

    return axios.post('/consumers/sms-promotion/send-csv-data', data)
  },
  postConsultationsCsv: data => {
    return axios.post('/consumers/consultations/send-csv-data', data)
  },
  sendDiscountMail: (consumerId, salonId) => {
    return axios.get(`/consumers/mails/discount/${consumerId}/${salonId}`)
  },

  sendFollowUpMail: (consumerId, salonId) => {
    return axios.get(`/consumers/mails/follow-up/${consumerId}/${salonId}`)
  },

  getConsumers: (limit, page, search) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/consumers?page=${page}&limit=${limit}&fields=-location,-__v,-active,-productDescription,-productAnnual&${searchText}`
    )
  },

  getConsumersTableData: (limit, page, search, sortBy, sortDesc) => {
    const { start, end } = getDatesForDateFilter(
      search['startDate'],
      search['endDate']
    )
    search['startDate'] = start
    search['endDate'] = end

    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/consumers/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },

  getConsumersEmailPromotionTableData: (
    limit,
    page,
    search,
    sortBy,
    sortDesc
  ) => {
    const { start, end } = getDatesForDateFilter(
      search['startDate'],
      search['endDate']
    )
    search['startDate'] = start
    search['endDate'] = end

    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/consumers/email-promotion/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },

  getConsumersSmsPromotionTableData: (
    limit,
    page,
    search,
    sortBy,
    sortDesc
  ) => {
    const { start, end } = getDatesForDateFilter(
      search['startDate'],
      search['endDate']
    )
    search['startDate'] = start
    search['endDate'] = end

    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/consumers/sms-promotion/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },

  getConsumersByFirstNameLastNameAndSalon: (
    limit,
    page,
    search,
    sortBy,
    sortDesc
  ) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/consumers/salons?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },

  // archive
  getConsultations: () => {
    return axios.get('/consumers/consultations')
  },

  getConsultationsTableData: (limit, page, search, sortBy, sortDesc) => {
    const { startLocal, endLocal, offset } = getDatesForDateFilter(
      search['startDate'],
      search['endDate']
    )

    search['startDate'] = startLocal
    search['endDate'] = endLocal
    search['offset'] = offset

    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/consumers/consultations/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },

  addSalonsByDistance: (id, distance) => {
    return axios.get(`/consumers/salon-near/${id}`, {
      params: {
        distance
      }
    })
  },

  // use for edit and for delete -> active: false
  patchConsumer: consumer => {
    return axios.patch(`/consumers/${consumer.id}`, consumer)
  },

  // note: calls
  // user
  call_c_s: data => {
    return axios.post('/calls/c_s', data)
  },
  call_c_h: data => {
    return axios.post('/calls/c_h', data)
  },
  getCalls_c_s: (salonId, consumerId) => {
    return axios.get(`/calls/c_s/${salonId}/${consumerId}`)
  },
  // admin
  getCalls: () => {
    return axios.get('/calls')
  },
  // use for edit and for delete -> active: false
  patchCall: call => {
    return axios.patch(`/calls/${call.id}`, call)
  },

  // note: messages
  // user
  send_c_s: data => {
    return axios.post('/messages/c_s', data)
  },
  send_c_h: data => {
    return axios.post('/messages/c_h', data)
  },
  send_s_h: data => {
    return axios.post('/messages/s_h', data)
  },
  send_st_h: data => {
    return axios.post('/messages/st_h', data)
  },
  get_c_s: (salonId, consumerId) => {
    return axios.get(`/messages/c_s/${salonId}/${consumerId}`)
  },
  getCount_c_s: (salonId, consumerId) => {
    return axios.get(`/messages/c_s/count/${salonId}/${consumerId}`)
  },
  get_st_h: stylistId => {
    return axios.get(`/messages/st_h/${stylistId}`)
  },
  // admin
  getMessages: () => {
    return axios.get('/messages')
  },
  getConversations: salonId => {
    return axios.get(`/messages/salons/${salonId}`)
  },
  getSalonMessagesUnreadCount: salonId => {
    return axios.get(`/messages/salons/${salonId}/unread-count`)
  },
  postMessagesCsv: data => {
    return axios.post('/messages/send-csv-data', data)
  },
  postMessagesAndCallsCsv: data => {
    return axios.post('/consumers/messages/calls/send-csv-data', data)
  },
  postConsumerMessagesCsv: data => {
    return axios.post('/consumers/messages/send-csv-data', data)
  },
  getMessagesTableData: (limit, page, search, sortBy, sortDesc) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/messages/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },
  getMessagesAndCallsTableData: (limit, page, search, sortBy, sortDesc) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/consumers/messages/calls/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },
  getConsumerMessagesTableData: (limit, page, search, sortBy, sortDesc) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/consumers/messages/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }`
    )
  },
  getMessagesAndCallsCS: ({ page, limit }) => {
    return axios.get(`/messages/calls/c_s?page=${page}&limit=${limit}`)
  },
  getCHMessages: () => {
    return axios.get(`/messages?type=${MessageTypes.C_H}`)
  },
  getSTHMessages: () => {
    return axios.get(`/messages?type=${MessageTypes.ST_H}`)
  },
  // use for edit and for delete -> active: false
  patchMessage: message => {
    return axios.patch(`/messages/${message.id}`, message)
  },

  // note: salons USER
  // memo -> {text: String, consumerId: String}
  patchSalonAdminMessagesMemo: (salonId, memo) => {
    return axios.patch(`/salons/admin-messages-memo/${salonId}`, memo)
  },

  sendCode: email => {
    return axios.get('/salons/send-code', {
      params: {
        email
      }
    })
  },

  validateCode: code => {
    return axios.get('/salons/validate-code/' + code)
  },

  // only upload to get gcloud link, not connected to salon
  uploadImg: form => {
    return axios.post('/salons/image', form)
  },

  // connect to salon
  sendImg: (id, form) => {
    return axios.patch('/salons/image/' + id, form)
  },

  // connect to salon
  sendImgTemp: (id, form) => {
    return axios.patch('/salons/image/temp/' + id, form)
  },

  patchSalonTemp: (id, data, isAdmin = false) => {
    return axios.patch(`/salons/temp/${id}?admin=${isAdmin}`, data)
  },

  confirmSalonEdit: (salon, approveAll) => {
    return axios.patch(`/salons/confirm-edit/${salon.id}`, salon, {
      params: {
        approveAll
      }
    })
  },
  getNearestSalon: (zip, system) => {
    return axios.get(`/salons/salon-near/${zip}`, {
      params: {
        system,
        limit: '1'
      }
    })
  },
  postSalonsCsv: data => {
    return axios.post('/salons/send-csv-data', data)
  },
  getSalonsTableData: (
    limit,
    page,
    search,
    sortBy,
    sortDesc,
    extra_zip,
    extra_system
  ) => {
    let searchText = ''
    for (const key in search) {
      if (search[key])
        searchText = `${searchText}${searchText ? '&' : ''}${key}=${
          search[key]
        }`
    }

    return axios.get(
      `/salons/table?page=${page}&limit=${limit}&sortBy=${sortBy}&sortDesc=${sortDesc}${
        searchText ? '&' + searchText : ''
      }${extra_zip ? '&extra_zip=' + extra_zip : ''}${
        extra_system ? '&extra_system=' + extra_system : ''
      }`
    )
  },

  sendSalonsInviteMails: () => {
    return axios.get('/salons/invite')
  },

  sendSalonsInviteMailsTest: () => {
    return axios.get('/salons/invite/test')
  },

  patchSalonFeedback: (salonId, feedback) => {
    return axios.patch(`/salons/feedback/${salonId}`, { feedback })
  },

  // note: salons ADMIN
  sendVerifySms: id => {
    return axios.get(`/salons/sms/verify/${id}`)
  },
  getSalons: () => {
    return axios.get('/salons')
  },
  getSalonsByNavid: navid => {
    return axios.get(`/salons/navid/${navid}`)
  },
  // archive
  getSalonsByZipAndSystem: (zip, system) => {
    return axios.get(`/salons/salon-near/${zip}`, {
      params: {
        system
      }
    })
  },
  getSalon: id => {
    return axios.get(`/salons/${id}`)
  },
  postSalon: salon => {
    return axios.post(`/salons`, salon)
  },
  postSalonFile: file => {
    let formData = new FormData()
    formData.append('file', file)
    return axios.post(`/salons/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  patchSalon: salon => {
    return axios.patch(`/salons/${salon.id}`, salon)
  },
  sendTermsMail: (id, terms) => {
    return axios.get(`/salons/${id}/terms-mail?terms=${terms}`)
  },
  deleteSalon: id => {
    return axios.delete(`/salons/${id}`)
  },

  // note: auth
  login(user) {
    return axios.post(`/users/login`, user)
  },
  signup(user) {
    return axios.post(`/users/signup`, user)
  },
  signupStylist(user) {
    return axios.post(`/users/signup/stylists`, user)
  },
  forgotPasswordSalon(data) {
    return axios.post(`/users/salons/forgot-password`, data)
  },
  forgotPasswordStylistsAccount(data) {
    return axios.post(`/users/stylists-account/forgot-password`, data)
  },
  validateForgotPassword: code => {
    return axios.get('/users/validate-forgot-password/' + code)
  },
  changePassword: data => {
    return axios.post('/users/change-password', data)
  },
  sendSalonSignupValidationCode: salonId => {
    return axios.get(`/users/send-salon-signup-validation-code/${salonId}`)
  }
}
