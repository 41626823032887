<template>
  <v-toolbar flat>
    <v-row>
      <!-- start date -->
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Start date"
          type="date"
          v-model="startDate"
          hide-details
          :max="limit && getTodayString()"
        />
      </v-col>
      <!-- end date -->
      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="End date"
          type="date"
          v-model="endDate"
          hide-details
          :max="limit && getTodayString()"
        />
      </v-col>

      <v-col v-if="showBtn">
        <v-btn @click="() => $emit('onFilter')">Filter by dates</v-btn></v-col
      >
    </v-row>
  </v-toolbar>
</template>

<script>
  import { getTodayString } from '@/utils/helpers'
  import moment from 'moment'

  export default {
    props: {
      showBtn: {
        type: Boolean,
        default: false
      },
      limit: {
        type: Boolean,
        default: true
      },
      start: {
        type: String,
        default: null
      },
      end: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        getTodayString,
        startDate: this.start,
        endDate: this.end
      }
    },
    methods: {
      emitChange() {
        this.$emit('change', {
          startDate: this.startDate,
          endDate: this.endDate
        })
      }
    },
    watch: {
      startDate(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (moment(this.startDate).isAfter(this.endDate, 'day')) {
            this.endDate = this.startDate
          }
          this.emitChange()
        }
      },
      endDate(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (moment(this.endDate).isBefore(this.startDate, 'day')) {
            this.startDate = this.endDate
          }
          this.emitChange()
        }
      }
    }
  }
</script>
