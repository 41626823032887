<template>
  <v-dialog
    v-model="show"
    max-width="500"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-card-title class="pr-3 pb-0">
        <v-spacer></v-spacer>

        <v-icon @click="handleClose" style="cursor: pointer"
          >mdi-close</v-icon
        ></v-card-title
      >

      <v-card-text class="pt-0 px-2">
        <!-- IMGS -->
        <template v-if="options.popUpImgs">
          <v-card-text v-for="img in options.popUpImgs" :key="img.name">
            <v-img
              contain
              :lazy-src="require(`@/assets/apple-style/${img.name}`)"
              :src="require(`@/assets/apple-style/${img.name}`)"
            />
            <v-card-text
              v-if="img.text"
              v-text="img.text"
              class="text-body-1 pt-1 font-weight-bold text-center"
            ></v-card-text>
            <v-card-text
              v-if="img.html"
              v-html="img.html"
              class="text-body-1 pt-1 font-weight-bold text-center"
            ></v-card-text>
          </v-card-text>
        </template>
        <!-- TXT -->
        <template v-if="options.popUpText">
          <v-card-text
            class="text-h6 font-weight-bold py-0"
            v-if="options.popUpText.title1"
            v-text="options.popUpText.title1"
          />
          <v-card-text
            class="pt-0"
            v-if="options.popUpText.title2"
            v-text="options.popUpText.title2"
          />
          <v-card-text
            class="font-weight-bold py-0"
            v-if="options.popUpText.subtitle1"
            v-text="options.popUpText.subtitle1"
          />
          <v-card-text
            class="font-weight-bold py-0"
            v-if="options.popUpText.subtitle2"
            v-text="options.popUpText.subtitle2"
          />
          <v-card-text
            v-if="options.popUpText.htmlText1"
            v-html="options.popUpText.htmlText1"
          />
          <v-card-text
            class="font-weight-bold pb-0"
            v-if="options.popUpText.subtitle3"
            v-text="options.popUpText.subtitle3"
          />
          <v-card-text
            class="font-weight-bold py-0"
            v-if="options.popUpText.subtitle4"
            v-text="options.popUpText.subtitle4"
          />
          <v-card-text
            v-if="options.popUpText.htmlText2"
            v-html="options.popUpText.htmlText2"
          />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        default: () => ({
          img: '',
          hasAction: false,
          video: '',
          popUpImg: '',
          text: '',
          popUpBtnText: ''
        })
      }
    },
    methods: {
      handleClose() {
        this.$emit('close', false)
        if (this.videoLink) {
          this.$refs.video.src = ''
          this.$refs.video.src = this.video
        }
      },
      handleBtnClick() {
        this.$emit('btn-click')
      }
    }
  }
</script>
