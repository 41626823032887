<template>
  <v-container fluid>
    <v-data-table
      dense
      :headers="headers"
      :items="list"
      item-key="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 ml-3"
      @page-count="pageCount = $event"
      :server-items-length="totalCount"
      :loading="isLoading"
      @update:options="handleUpdateOptions"
    >
      <template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification || errorMessage }}</v-snackbar
        >

        <v-toolbar flat>
          <v-toolbar-title>DISTANCE CHECKER</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="title"
            >Count: {{ $store.state.sourcesalons.totalCount }}</span
          >
          <v-divider class="mx-4" inset vertical></v-divider>

          <!-- reset filters -->
          <v-btn
            color="secondary"
            class="mx-5"
            small
            @click="handleResetFilters"
          >
            <v-icon class="mx-1" small>mdi-reload</v-icon> Reset filters
          </v-btn>

          <v-spacer></v-spacer>

          <!-- export  -->
          <v-btn
            class="mx-5"
            small
            @click="handleExportData"
            :loading="isExportingData"
          >
            <v-icon class="mx-1">mdi-download</v-icon>
            Export CSV
          </v-btn>

          <v-spacer></v-spacer>

          <!-- import -->
          <v-col cols="4" class="d-flex justify-end align-center">
            <v-file-input
              filled
              class="mt-5"
              v-model="importFile"
              accept=".csv"
              dense
              small-chips
            ></v-file-input>
            <v-btn
              :loading="isSending"
              small
              class="ml-3"
              :disabled="!importFile"
              @click="handleImportSourceSalons"
            >
              <v-icon class="mx-1">mdi-upload</v-icon>
              Import Distance</v-btn
            >
          </v-col>
        </v-toolbar>
      </template>

      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="handleFilterChange($event, header)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td></tr
      ></template>

      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | localeDate(true) }}
      </template>
    </v-data-table>
    <!-- NOTE: PAGINATION -->
    <div class="text-center pt-2" v-if="list && list.length > 0">
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
  import FilterField from '@/components/FilterField.vue'
  import { getDistanceChecker } from '@/config/tables.helper'
  import { mapActions } from 'vuex'

  export default {
    components: {
      FilterField
    },
    data() {
      return {
        isSending: false,
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        // pagintaion
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        // sorting
        sortBy: 'createdAt',
        sortDesc: true,
        // other
        isExportingData: false,
        filters: getDistanceChecker(this).filters,
        headers: [],
        notification: '',
        isLoading: false,
        importFile: null
      }
    },
    computed: {
      list() {
        return this.$store.state.sourcesalons.tableList
      },
      totalCount() {
        return this.$store.state.sourcesalons.totalCount
      }
    },
    methods: {
      ...mapActions([
        'fetchSourceSalonsTableData',
        'importSourceSalons',
        'getSourceSalonsCsv'
      ]),

      async handleExportData() {
        this.isExportingData = true
        let transform = {}
        getDistanceChecker(this).headers.forEach(h => {
          if (h.csv !== false) {
            transform[h.text] = h.value
          }
        })
        const data = await this.getSourceSalonsCsv({
          transform
        })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'source_salons.csv')
        this.isExportingData = false
      },

      async handleUpdate() {
        await this.updateData()
      },

      async handleImportSourceSalons() {
        if (!this.importFile) return

        this.isSending = true
        await this.importSourceSalons(this.importFile)
        if (this.$store.state.sourcesalons.error) {
          this.errorMessage = this.$store.state.stylistsAccount.error
          this.snackbarColor = 'red'
          this.snackbar = true
        } else {
          this.snackbarColor = 'green'
          this.errorMessage = 'Salons imported.'
          this.snackbar = true
        }
        this.importFile = null
        this.isSending = false
      },

      async handleUpdateOptions(options) {
        this.sortBy = options.sortBy[0] ? options.sortBy[0] : 'createdAt'
        this.sortDesc = options.sortDesc
        await this.updateData()
      },
      async updateData() {
        this.isLoading = true

        const options = {
          page: this.page,
          limit: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          search: this.filters
        }

        await this.fetchSourceSalonsTableData(options)
        this.pageCount = Math.ceil(
          this.$store.state.sourcesalons.totalCount / this.itemsPerPage
        )

        this.isLoading = false
      },

      async handleFilterChange(value, header) {
        this.page = 1
        this.filters[header.value] = value
        await this.updateData()
      },
      handleResetFilters() {
        this.filters = getDistanceChecker(this).filters

        this.page = 1

        this.updateData()
      }
    },
    watch: {
      dialog(val) {
        val || this.close()
      },
      async page(newValue, oldValue) {
        await this.updateData()
      }
    },
    async created() {
      // redirect to example pages if user is not full (admin with no stylists access)
      if (!this.$store.getters.isUserFull)
        this.$router.push({ name: 'ExamplePages' })

      this.headers = getDistanceChecker(this).headers
    }
  }
</script>
