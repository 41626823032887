<template>
  <div class="box">
    <div
      class="d-flex justify-center align-center"
      v-if="isLoading"
      style="min-height: 600px"
    >
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <div class="pa-8" v-if="!isLoading">
      <v-card-title class="heading"
        >Consumers with No Salon within 100mi: {{ total }}</v-card-title
      >
      <GChart
        :type="type"
        :data="consumersWithoutSalonsByState"
        :options="options"
        :settings="settings"
      />
    </div>
    <div v-if="!isLoading">
      <!-- note: states -->
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header> By States </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">State</th>
                    <th class="text-left">Consumers</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(record, index) in consumersWithoutSalonsByState"
                    :key="record[0]"
                  >
                    <template v-if="index !== 0">
                      <td>{{ record[0] }}</td>
                      <td>{{ record[1] }}</td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- note: counties -->
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header> By Counties </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">County</th>
                    <th class="text-left">Consumers</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(record, index) in consumersWithoutSalonsByCounty"
                    :key="record[0]"
                  >
                    <template v-if="index !== 0">
                      <td>{{ record[0] }}</td>
                      <td>{{ record[1] }}</td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- note: not found zip -->
      <v-expansion-panels>
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header>
            Zip codes not found
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Zip</th>
                    <th class="text-left">not found</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      zipcode, index
                    ) in consumersWithoutSalonsNotFoundState"
                    :key="index"
                  >
                    <template>
                      <td>zip</td>
                      <td>{{ zipcode }}</td>
                    </template>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  export default {
    components: {
      GChart
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true,
        type: 'GeoChart',
        options: {
          region: 'US',
          title: '',
          resolution: 'provinces',
          colorAxis: { colors: ['yellow', 'red'] },
          height: 337,
          legend: { position: 'bottom' }
        },
        settings: {
          packages: ['geochart'],
          mapsApiKey: process.env.VUE_APP_GOOGLE_MAP_KEY
        }
      }
    },
    computed: {
      total() {
        return this.$store.state.analytics.consumersWithoutSalonsByGeo?.total
      },
      consumersWithoutSalonsByState() {
        return this.$store.state.analytics.consumersWithoutSalonsByGeo?.states
      },
      consumersWithoutSalonsByCounty() {
        return this.$store.state.analytics.consumersWithoutSalonsByGeo?.counties
      },
      consumersWithoutSalonsNotFoundState() {
        return this.$store.state.analytics.consumersWithoutSalonsByGeo?.notFound
      }
    },
    methods: {
      ...mapActions(['fetchConsumersWithoutSalonsByGeo'])
    },
    watch: {
      async startDate() {
        this.isLoading = true
        await this.fetchConsumersWithoutSalonsByGeo({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.isLoading = false
      },
      async endDate() {
        this.isLoading = true

        await this.fetchConsumersWithoutSalonsByGeo({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.isLoading = false
      }
    },
    async created() {
      await this.fetchConsumersWithoutSalonsByGeo({
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped>
  .box {
    background: #fff;
  }
  .heading {
    color: black;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;
  }
</style>
