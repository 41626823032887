<template>
  <v-bottom-navigation
    v-model="bottomMenuValue"
    color="primary"
    grow
    class="elevation-0 bottom-menu tabs"
  >
    <v-btn
      v-for="item in items"
      :value="item.value"
      :key="item.title"
      @click="handleBottomMenu(item)"
    >
      <!-- with badge -->
      <v-badge
        v-if="showBadge(item) || showAdminNotificationBadge(item)"
        offset-x="10"
        offset-y="5"
        color="red"
        :content="showAdminNotificationBadge(item) ? 1 : unreadMessages"
      >
        <div class="d-flex flex-column">
          <v-icon>mdi-{{ item.icon }}</v-icon>
          <span>{{ item.title }}</span>
        </div></v-badge
      >
      <!-- without badge -->
      <div v-else class="d-flex flex-column">
        <v-icon>mdi-{{ item.icon }}</v-icon>
        <span>{{ item.title }}</span>
      </div>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  import { mapActions } from 'vuex'
  import { getTermsStatus } from '../../utils/helpers'

  export default {
    data() {
      return {
        bottomMenuValue: 'SalonHome',
        items: [
          {
            value: 'SalonHome',
            title: 'Home',
            icon: 'home',
            protected: false,
            link: { name: 'SalonHomeLoggedIn' },
            notification: true
          },
          {
            value: 'SalonMessages',
            title: 'Messages',
            icon: 'message',
            protected: true,
            link: { name: 'SalonMessages' },
            badge: true
          },
          {
            value: 'SalonPage',
            title: 'My Page',
            icon: 'account',
            protected: true,
            link: { name: 'SalonPage' }
          }
        ]
      }
    },
    computed: {
      routeName() {
        return this.$route.name
      },
      salonId() {
        return this.$store.state.auth.user.salon
      },
      salon() {
        return this.$store.state.salons.selectedSalon
      },
      unreadMessages() {
        return this.$store.state.messages.salonMessagesUnreadCount
      }
    },
    methods: {
      ...mapActions(['fetchSalonMessagesUnreadCount', 'editSettings']),

      showBadge(item) {
        if (item.badge && this.unreadMessages > 0) return true
        else return false
      },
      showAdminNotificationBadge(item) {
        // 0. has salon id
        const hasSalonId = !!this.salonId

        // 1. terms accepted
        const termsAccepted =
          this.$store.getters['lastAgreement']?.termsDate ==
            this.salon?.terms?.date &&
          getTermsStatus(this.salon?.terms) === 'accepted'

        // 2. no salon id in settings ids &&
        const ids =
          this.$store.getters.salonHomePageSettings?.settings?.ids || []
        const noSalonIdInsideAdminSettings = !ids.includes(this.salonId)

        // 3. hidePromo is false &&
        const showPromo =
          !this.$store.getters.salonHomePageSettings?.settings?.hidePromo
        // 4. this item has notification true
        const hasNotification = item.notification

        return (
          hasSalonId &&
          termsAccepted &&
          noSalonIdInsideAdminSettings &&
          showPromo &&
          hasNotification
        )
      },
      async handleBottomMenu(item) {
        // ako je home i treba da se prikaze badge onda na home page dodaj salon id i na taj nacin skloni badge
        if (item.title === 'Home' && this.showAdminNotificationBadge(item)) {
          await this.editSettings({
            id: this.$store.getters.salonHomePageSettings?._id,
            settings: {
              ...this.$store.getters.salonHomePageSettings?.settings,
              ids: [
                ...(this.$store.getters.salonHomePageSettings?.settings?.ids ||
                  []),
                this.salonId
              ]
            }
          })
        }
        // ako isti name nemoj nista da radis
        if (this.$route.name !== item.link.name) {
          // 1. protected
          if (item.protected) {
            // 1.1 protected logged in
            if (this.$store.getters.salonLoggedIn) {
              this.$router.push({
                name: item.link.name,
                params: { salonId: this.salonId },
                query: {
                  from: this.$route.fullPath
                }
              })
            }
            // 1.2 protected not logged in -> to find salon
            else {
              this.$router.push({
                name: 'FindSalon',
                query: {
                  from: this.$route.name,
                  email: this.$route.query.email
                }
              })
            }
          }
          // 2. not protected
          else {
            this.$router.push(item.link)
          }
        }
      }
    },
    watch: {
      routeName(newValue, oldValue) {
        this.bottomMenuValue = newValue
        if (this.salonId) {
          this.fetchSalonMessagesUnreadCount(this.salonId)
        }
      }
    },
    created() {
      this.bottomMenuValue = this.$route.name
      if (this.salonId) {
        this.fetchSalonMessagesUnreadCount(this.salonId)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .tabs {
    bottom: 0;
    position: sticky;
    z-index: 100;
  }
  .bottom-menu {
    margin-top: auto;
    background-color: #fafafa;
  }
</style>
