<template>
  <div>
    <div
      class="d-flex justify-center align-center"
      v-if="isLoading"
      style="min-height: 600px"
    >
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <GChart :type="type" :data="chartData" :options="options"
    /></template>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  export default {
    components: {
      GChart
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true,
        type: 'PieChart',
        options: {
          colors: ['orange', 'blue', 'pink', '#808080'],
          title: '',
          height: 600,
          animation: { startup: true, duration: 500 },
          legend: { position: 'bottom' }
        }
      }
    },
    computed: {
      consumersWithoutSalons() {
        return this.$store.state.analytics.consumersWithoutSalons
      },
      total() {
        return this.$store.state.analytics.consumersWithoutSalons?.total || '/'
      },
      chartData() {
        return [
          ['Discount used', 'Count'],
          [
            'No salon in 25 mi',
            this.consumersWithoutSalons?.['No salon in 25 mi']
          ],
          [
            'No salon in 50 mi',
            this.consumersWithoutSalons?.['No salon in 50 mi']
          ],
          [
            'No salon in 75 mi',
            this.consumersWithoutSalons?.['No salon in 75 mi']
          ],
          [
            'No salon in 100 mi',
            this.consumersWithoutSalons?.['No salon in 100 mi']
          ]
        ]
      }
    },
    methods: {
      ...mapActions(['fetchConsumersWithoutSalons'])
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true
        await this.fetchConsumersWithoutSalons({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.options.title = `Consumers without a salon: ${this.total}`
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true
        await this.fetchConsumersWithoutSalons({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.options.title = `Consumers without a salon: ${this.total}`
        this.isLoading = false
      }
    },
    async created() {
      await this.fetchConsumersWithoutSalons({
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.options.title = `Consumers without a salon: ${this.total}`
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped></style>
