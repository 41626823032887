<template>
  <v-container justify="center" v-if="consumer">
    <v-dialog v-model="showDialog" max-width="500" @click:outside="onClose">
      <v-card>
        <!-- note: navigation -->
        <v-card-actions class="navigation"
          ><v-btn
            class="navigation-btn"
            text
            @click="handleBack"
            v-if="form[currentStep].back"
            ><v-icon x-small>mdi-arrow-left</v-icon> Back</v-btn
          ><v-spacer></v-spacer>
          <!-- <v-btn
            @click="handleNext"
            class="navigation-btn"
            text
            v-if="form[currentStep].next"
            >Next <v-icon x-small>mdi-arrow-right</v-icon></v-btn
          > -->
        </v-card-actions>
        <v-card-text class="px-2 pb-0">
          <v-slide-x-transition v-if="isBack" hide-on-leave>
            <component
              @onValid="handleValid"
              :sendingData="sendingData"
              @onDataChange="handleDataChange"
              :is="currentComponent"
            ></component>
          </v-slide-x-transition>
          <v-slide-x-reverse-transition v-else hide-on-leave>
            <component
              @onValid="handleValid"
              :sendingData="sendingData"
              @onDataChange="handleDataChange"
              :is="currentComponent"
            ></component>
          </v-slide-x-reverse-transition>
        </v-card-text>
        <v-card-actions v-if="form[currentStep].next" class="pb-5 pr-10">
          <v-spacer></v-spacer>
          <v-btn
            @click="handleNext"
            class="white--text"
            color="user_primary"
            :disabled="!form[currentStep].isValid"
          >
            Next
          </v-btn>
        </v-card-actions>
        <v-card-actions
          v-if="form[currentStep].sendData"
          class="pb-6 px-4 px-sm-10"
          ><v-btn
            block
            @click="handleSend"
            :loading="isSending"
            class="white--text"
            color="user_primary"
            :disabled="!form[currentStep].isValid"
          >
            {{ form[currentStep].sendDataText }}
          </v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
    <SimpleModal
      v-if="responseDialog"
      :showDialog="responseDialog"
      title="Success!"
      :message="responseMessage"
      @closeSimpleModal="closeResponseDialog"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import Step1 from '@/views/user/WF2/messages/Step1.vue'
  import Step2 from '@/views/user/WF2/messages/Step2.vue'
  import Step3 from '@/views/user/WF2/messages/Step3.vue'

  export default {
    props: {
      system: String,
      showDialog: Boolean,
      salonName: String
    },
    data() {
      return {
        currentStep: 1,
        isBack: false,
        isValid: false,
        isSending: false,
        responseDialog: false,
        responseMessage: '',
        sendingData: {},
        form: {
          1: {
            id: 1,
            back: false,
            next: false,
            sendData: false,
            sendDataText: 'Send',
            component: 'Step1',
            isValid: false
          },
          2: {
            id: 2,
            back: true,
            next: false,
            component: 'Step2',
            isValid: false
          },
          3: {
            id: 3,
            back: true,
            next: false,
            sendData: true,
            sendDataText: 'Confirm & Send',
            component: 'Step3',
            isValid: false
          }
        }
      }
    },
    computed: {
      consumer() {
        return this.$store.state.consumers.consumer
      },
      currentComponent() {
        return this.form[this.currentStep].component
      },
      hasValidatedSMSPhone() {
        return this.$store.state.consumers.consumer.smsPhone.validated
      }
    },
    methods: {
      ...mapActions(['send_c_s', 'sendVerifyConsumerSms']),
      onClose() {
        this.$emit('close', false)
      },
      closeResponseDialog() {
        this.responseDialog = false
        this.onClose()
      },
      handleBack() {
        if (this.currentStep > 1) {
          this.isBack = true
          this.currentStep = this.currentStep - 1
        }
      },
      handleNext() {
        if (this.currentStep < 3) {
          this.isBack = false
          this.currentStep = this.currentStep + 1
        }
      },
      handleValid(payload) {
        const { step, isValid } = payload
        this.form[step].isValid = isValid
      },
      handleDataChange(payload) {
        const { step, data, goNext } = payload
        if (step === 3) {
          this.sendingData[1][data.key] = data.value
        } else {
          this.sendingData[step] = data
        }
        if (goNext) this.handleNext()
      },
      async handleSend() {
        this.isSending = true
        const message = this.sendingData[1]
        message.consumer = this.$route.params.consumerId
        message.salon = this.$route.params.salonId
        // send verify sms and create message
        if (this.sendingData[2] === 'emailAndText') {
          // note: bitan je redosled
          // - prvo se posalje sms jer se on salje samo ako smsPhone nije validated
          // - a onda send_c_s gde se validira sms
          await this.sendVerifyConsumerSms(message.smsPhone.formattedNumber)
          await this.send_c_s(message)
        }
        // create message
        else {
          await this.send_c_s(message)
        }
        this.isSending = false
        if (!this.$store.state.messages.error) {
          // send sms if not validated
          //   this.responseMessage = `You will receive an email when ${this.salonName} replies, which is typically within 24 hours`
          //   this.responseDialog = true
          //   // this.$router.push({ name: 'ExamplePages' })
          this.sendingData = {}
          this.$router.push({
            name: 'Communication',
            params: {
              consumerId: message.consumer,
              salonId: message.salon
            }
          })
        }
      }
    },
    created() {
      if (this.hasValidatedSMSPhone) this.form[1].sendData = true
      else this.form[1].next = true
    },
    components: {
      Step1,
      Step2,
      Step3
    }
  }
</script>

<style lang="scss" scoped>
  .navigation-btn {
    padding: 0px;
    font-size: 12px !important;
  }
</style>
