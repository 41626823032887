<template>
  <v-app>
    <v-app-bar
      color="#f7f7f7s"
      app
      class="d-flex justify-center"
      elevation="0"
      width="100%"
    >
      <!-- note: salon menu btn -->
      <v-btn
        depressed
        class="profile-btn"
        rounded
        v-if="areSalonPages"
        @click.stop="handleProfileAccount"
        ><v-icon>mdi-menu</v-icon></v-btn
      >
      <!-- note: stylist user btn -->
      <v-btn
        depressed
        class="profile-btn"
        :class="$vuetify.breakpoint.xs ? 'pl-0' : 'pl-3'"
        rounded
        v-if="$store.getters.stylistLoggedIn"
        @click.stop="handleStylistBtn"
        ><v-icon>mdi-account</v-icon></v-btn
      >
      <v-img
        :style="{
          cursor: hidePointerOnLogo ? 'auto' : 'pointer'
        }"
        @click.stop="handleToolbarLogoClick"
        src="@/assets/logo-lg.png"
        lazy-src="@/assets/logo-lg.png"
        alt="hairdreams salon finder logo"
        contain
        max-height="100%"
      ></v-img>
    </v-app-bar>

    <v-main>
      <v-container fluid class="mt-0 mt-sm-5 px-0 px-sm-3 pt-0 pl-0">
        <v-sheet color="white" class="pa-3" v-if="showSkeleton">
          <v-skeleton-loader
            class="mx-auto"
            :max-width="skeletonWidth"
            type="card, actions, article, card-avatar"
          ></v-skeleton-loader>
        </v-sheet>
        <v-alert
          v-if="errorMessage"
          :max-width="skeletonWidth"
          class="mx-auto text-center"
          dense
          color="red lighten-2"
          dark
        >
          {{ errorMessage }}
        </v-alert>

        <transition name="fade" mode="out-in">
          <router-view class="main-container" />
        </transition>
      </v-container>
    </v-main>
    <ConsumerFooter />
    <ProfileModal
      :user="salonUser"
      v-if="showUserProfile"
      :showDialog="showUserProfile"
      @close="() => (showUserProfile = false)"
    />
    <StylistProfileModal
      :user="stylistUser"
      v-if="showStylistProfile"
      :showDialog="showStylistProfile"
      @close="() => (showStylistProfile = false)"
    />
  </v-app>
</template>

<script>
  import ConsumerFooter from '@/components/user/ConsumerFooter.vue'
  import ProfileModal from '@/views/user/auth/ProfileModal.vue'
  import StylistProfileModal from '@/views/user/auth/StylistProfileModal.vue'
  export default {
    components: { ConsumerFooter, ProfileModal, StylistProfileModal },
    data() {
      return {
        skeletonWidth: 550,
        showUserProfile: false,
        showStylistProfile: false
      }
    },
    computed: {
      salonUser() {
        if (this.$store.state.auth.user.salon)
          return this.$store.state.auth.user
        else return null
      },
      stylistUser() {
        if (this.$store.state.auth.user.stylistId)
          return this.$store.state.auth.user
        else return null
      },
      salonId() {
        return this.$route.params.salonId || this.salonUser.salon
      },
      isWF3() {
        return this.$route.name === 'NewInquiry'
      },
      isWF5() {
        return this.$route.name === 'EditSalon'
      },
      isSalonHome() {
        return this.$route.name === 'SalonHome'
      },
      isSalonMessages() {
        return this.$route.name === 'SalonMessages'
      },
      isSalonPage() {
        return this.$route.name === 'SalonPage'
      },
      areSalonPages() {
        return (
          (this.isWF3 ||
            this.isWF5 ||
            this.isSalonHome ||
            this.isSalonMessages ||
            this.isSalonPage) &&
          this.$store.getters.salonLoggedIn
        )
      },
      hidePointerOnLogo() {
        return (
          !this.$store.getters.adminLoggedIn &&
          (this.$route.name === 'GreatNews' ||
            this.$route.name === 'FindSalon' ||
            this.$route.name === 'ValidateEmail' ||
            this.$route.name === 'EditSalonTerms' ||
            this.$route.name === 'ThankYouPage' ||
            this.isSalonMessages)
        )
      },
      showSkeleton() {
        return (
          (this.$store.state.salons.loading ||
            this.$store.state.consumers.loading ||
            this.$store.state.messages.loading ||
            this.$store.state.stylists.loading ||
            this.$store.state.settings.loading ||
            this.$store.state.setups.loading) &&
          !this.isSalonPage &&
          !this.isSalonMessages
        )
      },
      errorMessage() {
        if (this.$store.state.salons.error) {
          return this.$store.state.salons.error
        } else if (this.$store.state.messages.error) {
          return this.$store.state.messages.error
        } else if (this.$store.state.stylists.error) {
          return this.$store.state.stylists.error
        } else if (this.$store.state.consumers.error) {
          return this.$store.state.consumers.error
        } else {
          return ''
        }
      }
    },
    methods: {
      handleProfileAccount() {
        this.showUserProfile = true
      },
      handleStylistProfileAccount() {
        this.showStylistProfile = true
      },
      handleStylistBtn() {
        this.showStylistProfile = true
      },
      handleToolbarLogoClick() {
        if (this.areSalonPages && !this.isSalonMessages) {
          this.$router.push({
            name: 'SalonMessages',
            params: { salonId: this.salonId },
            query: { from: this.$route.fullPath }
          })
        } else if (this.areSalonPages && this.isSalonMessages) {
          // stay on this page
          return
        } else if (
          this.$store.getters.adminLoggedIn &&
          this.$route.name !== 'ExamplePages'
        ) {
          this.$router.push({ name: 'ExamplePages' })
        } else {
          // unclickable for consumers
          return
          window.location.href = 'https://iwanthairdreams.com/'
        }
      }
    }
  }
</script>

<style scoped>
  .logo-col {
    margin-left: 88px;
  }
  .profile-btn {
    position: absolute;
    right: 160px;
    top: 11px;
    z-index: 10000000;
  }
</style>
