<template>
  <v-text-field
    class="my-2"
    v-if="type === 'text'"
    clearable
    dense
    outlined
    hide-details
    @input="debounceInput"
    :value="value"
    type="text"
    label="Search"
  />
  <v-select
    class="my-2"
    v-else
    clearable
    dense
    outlined
    hide-details
    @input="debounceInput"
    :value="value"
    label="Search"
    :items="options"
  />
</template>

<script>
  import _ from 'lodash'

  export default {
    props: {
      value: {
        type: String,
        default: ''
      },
      options: {
        type: Array
      },
      type: {
        type: String,
        default: 'text'
      }
    },
    data() {
      return {
        search: ''
      }
    },
    methods: {
      debounceInput: _.debounce(function (value) {
        this.$emit('change', value)
      }, 500)
    }
  }
</script>

<style scoped></style>
