<template>
  <v-dialog
    v-model="show"
    max-width="400"
    @click:outside="close"
    @keydown.esc="() => $emit('close')"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title class="text-uppercase">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="mt-3">
        <v-row>
          <v-col cols="12">
            <v-text-field
              hide-details
              type="email"
              label="Email"
              v-model="email"
            ></v-text-field
          ></v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          @click="handleSubmit"
          :disabled="!isValid"
          :loading="isSending"
        >
          Submit
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      show: {
        type: Boolean,
        required: false
      },
      title: {
        type: String,
        required: true
      },
      requestType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isSending: false,
        email: ''
      }
    },
    computed: {
      isValid() {
        return this.email
      }
    },
    methods: {
      ...mapActions(['sendCCPA']),
      close() {
        this.$emit('close')
      },
      async handleSubmit() {
        this.isSending = true
        await this.sendCCPA({
          info: { email: this.email },
          requestType: this.requestType
        })
        this.isSending = false
        this.$emit('close', 'submit')
      }
    }
  }
</script>
