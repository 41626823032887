import { render, staticRenderFns } from "./FsOnlineView.vue?vue&type=template&id=536d5385&scoped=true&"
import script from "./FsOnlineView.vue?vue&type=script&lang=js&"
export * from "./FsOnlineView.vue?vue&type=script&lang=js&"
import style0 from "./FsOnlineView.vue?vue&type=style&index=0&id=536d5385&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536d5385",
  null
  
)

export default component.exports