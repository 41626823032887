import api from '@/services/api.service'
import { SettingsType } from '../../config/enums'

// state
export const state = { list: [], loading: false, error: '' }

// mutations
export const mutations = {
  settingsFetched: (state, settings) => {
    state.list = settings
  },
  settingsEdited: (state, editedSettings) => {
    state.list = state.list.map(settings => {
      return settings._id === editedSettings._id
        ? { ...editedSettings }
        : { ...settings }
    })
  },
  loadingSettingsSet: state => (state.loading = true),
  loadingSettingsCleared: state => (state.loading = false),
  errorSettingsSet: (state, error) => (state.error = error),
  errorSettingsCleared: state => (state.error = '')
}
// getters
export const getters = {
  certificateSettings: state =>
    state.list.find(s => s.settingsType === SettingsType.CERTIFICATES),
  certificateSettingsCanada: state =>
    state.list.find(s => s.settingsType === SettingsType.CERTIFICATES_CANADA),
  salonHomePageSettings: state =>
    state.list.find(s => s.settingsType === SettingsType.SALON_HOME_PAGE)
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorSettingsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingSettingsCleared')
  setTimeout(() => {
    commit('errorSettingsCleared')
  }, 10000)
}

// actions
export const actions = {
  fetchSettings: async ({ commit }) => {
    commit('loadingSettingsSet')
    try {
      const response = await api.getSettings()
      commit('settingsFetched', response.data.data)
      commit('loadingSettingsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  editSettings: async ({ commit }, settings) => {
    // commit('loadingSettingsSet')

    try {
      const response = await api.patchSettings(settings)
      const data = response.data

      commit('settingsEdited', data.data)
      commit('loadingSettingsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
