import api from '@/services/api.service'

// state
export const state = {
  trafficConsumersSources: [],
  trafficTopConsumersSources: [],
  trafficTopConsumersIndividualSources: null,
  trafficTopStylistsSources: [],
  trafficTopStylistsIndividualSources: null,
  salonsActive: [],
  salonsSmsPhones: null,
  salonsAnalyticsAdvanced: null,
  subtotalCount: null,
  consumersNumbers: null,
  consumersByZip: null,
  consumersSystems: [],
  consumersWithoutSalons: null,
  consumersWithoutSalonsByGeo: null,
  consumersContactedSalons: null,
  consumersMessagesAndCalls: null,
  consumersSmsPhones: null,
  consumersBySystemPie: null,
  consumersLogsBySystem: null,
  consultationsNumbers: null,
  consultationsByCompletedAndBoooked: null,
  consultationsSystems: null,
  consultationsDiscountUsed: null,
  consultationsSystemsSubtotal: null,
  consultationsApplicationYesBySystem: null,
  advancedConsumersContacted: null,
  consultationsSubtotalSum: null,
  consultationsSubtotalAvg: null,
  loading: false,
  error: ''
}

// mutations
export const mutations = {
  trafficConsumersSourcesFetched: (state, data) => {
    state.trafficConsumersSources = data
  },
  trafficTopConsumersSourcesFetched: (state, data) => {
    state.trafficTopConsumersSources = data
  },
  trafficTopConsumersIndividualSourcesFetched: (state, data) => {
    state.trafficTopConsumersIndividualSources = data
  },
  trafficTopStylistsSourcesFetched: (state, data) => {
    state.trafficTopStylistsSources = data
  },
  trafficTopStylistsIndividualSourcesFetched: (state, data) => {
    state.trafficTopStylistsIndividualSources = data
  },
  salonsActiveFetched: (state, data) => {
    state.salonsActive = data
  },
  salonsSmsPhonesFetched: (state, data) => {
    state.salonsSmsPhones = data
  },
  salonsAnalyticsAdvancedFetched: (state, data) => {
    state.salonsAnalyticsAdvanced = data
  },
  subtotalCountFetched: (state, data) => {
    state.subtotalCount = data
  },
  salonsAnalyticsSubtotalsFetched: (state, data) => {
    let consultationsSubtotalSum = 0
    let hasSubtotal = 0
    data.forEach(item => {
      consultationsSubtotalSum += item.orders ? item.orders : 0
      hasSubtotal += item.orders > 0 ? 1 : 0
    })
    state.consultationsSubtotalSum = consultationsSubtotalSum
    state.consultationsSubtotalAvg = consultationsSubtotalSum / hasSubtotal
  },
  consumersNumbersFetched: (state, data) => {
    state.consumersNumbers = data
  },
  consumersSystemsFetched: (state, data) => {
    state.consumersSystems = data
  },
  consumersByZipFetched: (state, data) => {
    state.consumersByZip = data
  },
  consumersWithoutSalonsFetched: (state, data) => {
    state.consumersWithoutSalons = data
  },
  consumersWithoutSalonsByGeoFetched: (state, data) => {
    state.consumersWithoutSalonsByGeo = data
  },
  consumersContactedSalonsFetched: (state, data) => {
    state.consumersContactedSalons = data
  },
  consumersMessagesAndCallsFetched: (state, data) => {
    state.consumersMessagesAndCalls = data
  },
  consumersSmsPhonesFetched: (state, data) => {
    state.consumersSmsPhones = data
  },
  consumersBySystemPieFetched: (state, data) => {
    state.consumersBySystemPie = data
  },
  consumersLogsBySystemFetched: (state, data) => {
    state.consumersLogsBySystem = data
  },
  consultationsNumbersFetched: (state, data) => {
    state.consultationsNumbers = data
  },
  consultationsByCompletedAndBoookedFetched: (state, data) => {
    state.consultationsByCompletedAndBoooked = data
  },
  consultationsSystemsFetched: (state, data) => {
    state.consultationsSystems = data
  },
  consultationsDiscountUsedFetched: (state, data) => {
    state.consultationsDiscountUsed = data
  },
  consultationsSystemsSubtotalFetched: (state, data) => {
    state.consultationsSystemsSubtotal = data
  },
  consultationsApplicationYesBySystemFetched: (state, data) => {
    state.consultationsApplicationYesBySystem = data
  },
  advancedConsumersContactedFetched: (state, data) => {
    let count = 0
    data.forEach(item => {
      count += item.consumersContacted ? item.consumersContacted : 0
    })
    state.advancedConsumersContacted = count
  },
  loadingAnalyticsSet: state => (state.loading = true),
  loadingAnalyticsCleared: state => (state.loading = false),
  errorAnalyticsSet: (state, error) => (state.error = error),
  errorAnalyticsCleared: state => (state.error = '')
}

// getters
export const getters = {}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorAnalyticsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingAnalyticsCleared')
  setTimeout(() => {
    commit('errorAnalyticsCleared')
  }, 10000)
}

// actions
export const actions = {
  fetchTrafficConsumersSources: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getTrafficConsumersSources(startDate, endDate)
      commit('trafficConsumersSourcesFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchTrafficTopConsumersSources: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getTrafficTopConsumersSources(
        startDate,
        endDate
      )
      commit('trafficTopConsumersSourcesFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchTrafficTopConsumersIndividualSources: async ({ commit }, payload) => {
    const { startDate, endDate, source } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getTrafficTopConsumersIndividualSources(
        startDate,
        endDate,
        source
      )
      commit('trafficTopConsumersIndividualSourcesFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchTrafficTopStylistsSources: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getTrafficTopStylistsSources(
        startDate,
        endDate
      )
      commit('trafficTopStylistsSourcesFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchTrafficTopStylistsIndividualSources: async ({ commit }, payload) => {
    const { startDate, endDate, source } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getTrafficTopStylistsIndividualSources(
        startDate,
        endDate,
        source
      )
      commit('trafficTopStylistsIndividualSourcesFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchSalonsActive: async ({ commit }) => {
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getSalonsActive()
      commit('salonsActiveFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchSalonsSmsPhones: async ({ commit }) => {
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getSalonsSmsPhones()
      commit('salonsSmsPhonesFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchSalonsAnalyticsAdvanced: async ({ commit }, payload) => {
    const { startDate, endDate, system } = payload
    commit('loadingAnalyticsSet')
    try {
      const responses = await Promise.all([
        api.getSalonsAdvancedAnalyticsReferrals(startDate, endDate, system),
        // api.getSalonsAdvancedAnalyticsMessages(startDate, endDate, system),
        // api.getSalonsAdvancedAnalyticsCalls(startDate, endDate, system),
        api.getSalonsAdvancedAnalyticsConsultations(startDate, endDate, system),
        api.getSalonsAdvancedAnalyticsApplications(startDate, endDate, system),
        api.getSalonsAdvancedAnalyticsOrders(startDate, endDate, system),
        api.getSalonsAdvancedConsumersContacted(startDate, endDate, system)
      ])

      const mergedData = Object.values(
        responses.reduce((acc, response) => {
          response.data.data.forEach(item => {
            if (!acc[item.salonId]) {
              acc[item.salonId] = { salonId: item.salonId }
            }
            Object.assign(acc[item.salonId], item)
          })
          return acc
        }, {})
      )

      commit('salonsAnalyticsAdvancedFetched', mergedData)
      commit('salonsAnalyticsSubtotalsFetched', mergedData)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // note: number of consultations with subtotal > 0
  fetchSubotalsCount: async ({ commit }, payload) => {
    const { startDate, endDate, system } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getSalonsAdvancedSubtotalCount(
        startDate,
        endDate,
        system
      )
      commit('subtotalCountFetched', response.data.data.subtotalCount)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersNumbers: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersNumbers(startDate, endDate)
      commit('consumersNumbersFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersByZip: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersByZip(startDate, endDate)
      commit('consumersByZipFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersSystems: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersBySystem(startDate, endDate)
      commit('consumersSystemsFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersWithoutSalons: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersWithoutSalons(startDate, endDate)
      commit('consumersWithoutSalonsFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersWithoutSalonsByGeo: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersWithoutSalonsByGeo(
        startDate,
        endDate
      )
      commit('consumersWithoutSalonsByGeoFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersContactedSalons: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersContactedSalons(startDate, endDate)
      commit('consumersContactedSalonsFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersMessagesAndCalls: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersMessagesAndCalls(
        startDate,
        endDate
      )
      commit('consumersMessagesAndCallsFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersSmsPhones: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersSmsPhones(startDate, endDate)
      commit('consumersSmsPhonesFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersBySystemPie: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersBySystemPie(startDate, endDate)
      commit('consumersBySystemPieFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersLogsBySystem: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsumersLogsBySystem(startDate, endDate)
      commit('consumersLogsBySystemFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsultationsNumbers: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsultationsNumbers(startDate, endDate)
      commit('consultationsNumbersFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsultationsByCompletedAndBoooked: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsultationsByCompletedAndBoooked(
        startDate,
        endDate
      )
      commit('consultationsByCompletedAndBoookedFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsultationsSystems: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsultationsSystems(startDate, endDate)
      commit('consultationsSystemsFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsultationsDiscountUsed: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsultationsDiscountUsed(
        startDate,
        endDate
      )
      commit('consultationsDiscountUsedFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsultationsSystemsSubtotal: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsultationsSystemsSubtotal(
        startDate,
        endDate
      )
      commit('consultationsSystemsSubtotalFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsultationsApplicationYesBySystem: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getConsultationsApplicationYesBySystem(
        startDate,
        endDate
      )
      commit('consultationsApplicationYesBySystemFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchAdvancedConsumersContacted: async ({ commit }, payload) => {
    const { startDate, endDate } = payload
    commit('loadingAnalyticsSet')
    try {
      const response = await api.getSalonsAdvancedConsumersContacted(
        startDate,
        endDate
      )
      commit('advancedConsumersContactedFetched', response.data.data)
      commit('loadingAnalyticsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
