<template>
  <div v-if="getHours(hours).filter(h => h.time !== 'Closed').length > 0">
    <v-card-title class="pl-3 pt-0">Business Hours</v-card-title>
    <v-card elevation="0" class="mx-1 mb-5 pb-3">
      <v-card-text text-subtitle-1>
        <v-row
          v-for="dayObj in getHours(hours)"
          :key="dayObj.day"
          class="pb-0 justify-end"
          :class="dayObj.time === 'Closed' ? 'red--text' : ''"
        >
          <v-col class="font-weight-bold pb-0">{{ dayObj.day }}</v-col>

          <template v-if="dayObj.time === 'Closed'"
            ><div>
              <v-col class="pb-0">{{ dayObj.time }}</v-col>
            </div></template
          >
          <template v-else>
            <div v-for="time in dayObj.time" :key="time">
              <v-col class="pb-0">{{ time }}</v-col>
            </div></template
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { getHours } from '@/utils/helpers'
  export default {
    props: {
      hours: { type: [Object, String], default: '' }
    },
    data() {
      return {
        getHours
      }
    }
  }
</script>
