<template>
  <v-container class="px-0 px-sm-3 pt-0">
    <v-card class="elevation-12 mx-auto pa-0 pb-4 pt-1" max-width="480">
      <!-- note: navigation -->
      <v-card-actions class="navigation mt-1"
        ><v-btn
          text
          v-if="form[currentScreen].back"
          @click="handleBack"
          class="navigation-btn"
          ><v-icon x-small>mdi-arrow-left</v-icon> Back</v-btn
        ><v-spacer></v-spacer
        ><v-btn
          class="navigation-btn"
          :text="!!currentAnswer && currentAnswer.length > 0 ? false : true"
          :color="
            !!currentAnswer && currentAnswer.length > 0 ? '#1976d2' : 'grey'
          "
          :dark="!!currentAnswer && currentAnswer.length > 0 ? true : false"
          :small="!!currentAnswer && currentAnswer.length > 0 ? true : false"
          elevation="0"
          :class="
            !!currentAnswer && currentAnswer.length > 0
              ? 'font-weight-black'
              : ''
          "
          v-if="form[currentScreen].next"
          @click="handleNext"
          :disabled="!currentAnswer || currentAnswer.length === 0"
          >Next <v-icon x-small>mdi-arrow-right</v-icon></v-btn
        ></v-card-actions
      >
      <!-- note: main container -->
      <v-card-text
        :class="form[currentScreen].noPadding ? 'pa-0' : ''"
        class="py-0"
      >
        <v-slide-x-transition v-if="isBack" hide-on-leave>
          <router-view @select="handleSelect"
        /></v-slide-x-transition>
        <v-slide-x-reverse-transition v-else hide-on-leave>
          <router-view @select="handleSelect"
        /></v-slide-x-reverse-transition>
      </v-card-text> </v-card
  ></v-container>
</template>

<script>
  export default {
    data() {
      return {
        isBack: false,
        otherWFLink: null,
        externalLink: null,
        clearUrl: false,
        currentAnswer: '',
        backScreens: [],
        // backScreen: '',
        currentScreen: 'userType',
        nextScreen: '',
        query: {},
        form: {
          userType: {
            id: 1,
            noPadding: true,
            back: false,
            next: false
          },
          pro: { id: 2, back: true, next: true },
          // q4
          systemInfo: { id: 3, back: true, next: true },
          info: { id: 4, back: true, next: true },
          // q1M
          moreHair: { id: 5, back: true, next: true },
          // q2
          hairLongWant: {
            id: 6,
            back: true,
            next: true
          },
          microlinesPrice: {
            id: 15,
            back: true,
            next: true
          },
          consumerInfo: {
            id: 7,
            back: true,
            next: false
          },
          extensions: {
            id: 8,
            back: true,
            next: true
          },
          consumerPick: {
            id: 9,
            back: true,
            next: true
          },
          // q1
          hairLongIs: {
            id: 10,
            back: true,
            next: true
          },
          // q3
          extraCoverage: {
            id: 11,
            back: true,
            next: true
          },
          // no -> system = TAPE
          wearExtensionsBefore: {
            id: 12,
            back: true,
            next: true
          },
          consumerPickOther: {
            id: 13,
            back: true,
            next: true
          },
          lifestyleExtensions: {
            id: 14,
            back: true,
            next: true
          }
        }
      }
    },
    methods: {
      handleSelect(payload) {
        // if (process.env.NODE_ENV === 'development') console.log(payload)
        const {
          otherWFLink,
          externalLink,
          screen,
          nextScreen,
          question,
          answer,
          systemInfo,
          question4,
          systemPickedBy,
          goNext,
          clearUrl,
          playVideo,
          wantAgain
        } = payload

        this.clearUrl = clearUrl || false
        this.externalLink = externalLink || null
        this.otherWFLink = otherWFLink || null

        this.currentScreen = screen
        // this.form[this.currentScreen].backScreen = backScreen
        this.nextScreen = nextScreen
        this.form[this.currentScreen].answer = answer
        this.currentAnswer = answer
        if (question) this.query[question] = answer
        if (question4) this.query['question4'] = question4
        if (systemInfo) this.query['systemInfo'] = systemInfo
        if (playVideo) this.query['playVideo'] = true
        if (systemPickedBy) this.query['systemPickedBy'] = systemPickedBy
        if (wantAgain) this.query['wantAgain'] = wantAgain
        if (goNext) this.handleNext()
      },
      handleBack() {
        this.isBack = true
        // 1. current postaje next
        this.nextScreen = this.currentScreen
        // 2. poslednji back screen postaje current
        this.currentScreen = this.backScreens.pop()
        this.query.backScreens = this.backScreens
        // 3. answer
        this.currentAnswer = this.form[this.currentScreen].answer
        // 4. clear url? - save only source
        if (this.clearUrl) {
          const source = this.query['source']
          this.query = { source }
        }
        this.clearUrl = false
        // 5. change route
        this.$router.push({
          name: this.currentScreen,
          query: this.query
        })
      },

      handleNext() {
        this.isBack = false
        if (this.otherWFLink) {
          return this.$router.push(this.otherWFLink)
        }
        if (this.externalLink) {
          return (window.location.href = this.externalLink)
        }
        // 1. current ide u back arr
        this.backScreens.push(this.currentScreen)
        this.query.backScreens = this.backScreens
        // 2. next prelazi u current
        this.currentScreen = this.nextScreen
        // 3. change route
        this.$router.push({ name: this.currentScreen, query: this.query })
      }
    },
    created() {
      this.query = this.$route.query

      // has url back screens
      if (this.query['backScreens']) {
        // only one back - string
        if (typeof this.query['backScreens'] === 'string')
          this.backScreens = [this.query['backScreens']]
        // more then one back - array
        else this.backScreens = [...this.query['backScreens']]
      }

      this.currentScreen = this.$route.name
    }
  }
</script>

<style lang="scss" scoped>
  .navigation {
    height: 28px;
  }
  .navigation-btn {
    padding: 0px;
    font-size: 12px !important;
  }
</style>
