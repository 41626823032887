<template>
  <div>
    <!-- note: title -->
    <v-card-title class="pt-0 pl-0 pl-sm-5 ml-5 mb-1 breakword">
      <span class="text-h5">{{ titleText }}</span>
    </v-card-title>
    <v-card-text class="pt-0 pb-5 px-3 px-sm-5">
      <v-row class="justify-center px-0 px-sm-5">
        <v-col cols="12">
          <v-text-field
            @input="handleEmailUpdate"
            outlined
            dense
            hide-details
            label="Email"
            :value="email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" v-if="isEmailAndText">
          <VuePhoneNumberInput
            show-code-on-list
            @update="handleSmsPhoneUpdate"
            v-model="sendingData[1].smsPhone.phoneNumber"
            no-example
            default-country-code="US"
            :only-countries="['US', 'CA']"
            :no-flags="detectMob() ? true : false"
            color="#222"
            valid-color="#222"
            error-color="red"
          />
        </v-col>
        <v-col cols="12" class="grey-color-light" v-if="isEmailAndText">
          By clicking CONFIRM & SEND I agree to receive recurring automated text
          messages (e.g. salon replies) at the phone number provided. Consent is
          not a condition to purchase. Msg & data rates may apply. Msg frequency
          varies. Reply HELP for help and STOP to cancel. View our
          <a
            href="https://iwanthairdreams.com/policies/terms-of-service"
            target="_blanc"
            >Terms of Service</a
          >
          and
          <a
            href="https://iwanthairdreams.com/policies/privacy-policy"
            target="_blanc"
            >Privacy Policy</a
          >.</v-col
        >
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
  import { detectMob } from '@/utils/helpers'

  export default {
    props: {
      sendingData: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        detectMob,
        isPhoneValid: false
        // emailRules: [
        //   v =>
        //     !v ||
        //     /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        //     'E-mail must be valid'
        // ]
      }
    },
    computed: {
      isEmailAndText() {
        return this.sendingData[2] === 'emailAndText'
      },
      titleText() {
        if (this.isEmailAndText) return 'Confirm your email and phone'
        else return 'Confirm your email'
      },
      email() {
        return this.sendingData[1].email
      },
      isValid() {
        const emailValid = !!this.email
        const phoneValid =
          !this.isEmailAndText || !!this.sendingData[1].smsPhone.phoneNumber
        // const phoneValid =
        //   !this.isEmailAndText ||
        //   (this.isPhoneValid && this.sendingData[1].smsPhone.phoneNumber)
        return emailValid && phoneValid
      }
    },
    methods: {
      handleEmailUpdate(email) {
        this.$emit('onDataChange', {
          step: 3,
          data: { key: 'email', value: email }
        })
      },
      handleSmsPhoneUpdate(obj) {
        // this.isPhoneValid = obj.isValid
        this.$emit('onDataChange', {
          step: 3,
          data: {
            key: 'smsPhone',
            value: {
              formattedNumber: obj.formattedNumber,
              phoneNumber: obj.phoneNumber,
              validated: true
            }
          }
        })
      }
    },
    watch: {
      isValid(newValue, oldValue) {
        this.$emit('onValid', { step: 3, isValid: newValue })
      }
    },
    created() {
      this.$emit('onValid', { step: 3, isValid: this.isValid })
    }
  }
</script>

<style lang="scss" scoped>
  .grey-color-light {
    color: #a8a8a8;
    font-size: 10px;
    line-height: 14px;
  }
</style>
