<template>
  <v-container class="px-0 px-sm-3 pt-0" v-if="!$store.state.salons.loading">
    <v-card class="elevation-12 mx-auto" max-width="550">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto">{{
          titleText
        }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pa-0 pa-sm-3">
        <v-container>
          <v-card-title>Email address</v-card-title>

          <v-card-subtitle>{{ subtitleText }}</v-card-subtitle>

          <v-col cols="12" md="8" class="pl-4"
            ><v-text-field
              label="Email"
              hide-details
              v-model="selectedEmail"
            ></v-text-field
          ></v-col>

          <v-col>
            <v-btn
              light
              text
              color="blue"
              small
              @click="showSimpleModal = true"
            >
              Trouble verifying?
            </v-btn>
          </v-col>

          <v-col cols="12" class="d-flex">
            <v-btn
              rounded
              color="user_primary"
              @click="findSalon"
              :disabled="!isValidEmailForm"
              class="ml-auto white--text"
              :loading="isSending"
              >Next
            </v-btn>
          </v-col>
        </v-container>
      </v-card-text>
    </v-card>
    <SimpleModal
      max-width="400"
      :showDialog="showSimpleModal"
      title="Contact Us"
      :message="contactText"
      @closeSimpleModal="closeSimpleModal"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        isSending: false,
        selectedEmail: '',
        showSimpleModal: false,
        contactText:
          'If you have further questions or need immediate assistance, please feel free to contact us at <a href="mailto:hairdreams@bht-usa.com?subject=Salon Finder Assistance Needed">hairdreams@bht-usa.com</a>.'
      }
    },
    computed: {
      isValidEmailForm() {
        return !!this.selectedEmail
      },
      isFromSalonHome() {
        return this.$route.query.from === 'SalonHome'
      },
      titleText() {
        if (this.isFromSalonHome) return 'Hairdreams Partner Account'
        else return 'Edit Salon Finder Page'
      },
      subtitleText() {
        if (this.isFromSalonHome)
          return 'Please provide an email address on file to login to your account.'
        else
          return 'Please provide an email address on file to edit your Salon Finder page.'
      }
    },
    methods: {
      closeSimpleModal() {
        this.showSimpleModal = false
      },
      ...mapActions(['sendCode']),
      async findSalon() {
        this.isSending = true
        const response = await this.sendCode(this.selectedEmail)
        if (!this.$store.state.salons.error) {
          // 1. ako je neaktivan prikazi opt out
          if (response.salonActive === false) {
            return this.$router.push({
              name: 'OptOut',
              query: { from: this.$route.query.from || this.$route.name }
            })
          }
          // 2. ako je aktivan salji ga na validate mail
          this.$router.push({
            name: 'ValidateEmail',
            query: { from: this.$route.query.from || this.$route.name }
          })
        }
        this.isSending = false
      }
    },
    created() {
      if (this.$route.query.email) {
        this.selectedEmail = this.$route.query.email
      }
    }
  }
</script>
