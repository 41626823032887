<template>
  <v-container>
    <v-card class="elevation-12 mx-auto" max-width="450">
      <!-- is loading -->
      <v-card-text v-if="isLoading" class="text-center">
        <v-progress-circular
          indeterminate
          color="blue-grey"
        ></v-progress-circular>
      </v-card-text>
      <!-- finished loading -->
      <template v-else>
        <v-card-title class="justify-center">Choose action</v-card-title>
        <!-- <v-card-text
          v-if="hasFinishedApplication"
          @click="handleViewCurrentApplication"
          class="d-flex justify-center"
          ><v-btn>View Current Application</v-btn>
        </v-card-text> -->
        <v-card-text
          v-if="hasNotFinishedApplication"
          @click="handleContinueApplication"
          class="d-flex justify-center"
          ><v-btn>Continue Application</v-btn>
        </v-card-text>
        <!-- <v-card-text
          v-if="!hasNotFinishedApplication"
          class="d-flex justify-center"
          ><v-btn @click="handleApplyAgain">Apply Again</v-btn></v-card-text
        > -->
        <v-card-text
          v-if="hasNotFinishedApplication"
          class="d-flex justify-center"
          ><v-btn @click="handleStartOver">Start Over</v-btn></v-card-text
        >
      </template>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      applicationsCount() {
        return this.$store.state.applications.applications.length
      },
      hasNotFinishedApplication() {
        return !!this.$store.state.applications.applications?.find(
          app => !app.finished
        )
      },
      hasFinishedApplication() {
        return !!this.$store.state.applications.applications?.find(
          app => app.finished
        )
      }
    },
    methods: {
      ...mapActions([
        'addApplication',
        'resetApplication',
        'fetchApplicationsByStylist',
        'setFinishedApplication',
        'setContinueApplication'
      ]),
      handleViewCurrentApplication() {
        this.setFinishedApplication()
        this.$router.push({
          name: 'stylist-final-status',
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.$store.state.applications.application._id
          },
          query: this.$route.query
        })
      },
      handleContinueApplication() {
        this.setContinueApplication()
        this.$router.push({
          name: this.$store.state.applications.application.currentScreen,
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.$store.state.applications.application._id
          },
          query: this.$route.query
        })
      },
      handleApplyAgain() {
        this.createApplication()
      },
      async createApplication() {
        await this.addApplication({ stylistId: this.$route.params.stylistId })
        this.$router.push({
          name: 'stylist-system1',
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.$store.state.applications.application._id
          },
          query: this.$route.query
        })
      },
      async handleStartOver() {
        await this.resetApplication(
          this.$store.state.applications.application._id
        )
        this.$router.push({
          name: 'stylist-system1',
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.$store.state.applications.application._id
          },
          query: this.$route.query
        })
      }
    },
    async created() {
      await this.fetchApplicationsByStylist(this.$route.params.stylistId)
      // if no applications, create one
      if (this.applicationsCount === 0) {
        await this.createApplication()
      }
      if (this.hasFinishedApplication) {
        this.$router.push({
          name: 'stylist-final-status',
          params: {
            stylistId: this.$route.params.stylistId,
            applicationId: this.$store.state.applications.applications?.find(
              app => app.finished
            )._id
          },
          query: this.$route.query
        })
      }
      this.isLoading = false
    }
  }
</script>
