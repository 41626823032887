import api from '@/services/api.service'

// state
export const state = { list: [], loading: false, error: '', totalCount: 0 }

// getters
export const getters = {
  stylistsWithSystem: state => {
    return state.list.filter(stylist => stylist.system !== 'Other')
  },
  stylistsWithOtherSystem: state => {
    return state.list.filter(stylist => stylist.system === 'Other')
  }
}
// mutations
export const mutations = {
  stylistFetched: (state, stylist) => (state.stylist = stylist),
  stylistsFetched: (state, stylists) => {
    state.list = stylists
  },
  totalCountFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  stylistEdited: (state, editedStylist) => {
    state.list = state.list.map(stylist => {
      return stylist._id === editedStylist._id
        ? { ...editedStylist }
        : { ...stylist }
    })
  },
  stylistRemoved: (state, id) =>
    (state.list = state.list.filter(s => s._id !== id)),
  loadingStylistsSet: state => (state.loading = true),
  loadingStylistsCleared: state => (state.loading = false),
  errorStylistsSet: (state, error) => (state.error = error),
  errorStylistsCleared: state => (state.error = '')
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorStylistsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingStylistsCleared')
  setTimeout(() => {
    commit('errorStylistsCleared')
  }, 10000)
}

// actions
export const actions = {
  getStylistsCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postStylistsCsv(payload)
      return response.data
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addStylist: async ({ commit }, stylist) => {
    // commit('loadingStylistsSet')
    try {
      const response = await api.postStylist(stylist)
      commit('stylistFetched', response.data.data)
      // commit('loadingStylistsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchStylists: async ({ commit }) => {
    commit('loadingStylistsSet')
    try {
      const response = await api.getStylists()
      commit('stylistsFetched', response.data.data)
      commit('loadingStylistsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchStylistsTableData: async ({ commit }, payload = {}) => {
    commit('loadingStylistsSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getStylistsTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('stylistsFetched', response.data.data)
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingStylistsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // use it for deactivate also (send from component active:false)
  editStylist: async ({ commit }, stylist) => {
    commit('loadingStylistsSet')

    try {
      const { data } = await api.patchStylist(stylist)
      // edit
      if (stylist.active === false) {
        commit('stylistRemoved', stylist.id)
      }
      // delete
      else {
        commit('stylistEdited', data.data)
      }
      commit('loadingStylistsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
