<template>
  <div>
    <div class="d-flex justify-center align-center" v-if="isLoading">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <GChart :type="type" :data="chartData" :options="options"
    /></template>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  export default {
    components: {
      GChart
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true,
        type: 'PieChart',
        options: {
          colors: ['green', '#808080', 'red'],
          title: '',
          height: 300,
          animation: { startup: true, duration: 500 },
          legend: { position: 'bottom' }
        }
      }
    },
    computed: {
      consumersContactedSalons() {
        return this.$store.state.analytics.consumersContactedSalons
      },
      chartData() {
        return [
          ['Status', 'Count'],
          [
            'Contacted',
            this.consumersContactedSalons?.['consumersContactedSalon']
          ],
          [
            'Not contacted',
            this.$store.state.analytics.consumersNumbers?.total -
              this.consumersContactedSalons?.['consumersContactedSalon'] -
              this.consumersContactedSalons?.['consumersWithoutSalons']
          ],
          [
            'No Salon',
            this.consumersContactedSalons?.['consumersWithoutSalons']
          ]
        ]
      },
      contactedSalonsPercentage() {
        const total =
          this.consumersContactedSalons?.['consumersContactedSalon'] +
          (this.$store.state.analytics.consumersNumbers?.total -
            this.consumersContactedSalons?.['consumersContactedSalon'])

        const percentage =
          (this.consumersContactedSalons?.['consumersContactedSalon'] / total) *
          100
        return percentage.toFixed(1)
      }
    },
    methods: {
      ...mapActions(['fetchConsumersContactedSalons'])
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true

        await this.fetchConsumersContactedSalons({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.options.title = `Contacted salon: ${this.contactedSalonsPercentage}%`
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true

        await this.fetchConsumersContactedSalons({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.options.title = `Contacted salon: ${this.contactedSalonsPercentage}%`
        this.isLoading = false
      }
    },
    async created() {
      await this.fetchConsumersContactedSalons({
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.options.title = `Contacted salon: ${this.contactedSalonsPercentage}%`
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped></style>
