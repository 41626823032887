<template>
  <v-container>
    <v-card max-width="400" class="elevation-12 mx-auto pb-5 pt-0">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Web Forms Examples</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'stylist-certification',
                params: {
                  stylistId: stylistAccountId,
                  certification: StylistsCertifications.LB_HALF
                }
              }"
              >LB HALF</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'stylist-certification',
                params: {
                  stylistId: stylistAccountId,
                  certification: StylistsCertifications.LB_FULL
                }
              }"
              >LB FULL</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'stylist-certification',
                params: {
                  stylistId: stylistAccountId,
                  certification: StylistsCertifications.ML_REGULAR
                }
              }"
              >ML REGULAR</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'stylist-certification',
                params: {
                  stylistId: stylistAccountId,
                  certification: StylistsCertifications.ML_CUTTING
                }
              }"
              >ML CUTTING</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'stylist-certification',
                params: {
                  stylistId: stylistAccountId,
                  certification: StylistsCertifications.FS_ONLINE
                }
              }"
              >FS ONLINE</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'stylist-certification',
                params: {
                  stylistId: stylistAccountId,
                  certification: StylistsCertifications.FS_IN_PERSON
                }
              }"
              >FS IN-PERSON</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'Salons'
              }"
              >Admin</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'SalonHome'
              }"
              >Salon Home Page</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'SalonMessages',
                params: { salonId }
              }"
              >Salon Messages Dashboard</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{ name: 'userType' }"
              >Web Form 1: Find a salon</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'GreatNews',
                params: {
                  consumerId,
                  salonId
                }
              }"
              >Web Form 2: Great News</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'NoSalonFound',
                params: { consumerId: emptyConsumerId }
              }"
              >Web Form 2.1: No Salon Found</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'NewConsumerContact',
                query: { source: ContactMessageSources.example_page }
              }"
              >Web Form 2.2: Contact Page</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'Welcome'
              }"
              >Web Form 2.3: Welcome</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'NewInquiry',
                params: {
                  consumerId: newInquiryConsumerId,
                  salonId: newInquirySalonId
                }
              }"
              >Web Form 3: New Inquiry</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              :to="{
                name: 'DiscountPage',
                params: { consumerId, salonId }
              }"
              dark
              right
              >Web Form 4: Discount Page</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{ name: 'FindSalon' }"
              >Web Form 5: Salon Sign Up</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{ name: 'PendingSalons' }"
              >Web Form 5.1: Pending Salons</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'Communication',
                params: {
                  consumerId: newInquiryConsumerId,
                  salonId: newInquirySalonId
                }
              }"
              >Web Form 6: Communication</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'AdminCommunication',
                params: {
                  consumerId: newInquiryConsumerId,
                  salonId: newInquirySalonId
                }
              }"
              >Web Form 6.1: Communication</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'Agreement'
              }"
              >Web Form 7: Agreement</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'Certification'
              }"
              >Certification</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'Unsubscribe',
                params: { consumerId: this.consumerId }
              }"
              >Web Form 8: Unsubscribe</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="primary"
              dark
              right
              :to="{
                name: 'SendEmails'
              }"
              >Web Form 9: Send E-Mails</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'CCPA'
              }"
              >CCPA Compliance</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'WF11_Stylists',
                query: { system: SystemOptions.LASERBEAMER }
              }"
              >WF11: Stylists (NANO)</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'WF11_Stylists',
                query: { system: SystemOptions.FREESTYLER }
              }"
              >WF11: Stylists (FREESTYLER)</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'WF11_Stylists',
                query: { system: SystemOptions.MICROLINES }
              }"
              >WF11: Stylists (MICROLINES)</v-btn
            >
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'WF12_Contact'
              }"
              >WF12: Contact</v-btn
            >
          </v-col>
        </v-row> -->
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'GlobalContactPage',
                params: { source: 'fb-ig-ad-ml' }
              }"
              >CONTACT PAGE: MICROLINES</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'GlobalContactPage',
                params: { source: 'fb-ig-ad-lb' }
              }"
              >CONTACT PAGE: NANO</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'GlobalContactPage',
                params: { source: 'fb-ig-ad-fs' }
              }"
              >CONTACT PAGE: FREESTYLER</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex">
            <v-btn
              class="text-caption text-sm-button"
              width="100%"
              color="user_primary"
              dark
              right
              :to="{
                name: 'GlobalContactPage',
                params: { source: 'fb-ig-ad-ta' }
              }"
              >CONTACT PAGE: TAPES</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import {
    ContactMessageSources,
    StylistsCertifications,
    SystemOptions
  } from '@/config/enums'

  export default {
    data() {
      return {
        SystemOptions,
        ContactMessageSources,
        StylistsCertifications,
        url: '',
        picture: null
      }
    },
    computed: {
      consumerId() {
        return process.env.NODE_ENV === 'production'
          ? '62c95b9ab193f25d1b3cb7d0'
          : '630e0bc72b3a523a972202ef'
      },
      newInquiryConsumerId() {
        return process.env.NODE_ENV === 'production'
          ? '62c95b9ab193f25d1b3cb7d0'
          : '630e0bc72b3a523a972202ef'
      },
      salonId() {
        return process.env.NODE_ENV === 'production'
          ? '6291390ac28bf31afe504bd0'
          : '6299fba54c80e66217bd730a'
      },
      newInquirySalonId() {
        return process.env.NODE_ENV === 'production'
          ? '6291390ac28bf31afe504bd0'
          : '6299fba54c80e66217bd730a'
      },
      emptyConsumerId() {
        return process.env.NODE_ENV === 'production'
          ? '62c95bf3b193f25d1b3cb7e4'
          : '630e0b9d2b3a523a972202e8'
      },
      stylistId() {
        return process.env.NODE_ENV === 'production'
          ? '6291390ac28bf31afe504bd0'
          : '653000e823ec1ff46ec608a7'
      },
      stylistAccountId() {
        return process.env.NODE_ENV === 'production'
          ? '65a75d87c3dfcf3667039cf6'
          : '65a75d87c3dfcf3667039cf6'
      }
    }
  }
</script>
