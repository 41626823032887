<template>
  <v-container>
    <v-row class="justify-center">
      <v-row>
        <v-col cols="12" class="pl-0 pb-0">
          <v-card-title class="breakword pb-0"
            >What systems are you interested in?</v-card-title
          ></v-col
        >
        <v-col
          v-for="option in options"
          :key="option.title"
          class="d-flex justify-center flex-column align-center pb-0 mx-auto"
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 6"
          @click="handleSelect(option.value)"
        >
          <v-btn
            depressed
            :color="
              JSON.stringify(answer) === JSON.stringify(option.value)
                ? 'user_cta'
                : 'user_btn'
            "
            :dark="JSON.stringify(answer) === JSON.stringify(option.value)"
            class="text-none mt-1"
            width="100%"
            >{{ option.text }}
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { SystemOptions } from '@/config/enums'

  export default {
    data() {
      return {
        answer: '',
        SystemOptions,
        options: [
          {
            text: 'Extensions and Hair loss',
            value: [
              SystemOptions.MICROLINES,
              SystemOptions.TAPE,
              SystemOptions.LASERBEAMER,
              SystemOptions.FREESTYLER
            ]
          },
          {
            text: 'Extensions only',
            value: [
              SystemOptions.TAPE,
              SystemOptions.LASERBEAMER,
              SystemOptions.FREESTYLER
            ]
          },
          {
            text: 'Hair loss only',
            value: [SystemOptions.MICROLINES]
          }
        ],
        field: 'system',
        screen: 'stylist-system1',
        nextScreen: 'stylist-extensions1'
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        this.setNextScreen(this.nextScreen)
      },
      async handleSelect(answer) {
        this.answer = answer
        // 1. set data
        await this.setApplicationData({ field: this.field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
        // 4. go to next screen
        this.$emit('next')
      }
    },
    created() {
      // If microLines is the primary system, remove the extensions only option
      if (
        this.$store.state.applications?.application?.account?.primarySystem ===
        SystemOptions.MICROLINES
      ) {
        this.options = this.options.filter(
          option => option.text !== 'Extensions only'
        )
      }

      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (
        this.$store.state.applications.application[this.field] &&
        this.$store.state.applications.application[this.field].length > 0
      ) {
        this.answer = this.$store.state.applications.application[this.field]
        this.handleNextScreen()
        this.setIsValid(true)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    }
  }
</script>
<style scoped>
  .breakword {
    word-break: break-word;
  }
</style>
