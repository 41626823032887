<template>
  <v-container fluid class="container">
    <v-card-title>Consumers Analytics</v-card-title>
    <v-row>
      <v-col cols="12" v-if="tab === 2">
        <ConsumersNumbers :startDate="startDate" :endDate="endDate" />
      </v-col>

      <!-- <v-col cols="12">
        <ConsumerReferralsGeo :startDate="startDate" :endDate="endDate" />
      </v-col> -->

      <v-col cols="6" v-if="tab === 2">
        <ConsumersSystems :startDate="startDate" :endDate="endDate" />
      </v-col>
      <v-col cols="6" v-if="tab === 2">
        <ConsumersMessagesAndCalls :startDate="startDate" :endDate="endDate" />
      </v-col>
      <v-col cols="6" v-if="tab === 2">
        <ConsumersWithoutSalons :startDate="startDate" :endDate="endDate" />
      </v-col>

      <v-col cols="6" v-if="tab === 2">
        <ConsumersWithoutSalonsByCounty
          :startDate="startDate"
          :endDate="endDate"
        />
      </v-col>
      <!-- <v-col cols="12" v-if="tab === 2">
        <ConsumersLogsBySystem :startDate="startDate" :endDate="endDate" />
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
  import ConsumersNumbers from '@/components/admin/analytics/consumers/ConsumersNumbers.vue'
  // import ConsumerReferralsGeo from '@/components/admin/analytics/consumers/ConsumerReferralsGeo.vue'
  import ConsumersSystems from '@/components/admin/analytics/consumers/ConsumersSystems.vue'
  import ConsumersWithoutSalons from '@/components/admin/analytics/consumers/ConsumersWithoutSalons.vue'
  import ConsumersWithoutSalonsByCounty from '@/components/admin/analytics/consumers/ConsumersWithoutSalonsByCounty.vue'
  import ConsumersMessagesAndCalls from '@/components/admin/analytics/consumers/ConsumersMessagesAndCalls.vue'
  // import ConsumersLogsBySystem from '@/components/admin/analytics/consumers/ConsumersLogsBySystem.vue'

  export default {
    components: {
      ConsumersNumbers,
      // ConsumerReferralsGeo,
      ConsumersSystems,
      ConsumersWithoutSalons,
      ConsumersWithoutSalonsByCounty,
      ConsumersMessagesAndCalls,
      // SignedUpForSms
      ConsumersMessagesAndCalls
      // ConsumersLogsBySystem
    },
    props: {
      tab: {
        type: Number
      },
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    background-color: #eee;
  }
</style>
