<template>
  <v-container v-if="!$store.state.salons.loading">
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ notification }}</v-snackbar
    >
    <v-card width="400" class="mx-auto mt-5">
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <!-- note: validation form -->
      <template v-if="showValidationForm">
        <v-card-text class="text-center"
          >Please provide the security code we just sent to
          {{ email }}</v-card-text
        >
        <v-card-text class="pa-5 pt-0">
          <v-form class="d-flex flex-column">
            <v-btn
              text
              small
              class="text-capitalize pr-0 align-self-end"
              color="#19b3cc"
              @click="handleDidntGetCode"
              >Didn't get the code?</v-btn
            >
            <v-text-field
              :rules="[rules.required]"
              v-model="code"
              type="text"
              label="Security code"
              prepend-icon="mdi-lock"
              class="pt-0 mt-0"
            />
            <v-card-actions>
              <v-btn
                block
                color="user_primary"
                class="white--text"
                rounded
                :disabled="disableConfirm"
                @click="handleConfirmCode"
                :loading="$store.state.auth.loading"
              >
                Confirm</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card-text>
      </template>
      <!-- note: set password form -->
      <template v-else>
        <!-- only from salom home show this -->
        <v-card-text
          v-if="!isFromWF3 && !isFromWF5"
          class="text-uppercase subtitle-1 font-weight-bold pa-5 pb-0"
          >Security update</v-card-text
        >
        <v-card-text class="pa-5 pb-0 subtitle-1 font-weight-bold"
          >Seems that you didn't set a password</v-card-text
        >
        <v-card-text class="pa-5"
          >If you use this Hairdreams account on more devices, you can log in
          using your new password.</v-card-text
        >
        <v-card-text class="pa-5 pt-0 d-flex flex-column">
          <v-btn
            text
            small
            class="text-capitalize pr-0 align-self-end"
            color="#19b3cc"
            @click="handleNotYou"
            >Not you?</v-btn
          >
          <v-text-field
            prepend-icon="mdi-email"
            :rules="[rules.required, rules.email]"
            disabled
            label="Salon Email on the account"
            :value="email"
            class="pt-0 mt-0"
          />

          <v-text-field
            :rules="[rules.required, rules.minLength]"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            label="New Password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            :rules="[rules.required, rules.minLength]"
            v-model="passwordConfirm"
            :type="showPasswordConfirm ? 'text' : 'password'"
            label="Confirm Password"
            prepend-icon="mdi-lock"
            :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPasswordConfirm = !showPasswordConfirm"
          />
          <v-checkbox
            label="Keep me logged in on this device"
            v-model="keepLogged"
          />
          <v-card-actions>
            <v-btn
              block
              color="user_primary"
              class="white--text"
              rounded
              :disabled="disableSetPassword"
              @click="handleSetPassword"
              :loading="$store.state.auth.loading"
            >
              Set Password</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </template>
    </v-card>
    <SimpleModal
      max-width="400"
      :showDialog="showSimpleModal"
      title="Contact Us"
      :message="contactText"
      @closeSimpleModal="closeSimpleModal"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { UserRoles } from '@/config/enums'
  export default {
    data() {
      return {
        timeout: null,
        showValidationForm: false,
        code: null,
        keepLogged: false,
        password: '',
        passwordConfirm: '',
        rules: {
          required: value => !!value || 'Required.',
          minLength: value => value.length >= 6 || 'Min 6 characters',
          email: value => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },
        showPassword: false,
        showPasswordConfirm: false,
        notification: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red',
        showSimpleModal: false,
        contactText:
          'If you have further questions or need immediate assistance, please feel free to contact us at <a href="mailto:hairdreams@bht-usa.com?subject=Salon Finder Assistance Needed">hairdreams@bht-usa.com</a>.'
      }
    },
    computed: {
      email() {
        return this.$store.state?.salons?.selectedSalon?.email
      },
      disableSetPassword() {
        return (
          !this.email ||
          !this.password ||
          this.password.length < 6 ||
          !this.passwordConfirm ||
          this.passwordConfirm.length < 6
        )
      },
      disableConfirm() {
        return !this.code
      },
      isFromWF5() {
        return this.$route.query.from.includes('edit')
      },
      isFromWF3() {
        return this.$route.query.from.includes('new-inquiry')
      },
      titleText() {
        if (this.isFromWF3) return 'Security Update'
        else if (this.isFromWF5) return 'Security Update'
        else return 'Hairdreams Partner Account'
      }
    },
    methods: {
      ...mapActions(['fetchSalon', 'sendSalonSignupValidationCode', 'signup']),
      closeSimpleModal() {
        this.showSimpleModal = false
      },
      handleNotYou() {
        this.showSimpleModal = true
      },
      handleDidntGetCode() {
        this.showSimpleModal = true
      },
      async handleSetPassword() {
        if (this.password !== this.passwordConfirm) {
          this.notification = 'Your password and confirm password do not match.'
          this.snackbarColor = 'red'
          this.snackbar = true
          return
        }
        // send email
        await this.sendSalonSignupValidationCode(this.$route.params.salonId)

        if (!this.$store.state.auth.error) {
          this.showValidationForm = true
        } else {
          this.notification = this.$store.state.auth.error
          this.snackbarColor = 'red'
          this.snackbar = true
        }
      },
      async handleConfirmCode() {
        const userData = {
          code: this.code,
          email: this.email,
          password: this.password,
          passwordConfirm: this.passwordConfirm,
          role: UserRoles.SALON,
          salon: this.$route.params.salonId
        }
        await this.signup(userData)

        if (!this.$store.state.auth.error) {
          this.notification = 'Your new password has been set'
          this.snackbarColor = 'green'
          this.snackbar = true
          this.timeout = setTimeout(() => {
            if (this.$route.query.from) {
              this.$router.replace(this.$route.query.from)
            } else {
              this.$router.push('/')
            }
          }, 1000)
        } else {
          this.notification = this.$store.state.auth.error
          this.snackbarColor = 'red'
          this.snackbar = true
        }
      }
    },
    async mounted() {
      await this.fetchSalon(this.$route.params.salonId)
    },
    beforeDestroy() {
      clearTimeout(this.timeout)
    }
  }
</script>
