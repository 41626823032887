<template>
  <v-container>
    <v-row class="justify-center">
      <v-row>
        <v-col cols="12" class="pl-0 pb-0">
          <v-card-title class="breakword pb-0"
            >Based on your answer of $500 to $1000 for what you feel your
            clients are willing to spend for a MicroLines+ application, we are
            not sure if this would be a good fit for your salon. Typically, a
            salon will charge $2000+. <br />
            <br />Do you feel your clients would be willing to pay $2000+?
          </v-card-title></v-col
        >
        <v-col
          v-for="option in options"
          :key="option.value"
          class="d-flex justify-center flex-column align-center pb-0"
          :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-3'"
          cols="6"
          @click="handleSelect(option.value)"
        >
          <v-btn
            depressed
            :color="answer === option.value ? 'user_cta' : 'user_btn'"
            :dark="answer === option.value"
            class="text-none mt-1"
            width="100%"
            >{{ option.text }}
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        answer: '',
        options: [
          {
            text: 'Yes',
            value: 'Yes'
          },
          {
            text: 'No',
            value: 'No'
          }
        ],
        previousField: 'microlinesApplicationSpend',
        field: 'changedApplicationSpendAnswer',
        screen: 'stylist-restoration2_1',
        nextScreen: 'stylist-restoration3'
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        this.setNextScreen(this.nextScreen)
      },
      setPreviousFieldIfYes() {
        if (this.answer === 'Yes') {
          this.setApplicationData({
            field: this.previousField,
            answer: '$1000 to $2000'
          })
        } else if (this.answer === 'No') {
          this.setApplicationData({
            field: this.previousField,
            answer: '$500 to $1000'
          })
        }
      },
      handleSelect(answer) {
        this.answer = answer
        // 1. set data
        // 1a. set this component data
        this.setApplicationData({ field: this.field, answer })
        // 1b. set previous component data
        this.setPreviousFieldIfYes()
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
        // 4. go to next screen
        this.$emit('next')
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field]) {
        this.answer = this.$store.state.applications.application[this.field]
        // 1b. set previous component data
        this.setPreviousFieldIfYes()
        this.handleNextScreen()
        this.setIsValid(true)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    }
  }
</script>
<style scoped>
  .breakword {
    word-break: break-word;
  }
</style>
