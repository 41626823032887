<template>
  <div>
    <v-card-text class="mb-0 pb-0 mt-4">
      <v-row class="align-center mb-0 pb-0">
        <v-spacer></v-spacer>
        <v-col cols="6">
          <v-text-field
            outlined
            hide-details
            dense
            label="Enter Salon Navid"
            v-model="navid"
          ></v-text-field
        ></v-col>
        <v-col cols="2" class="d-flex justify-start">
          <v-btn
            @click="handleFindSalons"
            small
            block
            :loading="$store.state.salons.loading"
            >Find</v-btn
          ></v-col
        >
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
    <v-card-text class="pt-0">
      <v-data-table
        :headers="headers"
        :items="salons"
        item-key="id"
        :loading="$store.state.salons.loading"
      >
        <!-- for edit action -->
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            rounded
            color="#ff5e6c"
            class="white--text text-capitalize"
            @click="selectSalon(item)"
          >
            Select
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        navid: '',
        headers: [
          { text: 'Navid', align: 'center', value: 'navid' },
          { text: 'Name', align: 'start', value: 'name' },
          { text: 'Actions', value: 'actions', align: 'center' }
        ]
      }
    },
    computed: {
      salons() {
        return this.$store.state.salons.list
      }
    },
    methods: {
      ...mapActions(['fetchSalonsByNavid']),
      handleFindSalons() {
        this.fetchSalonsByNavid(this.navid)
      },
      selectSalon(item) {
        this.$emit('onSelectSalon', item._id)
      }
    }
  }
</script>
