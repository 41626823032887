<template>
  <div v-if="!isLoading">
    <!-- Loading -->
    <div v-if="!salon" class="text-center mt-3">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <!-- Loaded -->
    <template v-else>
      <!-- note: if terms not accepted -->
      <TermsPopUp
        @accept="handleTermsAccept"
        :salon="salon"
        v-if="!termsAccepted"
      />
      <template v-else>
        <div class="top">
          <v-img
            height="250"
            class="ma-1"
            :lazy-src="
              salon.imgUrl ||
              'https://storage.googleapis.com/hairdreams-v2/default-salon-img.jpg'
            "
            :src="
              salon.imgUrl ||
              'https://storage.googleapis.com/hairdreams-v2/default-salon-img.jpg'
            "
          ></v-img>
          <v-btn icon dark class="edit-photo" @click="handleEditPhoto"
            ><v-icon>mdi-camera-outline</v-icon></v-btn
          >

          <v-card elevation="24" class="ma-1 card-wrapper">
            <v-card-title class="pb-0 main-title">{{
              salon.name
            }}</v-card-title>
            <!-- <v-row dense class="d-flex mobile-row">
          <v-col
            class="d-flex pl-2 justify-start align-center mobile-flex-50"
            v-if="bestMatch"
            cols="12"
            sm="8"
          >
            <v-icon left color="red"> mdi-heart </v-icon>
            <span class="text-red text-sm-h6 font-weight-bold fixed-height"
              >BEST MATCH</span
            >
          </v-col>
          <v-col
            class="d-flex align-center pr-2 justify-end mobile-flex-50"
            cols="12"
            sm="4"
          >
            <div class="text-subtitle-1 text-sm-h5 fixed-height">
              {{ (distance * 0.000621371192).toFixed(0) }}
              mi away
            </div>
          </v-col>
        </v-row> -->
          </v-card>
        </div>

        <v-container class="px-0 px-sm-3">
          <!-- note: buttons -->
          <v-row class="d-flex justify-center px-3">
            <!-- add reviews -->
            <v-col class="px-0 px-sm-3 mr-3 d-flex justify-end">
              <v-btn
                rounded
                dark
                class="btn"
                color="user_primary"
                @click="handleAddReviews"
                :small="detect399()"
                width="150"
              >
                <v-icon left>mdi-star</v-icon> Add reviews
              </v-btn></v-col
            >

            <!-- edit info -->
            <v-col class="px-0 px-sm-3 d-flex justify-start ml-3">
              <v-btn
                rounded
                class="btn"
                color="user_primary"
                dark
                width="150"
                @click="handleEditInfo"
                :small="detect399()"
              >
                <v-icon left> mdi-pencil </v-icon>
                Edit info</v-btn
              >
            </v-col>
          </v-row>

          <!-- note: Highlights -->
          <template v-if="salon.highlights.length > 0">
            <v-card-title class="pb-1 pl-3">Highlights</v-card-title>
            <HighlightsSlider
              v-if="salon.additionalHighlight"
              :highlights="[salon.additionalHighlight, ...salon.highlights]"
            />
            <HighlightsSlider v-else :highlights="salon.highlights" />
          </template>

          <!-- note: Reviews -->
          <template v-if="showReviews">
            <v-card-title class="pb-3 pl-3">Salon Reviews</v-card-title>
            <div class="wrapper">
              <ReviewsCarousel :reviews="reviewsArray" />
            </div>
          </template>

          <!-- note: MAP -->
          <v-card-title class="pb-1 pl-3">Location</v-card-title>
          <v-card elevation="0" class="mx-1">
            <Map :salon="salon" class="map" />
          </v-card>

          <!-- note: Hours -->
          <ShowHours
            v-if="salon.appointmentOnly !== true"
            :hours="salon.hours"
          />
        </v-container>
      </template>
    </template>
  </div>
</template>

<script>
  import ShowHours from '@/components/ShowHours'
  import HighlightsSlider from '@/components/user/HighlightsSlider.vue'
  import Map from '@/components/user/Map'
  import ReviewsCarousel from '@/components/user/ReviewsCarousel.vue'
  import TermsPopUp from '@/components/user/TermsPopUp.vue'
  import {
    // getHours,
    // getTermsStatus,
    // detectMob,
    detect399,
    detectXS,
    getTermsStatus
  } from '@/utils/helpers'
  import { mapActions } from 'vuex'

  export default {
    components: {
      Map,
      TermsPopUp,
      HighlightsSlider,
      ReviewsCarousel,
      ShowHours
    },
    data() {
      return {
        isLoading: true,
        termsAccepted: false,
        detectXS,
        detect399
      }
    },
    computed: {
      salonId() {
        return this.$store.state.auth.user.salon
      },
      salon() {
        return this.$store.state.salons.selectedSalon
      },
      reviewsArray() {
        const arr = []
        if (this.salon.review1.content) arr.push(this.salon.review1)
        if (this.salon.review2.content) arr.push(this.salon.review2)
        if (this.salon.review3.content) arr.push(this.salon.review3)
        return arr
      },
      showReviews() {
        return this.reviewsArray.length > 0
      }
    },
    methods: {
      handleTermsAccept() {
        this.termsAccepted = true
      },
      handleEditPhoto() {
        this.$router.push({
          name: 'EditSalon',
          params: { salonId: this.salonId },
          query: { step: 3 }
        })
      },
      handleAddReviews() {
        this.$router.push({
          name: 'EditSalon',
          params: { salonId: this.salonId },
          query: { step: 4 }
        })
      },
      handleEditInfo() {
        this.$router.push({
          name: 'EditSalon',
          params: { salonId: this.salonId },
          query: { step: 1 }
        })
      }
    },
    async created() {
      // set terms
      // -> isti text poslednjeg agreementa
      // +
      // -> status accepted
      if (
        this.$store.getters['lastAgreement']?.termsDate ==
          this.salon?.terms?.date &&
        getTermsStatus(this.salon?.terms) === 'accepted'
      ) {
        this.termsAccepted = true
      }

      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped>
  .edit-photo {
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .btn {
    text-transform: unset !important;
  }
  .wrapper {
    // background-color: #444444;
    border-radius: 15px;
    overflow: hidden;
    border: 2px solid #d5d5d58a;
  }

  .text-blue {
    color: #2c58f9;
  }
  .text-red {
    color: red;
  }
  .call-now {
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .map {
    margin-bottom: 30px;
  }
  .top {
    position: relative;
    margin-bottom: 60px;
  }
  .card-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 80%;
    padding: 20px 30px;
    border-radius: 50px !important;
  }
  .main-title {
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 12px;
    text-align: center;
    justify-content: center;
    word-break: break-word;
  }
  @media only screen and (max-width: 600px) {
    .main-title {
      font-size: 24px;
      line-height: 24px;
      font-weight: bold;
      margin-bottom: 8px;
      text-align: center;
    }
    .card-wrapper {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 92%;
      padding: 15px;
    }
    .mobile-row {
      display: flex;
      flex-wrap: nowrap;
    }
    .mobile-flex-50 {
      flex: 45%;
    }
    // .v-application .elevation-3 {
    //   box-shadow: none !important;
    // }
    .v-application .ma-1 {
      margin: 0 !important;
    }
  }
</style>
