<template>
  <v-container>
    <!--  ExtensionBrands -->
    <v-row class="justify-center">
      <v-col cols="12" class="pl-0 pb-0">
        <v-card-title class="breakword pb-0">
          What<span class="font-weight-bold">&nbsp;extension&nbsp;</span>brands
          do you offer?
        </v-card-title></v-col
      >
      <v-card-text class="text-center subtitle mb-2"
        >Choose multiple</v-card-text
      >
      <transition name="fade">
        <v-row class="form">
          <v-col
            :cols="$vuetify.breakpoint.smAndDown ? 6 : 4"
            class="d-flex justify-center my-0 py-1 mx-auto"
            :class="$vuetify.breakpoint.smAndDown ? 'px-1' : ''"
            v-for="option in options"
            :key="option.value"
          >
            <v-btn
              :width="$vuetify.breakpoint.smAndDown ? '100%' : 160"
              class="text-none"
              depressed
              small
              @click="
                handleMultiselectSelectAnswer(field, 'answer', option.value)
              "
              :dark="
                answer.includes(option.value) ||
                (option.value === 'Other' && showOtherInput)
              "
              :color="
                answer.includes(option.value) ||
                (option.value === 'Other' && showOtherInput)
                  ? 'user_cta'
                  : 'user_btn'
              "
              >{{ option.text }}</v-btn
            >
          </v-col>
        </v-row>
      </transition>

      <v-card-text class="my-0 pb-0" v-if="showOtherInput">
        <v-col
          :cols="$vuetify.breakpoint.smAndDown ? 12 : 5"
          class="d-flex justify-center my-0 pb-0 mx-auto"
        >
          <v-text-field
            class="pb-0"
            v-model="otherBrandText"
            label="Other Brand"
            dense
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-card-text>
      <v-row class="justify-center mt-5 my-3"
        ><v-btn
          color="blue"
          dark
          @click="handleMore(btnText == 'See more...' ? 'add' : 'remove')"
          text
          class="mx-auto text-capitalize"
          >{{ btnText }}</v-btn
        ></v-row
      >
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        showOtherInput: false,
        otherBrandText: '',
        btnText: 'See more...',
        answer: [],
        options: [
          {
            text: 'Bellami',
            value: 'Bellami'
          },
          {
            text: 'Babe',
            value: 'Babe'
          },
          {
            text: 'Hotheads',
            value: 'Hotheads'
          },
          {
            text: 'Dream Catchers',
            value: 'Dream Catchers'
          },
          {
            text: 'Donna Bella Milan',
            value: 'Donna Bella Milan'
          },
          {
            text: 'Hairtalk',
            value: 'Hairtalk'
          },
          {
            text: 'Aqua Hair',
            value: 'Aqua Hair'
          },
          {
            text: 'Halo Couture',
            value: 'Halo Couture'
          }
        ],
        additionalOptions: [
          {
            text: 'Great Lengths',
            value: 'Great Lengths'
          },
          {
            text: 'IBE',
            value: 'IBE-Invisible Bead Extensions'
          },
          { text: 'JZ Styles Hair', value: 'JZ Styles Hair' },
          { text: 'Covet & Main', value: 'Covet & Main' },
          { text: 'Hair Shop', value: 'Hair Shop' },
          { text: 'Cinderella', value: 'Cinderella' },
          { text: 'Bombshell', value: 'Bombshell' },
          { text: 'Habit Hand Tied', value: 'Habit Hand Tied' },
          { text: 'NBR', value: 'NBR-Natural Beaded Rows' },
          { text: 'Glam Seamless', value: 'Glam Seamless' },
          { text: 'Easihair Pro', value: 'Easihair Pro' },
          { text: 'Hairlocs', value: 'Hairlocs' },
          { text: 'Laced Hair Extensions', value: 'Laced Hair Extensions' },
          { text: 'SO. CAP. USA', value: 'SO. CAP. USA' },
          { text: 'Harper Ellis Hair Co.', value: 'Harper Ellis Hair Co.' },
          { text: 'Platinum Seamless', value: 'Platinum Seamless' },
          { text: 'Perfectress', value: 'Perfectress' },
          { text: 'Amplify Hair Extensions', value: 'Amplify Hair Extensions' },
          { text: 'Zala Extensions', value: 'Zala Extensions' },
          { text: 'Bohyme', value: 'Bohyme' },
          { text: 'Hairdreams', value: 'Hairdreams' },
          { text: 'KLIX', value: 'KLIX' },
          { text: 'OTHER', value: 'Other' }
        ],
        field: 'extensionBrands',
        screen: 'stylist-extensions2',
        nextScreen: 'stylist-extensions2_1'
      }
    },
    computed: {
      isValid() {
        return this.answer.length > 0
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        this.setNextScreen(this.nextScreen)
      },
      handleVuex(field, answer) {
        // 1. set data
        this.setApplicationData({ field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(this.isValid)
      },
      handleMultiselectSelectAnswer(field, answer, value) {
        if (value === 'Other') {
          this.showOtherInput = !this.showOtherInput
          if (this.showOtherInput) this.otherBrandText = ''
        } else {
          // this.showOtherInput = false

          if (this[answer].includes(value))
            this[answer] = this[answer].filter(q => q !== value)
          else this[answer].push(value)
          this.handleVuex(field, this[answer])
        }
      },
      handleMore(todo) {
        if (todo === 'add') {
          this.options = this.options.concat(this.additionalOptions)
          this.btnText = 'See less...'
        } else if (todo === 'remove') {
          this.options = this.options.slice(0, 8)
          this.btnText = 'See more...'
          this.answer = this.answer.filter(
            q => this.options.map(o => o.value).indexOf(q) !== -1
          )
          this.handleVuex(this.field, this.answer)
        }
      }
    },
    watch: {
      otherBrandText(newValue, oldValue) {
        this.answer = this.answer.filter(q => q !== oldValue)
        if (newValue) this.answer.push(newValue)

        this.handleVuex(this.field, this.answer)
      },
      showOtherInput(newValue, oldValue) {
        if (!newValue) this.otherBrandText = ''
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field]) {
        this.answer = this.$store.state.applications.application[this.field]

        // // check if there is a difference between answer and options
        // // and set otherBrandText and showOtherInput if there is
        // const difference = []
        // const allOptions = this.options
        //   .concat(this.additionalOptions)
        //   .map(o => o.value)

        // this.answer.forEach(a => {
        //   if (!allOptions.includes(a)) {
        //     difference.push(a)
        //   }
        // })

        // if (difference.length > 0) {
        //   this.answer.push('Other')
        //   this.otherBrandText = difference[0]
        //   this.showOtherInput = true
        // }

        this.handleNextScreen()
        this.setIsValid(this.isValid)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(this.isValid)
      }
    }
  }
</script>

<style>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s; /* Adjust the transition duration as needed */
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
<style scoped>
  .breakword {
    word-break: break-word;
  }
</style>
