<template>
  <v-container class="px-0 px-sm-3 pt-0" v-if="!isLoading">
    <v-card
      class="elevation-12 mx-auto d-flex flex-column"
      max-width="680"
      min-height="750"
    >
      <transition name="fade" mode="out-in">
        <router-view class="main-container" />
      </transition>

      <!-- bottom menu -->
      <SalonBottomMenu v-if="!isLoading" />
    </v-card>
  </v-container>
</template>

<script>
  import SalonBottomMenu from '@/components/user/SalonBottomMenu'
  import { mapActions } from 'vuex'

  export default {
    components: {
      SalonBottomMenu
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      salonId() {
        return this.$route.params.salonId
      }
    },
    methods: {
      ...mapActions(['editSalon', 'fetchSettings', 'fetchSetups', 'fetchSalon'])
    },
    async created() {
      const promises = [this.fetchSettings(), this.fetchSetups()]
      await Promise.all(promises)

      if (this.salonId) {
        await this.fetchSalon(this.salonId)

        if (this.$store.state.salons?.error) {
          this.$router.push({ name: 'FindSalon' })
        }
      }

      this.isLoading = false
    }
  }
</script>
