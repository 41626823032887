<template>
  <GChart
    type="LineChart"
    :data="chartData"
    :options="chartOptions"
    :events="chartEvents"
  />
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  import moment from 'moment'

  export default {
    components: {
      GChart
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        chartEvents: {
          select: () => {
            const selection = getSelection()

            let isFound = false
            const selectedText =
              selection?.baseNode?.data || selection?.focusNode?.data

            for (const key in this.chartOptions.series) {
              if (this.chartOptions.series[key].value === selectedText) {
                isFound = true
                this.chartOptions.series[key].lineWidth = 3
                this.chartOptions.series[key].pointsVisible = true
              } else {
                this.chartOptions.series[key].lineWidth = 0.5
                this.chartOptions.series[key].pointsVisible = false
              }
            }
            if (!isFound) {
              for (const key in this.chartOptions.series) {
                this.chartOptions.series[key].lineWidth = 2
                this.chartOptions.series[key].pointsVisible = false
              }
              this.showSecondChart = false
            }
          }
        },
        chartOptions: {
          // explorer: {},
          vAxis: { viewWindow: { min: 0 } },
          title: 'Where are we going viral?',
          dataOpacity: 100,
          legend: { position: 'bottom' },
          height: 600,
          animation: { startup: true, duration: 500 },
          series: {
            0: {
              color: 'black',
              pointsVisible: false,
              lineWidth: 2,
              value: 'TikTok HDUSA'
            },
            1: {
              color: '#ff0000',
              pointsVisible: false,
              lineWidth: 2,
              value: 'IG HD Plus'
            },
            2: {
              color: '#ff00ff',
              pointsVisible: false,
              lineWidth: 2,
              value: 'IG Hairdreaming'
            },
            3: {
              color: '#d5a6bd',
              pointsVisible: false,
              lineWidth: 2,
              value: 'IG HD USA'
            },
            4: {
              color: '#eb5757',
              pointsVisible: false,
              lineWidth: 2,
              value: 'IG HD Plus Salon'
            },
            5: {
              color: '#0000ff',
              pointsVisible: false,
              lineWidth: 2,
              value: 'FB HD USA Post'
            },
            6: {
              color: '#00ffff',
              pointsVisible: false,
              lineWidth: 2,
              value: 'FB HD Plus Post'
            },
            7: {
              color: 'blue',
              pointsVisible: false,
              lineWidth: 2,
              value: 'FB HD Plus Bio'
            },
            8: {
              color: 'green',
              pointsVisible: false,
              lineWidth: 2,
              value: 'TikTok Hairdreaming'
            },
            9: {
              color: 'orange',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Youtube HDUSA'
            }
          }
        }
      }
    },
    methods: {
      ...mapActions(['fetchTrafficConsumersSources'])
    },
    computed: {
      trafficConsumersSources() {
        return this.$store.state.analytics.trafficConsumersSources
      },
      chartData() {
        const data = [
          [
            'Date',
            'TikTok HDUSA',
            'IG HD Plus',
            'IG Hairdreaming',
            'IG HD USA',
            'IG HD Plus Salon',
            'FB HD USA Post',
            'FB HD Plus Post',
            'FB HD Plus Bio',
            'TikTok Hairdreaming',
            'Youtube HDUSA'
          ]
        ]
        this.trafficConsumersSources.forEach(item => {
          // console.log({ item: item.date, moment: moment(item.date) })
          data.push([
            // note: moment is converting utc to local date and format
            moment(item.date).format('ddd. MM/DD/YY'),
            item['TikTok HDUSA'],
            item['IG HD Plus'],
            item['IG Hairdreaming'],
            item['IG HD USA'],
            item['IG HD Plus Salon'],
            item['FB HD USA Post'],
            item['FB HD Plus Post'],
            item['FB HD Plus Bio'],
            item['TikTok Hairdreaming'],
            item['Youtube HDUSA']
          ])
        })
        return data
      }
    },
    watch: {
      startDate() {
        this.fetchTrafficConsumersSources({
          startDate: this.startDate,
          endDate: this.endDate
        })
      },
      endDate() {
        this.fetchTrafficConsumersSources({
          startDate: this.startDate,
          endDate: this.endDate
        })
      }
    },
    async created() {
      await this.fetchTrafficConsumersSources({
        startDate: this.startDate,
        endDate: this.endDate
      })
    }
  }
</script>
<style lang="scss" scoped></style>
