<template>
  <v-container fluid>
    <v-data-table
      dense
      :headers="headers"
      :items="applications"
      item-key="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 ml-3"
      @page-count="pageCount = $event"
      :server-items-length="totalApplications"
      :loading="isLoading"
      @update:options="handleUpdateOptions"
    >
      <template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification || errorMessage }}</v-snackbar
        >

        <v-toolbar flat>
          <v-toolbar-title>STYLIST APPLICATIONS</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="title"
            >Count: {{ $store.state.applications.totalCount }}</span
          >
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- filter by archive -->
          <v-switch hide-details v-model="showArchived" label="Show archived" />

          <!-- reset filters -->
          <v-btn
            color="secondary"
            class="mx-5"
            small
            @click="handleResetFilters"
          >
            <v-icon class="mx-1" small>mdi-reload</v-icon> Reset filters
          </v-btn>
          <v-spacer></v-spacer>

          <!-- show utm columns -->
          <v-switch
            hide-details
            v-model="showUtmColumns"
            label="Show UTM"
          ></v-switch>

          <!-- export all -->
          <v-btn
            class="mx-5"
            small
            @click="handleExportData"
            :loading="isExportingData"
          >
            <v-icon class="mx-1">mdi-download</v-icon>
            Export All
          </v-btn>
          <v-spacer></v-spacer>

          <!-- export checked -->
          <v-btn
            class="mx-5"
            small
            @click="handleExportChecked"
            :loading="isExportingChecked"
          >
            <v-icon class="mx-1">mdi-download</v-icon>
            Export Checked-Only
          </v-btn>
          <v-spacer></v-spacer>
          <!-- import -->
          <v-col cols="4" class="d-flex justify-end align-center">
            <v-file-input
              filled
              class="mt-5"
              v-model="importFile"
              accept=".csv"
              dense
              small-chips
            ></v-file-input>
            <v-btn
              :loading="isSending"
              small
              class="ml-3"
              :disabled="!importFile"
              @click="handleImportSourceSalons"
            >
              <v-icon class="mx-1">mdi-upload</v-icon>
              Import Distance</v-btn
            >
          </v-col>
          <!-- ADMIN EDIT POP UP -->
          <EditApplicationPopUp
            v-if="editedItem && editedItem.account"
            :show="dialog"
            :application="editedItem"
            :stylist="editedItem.account"
            :messages="st_h.allMessages"
            :first-name="st_h.firstName"
            :last-name="st_h.lastName"
            :email="st_h.email"
            @close="close"
            @update="handleUpdate"
          />
        </v-toolbar>
      </template>

      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="handleFilterChange($event, header)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td></tr
      ></template>

      <!-- tags -->
      <template v-slot:item.tags="{ item }">
        <v-chip
          x-small
          class="ma-2"
          :color="
            tag === ApplicationTags.DUPLICATE_PHONE ||
            tag === ApplicationTags.DUPLICATE_ADDRESS
              ? 'red'
              : 'orange'
          "
          label
          text-color="white"
          v-for="tag in item.tags"
          :key="tag"
          ><v-icon x-small left> mdi-label </v-icon
          >{{
            tag === ApplicationTags.DUPLICATE_PHONE
              ? 'Dupe Phone'
              : tag === ApplicationTags.DUPLICATE_ADDRESS
              ? 'Dupe Address'
              : tag
          }}</v-chip
        >
      </template>
      <!-- systems -->
      <template v-slot:item.system="{ item }">
        <v-chip
          x-small
          class="ma-2"
          color="pink"
          label
          text-color="white"
          v-for="s in item.system"
          :key="s"
          ><v-icon x-small left> mdi-label </v-icon>{{ s }}</v-chip
        >
      </template>

      <!-- to export -->
      <template v-slot:item.toExport="{ item }">
        <!-- this field is not saved to db - local state -->
        <v-checkbox
          small
          plain
          v-model="item.toExport"
          @click="() => saveIdToLS(item.id, item.toExport)"
        />
      </template>

      <!-- archive -->
      <template v-slot:item.archiveBtn="{ item }">
        <v-btn
          small
          plain
          v-if="!item.archive"
          @click="handleArchive(item, true)"
          ><v-icon>mdi-archive-arrow-down</v-icon></v-btn
        >
        <v-btn plain small v-else @click="handleArchive(item, false)"
          ><v-icon>mdi-archive-arrow-up-outline</v-icon></v-btn
        >
      </template>

      <template v-slot:item.archive="{ item }">
        <v-chip small dark :color="item.archive ? 'grey' : 'green'">{{
          item.archive ? 'Yes' : 'No'
        }}</v-chip>
      </template>

      <!-- distance -->
      <template v-slot:item.noSSalonsCloserThan8Miles="{ item }">
        <v-chip
          small
          dark
          :color="item.noSSalonsCloserThan8Miles ? 'green' : 'red'"
          >{{ item.noSSalonsCloserThan8Miles ? 'Yes' : 'No' }}</v-chip
        >
      </template>
      <template v-slot:item.noASalonsCloserThan4Miles="{ item }">
        <v-chip
          small
          dark
          :color="item.noASalonsCloserThan4Miles ? 'green' : 'red'"
          >{{ item.noASalonsCloserThan4Miles ? 'Yes' : 'No' }}</v-chip
        >
      </template>
      <template v-slot:item.noBSalonsCloserThan1Mile="{ item }">
        <v-chip
          small
          dark
          :color="item.noBSalonsCloserThan1Mile ? 'green' : 'red'"
          >{{ item.noBSalonsCloserThan1Mile ? 'Yes' : 'No' }}</v-chip
        >
      </template>
      <template v-slot:item.noCSalonsCloserThan1Mile="{ item }">
        <v-chip
          small
          dark
          :color="item.noCSalonsCloserThan1Mile ? 'green' : 'red'"
          >{{ item.noCSalonsCloserThan1Mile ? 'Yes' : 'No' }}</v-chip
        >
      </template>

      // ct clicked
      <template v-slot:item.lbClicked="{ item }">
        <v-chip
          small
          dark
          :color="item.lbClicked === 'yes' ? 'grey' : 'green'"
          >{{ item.lbClicked }}</v-chip
        >
      </template>
      <template v-slot:item.fsClicked="{ item }">
        <v-chip
          small
          dark
          :color="item.fsClicked === 'yes' ? 'grey' : 'green'"
          >{{ item.fsClicked }}</v-chip
        >
      </template>
      <template v-slot:item.mlClicked="{ item }">
        <v-chip
          small
          dark
          :color="item.mlClicked === 'yes' ? 'grey' : 'green'"
          >{{ item.mlClicked }}</v-chip
        >
      </template>
      <template v-slot:item.taClicked="{ item }">
        <v-chip
          small
          dark
          :color="item.taClicked === 'yes' ? 'grey' : 'green'"
          >{{ item.taClicked }}</v-chip
        >
      </template>

      <!-- final status page link -->
      <template v-slot:item.fspBtn="{ item }">
        <v-btn small plain @click="handleFSP(item)"
          ><v-icon>mdi-account</v-icon></v-btn
        >
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mx-3" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:item.updatedAt="{ item }">
        {{ item.updatedAt | localeDate(true) }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | localeDate(true) }}
      </template>
      <template v-slot:item.exportedDate="{ item }">
        <template v-if="item.exportedDate">{{
          item.exportedDate | localeDate(true)
        }}</template>
      </template>
    </v-data-table>
    <!-- NOTE: PAGINATION -->
    <div
      class="text-center pt-2"
      v-if="applications && applications.length > 0"
    >
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
  import FilterField from '@/components/FilterField.vue'
  import { ApplicationTags, MessageFrom, SystemOptions } from '@/config/enums'
  import { getPendingApplications } from '@/config/tables.helper'
  import { mapActions } from 'vuex'
  import EditApplicationPopUp from './EditApplicationPopUp.vue'

  export default {
    components: {
      EditApplicationPopUp,
      FilterField
    },
    data() {
      return {
        showUtmColumns: false,
        MessageFrom,
        ApplicationTags,
        showArchived: true,
        isSending: false,
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        // pagintaion
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        // sorting
        sortBy: 'createdAt',
        sortDesc: true,
        // other
        isExportingData: false,
        isExportingChecked: false,
        filters: getPendingApplications(this).filters,
        headers: [],
        notification: '',
        isLoading: false,
        dialog: false,
        editedIndex: -1,
        editedItem: getPendingApplications(this).item,
        defaultItem: getPendingApplications(this).item,
        SystemOptions,
        importFile: null
      }
    },
    computed: {
      editedItemToExport() {
        return this.editedItem?.toExport
      },
      // hasMessageTag() {
      //   return (
      //     this.editedItem.tags &&
      //     this.editedItem.tags.includes(this.ApplicationTags.MESSAGE)
      //   )
      // },
      stylistId() {
        return this.editedItem?.account?._id
      },
      applications() {
        const idsForExportLS = JSON.parse(
          localStorage.getItem('idsForExport') || '[]'
        )
        return this.$store.state.applications.list.map(a => {
          return {
            ...a,
            toExport: idsForExportLS.includes(a.id)
          }
        })
      },
      totalApplications() {
        return this.$store.state.applications.totalCount
      },
      st_h() {
        return this.$store.state.messages.st_h
      }
    },
    methods: {
      ...mapActions([
        'fetchApplicationsTableData',
        'getApplicationsCsv',
        'editApplication',
        'importSourceSalons',
        'fetch_st_h',
        'calculateStatuses'
      ]),
      saveIdToLS(id, checked) {
        const idsForExportLS = JSON.parse(
          localStorage.getItem('idsForExport') || '[]'
        )
        if (checked && !idsForExportLS.includes(id)) idsForExportLS.push(id)
        if (!checked && idsForExportLS.includes(id))
          idsForExportLS.splice(idsForExportLS.indexOf(id), 1)

        localStorage.setItem('idsForExport', JSON.stringify(idsForExportLS))
      },
      async handleUpdate() {
        await this.updateData()
      },
      async handleArchive(application, toArchive) {
        await this.editApplication({ _id: application._id, archive: toArchive })
        await this.updateData()
      },
      handleFSP(application) {
        // Get the URL for the desired route
        const routeUrl = this.$router.resolve({
          name: 'StylistFinalStatusAdmin',
          params: {
            applicationId: application._id,
            stylistId: application.stylistId
          }
        }).href
        // Open the route in a new tab
        window.open(routeUrl, '_blank')
      },
      async handleImportSourceSalons() {
        if (!this.importFile) return

        this.isSending = true
        await this.importSourceSalons(this.importFile)
        if (this.$store.state.sourcesalons.error) {
          this.errorMessage = this.$store.state.stylistsAccount.error
          this.snackbarColor = 'red'
          this.snackbar = true
        } else {
          this.snackbarColor = 'green'
          this.errorMessage = 'Salons imported.'
          this.snackbar = true
        }
        this.importFile = null
        this.isSending = false
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        if (this.$route.name !== 'pending-applications') {
          this.$router.push({ name: 'pending-applications' })
        }
      },
      editItem(item) {
        // Get the URL for the desired route
        const routeUrl = this.$router.resolve({
          name: 'pending-application',
          params: { applicationId: item.id }
        }).href
        // Open the route in a new tab
        window.open(routeUrl, '_blank')

        // this.editedIndex = this.applications.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        // this.dialog = true
        // this.$router.push({
        //   name: 'pending-application',
        //   params: { applicationId: item.id }
        // })
      },
      async handleUpdateOptions(options) {
        this.sortBy = options.sortBy[0] ? options.sortBy[0] : 'createdAt'
        this.sortDesc = options.sortDesc
        await this.updateData()
      },
      async updateData() {
        this.isLoading = true

        // const promises = this.applications.map(async app => {
        //   return await this.calculateStatuses({ id: app.id })
        // })

        // await Promise.all(promises)

        const options = {
          page: this.page,
          limit: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          search:
            this.$route.name === 'pending-application'
              ? { ...this.filters, id: this.$route.params.applicationId }
              : this.filters,
          status: 'pending'
        }

        await this.fetchApplicationsTableData(options)
        this.pageCount = Math.ceil(
          this.$store.state.applications.totalCount / this.itemsPerPage
        )
        if (this.$route.name === 'pending-application') {
          this.filters['id'] = this.$route.params.applicationId
          const item = this.applications.find(
            item => item.id === this.$route.params.applicationId
          )

          this.editedItem = Object.assign({}, item)
          this.editedIndex = this.applications.indexOf(item)
          this.dialog = true
        }
        this.isLoading = false
      },
      async handleExportData() {
        this.isExportingData = true
        let transform = {}
        getPendingApplications(this)
          .headers.concat(getPendingApplications(this).additionalHeaders)
          .forEach(h => {
            if (h.csv !== false) {
              transform[h.text] = h.value
            }
          })
        const data = await this.getApplicationsCsv({
          data: { transform },
          status: 'pending'
        })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'applications.csv')
        this.isExportingData = false
      },
      async handleExportChecked() {
        this.isExportingChecked = true
        let transform = {}
        getPendingApplications(this).headers.forEach(h => {
          if (h.csv !== false) {
            transform[h.text] = h.value
          }
        })
        const idsForExportLS = JSON.parse(
          localStorage.getItem('idsForExport') || '[]'
        )
        const data = await this.getApplicationsCsv({
          data: { transform, ids: idsForExportLS, toUpdate: true },
          status: 'pending'
        })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'applications.csv')
        this.isExportingChecked = false
        this.updateData()
      },
      async handleFilterChange(value, header) {
        this.page = 1
        this.filters[header.value] = value
        await this.updateData()
      },
      handleResetFilters() {
        this.filters = getPendingApplications(this).filters

        this.page = 1

        this.updateData()
      }
    },
    watch: {
      dialog(val) {
        val || this.close()
      },
      showUtmColumns() {
        if (this.showUtmColumns) {
          this.headers = getPendingApplications(this).headers.concat(
            getPendingApplications(this).additionalHeaders
          )
        } else {
          this.headers = getPendingApplications(this).headers
        }
      },
      async showArchived(newValue, oldValue) {
        if (newValue) delete this.filters.archive
        else this.filters.archive = 'false'
        await this.updateData()
      },
      stylistId() {
        if (this.stylistId) this.fetch_st_h({ stylistId: this.stylistId })
      },
      async page(newValue, oldValue) {
        await this.updateData()
      }
    },
    async created() {
      localStorage.removeItem('idsForExport')

      // redirect to example pages if user is not full (admin with no stylists access)
      if (!this.$store.getters.isUserFull)
        this.$router.push({ name: 'ExamplePages' })

      this.headers = getPendingApplications(this).headers
      // if (this.stylistId) this.fetch_st_h({ stylistId: this.stylistId })
    },
    destroyed() {
      localStorage.removeItem('idsForExport')
    }
  }
</script>
