<template>
  <v-container fluid class="px-0">
    <!-- salons table -->
    <v-data-table
      v-if="items"
      dense
      :headers="headers"
      :items="items"
      item-key="salonId"
      class="elevation-1 pt-3"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Advanced analytics by salon</v-toolbar-title>
          <v-row align="center">
            <v-spacer></v-spacer>
            <v-col cols="2" class="mb-3">
              <v-btn block @click="handleExportData" :loading="isExportingData">
                <v-icon class="mx-1">mdi-download</v-icon>
                Export CSV
              </v-btn></v-col
            >
            <v-col cols="5" sm="5" md="5">
              <v-select
                v-model="searchSystem"
                :items="allSystemOptions"
                label="System"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row> </v-toolbar
      ></template>
      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="value => (filters[header.value] = value)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td>
        </tr>
      </template>
      <template v-slot:item.consultationPercentage="{ item }">
        <span v-if="item.consultationPercentage"
          >{{ item.consultationPercentage.toFixed(2) }}%</span
        >
        <span v-else></span>
      </template>
      <template v-slot:item.applicationPercentage="{ item }">
        <span v-if="item.applicationPercentage"
          >{{ item.applicationPercentage.toFixed(2) }}%</span
        >
        <span v-else></span>
      </template>
      <template v-slot:item.orders="{ item }">
        <span v-if="item.orders">${{ item.orders.toFixed(2) }}</span>
        <span v-else></span>
      </template>
      <template v-slot:item.ordersAvg="{ item }">
        <span v-if="item.ordersAvg">${{ item.ordersAvg.toFixed(2) }}</span>
        <span v-else></span>
      </template>
      <template v-slot:item.contactRate="{ item }">
        <span v-if="item.contactRate">{{ item.contactRate.toFixed(2) }}%</span>
        <span v-else></span>
      </template>
    </v-data-table>

    <!-- note: totals table -->
    <v-data-table
      v-if="totals"
      :loading="isLoading"
      dense
      class="elevation-1 pt-3"
      :headers="headersTotal"
      :items="totals"
      disable-sort
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.contactRateTotal="{ item }">
        <span v-if="item.contactRateTotal"
          >{{ item.contactRateTotal.toFixed(2) }}%</span
        >
        <span v-else></span>
      </template>
      <template v-slot:item.consultationPercentageAvg="{ item }">
        <span v-if="item.consultationPercentageAvg"
          >{{ item.consultationPercentageAvg.toFixed(2) }}%</span
        >
        <span v-else></span>
      </template>
      <template v-slot:item.applicationPercentageAvg="{ item }">
        <span v-if="item.applicationPercentageAvg"
          >{{ item.applicationPercentageAvg.toFixed(2) }}%</span
        >
        <span v-else></span>
      </template>
      <template v-slot:item.ordersTotal="{ item }">
        <span v-if="item.ordersTotal">${{ item.ordersTotal.toFixed(2) }}</span>
        <span v-else></span>
      </template>
      <template v-slot:item.ordersAvg="{ item }">
        <span v-if="subtotalCount > 0 && item.salonAvg > 0"
          >${{ (item.ordersTotal / subtotalCount).toFixed(2) }}
          <!-- (${{
            item.salonAvg.toFixed(2)
          }}) -->
        </span>
        <span v-else></span>
      </template>
    </v-data-table>
    <div v-else class="d-flex justify-center align-center">
      <v-progress-circular
        indeterminate
        :size="70"
        :width="7"
        color="purple"
      ></v-progress-circular>
    </div>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { AdditionalSystemOptions, SystemOptions } from '@/config/enums'
  import { saveAs } from 'file-saver'
  import { getSalonAdvancedTableInfo } from '@/config/tables.helper'
  import FilterField from '@/components/FilterField.vue'

  export default {
    components: {
      FilterField
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true,
        searchSystem: '',
        filters: getSalonAdvancedTableInfo(this).filters,
        headers: getSalonAdvancedTableInfo(this).headers,
        isExportingData: false,
        headersTotal: [
          {
            text: '',
            align: 'start',
            value: 'info',
            width: 700
          },
          {
            text: 'Total Referrals',
            align: 'start',
            value: 'referralsTotal',
            width: 150
          },
          // {
          //   text: 'Total Messages',
          //   align: 'start',
          //   value: 'messagesTotal',
          //   width: 150
          // },
          // {
          //   text: 'Total Calls',
          //   align: 'start',
          //   value: 'callsTotal',
          //   width: 150
          // },
          {
            text: 'Total Consumers Contacted',
            align: 'start',
            value: 'consumersContactedTotal',
            width: 150
          },
          {
            text: 'Contact Rate',
            align: 'start',
            value: 'contactRateTotal',
            width: 150
          },
          {
            text: 'Total Consultations',
            align: 'start',
            value: 'consultationsTotal',
            width: 150
          },
          {
            text: 'Average Consultation %',
            align: 'start',
            value: 'consultationPercentageAvg',
            width: 150
          },
          {
            text: 'Total Applications',
            align: 'start',
            value: 'applicationsTotal',
            width: 150
          },
          {
            text: 'Average Application %',
            align: 'start',
            value: 'applicationPercentageAvg',
            width: 150
          },
          {
            text: '$ Total Orders',
            align: 'start',
            value: 'ordersTotal',
            width: 150
          },
          {
            // text: '$ Order Avg (Salon Avg)',
            text: '$ Order Avg',
            align: 'start',
            value: 'ordersAvg',
            width: 150
          }
        ]
      }
    },
    computed: {
      subtotalCount() {
        return this.$store.state.analytics?.subtotalCount
      },
      totals() {
        const totals = [
          {
            info: 'Total',
            referralsTotal: 0,
            // messagesTotal: 0,
            // callsTotal: 0,
            consumersContactedTotal: 0,
            contactRateTotal: 0,
            consultationsTotal: 0,
            consultationPercentageTotal: 0,
            consultationPercentageAvg: 0,
            applicationsTotal: 0,
            applicationPercentageTotal: 0,
            applicationPercentageAvg: 0,
            ordersTotal: this.$store.state.analytics.consultationsSubtotalSum,
            // ordersAvgTotal: this.$store.state.analytics.consultationsSubtotalAvg

            salonAvg: this.$store.state.analytics.consultationsSubtotalAvg,
            // hasApplications: 0,
            hasOrderSubtotal: 0
          }
        ]

        if (this.items) {
          this.items.forEach(item => {
            totals[0].referralsTotal += item.referrals ? item.referrals : 0
            // totals[0].messagesTotal += item.messages ? item.messages : 0
            // totals[0].callsTotal += item.calls ? item.calls : 0
            totals[0].consumersContactedTotal += item.consumersContacted
              ? item.consumersContacted
              : 0
            totals[0].consultationsTotal += item.consultations
              ? item.consultations
              : 0
            totals[0].consultationPercentageTotal += item.consultationPercentage
              ? item.consultationPercentage
              : 0
            totals[0].applicationsTotal += item.applications
              ? item.applications
              : 0
            totals[0].applicationPercentageTotal += item.applicationPercentage
              ? item.applicationPercentage
              : 0
            // totals[0].ordersTotal += item.orders ? item.orders : 0

            totals[0].hasOrderSubtotal += item.orders > 0 ? 1 : 0

            // totals[0].ordersAvgTotal += item.ordersAvg ? item.ordersAvg : 0
            // totals[0].hasApplications +=
            //   item.applications > 0 ? item.applications : 0
          })
        }
        if (totals[0].consultationPercentageTotal > 0) {
          totals[0].consultationPercentageAvg =
            totals[0].consultationPercentageTotal / totals[0].consultationsTotal
        }
        if (totals[0].applicationPercentageTotal > 0) {
          totals[0].applicationPercentageAvg =
            totals[0].applicationPercentageTotal / totals[0].applicationsTotal
        }
        // if (totals[0].ordersAvgTotal > 0) {
        //   totals[0].ordersAvg =
        //     totals[0].ordersTotal / totals[0].hasOrderSubtotal
        // }

        totals[0].contactRateTotal =
          (totals[0].consumersContactedTotal / totals[0].referralsTotal) * 100

        return totals
      },
      items() {
        if (!this.$store.state.analytics.salonsAnalyticsAdvanced) {
          return []
        }
        return this.$store.state.analytics.salonsAnalyticsAdvanced.map(item => {
          let consultationPercentage = null
          let applicationPercentage = null
          let ordersAvg = null

          if (item.consultations > 0)
            consultationPercentage =
              (item.consultations / item.consumersContacted) * 100

          if (item.applications > 0)
            applicationPercentage =
              (item.applications / item.consultations) * 100

          if (item.orders > 0) ordersAvg = item.orders / item.applications

          const contactRate = (item.consumersContacted / item.referrals) * 100
          return {
            ...item,
            consultationPercentage,
            applicationPercentage,
            ordersAvg,
            contactRate
          }
        })
      },
      allSystemOptions() {
        return [
          ...Object.values(SystemOptions),
          ...Object.values(AdditionalSystemOptions)
        ]
      }
    },
    methods: {
      ...mapActions(['fetchSalonsAnalyticsAdvanced', 'fetchSubotalsCount']),
      async updateData() {
        await Promise.all([
          this.fetchSalonsAnalyticsAdvanced({
            startDate: this.startDate,
            endDate: this.endDate,
            system: this.searchSystem
          }),
          await this.fetchSubotalsCount({
            startDate: this.startDate,
            endDate: this.endDate,
            system: this.searchSystem
          })
        ])
      },
      async handleExportData() {
        const items = []
        this.items.forEach(i => {
          const item = {}
          this.headers.forEach(h => {
            if (h.csv !== false) {
              if (!i[h.value]) {
                item[h.text] = ''
              } else if (
                h.value === 'consultationPercentage' ||
                h.value === 'applicationPercentage' ||
                h.value === 'contactRate'
              ) {
                item[h.text] = i[h.value]?.toFixed(2) + '%'
              } else if (h.value === 'orders' || h.value === 'ordersAvg') {
                item[h.text] = i[h.value]?.toFixed(2)
              } else {
                item[h.text] = i[h.value]
              }
            }
          })
          items.push(item)
        })

        const separator = ','
        const keys = Object.keys(items[0])
        const csvData =
          keys.join(separator) +
          '\n' +
          items
            .map(item => {
              return keys
                .map(key => {
                  return item[key]
                })
                .join(separator)
            })
            .join('\n')
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
        saveAs(blob, 'salons-advanced-analytics.csv')
      }
    },
    watch: {
      async startDate() {
        this.isLoading = true
        await this.updateData()
        this.isLoading = false
      },
      async endDate() {
        this.isLoading = true
        await this.updateData()
        this.isLoading = false
      },
      async searchSystem() {
        this.isLoading = true
        await this.updateData()
        this.isLoading = false
      }
    },
    async created() {
      this.headers = getSalonAdvancedTableInfo(this).headers
      await this.updateData()
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped></style>
