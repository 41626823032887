<template>
  <div>
    <!-- note: title -->
    <v-card-title class="pt-0 pl-0 pl-sm-5 ml-5 mb-1 breakword">
      <span class="text-h5"
        >How do you want to be notified when the Salon replies?</span
      >
    </v-card-title>
    <v-card-text class="py-0 pb-5 px-5">
      <v-row class="justify-center px-0 px-sm-5 pb-5 mb-5">
        <v-card-text class="text-center">Choose one</v-card-text>

        <v-col class="d-flex justify-center flex-column align-center" cols="6">
          <div
            @click="handleSelect('email')"
            :class="answer === 'email' ? 'custom-btn-selected' : 'custom-btn'"
          >
            <v-icon color="white" v-if="answer === 'email'" x-large
              >mdi-email-outline</v-icon
            >
            <v-icon v-else x-large>mdi-email</v-icon>
            <span
              class="subtitle-1"
              :class="
                answer === 'email'
                  ? 'custom-btn-selected__main-txt'
                  : 'custom-btn__main-txt'
              "
              >Email only</span
            >
          </div>
        </v-col>
        <v-col class="d-flex justify-center" cols="6">
          <div
            :class="
              answer === 'emailAndText' ? 'custom-btn-selected' : 'custom-btn'
            "
            @click="handleSelect('emailAndText')"
          >
            <div class="d-flex" v-if="answer === 'emailAndText'">
              <v-icon color="white" large>mdi-email-outline</v-icon>
              <v-icon color="white" large>mdi-message-text-outline</v-icon>
            </div>
            <div class="d-flex" v-else>
              <v-icon large>mdi-email</v-icon>
              <v-icon large>mdi-message-text</v-icon>
            </div>
            <span
              class="subtitle-1"
              :class="
                answer === 'emailAndText'
                  ? 'custom-btn-selected__main-txt'
                  : 'custom-btn__main-txt'
              "
              >Email & Text</span
            >
          </div>
        </v-col></v-row
      >
    </v-card-text>
  </div>
</template>

<script>
  export default {
    props: {
      sendingData: {
        type: Object,
        required: true
      }
    },
    computed: {
      answer() {
        return this.sendingData[2]
      }
    },
    methods: {
      handleSelect(answer) {
        this.$emit('onDataChange', { step: 2, data: answer, goNext: true })
      }
    }
  }
</script>

<style scoped lang="scss">
  .custom-btn {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #cecece;
    height: 100px;
    width: 100%;
    transition: all 0.28s;

    &:hover {
      background-color: #a3a3a3;
    }

    &__main-txt {
      color: #000;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }
  }

  .custom-btn-selected {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background-color: #2b2b2b;
    transition: all 0.28s;

    &:hover {
      background-color: #5e5e5e;
    }

    &__main-txt {
      color: #fff;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }
  }
</style>
