<template>
  <v-container
    v-if="!$store.state.salons.loading && $store.state.salons.selectedSalon"
  >
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="400" class="mx-auto mt-5">
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>{{ titleText }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="pa-5 text-center">{{
        isFromWF5 ? 'Please log in' : 'Please log in to see it'
      }}</v-card-text>
      <v-card-text class="pa-5 pt-0 d-flex flex-column">
        <v-btn
          text
          small
          class="text-capitalize pr-0 align-self-end"
          color="#19b3cc"
          @click="handleNotYou"
          >Not you?</v-btn
        >
        <v-text-field
          prepend-icon="mdi-email"
          :rules="[rules.required, rules.email]"
          disabled
          label="Salon Email"
          :value="email"
          class="pt-0 mt-0"
        />

        <v-btn
          text
          small
          class="text-capitalize pr-0 align-self-end mb-0 pb-0"
          color="#19b3cc"
          @click="handleForgotPassword"
          >Forgot Password?</v-btn
        >
        <v-text-field
          :rules="[rules.required]"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          class="pt-0 mt-0"
        />
        <v-checkbox
          label="Keep me logged in on this device"
          v-model="keepLogged"
        />
        <v-card-actions>
          <v-btn
            block
            color="user_primary"
            class="white--text"
            rounded
            :disabled="disableBtn"
            @click="handleLogin"
            :loading="this.$store.state.auth.loading"
          >
            Log In</v-btn
          >
        </v-card-actions>
        <!-- <v-card-text class="my-0 py-0 text-center">
          Don't have account?
        </v-card-text>
        <v-btn
          plain
          link
          class="text-capitalize pa-0 ma-0"
          color="#19b3cc"
          @click="handleSignUp"
          >Sign Up</v-btn
        > -->
      </v-card-text>
    </v-card>
    <SimpleModal
      max-width="400"
      :showDialog="showSimpleModal"
      title="Contact Us"
      :message="contactText"
      @closeSimpleModal="closeSimpleModal"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { UserRoles } from '../../../config/enums'
  export default {
    data() {
      return {
        UserRoles,
        keepLogged: false,
        password: '',
        rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },
        showPassword: false,
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red',
        showSimpleModal: false,
        contactText:
          'If you have further questions or need immediate assistance, please feel free to contact us at <a href="mailto:hairdreams@bht-usa.com?subject=Salon Finder Assistance Needed">hairdreams@bht-usa.com</a>.'
      }
    },
    computed: {
      isFromWF5() {
        return this.$route.query.from.includes('edit')
      },
      isFromWF3() {
        return this.$route.query.from.includes('new-inquiry')
      },
      titleText() {
        if (this.isFromWF3) return 'You have a new Hairdreams Inquiry'
        else if (this.isFromWF5) return 'Account page'
        else return 'Hairdreams Partner Account'
      },
      email() {
        return this.$store.state?.salons?.selectedSalon?.email
      },
      disableBtn() {
        return !this.email || !this.password
      }
    },
    methods: {
      ...mapActions(['fetchSalon', 'login', 'forgotPasswordSalon']),
      async handleForgotPassword() {
        await this.forgotPasswordSalon({
          email: this.email,
          salonId: this.$route.params.salonId
        })
        this.$router.push({
          name: 'ForgotPasswordSalon',
          params: { salonId: this.$route.params.salonId },
          query: { from: this.$route.query.from }
        })
      },
      // handleSignUp() {
      //   this.$router.push({
      //     name: 'SignupSalon',
      //     params: { salonId: this.$route.params.salonId },
      //     query: { from: this.$route.query.from }
      //   })
      // },
      closeSimpleModal() {
        this.showSimpleModal = false
      },
      handleNotYou() {
        this.showSimpleModal = true
      },
      async handleLogin() {
        const userData = {
          email: this.email,
          password: this.password,
          role: UserRoles.SALON
        }

        await this.login(userData)

        if (!this.$store.state.auth.error) {
          if (this.$route.query.from) {
            this.$router.replace(this.$route.query.from)
          } else {
            this.$router.push('/')
          }
        } else {
          this.errorMessage = this.$store.state.auth.error
          this.snackbar = true
        }
      }
    },
    async created() {
      await this.fetchSalon(this.$route.params.salonId)
      if (!this.$store.state.salons?.selectedSalon?.user?._id) {
        this.$router.replace({
          name: 'SignupSalon',
          params: { salonId: this.$route.params.salonId },
          query: { from: this.$route.query.from }
        })
      }
    }
  }
</script>
