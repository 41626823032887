<template>
  <v-dialog
    v-model="show"
    max-width="1000px"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-snackbar
        centered
        top
        v-model="snackbar"
        :color="snackbarColor"
        :timeout="snackbarTimeout"
        >{{ notification }}</v-snackbar
      >
      <v-toolbar dark color="primary">
        <v-toolbar-title class="mx-auto">Edit Application</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="px-0 px-sm-3">
        <v-container>
          <!--  ID -->
          <input v-model="application.id" type="hidden" />

          <!-- note: BASIC INFORMATION -->
          <template v-if="stylist">
            <v-card-title
              class="text-body-1 text-sm-h5 font-weight-bold d-flex justify-center"
              >BASIC INFORMATION</v-card-title
            >
            <v-row class="px-2">
              <!-- salon name -->
              <v-col cols="6" md="3" class="pb-0 px-1 px-sm-3">
                <div class="info-box">
                  <div class="info-label text-caption text-sm-body-1">
                    Salon name:
                  </div>
                  <div class="info-value text-caption text-sm-body-1">
                    <strong>{{ stylist.salon }}</strong>
                  </div>
                </div>
              </v-col>

              <!-- primary system -->
              <v-col cols="6" md="3" class="pb-0 px-1 px-sm-3">
                <div class="info-box">
                  <div class="info-label text-caption text-sm-body-1">
                    Primary system:
                  </div>
                  <div class="info-value text-caption text-sm-body-1">
                    <strong>{{ application.primarySystem }}</strong>
                  </div>
                </div>
              </v-col>

              <!-- phone -->
              <v-col cols="6" md="3" class="pb-0 px-1 px-sm-3">
                <div class="info-box">
                  <div class="info-label text-caption text-sm-body-1">
                    Salon phone:
                  </div>
                  <div class="info-value text-caption text-sm-body-1">
                    <strong>{{ stylist.phone }}</strong>
                  </div>
                </div>
              </v-col>

              <!-- email -->
              <v-col cols="6" md="3" class="pb-0 px-1 px-sm-3">
                <div class="info-box">
                  <div class="info-label text-caption text-sm-body-1">
                    Salon email:
                  </div>
                  <div class="info-value text-caption text-sm-body-1">
                    <strong>{{ application.email }}</strong>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>

          <!-- note: RECENT CHANGES & MEMO -->

          <template>
            <v-row class="mt-6 px-3 px-sm-4">
              <v-col cols="12" md="6" class="recent-changes">
                <template
                  v-if="application.tags && application.tags.length > 0"
                >
                  <v-card-title
                    class="text-body-1 text-sm-h5 justify-center d-flex pb-0"
                    >Recent Changes</v-card-title
                  ></template
                >
                <v-col cols="12" v-for="tag in application.tags" :key="tag">
                  <v-expansion-panels
                    v-if="
                      tag !== ApplicationTags.LICENSE &&
                      tag !== ApplicationTags.DISTANCE &&
                      tag !== ApplicationTags.MESSAGE &&
                      tag !== ApplicationTags.DUPLICATE_PHONE &&
                      tag !== ApplicationTags.DUPLICATE_ADDRESS
                    "
                    accordion
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        color="orange"
                        class="white--text text-caption text-sm-body-2"
                        >{{ tag }}</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="orange">
                        <v-card-actions class="d-flex justify-end">
                          <v-btn
                            @click="handleMarkAsDone(tag)"
                            :loading="isSaving"
                            small
                            >Mark as done</v-btn
                          >
                        </v-card-actions>
                      </v-expansion-panel-content></v-expansion-panel
                    ></v-expansion-panels
                  >

                  <v-expansion-panels
                    v-if="tag === ApplicationTags.LICENSE"
                    accordion
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        disable
                        hide-actions
                        color="orange"
                        class="white--text text-caption text-sm-body-2"
                        >{{
                          stylist.cosmetologyLicenseFile
                            ? 'License: Needs review'
                            : 'License: No file'
                        }}</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="orange">
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <v-expansion-panels
                    v-if="tag === ApplicationTags.DISTANCE"
                    accordion
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        hide-actions
                        disabled
                        color="orange"
                        class="white--text text-caption text-sm-body-2"
                        >{{ tag }}</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="orange">
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <v-expansion-panels
                    v-if="tag === ApplicationTags.MESSAGE"
                    accordion
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        hide-actions
                        disabled
                        color="orange"
                        class="white--text text-caption text-sm-body-2"
                        >{{ tag }}</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="orange">
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- duplicate phone -->
                  <v-expansion-panels
                    v-if="tag === ApplicationTags.DUPLICATE_PHONE"
                    accordion
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        disable
                        hide-actions
                        color="orange"
                        class="white--text text-caption text-sm-body-2"
                        >{{ tag }}</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="orange">
                        <v-card-actions class="d-flex justify-end">
                          <v-btn
                            :dark="
                              application.adminSetDuplicatePhone === 'Dupe'
                            "
                            @click="
                              handleDuplicate('adminSetDuplicatePhone', 'Dupe')
                            "
                            small
                            >Dupe</v-btn
                          >
                          <v-btn
                            :dark="
                              application.adminSetDuplicatePhone ===
                              'Note a dupe'
                            "
                            @click="
                              handleDuplicate(
                                'adminSetDuplicatePhone',
                                'Note a dupe'
                              )
                            "
                            small
                            >Note a dupe</v-btn
                          >
                        </v-card-actions>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- duplicate address -->
                  <v-expansion-panels
                    v-if="tag === ApplicationTags.DUPLICATE_ADDRESS"
                    accordion
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        disable
                        hide-actions
                        color="orange"
                        class="white--text text-caption text-sm-body-2"
                        >{{ tag }}</v-expansion-panel-header
                      >
                      <v-expansion-panel-content color="orange">
                        <v-card-actions class="d-flex justify-end">
                          <v-btn
                            :dark="
                              application.adminSetDuplicateAddress === 'Dupe'
                            "
                            @click="
                              handleDuplicate(
                                'adminSetDuplicateAddress',
                                'Dupe'
                              )
                            "
                            small
                            >Dupe</v-btn
                          >
                          <v-btn
                            :dark="
                              application.adminSetDuplicateAddress ===
                              'Note a dupe'
                            "
                            @click="
                              handleDuplicate(
                                'adminSetDuplicateAddress',
                                'Note a dupe'
                              )
                            "
                            small
                            >Note a dupe</v-btn
                          >
                        </v-card-actions>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="pt-0 px-0 px-sm-3 pr-sm-0 d-flex justify-center align-center"
                height="100%"
              >
                <v-textarea
                  outlined
                  hide-details
                  label="Memo"
                  v-model="application.memo"
                ></v-textarea
              ></v-col>
            </v-row>
          </template>

          <!-- note: STATUSES -->
          <template>
            <v-divider
              class="my-5 border-opacity-100"
              color="#1876d2"
            ></v-divider>
            <v-card-title
              class="text-body-1 text-sm-h5 font-weight-bold d-flex justify-center"
              >STATUSES</v-card-title
            >
            <v-row>
              <!-- note: ML -->
              <v-col cols="12" md="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      disable-icon-rotate
                      class="text-caption text-sm-body-2"
                    >
                      ML: {{ application.statusML }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="d-flex">
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="
                              application.forceML_Reg === 'No' &&
                              application.forceML_Cut === 'No'
                            "
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceML_Cut',
                                  value: 'No'
                                },
                                {
                                  key: 'forceML_Reg',
                                  value: 'No'
                                }
                              ])
                            "
                            >Deny</v-btn
                          ></v-col
                        >
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="application.forceML_Reg === 'Yes'"
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceML_Reg',
                                  value: 'Yes'
                                },
                                {
                                  key: 'forceML_Cut',
                                  value: 'No'
                                }
                              ])
                            "
                            >Approve Reg</v-btn
                          ></v-col
                        >
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="application.forceML_Cut === 'Yes'"
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceML_Cut',
                                  value: 'Yes'
                                },
                                {
                                  key: 'forceML_Reg',
                                  value: 'No'
                                }
                              ])
                            "
                            >Approve Cut</v-btn
                          ></v-col
                        >
                      </v-row>
                    </v-expansion-panel-content></v-expansion-panel
                  >
                </v-expansion-panels>
              </v-col>

              <!-- note: LB -->
              <v-col cols="12" md="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      disable-icon-rotate
                      class="text-caption text-sm-body-2"
                    >
                      LB: {{ application.statusLB }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="d-flex">
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="
                              application.forceLB_Full_Head === 'No' &&
                              application.forceLB_Half_Head === 'No'
                            "
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceLB_Half_Head',
                                  value: 'No'
                                },
                                {
                                  key: 'forceLB_Full_Head',
                                  value: 'No'
                                }
                              ])
                            "
                            >Deny</v-btn
                          >
                        </v-col>
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="application.forceLB_Half_Head === 'Yes'"
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceLB_Half_Head',
                                  value: 'Yes'
                                },
                                {
                                  key: 'forceLB_Full_Head',
                                  value: 'No'
                                }
                              ])
                            "
                            >Approve Half Head</v-btn
                          >
                        </v-col>
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="application.forceLB_Full_Head === 'Yes'"
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceLB_Full_Head',
                                  value: 'Yes'
                                },
                                {
                                  key: 'forceLB_Half_Head',
                                  value: 'No'
                                }
                              ])
                            "
                            >Approve Full Head</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <!-- note: FS -->
              <v-col cols="12" md="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      disable-icon-rotate
                      class="text-caption text-sm-body-2"
                    >
                      FS: {{ application.statusFS }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="d-flex">
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="
                              application.forceFS_Online === 'No' &&
                              application.forceFS_InSalon === 'No'
                            "
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceFS_Online',
                                  value: 'No'
                                },
                                {
                                  key: 'forceFS_InSalon',
                                  value: 'No'
                                }
                              ])
                            "
                            >Deny</v-btn
                          >
                        </v-col>
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="application.forceFS_InSalon === 'Yes'"
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceFS_InSalon',
                                  value: 'Yes'
                                },
                                {
                                  key: 'forceFS_Online',
                                  value: 'No'
                                }
                              ])
                            "
                            >Approve InSalon</v-btn
                          >
                        </v-col>
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="application.forceFS_Online === 'Yes'"
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceFS_Online',
                                  value: 'Yes'
                                },
                                {
                                  key: 'forceFS_InSalon',
                                  value: 'No'
                                }
                              ])
                            "
                            >Approve Online</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <!-- note: TA -->
              <v-col cols="12" md="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="text-caption text-sm-body-2"
                      disable-icon-rotate
                    >
                      TA: {{ application.statusTA }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="d-flex">
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="application.forceTA === 'No'"
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceTA',
                                  value: 'No'
                                }
                              ])
                            "
                            >Deny</v-btn
                          >
                        </v-col>
                        <v-col cols="12" class="pa-1">
                          <v-btn
                            width="100%"
                            small
                            :dark="application.forceTA === 'Yes'"
                            @click="
                              handleForceFlag([
                                {
                                  key: 'forceTA',
                                  value: 'Yes'
                                }
                              ])
                            "
                            >Approve</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>

              <!-- note: Distance -->
              <v-col cols="12" md="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="text-caption text-sm-body-2"
                      disable-icon-rotate
                    >
                      Distance:
                      {{
                        application.distanceApproved
                          ? application.distanceApproved
                          : 'Pending'
                      }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card-text class="py-0 d-flex justify-start"
                        >S: {{ application.closestDistanceS }} from
                        {{ application.closestSalonS }} (8mi)</v-card-text
                      >
                      <v-card-text class="pb-0 d-flex justify-start"
                        >A: {{ application.closestDistanceA }} from
                        {{ application.closestSalonA }} (4mi)</v-card-text
                      >
                      <v-card-text class="pb-0 d-flex justify-start"
                        >B: {{ application.closestDistanceB }} from
                        {{ application.closestSalonB }} (1mi)</v-card-text
                      >
                      <v-card-text
                        >C: {{ application.closestDistanceC }} from
                        {{ application.closestSalonC }} (1mi)</v-card-text
                      >
                      <v-card-actions class="justify-end">
                        <v-btn
                          small
                          :dark="application.distanceApproved === 'No'"
                          @click="
                            handleForceFlag([
                              {
                                key: 'distanceApproved',
                                value: 'No'
                              }
                            ])
                          "
                          >Deny</v-btn
                        >
                        <v-btn
                          small
                          :dark="application.distanceApproved === 'Yes'"
                          @click="
                            handleForceFlag([
                              {
                                key: 'distanceApproved',
                                value: 'Yes'
                              }
                            ])
                          "
                          >Approve
                        </v-btn>
                      </v-card-actions>
                    </v-expansion-panel-content></v-expansion-panel
                  >
                </v-expansion-panels>
              </v-col>

              <!-- note: License -->
              <v-col cols="12" md="4" v-if="stylist">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      disable-icon-rotate
                      class="text-caption text-sm-body-2"
                    >
                      License:
                      {{
                        application.licenseApproved === 'Yes'
                          ? 'Approved'
                          : application.licenseApproved === 'No'
                          ? 'Not approved'
                          : 'N/A'
                      }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <!-- 1. if email already in shopify, admin cannot start process -->
                      <v-card-text
                        v-if="emailAlreadyInShopify"
                        style="
                          color: red;
                          text-transform: uppercase;
                          text-align: center;
                        "
                        >Email already in shopify</v-card-text
                      >
                      <!-- 2. no email in shopify -->
                      <template v-else>
                        <!-- has license -->
                        <v-card-text
                          class="text-center"
                          v-if="stylist.cosmetologyLicenseFile"
                        >
                          <!-- 1.1. is pdf or HEIC -->
                          <a
                            v-if="isLicensePdfOrHEIC"
                            :href="stylist.cosmetologyLicenseFile"
                            target="_blank"
                            >Open File</a
                          >
                          <!-- 1.2. is image -->
                          <v-img
                            v-else
                            class="mx-auto"
                            text-align="center"
                            :src="stylist.cosmetologyLicenseFile"
                            :lazy-src="stylist.cosmetologyLicenseFile"
                          ></v-img>
                        </v-card-text>
                        <v-row class="d-flex">
                          <v-col cols="12" class="pa-1">
                            <v-btn
                              width="100%"
                              small
                              :dark="application.licenseApproved === 'No'"
                              @click="
                                handleForceFlag([
                                  {
                                    key: 'licenseApproved',
                                    value: 'No'
                                  }
                                ])
                              "
                              >Deny</v-btn
                            >
                          </v-col>
                          <v-col cols="12" class="pa-1">
                            <v-btn
                              width="100%"
                              small
                              :dark="application.licenseApproved === 'Yes'"
                              @click="
                                handleForceFlag([
                                  {
                                    key: 'licenseApproved',
                                    value: 'Yes'
                                  }
                                ])
                              "
                              >Approve</v-btn
                            >
                          </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <!-- admin added application -->
                        <v-card-title
                          class="text-subtitle-1"
                          v-if="application.adminLicense"
                          >License added by admin:</v-card-title
                        >
                        <v-card-text
                          class="text-center"
                          v-if="application.adminLicense"
                        >
                          <a
                            v-if="isAdminLicensePdf"
                            :href="stylist.cosmetologyLicenseFile"
                            target="_blank"
                            >Open file</a
                          >
                          <v-img
                            v-else
                            class="mx-auto"
                            text-align="center"
                            :src="application.adminLicense"
                            :lazy-src="application.adminLicense"
                          ></v-img>
                        </v-card-text>
                        <v-row class="justify-center">
                          <v-alert
                            v-if="imgError"
                            color="error"
                            dense
                            class="mt-2"
                            outlined
                            >Oops! The size limit for images is 5.0 MB. Reduce
                            the file size and try again.</v-alert
                          >
                          <v-col cols="12" class="d-flex justify-center">
                            <v-file-input
                              v-if="!pictureLoading"
                              ref="fileInput"
                              label="Upload new file"
                              accept="image/* .pdf"
                              :rules="pictureRules"
                              prepend-icon="mdi-camera"
                              @change="handlePictureChange(application)"
                              v-model="picture"
                            ></v-file-input>
                            <v-progress-circular
                              class="mx-auto"
                              v-else
                              indeterminate
                              color="dark"
                            ></v-progress-circular>
                          </v-col>
                        </v-row>
                      </template>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </template>

          <!-- note: CLASSES WOULD THEY BE ELIGIBLE FOR -->
          <template>
            <v-divider class="mb-3 mt-6" color="#1876d2"></v-divider>
            <v-card-title
              class="text-body-1 text-sm-h5 font-weight-bold d-flex justify-center"
              >CLASSES WOULD THEY BE ELIGIBLE FOR</v-card-title
            >
            <v-row>
              <!-- note: ML -->
              <v-col cols="12" md="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="text-caption text-sm-body-2"
                      disabled
                      hide-actions
                      disable-icon-rotate
                    >
                      Microlines Class: {{ application.mlClass }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content> </v-expansion-panel-content
                  ></v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <!-- note: LB -->
              <v-col cols="12" md="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="text-caption text-sm-body-2"
                      disabled
                      hide-actions
                      disable-icon-rotate
                    >
                      Laserbeamer Class: {{ application.lbClass }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content> </v-expansion-panel-content
                  ></v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <!-- note: FS -->
              <v-col cols="12" md="4">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="text-caption text-sm-body-2"
                      disabled
                      hide-actions
                      disable-icon-rotate
                    >
                      FreeStyler Class: {{ application.fsClass }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content> </v-expansion-panel-content
                  ></v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </template>

          <!-- note: MESSAGES -->
          <template v-if="messages && messages.length > 0 > 0">
            <v-divider
              class="my-6 border-opacity-100"
              color="#1876d2"
            ></v-divider>

            <v-row class="d-flex justify-center">
              <v-col cols="12" md="6">
                <v-card class="mt-2">
                  <v-toolbar dark color="primary" class="d-flex justify-center"
                    ><v-toolbar-title>Messages</v-toolbar-title></v-toolbar
                  >
                  <Chat
                    :messages="messages"
                    :host="MessageFrom.HD"
                    hostName="Hairdreams"
                    moderator="HairDreams"
                    :guestName="firstName + ' ' + lastName"
                    :showSend="false"
                  />
                </v-card>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-center mt-0">
              <v-col cols="12" md="6">
                <v-card-actions
                  ><v-btn
                    block
                    rounded
                    dark
                    class="btn-resetcase text-caption text-sm-body-2"
                    :loading="isSendingMessageMarkAsDone"
                    @click="handleMessageMarkAsDone"
                    v-if="messages?.length > 0"
                    >Send Email and mark as done</v-btn
                  ></v-card-actions
                ></v-col
              >
            </v-row>
          </template>

          <!-- note: SALON INFORMATION -->
          <template v-if="stylist">
            <v-divider
              class="my-6 border-opacity-100"
              color="#1876d2"
            ></v-divider>
            <v-card-title
              class="text-body-1 text-sm-h5 font-weight-bold d-flex justify-center"
              >SALON INFORMATION</v-card-title
            >
            <v-row class="px-2">
              <!--  name -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field label="Name" v-model="stylist.salon" />
              </v-col>

              <!--  primary system -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-select
                  label="Primary System"
                  v-model="stylist.primarySystem"
                  :items="Object.values(SystemOptions)"
                />
              </v-col>

              <!-- first name -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="First Name" v-model="stylist.firstName" />
              </v-col>

              <!-- last name -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="Last Name" v-model="stylist.lastName" />
              </v-col>

              <!-- USER: email (disabled) -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  disabled
                  label="Email"
                  v-model="application.email"
                />
              </v-col>

              <!--  address -->
              <v-col cols="12" md="6" lg="8" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  disabled
                  label="Address"
                  v-model="stylist.salonAddress"
                />
              </v-col>

              <!-- short address -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  label="Short Address"
                  v-model="stylist.shortSalonAddress"
                />
              </v-col>

              <!-- phone -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <VuePhoneNumberInput
                  :translations="{
                    phoneNumberLabel: 'Mobile number'
                  }"
                  show-code-on-list
                  v-model="stylist.phone"
                  no-example
                  default-country-code="US"
                  :only-countries="['US', 'CA']"
                  color="#222"
                  valid-color="#222"
                  error-color="red"
                  :size="$vuetify.breakpoint.smAndDown ? 'sm' : 'lg'"
                  :no-flags="$vuetify.breakpoint.smAndDown ? true : false"
                />
              </v-col>

              <!-- city -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="City" v-model="stylist.city" />
              </v-col>

              <!-- state -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="State" v-model="stylist.state" />
              </v-col>

              <!-- country -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-select
                  label="Country"
                  v-model="stylist.country"
                  :items="Object.values(SalonCountries)"
                />
              </v-col>

              <!-- zip code -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="Zip Code" v-model="stylist.salonZip" />
              </v-col>

              <!-- address type -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-select
                  label="Address Type"
                  v-model="stylist.addressType"
                  :items="Object.values(StylistAddressType)"
                />
              </v-col>

              <!-- mobile salon -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-select
                  label="Mobile Salon"
                  v-model="stylist.mobile"
                  :items="Object.values(StylistMobile)"
                />
              </v-col>

              <!-- salon type -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-select
                  label="Salon Type"
                  v-model="stylist.salonType"
                  :items="Object.values(StylistSalonType)"
                />
              </v-col>

              <!-- profession -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-select
                  label="Profession"
                  v-model="stylist.profession"
                  :items="Object.values(StylistProfession)"
                />
              </v-col>

              <!-- hd certified -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-select
                  label="Hairdreams Certified"
                  v-model="stylist.hdCertified"
                  :items="Object.values(StylistHdCertified)"
                />
              </v-col>

              <!-- website -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="Website" v-model="stylist.website" />
              </v-col>

              <!-- instagram -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="Instagram" v-model="stylist.instagram" />
              </v-col>

              <!-- facebook -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="Facebook" v-model="stylist.facebook" />
              </v-col>

              <!-- tik tok -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="TikTok" v-model="stylist.tikTok" />
              </v-col>

              <!-- other -->
              <v-col cols="12" md="6" lg="4" class="pb-0 px-1 px-sm-3">
                <v-text-field label="Other" v-model="stylist.other" />
              </v-col>
            </v-row>
          </template>

          <!-- note: APPLICATION INFORMATION -->
          <template v-if="application">
            <v-divider
              class="my-6 border-opacity-100"
              color="#1876d2"
            ></v-divider>
            <v-card-title
              class="text-body-1 text-sm-h5 font-weight-bold d-flex justify-center"
              >APPLICATION INFORMATION</v-card-title
            >
            <v-row class="px-2">
              <!--  all systems -->
              <v-col cols="12" md="12" lg="12" class="pb-0 px-1 px-sm-3">
                <v-select
                  hide-details
                  label="All Systems"
                  v-model="application.system"
                  :items="Object.values(SystemOptions)"
                  multiple
                  chips
                  disabled
                />
              </v-col>

              <!--  extensions -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Extensions"
                  v-model="application.extensions"
                  disabled
                />
              </v-col>

              <!--  extension brands -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Extension Brands"
                  v-model="application.extensionBrands"
                  disabled
                />
              </v-col>

              <!--  extension services frequency -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Extension Services Frequency"
                  v-model="application.extensionServicesFrequency"
                  disabled
                />
              </v-col>

              <!--  extension methods  -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Extension Methods"
                  v-model="application.extensionMethods"
                  disabled
                />
              </v-col>

              <!--  extension clients fusion  -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Extension Clients Fusion"
                  v-model="application.extensionClientsFusion"
                  disabled
                />
              </v-col>

              <!--  extension client number  -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Extension Client Number"
                  v-model="application.extensionsClientNumber"
                  disabled
                />
              </v-col>

              <!--  toppers -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Toppers"
                  v-model="application.toppers"
                  disabled
                />
              </v-col>

              <!--  toppers blend -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Toppers Blend"
                  v-model="application.toppersBlend"
                  disabled
                />
              </v-col>

              <!--  microlines application spend -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Microlines Application Spend"
                  v-model="application.microlinesApplicationSpend"
                  disabled
                />
              </v-col>

              <!--  microlines application spend changed answer -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Changed Application Spend Answer"
                  v-model="application.changedApplicationSpendAnswer"
                  disabled
                />
              </v-col>

              <!--  microlines maintentance spend -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Microlines Maintenance Spend"
                  v-model="application.microlinesMaintenanceSpend"
                  disabled
                />
              </v-col>

              <!--  microlines client number -->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Microlines Client Number"
                  v-model="application.microlinesClientNumber"
                  disabled
                />
              </v-col>

              <!--  ads-->
              <v-col cols="12" md="6" lg="6" class="pb-0 px-1 px-sm-3">
                <v-text-field
                  hide-details
                  label="Ads"
                  v-model="application.ads"
                  disabled
                />
              </v-col>
            </v-row>
          </template>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="white darken-1" @click="close"> Cancel </v-btn>
        <v-btn
          color="green darken-1"
          class="white--text"
          id="save-btn"
          dark
          @click="handleSave"
          :disabled="application && !isValid"
          :loading="isSaving"
          >Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import Chat from '@/components/user/Chat.vue'
  import {
    ApplicationTags,
    MessageFrom,
    SalonCountries,
    StylistAddressType,
    StylistHdCertified,
    StylistMobile,
    StylistProfession,
    StylistSalonType,
    SystemOptions
  } from '@/config/enums'
  import { mapActions } from 'vuex'

  export default {
    components: {
      Chat
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      application: {
        type: Object,
        required: true
      },
      stylist: {
        type: Object,
        required: true
      },
      messages: {
        type: Array
      },
      firstName: { type: String },
      lastName: { type: String },
      email: { type: String }
    },
    data() {
      return {
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'green',
        notification: '',
        SystemOptions,
        ApplicationTags,
        MessageFrom,
        SalonCountries,
        StylistAddressType,
        StylistMobile,
        StylistSalonType,
        StylistHdCertified,
        StylistProfession,
        isSendingMessageMarkAsDone: false,
        isSaving: false,
        isApproving: false,
        isDenying: false,
        picture: null,
        pictureLoading: false,
        imgError: null,
        pictureRules: [
          value =>
            !value ||
            value.size < 5000000 ||
            'Image size should be less than 5 MB!'
        ]
      }
    },
    computed: {
      isValid() {
        return !!this.application
      },
      isAdminLicensePdf() {
        return this.application.adminLicense?.includes('.pdf')
      },
      isLicensePdfOrHEIC() {
        return (
          this.stylist.cosmetologyLicenseFile?.includes('.pdf') ||
          this.stylist.cosmetologyLicenseFile?.includes('.HEIC')
        )
      },
      emailAlreadyInShopify() {
        return this.application?.account?.emailAlreadyInShopify
      }
    },
    methods: {
      ...mapActions([
        'editApplication',
        'editStylistAccount',
        'calculateStatuses',
        'addAdminLicense'
      ]),
      close() {
        this.$emit('close')
      },
      async handleSave(tag = null) {
        this.isSaving = true

        // 1. edit stylist account
        const editStylistAccountPromise = this.editStylistAccount({
          id: this.stylist._id,
          salon: this.stylist.salon,
          primarySystem: this.stylist.primarySystem,
          firstName: this.stylist.firstName,
          lastName: this.stylist.lastName,
          shortSalonAddress: this.stylist.shortSalonAddress,
          phone: this.stylist.phone,
          city: this.stylist.city,
          state: this.stylist.state,
          country: this.stylist.country,
          salonZip: this.stylist.salonZip,
          addressType: this.stylist.addressType,
          mobile: this.stylist.mobile,
          salonType: this.stylist.salonType,
          profession: this.stylist.profession,
          hdCertified: this.stylist.hdCertified,
          website: this.stylist.website,
          instagram: this.stylist.instagram,
          facebook: this.stylist.facebook,
          tikTok: this.stylist.tikTok,
          other: this.stylist.other,
          // this cannot be edited but needed for backend endpoint
          salonAddress: this.stylist.salonAddress,
          lng: this.stylist.location.coordinates[0],
          lat: this.stylist.location.coordinates[1]
        })
        // 2. edit application
        const editObj = {
          _id: this.application.id,
          memo: this.application.memo,
          forceML_Cut: this.application.forceML_Cut,
          forceML_Reg: this.application.forceML_Reg,
          forceLB_Full_Head: this.application.forceLB_Full_Head,
          forceLB_Half_Head: this.application.forceLB_Half_Head,
          forceFS_InSalon: this.application.forceFS_InSalon,
          forceFS_Online: this.application.forceFS_Online,
          forceTA: this.application.forceTA,
          licenseApproved: this.application.licenseApproved,
          distanceApproved: this.application.distanceApproved,
          adminSetDuplicatePhone: this.application.adminSetDuplicatePhone,
          adminSetDuplicateAddress: this.application.adminSetDuplicateAddress,
          // note: this will transfer application to finished
          finished: true
        }

        // ako se salje iz handleMarkAsDone
        if (!!tag) {
          editObj.tags = this.application.tags.filter(t => t !== tag)
          editObj.markAsDone = true
        }

        const editApplicationPromise = this.editApplication(editObj)

        await Promise.all([editStylistAccountPromise, editApplicationPromise])

        // 3. calculate statuses edititng stylist account and application -> note: only if application is finished, to not change finished to true for pending apps
        // note: this if is removed because now we are always sending finished: true
        // if (this.application.finished) {
        await this.calculateStatuses({ id: this.application.id })
        // }

        // 4. update table
        this.$emit('update')

        // // 5. close pop up (if not called from handleMarkAsDone)
        // this.$emit('close')

        this.snackbarColor = 'green'
        this.notification = 'Saved successfully!'
        this.snackbar = true

        this.isSaving = false
      },
      async handleMarkAsDone(tag) {
        this.handleSave(tag)
      },
      async handleMessageMarkAsDone() {
        this.isSendingMessageMarkAsDone = true

        // open mail client
        const email = this.email
        const subject = 'Hairdreams Inquiry/Update'
        const bodyHTML = `Dear ${this.firstName},

  Message: ${this.messages[this.messages.length - 1].body}`

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
          subject
        )}&body=${encodeURIComponent(bodyHTML)}`

        window.location.href = mailtoLink

        // edit application
        await this.editApplication({
          _id: this.application.id,
          messagesCount: this.messages.length,
          tags: this.application.tags.filter(
            t => t !== this.ApplicationTags.MESSAGE
          )
        })
        this.$emit('update')
        this.isSendingMessageMarkAsDone = false
      },
      async handleLicense(isApproved) {
        if (isApproved) this.isApproving = true
        else this.isDenying = true

        await this.editApplication({
          _id: this.editedItem.id,
          licenseApproved: isApproved ? 'Yes' : 'No',
          tags: this.editedItem.tags.filter(
            t => t !== this.ApplicationTags.LICENSE
          )
        })
        this.isApproving = false
        this.isDenying = false

        this.$emit('update')
      },
      async handlePictureChange(application) {
        if (this.picture?.size < 5000000) {
          const file = this.picture
          const form = new FormData()
          form.append('file', file)
          this.pictureLoading = true

          await this.addAdminLicense({
            form,
            id: application._id
          })

          this.$emit('update')

          this.pictureLoading = false
          this.imgError = null
        } else {
          this.imgError = 'Image size should be less than 5 MB!'
        }
      },
      handleForceFlag(flagsArr) {
        flagsArr.forEach(flagObj => {
          this.application[flagObj.key] = flagObj.value
        })
      },
      async handleDuplicate(flag, value) {
        this.application[flag] = value

        this.editApplication({
          _id: this.application.id,
          [flag]: value
        })
      }
    }
  }
</script>

<style scoped>
  .recent-changes {
    border: 1px solid #8b8b8b;
    padding: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 30px;
    min-height: 100px;
  }
  .info-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #8b8b8b;
    margin-bottom: 10px;
    text-align: center;
    background-color: #f8f8f8;
    border-radius: 30px;
    min-height: 100px;
  }
  .info-label {
    margin-bottom: 5px;
  }
  .info-value {
    font-weight: bold;
    word-break: break-all;

    font-size: 16px;
  }
</style>
