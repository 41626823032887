<template>
  <div>
    <!-- note: title -->
    <v-card-title class="pt-5 pl-0 pl-sm-5 ml-5 mb-1 breakword">
      <span class="text-h5">Send a message</span>
    </v-card-title>

    <v-card-text class="px-0 px-sm-3">
      <v-container>
        <v-expansion-panels v-model="panels" multiple accordion class="mb-3">
          <!-- note: my info -->
          <v-expansion-panel class="mb-3">
            <v-expansion-panel-header disable-icon-rotate
              ><v-card-text class="subtitle-1 font-weight-bold py-0 px-0"
                >My information
                <span
                  v-if="!detectXS()"
                  class="subtitle-2 font-weight-light pl-3"
                >
                  {{ message.firstName }} {{ message.lastName }},
                  {{ message.email }}</span
                ></v-card-text
              >
              <template v-slot:actions>
                <v-icon color="teal" :small="detectXS() ? true : false">
                  mdi-pencil
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    auto-resize
                    hide-details
                    label="First name"
                    v-model="message.firstName"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    hide-details
                    label="Last name"
                    v-model="message.lastName"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <VuePhoneNumberInput
                    show-code-on-list
                    @update="handleSmsPhoneUpdate"
                    v-model="message.smsPhone.phoneNumber"
                    no-example
                    default-country-code="US"
                    :only-countries="['US', 'CA']"
                    :no-flags="detectMob() ? true : false"
                    color="#222"
                    valid-color="#222"
                    error-color="red"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    hide-details
                    label="Email"
                    v-model="message.email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- note: my preferences -->
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate
              ><v-card-text class="subtitle-1 font-weight-bold py-0 px-0"
                >My preferences
                <span
                  v-if="!detectXS()"
                  class="subtitle-2 font-weight-light pl-3"
                  >{{ getMyPrefText }}
                </span></v-card-text
              >
              <template v-slot:actions>
                <v-icon color="teal" :small="detectXS() ? true : false">
                  mdi-pencil
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- NOTE: QUESTIONS -->
              <!-- MICROLINES -->
              <v-divider></v-divider>
              <!-- <template v-if="isMicrolines"> -->
              <template v-if="isMicrolines">
                <!-- note: question 4 -->
                <!-- <div class="form">
                      <v-card-text class="text-center title">{{
                        getQ4Info().question
                      }}</v-card-text>
                      <v-card-text class="text-center subtitle"
                        >Choose one</v-card-text
                      >
                      <v-row>
                        <v-col class="d-flex align-center flex-column"
                          ><v-btn
                            rounded
                            class="text-capitalize"
                            @click="onQ4B(getQ4Info().answers[0])"
                            :dark="message.question4 === getQ4Info().answers[0]"
                            >{{ getQ4Info().answers[0] }}</v-btn
                          ></v-col
                        >
                        <v-col class="d-flex justify-center flex-column">
                          <v-btn
                            rounded
                            class="text-capitalize"
                            @click="onQ4B(getQ4Info().answers[1])"
                            :dark="message.question4 === getQ4Info().answers[1]"
                            >{{ getQ4Info().answers[1] }}</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-divider class="mt-5"></v-divider>
                    </div> -->
                <!-- note: question 1 -->
                <template v-if="showQM1">
                  <v-card-text class="text-center title">{{
                    getQ1MInfo(message.question1).question
                  }}</v-card-text>
                  <v-card-text class="text-center subtitle py-0"
                    >Choose multiple</v-card-text
                  >
                  <v-row class="form" v-if="q1M.show">
                    <v-col cols="6" class="d-flex justify-end">
                      <v-btn
                        class="text-capitalize"
                        rounded
                        small
                        @click="onQM1B('a')"
                        :dark="message.question1.includes('a')"
                        >{{
                          getQ1MInfo(message.question1).options[0].text
                        }}</v-btn
                      >
                    </v-col>
                    <v-col cols="6" class="d-flex justify-start">
                      <v-btn
                        @click="onQM1B('b')"
                        :dark="message.question1.includes('b')"
                        class="text-capitalize"
                        rounded
                        small
                        >{{
                          getQ1MInfo(message.question1).options[1].text
                        }}</v-btn
                      >
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end">
                      <v-btn
                        @click="onQM1B('c')"
                        :dark="message.question1.includes('c')"
                        class="text-capitalize"
                        rounded
                        small
                        >{{
                          getQ1MInfo(message.question1).options[2].text
                        }}</v-btn
                      >
                    </v-col>
                    <v-col cols="6" class="d-flex justify-start">
                      <v-btn
                        @click="onQM1B('d')"
                        :dark="message.question1.includes('d')"
                        class="text-capitalize"
                        rounded
                        small
                        >{{
                          getQ1MInfo(message.question1).options[3].text
                        }}</v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                </template>
              </template>
              <!-- NOT MICROLINES -->
              <template v-if="!isMicrolines">
                <!-- Q1 -->
                <v-row class="form">
                  <v-card-text class="text-center title">{{
                    getQ1Info(message.question1).question
                  }}</v-card-text>
                  <v-card-text class="text-center subtitle"
                    >Choose one</v-card-text
                  >
                  <v-row>
                    <v-col
                      @click="onQ1B1"
                      class="d-flex justify-center flex-column align-center py-5"
                    >
                      <v-img
                        width="100"
                        contain
                        src="@/assets/BEFORE SHORT.png"
                        lazy-src="@/assets/BEFORE SHORT.png"
                      ></v-img>
                      <v-btn
                        class="text-capitalize"
                        rounded
                        small
                        :dark="q1.btn1"
                        >{{
                          getQ1Info(message.question1).options[0].text
                        }}</v-btn
                      ></v-col
                    >
                    <v-col
                      @click="onQ1B2"
                      class="d-flex justify-center flex-column align-center py-5"
                      ><v-img
                        width="100"
                        contain
                        lazy-src="@/assets/BEFORE MID.png"
                        src="@/assets/BEFORE MID.png"
                      ></v-img
                      ><v-btn
                        class="text-capitalize"
                        rounded
                        small
                        :dark="q1.btn2"
                        >{{
                          getQ1Info(message.question1).options[1].text
                        }}</v-btn
                      ></v-col
                    >
                    <v-col
                      @click="onQ1B3"
                      class="d-flex justify-center flex-column align-center py-5"
                      ><v-img
                        width="100"
                        contain
                        lazy-src="@/assets/BEFORE LONG.png"
                        src="@/assets/BEFORE LONG.png"
                      ></v-img
                      ><v-btn
                        class="text-capitalize"
                        rounded
                        small
                        :dark="q1.btn3"
                        >{{
                          getQ1Info(message.question1).options[2].text
                        }}</v-btn
                      ></v-col
                    >
                  </v-row>
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </template>
              <!-- Q2 -->
              <div class="form" v-if="showQ2">
                <v-card-text class="text-center title">{{
                  getQ2Info(message.question2).question
                }}</v-card-text>
                <v-card-text class="text-center subtitle"
                  >Choose one</v-card-text
                >
                <v-row>
                  <v-col
                    v-if="q2.btn1Show"
                    class="d-flex justify-center flex-column align-center py-5"
                    @click="onQ2B1"
                  >
                    <v-img
                      width="100"
                      contain
                      src="@/assets/AFTER SHORT.png"
                      lazy-src="@/assets/AFTER SHORT.png"
                    ></v-img>
                    <v-btn
                      class="text-capitalize"
                      rounded
                      small
                      :dark="q2.btn1"
                      >{{ getQ2Info(message.question2).options[0].text }}</v-btn
                    ></v-col
                  >
                  <v-col
                    class="d-flex justify-center flex-column align-center py-5"
                    v-if="q2.btn2Show"
                    @click="onQ2B2"
                    ><v-img
                      width="100"
                      contain
                      src="@/assets/AFTER MID.png"
                      lazy-src="@/assets/AFTER MID.png"
                    ></v-img
                    ><v-btn
                      class="text-capitalize"
                      rounded
                      small
                      :dark="q2.btn2"
                      >{{ getQ2Info(message.question2).options[1].text }}</v-btn
                    ></v-col
                  >
                  <v-col
                    @click="onQ2B3"
                    class="d-flex justify-center flex-column align-center py-5"
                    ><v-img
                      width="100"
                      contain
                      src="@/assets/AFTER LONG.png"
                      lazy-src="@/assets/AFTER LONG.png"
                    ></v-img
                    ><v-btn
                      class="text-capitalize"
                      rounded
                      small
                      :dark="q2.btn3"
                      >{{ getQ2Info(message.question2).options[2].text }}</v-btn
                    ></v-col
                  >
                </v-row>
                <v-divider class="mt-5"></v-divider>
              </div>

              <!-- Q3-->
              <template v-if="!isMicrolines">
                <div class="form" v-if="showQ3">
                  <v-card-text class="text-center title px-0 px-sm-3">{{
                    getQ3Info(message.question3).question
                  }}</v-card-text>
                  <v-row class="justify-center">
                    <v-col cols="4" sm="2" class="d-flex justify-center"
                      ><v-btn rounded :dark="q3.btn1" @click="onQ3B1">{{
                        getQ3Info(message.question3).options[0].text
                      }}</v-btn></v-col
                    >
                    <v-col cols="4" sm="2" class="d-flex justify-center"
                      ><v-btn rounded :dark="q3.btn2" @click="onQ3B2">{{
                        getQ3Info(message.question3).options[1].text
                      }}</v-btn></v-col
                    >
                  </v-row>
                  <v-divider class="mt-5"></v-divider>
                </div>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row class="px-2 mt-3" v-if="showMessageBox">
          <v-col cols="12" sm="12">
            <v-textarea
              filled
              outlined
              prepend-inner-icon="mdi-comment"
              hide-details
              auto-grow
              name="input-7-1"
              label="Message"
              placeholder="Write us a message"
              v-model="message.body"
            ></v-textarea>
          </v-col>
        </v-row>
        <!-- <v-row>
              <v-col cols="12">
                <v-checkbox
                  v-model="terms"
                  label="I accept Terms and Privacy Policy of Hairdreams Referral app"
                ></v-checkbox>
              </v-col>
            </v-row> -->
      </v-container>
    </v-card-text>
  </div>
</template>

<script>
  import { MessageFrom, MessageTypes, NewSystemOptions } from '@/config/enums'
  import { getQ1MInfo } from '@/utils/helpers'
  import { getQ1Info } from '@/utils/helpers'
  import { getQ2Info } from '@/utils/helpers'
  import { getQ3Info } from '@/utils/helpers'
  import { detectMob, detectXS, getQ4Info } from '@/utils/helpers'

  export default {
    props: {
      sendingData: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        getQ4Info,
        detectMob,
        detectXS,
        panels: [],
        getQ1MInfo,
        getQ1Info,
        getQ2Info,
        getQ3Info,
        q1: { btn1: false, btn2: false, btn3: false },
        q2: {
          btn1: false,
          btn1Show: true,
          btn2: false,
          btn2Show: true,
          btn3: false
        },
        q3: { show: false, btn1: false, btn2: false },
        q1M: {
          show: true
        },

        // terms: false,
        options2: [],
        message: {
          type: MessageTypes.C_S,
          from: MessageFrom.C,
          firstName: '',
          lastName: '',
          smsPhone: { phoneNumber: '', formattedNumber: '', validated: false },
          email: '',
          consumer: '',
          salon: '',
          body: '',
          question1: '',
          question2: '',
          question3: '',
          question4: ''
        },
        changeQ2Options() {
          if (this.consumer.system !== NewSystemOptions.MICROLINES) {
            if (this.message.question1 === 'b') {
              this.q2.btn1 = false
              this.q2.btn1Show = false
              this.q2.btn2Show = true
              if (this.message.question2 === 'a') this.message.question2 = ''
            } else if (this.message.question1 === 'c') {
              this.q2.btn1 = false
              this.q2.btn2 = false
              this.q2.btn1Show = false
              this.q2.btn2Show = false
              if (
                this.message.question2 === 'a' ||
                this.message.question2 === 'b'
              )
                this.message.question2 = ''
            } else {
              this.q2.btn1Show = true
              this.q2.btn2Show = true
            }
          }
        }
      }
    },
    computed: {
      getMyPrefText() {
        let text = ''
        // note: prikazujemo uvek samo drugo pitanje sa dinamickim odgovorom
        text =
          'How long do you want your hair to be?' +
          ' ' +
          this.getQ2Info(this.message.question2).answer
        return text
      },
      consumer() {
        return this.$store.state.consumers.consumer
      },
      isMicrolines() {
        return this.consumer.system === NewSystemOptions.MICROLINES
      },
      showQM1() {
        return !!this.message.question4
      },
      showQ2() {
        return typeof this.message.question1 === 'string'
          ? !!this.message.question1
          : this.message?.question1?.length > 0
      },
      showQ3() {
        return !!this.message.question2 && !this.isMicrolines
      },
      isValid() {
        return (
          !!this.message.firstName &&
          !!this.message.lastName &&
          !!this.message.smsPhone.phoneNumber &&
          !!this.message.email &&
          !!this.message.body &&
          (this.isMicrolines
            ? !!this.message.question1.length &&
              !!this.message.question2 &&
              !!this.message.question4
            : !!this.message.question1 &&
              !!this.message.question2 &&
              !!this.message.question3)
        )
      },
      showMessageBox() {
        return (
          !!this.message.firstName &&
          !!this.message.lastName &&
          !!this.message.smsPhone.phoneNumber &&
          !!this.message.email &&
          (this.isMicrolines
            ? !!this.message.question1 &&
              !!this.message.question2 &&
              !!this.message.question4
            : !!this.message.question1 &&
              !!this.message.question2 &&
              !!this.message.question3)
        )
      }
    },
    methods: {
      handleSmsPhoneUpdate(obj) {
        this.message.smsPhone.formattedNumber = obj.formattedNumber
      },
      onQM1B(value) {
        if (!this.message.question1) {
          this.message.question1 = []
        }
        if (this.message.question1.includes(value)) {
          this.message.question1 = this.message.question1.filter(
            q => q !== value
          )
        } else {
          this.message.question1.push(value)
        }
      },
      onQ1B1() {
        this.q1.btn1 = true
        this.q1.btn2 = false
        this.q1.btn3 = false
        this.message.question1 = 'a'
        this.changeQ2Options()
      },
      onQ1B2() {
        this.q1.btn1 = false
        this.q1.btn2 = true
        this.q1.btn3 = false
        this.message.question1 = 'b'
        this.changeQ2Options()
      },
      onQ1B3() {
        this.q1.btn1 = false
        this.q1.btn2 = false
        this.q1.btn3 = true
        this.message.question1 = 'c'
        this.changeQ2Options()
      },
      onQ2B1() {
        this.q2.btn1 = true
        this.q2.btn2 = false
        this.q2.btn3 = false

        this.message.question2 = 'a'
      },
      onQ2B2() {
        this.q2.btn1 = false
        this.q2.btn2 = true
        this.q2.btn3 = false

        this.message.question2 = 'b'
      },
      onQ2B3() {
        this.q2.btn1 = false
        this.q2.btn2 = false
        this.q2.btn3 = true

        this.message.question2 = 'c'
      },
      onQ3B1() {
        this.q3.btn1 = true
        this.q3.btn2 = false
        this.message.question3 = 'a'
      },
      onQ3B2() {
        this.q3.btn1 = false
        this.q3.btn2 = true
        this.message.question3 = 'b'
      },
      onQ4B(value) {
        this.message.question4 = value
      }
    },
    watch: {
      isValid(newValue, oldValue) {
        this.$emit('onValid', { step: 1, isValid: newValue })
      },
      message: {
        handler(newValue, oldValue) {
          this.$emit('onDataChange', { step: 1, data: newValue })
        },
        deep: true
      }
    },
    created() {
      this.message.smsPhone = this.consumer.smsPhone
      this.message.email = this.consumer.email
      this.message.firstName = this.consumer.firstName
      this.message.lastName = this.consumer.lastName
      this.message.question1 = this.consumer.question1
      this.message.question2 = this.consumer.question2
      this.message.question3 = this.consumer.question3
      this.message.question4 = this.consumer.question4 || ''
      this.q1.btn1 = this.consumer.question1 === 'a'
      this.q1.btn2 = this.consumer.question1 === 'b'
      this.q1.btn3 = this.consumer.question1 === 'c'
      this.q2.btn1 = this.consumer.question2 === 'a'
      this.q2.btn2 = this.consumer.question2 === 'b'
      this.q2.btn3 = this.consumer.question2 === 'c'
      this.q3.btn1 = this.consumer.question3 === 'a'
      this.q3.btn2 = this.consumer.question3 === 'b'
      this.changeQ2Options()
      // note: open panel 2 if not filled
      if (this.isMicrolines) {
        if (!this.message.question1 || !this.message.question2) {
          this.panels.push(1)
        }
      } else {
        if (
          !this.message.question1 ||
          !this.message.question2 ||
          !this.message.question3
        ) {
          this.panels.push(1)
        }
      }
      if (this.sendingData[1]) {
        this.message = this.sendingData[1]
      }

      this.$emit('onValid', { step: 1, isValid: this.isValid })
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    margin: 10px 0;
  }
  .hair-img {
    cursor: pointer;
  }
</style>
