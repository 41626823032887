<template>
  <v-dialog v-model="show" @click:outside="close" maxWidth="500">
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-uppercase">Send Feedback</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="mt-5 pt-2">
        <v-textarea
          label="Enter feedback"
          v-model="feedback"
          auto-grow
          outlined
          rows="4"
          filled
          hide-details
        ></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          :disabled="!feedback"
          rounded
          class="white--text"
          color="user_primary"
          @click="send"
        >
          Send
        </v-btn>
      </v-card-actions>
    </v-card></v-dialog
  >
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'SimpleModal',
    props: {
      show: {
        type: Boolean,
        required: false
      },
      salonId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        feedback: '',
        loading: false
      }
    },
    methods: {
      ...mapActions(['editSalonFeedback']),

      close() {
        this.feedback = ''
        this.$emit('close')
      },
      async send() {
        this.loading = true
        await this.editSalonFeedback({
          salonId: this.salonId,
          feedback: this.feedback
        })
        this.loading = false
        this.close()
      }
    }
  }
</script>
