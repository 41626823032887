<template>
  <v-dialog
    v-model="showDialog"
    :max-width="maxWidth"
    @keydown.esc="closeSimpleModal"
    @click:outside="closeSimpleModal"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-uppercase">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <div class="text-subtitle-1 my-3" v-html="message"></div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded color="user_primary" dark @click="closeSimpleModal">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SimpleModal',
    props: {
      maxWidth: {
        type: String,
        default: '300'
      },
      showDialog: {
        type: Boolean,
        required: false
      },
      title: {
        type: String,
        default: 'Response'
      },
      message: {
        type: String,
        required: true
      }
    },
    methods: {
      closeSimpleModal() {
        this.$emit('closeSimpleModal', false)
      }
    }
  }
</script>
