<template>
  <v-dialog v-model="show" max-width="600" @click:outside="close">
    <v-card>
      <v-toolbar elevation="0" height="100">
        <v-card-title class="breakword my-3"
          >LASERBEAMER NANO START KIT INCLUDES:</v-card-title
        >
        <v-spacer></v-spacer>
        <v-icon @click="close" class="mb-auto mt-4">mdi-close</v-icon>
      </v-toolbar>

      <v-card-text>
        <ul>
          <li>Laserbeamer Nano Machine+Travel Carrying Case</li>
          <li>Nano Accessory Kit</li>
          <li>Hairdreams Color Ring</li>
          <li>Removal System</li>
          <li>Full Wet line Assortment</li>
          <li>Extension Brush</li>
          <li>Training Manual</li>
          <li>Style Books & Brochures</li>
          <li>3 Packs of 16” 5 Star Nano Hair</li>
          <li>Mannequin Head and Holder</li>
        </ul>
      </v-card-text>
      <v-card-text class="subtitle-1"
        >ONLINE TRAINING MODULE + VIRTUAL LIVE ZOOM CERTIFICATION
        CLASS:</v-card-text
      >
      <v-card-text
        >This class combines the convenience of an easy-to-use online training +
        a live Zoom hands on class where you will apply, cut, and style
        Hairdreams extensions to one half of a mannequin head. Training
        additionally covers removal, maintenance, consultation and marketing.
        Live Zoom training date will be scheduled once the online training
        module is completed. The Start Kit can only certify one licensed
        cosmetologist. Inquire for details on certifying more than one
        stylist.</v-card-text
      >
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: false
      }
    },
    methods: {
      close() {
        this.$emit('close', false)
      }
    }
  }
</script>
