var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('v-card',{staticClass:"elevation-0 mx-auto",attrs:{"max-width":"550"}},[_c('Title',{attrs:{"text":_vm.source.title}}),_c('v-card-text',{staticClass:"text-center mt-5 pb-0 text-body-1 font-weight-bold",attrs:{"color":"#a5a5a5"}},[_vm._v("Part 1: Prerequisite class #CT101")]),_c('v-card-text',{staticClass:"text-center text-h6 font-weight-bold pt-0 pb-1"},[_vm._v("Topper & Extension Cutting")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ImageBox',{attrs:{"options":_vm.source.items[1]},on:{"action":_vm.handleImageAction}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ImageBox',{attrs:{"options":_vm.source.items[2]},on:{"action":_vm.handleImageAction}})],1)],1),_c('TextBox',{attrs:{"isCanada":_vm.isCanada,"text":{
        ..._vm.source.textBox1,
        paragraphHTML: _vm.settings[_vm.StylistsCertifications.ML_CUTTING]?.paragraph1
      },"promo-price":_vm.settings[_vm.StylistsCertifications.ML_CUTTING]?.promoPrice1,"list-price":_vm.settings[_vm.StylistsCertifications.ML_CUTTING]?.listPrice1}})],1),_c('v-divider',{staticClass:"my-5"}),_c('v-card-text',{staticClass:"text-center mt-5 pb-0 text-body-1 font-weight-bold",attrs:{"color":"#a5a5a5"}},[_vm._v("Part 2: Main class #ML101")]),_c('v-card-text',{staticClass:"text-center text-h6 font-weight-bold pt-0 pb-1"},[_vm._v("MicroLines Start Kit and Certification")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ImageBox',{attrs:{"options":_vm.source.items[3]},on:{"action":_vm.handleImageAction}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ImageBox',{attrs:{"options":_vm.source.items[4]},on:{"action":_vm.handleImageAction}}),_c('ImageBox',{staticClass:"mt-4",attrs:{"options":_vm.source.items[5]},on:{"action":_vm.handleImageAction}})],1)],1),_c('TextBox',{attrs:{"isCanada":_vm.isCanada,"text":{
        ..._vm.source.textBox2,
        paragraphHTML:
          _vm.settings[_vm.StylistsCertifications.ML_CUTTING]?.paragraph2,
        buttonText: _vm.settings[_vm.StylistsCertifications.ML_CUTTING]?.buttonText,
        buttonLink: _vm.settings[_vm.StylistsCertifications.ML_CUTTING]?.buttonLink
      },"promo-price":_vm.settings[_vm.StylistsCertifications.ML_CUTTING]?.promoPrice2,"list-price":_vm.settings[_vm.StylistsCertifications.ML_CUTTING]?.listPrice2}})],1),_c('v-divider',{staticClass:"my-5"}),_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-h5 font-weight-bold breakword color-blue px-0"},[_c('v-spacer'),_c('span',{staticClass:"color-blue text-center font-weight-medium"},[_vm._v("Why MicroLines?")]),_c('v-spacer')],1),_c('v-expansion-panel-content',{staticClass:"accordion"},[_c('MicrolinesView',{attrs:{"source":_vm.stylistSources[_vm.SystemOptions.MICROLINES],"action":false,"certPage":_vm.StylistsCertifications.ML_CUTTING}})],1)],1)],1),_c('CertPopUp',{attrs:{"show":_vm.showPopUp,"options":_vm.options},on:{"close":_vm.handleClosePopUp}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }