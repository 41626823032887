<template>
  <v-container class="px-0 px-sm-3 pt-0">
    <!-- note: OPTION 1:  -->
    <v-card
      class="pa-6 elevation-0 mx-auto"
      max-width="680"
      v-if="notLoggedInOrNoTerms"
    >
      <v-row class="mt-7">
        <iframe
          src="https://player.vimeo.com/video/925611947?h=f3972f9ef7"
          width="640"
          height="564"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </v-row>
      <v-card-title
        class="mt-4 d-flex justify-center font-weight-black breakword"
        >Hairdreams Salon Finder</v-card-title
      >
      <v-card-text>
        This is an all-in-one platform for you to connect with new Hairdreams
        customers and increase your business. Here are some cool features:
        <ul class="dash">
          <li>organized messaging platform</li>
          <li>auto pre-consultation</li>
          <li>highlights and reviews</li>
          <li>free marketing and much more</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="btn"
          block
          color="user_primary"
          dark
          rounded
          @click="handleGoToMyAccount"
          >Activate your account
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- note: OPTION 2:  -->
    <v-card
      class="pa-6 elevation-0 mx-auto"
      max-width="680"
      v-else-if="loggedIn_Terms_HidePromo"
    >
      <v-img
        src="@/assets/salon-home.png"
        lazy-src="@/assets/salon-home.png"
        alt="salon home page image"
        max-width="90%"
        contain
        class="mx-auto"
      ></v-img>
      <v-card-title class="d-flex justify-center font-weight-black breakword"
        >New Hairdreams Account Features</v-card-title
      >
      <v-card-text>
        This is an all-in-one platform for you to connect with new Hairdreams
        customers and increase your business. Here are some cool features:
        <ul class="dash">
          <li>organized messaging platform</li>
          <li>auto pre-consultation</li>
          <li>highlights and reviews</li>
          <li>free marketing and much more</li>
        </ul>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="btn"
          block
          color="user_primary"
          dark
          rounded
          @click="handleGoToMyAccount"
          >Take a tour</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- note: OPTION 3:  -->
    <v-card
      class="pa-6 pt-8 elevation-0 mx-auto"
      max-width="680"
      v-else-if="loggedIn_Terms_ShowPromo"
    >
      <v-img
        v-if="settings?.urlType === 'picture-url'"
        :src="settings?.url"
        :lazy-src="settings?.url"
        alt="salon home page image"
        max-width="90%"
        contain
        class="mx-auto"
      ></v-img>
      <iframe
        v-if="settings?.urlType === 'video-url'"
        :src="'https://player.vimeo.com/video/' + settings.url"
        width="640"
        height="564"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
      ></iframe>

      <v-card-title class="d-flex justify-center font-weight-black breakword">{{
        settings.title
      }}</v-card-title>
      <v-card-text v-html="decodedParagraph"> </v-card-text>
      <v-card-actions>
        <v-btn
          class="btn"
          block
          color="user_primary"
          dark
          rounded
          @click="handleGoToSettingsLink"
          >{{ settings.buttonText }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import { decode } from 'html-entities'
  import { mapActions } from 'vuex'
  import { getTermsStatus } from '../../../utils/helpers'

  export default {
    computed: {
      decodedParagraph() {
        return decode(this.settings.paragraph)
      },
      salonId() {
        return this.$route.params.salonId
      },
      salon() {
        return this.$store.state.salons.selectedSalon
      },
      termsAccepted() {
        return getTermsStatus(this.salon?.terms) === 'accepted'
      },
      settings() {
        return this.$store.getters.salonHomePageSettings?.settings
      },
      loggedIn() {
        return this.$store.getters.salonLoggedIn
      },
      hidePromo() {
        return !!this.settings?.hidePromo
      },
      // OPTION 1: User not logged in and terms=no answer:
      notLoggedInOrNoTerms() {
        return !this.loggedIn || !this.termsAccepted
      },
      // OPTION 2: User logged in & terms=yes & hide promo=true
      loggedIn_Terms_HidePromo() {
        return this.loggedIn && this.termsAccepted && this.hidePromo
      },
      // OPTION 3: User logged in & terms=yes & hide promo=false
      loggedIn_Terms_ShowPromo() {
        return this.loggedIn && this.termsAccepted && !this.hidePromo
      }
    },
    methods: {
      ...mapActions(['editSalon']),
      handleGoToSettingsLink() {
        window.location.href = this.settings.buttonLink
      },
      handleGoToMyAccount() {
        if (this.$store.getters.salonLoggedIn) {
          this.$router.push({
            name: 'SalonMessages',
            params: { salonId: this.$store.state.auth.user.salon },
            query: {
              from: this.$route.fullPath,
              showHelpers: 'yes'
            }
          })
        } else {
          this.$router.push({
            name: 'FindSalon',
            query: { from: this.$route.name, showHelpers: 'yes' }
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  ul.dash {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
  }
  ul.dash > li:before {
    display: inline-block;
    content: '-';
    width: 1em;
    margin-left: -1em;
  }
  .btn {
    text-transform: unset !important;
  }
  .breakword {
    word-break: break-word;
  }
</style>
