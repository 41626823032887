<template>
  <v-container class="pa-1 pa-sm-3 pb-sm-0">
    <!-- note: video -->
    <!-- <v-row text-xs-center>
      <iframe
        width="560"
        height="315"
        :src="result"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </v-row> -->
    <!-- note: review 1 -->
    <EditReview
      v-if="showEditReview1"
      num="1"
      :open="showEditReview1"
      @close="showEditReview1 = false"
      @fb-change="onFbChange"
      @other-change="onOtherChange"
      :review="salon.review1"
    />
    <v-row
      class="flex-column review-box pa-0 pt-1 ma-0 mt-sm-1 mb-5 pb-3 pb-sm-5"
      v-if="!skipReview1"
    >
      <v-row class="align-center">
        <v-col class="mb-0 pb-0" v-if="!detectXS()"
          ><v-card-text class="pb-0 mt-0 pt-0">Review 1 Preview</v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end mb-0 pb-0">
          <v-btn @click="showEditReview1 = true" text
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >
          <v-btn text class="text-capitalize" @click="onSkip(1)">Skip</v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="showCustomPreview(salon.review1)"
        >
          <CustomReview
            :name="salon.review1.name"
            :date="salon.review1.date"
            :content="salon.review1.content"
            :reviewType="salon.review1.reviewType"
          />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="showFbPreview(salon.review1)"
        >
          <div :class="reviewPreview1Classes" v-html="reviewPreview1"></div>
        </v-col>
      </v-row>
    </v-row>

    <!-- note: review 2 -->
    <EditReview
      v-if="showEditReview2"
      num="2"
      :open="showEditReview2"
      @close="showEditReview2 = false"
      @fb-change="onFbChange"
      @other-change="onOtherChange"
      :review="salon.review2"
    />
    <v-row
      class="flex-column review-box pa-0 pt-1 ma-0 mt-sm-1 mb-5 pb-3 pb-sm-5"
      v-if="showReview2 && !skipReview2"
    >
      <v-row class="align-center">
        <v-col class="mb-0 pb-0" v-if="!detectXS()"
          ><v-card-text class="pb-0 mt-0 pt-0">Review 2 Preview</v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end mb-0 pb-0">
          <v-btn @click="showEditReview2 = true" text
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >
          <v-btn text class="text-capitalize" @click="onSkip(2)">Skip</v-btn>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="showCustomPreview(salon.review2)"
        >
          <CustomReview
            :name="salon.review2.name"
            :date="salon.review2.date"
            :content="salon.review2.content"
            :reviewType="salon.review2.reviewType"
          />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="showFbPreview(salon.review2)"
        >
          <div :class="reviewPreview2Classes" v-html="reviewPreview2"></div>
        </v-col>
      </v-row>
    </v-row>

    <!-- note: review 3 -->
    <EditReview
      v-if="showEditReview3"
      num="3"
      :open="showEditReview3"
      @close="showEditReview3 = false"
      @fb-change="onFbChange"
      @other-change="onOtherChange"
      :review="salon.review3"
    />
    <v-row
      class="flex-column review-box pa-0 pt-1 ma-0 mt-sm-1 pb-3 pb-sm-5"
      v-if="showReview3 && !skipReview3"
    >
      <v-row class="align-center">
        <v-col class="mb-0 pb-0" v-if="!detectXS()"
          ><v-card-text class="pb-0 mt-0 pt-0">Review 3 Preview</v-card-text>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex justify-end mb-0 pb-0">
          <v-btn @click="showEditReview3 = true" text
            ><v-icon small>mdi-pencil</v-icon></v-btn
          >
          <v-btn text class="text-capitalize" @click="onSkip(3)">Skip</v-btn>
        </v-col></v-row
      >
      <v-row class="justify-center">
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="showCustomPreview(salon.review3)"
        >
          <CustomReview
            :name="salon.review3.name"
            :date="salon.review3.date"
            :content="salon.review3.content"
            :reviewType="salon.review3.reviewType"
          />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
          v-if="showFbPreview(salon.review3)"
        >
          <div :class="reviewPreview3Classes" v-html="reviewPreview3"></div>
        </v-col>
      </v-row>
    </v-row>
    <v-row v-if="showAddReviews">
      <v-btn class="mx-auto text-capitalize" @click="onAddReviews"
        >Add Reviews</v-btn
      ></v-row
    >
  </v-container>
</template>

<script>
  import EditReview from '@/components/user/edit-salon/EditReview.vue'
  import CustomReview from '@/components/CustomReview.vue'
  import { ReviewTypes } from '@/config/enums'
  import { detectXS } from '@/utils/helpers'
  import { decode } from 'html-entities'
  const isHtml = review => {
    return review.toString().startsWith('<iframe')
  }

  export default {
    components: {
      EditReview,
      CustomReview
    },
    props: {
      salon: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        detectXS,
        num: 0,
        skipReview1: false,
        skipReview2: false,
        skipReview3: false,
        ReviewTypes,
        showEditReview1: false,
        showEditReview2: false,
        showEditReview3: false,
        reviewPreview1: '',
        reviewPreview1Classes: '',
        reviewPreview2: '',
        reviewPreview2Classes: '',
        reviewPreview3: '',
        reviewPreview3Classes: '',
        isValidReview1: false,
        isValidReview2: false,
        isValidReview3: false,
        showReview2: false,
        showReview3: false,
        showAddReviews: false
      }
    },
    computed: {
      isValid() {
        return true
      },
      isCreateSalon() {
        return this.$route.name === 'CreateSalon'
      },
      isAdminPage() {
        return this.$route.name === 'EditPendingSalon'
      }
    },
    methods: {
      onSkip(num) {
        for (let index = num; index <= 3; index++) {
          this['skipReview' + index] = true
          this.onChange({ content: '' }, 'review' + index, index)
        }
        if (!this.skipReview1 && !this.skipReview2 && !this.skipReview3) {
          this.showAddReviews = false
        } else {
          this.showAddReviews = true
        }
      },
      onAddReviews() {
        if (this.skipReview1) {
          this.skipReview1 = false
          this.validateReview(1, this.salon['review' + 1])
        } else if (this.skipReview2) {
          this.skipReview2 = false
          this.validateReview(2, this.salon['review' + 2])
        } else if (this.skipReview3) {
          this.skipReview3 = false
          this.validateReview(3, this.salon['review' + 3])
        }
        if (!this.skipReview1 && !this.skipReview2 && !this.skipReview3) {
          this.showAddReviews = false
        } else {
          this.showAddReviews = true
        }
      },
      showCustomPreview(review) {
        return (
          review?.reviewType === ReviewTypes.YELP ||
          review?.reviewType === ReviewTypes.GOOGLE ||
          review?.reviewType === ReviewTypes.OTHER
        )
      },
      showFbPreview(review) {
        return review?.reviewType === ReviewTypes.FB
      },
      onFbChange(data) {
        this.onChange(
          { reviewType: data.reviewType, content: data.content },
          'review' + data.num,
          data.num
        )
      },
      onOtherChange(data) {
        this.onChange(
          {
            reviewType: data.reviewType,
            content: data.content,
            name: data.name,
            date: data.date
          },
          'review' + data.num,
          data.num
        )
      },
      validateReview(num, review) {
        if (this['skipReview' + num]) {
          this['isValidReview' + num] = true
        } else {
          if (review.reviewType === this.ReviewTypes.FB) {
            if (
              !!review.content &&
              (isHtml(review.content) || isHtml(decode(review.content)))
            ) {
              this['isValidReview' + num] = true
            } else {
              this['isValidReview' + num] = false
            }
          } else {
            this['isValidReview' + num] =
              !!review.content &&
              !!review.name &&
              !!review.date &&
              !!review.reviewType
          }
          // this.$emit('valid', { step: 4, isValid: this.isValid })
        }
        if (this.isValidReview1) {
          this.showReview2 = true
          if (this.isValidReview2) {
            this.showReview3 = true
          }
        }
      },
      // helper
      getPreview(preview) {
        if (preview) {
          if (isHtml(preview)) {
            return {
              html: preview,
              classes: `fb-review ${this.detectXS() ? 'xs-width' : ''}`
            }
          } else if (isHtml(decode(preview))) {
            return {
              html: decode(preview),
              classes: `fb-review ${this.detectXS() ? 'xs-width' : ''}`
            }
          } else {
            return {
              html: 'Format not supported.',
              classes: `fb-review  review-warning ${
                this.detectXS() ? 'xs-width' : ''
              }`
            }
          }
        } else {
          return {
            html: '',
            classes: `fb-review ${this.detectXS() ? 'xs-width' : ''}`
          }
        }
      },
      setPreviews(num, value) {
        this['reviewPreview' + num] = this.getPreview(value).html
        this['reviewPreview' + num + 'Classes'] = this.getPreview(value).classes
      },
      onChange(value, prop, num) {
        this.$emit('change', {
          prop,
          value
        })
        this.setPreviews(num, value.content)
        this.validateReview(num, value)
        // this.$emit('valid', { step: 4, isValid: this.isValid })
        this.$emit('valid', {
          step: 4,
          isValid: this.isValid
          // at least one (archived)
          // isValid: !!this.salon.review1.content && this.isValidReview1
        })
      }
    },
    created() {
      this.setPreviews('1', this.salon.review1.content)
      this.setPreviews('2', this.salon.review2.content)
      this.setPreviews('3', this.salon.review3.content)
      this.validateReview('1', this.salon.review1)
      this.validateReview('2', this.salon.review2)
      this.validateReview('3', this.salon.review3)
      this.$emit('valid', { step: 4, isValid: this.isValid })
      // this.$emit('valid', {
      //   step: 4,
      //   isValid: false
      //   // at least one (archived)
      //   // isValid: !!this.salon.review1.content && this.isValidReview1
      // })
    }
  }
</script>

<style scoped lang="scss">
  .review-box {
    display: flex;
    background-color: lightgrey;
    border-radius: 20px;

    .review-custom-card {
      width: 500px;
      padding: 14px;
      padding-bottom: 30px;
    }
  }

  .fb-review {
    display: flex;
    justify-content: center;
    width: 95%;
  }

  .review-warning {
    color: red;
  }

  @media only screen and (max-width: 600px) {
    .review-box {
      .review-custom-card {
        width: 95%;
        padding: 10px;
        padding-bottom: 10px;
      }
    }
  }
</style>
