<template>
  <v-container fluid class="container">
    <v-card-title>Salons Analytics</v-card-title>
    <v-row>
      <v-col cols="12" v-if="tab === 1"><SalonsNumbers /></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="tab === 1"
        ><SalonsAdvancedAnalytics :startDate="startDate" :endDate="endDate"
      /></v-col> </v-row
    ><v-row>
      <v-col v-if="tab === 1" cols="6"><ActiveSalons /></v-col
      ><v-col cols="6" v-if="tab === 1"><SignedUpForSms /></v-col></v-row
  ></v-container>
</template>

<script>
  import SalonsNumbers from '@/components/admin/analytics/salons/SalonsNumbers.vue'
  import ActiveSalons from '@/components/admin/analytics/salons/ActiveSalons.vue'
  import SignedUpForSms from '@/components/admin/analytics/salons/SignedUpForSms.vue'
  import SalonsAdvancedAnalytics from '@/components/admin/analytics/salons/SalonsAdvancedAnalytics.vue'

  export default {
    components: {
      SalonsNumbers,
      ActiveSalons,
      SignedUpForSms,
      SalonsAdvancedAnalytics
    },
    props: {
      tab: {
        type: Number
      },
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    background-color: #eee;
  }
</style>
