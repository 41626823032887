<template>
  <div
    class="d-flex justify-center align-center"
    style="min-height: 236px"
    v-if="isLoading"
  >
    <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
  </div>
  <div class="d-flex justify-center align-center" v-else>
    <div class="item">
      <span>{{ total }} </span> Consultations total
    </div>
    <div class="item">
      <span>{{ notCompleted }} </span>Consultations - Unknown/Not completed
    </div>
    <div class="item">
      <span> {{ completed }}</span> Consultation completed
    </div>
    <div class="item">
      <span>{{ applicationBooked }} </span> Application booked
    </div>
    <div class="item">
      <span> {{ subtotal | dollar }} </span> Total sales to salons
    </div>
    <div class="item">
      <span> {{ avgSubtotal | dollar }}</span> Average sale to salon
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      total() {
        return this.$store.state.analytics.consultationsNumbers?.total
      },
      completed() {
        return this.$store.state.analytics.consultationsNumbers?.completed
      },
      notCompleted() {
        return this.$store.state.analytics.consultationsNumbers?.notCompleted
      },
      applicationBooked() {
        return this.$store.state.analytics.consultationsNumbers
          ?.applicationBooked
      },
      subtotal() {
        return this.$store.state.analytics.consultationsSubtotalSum
      },
      avgSubtotal() {
        return this.$store.state.analytics?.consultationsNumbers?.avgSubtotal
      }
    },
    methods: {
      ...mapActions([
        'fetchConsultationsNumbers',
        'fetchSalonsAnalyticsAdvanced'
      ])
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true

        await Promise.all([
          this.fetchConsultationsNumbers({
            startDate: this.startDate,
            endDate: this.endDate
          }),
          this.fetchSalonsAnalyticsAdvanced({
            startDate: this.startDate,
            endDate: this.endDate
          })
        ])
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true

        await Promise.all([
          this.fetchConsultationsNumbers({
            startDate: this.startDate,
            endDate: this.endDate
          }),
          this.fetchSalonsAnalyticsAdvanced({
            startDate: this.startDate,
            endDate: this.endDate
          })
        ])

        this.isLoading = false
      }
    },
    async created() {
      await Promise.all([
        this.fetchConsultationsNumbers({
          startDate: this.startDate,
          endDate: this.endDate
        }),
        this.fetchSalonsAnalyticsAdvanced({
          startDate: this.startDate,
          endDate: this.endDate
        })
      ])
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    padding: 20px 35px;
    text-align: center;
  }
  .item span {
    line-height: 50px;
    font-size: 50px;
    font-weight: 700;
  }
</style>
