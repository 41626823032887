<template>
  <div>
    <div class="d-flex justify-center align-center" v-if="isLoading">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>

    <template v-else>
      <GChart :type="type" :data="chartData" :options="options"
    /></template>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  export default {
    components: {
      GChart
    },
    data() {
      return {
        isLoading: true,
        type: 'PieChart',
        options: {
          width: '100%',
          colors: ['#69ce4b', 'orange', '#df0000'],
          title: '',
          height: 600,
          animation: { startup: true, duration: 500 },
          legend: { position: 'bottom' }
        }
      }
    },
    computed: {
      salonsActive() {
        return this.$store.state.analytics.salonsActive
      },
      chartData() {
        return [
          ['Status', 'Count'],
          [
            'Terms Accepted and Active',
            this.salonsActive['Terms Accepted and Active']
          ],
          [
            'Terms No Answer and Active',
            this.salonsActive['Terms No Answer and Active']
          ],
          ['Not Active', this.salonsActive['Not Active']]
        ]
      },
      activeSalonCount() {
        return this.salonsActive.active
      }
    },
    methods: {
      ...mapActions(['fetchSalonsActive'])
    },
    async created() {
      await this.fetchSalonsActive()
      this.options.title = `Active Salons: ${this.activeSalonCount}`
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped></style>
