<template>
  <div>
    <GChart
      type="LineChart"
      :data="chartData"
      :options="chartOptions"
      :events="chartEvents"
    />
    <GChart
      v-if="
        showSecondChart && this.trafficTopConsumersIndividualSources.length > 0
      "
      type="LineChart"
      :data="secondChartData"
      :options="secondChartOptions"
    />
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  import moment from 'moment'

  export default {
    components: {
      GChart
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        showSecondChart: false,
        secondChartData: {},
        secondChartOptions: {
          // explorer: {},
          vAxis: { viewWindow: { min: 0 } },
          title: this.secondChartTitle,
          legend: { position: 'bottom' },
          height: 600,
          animation: { startup: true, duration: 500 },
          series: {}
        },
        // secondChartEvents: {
        //   select: () => {
        //     const selection = getSelection()

        //     let isFound = false
        //     const selectedText =
        //       selection?.baseNode?.data || selection?.focusNode?.data

        //     for (const key in this.secondChartOptions.series) {
        //       if (this.secondChartOptions.series[key].value === selectedText) {
        //         isFound = true
        //         this.secondChartOptions.series[key].lineWidth = 3
        //         this.secondChartOptions.series[key].pointsVisible = true
        //       } else {
        //         this.secondChartOptions.series[key].lineWidth = 0.5
        //         this.secondChartOptions.series[key].pointsVisible = false
        //       }
        //     }
        //     if (!isFound) {
        //       for (const key in this.secondChartOptions.series) {
        //         this.secondChartOptions.series[key].lineWidth = 2
        //         this.secondChartOptions.series[key].pointsVisible = false
        //       }
        //     }
        //   }
        // },

        chartEvents: {
          select: async () => {
            const selection = getSelection()

            let isFound = false
            const selectedText =
              selection?.baseNode?.data || selection?.focusNode?.data

            let secondChartColor = ''
            for (const key in this.chartOptions.series) {
              if (this.chartOptions.series[key].value === selectedText) {
                secondChartColor = this.chartOptions.series[key].color
                isFound = true
                this.chartOptions.series[key].lineWidth = 3
                this.chartOptions.series[key].pointsVisible = true
              } else {
                this.chartOptions.series[key].lineWidth = 0.5
                this.chartOptions.series[key].pointsVisible = false
              }
            }
            if (!isFound) {
              for (const key in this.chartOptions.series) {
                this.chartOptions.series[key].lineWidth = 2
                this.chartOptions.series[key].pointsVisible = false
              }
              this.showSecondChart = false
            }
            if (isFound) {
              await this.fetchTrafficTopConsumersIndividualSources({
                startDate: this.startDate,
                endDate: this.endDate,
                source: this.getSource(selectedText)
              })

              this.setSecondChart(selectedText, secondChartColor)
              this.showSecondChart = true
            }
          }
        },
        chartOptions: {
          // explorer: {},
          vAxis: { viewWindow: { min: 0 } },
          title: 'Top Consumer Sources',
          legend: { position: 'bottom' },
          height: 600,
          animation: { startup: true, duration: 500 },
          series: {
            0: {
              color: 'black',
              pointsVisible: false,
              lineWidth: 2,
              value: 'TikTok'
            },
            1: {
              color: 'orange',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Other'
            },
            2: {
              color: '#00ffff',
              pointsVisible: false,
              lineWidth: 2,
              value: 'IWHD'
            },
            3: {
              color: '#ff00ff',
              pointsVisible: false,
              lineWidth: 2,
              value: 'IG'
            },
            4: {
              color: '#00008B',
              pointsVisible: false,
              lineWidth: 2,
              value: 'FB'
            },
            5: {
              color: '#00ff00',
              pointsVisible: false,
              lineWidth: 2,
              value: 'HD'
            },
            6: {
              color: 'red',
              pointsVisible: false,
              lineWidth: 2,
              value: 'YT'
            },
            7: {
              color: 'lightblue',
              pointsVisible: false,
              lineWidth: 2,
              value: 'TW'
            }
          }
        }
      }
    },
    methods: {
      ...mapActions([
        'fetchTrafficTopConsumersSources',
        'fetchTrafficTopConsumersIndividualSources'
      ]),
      getSource(text) {
        switch (text) {
          case 'TikTok':
            return 'tt_'
          case 'Other':
            return 'other'
          case 'IWHD':
            return 'iwhd_'
          case 'IG':
            return 'ig_'
          case 'FB':
            return 'fb_'
          case 'YT':
            return 'yt_'
          case 'TW':
            return 'tw_'
          case 'HD':
            return '(Iwhd_hairdreamsusa_page_redirect_from_deleted_page|hd.com_)'
          default:
            return ''
        }
      },
      setSecondChart(title, color) {
        // set data
        if (this.trafficTopConsumersIndividualSources) {
          this.secondChartData = [['Date']]
          // add all sources
          this.trafficTopConsumersIndividualSources.forEach(item => {
            for (const key in item.sources) {
              if (!this.secondChartData[0].includes(key)) {
                this.secondChartData[0].push(key)
              }
            }
          })
          // add dates and sources count
          this.trafficTopConsumersIndividualSources.forEach(item => {
            const arr = [moment(item.date).format('ddd. MM/DD/YY')]
            this.secondChartData[0].forEach(sourceName => {
              if (sourceName !== 'Date') {
                let count = 0
                for (const key in item.sources) {
                  if (sourceName === key) {
                    count = item.sources[key]
                  }
                }
                arr.push(count)
              }
            })
            this.secondChartData.push(arr)
          })
        }

        // set options
        this.secondChartOptions.title = title
        if (this.secondChartData.length > 0) {
          let i = 0
          this.secondChartData[0].forEach(source => {
            if (source !== 'Date') {
              this.secondChartOptions.series[i] = {
                // color: color,
                pointsVisible: true,
                lineWidth: 2,
                value: source
              }
              i++
            }
          })
        }
      }
    },
    computed: {
      trafficTopConsumersSources() {
        return this.$store.state.analytics.trafficTopConsumersSources
      },
      trafficTopConsumersIndividualSources() {
        return this.$store.state.analytics.trafficTopConsumersIndividualSources
      },
      chartData() {
        const data = [
          ['Date', 'TikTok', 'Other', 'IWHD', 'IG', 'FB', 'HD', 'YT', 'TW']
        ]
        this.trafficTopConsumersSources.forEach(item => {
          data.push([
            moment(item.date).format('ddd. MM/DD/YY'),
            item['TikTok'],
            item['Other'],
            item['IWHD'],
            item['IG'],
            item['FB'],
            item['HD'],
            item['YT'],
            item['TW']
          ])
        })
        return data
      }
    },
    watch: {
      startDate() {
        this.fetchTrafficTopConsumersSources({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.showSecondChart = false
      },
      endDate() {
        this.fetchTrafficTopConsumersSources({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.showSecondChart = false
      }
    },
    async created() {
      await this.fetchTrafficTopConsumersSources({
        startDate: this.startDate,
        endDate: this.endDate
      })
    }
  }
</script>
<style lang="scss" scoped></style>
