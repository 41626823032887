<template>
  <v-container class="pa-1 pa-sm-3 pb-sm-0">
    <v-card class="elevation-12 mx-auto" max-width="500">
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>Terms and Conditions</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="pa-0 pa-sm-3">
        <!-- agreement -->
        <v-container v-html="content" class="mt-0 pt-2 content"></v-container>
      </v-card-text>
      <!-- first buttons -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :x-small="detectXS()"
          :loading="isSending"
          @click="handleAccept"
          class="mr-1"
          rounded
          color="blue"
          :dark="!showModal"
          :disabled="showModal"
          >Accept the terms</v-btn
        >
        <v-btn
          :x-small="detectXS()"
          rounded
          @click="showModal = true"
          :disabled="showModal"
          class="ml-1"
          >Decline</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>

      <TermsConfirmModal
        @decline="handleConfirmDecline"
        v-if="salon"
        :salon="salon"
        @close="showModal = false"
        :showDialog="showModal"
        message="By declining Terms and Conditions you will be automatically opted
              out as a Hairdreams referral salon"
      /> </v-card
  ></v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { detectXS } from '@/utils/helpers'
  import TermsConfirmModal from '@/components/user/TermsConfirmModal.vue'
  import { decode } from 'html-entities'

  export default {
    components: { TermsConfirmModal },
    props: ['salon'],
    data() {
      return {
        detectXS,
        showModal: false,
        isSending: false
      }
    },
    computed: {
      content() {
        return decode(this.$store.getters['lastAgreement']?.terms)
      },
      termsDate() {
        return this.$store.getters['lastAgreement']?.termsDate
      }
    },
    methods: {
      ...mapActions(['fetchSalon', 'editSalon', 'sendTermsMail']),
      handleClose() {
        this.$emit('close')
      },
      handleDecline() {
        this.$emit('decline')
        this.$router.replace({
          name: 'OptOut'
        })
        this.$emit('close')
      },
      async handleAccept() {
        this.isSending = true
        if (!this.salon.terms) {
          this.salon.terms = {}
        }
        // 1. update salon terms
        // 1a) terms text and date
        this.salon.terms.text = this.content
        this.salon.terms.date = this.termsDate

        // 1b) accept time
        this.salon.terms.accept = new Date()
        // set call only
        this.salon.callOnly = false

        // 2. emit to wf3
        this.$emit('accept')

        // 3. send to backend
        await this.editSalon(this.salon)
        this.sendTermsMail({ id: this.salon.id, termsStatus: 'accepted' })

        this.isSending = false
      },
      async handleConfirmDecline() {
        this.isSending = true
        if (!this.salon.terms) {
          this.salon.terms = {}
        }
        // 1. update salon terms
        // 1a) terms text
        this.salon.terms.text = this.content
        // 1c) decline time
        this.salon.terms.decline = new Date()
        // set call only
        this.salon.callOnly = false
        // 2. deactivate salon
        this.salon.active = false
        // 3. send to backend
        await this.editSalon(this.salon)
        this.sendTermsMail({ id: this.salon.id, termsStatus: 'declined' })

        this.isSending = false
      }
    }
  }
</script>

<style scoped>
  .content {
    max-height: 500px;
    overflow-y: scroll;
  }
</style>
