<template>
  <div>
    <div
      class="d-flex justify-center align-center"
      v-if="isLoading"
      style="min-height: 600px"
    >
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <GChart :type="type" :data="chartData" :options="options"
    /></template>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  export default {
    components: {
      GChart
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true,
        type: 'PieChart',
        options: {
          colors: [
            '#00FF00',
            '#00FF7F',
            '#00FA9A',
            '#7FFF00',
            '#90EE90',
            '#98FB98',
            '#808080',
            '#FF2400',
            '#FF7F50',
            '#FA8072',
            '#FF0000'
          ],
          title: '',
          height: 600,
          animation: { startup: true, duration: 500 },
          legend: { position: 'bottom' }
        }
      }
    },
    computed: {
      consultationsByCompletedAndBoooked() {
        return this.$store.state.analytics.consultationsByCompletedAndBoooked
      },
      chartData() {
        const data = [['Status', 'Count']]
        for (const key in this.consultationsByCompletedAndBoooked) {
          if (key !== 'total') {
            data.push([key, this.consultationsByCompletedAndBoooked[key]])
          }
        }
        // todo: da li treba ovako da bi bile zakucane boje ili je dobro i ovo iznad s obzirom na back da sam podesio redosled
        // const data = [
        //   ['Status', 'Count'],
        //   [
        //     'consYesAppUnknown',
        //     this.consultationsByCompletedAndBoooked?.consYesAppUnknown
        //   ],
        //   [
        //     'consYesAppYes',
        //     this.consultationsByCompletedAndBoooked?.consYesAppYes
        //   ],
        //   [
        //     'consYesAppNo',
        //     this.consultationsByCompletedAndBoooked?.consYesAppNo
        //   ],
        //   [
        //     'consYesAppNoPrice',
        //     this.consultationsByCompletedAndBoooked?.consYesAppNoPrice
        //   ],
        //   [
        //     'consYesAppNoRethinking',
        //     this.consultationsByCompletedAndBoooked?.consYesAppNoRethinking
        //   ],
        //   [
        //     'consYesAppNoMaintenance',
        //     this.consultationsByCompletedAndBoooked?.consYesAppNoMaintenance
        //   ],
        //   ['consUnknown', this.consultationsByCompletedAndBoooked?.consUnknown],
        //   ['consNo', this.consultationsByCompletedAndBoooked?.consYesAppNo],
        //   [
        //     'consRescheduled',
        //     this.consultationsByCompletedAndBoooked?.consRescheduled
        //   ],
        //   [
        //     'consNoNoShow',
        //     this.consultationsByCompletedAndBoooked?.consNoNoShow
        //   ],
        //   [
        //     'consNoRethinking',
        //     this.consultationsByCompletedAndBoooked?.consNoRethinking
        //   ]
        // ]
        return data
      },
      total() {
        return this.consultationsByCompletedAndBoooked?.total || 0
      }
    },
    methods: {
      ...mapActions(['fetchConsultationsByCompletedAndBoooked'])
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true

        await this.fetchConsultationsByCompletedAndBoooked({
          startDate: this.startDate,
          endDate: this.endDate
        })

        this.options.title = `Consultations: ${this.total}`
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true

        await this.fetchConsultationsByCompletedAndBoooked({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.options.title = `Consultations: ${this.total}`
        this.isLoading = false
      }
    },
    async created() {
      await this.fetchConsultationsByCompletedAndBoooked({
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.options.title = `Consultations: ${this.total}`
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped></style>
