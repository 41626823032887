<template>
  <v-container>
    <v-row class="justify-center">
      <v-row>
        <v-col cols="12" class="pl-0 pb-0">
          <v-card-title class="breakword pb-0"
            >Do you offer/do Hair Extensions?
          </v-card-title></v-col
        >
        <v-col
          v-for="option in options"
          :key="option.value"
          class="d-flex justify-center flex-column align-center pb-0"
          :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-3'"
          cols="6"
          @click="handleSelect(option.value)"
        >
          <v-btn
            depressed
            :color="answer === option.value ? 'user_cta' : 'user_btn'"
            :dark="answer === option.value"
            class="text-none mt-1"
            width="100%"
            >{{ option.text }}
          </v-btn>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { SystemOptions } from '@/config/enums'

  export default {
    data() {
      return {
        answer: '',
        options: [
          {
            text: 'Yes',
            value: 'Yes'
          },
          {
            text: 'No',
            value: 'No'
          }
        ],
        field: 'extensions',
        screen: 'stylist-extensions1',
        nextScreen: ''
      }
    },
    computed: {
      application() {
        return this.$store.state.applications.application
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        // 1. if extensions is yes
        if (this.answer === 'Yes') this.nextScreen = 'stylist-extensions2'
        // 2. if extensions is no and systems include laserbeamer
        else if (this.application.system.includes(SystemOptions.LASERBEAMER))
          this.nextScreen = 'stylist-extensions3'
        // 3. if extensions is no and system doesnt include laserbeamer
        else {
          this.nextScreen = 'stylist-restoration1'
        }

        this.setNextScreen(this.nextScreen)
      },
      handleSelect(answer) {
        this.answer = answer
        // 1. set data
        this.setApplicationData({ field: this.field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
        // 4. go to next screen
        this.$emit('next')
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field]) {
        this.answer = this.$store.state.applications.application[this.field]
        this.handleNextScreen()
        this.setIsValid(true)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    }
  }
</script>
<style scoped>
  .breakword {
    word-break: break-word;
  }
</style>
