import Vue from 'vue'
import Vuex from 'vuex'

import * as analytics from './modules/analytics.module'
import * as applications from './modules/applications.module'
import * as auth from './modules/auth.module'
import * as calls from './modules/calls.module'
import * as consumers from './modules/consumers.module'
import * as logs from './modules/logs.module'
import * as messages from './modules/messages.module'
import * as salons from './modules/salons.module'
import * as settings from './modules/settings.module'
import * as setups from './modules/setups.module'
import * as sourcesalons from './modules/sourcesalons.module'
import * as stylistsAccount from './modules/stylists-account.module'
import * as stylists from './modules/stylists.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    stylists,
    consumers,
    salons,
    messages,
    calls,
    auth,
    setups,
    logs,
    analytics,
    stylistsAccount,
    applications,
    sourcesalons,
    settings
  }
})
