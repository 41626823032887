<template>
  <v-container>
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="500" class="mx-auto mt-5">
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>More Information</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form class="form py-0" ref="form" v-model="valid">
        <v-card-text class="px-5 mt-2 d-flex flex-column">
          <v-text-field
            dense
            outlined
            :rules="[rules.required]"
            label="Salon Name *"
            v-model="salonInfo.salon"
          />

          <vue-google-autocomplete
            id="stylistAddress"
            :classname="addressClass"
            types="address"
            class="form-control"
            placeholder="Salon Address *"
            @placechanged="setAddressData"
            @no-results-found="setNoAddressFound"
            @inputChange="() => (addressData = '')"
            :country="['us', 'ca']"
          >
          </vue-google-autocomplete>
          <v-card-text
            class="caption py-0 pt-0 pl-3 error-color"
            v-if="addressError"
            >{{ addressErrorMessage }}</v-card-text
          >

          <!-- <v-text-field
          class="mt-5"
          dense
          outlined
          :rules="[rules.required]"
          label="Salon Address *"
          v-model="salonInfo.salonAddress"
        /> -->

          <!-- <v-text-field
          dense
          outlined
          :rules="[rules.required]"
          label="Salon City *"
          v-model="salonInfo.city"
        /> -->
          <!-- 
        <v-text-field
          dense
          outlined
          :rules="[rules.required]"
          label="Salon State *"
          v-model="salonInfo.state"
        /> -->

          <!-- <v-select
          dense
          outlined
          :rules="[rules.required]"
          v-model="salonInfo.country"
          :items="Object.values(StylistCountries)"
          label="Country *"
        ></v-select> -->

          <v-text-field
            :class="addressError ? 'mt-1' : 'mt-5'"
            dense
            outlined
            :rules="[rules.required]"
            label="Salon Zip Code *"
            v-model="salonInfo.salonZip"
          />

          <v-select
            dense
            outlined
            :rules="[rules.required]"
            v-model="salonInfo.addressType"
            :items="Object.values(StylistAddressType)"
            label="Is this a business or a residential address? *"
          ></v-select>

          <v-select
            dense
            outlined
            :rules="[rules.required]"
            v-model="salonInfo.mobile"
            :items="Object.values(StylistMobile)"
            label="Are you a Mobile Salon? *"
          ></v-select>

          <v-select
            dense
            outlined
            :rules="[rules.required]"
            v-model="salonInfo.salonType"
            :items="Object.values(StylistSalonType)"
            label="Salon Type *"
          ></v-select>

          <v-select
            dense
            outlined
            :rules="[rules.required]"
            v-model="salonInfo.profession"
            :items="Object.values(StylistProfession)"
            label="Please choose a profession that best describes you *"
          ></v-select>

          <v-select
            dense
            outlined
            :rules="[rules.required]"
            v-model="salonInfo.hdCertified"
            :items="Object.values(StylistHdCertified)"
            label="Have you attended any Hairdreams certifications in the past? *"
          ></v-select>

          <v-text-field
            dense
            outlined
            label="Website / Booking site"
            v-model="salonInfo.website"
          />

          <v-text-field
            dense
            outlined
            label="Instagram"
            v-model="salonInfo.instagram"
          />

          <v-text-field
            dense
            outlined
            label="Facebook"
            v-model="salonInfo.facebook"
          />

          <v-text-field
            dense
            outlined
            label="TikTok"
            v-model="salonInfo.tikTok"
          />

          <v-text-field
            denseac
            outlined
            label="Other related links"
            v-model="salonInfo.other"
            class="pt-0 mt-3"
          />

          <v-card-actions>
            <v-btn
              block
              color="user_primary"
              class="white--text"
              rounded
              @click.prevent="handleSubmit"
              :loading="isLoading"
            >
              Submit</v-btn
            >
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
  import {
    StylistAddressType,
    StylistHdCertified,
    StylistMobile,
    StylistProfession,
    StylistSalonType
  } from '@/config/enums'
  import { mapActions } from 'vuex'
  export default {
    data() {
      return {
        screen: 'stylist-additional-info',
        field: 'salonInfo',
        nextScreen: 'stylist-license',
        StylistProfession,
        StylistAddressType,
        StylistMobile,
        StylistSalonType,
        StylistHdCertified,
        valid: true,
        salonInfo: {},
        // google autocomplete
        setAddressTimeout: null,
        addressData: null,
        placeResultData: null,
        addressError: false,
        addressErrorMessage: '',
        rules: {
          required: value => !!value || 'Required.',
          minLength: value => value.length >= 6 || 'Min 6 characters',
          email: value => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },
        showPassword: false,
        showPasswordConfirm: false,
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red',
        isLoading: false
      }
    },
    computed: {
      addressClass() {
        return this.addressError
          ? 'address-field address-field--error'
          : 'address-field'
      },
      source() {
        return this.$route.query.source
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        this.setNextScreen(this.nextScreen)
      },
      async handleSelect(salonInfo, source) {
        // 1. set data
        await this.setApplicationData({
          field: this.field,
          answer: salonInfo
        })
        await this.setApplicationData({ field: 'source', answer: source })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
        // 4. go to next screen
        this.$emit('next')
      },
      setAddressData(addressData, placeResultData) {
        this.setAddressTimeout = setTimeout(() => {
          this.addressData = addressData
          this.placeResultData = placeResultData
        }, 100)
      },
      setNoAddressFound() {
        this.addressData = ''
        this.placeResultData = ''
      },
      async handleSubmit() {
        this.isLoading = true

        this.$refs.form.validate()

        const addressValid =
          !!this.salonInfo.salonAddress &&
          !!this.salonInfo.country &&
          !!this.salonInfo.lng &&
          !!this.salonInfo.lat

        if (!addressValid) {
          this.addressErrorMessage =
            'Address is incomplete. Please fill in the complete address, including the city and state.'
          this.addressError = true
          this.isLoading = false
        }

        if (!this.valid || !addressValid) {
          this.isLoading = false

          return
        }

        await this.handleSelect(this.salonInfo, this.source)
        this.isLoading = false
      }
    },
    watch: {
      addressData() {
        // set fields
        this.salonInfo.salonAddress = this.placeResultData?.formatted_address
        this.salonInfo.salonZip = this.addressData?.postal_code
        this.salonInfo.city = this.addressData?.locality
        this.salonInfo.country = this.addressData?.country
        this.salonInfo.lat = this.addressData?.latitude
        this.salonInfo.lng = this.addressData?.longitude

        this.salonInfo.location = {
          type: 'Point',
          coordinates: [this.salonInfo.lng, this.salonInfo.lat],
          formattedAddress: this.salonInfo.salonAddress
        }

        this.salonInfo.state = this.addressData?.administrative_area_level_1
        this.salonInfo.shortSalonAddress =
          this.placeResultData?.name || this.placeResultData?.formatted_address
        // validate
        const addressValid =
          this.salonInfo.salonAddress &&
          this.salonInfo.country &&
          this.salonInfo.lng &&
          this.salonInfo.lat
        if (addressValid) {
          this.addressError = false
          this.addressErrorMessage = ''
        } else {
          this.addressError = true
          this.addressErrorMessage =
            'Address is incomplete. Please fill in the complete address, including the city and state.'
        }
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)

      // if no primary system go to primary system screen
      if (!this.$store.state.applications.application.primarySystem) {
        this.$router.push({
          name: 'stylist-application-primary-system',
          query: this.$route.query
        })
      }

      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field]) {
        this.salonInfo = this.$store.state.applications.application[this.field]
        this.handleNextScreen()
        this.setIsValid(false)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    },
    destroyed() {
      clearTimeout(this.setAddressTimeout)
    }
  }
</script>

<style scoped>
  .address-field {
    width: 100%;
    min-height: 40px;
    position: relative;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    transition-property: all;
    transition-property: height, min-height;
    width: 100%;
    margin-bottom: 4px;
    background: transparent;
    padding: 0 12px;
    cursor: text;
    align-items: stretch;
    caret-color: #ff5252 !important;
    font-size: 16px;
    letter-spacing: normal;
    text-align: left;
    tab-size: 4;
    word-break: normal;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    overflow-wrap: break-word;
    white-space: normal;
    color: #212121;
    border: 1px solid #9e9e9e !important;
    border-radius: 3px !important;
  }

  .address-field--error {
    border: 2px solid #ff5252;
    color: #ff5252;
  }
  .address-field--error::-moz-placeholder {
    color: #ff5252;
    opacity: 1;
  }
  .address-field--error::placeholder {
    opacity: 1;
    color: #ff5252;
  }

  .error-message {
    text-align: left;
    font-size: 12px;
    color: #ff5252;
  }
  .error-color {
    color: #ff5252;
  }
</style>
