<template>
  <v-card
    v-if="application"
    :elevation="adminLoggedIn ? 12 : 0"
    :max-width="adminLoggedIn ? '550' : '100%'"
    :class="adminLoggedIn && 'mx-auto'"
  >
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ notification }}</v-snackbar
    >
    <v-img
      lazy-src="@/assets/userType-header.webp"
      src="@/assets/userType-header.jpg"
    ></v-img>

    <v-col cols="12" class="pl-0 pb-0">
      <v-card-title class="breakword pb-0">
        Partner Application Updates
      </v-card-title>
      <v-card-text class="pt-3 pb-0"
        >Salon name:
        <strong>{{ application.salonInfo.salon }}</strong></v-card-text
      >
      <v-card-text class="py-0"
        >Application number: <strong>{{ application._id }}</strong></v-card-text
      >
      <v-card-text class="pb-1">
        Thank you for your Hairdreams Pro Inquiry!</v-card-text
      >
      <v-card-text class="pt-0">
        Below, you will find which hair extension and/or hair loss systems you
        are approved for.</v-card-text
      >
    </v-col>

    <!-- is loading -->
    <v-card-text v-if="isLoading" class="text-center">
      <v-progress-circular
        indeterminate
        color="blue-grey"
      ></v-progress-circular>
    </v-card-text>
    <template v-else>
      <v-card-text :class="$vuetify.breakpoint.smAndDown ? 'px-0' : 'px-4'">
        <!-- finished loading -->
        <v-expansion-panels accordion :value="accordionValueOnLoad">
          <v-expansion-panel>
            <v-expansion-panel-header
              :class="$vuetify.breakpoint.smAndDown && 'pl-3 pr-1 text-body-2'"
            >
              <span
                style="width: 50px"
                class="text-left"
                :class="$vuetify.breakpoint.smAndDown && 'text-body-2'"
                >Laserbeamer Nano</span
              >
              <v-spacer></v-spacer>

              <v-icon v-if="statusLB === 'Not approved'" color="error">
                mdi-alert-circle
              </v-icon>
              <v-icon v-if="statusLB === 'Approved'" color="teal">
                mdi-check
              </v-icon>
              <v-icon v-if="statusLB === 'Pending'" color="warning">
                mdi-clock-outline
              </v-icon>

              <v-icon v-if="statusLB === 'Continue'" color="error">
                mdi-dots-horizontal
              </v-icon>
              {{ statusLB }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <span v-if="statusLB === 'Not approved'">
                Unfortunately, your application has not been approved. Below,
                you'll find the reasons why:
              </span>
              <ul>
                <li v-html="nanoTextAndLinks.answer"></li>
                <!-- {{ answer }} -->
              </ul>
              <span v-if="statusLB === 'Not approved'">
                If any of the reasons are inaccurate, please
                <v-btn
                  text
                  depressed
                  color="blue"
                  class="px-0 pb-0 btn-resetcase"
                  @click="handleShowMessageBox"
                  >send us a message</v-btn
                >
              </span>
              <button
                v-for="(link, index) in nanoTextAndLinks.links"
                :key="index"
                class="link-btn"
                @click="handleSeeMore(link, 'lbClicked')"
              >
                {{ link.btnText || 'Check now' }}
              </button>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              :class="$vuetify.breakpoint.smAndDown && 'pl-3 pr-1 text-body-2'"
            >
              <span style="width: 50px" class="text-left"
                >MicroLines System</span
              >
              <v-spacer></v-spacer>
              <v-icon v-if="statusML === 'Not approved'" color="error">
                mdi-alert-circle
              </v-icon>
              <v-icon v-if="statusML === 'Approved'" color="teal">
                mdi-check
              </v-icon>
              <v-icon v-if="statusML === 'Pending'" color="warning">
                mdi-clock-outline
              </v-icon>
              <v-icon v-if="statusML === 'Continue'" color="error">
                mdi-dots-horizontal </v-icon
              >{{ statusML }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <span v-if="statusML === 'Not approved'">
                Unfortunately, your application has not been approved. Below,
                you'll find the reasons why:
              </span>
              <ul>
                <div v-html="microlinesTextAndLinks.answer"></div>
                <!-- {{ answer }} -->
              </ul>
              <span v-if="statusML === 'Not approved'">
                If any of the reasons are inaccurate, please
                <v-btn
                  text
                  depressed
                  color="blue"
                  class="px-0 pb-0 btn-resetcase"
                  @click="handleShowMessageBox"
                  >send us a message</v-btn
                >
              </span>
              <button
                v-for="(link, index) in microlinesTextAndLinks.links"
                :key="index"
                class="link-btn"
                @click="handleSeeMore(link, 'mlClicked')"
              >
                {{ link.btnText || 'Check now' }}
              </button>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              :class="$vuetify.breakpoint.smAndDown && 'pl-3 pr-1 text-body-2'"
            >
              <span style="width: 50px" class="text-left">FreeStyler</span>
              <v-spacer></v-spacer>
              <v-icon v-if="statusFS === 'Not approved'" color="error">
                mdi-alert-circle
              </v-icon>
              <v-icon v-if="statusFS === 'Approved'" color="teal">
                mdi-check
              </v-icon>
              <v-icon v-if="statusFS === 'Pending'" color="warning">
                mdi-clock-outline
              </v-icon>
              <v-icon v-if="statusFS === 'Continue'" color="error">
                mdi-dots-horizontal </v-icon
              >{{ statusFS }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <span v-if="statusFS === 'Not approved'">
                Unfortunately, your application has not been approved. Below,
                you'll find the reasons why:
              </span>
              <ul>
                <div v-html="freestylerTextAndLinks.answer"></div>
                <!-- {{ answer }} -->
              </ul>
              <span v-if="statusFS === 'Not approved'">
                If any of the reasons are inaccurate, please
                <v-btn
                  text
                  depressed
                  color="blue"
                  class="px-0 pb-0 btn-resetcase"
                  @click="handleShowMessageBox"
                  >send us a message</v-btn
                >
              </span>
              <button
                v-for="(link, index) in freestylerTextAndLinks.links"
                :key="index"
                class="link-btn"
                @click="handleSeeMore(link, 'fsClicked')"
              >
                {{ link.btnText || 'Check now' }}
              </button>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header
              :class="$vuetify.breakpoint.smAndDown && 'pl-3 pr-1 text-body-2'"
            >
              <span style="width: 50px" class="text-left">Tape-Ins</span>
              <v-spacer></v-spacer>
              <v-icon v-if="statusTA === 'Not approved'" color="error">
                mdi-alert-circle
              </v-icon>
              <v-icon
                v-if="statusTA === 'Approved' || statusTA === 'forceTA'"
                color="teal"
              >
                mdi-check
              </v-icon>
              <v-icon v-if="statusTA === 'Pending'" color="warning">
                mdi-clock-outline
              </v-icon>
              <v-icon v-if="statusTA === 'Continue'" color="error">
                mdi-dots-horizontal
              </v-icon>

              {{ statusTA }}
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <span v-if="statusTA === 'Not approved'">
                Unfortunately, your application has not been approved. Below,
                you'll find the reasons why:
              </span>
              <ul>
                <div v-html="tapeTextAndLinks.answer"></div>
                <!-- {{ answer }} -->
              </ul>
              <span v-if="statusTA === 'Not approved'">
                If any of the reasons are inaccurate, please
                <v-btn
                  text
                  depressed
                  color="blue"
                  class="px-0 pb-0 btn-resetcase"
                  @click="handleShowMessageBox"
                  >send us a message</v-btn
                >
              </span>
              <button
                v-for="(link, index) in tapeTextAndLinks.links"
                :key="index"
                class="link-btn"
                @click="handleSeeMore(link, 'taClicked')"
              >
                {{ link.btnText || 'Check now' }}
              </button>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <template v-if="!adminLoggedIn">
        <v-card-text>
          Disclaimer: This is an automated system, and your approval status
          might change based on the information provided after review
          verification.
        </v-card-text>
        <v-card-text class="pt-0">
          You can always send us a message if you think there are some
          inaccuracies on your submission.
          <v-btn
            plain
            text
            depressed
            color="blue"
            small
            class="pa-1 btn-resetcase"
            @click="handleShowMessageBox"
            >Send a message</v-btn
          >
        </v-card-text>

        <transition name="fade">
          <v-card-text v-if="showMessageBox">
            <v-textarea
              ref="messageBox"
              label="Send a message"
              class="px-5 mt-0"
              auto-grow
              outlined
              rows="2"
              hide-details
              v-model="messageText"
            ></v-textarea>
            <v-card-actions class="px-10 pt-4"
              ><v-btn
                block
                rounded
                dark
                :loading="isSendingMessage"
                @click="handleSendMessage"
                >Send</v-btn
              ></v-card-actions
            >
          </v-card-text></transition
        >
      </template>
    </template>
    <NotificationModal
      message="Test"
      :show-actions="false"
      :show="showNotificationModal"
      @close="handleCloseNotificationModal"
    >
      <template v-slot:content>
        <div>
          Message sent. <br />
          <br />
          PLEASE NOTE: All replies will be sent to {{ salonUser.email }}. We
          usually reply within 1 business day. <br />
          <br />
          Please check your spam folder if you don’t see a reply
        </div>
      </template>
    </NotificationModal>
  </v-card>
</template>

<script>
  import NotificationModal from '@/components/NotificationModal'
  import {
    StylistProfession,
    StylistsCertifications,
    SystemOptions
  } from '@/config/enums'
  import { mapActions } from 'vuex'

  export default {
    components: {
      NotificationModal
    },
    data() {
      return {
        showNotificationModal: false,
        StylistProfession,
        StylistsCertifications,
        SystemOptions,
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'green',
        notification: '',
        showMessageBox: false,
        isSendingMessage: false,
        isLoading: true,
        messageText: '',
        answer: '',
        screen: 'stylist-final-status',
        nextScreen: ''
      }
    },
    computed: {
      accordionValueOnLoad() {
        switch (this.application.primarySystem) {
          case this.SystemOptions.LASERBEAMER:
            return 0
          case this.SystemOptions.MICROLINES:
            return 1
          case this.SystemOptions.FREESTYLER:
            return 2
          case this.SystemOptions.TAPE:
            return 4
          default:
            return 1000
        }
      },
      application() {
        return this.$store.state.applications.application
      },
      adminLoggedIn() {
        return this.$store.getters.adminLoggedIn
      },
      statuses() {
        return {
          MICROLINES: {
            'Not approved': () => {
              let text = ''
              const links = []
              if (
                this.$store.getters.distanceApproved === 'No' ||
                this.$store.getters.forceML === 'No'
              ) {
                text +=
                  '<li>We do not have an opening in your area for a new Hairdreams Partner Salon at this time. We will put you on a waiting list in the event that an opening becomes available.</li>'

                // note: RETURN HERE don't check others
                return { text, links }
              }
              if (
                this.application.salonInfo.mobile === 'Yes' ||
                this.application.salonInfo.addressType === 'Residential' ||
                this.application.salonInfo.salonType === 'Home'
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to mobile/residential/home salons..</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.COSMETOLOGY_STUDENT
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to cosmetology students. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.ESTHETICIAN
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to licensed estheticians. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.NAIL_TECH
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to licensed nail technicians. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.microlinesApplicationSpend ===
                  '$100 to $500' ||
                this.application.microlinesApplicationSpend === '$500 to $1000'
              ) {
                text += `<li>Based on your answer of ${this.application.microlinesApplicationSpend} for what you feel your clients are willing to spend for a MicroLines+ application, we are not sure if this would be a good fit for your salon. Typically, a salon will charge $2000+.</li>`
              }
              if (
                this.application.microlinesMaintenanceSpend === '$50 or less'
              ) {
                text += `<li>Based on your answer of ${this.application.microlinesMaintenanceSpend} for what you feel your clients are willing to spend for monthly maintenance, we are not sure if this would be a good fit for your salon. Typically, the monthly maintenance averages around $250.</li>`
              }
              if (
                this.application.microlinesClientNumber === '0 to 1' &&
                (this.application.ads === 'I do not advertise' ||
                  this.application.ads === '$100 to less' ||
                  this.application.ads === '$100 to $300')
              ) {
                text +=
                  '<li>Based on having so few clients with a need for our system, we are not sure if this would be a good investment unless you consider spending additional money on advertising to bring in new clients outside of your current client base.</li>'
              }
              return { text, links }
            },
            Approved: () => {
              let text =
                '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
              let links = [
                {
                  newTab: true,
                  type: 'internal',
                  name: 'stylist-certification',
                  params: {
                    stylistId: this.$route.params.stylistId,
                    certification:
                      (this.application.extensions === 'No' &&
                        this.application.toppers === 'No' &&
                        !this.application.toppersBlend) ||
                      (this.application.extensions === 'No' &&
                        this.application.toppers === 'Yes' &&
                        this.application.toppersBlend === 'No') ||
                      (this.application.extensions === 'No' &&
                        this.application.toppers === 'Yes' &&
                        this.application.toppersBlend === 'Little to none') ||
                      this.application.forceML_Cut === 'Yes'
                        ? StylistsCertifications.ML_CUTTING
                        : StylistsCertifications.ML_REGULAR
                  }
                }
              ]

              return { text, links }
            },
            Continue: () => {
              let text = ''
              let links = []
              if (!this.application.toppers) {
                text +=
                  '<li>You can apply for MicroLines, our topper hair loss system! Check instantly by answering 5 questions.</li>'
                links = [
                  {
                    type: 'internal',
                    name: 'stylist-restoration1',
                    btnText: 'Continue',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      applicationId: this.$route.params.applicationId
                    },
                    continueApplication: true
                  }
                ]
              } else if (!this.application.system) {
                text +=
                  '<li>You can apply for MicroLines, our topper hair loss system! Check instantly by answering 10 questions.</li>'
                links = [
                  {
                    type: 'internal',
                    name: 'stylist-system1',
                    btnText: 'Continue',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      applicationId: this.$route.params.applicationId
                    },
                    continueApplication: true
                  }
                ]
              }
              return { text, links }
            },
            Pending: () => {
              return {
                text:
                  this.application.closeToLasVegasDistance ||
                  !this.application.noASalonsCloserThan4Miles ||
                  !this.application.noBSalonsCloserThan1Mile ||
                  !this.application.noCSalonsCloserThan1Mile ||
                  !this.application.noSSalonsCloserThan8Miles
                    ? '<li>We are double-checking to see if we have an opening in your area for a new Hairdreams Partner Salon. Our New Client Department will reach out within 2 business days to let you know if we do or do not have an opening, along with additional information. If we do not have an opening, we will put you on a waiting list in the event an opening becomes available.</li>'
                    : '',
                links: []
              }
            }
          },
          FREESTYLER: {
            'Not approved': () => {
              let text = ''
              const links = []
              if (this.$store.getters.distanceApproved === 'No') {
                text +=
                  '<li>We do not have an opening; we will put you on a waiting list in the event an opening becomes available.</li>'
                // note: RETURN HERE don't check others
                return { text, links }
              }
              if (this.$store.getters.forceFS === 'No') {
                text +=
                  '<li>We do not have an opening in your area for a new Hairdreams Partner Salon at this time. We will put you on a waiting list in the event that an opening becomes available.</li>'
                // note: RETURN HERE don't check others
                return { text, links }
              }
              if (
                this.application.salonInfo.mobile === 'Yes' ||
                this.application.salonInfo.addressType === 'Residential' ||
                this.application.salonInfo.salonType === 'Home'
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to mobile/residential/home salons..</li>"
              }
              if (
                this.application.extensionsClientNumber === '0 to 1' &&
                (this.application.ads === 'I do not advertise' ||
                  this.application.ads === '$100 to less' ||
                  this.application.ads === '$100 to $300')
              ) {
                text +=
                  '<li>Based on having so few clients with a need for our system, we are not sure if this would be a good investment unless you consider spending additional money on advertising to bring in new clients outside of your current client base.</li>'
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.COSMETOLOGY_STUDENT
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to cosmetology students. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.ESTHETICIAN
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to licensed estheticians. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.NAIL_TECH
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to licensed nail technicians. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              return { text, links }
            },
            Pending: () => {
              return {
                text:
                  this.application.closeToLasVegasDistance ||
                  !this.application.noASalonsCloserThan4Miles ||
                  !this.application.noBSalonsCloserThan1Mile ||
                  !this.application.noCSalonsCloserThan1Mile ||
                  !this.application.noSSalonsCloserThan8Miles
                    ? '<li>We are double-checking to see if we have an opening in your area for a new Hairdreams Partner Salon. Our New Client Department will reach out within 2 business days to let you know if we do or do not have an opening, along with additional information. If we do not have an opening, we will put you on a waiting list in the event an opening becomes available.</li>'
                    : '',
                links: []
              }
            },
            Approved: () => {
              let text = ''
              let links = []
              if (this.$store.getters.distanceApproved === 'Yes') {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification:
                        this.application.extensionClientsFusion === '3 to 5' ||
                        this.application.extensionClientsFusion === '6 to 9' ||
                        this.application.extensionClientsFusion === '10 to 20+'
                          ? //   ||
                            // this.application.extensionClientsFusion === '20+'
                            StylistsCertifications.FS_ONLINE
                          : StylistsCertifications.FS_IN_PERSON
                    }
                  }
                ]
              } else if (this.$store.getters.forceFS_InSalon === 'Yes') {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification: StylistsCertifications.FS_IN_PERSON
                    }
                  }
                ]
              } else if (this.$store.getters.forceFS_Online === 'Yes') {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification: StylistsCertifications.FS_ONLINE
                    }
                  }
                ]
              } else if (
                this.application.extensionClientsFusion === '3 to 5' ||
                this.application.extensionClientsFusion === '6 to 9' ||
                this.application.extensionClientsFusion === '10 to 20+'
                // ||
                // this.application.extensionClientsFusion === '20+'
              ) {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification: StylistsCertifications.FS_ONLINE
                    }
                  }
                ]
              } else {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification: StylistsCertifications.FS_IN_PERSON
                    }
                  }
                ]
              }
              return { text, links }
            },
            Continue: () => {
              return {
                text: !this.application.system
                  ? '<li>You can apply for FreeStyler, our extension system for customizable bonds! Check instantly by answering 5 questions.</li>'
                  : '',
                links: !this.application.system
                  ? [
                      {
                        type: 'internal',
                        name: 'stylist-system1',
                        params: {
                          stylistId: this.$route.params.stylistId,
                          applicationId: this.$route.params.applicationId
                        },
                        continueApplication: true
                      }
                    ]
                  : []
              }
            }
          },
          NANO: {
            'Not approved': () => {
              let text = ''
              const links = []
              if (this.$store.getters.distanceApproved === 'No') {
                text +=
                  '<li>We do not have an opening; we will put you on a waiting list in the event an opening becomes available.</li>'
                // note: RETURN HERE don't check others
                return { text, links }
              }
              if (this.$store.getters.forceLB === 'No') {
                text +=
                  '<li>We do not have an opening in your area for a new Hairdreams Partner Salon at this time. We will put you on a waiting list in the event that an opening becomes available.</li>'
                // note: RETURN HERE don't check others
                return { text, links }
              }
              if (
                this.application.salonInfo.mobile === 'Yes' ||
                this.application.salonInfo.addressType === 'Residential' ||
                this.application.salonInfo.salonType === 'Home'
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to mobile/residential/home salons..</li>"
              }
              if (
                this.application.extensionsClientNumber === '0 to 1' &&
                (this.application.ads === 'I do not advertise' ||
                  this.application.ads === '$100 to less' ||
                  this.application.ads === '$100 to $300')
              ) {
                text +=
                  '<li>Based on having so few clients with a need for our system, we are not sure if this would be a good investment unless you consider spending additional money on advertising to bring in new clients outside of your current client base.</li>'
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.COSMETOLOGY_STUDENT
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to cosmetology students. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.ESTHETICIAN
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to licensed estheticians. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.NAIL_TECH
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to licensed nail technicians. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              return { text, links }
            },
            Pending: () => {
              return {
                text:
                  this.application.closeToLasVegasDistance ||
                  !this.application.noASalonsCloserThan4Miles ||
                  !this.application.noBSalonsCloserThan1Mile ||
                  !this.application.noCSalonsCloserThan1Mile ||
                  !this.application.noSSalonsCloserThan8Miles
                    ? '<li>We are double-checking to see if we have an opening in your area for a new Hairdreams Partner Salon. Our New Client Department will reach out within 2 business days to let you know if we do or do not have an opening, along with additional information. If we do not have an opening, we will put you on a waiting list in the event an opening becomes available.</li>'
                    : '',
                links: []
              }
            },
            Approved: () => {
              let text = ''
              let links = []
              if (this.$store.getters.distanceApproved === 'Yes') {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                if (this.application.extensions === 'No') {
                  links = [
                    {
                      newTab: true,
                      type: 'internal',
                      name: 'stylist-certification',
                      params: {
                        stylistId: this.$route.params.stylistId,
                        certification: StylistsCertifications.LB_FULL
                      }
                    }
                  ]
                } else {
                  links = [
                    {
                      newTab: true,
                      type: 'internal',
                      name: 'stylist-certification',
                      params: {
                        stylistId: this.$route.params.stylistId,
                        certification: StylistsCertifications.LB_HALF
                      }
                    }
                  ]
                }
              } else if (this.$store.getters.forceLB_Full_Head === 'Yes') {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification: StylistsCertifications.LB_FULL
                    }
                  }
                ]
              } else if (this.$store.getters.forceLB_Half_Head === 'Yes') {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification: StylistsCertifications.LB_HALF
                    }
                  }
                ]
              } else if (this.application.extensions === 'No') {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification: StylistsCertifications.LB_FULL
                    }
                  }
                ]
              } else if (this.application.extensions === 'Yes') {
                text +=
                  '<li>Your application has been accepted. You should receive an email correspondence from a specialist within 2 business days.<br>To help you get started, here is some information on our current promotions:</li>'
                links = [
                  {
                    newTab: true,
                    type: 'internal',
                    name: 'stylist-certification',
                    params: {
                      stylistId: this.$route.params.stylistId,
                      certification: StylistsCertifications.LB_HALF
                    }
                  }
                ]
              }
              return { text, links }
            },
            Continue: () => {
              return {
                text: !this.application.system
                  ? '<li>You can apply for Laserbeamer Nano, our extension system for customizable bonds! Check instantly by answering 5 questions</li>'
                  : '',
                links: !this.application.system
                  ? [
                      {
                        type: 'internal',
                        name: 'stylist-system1',
                        params: {
                          stylistId: this.$route.params.stylistId,
                          applicationId: this.$route.params.applicationId
                        },
                        continueApplication: true
                      }
                    ]
                  : []
              }
            }
          },
          TAPE: {
            'Not approved': () => {
              let text = ''
              let links = []
              if (this.$store.getters.forceTA === 'No') {
                text =
                  "<li>We are sorry; there was a problem approving your account. You'll be emailed with more information.</li>"
                // note: RETURN HERE don't check others
                return { text, links }
              }
              if (this.$store.getters.licenseApproved === 'No') {
                text =
                  "<li>It seems like you didn't upload a valid cosmetology license, so we weren't able to approve your account. To shop for Hairdreams Tapes online, create an account here and make sure to upload a current, valid cosmetology license, issued from the state you do business in.</li>"
                // note: RETURN HERE don't check others
                return { text, links }
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.COSMETOLOGY_STUDENT
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to cosmetology students. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.ESTHETICIAN
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to licensed estheticians. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              if (
                this.application.salonInfo.profession ===
                StylistProfession.NAIL_TECH
              ) {
                text +=
                  "<li>Hairdreams doesn't offer this system to licensed nail technicians. A valid current cosmetology license in hairdressing/barbering is required.</li>"
              }
              return {
                text,
                links
              }
            },
            Approved: () => {
              let text = ''
              let links = []
              if (this.application.alreadyInTheSystem) {
                text +=
                  '<li>Your Hairdreams Tapes account has previously been approved and set up.'
                links = [
                  {
                    type: 'external',
                    path: 'https://hairdreamstapes.com/account/login',
                    btnText: 'Shop Now'
                  }
                ]
              } else if (
                this.$store.getters.licenseApproved === 'Yes' ||
                this.$store.getters.forceTA === 'Yes'
              ) {
                text +=
                  '<li>Your application is approved! No extra certification needed for Tape-ins - you can shop now at our Hairdreams Tapes Online Store! Click to set a password or login if you already have one.</li>'

                links = [
                  {
                    type: 'external',
                    path: this.application.account.activationUrlShopify,
                    btnText: 'Shop Online'
                  }
                ]
              }
              return {
                text,
                links
              }
            },
            Pending: () => {
              let text = ''
              const links = []
              if (!!this.application.cosmetologyLicenseFile) {
                text +=
                  "<li>We are currently verifying your cosmetology license. Please allow up to 2 business days for processing. Once approved, you'll gain immediate access to the Hairdreams Tapes online shop.</li>"
              }
              if (this.application.salonInfo.country === 'Canada') {
                text =
                  '<li>Due to duties and taxes, online ordering of Hairdreams Tape-ins is unavailable in Canada. You may be eligible for a phone-in type of account. Call Hairdreams at 888-434-2471 to find out more.</li>'
              }
              return { text, links }
            },
            Continue: () => {
              return {
                text:
                  !this.application.cosmetologyLicenseFile &&
                  this.application.salonInfo.country === 'United States'
                    ? '<li>Upload your cosmetology license to expedite the approval process for this system!</li>'
                    : '',
                links:
                  !this.application.cosmetologyLicenseFile &&
                  this.application.salonInfo.country === 'United States'
                    ? [
                        {
                          type: 'internal',
                          name: 'stylist-license',
                          params: {
                            applicationId: this.$route.params.applicationId,
                            stylistId: this.$route.params.stylistId
                          },
                          btnText: 'Upload',
                          continueApplication: true
                        }
                      ]
                    : []
              }
            }
          }
        }
      },
      statusML() {
        return this.$store.getters.statusML
      },
      statusLB() {
        return this.$store.getters.statusLB
      },
      statusTA() {
        return this.$store.getters.statusTA
      },
      statusFS() {
        return this.$store.getters.statusFS
      },
      approvedMicrolinesCondition() {
        return (
          this.application.microlinesApplicationSpend === '$1000 to $2000' ||
          this.application.microlinesApplicationSpend === '$2000 to $3000+'
        )
      },
      approvedMicrolinesCondition1() {
        return (
          this.application.microlinesMaintenanceSpend === '$50 to $100' ||
          this.application.microlinesMaintenanceSpend === '$100 to $200' ||
          this.application.microlinesMaintenanceSpend === '$200 to $400'
          // ||
          // this.application.microlinesMaintenanceSpend === '$400+'
        )
      },
      approvedMicrolinesCondition2() {
        return (
          this.application.microlinesClientNumber === '1 to 2' ||
          this.application.microlinesClientNumber === '3 to 5' ||
          this.application.microlinesClientNumber === '6 to 9' ||
          this.application.microlinesClientNumber === '10 to 20' ||
          this.application.microlinesClientNumber === '20+' ||
          this.application.ads === '$300 to $500' ||
          this.application.ads === '$500 to $1000' ||
          this.application.ads === '$1000 to $2500+'
          // ||
          // this.application.ads === '$2500 to $5000' ||
          // this.application.ads === '$5000+'
        )
      },
      microlinesTextAndLinks() {
        if (!this.statusML)
          return { answer: 'Not enough info entered.', links: [] }

        return {
          answer: this.statuses.MICROLINES[this.statusML]().text,
          links: this.statuses.MICROLINES[this.statusML]().links
        }
      },
      freestylerTextAndLinks() {
        if (!this.statusFS)
          return { answer: 'Not enough info entered.', links: [] }

        return {
          answer: this.statuses.FREESTYLER[this.statusFS]().text,
          links: this.statuses.FREESTYLER[this.statusFS]().links
        }
      },
      nanoTextAndLinks() {
        if (!this.statusLB)
          return { answer: 'Not enough info entered.', links: [] }

        return {
          answer: this.statuses.NANO[this.statusLB]().text,
          links: this.statuses.NANO[this.statusLB]().links
        }
      },
      tapeTextAndLinks() {
        if (!this.statusTA)
          return { answer: 'Not enough info entered.', links: [] }

        return {
          answer: this.statuses.TAPE[this.statusTA]().text,
          links: this.statuses.TAPE[this.statusTA]().links
        }
      },
      salonUser() {
        if (this.$store.state.auth.user) return this.$store.state.auth.user
        else return true
      }
    },

    methods: {
      ...mapActions([
        'setCurrentScreen',
        'setNextScreen',
        'setScreenHistory',
        'calculateStatuses',
        'send_st_h',
        'editApplication',
        'fetchApplication'
      ]),
      handleCloseNotificationModal() {
        this.showNotificationModal = false
      },
      scrollToBottom() {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth' // Optional: Add smooth scrolling
        })
      },
      handleShowMessageBox() {
        this.showMessageBox = true
        this.scrollToBottom()

        this.$nextTick(() => {
          this.$refs.messageBox.focus()
        })
      },
      async handleSendMessage() {
        this.isSendingMessage = true
        await this.send_st_h({
          stylist: this.$route.params.stylistId,
          body: this.messageText
        })
        await this.calculateStatuses({
          id: this.$route.params.applicationId,
          sendEmail: false
        })
        if (!this.$store.state.messages.error) {
          this.isSendingMessage = false
          // this.messageText = ''
          // this.snackbarColor = 'green'
          // this.notification = 'Message sent'
          // this.snackbar = true
          this.showNotificationModal = true
          this.messageText = ''
        } else {
          this.isSendingMessage = false
          this.snackbarColor = 'red'
          this.notification = 'Something went wrong with sending message'
          this.snackbar = true
        }
      },
      async handleSeeMore(link, clickedField) {
        // if (this.adminLoggedIn) return

        if (
          link.btnText === 'Check now' ||
          link.btnText === 'Shop Online' ||
          !link.btnText
        ) {
          await this.editApplication({
            _id: this.$route.params.applicationId,
            [clickedField]: true
          })
        }

        if (link.type === 'external') {
          window.open(link.path, '_blank')
        } else if (link.type === 'internal') {
          // If the link is a continue link, set the application to unfinished
          if (link.continueApplication) {
            this.isLoading = true
            await this.editApplication({
              _id: this.$route.params.applicationId,
              finished: false
            })
            this.isLoading = false
          }
          if (link.newTab) {
            // Get the URL for the desired route
            const routeUrl = this.$router.resolve({
              name: link.name,
              params: link.params
            }).href
            // Open the route in a new tab
            window.open(routeUrl, '_blank')
          }
          // Otherwise, navigate to the route in the current tab
          else {
            this.$router.push({
              name: link.name,
              params: link.params,
              query: this.$route.query
            })
          }
        }
      },
      handleNextScreen() {
        this.setNextScreen(this.nextScreen)
      }
    },
    async created() {
      if (this.adminLoggedIn) {
        await this.fetchApplication(this.$route.params.applicationId)
      } else {
        // set current screen
        this.setCurrentScreen(this.screen)
        // set next screen
        this.handleNextScreen()
        // clear screen history
        this.setScreenHistory([])
        // calculate statuses
        await this.calculateStatuses({
          id: this.$route.params.applicationId,
          sendEmail: !this.application.finalStatusPageEmailSent
        })
      }
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped>
  .link-btn {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 15px;
    padding: 6px 12px;
    border: 2px solid #385898;
    color: #385898;

    &:hover {
      text-decoration: underline;
    }
  }
  .breakword {
    word-break: break-word;
  }
</style>
