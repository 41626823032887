<template>
  <v-container
    class="ma-0 pa-0 mx-auto pt-0"
    v-if="!$store.state.salons.loading"
  >
    <v-card class="elevation-12 mx-auto pb-5" max-width="400">
      <v-toolbar dark color="primary">
        <v-toolbar-title class="mx-auto">SEND EMAILS MANUALLY</v-toolbar-title>
      </v-toolbar>

      <!-- note: SALONS -->
      <v-card-text>
        <v-card-title class="d-flex justify-center pb-0"
          >Invite Salons</v-card-title
        >
        <v-card-actions class="pt-5">
          <v-spacer></v-spacer>
          <v-btn
            width="90%"
            v-if="!isSalonsSent"
            @click="handleSalonInvite"
            color="primary"
            dark
            :loading="isSalonsSending"
            >Send Intro Email to Salons</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>

          <v-btn
            width="90%"
            v-if="!isSalonsSentTest"
            @click="handleSalonInviteTest"
            color="primary"
            dark
            :loading="isSalonsSendingTest"
            >Test</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text v-if="isSalonsSent" class="text-center">
          Emails sent. (this feature is not active yet!)
        </v-card-text>
        <v-card-text v-if="isSalonsSentTest" class="text-center">
          Emails sent. (only test salons)
        </v-card-text>
        <!-- <v-divider class="mt-5"></v-divider> -->
      </v-card-text>

      <!-- note: CONSUMERS
      <v-card-text class="pt-0">
        <v-card-title class="d-flex justify-center"
          >Consumers Discount and Follow Up</v-card-title
        >

        <v-row>
          <v-col cols="12"
            ><v-text-field
              label="Consumer Id"
              outlined
              hide-details
              v-model="consumerId"
            ></v-text-field
          ></v-col>
          <v-col cols="12"
            ><v-text-field
              label="Salon Id"
              outlined
              hide-details
              v-model="salonId"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row>
          <v-col cols="12 d-flex justify-center">
            <v-btn
              width="90%"
              :disabled="!consumerId || !salonId"
              @click="handleConsumerDiscount"
              color="primary"
              :loading="isDiscountSending"
              >Send a Discount email</v-btn
            >
          </v-col>
          <v-col cols="12 d-flex justify-center">
            <v-btn
              width="90%"
              :disabled="!consumerId || !salonId"
              @click="handleConsumerFollowUp"
              color="primary"
              :loading="isFollowUpSending"
              >Send a Follow Up email</v-btn
            >
          </v-col>
        </v-row>

        <v-card-text v-if="consumerSuccessMessage" class="text-center">
          {{ consumerSuccessMessage }}
        </v-card-text>
      </v-card-text> -->
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        isSalonsSent: false,
        isSalonsSending: false,
        isSalonsSentTest: false,
        isSalonsSendingTest: false,
        isDiscountSending: false,
        isFollowUpSending: false,
        consumerId: '',
        salonId: '',
        timeout: null,
        consumerSuccessMessage: ''
      }
    },
    methods: {
      ...mapActions([
        'sendSalonsInviteMail',
        'sendSalonsInviteMailTest',
        'sendDiscountMail',
        'sendFollowUpMail'
      ]),
      async handleSalonInvite() {
        this.isSalonsSending = true
        await this.sendSalonsInviteMail()
        if (!this.$store.state.salons.error) this.isSalonsSent = true

        this.isSalonsSending = false
      },
      async handleSalonInviteTest() {
        this.isSalonsSendingTest = true
        await this.sendSalonsInviteMailTest()
        if (!this.$store.state.salons.error) this.isSalonsSentTest = true
        this.isSalonsSendingTest = false
      }
      // async handleConsumerDiscount() {
      //   this.isDiscountSending = true
      //   await this.sendDiscountMail({
      //     consumerId: this.consumerId,
      //     salonId: this.salonId
      //   })
      //   if (!this.$store.state.consumers.error)
      //     this.consumerSuccessMessage = 'Discount e-mail sent to consumer.'
      //   this.isDiscountSending = false
      // },
      // async handleConsumerFollowUp() {
      //   this.isFollowUpSending = true
      //   await this.sendFollowUpMail({
      //     consumerId: this.consumerId,
      //     salonId: this.salonId
      //   })
      //   if (!this.$store.state.consumers.error)
      //     this.consumerSuccessMessage =
      //       'Follow up e-mail sent to consumer and Hairdreams.'
      //   this.isFollowUpSending = false
      // }
    }
  }
</script>
