<template>
  <v-card class="elevation-0 mx-auto" max-width="550">
    <!-- title: system -->
    <Title :text="source.title" v-if="action" />
    <!-- content -->
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <ImageBox :options="source.items[1]" @action="handleImageAction" />
        </v-col>
        <!-- note: if mobile show button after first image -->
        <ButtonBox
          class="mx-auto mb-4"
          v-if="action && $vuetify.breakpoint.smAndDown"
          big-text
          :text="source.btnBoxText"
          :btnText="source.btnText"
          @click="handleButtonClick"
        />
        <v-col cols="12" md="6" class="">
          <ImageBox :options="source.items[2]" @action="handleImageAction" />

          <ImageBox
            class="mt-4"
            :options="source.items[3]"
            @action="handleImageAction"
          />
        </v-col>
      </v-row>

      <ButtonBox
        v-if="action"
        big-text
        :text="source.btnBoxText"
        :btnText="source.btnText"
        @click="handleButtonClick"
      />

      <v-row class="mt-3">
        <v-col cols="12" md="6">
          <ImageBox :options="source.items[4]" @action="handleImageAction" />
          <ImageBox
            class="mt-4"
            :options="source.items[7]"
            @action="handleImageAction"
          />
          <ImageBox
            class="mt-4"
            :options="source.items[8]"
            @action="handleImageAction"
          />
        </v-col>
        <v-col cols="12" md="6" class="">
          <ImageBox :options="source.items[5]" @action="handleImageAction" />
          <ImageBox
            class="mt-4"
            :options="source.items[6]"
            @action="handleImageAction"
          />
          <ImageBox
            class="mt-4"
            :options="source.items[9]"
            @action="handleImageAction"
          />
          <ImageBox
            class="mt-4"
            :options="source.items[10]"
            @action="handleImageAction"
          />
        </v-col>
      </v-row>

      <ButtonBox
        v-if="action"
        big-text
        :text="source.btnBoxText"
        :btnText="source.btnText"
        @click="handleButtonClick"
      />

      <v-row class="mt-3">
        <v-col cols="12" md="6">
          <ImageBox :options="source.items[11]" @action="handleImageAction" />
        </v-col>
        <v-col cols="12" md="6" class="">
          <ImageBox :options="source.items[12]" @action="handleImageAction" />
          <ImageBox
            class="mt-4"
            :options="source.items[13]"
            @action="handleImageAction"
          />
        </v-col>
      </v-row>

      <ButtonBox
        v-if="action"
        big-text
        :text="source.btnBoxText"
        :btnText="source.btnText"
        @click="handleButtonClick"
      />

      <PopUp
        :action="action"
        :show="showPopUp"
        :options="options"
        @close="handleClosePopUp"
        @btn-click="handleButtonClick"
        :cert-page="certPage"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import ButtonBox from '../ButtonBox.vue'
  import ImageBox from '../ImageBox.vue'
  import Title from '../Title.vue'
  import PopUp from '../PopUp.vue'

  const defaultOptions = {
    img: '',
    hasAction: false,
    video: '',
    popUpImg: '',
    text: '',
    popUpBtnText: ''
  }

  export default {
    components: {
      ImageBox,
      ButtonBox,
      Title,
      PopUp
    },
    props: {
      source: {
        type: Object
      },
      action: {
        type: Boolean,
        default: true
      },
      certPage: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        showPopUp: false,
        options: defaultOptions
      }
    },
    methods: {
      handleImageAction(options) {
        this.options = { ...options, isCertPage: !!this.certPage }
        this.showPopUp = true
      },
      handleClosePopUp() {
        this.options = defaultOptions
        this.showPopUp = false
      },
      handleButtonClick() {
        this.$router.push({ name: 'stylist-steps', query: this.$route.query })
      }
    }
  }
</script>

<style lang="scss" scoped></style>
