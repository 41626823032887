import { UserRoles } from '@/config/enums'
import api from '@/services/api.service'
import { setAuthToken } from '../../utils/token.helpers'

// state
export const state = { user: '', loading: false, error: '' }

// mutations
export const mutations = {
  // only for forgot password stylist account (without localstorage)
  userSetVuex: (state, userData) => {
    state.user = userData
  },
  userSet: (state, userData) => {
    localStorage.setItem('user', JSON.stringify(userData))
    state.user = userData
    setAuthToken(userData.token)
  },
  userCleared: () => {
    localStorage.removeItem('user')
    setAuthToken(null)
    location.reload()
  },
  loadingAuthSet: state => (state.loading = true),
  loadingAuthCleared: state => (state.loading = false),
  errorAuthSet: (state, error) => (state.error = error),
  errorAuthCleared: state => (state.error = '')
}

const errorHandling = (commit, error) => {
  commit(
    'errorAuthSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingAuthCleared')
  setTimeout(() => {
    commit('errorAuthCleared')
  }, 10000)
}

// getters
export const getters = {
  adminLoggedIn: state => {
    return (
      !!state.user &&
      (state.user.role === UserRoles.FULL ||
        state.user.role === UserRoles.ADMIN_NO_STYLISTS_ACCESS)
    )
  },
  salonLoggedIn: state => {
    return !!state.user && state.user.role === UserRoles.SALON
  },
  // stylist or admin with full access
  stylistLoggedIn: state => {
    return (
      !!state.user && state.user.role === UserRoles.STYLIST
      // (state.user.role === UserRoles.STYLIST ||
      //   state.user.role === UserRoles.FULL)
    )
  },
  isUserFull: state => {
    return state.user.role === UserRoles.FULL
  },
  user: state => {
    return state.user
  }
}

// actions
export const actions = {
  login: async ({ commit }, credentials) => {
    commit('loadingAuthSet')
    try {
      const response = await api.login(credentials)
      commit('userSet', { ...response.data.data, token: response.data.token })
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  signup: async ({ commit }, credentials) => {
    commit('loadingAuthSet')
    try {
      const response = await api.signup(credentials)
      commit('userSet', { ...response.data.data, token: response.data.token })
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  signupStylist: async ({ commit }, stylistInfo) => {
    commit('loadingAuthSet')
    try {
      const response = await api.signupStylist(stylistInfo)
      commit('userSet', { ...response.data.data, token: response.data.token })
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  forgotPasswordSalon: async ({ commit }, data) => {
    commit('loadingAuthSet')
    try {
      await api.forgotPasswordSalon(data)
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  forgotPasswordStylistsAccount: async ({ commit }, data) => {
    commit('loadingAuthSet')
    try {
      await api.forgotPasswordStylistsAccount(data)
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  validateForgotPassword: async ({ commit }, code) => {
    commit('loadingAuthSet')
    try {
      const response = await api.validateForgotPassword(code)
      commit('userSetVuex', { ...response.data.data })
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendSalonSignupValidationCode: async ({ commit }, salonId) => {
    commit('loadingAuthSet')
    try {
      await api.sendSalonSignupValidationCode(salonId)
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  logout: ({ commit }) => {
    commit('userCleared')
  },
  changePassword: async ({ commit }, credentials) => {
    commit('loadingAuthSet')
    try {
      await api.changePassword(credentials)
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  changePasswordStylist: async ({ commit }, credentials) => {
    commit('loadingAuthSet')
    try {
      await api.changePassword(credentials)
      commit('userCleared')
      commit('loadingAuthCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
