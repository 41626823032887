<template>
  <v-container class="px-0 px-sm-3">
    <v-card
      class="elevation-12 mx-auto pb-3"
      max-width="420"
      v-if="!$store.state.consumers.loading && consumer"
    >
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Get 10% Discount Code
        </v-toolbar-title>
      </v-toolbar>

      <v-card class="mx-5 my-5" color="#eee">
        <v-card-text>
          <!-- 1. AKO NEMA VEC GENERISAN CODE -->
          <div class="text-body-1 text-sm-h6 mb-3" v-if="!isRevealed">
            <span
              >To reveal your 10% Discount Code, please confirm your
              consultation date and time at
            </span>
            <span class="text-h6 text-sm-headline text-uppercase">{{
              salonNear.salon.name
            }}</span
            >.
          </div>
          <!-- 2. AKO IMA VEC GENERISAN CODE -->
          <template v-if="isRevealed">
            <v-col class="d-flex justify-end pa-0">
              <v-btn
                color="user_primary"
                dark
                small
                class="text-uppercase"
                @click="handlePrint"
                >Print</v-btn
              ></v-col
            >
            <v-row id="printMe">
              <v-card elevation="3" width="100%" class="pb-3 mt-5 mx-5">
                <v-toolbar class="code-toolbar" width="100%">
                  <v-spacer></v-spacer>
                  <v-toolbar-items
                    width="100%"
                    class="d-flex align-center justify-center"
                  >
                    <v-img
                      max-width="150"
                      contain
                      class="code-logo"
                      src="@/assets/hairdreams-logo-white.webp"
                      lazy-src="@/assets/hairdreams-logo-white.webp"
                      alt="hairdreams logo"
                    />

                    <v-img
                      max-width="50"
                      class="ml-5 code-gift"
                      contain
                      src="@/assets/gift.webp"
                      lazy-src="@/assets/gift.webp"
                      alt="gift"
                    />
                  </v-toolbar-items>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="text-uppercase text-center pb-0"
                  >Your 10% OFF discount code is</v-card-text
                >
                <v-card-text class="dark text-center headline pb-0"
                  ><span class="code-box">{{ discountObj.code }}</span>
                </v-card-text>
                <v-card-text class="dark text-center caption pb-0"
                  >Code expires:
                  {{
                    new Date(discountObj.expiringDate).toLocaleDateString()
                  }}</v-card-text
                >
              </v-card>
              <v-card-text class="text-left px-0 mt-3 pb-0 subtitle-1"
                >We have emailed your code. You have an option to print it as
                well. Please present this discount code to your salon at the
                time of your consultation.</v-card-text
              >
              <v-card-text class="text-left caption px-0"
                >Disclaimer: This code can is only valid at
                {{ salonNear.salon.name }}. This code can only be redeemed
                towards your first Hairdreams application. It cannot be applied
                to additional services i.e. color, smoothing treatments.
              </v-card-text>
            </v-row>
          </template>
          <v-row v-if="!isRevealed" class="align-end">
            <!-- Date -->
            <v-col cols="12" sm="6"
              ><v-text-field
                label="Date"
                type="date"
                hide-details
                v-model="date"
                :min="getTodayString()"
            /></v-col>
            <!-- Time -->
            <v-col cols="12" sm="6">
              <v-dialog
                ref="dialog"
                v-model="modal2"
                :return-value.sync="time"
                persistent
                width="290px"
                color="white"
                dark
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    hide-details
                    :value="ampmTime(time)"
                    label="Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="ampm"
                  v-if="modal2"
                  v-model="time"
                  full-width
                  dark
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="white" @click="modal2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="white" @click="$refs.dialog.save(time)">
                    OK
                  </v-btn>
                </v-time-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row v-if="!isRevealed">
            <v-spacer></v-spacer>
            <!-- next button -->
            <v-col class="d-flex justify-end">
              <v-btn
                rounded
                :disabled="!isValid"
                color="user_primary"
                class="white--text"
                @click="() => (showConfirm = true)"
                :loading="isSending"
                >Next</v-btn
              ></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-card>
    <DiscountConfirm
      :addedBy="CONSULTATION_ADDED_BY.CONSUMER"
      v-if="showConfirm"
      :show="showConfirm"
      :consumerId="consumer._id"
      :salonName="salonNear.salon.name"
      :salonId="salonNear.salon._id"
      :date="date"
      :time="time"
      @close="() => (showConfirm = false)"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { ampmTime } from '@/utils/filters'
  import { getTodayString } from '@/utils/helpers'
  import DiscountConfirm from '@/components/user/DiscountConfirm.vue'
  import { CONSULTATION_ADDED_BY } from '@/config/enums'

  export default {
    data() {
      return {
        CONSULTATION_ADDED_BY,
        getTodayString,
        isSending: false,
        ampmTime: ampmTime,
        menu2: false,
        modal2: false,
        date: null,
        time: '',
        showConfirm: false
      }
    },
    computed: {
      isRevealed() {
        let hasDiscount = false
        // ima discount ako nadje discount za taj salon sa generisanim kodom
        if (this.consumer?.discounts) {
          this.consumer.discounts.forEach(d => {
            if (
              d.salon.toString() === this.$route.params.salonId.toString() &&
              !!d.code
            ) {
              hasDiscount = true
            }
          })
        }
        return hasDiscount
      },
      isValid() {
        return !!this.date && !!this.time
      },
      consumer() {
        return this.$store.state.consumers.consumer
      },
      discountObj() {
        return this.consumer.discounts.find(
          d => d.salon === this.$route.params.salonId
        )
      },
      salonNear() {
        return this.$store.state.consumers.consumer?.salonsNear?.find(
          s => s.salon._id === this.$route.params.salonId
        )
      }
    },
    methods: {
      ...mapActions(['fetchConsumer']),
      async handlePrint() {
        // Pass the element id here
        await this.$htmlToPaper('printMe')
      }
      // onSaveAsPdf() {
      //   console.log('save as pdf')
      // }
    },
    async created() {
      // const salonId = this.$route.params.salonId
      const consumerId = this.$route.params.consumerId
      await this.fetchConsumer(consumerId)
      if (!this.$store.state.consumers.consumer) {
        this.$router.push({ name: 'NotFound' })
      }
    },
    components: { DiscountConfirm }
  }
</script>

<style scoped>
  .call-now {
    color: blue;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gift {
    height: 80%;
    width: auto;
  }
  .code-toolbar {
    background: #444 !important;
  }
  .code-box {
    background: #bdbdbd;
    padding: 5px;
    border-radius: 5px;
  }
</style>
