import api from '@/services/api.service'

// state
export const state = {
  list: [],
  loading: false,
  error: '',
  tableList: [],
  totalCount: 0
}

// getters
export const getters = {}

// mutations
export const mutations = {
  stylistsFetched: (state, stylists) => {
    state.list = stylists
  },
  tableFetched: (state, sourceSalons) => {
    state.tableList = sourceSalons
  },
  totalCountFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  loadingSourceSalonsSet: state => (state.loading = true),
  loadingSourceSalonsCleared: state => (state.loading = false),
  errorSourceSalonsSet: (state, error) => (state.error = error),
  errorSourceSalonsCleared: state => (state.error = '')
}

// helper:
const errorHandling = (commit, error) => {
  commit(
    'errorSourceSalonsSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingSourceSalonsCleared')
  setTimeout(() => {
    commit('errorSourceSalonsCleared')
  }, 10000)
}

// actions
export const actions = {
  fetchSourceSalonsTableData: async ({ commit }, payload = {}) => {
    commit('loadingSourceSalonsSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getSourceSalonsTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('tableFetched', response.data.data)
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingSourceSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  importSourceSalons: async ({ commit }, sourcesalons) => {
    commit('loadingSourceSalonsSet')
    try {
      const response = await api.importSourceSalons(sourcesalons)
      commit('stylistsFetched', response.data.data)
      commit('loadingSourceSalonsCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  getSourceSalonsCsv: async ({ commit }, payload) => {
    try {
      // in payload are transform, ids for export and toUpdate flag
      const response = await api.postSourceSalonsCsv(payload)
      return response.data
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
