<template>
  <v-card v-if="!isLoading" class="elevation-0 mx-auto" max-width="550">
    <!-- title: system -->
    <Title :text="source.title" />
    <!-- content -->
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <ImageBox :options="source.items[1]" @action="handleImageAction" />
        </v-col>
        <v-col cols="12" md="6" class="">
          <ImageBox :options="source.items[2]" @action="handleImageAction" />

          <ImageBox
            class="mt-4"
            :options="source.items[3]"
            @action="handleImageAction"
          />
        </v-col>
      </v-row>
      <TextBox
        :isCanada="isCanada"
        :text="{
          ...source.textBox,
          paragraphHTML: settings[StylistsCertifications.ML_REGULAR]?.paragraph,
          buttonText: settings[StylistsCertifications.ML_REGULAR]?.buttonText,
          buttonLink: settings[StylistsCertifications.ML_REGULAR]?.buttonLink
        }"
        :promo-price="settings[StylistsCertifications.ML_REGULAR]?.promoPrice"
        :list-price="settings[StylistsCertifications.ML_REGULAR]?.listPrice"
      />

      <v-divider class="my-5"></v-divider>

      <!-- apple style system accordion -->
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header
            class="text-h5 font-weight-bold breakword color-blue px-0"
          >
            <v-spacer></v-spacer>
            <span class="color-blue text-center font-weight-medium"
              >Why MicroLines?</span
            >
            <v-spacer></v-spacer>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="accordion">
            <MicrolinesView
              :source="stylistSources[SystemOptions.MICROLINES]"
              :action="false"
              :certPage="StylistsCertifications.ML_REGULAR"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <CertPopUp
        :show="showPopUp"
        :options="options"
        @close="handleClosePopUp"
      />
    </v-card-text>
  </v-card>
</template>

<script>
  import { StylistsCertifications, SystemOptions } from '@/config/enums'
  import { mapActions } from 'vuex'
  import ImageBox from '../ImageBox.vue'
  import TextBox from '../TextBox.vue'
  import Title from '../Title.vue'
  import MicrolinesView from '../stylists/MicrolinesView.vue'
  import stylistSources from '../stylists/source-stylists'
  import CertPopUp from './CertPopUp.vue'

  const defaultOptions = {
    img: '',
    hasAction: false,
    video: '',
    popUpImg: '',
    text: '',
    popUpBtnText: ''
  }

  export default {
    components: {
      ImageBox,
      TextBox,
      Title,
      CertPopUp,
      MicrolinesView
    },
    props: {
      source: {
        type: Object
      },
      isCanada: {
        type: Boolean
      }
    },
    data() {
      return {
        isLoading: true,
        StylistsCertifications,
        showPopUp: false,
        options: defaultOptions,
        stylistSources,
        SystemOptions
      }
    },
    computed: {
      settings() {
        if (this.isCanada) {
          return this.$store.getters.certificateSettingsCanada.settings
        }
        return this.$store.getters.certificateSettings.settings
      }
    },
    methods: {
      ...mapActions(['fetchSettings', 'editSettings']),
      handleImageAction(options) {
        this.options = options
        this.showPopUp = true
      },
      handleClosePopUp() {
        this.options = defaultOptions
        this.showPopUp = false
      },
      handleButtonClick() {
        this.$emit('btn-click')
      }
    },
    async created() {
      await this.fetchSettings()
      this.isLoading = false
    }
  }
</script>

<style scoped>
  .color-blue {
    color: #2179d2;
  }
  .accordion {
    margin-left: -48px;
    margin-right: -48px;
  }
</style>
