<template>
  <v-container v-if="salon">
    <v-row v-if="isCreateSalon || isAdminPage">
      <v-col cols="8">
        <v-textarea
          background-color="white"
          auto-grow
          outlined
          rows="3"
          row-height="25"
          filled
          hide-details
          @change="onChange($event, 'memo')"
          label="Memo"
          type="text"
          v-model="salon.memo"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row v-if="isCreateSalon">
      <v-col cols="12">
        <v-text-field
          @change="onChange($event, 'navid')"
          hide-details
          label="Navid"
          type="text"
          v-model="salon.navid"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          @change="onChange($event, 'name')"
          hide-details
          label="Name"
          v-model="salon.name"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          @change="onChange($event, 'address1')"
          label="Address 1"
          hide-details
          v-model="salon.address1"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          @change="onChange($event, 'address2')"
          label="Address 2"
          hide-details
          v-model="salon.address2"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          @change="onChange($event, 'city')"
          label="City"
          hide-details
          v-model="salon.city"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="4">
        <v-text-field
          @change="onChange($event, 'state')"
          label="State"
          hide-details
          v-model="salon.state"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="4">
        <v-select
          v-model="salon.country"
          :items="countries"
          label="Country"
          @change="onChange($event, 'country')"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8">
        <VuePhoneNumberInput
          @change="onChange($event, 'phone')"
          show-code-on-list
          v-model="salon.phone"
          no-example
          default-country-code="US"
          :only-countries="['US', 'CA']"
          color="#222"
          valid-color="#222"
          error-color="red"
          :size="detectMob() ? 'sm' : 'lg'"
          :no-flags="detectMob() ? true : false"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          @change="onChange($event, 'zip')"
          label="Zip"
          hide-details
          v-model="salon.zip"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          @change="onChange($event, 'email')"
          label="Email"
          :hide-details="isAdminPage || isCreateSalon"
          :disabled="!isAdminPage && !isCreateSalon"
          v-model="salon.email"
          persistent-hint
          hint="Please contact us if you would like to change your email."
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          @change="onChange($event, 'website')"
          label="Website"
          hide-details
          v-model="salon.website"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-select
          @change="onChange($event, 'systems')"
          v-model="salon.systems"
          :items="systems"
          label="Systems"
          multiple
          chips
          hint="Select all systems salon offers."
          persistent-hint
        >
          <template #selection="{ item }">
            <v-chip label color="success" small
              ><v-icon left> mdi-label </v-icon>{{ item }}</v-chip
            >
          </template>
        </v-select>
      </v-col>
    </v-row>
    <!-- only if admin edit page -->
    <v-row class="mt-1 mb-4" v-if="this.isAdminPage">
      <v-card-text
        v-for="item in salon.systemQuestions"
        :key="item.system"
        class="pb-0"
        >System: <span class="font-weight-bold">{{ item.system }}</span
        >; New clients? <span class="font-weight-bold">{{ item.answer1 }}</span
        >; Remove as referral?
        <span class="font-weight-bold">{{ item.answer2 }}</span></v-card-text
      >
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          @change="onChange($event, 'contact')"
          label="Contact Person"
          hide-details
          v-model="salon.contact"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- sms phones section -->
    <v-card-title class="headline pl-0 mt-5"
      >Get text alerts for new client inquiries</v-card-title
    >
    <v-card-text class="pl-0 pt-0 mt-0 mb-0"
      >Add up to 3 numbers to receive text alerts whenever you receive new
      client inquiry emails.</v-card-text
    >
    <!-- <v-card-text class="pl-0 pt-0 mt-0 mb-3"
      ><span
        ><b>PLEASE NOTE:</b> The SMS notification is a new feature and should be
        in effect by <u>December 1st</u> so sign up now to pre-activate!</span
      ></v-card-text
    > -->
    <v-row v-if="salon.smsPhone1">
      <v-col cols="8" md="8">
        <VuePhoneNumberInput
          show-code-on-list
          @update="handleSmsPhone1Update"
          v-model="salon.smsPhone1.phoneNumber"
          no-example
          default-country-code="US"
          :only-countries="['US', 'CA']"
          color="#222"
          valid-color="#222"
          error-color="red"
          :size="detectMob() ? 'sm' : 'lg'"
          :no-flags="detectMob() ? true : false"
        />
      </v-col>
      <v-col
        cols="4"
        md="4"
        class="d-flex align-center justify-center"
        v-if="salon.smsPhone1"
      >
        <v-btn
          text
          color="blue"
          class="text-capitalize pl-0 ml-0"
          plain
          @click="showSmsPhone2 = true"
          >{{ detectMob() ? 'Add another' : 'Add another number' }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="showSmsPhone2">
      <v-col cols="8" md="8">
        <VuePhoneNumberInput
          show-code-on-list
          @update="handleSmsPhone2Update"
          v-model="salon.smsPhone2.phoneNumber"
          no-example
          default-country-code="US"
          :only-countries="['US', 'CA']"
          color="#222"
          valid-color="#222"
          error-color="red"
          :size="detectMob() ? 'sm' : 'lg'"
          :no-flags="detectMob() ? true : false"
        />
      </v-col>
      <v-col
        cols="4"
        md="4"
        class="d-flex align-center justify-center"
        v-if="salon.smsPhone2"
      >
        <v-btn
          text
          color="blue"
          class="text-capitalize pl-0 ml-0"
          plain
          @click="showSmsPhone3 = true"
          >{{ detectMob() ? 'Add another' : 'Add another number' }}</v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="showSmsPhone3">
      <v-col cols="8" md="8">
        <VuePhoneNumberInput
          show-code-on-list
          @update="handleSmsPhone3Update"
          v-model="salon.smsPhone3.phoneNumber"
          no-example
          default-country-code="US"
          :only-countries="['US', 'CA']"
          color="#222"
          valid-color="#222"
          error-color="red"
          :size="detectMob() ? 'sm' : 'lg'"
          :no-flags="detectMob() ? true : false"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { SystemOptions, SalonCountries } from '@/config/enums'
  import { detectMob } from '@/utils/helpers'

  export default {
    props: ['salon'],
    data() {
      return {
        detectMob,
        showSmsPhone2: false,
        showSmsPhone3: false
      }
    },
    computed: {
      smsPhoneNumber1() {
        return this.salon.smsPhone1?.phoneNumber
      },
      isAdminPage() {
        return this.$route.name === 'EditPendingSalon'
      },
      isCreateSalon() {
        return this.$route.name === 'CreateSalon'
      },
      isValid() {
        const isValid =
          !!this.salon.name &&
          !!this.salon.zip &&
          !!this.salon.address1 &&
          !!this.salon.city &&
          !!this.salon.state &&
          !!this.salon.country &&
          !!this.salon.email &&
          !!this.salon.phone &&
          !!this.salon.contact &&
          this.salon.systems.length > 0
        if (this.isCreateSalon) return isValid && !!this.salon.navid

        return isValid
      },
      systems() {
        let arr = []
        for (const key in SystemOptions) {
          arr.push(SystemOptions[key])
        }
        return arr
      },
      countries() {
        let arr = []
        for (const key in SalonCountries) {
          arr.push(SalonCountries[key])
        }
        return arr
      }
    },
    methods: {
      handleSmsPhone1Update(obj) {
        this.salon.smsPhone1.formattedNumber = obj.formattedNumber
      },
      handleSmsPhone2Update(obj) {
        this.salon.smsPhone2.formattedNumber = obj.formattedNumber
      },
      handleSmsPhone3Update(obj) {
        this.salon.smsPhone3.formattedNumber = obj.formattedNumber
      },
      onChange(value, prop) {
        this.$emit('change', {
          prop,
          value
        })
      }
    },
    updated() {
      this.$emit('valid', { step: 1, isValid: this.isValid })
    },
    created() {
      this.$emit('valid', { step: 1, isValid: this.isValid })
      this.showSmsPhone2 = !!this.salon.smsPhone2.phoneNumber
      this.showSmsPhone3 = !!this.salon.smsPhone3.phoneNumber
      // this.showSmsPhone2 = !!this.salon.smsPhone1.phoneNumber
      // this.showSmsPhone3 = !!this.salon.smsPhone2.phoneNumber
    }
  }
</script>
