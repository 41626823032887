<template>
  <v-container v-if="!isLoading">
    <v-card max-width="500" class="elevation-12 mx-auto pb-5 pt-0">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-subtitle-2 text-sm-h5 mx-auto breakword">
          Please select one of the two options:
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text class="mt-3 px-0 px-sm-4">
        <v-row>
          <v-col class="d-flex justify-center px-0 px-sm-3"
            ><v-btn
              class="btn-resetcase text-caption text-sm-subtititle-2"
              width="85%"
              rounded
              @click="handleGoTo('stylist')"
              >I'm a salon professional
            </v-btn></v-col
          >
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center px-0 px-sm-3"
            ><v-btn
              width="85%"
              rounded
              class="btn-resetcase text-caption text-sm-subtititle-2"
              @click="handleGoTo('consumer')"
            >
              I'm a consumer looking for a salon
            </v-btn></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  const sources = {
    MICROLINES: 'fb-ig-ad-ml',
    NANO: 'fb-ig-ad-lb',
    FREESTYLER: 'fb-ig-ad-fs',
    TAPES: 'fb-ig-ad-ta',
    MICROLINES_REACTIVE: 'ml',
    NANO_REACTIVE: 'lb'
  }
  export default {
    data() {
      return {
        isLoading: true,
        sources
      }
    },
    computed: {
      source() {
        return this.$route.params.source
      },
      querySource() {
        return this.$route.query.source
      },
      link() {
        switch (this.source) {
          case this.sources.MICROLINES:
            return {
              stylist:
                '/stylists/account/bento-boxes/microlines?source=fb_hdplus_ads-traffic_',
              consumer:
                '/salon-finder/systemInfo?userType=hairloss&systemInfo=MicroLines%20Hair%20Integration%20Pieces&backScreens=userType&source=fb_hdplus_ads-traffic_'
            }
          case this.sources.NANO:
            return {
              stylist:
                '/stylists/account/bento-boxes/nano?source=fb_hdusa_ads-traffic_',
              consumer:
                '/salon-finder/systemInfo?userType=extensions&backScreens=userType&backScreens=extensions&backScreens=consumerPick&systemPickedBy=c&systemInfo=Laserbeamer%20Nano%20Automatic%20Extensions&source=fb_hdusa_ads-traffic_'
            }
          case this.sources.FREESTYLER:
            return {
              stylist:
                '/stylists/account/bento-boxes/freestyler?source=fb_hdusa_ads-traffic_',
              consumer:
                '/salon-finder/systemInfo?userType=extensions&backScreens=userType&backScreens=extensions&backScreens=consumerPick&systemPickedBy=c&systemInfo=FreeStyler%20Strands%20by%20Hand%20Extensions&source=fb_hdusa_ads-traffic_'
            }
          case this.sources.TAPES:
            return {
              stylist:
                '/stylists/account/bento-boxes/tapes?source=fb_hdusa_ads-traffic_',
              consumer:
                '/salon-finder/systemInfo?userType=extensions&backScreens=userType&backScreens=extensions&backScreens=consumerPick&systemPickedBy=c&systemInfo=Tape%20In%20Extensions&source=fb_hdusa_ads-traffic_'
            }
          // go to source query added by admin in the url
          case this.sources.NANO_REACTIVE:
            return {
              stylist: `/stylists/account/bento-boxes/nano?source=${this.querySource}`,
              consumer: `/salon-finder/systemInfo?userType=extensions&backScreens=userType&backScreens=extensions&backScreens=consumerPick&systemPickedBy=c&systemInfo=Laserbeamer%20Nano%20Automatic%20Extensions&source=${this.querySource}`
            }
          // go to source query added by admin in the url
          case this.sources.MICROLINES_REACTIVE:
            return {
              stylist: `/stylists/account/bento-boxes/microlines?source=${this.querySource}`,
              consumer: `/salon-finder/systemInfo?userType=hairloss&systemInfo=MicroLines%20Hair%20Integration%20Pieces&backScreens=userType&source=${this.querySource}`
            }
          default:
            return { stylist: '', consumer: '' }
        }
      }
    },
    methods: {
      handleGoTo(userType) {
        const currentQuery = this.$route.query
        this.$router.push({ path: this.link[userType], query: currentQuery })
      }
    },
    created() {
      if (!Object.values(this.sources).includes(this.source)) {
        this.$router.replace({ name: 'WF12_Contact' })
      }
      this.isLoading = false
    }
  }
</script>

<style scoped>
  .breakword {
    word-break: break-word;
  }
</style>
