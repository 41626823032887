f
<template>
  <v-dialog
    v-model="open"
    @click:outside="() => $emit('close')"
    max-width="500"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-capitalize"
          >Choose a stock photo</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container class="pa-5">
        <v-row v-if="true">
          <v-col
            cols="4"
            v-for="i in 9"
            :key="i"
            class="example-img d-flex justify-center"
            @click="onExampleImg(i)"
          >
            <v-img
              :max-width="detectXS() ? 80 : 150"
              :src="
                'https://storage.googleapis.com/hairdreams-v2/salon-img-' +
                i +
                '.jpg'
              "
              :lazy-src="
                'https://storage.googleapis.com/hairdreams-v2/salon-img-' +
                i +
                '.jpg'
              "
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import { detectXS } from '@/utils/helpers'
  export default {
    props: ['open'],
    data() {
      return {
        detectXS
      }
    },
    methods: {
      onExampleImg(i) {
        const url = `https://storage.googleapis.com/hairdreams-v2/salon-img-${i}.jpg`

        this.$emit('change', url)
        this.$emit('close')
      }
    }
  }
</script>

<style scoped>
  .example-img {
    cursor: pointer;
  }
</style>
