<template>
  <v-img
    contain
    src="@/assets/Virtual Estimate.svg"
    viewBox="0 0 34 34"
    width="34"
    height="34"
  />
</template>

<script>
  export default {
    name: 'VirtualEstimates'
  }
</script>
