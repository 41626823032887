import Terms from '@/components/user/edit-salon/Terms.vue'
import Admin from '@/views/admin/Admin.vue'
import Analytics from '@/views/admin/Analytics.vue'
import Certification from '@/views/admin/Certification.vue'
import Consultations from '@/views/admin/Consultations.vue'
import ConsumersOtherInq from '@/views/admin/ConsumersOtherInq.vue'
import Login from '@/views/admin/Login.vue'
import Logs from '@/views/admin/Logs.vue'
import PendingSalons from '@/views/admin/PendingSalons.vue'
import Salons from '@/views/admin/Salons.vue'
import Stylists from '@/views/admin/Stylists.vue'
import StylistsOtherInq from '@/views/admin/StylistsOtherInq.vue'
import WF6_1_HD_Communication from '@/views/admin/WF6_1_HD_Communication.vue'
import WF7_Agreement from '@/views/admin/WF7_Agreement.vue'
import WF9_SendEmails from '@/views/admin/WF9_SendEmails.vue'
import CCPA from '@/views/user/CCPA.vue'
import Home from '@/views/user/Home.vue'
import OptOut from '@/views/user/OptOut.vue'
import ThankYouPage from '@/views/user/ThankYouPage.vue'
import User from '@/views/user/User.vue'
import FinalStatusPage from '@/views/user/WF1/stylists/FinalStatusPage.vue'
import WF12_Contact from '@/views/user/WF12_Contact.vue'

import GlobalContactPage from '@/views/user/GlobalContactPage.vue'
import WF11_Stylists from '@/views/user/WF11_Stylists.vue'
import WF11_Success from '@/views/user/WF11_Success.vue'
import WF2_GreatNews from '@/views/user/WF2/WF2_GreatNews.vue'
import WF2_1_NoSalonFound from '@/views/user/WF2_1_NoSalonFound.vue'
import WF2_2_Contact_CH from '@/views/user/WF2_2_Contact_CH.vue'
import WF2_2_Contact_SH from '@/views/user/WF2_2_Contact_SH.vue'
import WF2_3_Welcome from '@/views/user/WF2_3_Welcome.vue'
import WF3_NewInquiry from '@/views/user/WF3_NewInquiry.vue'
import WF4_DiscountPage from '@/views/user/WF4_DiscountPage.vue'
import WF5_EditSalon from '@/views/user/WF5_EditSalon.vue'
import WF5_FindSalon from '@/views/user/WF5_FindSalon.vue'
import WF5_ValidateEmail from '@/views/user/WF5_ValidateEmail.vue'
import WF6_Communication from '@/views/user/WF6_Communication.vue'
import WF8_UnscubscribeThanksVue from '@/views/user/WF8_UnscubscribeThanks.vue'
import WF8_Unsubscribe from '@/views/user/WF8_Unsubscribe.vue'
import Vue from 'vue'
import VueRouter from 'vue-router'
// WF1 SCREENS
import ConsumerMessages from '@/views/admin/ConsumerMessages.vue'
import Messages from '@/views/admin/Messages.vue'
import MessagesAndCalls from '@/views/admin/MessagesAndCalls.vue'
import ConsumerInfo from '@/views/user/WF1/ConsumerInfo.vue'
import ConsumerPick from '@/views/user/WF1/ConsumerPick.vue'
import ConsumerPickOther from '@/views/user/WF1/ConsumerPickOther.vue'
import Extensions from '@/views/user/WF1/Extensions.vue'
import ExtraCoverage from '@/views/user/WF1/ExtraCoverage.vue'
import HairLongIs from '@/views/user/WF1/HairLongIs.vue'
import HairLongWant from '@/views/user/WF1/HairLongWant.vue'
import Info from '@/views/user/WF1/Info.vue'
import LifestyleExtensions from '@/views/user/WF1/LifestyleExtensions.vue'
import MoreHair from '@/views/user/WF1/MoreHair.vue'
import SystemInfo from '@/views/user/WF1/SystemInfo.vue'
import UserType from '@/views/user/WF1/UserType.vue'
import WearExtensionsBefore from '@/views/user/WF1/WearExtensionsBefore.vue'
import Wrapper from '@/views/user/WF1/Wrapper.vue'
import { UserRoles } from './config/enums'
import Consumers from './views/admin/Consumers.vue'
import ConsumersEmailPromotion from './views/admin/ConsumersEmailPromotion.vue'
import ConsumersSmsPromotion from './views/admin/ConsumersSmsPromotion.vue'
import FinishedApplications from './views/admin/FinishedApplications.vue'
import PendingApplications from './views/admin/PendingApplications.vue'
import RemoveConsumer from './views/admin/RemoveConsumer.vue'
import StylistCertifications from './views/admin/StylistCertifications.vue'
import NotFound from './views/user/NotFound.vue'
import MicrolinesPrice from './views/user/WF1/MicrolinesPrice.vue'
import ChangePasswordSalon from './views/user/auth/ChangePasswordSalon.vue'
import ForgotPasswordSalon from './views/user/auth/ForgotPasswordSalon.vue'
import LoginSalon from './views/user/auth/LoginSalon.vue'
import SignupSalon from './views/user/auth/SignupSalon.vue'
import SalonDashboard from './views/user/salon/SalonDashboard.vue'
import SalonHome from './views/user/salon/SalonHome.vue'
import SalonMessages from './views/user/salon/SalonMessages.vue'
import SalonPage from './views/user/salon/SalonPage.vue'

import {
  account as stylistAccount,
  application as stylistApplication,
  certifications as stylistCertifications,
  form as stylistForm,
  other as stylistOtherContact
} from './stylists.router'
import DistanceChecker from './views/admin/DistanceChecker.vue'
import EditSalonHome from './views/admin/EditSalonHome.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: User,
    children: [
      // todo: remove this
      {
        path: 'edit-salon',
        redirect: { name: 'FindSalon' }
      },
      { path: 'not-found', component: NotFound, name: 'NotFound' },
      // note: stylist project
      stylistOtherContact,
      stylistAccount,
      stylistApplication,
      stylistForm,
      stylistCertifications,
      // SALONS AUTH
      // signup
      {
        path: 'salons/:salonId/signup',
        component: SignupSalon,
        name: 'SignupSalon'
      },
      // login
      {
        path: 'salons/:salonId/login',
        component: LoginSalon,
        name: 'LoginSalon'
      },
      // forgot password
      {
        path: 'salons/:salonId/forgot-password',
        component: ForgotPasswordSalon,
        name: 'ForgotPasswordSalon'
      },
      // change password
      {
        path: 'salons/:salonId/change-password',
        component: ChangePasswordSalon,
        name: 'ChangePasswordSalon'
      },
      {
        path: 'salons/find/validate',
        name: 'ValidateEmail',
        component: WF5_ValidateEmail
      },
      {
        path: 'salons/find',
        name: 'FindSalon',
        component: WF5_FindSalon,
        // note: if salon is logged in go to edit-salon
        beforeEnter: (to, from, next) => {
          const user = JSON.parse(localStorage.getItem('user'))
          if (user?.salon) {
            next({ name: 'EditSalon', params: { salonId: user.salon } })
          } else {
            return next()
          }
        }
      },
      // home
      {
        path: 'salons',
        component: SalonDashboard,

        children: [
          {
            path: ':salonId',
            component: SalonHome,
            name: 'SalonHomeLoggedIn'
          },
          {
            path: '',
            component: SalonHome,
            name: 'SalonHome'
          },
          {
            path: 'messages/:salonId',
            component: SalonMessages,
            name: 'SalonMessages',
            meta: {
              requiresSalonAuth: true
            }
          },
          {
            path: 'page/:salonId',
            component: SalonPage,
            name: 'SalonPage'
          }
        ]
      },
      // WF 3
      // note: promenio bih isto da krece sa salons ali ne mogu zbog starih emails sa ovim linkom koji su poslati
      {
        path: 'new-inquiry/:salonId/:consumerId',
        name: 'NewInquiry',
        component: WF3_NewInquiry,
        meta: {
          requiresSalonAuth: true
        }
      },
      // WF 5
      {
        path: 'salons/:salonId/edit/terms',
        name: 'EditSalonTerms',
        component: Terms
      },
      {
        path: 'salons/:salonId/edit',
        name: 'EditSalon',
        component: WF5_EditSalon,
        meta: {
          requiresSalonAuth: true
        }
      },
      {
        path: 'salons/edit/opt-out',
        name: 'OptOut',
        component: OptOut
      },
      {
        path: 'salons/edit/thank-you/:source',
        name: 'ThankYouPage',
        component: ThankYouPage
      },
      // WF 1 NEW
      { path: '', redirect: { name: 'userType' } },
      {
        path: 'salon-finder',
        component: Wrapper,
        children: [
          { path: '', redirect: { name: 'userType' } },
          { path: 'userType', component: UserType, name: 'userType' },
          { path: 'extensions', component: Extensions, name: 'extensions' },
          { path: 'pro', redirect: { name: 'stylist-primary-system' } },
          { path: 'systemInfo', component: SystemInfo, name: 'systemInfo' },
          { path: 'info', component: Info, name: 'info' },
          { path: 'moreHair', component: MoreHair, name: 'moreHair' },
          { path: 'hairLongIs', component: HairLongIs, name: 'hairLongIs' },
          {
            path: 'hairLongWant',
            component: HairLongWant,
            name: 'hairLongWant'
          },
          {
            path: 'microlinesPrice',
            component: MicrolinesPrice,
            name: 'microlinesPrice'
          },
          {
            path: 'extraCoverage',
            component: ExtraCoverage,
            name: 'extraCoverage'
          },
          {
            path: 'consumerInfo',
            component: ConsumerInfo,
            name: 'consumerInfo'
          },
          {
            path: 'consumerPick',
            component: ConsumerPick,
            name: 'consumerPick'
          },
          {
            path: 'wearExtensionsBefore',
            component: WearExtensionsBefore,
            name: 'wearExtensionsBefore'
          },
          {
            path: 'consumerPickOther',
            component: ConsumerPickOther,
            name: 'consumerPickOther'
          },
          {
            path: 'lifestyleExtensions',
            component: LifestyleExtensions,
            name: 'lifestyleExtensions'
          },
          { path: '*', redirect: { name: 'userType' } }
        ]
      },
      // WF 2
      {
        path: 'great-news/:consumerId/:salonId',
        name: 'GreatNews',
        component: WF2_GreatNews
      },
      // WF 2.1
      {
        path: 'no-salon/:consumerId',
        name: 'NoSalonFound',
        component: WF2_1_NoSalonFound
      },
      // WF 2.2
      {
        path: 'contact/consumers/:consumerId',
        name: 'ConsumerContact',
        component: WF2_2_Contact_CH
      },
      // WF 2.3
      {
        path: 'welcome',
        name: 'Welcome',
        component: WF2_3_Welcome
      },
      {
        path: 'contact/salons/:salonId',
        name: 'SalonContact',
        component: WF2_2_Contact_SH
      },
      {
        path: 'contact/stylists',
        redirect: { name: 'stylist-other-contact' }
      },
      {
        path: 'contact/consumers',
        name: 'NewConsumerContact',
        component: WF2_2_Contact_CH
      },
      // WF 4
      {
        path: 'discount/:consumerId/:salonId',
        name: 'DiscountPage',
        component: WF4_DiscountPage
      },
      // WF 6
      {
        path: 'communication/:consumerId/:salonId',
        name: 'Communication',
        component: WF6_Communication
      },
      // WF 8
      {
        path: ':consumerId/unsubscribe',
        name: 'Unsubscribe',
        component: WF8_Unsubscribe
      },
      {
        path: ':consumerId/unsubscribe/thank-you',
        name: 'UnsubscribeThanks',
        component: WF8_UnscubscribeThanksVue
      },
      // WF 10
      {
        path: 'ccpa',
        name: 'CCPA',
        component: CCPA
      },
      // WF 11 Stylist
      {
        path: 'v1/stylists/success',
        name: 'WF11_Success',
        component: WF11_Success
      },
      {
        path: 'v1/stylists',
        name: 'WF11_Stylists',
        component: WF11_Stylists
      },
      // // WF 12 Contact
      // {
      //   path: 'contact',
      //   name: 'WF12_Contact',
      //   component: WF12_Contact
      // },
      // Global contact page
      {
        path: 'contact/:source',
        name: 'GlobalContactPage',
        component: GlobalContactPage
      },
      {
        path: 'contact',
        name: 'WF12_Contact',
        component: WF12_Contact
      }
    ]
  },
  {
    path: '/admin',
    component: Admin,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login
      },
      {
        path: 'example-pages',
        name: 'ExamplePages',
        component: Home,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylists/final/:stylistId/:applicationId',
        name: 'StylistFinalStatusAdmin',
        component: FinalStatusPage,
        meta: { requiresAuth: true }
      },
      // todo: change to navid
      {
        path: 'salons/create',
        name: 'CreateSalon',
        component: WF5_EditSalon,
        meta: { requiresAuth: true }
      },
      {
        path: 'salons',
        name: 'Salons',
        component: Salons,
        meta: { requiresAuth: true }
      },
      {
        path: 'distance-checker',
        name: 'DistanceChecker',
        component: DistanceChecker,
        meta: { requiresAuth: true }
      },
      {
        path: 'messages',
        name: 'Messages',
        component: Messages,
        meta: { requiresAuth: true }
      },
      {
        path: 'pending-salons',
        name: 'PendingSalons',
        component: PendingSalons,
        meta: { requiresAuth: true }
      },
      {
        path: 'edit-salon-home',
        name: 'EditSalonHome',
        component: EditSalonHome,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylists',
        name: 'Stylists',
        component: Stylists,
        meta: { requiresAuth: true }
      },
      {
        path: 'consumers',
        name: 'Consumers',
        component: Consumers,
        meta: { requiresAuth: true }
      },
      {
        path: 'consumer/messages',
        name: 'ConsumerMessages',
        component: ConsumerMessages,
        meta: { requiresAuth: true }
      },
      {
        path: 'messages-calls',
        name: 'MessagesCalls',
        component: MessagesAndCalls,
        meta: { requiresAuth: true }
      },
      {
        path: 'consultations',
        name: 'Consultations',
        component: Consultations,
        meta: { requiresAuth: true }
      },
      {
        path: 'logs',
        name: 'Logs',
        component: Logs,
        meta: { requiresAuth: true }
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: Analytics,
        meta: { requiresAuth: true }
      },
      {
        path: 'consumers/other-inq',
        name: 'ConsumersOtherInq',
        component: ConsumersOtherInq,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylists/other-inq',
        name: 'StylistsOtherInq',
        component: StylistsOtherInq,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylist-applications/finished/:applicationId',
        name: 'finished-application',
        component: FinishedApplications,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylist-applications/finished',
        name: 'finished-applications',
        component: FinishedApplications,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylist-applications/pending/:applicationId',
        name: 'pending-application',
        component: PendingApplications,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylist-applications/pending',
        name: 'pending-applications',
        component: PendingApplications,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylist-certifications',
        name: 'stylist-certifications',
        component: StylistCertifications,
        meta: { requiresAuth: true }
      },
      {
        path: 'stylist-certifications-canada',
        name: 'stylist-certifications-canada',
        component: StylistCertifications,
        meta: { requiresAuth: true }
      },
      {
        path: 'consumers/email-promotion',
        name: 'ConsumersEmailPromotion',
        component: ConsumersEmailPromotion,
        meta: { requiresAuth: true }
      },
      {
        path: 'consumers/sms-promotion',
        name: 'ConsumersSmsPromotion',
        component: ConsumersSmsPromotion,
        meta: { requiresAuth: true }
      },
      // WF 6.1
      {
        path: 'communication/:consumerId/:salonId',
        name: 'AdminCommunication',
        component: WF6_1_HD_Communication,
        meta: { requiresAuth: true }
      },
      {
        path: 'edit-salon/:salonId',
        name: 'EditPendingSalon',
        component: WF5_EditSalon,
        meta: { requiresAuth: true }
      },
      // WF 7
      {
        path: 'agreement',
        name: 'Agreement',
        component: WF7_Agreement,
        meta: { requiresAuth: true }
      },
      // Certification
      {
        path: 'certification',
        name: 'Certification',
        component: Certification,
        meta: { requiresAuth: true }
      },
      // WF 9
      {
        path: 'send-emails',
        name: 'SendEmails',
        component: WF9_SendEmails,
        meta: { requiresAuth: true }
      },
      {
        path: 'consumer-remove',
        name: 'RemoveConsumer',
        component: RemoveConsumer,
        meta: { requiresAuth: true }
      }
    ]
  },
  { path: '*', redirect: { name: 'userType' } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})

// salon auth
router.beforeEach((to, from, next) => {
  const requiresSalonAuth = to.matched.some(
    record => record.meta.requiresSalonAuth
  )

  const user = JSON.parse(localStorage.getItem('user'))

  const toSalonId = to.params.salonId

  if (requiresSalonAuth) {
    if (!user || user.salon !== toSalonId) {
      const toPath = to.path
      next({
        name: 'LoginSalon',
        params: { salonId: toSalonId },
        query: { from: toPath }
      })
    } else {
      next()
    }
  }

  next()
})

// stylist auth
router.beforeEach((to, from, next) => {
  const requiresStylistAuth = to.matched.some(
    record => record.meta.requiresStylistAuth
  )

  const userData = localStorage.getItem('user')
  const user = JSON.parse(userData)

  const toStylistId = to.params.stylistId

  // note: if route has meta requiresStylistAuth
  if (requiresStylistAuth && (!user || user.stylistId !== toStylistId)) {
    // save from (in login router.replace from)
    const toPath = to.path
    next({ name: 'stylist-login', query: { ...from.query, from: toPath } })
  }

  next()
})

// auth
router.beforeEach((to, from, next) => {
  const userData = localStorage.getItem('user')

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  const adminRole =
    JSON.parse(userData)?.role === UserRoles.FULL ||
    JSON.parse(userData)?.role === UserRoles.ADMIN_NO_STYLISTS_ACCESS

  // note: if going to login route and is logged in
  if (to.name === 'Login' && userData && adminRole) {
    next('/admin/salons')
  }

  // note: if route has meta requiresAuth
  if (requiresAuth && (!userData || !adminRole)) {
    // save from (in login router.replace from)
    const toPath = to.path
    next({ name: 'Login', query: { from: toPath } })
  }

  next()
})

export default router
