<template>
  <div class="box">
    <div
      class="d-flex justify-center align-center"
      style="min-height: 600px"
      v-if="isLoading"
    >
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <GChart
      v-else
      type="LineChart"
      :data="chartData"
      :options="chartOptions"
      :events="chartEvents"
    />
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <ConsumersContactedSalons :startDate="startDate" :endDate="endDate" />
        </v-col>
        <v-col cols="6">
          <SignedUpForSms :startDate="startDate" :endDate="endDate" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  import moment from 'moment'
  import ConsumersContactedSalons from '@/components/admin/analytics/consumers/ConsumersContactedSalons.vue'
  import SignedUpForSms from '@/components/admin/analytics/consumers/SignedUpForSms.vue'
  export default {
    components: {
      GChart,
      ConsumersContactedSalons,
      SignedUpForSms
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true,
        chartEvents: {
          select: () => {
            const selection = getSelection()

            let isFound = false
            const selectedText =
              selection?.baseNode?.data || selection?.focusNode?.data

            for (const key in this.chartOptions.series) {
              if (this.chartOptions.series[key].value === selectedText) {
                isFound = true
                this.chartOptions.series[key].lineWidth = 3
                this.chartOptions.series[key].pointsVisible = true
              } else {
                this.chartOptions.series[key].lineWidth = 0.5
                this.chartOptions.series[key].pointsVisible = false
              }
            }
            if (!isFound) {
              for (const key in this.chartOptions.series) {
                this.chartOptions.series[key].lineWidth = 2
                this.chartOptions.series[key].pointsVisible = false
              }
              this.showSecondChart = false
            }
          }
        },
        chartOptions: {
          // explorer: {},
          vAxis: { viewWindow: { min: 0 } },
          title: 'Consumers - contacted salon',
          dataOpacity: 100,
          legend: { position: 'bottom' },
          height: 600,
          width: '100%',
          animation: { startup: true, duration: 500 },
          series: {
            0: {
              color: 'black',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Total'
            },
            1: {
              color: 'red',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Extensions'
            },
            2: {
              color: 'blue',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Microlines'
            },
            3: {
              color: 'orange',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Tapes'
            },
            4: {
              color: 'pink',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Bonded (B+LB+FS)'
            }
          }
        }
      }
    },
    methods: {
      ...mapActions([
        'fetchConsumersMessagesAndCalls',
        'fetchAdvancedConsumersContacted'
      ])
    },
    computed: {
      consumersMessagesAndCalls() {
        return this.$store.state.analytics.consumersMessagesAndCalls
      },
      contactedSalon() {
        return this.$store.state.analytics.advancedConsumersContacted
      },
      chartData() {
        const data = [
          [
            'Date',
            'Total',
            'Extensions',
            'Microlines',
            'Tapes',
            'Bonded (B+LB+FS)'
          ]
        ]
        if (!this.consumersMessagesAndCalls) {
          return []
        }
        this.consumersMessagesAndCalls.forEach(item => {
          // console.log({ item: item.date, moment: moment(item.date) })
          data.push([
            // note: moment is converting utc to local date and format
            moment(item.date).format('ddd. MM/DD/YY'),
            item['Total'],
            item['Extensions'],
            item['Microlines'],
            item['Tapes'],
            item['Bonded (B+LB+FS)']
          ])
        })
        return data
      }
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true

        await Promise.all([
          this.fetchConsumersMessagesAndCalls({
            startDate: this.startDate,
            endDate: this.endDate
          }),
          this.fetchAdvancedConsumersContacted({
            startDate: this.startDate,
            endDate: this.endDate
          })
        ])

        this.chartOptions.title = `Consumers - contacted salon: ${this.contactedSalon}`
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true
        await Promise.all([
          this.fetchConsumersMessagesAndCalls({
            startDate: this.startDate,
            endDate: this.endDate
          }),
          this.fetchAdvancedConsumersContacted({
            startDate: this.startDate,
            endDate: this.endDate
          })
        ])

        this.chartOptions.title = `Consumers - contacted salon: ${this.contactedSalon}`
        this.isLoading = false
      }
    },
    async created() {
      await Promise.all([
        this.fetchConsumersMessagesAndCalls({
          startDate: this.startDate,
          endDate: this.endDate
        }),
        this.fetchAdvancedConsumersContacted({
          startDate: this.startDate,
          endDate: this.endDate
        })
      ])
      this.chartOptions.title = `Consumers - contacted salon: ${this.contactedSalon}`
      this.isLoading = false
    }
  }
</script>
<style lang="scss" scoped>
  .box {
    background: #fff;
    min-height: 930px;
  }
</style>
