<template>
  <v-container>
    <!-- ExtensionMethods -->
    <v-row class="justify-center">
      <v-col cols="12" class="pl-0 pb-0">
        <v-card-title class="breakword pb-0"
          >What<span class="font-weight-bold">&nbsp;extension&nbsp;</span
          >methods do you use?
        </v-card-title></v-col
      >
      <v-card-text class="text-center subtitle mb-2"
        >Choose multiple</v-card-text
      >
      <v-row class="form">
        <template v-for="option in options1">
          <v-col
            class="d-flex justify-center my-0 py-1 mx-auto px-1 px-sm-3"
            :key="option.value"
            :cols="option.cols"
          >
            <v-btn
              :width="option.width"
              class="text-none mt-1"
              depressed
              small
              @click="
                handleMultiselectSelectAnswer(field1, 'answer1', option.value)
              "
              :dark="
                answer1.includes(option.value) ||
                (option.value === 'Other' && showOtherInput)
              "
              :color="
                answer1.includes(option.value) ||
                (option.value === 'Other' && showOtherInput)
                  ? 'user_cta'
                  : 'user_btn'
              "
              >{{ option.text }}</v-btn
            >
          </v-col>
        </template>
      </v-row>
    </v-row>
    <v-card-text class="my-0 pb-0" v-if="showOtherInput">
      <v-col
        :cols="$vuetify.breakpoint.smAndDown ? 12 : 5"
        class="d-flex justify-center my-0 pb-0 mx-auto"
      >
        <v-text-field
          class="pb-0"
          v-model="otherMethodText"
          label="Other Method"
          dense
          outlined
          clearable
        ></v-text-field>
      </v-col>
    </v-card-text>

    <!-- ExtensionClientsFusion -->
    <v-row class="justify-center mt-5" v-if="showLastQuestion">
      <v-col cols="12" class="pl-0 pb-0">
        <v-card-title class="breakword pb-0"
          >How many clients do you have that wear Fusion/Cold Fusion Strands
          (U-Tip Keratin)
        </v-card-title></v-col
      >
      <v-card-text class="text-center subtitle">Choose one</v-card-text>
      <v-col
        v-for="option in options2"
        :key="option.value"
        class="d-flex justify-center my-0 py-1 mx-auto"
        cols="6"
        @click="handleSelect(option.value)"
      >
        <v-btn
          depressed
          small
          :color="answer2 === option.value ? 'user_cta' : 'user_btn'"
          :dark="answer2 === option.value"
          class="text-none mt-1"
          width="100%"
          >{{ option.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { SystemOptions } from '@/config/enums'

  export default {
    data() {
      return {
        showOtherInput: false,
        otherMethodText: '',
        answer1: [],
        answer2: '',
        options1: [
          {
            text: 'Beaded Wefts',
            value: 'Beaded Wefts',
            cols: '6',
            width: '100%'
          },
          {
            text: 'Sewn Wefts',
            value: 'Sewn Wefts',
            cols: 6,
            width: '100%'
          },
          {
            text: 'Tapes',
            value: 'Tapes',
            cols: 6,
            width: '100%'
          },
          {
            text: 'Beaded Strands (iTips)',
            value: 'Beaded Strands (iTips)',
            cols: 6,
            width: '100%'
          },
          {
            text: 'Fusion/Cold Fusion Strands (U-Tip Keratin)',
            value: 'Fusion/Cold Fusion Strands (U-Tip Keratin)',
            cols: 12,
            width: '100%'
          },
          { text: 'OTHER', value: 'Other', cols: 6, width: '50%' }
        ],
        options2: [
          {
            text: '1 to 2',
            value: '1 to 2'
          },
          {
            text: '3 to 5',
            value: '3 to 5'
          },
          {
            text: '6 to 9',
            value: '6 to 9'
          },
          {
            text: '10 to 20+',
            value: '10 to 20+'
          }
          // {
          //   text: '20+',
          //   value: '20+'
          // }
        ],
        field1: 'extensionMethods',
        field2: 'extensionClientsFusion',
        screen: 'stylist-extensions2_2',
        nextScreen: ''
      }
    },
    computed: {
      application() {
        return this.$store.state.applications.application
      },
      showLastQuestion() {
        return this.answer1.includes(
          this.options1[this.options1.length - 2].value
        )
      },
      isValid() {
        return (
          this.answer1.length > 0 &&
          (this.showLastQuestion ? !!this.answer2 : true)
        )
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        if (
          this.application.extensions === 'Yes' &&
          !this.application.system.includes(SystemOptions.MICROLINES)
        ) {
          this.nextScreen = 'stylist-ads1'
        } else if (
          this.application.extensions === 'Yes' &&
          this.application.system.includes(SystemOptions.MICROLINES)
        ) {
          this.nextScreen = 'stylist-restoration1'
        } else {
          this.nextScreen = 'stylist-extensions3'
        }

        this.setNextScreen(this.nextScreen)
      },
      handleVuex(field, answer) {
        // 1. set data
        this.setApplicationData({ field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(this.isValid)
      },
      handleMultiselectSelectAnswer(field, answer, value) {
        if (value === 'Other') {
          this.showOtherInput = !this.showOtherInput
          if (this.showOtherInput) this.otherMethodText = ''
        } else {
          if (this[answer].includes(value))
            this[answer] = this[answer].filter(q => q !== value)
          else this[answer].push(value)

          this.handleVuex(field, this[answer])
        }
      },
      handleSelect(answer) {
        this.answer2 = answer
        // 1. set data
        this.setApplicationData({ field: this.field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
      }
    },
    watch: {
      answer1() {
        this.handleVuex(this.field1, this.answer1)
      },
      showLastQuestion(show) {
        if (!show) this.answer2 = ''
        this.handleVuex(this.field2, this.answer2)
      },
      answer2() {
        this.handleVuex(this.field2, this.answer2)
      },
      showOtherInput(newValue, oldValue) {
        if (!newValue) this.otherMethodText = ''
      },
      otherMethodText(newValue, oldValue) {
        this.answer1 = this.answer1.filter(q => q !== oldValue)
        if (newValue) this.answer1.push(newValue)

        this.handleVuex(this.field, this.answer1)
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field1]) {
        this.answer1 = this.$store.state.applications.application[this.field1]
        this.answer2 = this.$store.state.applications.application[this.field2]
        this.handleNextScreen()
        this.setIsValid(this.isValid)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(this.isValid)
      }
    }
  }
</script>
<style scoped>
  .breakword {
    word-break: break-word;
  }
</style>
