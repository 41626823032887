<template>
  <div>
    <div class="d-flex justify-center align-center" v-if="isLoading">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <GChart :type="type" :data="chartData" :options="options"
    /></template>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  export default {
    components: {
      GChart
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        type: 'PieChart',
        isLoading: true,
        options: {
          colors: ['green', '#808080'],
          title: '',
          height: 300,
          animation: { startup: true, duration: 500 },
          legend: { position: 'bottom' }
        }
      }
    },
    computed: {
      consumersSmsPhones() {
        return this.$store.state.analytics.consumersSmsPhones
      },
      chartData() {
        return [
          ['Status', 'Count'],
          ['Signed up', this.consumersSmsPhones?.['Signed up']],
          ["Didn't sign Up", this.consumersSmsPhones?.["Didn't sign Up"]]
        ]
      },
      signedUpConsumersCount() {
        if (this.consumersSmsPhones) {
          const total = Object.values(this.consumersSmsPhones).reduce(
            (a, item) => a + item,
            0
          )

          return ((this.consumersSmsPhones['Signed up'] / total) * 100).toFixed(
            1
          )
        } else {
          return '0'
        }
      }
    },
    methods: {
      ...mapActions(['fetchConsumersSmsPhones'])
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true

        await this.fetchConsumersSmsPhones({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.options.title = `Consumer SMS sign up rate: ${this.signedUpConsumersCount}%`
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true

        await this.fetchConsumersSmsPhones({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.options.title = `Consumer SMS sign up rate: ${this.signedUpConsumersCount}%`
        this.isLoading = false
      }
    },
    async created() {
      await this.fetchConsumersSmsPhones({
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.options.title = `Consumer SMS sign up rate: ${this.signedUpConsumersCount}%`
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped></style>
