<template>
  <v-container
    fluid
    class="container py-0 px-0 mb-3"
    @click="() => $emit('onClick', consumerId)"
  >
    <v-card
      elevation="0"
      class="card pl-2 d-flex justify-center align-center rounded"
      :class="unread ? '' : 'read'"
    >
      <v-avatar :color="unread ? '#f06944' : '#aeaeae'">
        <span class="white--text text-h5">{{ avatarText }}</span>
      </v-avatar>
      <v-card-text class="px-0">
        <v-card-text class="py-0 font-weight-bold">{{ name }}</v-card-text>
        <v-card-text
          class="py-0 pr-0"
          :class="unread ? 'font-weight-bold' : ''"
        >
          <v-icon small class="pb-1" v-if="!lastMessageFromConsumer"
            >mdi-reply-outline</v-icon
          >
          <span class="host">{{ !lastMessageFromConsumer ? 'You: ' : '' }}</span
          >{{ lastMessageCut }}
        </v-card-text>
      </v-card-text>
      <v-icon>mdi-circle-small</v-icon>
      <span class="time pr-2" :class="unread ? 'font-weight-bold' : ''">{{
        time
      }}</span>
      <span v-if="unread" class="pl-2"
        ><v-icon left dark color="red">mdi-circle-medium</v-icon></span
      >

      <!-- with tooltip -->
      <v-tooltip
        v-if="showTooltip"
        bottom
        color="#1a83f0"
        v-model="showTooltip"
        max-width="120"
        class="text-center"
        content-class="custom-tooltip"
        z-index="999999"
        disabled
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#3f91f2"
            v-bind="attrs"
            v-on="on"
            class="text-capitalize mr-2"
            rounded
            depressed
            small
            @click.stop="
              () => $emit('onArchive', { consumerId, toArchive: !archived })
            "
            ><v-icon center color="#fff">{{
              archived
                ? 'mdi-archive-arrow-up-outline'
                : 'mdi-archive-arrow-down-outline'
            }}</v-icon></v-btn
          >
        </template>
        <div class="text-center">
          <span>This button will archive a conversation.</span>
          <v-btn rounded small class="btn-resetcase" @click.stop="handleGotIt"
            >Got it</v-btn
          >
        </div>
      </v-tooltip>

      <!-- without tooltip -->
      <v-btn
        v-else
        class="pl-2 text-capitalize"
        small
        rounded
        @click.stop="
          () => $emit('onArchive', { consumerId, toArchive: !archived })
        "
        plain
        ><v-icon>{{
          archived
            ? 'mdi-archive-arrow-up-outline'
            : 'mdi-archive-arrow-down-outline'
        }}</v-icon></v-btn
      >
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      showTooltip: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        required: true
      },
      lastMessage: {
        type: String,
        required: true
      },
      lastMessageFromConsumer: {
        type: Boolean,
        default: false
      },
      lastMessageTime: {
        type: String
      },
      answerTime: {
        type: Number
      },
      unread: {
        type: Boolean,
        default: true
      },
      archived: {
        type: Boolean
      },
      consumerId: {
        type: String,
        required: true
      }
    },
    computed: {
      avatarText() {
        const textArr = this.name.split(' ')
        return (textArr[0][0] + textArr[1][0]).toUpperCase()
      },
      lastMessageCut() {
        const maxWordNum = 4
        const messageArr = this.lastMessage.split(' ')
        if (messageArr.length > maxWordNum) {
          return messageArr.slice(0, maxWordNum).join(' ') + '...'
        }
        return this.lastMessage
      },
      time() {
        const now = moment(new Date()) // todays date
        const end = moment(this.lastMessageTime) // another date
        const duration = moment.duration(now.diff(end))
        const days = duration.asDays()
        const hours = duration.asHours()
        const minutes = duration.asMinutes()
        const seconds = duration.asSeconds()

        if (days > 1) {
          return Math.floor(days) + 'd'
        } else if (hours > 1) {
          return Math.floor(hours) + 'h'
        } else if (minutes > 1) {
          return Math.floor(minutes) + 'm'
        } else {
          return Math.floor(seconds) + 's'
        }
      }
    },
    methods: {
      handleGotIt() {
        this.$emit('onGotIt')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    cursor: pointer;
    z-index: 1000;
  }
  .read {
    background: #f5f6f6;
  }
  .card {
    transition: all 0.3s;
  }
  .card:hover {
    background: #eee;
  }
  .time {
    color: #666666;
  }
  .rounded {
    border-radius: 5px !important;
  }
  .custom-tooltip {
    pointer-events: auto;
  }
</style>
