<template>
  <v-dialog
    v-model="show"
    max-width="500"
    @click:outside="close"
    @keydown.esc="() => $emit('close')"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title class="text-uppercase"
          >Account Information</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-row class="justify-center">
          <v-col cols="12" md="12">
            <v-text-field
              hide-details
              label="Email"
              v-model="accountInfo.email"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="First Name"
              v-model="accountInfo.firstName"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="Last Name"
              v-model="accountInfo.lastName"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="Company"
              v-model="accountInfo.company"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="Address 1"
              v-model="accountInfo.address1"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="Address 2"
              v-model="accountInfo.address2"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="City"
              v-model="accountInfo.city"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="State/Province"
              v-model="accountInfo.state"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="Zip/Postal code"
              v-model="accountInfo.zip"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="Country"
              v-model="accountInfo.country"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-text-field
              hide-details
              label="Phone"
              v-model="accountInfo.phone"
            ></v-text-field
          ></v-col>
          <v-col cols="12" md="6">
            <v-select
              hide-details
              label="Accept marketing"
              :items="['yes', 'no']"
              v-model="accountInfo.acceptMarketing"
            ></v-select
          ></v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          rounded
          color="primary"
          @click="handleSubmit"
          :disabled="!isValid"
          :loading="isSending"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'
  import { CCPA_RequsetTypes } from '@/config/enums'
  export default {
    props: {
      show: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        isSending: false,
        requestType: CCPA_RequsetTypes.account_info,
        accountInfo: {
          email: '',
          firstName: '',
          lastName: '',
          company: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
          country: '',
          phone: '',
          acceptMarketing: ''
        }
      }
    },
    computed: {
      isValid() {
        return (
          this.accountInfo.email,
          this.accountInfo.firstName,
          this.accountInfo.lastName,
          this.accountInfo.address1,
          this.accountInfo.city,
          this.accountInfo.state,
          this.accountInfo.zip,
          this.accountInfo.country,
          this.accountInfo.phone,
          this.accountInfo.acceptMarketing
        )
      }
    },
    methods: {
      ...mapActions(['sendCCPA']),
      close() {
        this.$emit('close')
      },
      async handleSubmit() {
        this.isSending = true
        await this.sendCCPA({
          info: this.accountInfo,
          requestType: this.requestType
        })
        this.isSending = false
        this.$emit('close', 'submit')
      }
    }
  }
</script>
