<template>
  <v-container fluid class="container">
    <v-card-title>Consultations Analytics</v-card-title>
    <v-row>
      <v-col cols="12" v-if="tab === 3">
        <ConsulatationsNumbers :startDate="startDate" :endDate="endDate" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="tab === 3">
        <ConsultationsSlices :startDate="startDate" :endDate="endDate" />
      </v-col>

      <v-col cols="6" v-if="tab === 3">
        <SystemsChosen :startDate="startDate" :endDate="endDate" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="tab === 3">
        <DiscountUsed :startDate="startDate" :endDate="endDate" />
      </v-col>

      <v-col cols="6" v-if="tab === 3">
        <SalesSubtotal :startDate="startDate" :endDate="endDate" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="tab === 3">
        <ConsultationsApplicatonYesBySystem
          :startDate="startDate"
          :endDate="endDate"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import ConsulatationsNumbers from '@/components/admin/analytics/consultations/ConsultationsNumbers.vue'
  import ConsultationsSlices from '@/components/admin/analytics/consultations/ConsultationsSlices.vue'
  import SystemsChosen from '@/components/admin/analytics/consultations/SystemsChosen.vue'
  import DiscountUsed from '@/components/admin/analytics/consultations/DiscountUsed.vue'
  import SalesSubtotal from '@/components/admin/analytics/consultations/SalesSubtotal.vue'
  import ConsultationsApplicatonYesBySystem from '@/components/admin/analytics/consultations/ConsultationsApplicationYesBySystem.vue'

  export default {
    components: {
      ConsulatationsNumbers,
      ConsultationsSlices,
      SystemsChosen,
      DiscountUsed,
      SalesSubtotal,
      ConsultationsApplicatonYesBySystem
    },
    props: {
      tab: {
        type: Number
      },
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    background-color: #eee;
  }
</style>
