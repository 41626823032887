<template>
  <div>
    <!-- textBox: {
  title: 'Start Kit + In-person Class',
  subtitle: '#FS101-IP',
  paragraphHtml: '<span>Reserve today with only $500 down</span>',
  buttonText: 'Inquire',
  buttonLink: ''
}, -->
    <v-card-text
      v-if="text.subtitle"
      class="text-center text-h6 d-flex justify-center breakword pb-0 font-weight-light"
      v-text="text.subtitle"
    />
    <v-card-text
      v-if="text.title"
      class="text-center text-h5 font-weight-bold d-flex justify-center breakword py-0 color-black"
      v-text="text.title"
    />
    <v-card-text
      v-if="promoPrice && listPrice"
      class="text-center text-h6 d-flex justify-center align-end breakword"
    >
      <span class="align-self-start color-black"
        >{{ isCanada ? 'C' : '' }}$</span
      >
      <span>
        <span class="text-h4 font-weight-bold pr-2 color-black">{{
          promoPrice
        }}</span></span
      >
      <span
        class="text-decoration-line-through font-weight-light"
        style="padding-bottom: 2px"
        >List: {{ isCanada ? 'C' : '' }}${{ listPrice }}</span
      ></v-card-text
    >
    <v-card-text
      v-if="text.paragraphHTML"
      class="text-center text-h6 d-flex justify-center breakword pt-0 font-weight-light"
      v-html="decodedParagraph"
    />
    <v-card-actions v-if="text.buttonText" class="d-flex justify-center pt-0">
      <v-btn
        rounded
        depressed
        class="btn-resetcase px-5"
        color="primary"
        :href="text.buttonLink"
        >{{ text.buttonText }}</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
  import { decode } from 'html-entities'

  export default {
    props: {
      isCanada: {
        type: Boolean,
        default: false
      },
      text: {
        type: Object,
        default: () => ({
          title: '',
          subtitle: '',
          paragraphHTML: '',
          buttonText: '',
          buttonLink: ''
        })
      },
      promoPrice: {
        type: Number,
        required: true
      },
      listPrice: {
        type: Number,
        required: true
      }
    },
    computed: {
      decodedParagraph() {
        return decode(this.text.paragraphHTML)
      }
    },
    data() {
      return {
        decode
      }
    }
  }
</script>

<style lang="scss" scoped>
  .breakword {
    word-break: break-word;
  }
  .color-black {
    color: #0d0d0d;
  }
</style>
