<template>
  <v-container class="pt-0">
    <v-card-title class="breakword title pb-0"
      >What extensions would work better with your lifestyle?</v-card-title
    >
    <v-card-text class="py-0">
      <v-row class="justify-center">
        <v-card-text class="text-center pb-0">Choose one</v-card-text>
        <v-col cols="12" class="d-flex justify-center flex-column align-center">
          <div
            @click="handleSelect(NewSystemOptions.BONDED_EXTENSIONS)"
            :class="
              answer === NewSystemOptions.BONDED_EXTENSIONS
                ? 'custom-btn-selected'
                : 'custom-btn'
            "
          >
            <span
              :class="
                answer === NewSystemOptions.BONDED_EXTENSIONS
                  ? 'custom-btn-selected__main-txt'
                  : 'custom-btn__main-txt'
              "
              >Permanent</span
            >
            <span
              :class="
                answer === NewSystemOptions.BONDED_EXTENSIONS
                  ? 'custom-btn-selected__caption-txt'
                  : 'custom-btn__caption-txt'
              "
              >aka Nanos, Bonded, Keratin, NMU, Lazy Girl</span
            >
          </div>

          <v-card-text class="pl-1 my-0 pt-2" caption
            >One single wear lasting 4-6 months <br />
            Low in-salon maintenance<br />
            Can't be reused</v-card-text
          >
        </v-col>
        <v-col
          cols="12"
          class="pt-0 d-flex justify-center flex-column align-center pb-0"
        >
          <div
            @click="handleSelect(NewSystemOptions.TAPE)"
            :class="
              answer === NewSystemOptions.TAPE
                ? 'custom-btn-selected'
                : 'custom-btn'
            "
          >
            <span
              :class="
                answer === NewSystemOptions.TAPE
                  ? 'custom-btn-selected__main-txt'
                  : 'custom-btn__main-txt'
              "
              >Semi-permanent</span
            >
            <span
              :class="
                answer === NewSystemOptions.TAPE
                  ? 'custom-btn-selected__caption-txt'
                  : 'custom-btn__caption-txt'
              "
              >aka Tape-ins, Quikkies, Secrets, Baby Secrets</span
            >
          </div>

          <v-card-text class="pl-1 my-0 pt-2" caption
            >Can be reused/worn 3 times <br />
            Each reuse/wear last 6-8 weeks<br />
            More in-salon maintenance</v-card-text
          >
        </v-col>
      </v-row>
    </v-card-text></v-container
  >
</template>

<script>
  import { NewSystemOptions } from '@/config/enums'

  export default {
    data() {
      return {
        NewSystemOptions,
        screen: 'lifestyleExtensions',
        question: 'lifestyleExtensions',
        nextScreen: 'systemInfo',
        answer: ''
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false, systemInfo = '') {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          systemInfo,
          goNext
        })
      },
      handleSelect(answer) {
        this.answer = answer
        this.emitSelect(true, this.answer)
      }
    },
    created() {
      this.answer = this.$route.query[this.question] || ''
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped>
  .custom-btn {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #cecece;
    height: 100px;
    width: 100%;
    transition: all 0.28s;
    padding-left: 5px;
    padding-right: 5px;

    &:hover {
      background-color: #a3a3a3;
    }

    &__main-txt {
      color: #000;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }

    &__caption-txt {
      color: #828282;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }

  .custom-btn-selected {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    background-color: #2b2b2b;
    padding-left: 5px;
    padding-right: 5px;

    &:hover {
      background-color: #5e5e5e;
    }

    &__main-txt {
      color: #fff;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
    }

    &__caption-txt {
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }
</style>
