<template>
  <v-container class="px-0 pt-0" v-if="!isLoading">
    <!-- note: if terms not accepted -->
    <TermsPopUp
      @accept="handleTermsAccept"
      :salon="salon"
      v-if="!termsAccepted"
    />

    <v-card class="elevation-0 mx-auto pb-5" v-else>
      <!-- tabs -->
      <v-toolbar dark color="user_primary" flat class="d-flex justify-center">
        <template>
          <v-tabs v-model="tab">
            <v-tabs-slider color="yellow"> </v-tabs-slider>

            <v-tab
              class="text-uppercase"
              v-for="item in items"
              :key="item.title"
              :style="{
                background:
                  item.hasTooltip && showHelpers && showTooltip2
                    ? '#227de1'
                    : '',
                marginRight:
                  item.hasTooltip && showHelpers && showTooltip2 ? '10px' : ''
              }"
            >
              <!-- 1) with tooltip -->
              <v-tooltip
                v-if="item.tooltip && showHelpers && showTooltip2"
                disabled
                bottom
                nudge-bottom="19"
                nudge-right="50"
                color="#1a83f0"
                class="text-center"
                content-class="custom-tooltip"
                z-index="999999"
                v-model="showExplanation"
                ><template v-slot:activator="{ on, attrs }"
                  ><span v-bind="attrs" v-on="on" class="bold-tab">{{
                    item.title
                  }}</span></template
                >
                <div class="d-flex align-center">
                  <span>{{ item.tooltip }}</span
                  ><v-btn
                    rounded
                    small
                    class="btn-resetcase tooltip-btn"
                    @click.stop="handleHideTooltips"
                    >Got it</v-btn
                  >
                </div>
              </v-tooltip>
              <!-- 2) without tooltip -->
              <span
                v-else
                :class="
                  item.hasTooltip && showHelpers && showTooltip2 && 'bold-tab'
                "
                >{{ item.title }}</span
              >
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <!-- Loading -->
      <div v-if="isLoading" class="text-center mt-3">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>

      <!-- Loaded -->
      <template v-else>
        <!-- search -->
        <v-card-text class="px-2 px-sm-15">
          <v-text-field
            block
            hide-details
            dense
            placeholder="Search name"
            rounded
            filled
            prepend-inner-icon="mdi-magnify"
            v-model="search"
          ></v-text-field
        ></v-card-text>

        <v-card-text class="text-center" v-if="conversations.length === 0"
          >No conversations found.</v-card-text
        >
        <!-- conversations -->
        <v-tabs-items v-model="tab" v-else>
          <v-tab-item v-for="item in items" :key="item.value">
            <v-card flat class="px-2 px-sm-15 pt-0">
              <Conversation
                @onGotIt="handleGotIt"
                v-for="conversation in conversations"
                :showTooltip="showTooltip(conversation)"
                :key="conversation.consumer._id"
                :name="conversation.consumer.fullname"
                :lastMessage="
                  conversation.messages[conversation.messages.length - 1].body
                "
                :lastMessageFromConsumer="
                  conversation.messages[conversation.messages.length - 1]
                    .from === MessageFrom.C
                "
                :lastMessageTime="
                  conversation.messages[conversation.messages.length - 1]
                    .createdAt
                "
                :consumerId="conversation.consumer._id"
                :unread="conversation.unread"
                :archived="conversation.salonArchived"
                @onClick="handleConversationClick"
                @onArchive="handleConversationArchive"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </template>
    </v-card>

    <v-dialog
      max-width="350"
      v-model="showArchiveDialog"
      @click:outside="() => (showArchiveDialog = false)"
    >
      <v-card>
        <v-toolbar dark color="user_primary">
          <v-toolbar-title
            :class="detectMob() ? 'subtitle-1' : 'text-titlecase'"
            >How do you want to archive it?</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-icon
            @click="() => (showArchiveDialog = false)"
            class="mb-auto mt-3"
            >mdi-close</v-icon
          >
        </v-toolbar>
        <template v-if="toArchive">
          <v-card-text class="d-flex justify-center mt-5">
            <v-btn
              color="red"
              block
              :loading="isLoadingDidntBookBtn"
              @click="handleArchive(false)"
              >Didn't book a consultation</v-btn
            >
          </v-card-text>
          <v-card-text class="d-flex justify-center">
            <v-btn
              :loading="isLoadingBookedBtn"
              color="green"
              block
              @click="handleArchive(true)"
              >Booked a consultation</v-btn
            >
          </v-card-text></template
        >
        <template v-else>
          <v-card-text class="d-flex justify-center mt-5">
            <v-btn
              :loading="isLoadingInboxBtn"
              color="green"
              block
              @click="handleArchive('none')"
              >Move back to inbox</v-btn
            >
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import TermsPopUp from '@/components/user/TermsPopUp.vue'
  import Conversation from '@/components/user/salon-messages/Conversation.vue'
  import { MessageFrom } from '@/config/enums'
  import { detectMob, getTermsStatus } from '@/utils/helpers'
  import { mapActions } from 'vuex'

  export default {
    components: {
      Conversation,
      TermsPopUp
    },
    data() {
      return {
        termsAccepted: false,
        showTooltip1: true,
        showTooltip2: false,
        showExplanation: true,
        isLoading: true,
        isLoadingInboxBtn: false,
        isLoadingBookedBtn: false,
        isLoadingDidntBookBtn: false,
        detectMob,
        MessageFrom,
        consumerId: '',
        toArchive: false,
        showArchiveDialog: false,
        tab: null,
        search: '',
        items: [
          { title: 'inbox', filters: [{ key: 'salonArchived', value: false }] },
          {
            title: 'booked',
            filters: [
              { key: 'salonArchived', value: true },
              { key: 'salonBookedConsultation', value: true }
            ],
            tooltip: 'The archived convos will be moved here.',
            hasTooltip: true
          },
          {
            title: "didn't book",
            filters: [
              { key: 'salonArchived', value: true },
              { key: 'salonBookedConsultation', value: false }
            ],
            hasTooltip: true
          }
        ]
      }
    },
    computed: {
      salon() {
        return this.$store.state.salons.selectedSalon
      },
      showHelpers() {
        return !this.salon.sawMessagesHelpers
      },
      conversations() {
        const filters = this.items[this.tab]?.filters
        const conversations = this.$store.state.messages.conversations

        if (filters?.length > 0)
          return conversations
            .filter(conv =>
              conv.consumer.fullname
                .toLowerCase()
                .includes(this.search.toLowerCase())
            )
            .filter(conv => {
              let success = true
              filters.forEach(filter => {
                if (conv[filter.key] !== filter.value) success = false
              })
              return success
            })

        return conversations
      },
      salonId() {
        return this.$route.params.salonId
      }
    },
    methods: {
      ...mapActions([
        'fetchConversations',
        'editSalon',
        'fetchSalon',
        'editMessage',
        'createConsultationBySalon',
        'fetchSettings'
      ]),
      handleTermsAccept() {
        this.termsAccepted = true
        this.fetchSettings()
      },
      async handleHideTooltips() {
        this.showTooltip2 = false
        this.salon.sawMessagesHelpers = true
        await this.editSalon({ id: this.salonId, sawMessagesHelpers: true })
        this.fetchSalon(this.salonId)
      },
      handleGotIt() {
        this.showTooltip1 = false
        this.showTooltip2 = true
      },
      showTooltip(conversation) {
        return (
          this.conversations.indexOf(conversation) === 0 &&
          conversation.salonArchived === false &&
          this.showHelpers &&
          this.showTooltip1
        )
      },
      handleConversationClick(consumerId) {
        this.$router.push({
          name: 'NewInquiry',
          params: { salonId: this.salonId, consumerId }
        })
      },
      handleConversationArchive(payload) {
        const { consumerId, toArchive } = payload
        this.consumerId = consumerId
        this.toArchive = toArchive
        this.showArchiveDialog = true
      },
      async handleArchive(booked) {
        if (booked === 'none') {
          this.isLoadingInboxBtn = true
          // 1. messages archive
          const foundMessages = this.conversations.find(
            conv => conv.consumer._id === this.consumerId
          ).messages

          await Promise.all(
            foundMessages.map(async message => {
              await this.editMessage({
                id: message._id,
                salonArchived: this.toArchive
              })
            })
          )
        } else {
          if (booked) this.isLoadingBookedBtn = true
          else this.isLoadingDidntBookBtn = true

          // 1. messages archive
          const foundMessages = this.conversations.find(
            conv => conv.consumer._id === this.consumerId
          ).messages

          await Promise.all(
            foundMessages.map(async message => {
              await this.editMessage({
                id: message._id,
                salonArchived: this.toArchive,
                salonBookedConsultation: booked
              })
            })
          )

          // 2. add consultation tag
          await this.createConsultationBySalon({
            salonId: this.salonId,
            consumerId: this.consumerId,
            booked
          })
        }
        await this.fetchConversations(this.salonId)

        this.isLoadingInboxBtn = false
        this.isLoadingBookedBtn = false
        this.isLoadingDidntBookBtn = false

        this.showArchiveDialog = false
      }
    },
    async created() {
      if (this.detectMob()) {
        this.items[1].title = 'booked'
      }

      // set terms
      // -> isti text poslednjeg agreementa
      // +
      // -> status accepted
      if (
        this.$store.getters['lastAgreement']?.termsDate ==
          this.salon?.terms?.date &&
        getTermsStatus(this.salon?.terms) === 'accepted'
      ) {
        this.termsAccepted = true
      }

      // if coming from take a tour -> reset helpers and remove from $route because of refresh
      if (this.$route.query.showHelpers === 'yes') {
        await this.editSalon({ id: this.salonId, sawMessagesHelpers: false })
        this.$router.replace({
          name: this.$route.name,
          params: this.$route.params,
          query: { ...this.$route.query, showHelpers: undefined }
        })
      }
      await this.fetchSalon(this.salonId)
      await this.fetchConversations(this.salonId)
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped>
  .bold-tab {
    color: #fff;
    font-weight: 600;
  }
  .custom-tooltip {
    pointer-events: auto;
  }
  .tooltip-btn {
    background: #f5f5f5 !important;
    color: #525252;
    margin-left: 2px;
  }
</style>
