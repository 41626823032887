<template>
  <v-container>
    <v-card class="elevation-12 mx-auto" max-width="900">
      <v-img
        :contain="detectMob() ? true : false"
        :src="require(`@/assets/${getImg}`)"
        :lazy-src="require(`@/assets/${getImg}`)"
      ></v-img>
      <v-container class="px-0 px-sm-3">
        <!-- 1. LASERBEAMER -->
        <template v-if="isLaserbeamer">
          <v-card-text class="pb-0 body-1">
            ONE-ON-ONE CLASSES for the Laserbeamer Nano - The revolutionary
            automatic hair application system.</v-card-text
          >
          <v-card-text class="py-1 body-1">
            NOW get the fastest automatic fusion strand-by-strand hair extension
            system that’s a real time saver!
          </v-card-text>
          <v-card-text class="py-1 body-1">
            <ul>
              <li>Applies 5 strands at once in 35 sec</li>
              <li>Full head application 45 min</li>
              <li>Wears for 4-6 months</li>
              <li>No removal and re-application needed during wearing time</li>
              <li>Time-saving, hands-on-line training</li>
            </ul>
          </v-card-text>
          <v-card-text class="py-1 body-1">
            The
            <a @click="showLaserbeamerInfoModal = true">Laserbeamer Nano Kit</a>
            includes everything needed to get started.
          </v-card-text>

          <v-card-text class="body-1 px-0">
            <v-card-text class="body-1 py-0 font-weight-bold"
              >PRICE USD: 50% Off Special !!</v-card-text
            >
            <v-card-text class="body-1 py-0">List Price - $3500</v-card-text>
            <v-card-text class="body-1 py-0"
              ><span class="font-weight-bold">Promotional Price - $1750</span> +
              applicable sales tax</v-card-text
            >
            <v-card-text class="body-1 py-0">
              - $500 deposit/payment option available</v-card-text
            >
          </v-card-text>
          <v-card-text class="body-1">
            YOU MUST HAVE A CURRENT VALID COSMETOLOGY LICENSE TO REGISTER FOR
            THE COURSE.
          </v-card-text>
        </template>
        <!-- 2. FREESTYLER -->
        <template v-if="isFreestyler">
          <v-card-text class="py-1 body-1">
            NOW customize your every client's extension needs by hand
            ergonomically.
          </v-card-text>
          <v-card-text class="py-1 body-1">
            <ul>
              <li>Customizable bonds for any client's needs</li>
              <li>Super small attachment points</li>
              <li>
                Safe application with Hairdreams heat fusion crystal polymer
                bond technology
              </li>
              <li>Wears for 4-6 months</li>
              <li>No removal and re-application needed during wearing time</li>
              <li>
                FreeStyler is ergonomic, lightweight, and is auto-heat
                controlled
              </li>
            </ul>
          </v-card-text>
          <!-- <v-card-text class="py-1 body-1">
            The
            <a @click="showFreestylerInfoModal = true">FreeStyler Kit</a>
            includes everything needed to get started which includes our
            easy-to-use Online Training Module plus a 1 Day hands on in-person
            regional training.
            <a
              class="link"
              href="https://iwanthairdreams.com/pages/training-calendar-freestyler"
              >See Training Calendar</a
            >
          </v-card-text> -->
          <v-card-text class="py-1 body-1">
            Stylist who have a previous certification for fusion bond extensions
            from an approved vendor may be authorized to be trained virtually
            online with a FreeStyler Mini Start Kit. All others will require the
            purchase of a Full Start Kit where you will apply a full head of
            FreeStyler hand rolled extensions on an approved model/client that
            you will provide. We do not offer Regional Classes for the
            FreeStyler full training and can only offer an in-salon training for
            an additional fee. Inquire for details.
          </v-card-text>
          <v-card-text class="body-1 px-0">
            <v-card-text class="body-1 py-0 font-weight-bold"
              >PRICE USD:</v-card-text
            >
            <v-card-text class="body-1 py-0"
              >List Price In-Salon - $4900</v-card-text
            >
            <v-card-text class="body-1 py-0"
              ><span class="font-weight-bold">Promotional Price - $3150</span> +
              applicable sales tax</v-card-text
            >
            <v-card-text class="body-1 py-0">
              - $500 deposit/payment option available</v-card-text
            >
            <v-card-text class="body-1 py-0 font-italic"
              >Online class price available upon approval</v-card-text
            >
          </v-card-text>
          <v-card-text class="body-1">
            YOU MUST HAVE A CURRENT VALID COSMETOLOGY LICENSE TO REGISTER FOR
            THE COURSE.
          </v-card-text>
        </template>
        <!-- 3. MICROLINES -->
        <template v-if="isMicrolines">
          <v-card-text class="py-1 body-1">
            NOW get the most innovation hair integration system that’s a
            solution for all your client’s thinning hair needs!
          </v-card-text>
          <v-card-text class="py-1 body-1">
            <ul>
              <li>
                Virtually invisible micro-fine net is perfectly integrated into
                one's own hair
              </li>
              <li>
                Ideal for thinning hair and hair loss on the top of the head
              </li>
              <li>100 % top quality human hair</li>
              <li>Lightweight, durable and reusable</li>
              <li>
                Permanently attached and allows for a normal, active lifestyle
                which includes showering, swimming, or any other sports
              </li>
              <li>Exclusively applied by highly specialized stylist</li>
              <li>
                Wears up to 1 1/2 years with regular maintenance appointments
                and refresh services
              </li>
            </ul>
          </v-card-text>
          <v-card-text class="text-center headline"
            >MICROLINES START KIT AND CERTIFICATION</v-card-text
          >
          <v-card-text class="py-1 body-1">
            The
            <a @click="showMicrolinesInfoModal = true">MicroLines Start Kit</a>
            includes everything needed to get started which includes our
            easy-to-use Online Training Module plus a 1 Day hands on in-person
            regional training.
            <a
              class="link"
              href="https://iwanthairdreams.com/pages/training-calendar"
              >See Training Calendar</a
            >
          </v-card-text>
          <!-- <v-card-text class="py-1 body-1">
            For a limited time get the system that increases your income and
            changes lives for 50% off list!
          </v-card-text> -->
          <v-card-text class="body-1 px-0">
            <v-card-text class="body-1 py-0 font-weight-bold"
              >PRICE USD: 50% Off Special !!</v-card-text
            >
            <v-card-text class="body-1 py-0">List Price - $4600</v-card-text>
            <v-card-text class="body-1 py-0"
              ><span class="font-weight-bold">Promotional Price - $2300</span> +
              applicable sales tax</v-card-text
            >
            <v-card-text class="body-1 py-0">
              - $500 deposit/payment option available</v-card-text
            >
          </v-card-text>
          <v-card-text class="body-1 font-weight-bold">
            NOTE: You must have a current valid cosmetology license to get
            certified.
          </v-card-text>
        </template>

        <v-card-text class="body-1" v-if="isMicrolines">
          Fill in your information below to receive more information on how to
          get started.
        </v-card-text>

        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>

          <v-btn
            :width="detectMob() ? '90%' : '50%'"
            class="white--text"
            rounded
            color="user_primary"
            @click="handleRegister"
            >Register
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-container>
    </v-card>

    <LaserbeamerInfoModal
      v-if="showLaserbeamerInfoModal"
      :show="showLaserbeamerInfoModal"
      @close="showLaserbeamerInfoModal = false"
    />
    <FreestylerInfoModal
      v-if="showFreestylerInfoModal"
      :show="showFreestylerInfoModal"
      @close="showFreestylerInfoModal = false"
    />
    <MicrolinesInfoModal
      v-if="showMicrolinesInfoModal"
      :show="showMicrolinesInfoModal"
      @close="showMicrolinesInfoModal = false"
    />
  </v-container>
</template>

<script>
  import { SystemOptions } from '@/config/enums'
  import { detectMob } from '@/utils/helpers'
  import LaserbeamerInfoModal from '@/components/user/stylist/LaserbeamerInfoModal.vue'
  import FreestylerInfoModal from '@/components/user/stylist/FreestylerInfoModal.vue'
  import MicrolinesInfoModal from '@/components/user/stylist/MicrolinesInfoModal.vue'

  export default {
    components: {
      LaserbeamerInfoModal,
      FreestylerInfoModal,
      MicrolinesInfoModal
    },
    data() {
      return {
        SystemOptions,
        showLaserbeamerInfoModal: false,
        showFreestylerInfoModal: false,
        showMicrolinesInfoModal: false,
        detectMob,
        isSending: false
      }
    },
    computed: {
      system() {
        return this.$route.query.system
      },
      isMicrolines() {
        return this.system === SystemOptions.MICROLINES
      },
      isLaserbeamer() {
        return this.system === SystemOptions.LASERBEAMER
      },
      isFreestyler() {
        return this.system === SystemOptions.FREESTYLER
      },
      getImg() {
        if (this.$route.query.system === SystemOptions.FREESTYLER)
          return this.detectMob()
            ? 'stylist-freestyler.webp'
            : 'stylist-freestyler-desktop.webp'
        else if (this.$route.query.system === SystemOptions.MICROLINES)
          return this.detectMob()
            ? 'stylist-microlines.webp'
            : 'stylist-microlines-desktop.webp'
        else
          return this.detectMob()
            ? 'stylist-laserbeamer.webp'
            : 'stylist-laserbeamer-desktop.webp'
      }
    },
    methods: {
      async handleRegister() {
        this.$router.push({ name: 'stylist-signup', query: this.$route.query })
      }
    },
    created() {
      if (!this.$store.getters.stylistPrimarySystem) {
        this.$router.push({
          name: 'stylist-primary-system',
          query: this.$route.query
        })
      }
    }
  }
</script>

<style scoped>
  .link {
    text-decoration: underline;
    font-style: italic;
  }
  .grey-color {
    color: #696969;
    line-height: 20px;
  }
</style>
