<template>
  <v-container>
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="400" class="mx-auto mt-5">
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>Sign Up</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="my-0 py-0 text-center">
        Already have an account?
        <v-btn
          plain
          link
          class="text-capitalize pa-0 ma-0"
          color="#19b3cc"
          @click="handleLogin"
          >Sign In</v-btn
        >
      </v-card-text>

      <v-card-text class="pa-5 pt-0 d-flex flex-column">
        <v-text-field
          :rules="[rules.required]"
          label="First Name *"
          v-model="userData.firstName"
          class="pt-0 mt-0"
        />

        <v-text-field
          :rules="[rules.required]"
          label="Last Name *"
          v-model="userData.lastName"
          class="pt-0 mt-0"
        />

        <VuePhoneNumberInput
          :translations="{
            phoneNumberLabel: 'Mobile number'
          }"
          class="mb-6"
          show-code-on-list
          v-model="userData.phone"
          no-example
          default-country-code="US"
          :only-countries="['US', 'CA']"
          color="#222"
          valid-color="#222"
          error-color="red"
          :size="detectMob() ? 'sm' : 'lg'"
          :no-flags="detectMob() ? true : false"
        />

        <v-text-field
          prepend-icon="mdi-email"
          :rules="[rules.required, rules.email]"
          label="Email *"
          v-model="userData.email"
          class="pt-0 mt-0"
        />

        <v-text-field
          prepend-icon="mdi-email"
          :rules="[rules.required, rules.email]"
          label="Confirm Email *"
          v-model="userData.emailConfirm"
          class="pt-0 mt-0"
        />

        <v-text-field
          :rules="[rules.required, rules.minLength]"
          v-model="userData.password"
          :type="showPassword ? 'text' : 'password'"
          label="Password *"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          class="pt-0 mt-0"
        />

        <v-text-field
          :rules="[rules.required, rules.minLength]"
          v-model="userData.passwordConfirm"
          :type="showPasswordConfirm ? 'text' : 'password'"
          label="Repeat Password *"
          prepend-icon="mdi-lock"
          :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPasswordConfirm = !showPasswordConfirm"
        />
        <v-checkbox
          v-model="userData.sendPromotion"
          label="I want to receive promotional and educational emails"
        ></v-checkbox>

        <v-card-actions>
          <v-btn
            block
            color="user_primary"
            class="white--text"
            rounded
            :disabled="disableBtn"
            @click="handleSignup"
            :loading="this.$store.state.auth.loading"
          >
            Sign Up</v-btn
          >
        </v-card-actions>
        <v-card-text
          >By clicking SIGN UP, you are agreeing to the
          <a
            href="https://iwanthairdreams.com/pages/notice-of-collection-of-personal-information"
            target="_blank"
            >Notice of Collection of Personal Information</a
          >
        </v-card-text>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { detectMob } from '@/utils/helpers'

  export default {
    data() {
      return {
        detectMob,
        userData: {
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          passwordConfirm: '',
          phone: '',
          sendPromotion: true
        },
        rules: {
          required: value => !!value || 'Required.',
          minLength: value => value.length >= 6 || 'Min 6 characters',
          email: value => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },
        showPassword: false,
        showPasswordConfirm: false,
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red'
      }
    },
    computed: {
      disableBtn() {
        return (
          !this.userData.firstName ||
          !this.userData.lastName ||
          !this.userData.phone ||
          !this.userData.email ||
          !this.userData.password ||
          this.userData.password.length < 6 ||
          !this.userData.passwordConfirm ||
          this.userData.passwordConfirm.length < 6
        )
      }
    },
    methods: {
      ...mapActions(['signupStylist']),
      handleLogin() {
        this.$router.push({
          name: 'stylist-login',
          query: this.$route.query
        })
      },

      async handleSignup() {
        if (this.userData.password !== this.userData.passwordConfirm) {
          this.errorMessage = 'Your password and confirm password do not match.'
          this.snackbar = true
          return
        }
        if (this.userData.email !== this.userData.emailConfirm) {
          this.errorMessage = 'Your email and confirm email do not match.'
          this.snackbar = true
          return
        }
        await this.signupStylist({
          ...this.userData,
          primarySystem: this.$store.state.stylistsAccount.primarySystem
        })

        if (!this.$store.state.auth.error) {
          this.$router.push({
            name: 'stylist-additional-info',
            params: { stylistId: this.$store.getters.user.stylistId },
            query: this.$route.query
          })
        } else {
          this.errorMessage = this.$store.state.auth.error
          this.snackbar = true
        }
      }
    },
    created() {
      const isLoggedIn = this.$store.getters.stylistLoggedIn
      const hasPrimarySystem = !!this.$store.getters.stylistPrimarySystem
      const hasAdditionalInfo =
        !!this.$store.state.stylistsAccount.stylist?.salon

      if (isLoggedIn) {
        // 1. logged in and no primary system -> redirect to primary system
        if (!hasPrimarySystem) {
          this.$router.push({
            name: 'stylist-primary-system',
            query: this.$route.query
          })
        }
        // 2. logged in and no additional info -> redirect to additional info
        else if (!hasAdditionalInfo) {
          this.$router.push({
            name: 'stylist-additional-info',
            params: { stylistId: this.$store.getters.user.stylistId },
            query: this.$route.query
          })
        }
        // 3. logged in and has primary system and has additional info -> redirect to applications
        else {
          this.$router.push({
            name: 'stylist-applications',
            params: {
              stylistId: this.$store.getters.user.stylistId
            },
            query: this.$route.query
          })
        }
      } else {
        // 4. not logged in and no primary system -> redirect to primary system
        if (!hasPrimarySystem) {
          this.$router.push({
            name: 'stylist-primary-system',
            query: this.$route.query
          })
        }
      }
    }
  }
</script>
