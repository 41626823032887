<template>
  <v-container>
    <v-card max-width="400" class="elevation-12 mx-auto pb-5 pt-0">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Contact Corporate</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="mt-3">
        <v-row>
          <v-col class="d-flex justify-center"
            ><v-btn
              width="90%"
              rounded
              @click="
                $router.push({
                  name: 'NewConsumerContact',
                  query: $route.query
                })
              "
              >I'm a Salon Client</v-btn
            ></v-col
          >
        </v-row>
        <v-row>
          <v-col class="d-flex justify-center"
            ><v-btn
              width="90%"
              rounded
              @click="
                $router.push({
                  name: 'stylist-other-contact',
                  query: $route.query
                })
              "
              >I’m a Salon Pro</v-btn
            ></v-col
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {}
</script>
