<template>
  <v-dialog
    v-model="show"
    max-width="600"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-container fluid class="pa-0">
      <v-card>
        <v-toolbar dark color="#ff5e6c">
          <v-spacer></v-spacer>
          <v-toolbar-title class="text-uppercase"
            >Add Consultation</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>

        <!-- note: SALONS SCREEN -->
        <SalonsScreen
          @onSelectSalon="handleSelectSalon"
          v-if="showSalonsScreen"
        />

        <!-- note: CONSUMERS SCREEN -->
        <ConsumersScreen
          :salonId="salonId"
          v-if="showConsumersScreen"
          @onBack="handleConsumersBack"
          @onSelectConsumer="handleSelectConsumer"
        />

        <!-- note: CONSULTATION SCREEN -->
        <ConsultationScreen
          v-if="showConsultationScreen"
          @onBack="handleConsultationBack"
          :salonId="salonId"
          :consumerId="consumerId"
          @onSubmitSuccess="handleSubmitSuccess"
        /> </v-card
    ></v-container>
  </v-dialog>
</template>

<script>
  import { mapActions } from 'vuex'

  import ConsultationScreen from './ConsultationScreen.vue'
  import ConsumersScreen from './ConsumersScreen.vue'
  import SalonsScreen from './SalonsScreen.vue'

  export default {
    components: { SalonsScreen, ConsumersScreen, ConsultationScreen },
    props: {
      show: {
        type: Boolean,
        required: false
      }
    },
    data() {
      return {
        // submit data
        salonId: null,
        consumerId: null,
        // show/hide
        showSalonsScreen: true,
        showConsumersScreen: false,
        showConsultationScreen: false
      }
    },
    methods: {
      ...mapActions(['clearConsumers', 'clearSalons']),

      close(status) {
        this.$emit('close', status)
      },
      handleSelectSalon(salonId) {
        this.salonId = salonId
        this.showSalonsScreen = false
        this.showConsumersScreen = true
      },
      handleConsumersBack() {
        this.clearConsumers()

        this.showSalonsScreen = true
        this.showConsumersScreen = false
      },
      handleSelectConsumer(consumerId) {
        this.consumerId = consumerId
        this.showConsumersScreen = false
        this.showConsultationScreen = true
      },
      handleConsultationBack() {
        this.showConsumersScreen = true
        this.showConsultationScreen = false
      },
      handleSubmitSuccess() {
        this.close('success')
      }
    },
    created() {
      this.clearConsumers()
      this.clearSalons()
    }
  }
</script>

<style lang="scss" scoped></style>
