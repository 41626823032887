<template>
  <div class="box">
    <div
      class="d-flex justify-center align-center"
      style="height: 236px; min-height: 600px"
      v-if="isLoading"
    >
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <GChart
      v-else
      type="LineChart"
      :data="chartData"
      :options="chartOptions"
      :events="chartEvents"
    />
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <ConsumersBySystemPie :startDate="startDate" :endDate="endDate"
        /></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  import moment from 'moment'
  import ConsumersBySystemPie from './ConsumersBySystemPie.vue'

  export default {
    components: {
      GChart,
      ConsumersBySystemPie
    },
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true,
        chartEvents: {
          select: () => {
            const selection = getSelection()

            let isFound = false
            const selectedText =
              selection?.baseNode?.data || selection?.focusNode?.data

            for (const key in this.chartOptions.series) {
              if (this.chartOptions.series[key].value === selectedText) {
                isFound = true
                this.chartOptions.series[key].lineWidth = 3
                this.chartOptions.series[key].pointsVisible = true
              } else {
                this.chartOptions.series[key].lineWidth = 0.5
                this.chartOptions.series[key].pointsVisible = false
              }
            }
            if (!isFound) {
              for (const key in this.chartOptions.series) {
                this.chartOptions.series[key].lineWidth = 2
                this.chartOptions.series[key].pointsVisible = false
              }
            }
          }
        },
        chartOptions: {
          // explorer: {},
          vAxis: { viewWindow: { min: 0 } },
          title: 'Consumers - total',
          legend: { position: 'bottom' },
          height: 600,
          animation: { startup: true, duration: 500 },
          colors: ['black', 'red', 'blue', 'orange', 'pink'],
          series: {
            0: {
              color: 'black',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Total'
            },
            1: {
              color: 'red',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Extensions'
            },
            2: {
              color: 'blue',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Microlines'
            },
            3: {
              color: 'orange',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Tapes'
            },
            4: {
              color: 'pink',
              pointsVisible: false,
              lineWidth: 2,
              value: 'Bonded (B+LB+FS)'
            }
          }
        }
      }
    },
    computed: {
      total() {
        let total = 0
        this.consumersSystems.forEach(item => (total += item['Total']))
        return total
      },
      consumersSystems() {
        return this.$store.state.analytics.consumersSystems
      },
      chartData() {
        const data = [
          [
            'Date',
            'Total',
            'Extensions',
            'Microlines',
            'Tapes',
            'Bonded (B+LB+FS)'
          ]
        ]
        this.consumersSystems.forEach(item => {
          data.push([
            moment(item.date).format('ddd. MM/DD/YY'),
            item['Total'],
            item['Extensions'],
            item['Microlines'],
            item['Tapes'],
            item['Bonded (B+LB+FS)']
          ])
        })
        return data
      }
    },
    methods: {
      ...mapActions(['fetchConsumersSystems'])
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true

        await this.fetchConsumersSystems({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.chartOptions.title = `Consumers - total: ${this.total}`
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true

        await this.fetchConsumersSystems({
          startDate: this.startDate,
          endDate: this.endDate
        })
        this.chartOptions.title = `Consumers - total: ${this.total}`
        this.isLoading = false
      }
    },
    async created() {
      await this.fetchConsumersSystems({
        startDate: this.startDate,
        endDate: this.endDate
      })
      this.chartOptions.title = `Consumers - total: ${this.total}`
      this.isLoading = false
    }
  }
</script>
<style lang="scss" scoped>
  .box {
    background: #fff;
    min-height: 930px;
  }
</style>
