<template>
  <v-container
    v-if="
      !$store.state.salons.loading && !$store.state.setups.loading && !isLoading
    "
    class="ma-0 pa-0 mx-auto pt-0"
  >
    <v-card class="elevation-12 mx-auto" max-width="700">
      <v-toolbar
        dark
        :color="isAdminPage || isCreateSalon ? 'primary' : 'user_primary'"
      >
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >Edit Salon Finder Page</v-toolbar-title
        >
      </v-toolbar>

      <v-card-text class="pa-0 pa-sm-3">
        <v-container px-1 px-sm-3>
          <v-row
            v-if="isCreateSalon || isAdminPage"
            class="align-center pl-2 mb-2"
          >
            <v-col cols="6" class="my-0 py-0">
              <span class="display-1"
                >Status: {{ salon.active ? 'Active' : 'Not Active' }}</span
              >
            </v-col>
          </v-row>
          <v-card-title class="headline pl-3">{{
            currentStep.title
          }}</v-card-title>
          <v-card-subtitle
            v-if="currentStep.subtitle"
            class="mt-2 pl-3"
            v-html="currentStep.subtitle"
          ></v-card-subtitle>
          <v-row v-if="currentStep.video" class="video">
            <v-col>
              <iframe
                src="https://player.vimeo.com/video/700358269?h=300831d5f2"
                width="100%"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              ></iframe
            ></v-col>
          </v-row>

          <keep-alive>
            <component
              :salon="salon"
              @change="onChange"
              @valid="onValid"
              :is="currentStep.component"
            ></component
          ></keep-alive>
        </v-container>
      </v-card-text>
      <!-- note: feedback -->
      <!-- <v-card-text
        class="d-flex justify-center"
        v-if="!isAdminPage && !isCreateSalon"
      >
        <v-btn @click="showFeedbackModal = true" color="primary"
          >Feedback</v-btn
        >
      </v-card-text> -->
      <v-alert
        v-if="errorMessage"
        class="mx-auto text-center"
        max-width="90%"
        dense
        color="red lighten-2"
        dark
      >
        {{ errorMessage }}
      </v-alert>

      <v-card-actions>
        <v-btn
          rounded
          v-if="currentStep.backBtn"
          class="ml-5 mb-3"
          @click="handleBack"
        >
          {{ currentStep.backBtn }}
        </v-btn>
        <v-spacer></v-spacer>
        <!-- bilo za disabled kada hteli posebnu poruku za step 3 ->  && currentStepNum !== this.steps.length -->
        <v-btn
          rounded
          class="mr-5 mb-3 white--text"
          color="user_primary"
          @click="handleNext"
          :disabled="!currentStep.isValid"
          :loading="isSending"
          >{{ currentStep.nextBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <!-- <FeedbackModal
      v-if="!isAdminPage && !isCreateSalon"
      :salonId="$route.params.salonId"
      :show="showFeedbackModal"
      @close="showFeedbackModal = false"
    /> -->
  </v-container>
</template>

<script>
  import Step1 from '@/components/user/edit-salon/Step1'
  import Step2 from '@/components/user/edit-salon/Step2'
  import Step3 from '@/components/user/edit-salon/Step3'
  import Step4 from '@/components/user/edit-salon/Step4'
  import { mapActions } from 'vuex'
  import { ThankYouPageSources } from '@/config/enums'
  // import FeedbackModal from '@/components/user/FeedbackModal'

  export default {
    components: {
      Step1,
      Step2,
      Step3,
      Step4
      // FeedbackModal
    },
    data() {
      return {
        isLoading: false,
        startEmail: '',
        timeout: null,
        errorMessage: '',
        showFeedbackModal: false,
        isSending: false,
        currentStepNum: 1,
        salon: {},
        steps: [
          {
            num: 1,
            title: 'Business info',
            subtitle: '',
            video: false,
            component: 'Step1',
            backBtn: '',
            // backBtn: this.$route.name === 'EditPendingSalon' ? '' : 'Back',
            nextBtn: 'Next',
            isValid: false
          },
          {
            num: 3,
            title: 'Salon Finder info',
            subtitle: '',
            video: false,
            component: 'Step3',
            backBtn: 'Back',
            nextBtn: 'Next',
            isValid: false
          },
          {
            num: 4,
            title: 'Reviews',
            subtitle: `<span>We want you to look your best! To show positive reviews on your Salon Finder page, Please watch this 2-minute video on how to fill out this section. You can add up to 3 reviews.</span>`,
            video: true,
            component: 'Step4',
            backBtn: 'Back',
            nextBtn: 'Submit',
            isValid: false
          }
        ]
      }
    },
    computed: {
      currentStep() {
        return this.steps[this.currentStepNum - 1]
      },
      isAdminPage() {
        return this.$route.name === 'EditPendingSalon'
      },
      isCreateSalon() {
        return this.$route.name === 'CreateSalon'
      },
      termsAccepted() {
        if (
          this.isCreateSalon ||
          this.isAdminPage ||
          (this.$store.getters['lastAgreement']?.termsDate ==
            this.salon.terms?.date &&
            this.salon.terms?.accept &&
            this.startEmail === this.salon.email)
        ) {
          return true
        } else {
          return false
        }
      }
    },
    methods: {
      ...mapActions([
        'fetchSalon',
        'editSalonTemp',
        'addSalon',
        'fetchSetups',
        'sendVerifySms'
      ]),
      setLocalStorage() {
        localStorage.setItem('salon', JSON.stringify(this.salon))
        localStorage.setItem(
          'currentStepNum',
          JSON.stringify(this.currentStepNum)
        )
      },
      getLocalStorage() {
        return {
          salon: JSON.parse(localStorage.getItem('salon')),
          currentStepNum: JSON.parse(localStorage.getItem('currentStepNum'))
        }
      },
      removeLocalStorage() {
        localStorage.removeItem('salon')
        localStorage.removeItem('currentStepNum')
      },
      onChange(payload) {
        const { prop, value } = payload
        this.salon[prop] = value
        this.setLocalStorage()
      },
      onValid(payload) {
        const { isValid, step } = payload
        this.steps.find(s => s.num === step).isValid = isValid
      },
      async handleNext() {
        // note: only for step 3 show error message (archived)
        // if (
        //   this.currentStepNum === this.steps.length &&
        //   !this.currentStep.isValid
        // ) {
        //   this.timeout = setTimeout(() => {
        //     this.errorMessage = ''
        //   }, 5000)
        //   return (this.errorMessage = 'Please add at least 1 review.')
        // }
        if (this.currentStep.isValid) {
          if (this.currentStepNum < this.steps.length) {
            this.currentStepNum = this.currentStepNum + 1
            this.setLocalStorage()
            this.$router.push({
              name: this.$route.name,
              params: this.$route.params,
              query: { step: this.currentStepNum }
            })
          } else {
            this.isSending = true
            // 1. create new salon
            if (this.isCreateSalon) {
              const newSalon = await this.addSalon({
                ...this.salon,
                _id: undefined
              })
              await this.sendVerifySms(newSalon._id)
            }
            // 2. edit salon
            else {
              this.salon.smsPhones = this.salon.smsPhones.filter(
                phone => !!phone
              )

              const id = this.$route.params.salonId
              // send to backend
              // get systems from questons
              if (!this.isAdminPage) {
                const acceptedSystems = []
                this.salon.systemQuestions.forEach(item => {
                  if (item.accepted) {
                    acceptedSystems.push(item.system)
                  }
                })
                this.salon.systems = acceptedSystems
              }

              const data = {
                ...this.salon,
                _id: undefined
              }
              await this.editSalonTemp({
                id,
                data,
                isAdmin: this.isAdminPage
              })
            }

            if (!this.$store.state.salons.error) {
              // ocisti storage ako ne sledi terms posle
              if (this.termsAccepted) {
                this.removeLocalStorage()
              }
              if (this.isAdminPage) {
                this.$router.push({ name: 'PendingSalons' })
              } else if (this.isCreateSalon) {
                this.$router.push({ name: 'Salons' })
              } else if (!this.termsAccepted) {
                this.$router.push({ name: 'EditSalonTerms' })
              } else {
                this.$router.replace({
                  name: 'ThankYouPage',
                  params: { source: ThankYouPageSources.edit_salon }
                })
              }
            }
            this.isSending = false
          }
        }
      },
      handleBack() {
        if (this.currentStepNum > 1) {
          this.currentStepNum = this.currentStepNum - 1
          this.setLocalStorage()
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params,
            query: { step: this.currentStepNum }
          })
        } else {
          this.removeLocalStorage()
          this.$router.push({ name: 'FindSalon' })
        }
      }
    },
    async created() {
      this.isLoading = true
      // 1. create new salon
      if (!this.$route.params.salonId) {
        this.salon = {
          review1: { content: '' },
          review2: { content: '' },
          review3: { content: '' },
          highlights: [],
          additionalHighlight: '',
          hours: {
            monday: [
              {
                open: '',
                close: '',
                id: '1',
                isOpen: false
              }
            ],
            tuesday: [
              {
                open: '',
                close: '',
                id: '2',
                isOpen: false
              }
            ],
            wednesday: [
              {
                open: '',
                close: '',
                id: '3',
                isOpen: false
              }
            ],
            thursday: [
              {
                open: '',
                close: '',
                id: '4',
                isOpen: false
              }
            ],
            friday: [
              {
                open: '',
                close: '',
                id: '5',
                isOpen: false
              }
            ],
            saturday: [
              {
                open: '',
                close: '',
                id: '6',
                isOpen: false
              }
            ],
            sunday: [
              {
                open: '',
                close: '',
                id: '7',
                isOpen: false
              }
            ]
          },
          smsPhone1: { phoneNumber: '' },
          smsPhone2: { phoneNumber: '' },
          smsPhone3: { phoneNumber: '' }
        }
        return (this.isLoading = false)
      }
      // 2. edit salon
      await Promise.all([
        this.fetchSetups(),
        this.fetchSalon(this.$route.params.salonId)
      ])
      this.salon = this.$store.state.salons.selectedSalon

      // ako ima sacuvano u ls i ako je isti salon kao u route
      if (
        this.getLocalStorage()?.currentStepNum &&
        this.getLocalStorage()?.salon?.id == this.salon.id
      ) {
        this.salon = this.getLocalStorage().salon
        this.currentStepNum = this.getLocalStorage().currentStepNum
      }
      this.startEmail = this.salon.email
      if (!this.isAdminPage && !this.isCreateSalon) {
        this.steps = [
          {
            num: 1,
            title: 'Business info',
            subtitle: '',
            video: false,
            component: 'Step1',
            backBtn: '',
            // backBtn: this.$route.name === 'EditPendingSalon' ? '' : 'Back',
            nextBtn: 'Next',
            isValid: false
          },
          {
            num: 2,
            title: 'For your benefit - we need to know a few things (required)',
            subtitle: '',
            video: false,
            component: 'Step2',
            backBtn: this.$route.name === 'EditPendingSalon' ? '' : 'Back',
            nextBtn: 'Next',
            isValid: false
          },
          {
            num: 3,
            title: 'Salon Finder info',
            subtitle: '',
            video: false,
            component: 'Step3',
            backBtn: 'Back',
            nextBtn: 'Next',
            isValid: false
          },
          {
            num: 4,
            title: 'Reviews',
            subtitle: `<span>We want you to look your best! To show positive reviews on your Salon Finder page, Please watch this 2-minute video on how to fill out this section. You can add up to 3 reviews.</span>`,
            video: true,
            component: 'Step4',
            backBtn: 'Back',
            nextBtn: this.termsAccepted ? 'Submit' : 'Next',
            isValid: false
          }
        ]
      }
      if (this.$route.query.step) {
        this.currentStepNum = +this.$route.query.step
        this.setLocalStorage()
      }
      this.isLoading = false
    },
    beforeDestroy() {
      clearTimeout(this.timeout)
    }
  }
</script>
