<template>
  <div class="chat">
    <v-container class="fill-height">
      <v-row class="fill-height pb-14" align="end">
        <!-- note: is moderator -->
        <v-col class="mx-0 mx-sm-5" v-if="isModerator">
          <div
            v-for="(item, index) in chat"
            :key="index"
            :class="[
              'd-flex flex-column justify-center my-2 ',
              item.from == MessageFrom.HD
                ? 'align-end ml-5'
                : 'align-start mr-5'
            ]"
          >
            <span class="mx-2 caption">{{
              item.from == MessageFrom.HD
                ? 'Hairdreams'
                : item.from == MessageFrom.S
                ? getTitleCase(host)
                : getTitleCase(guestName)
            }}</span>
            <span
              v-if="item.from == MessageFrom.HD"
              class="bubble blue--bubble px-5 text-caption text-sm-body-1"
              >{{ item.msg }}</span
            >

            <span
              v-if="item.from != MessageFrom.HD"
              class="bubble px-5 text-caption text-sm-body-1"
              :class="
                item.from == MessageFrom.S ? 'grey--bubble' : 'orange--bubble'
              "
              >{{ item.msg }}</span
            >
            <span v-if="item.createdAt" class="caption px-2">{{
              item.createdAt | localeDate(true)
            }}</span>
          </div>
        </v-col>
        <!-- not moderator -->
        <v-col v-else>
          <div
            v-for="(item, index) in chat"
            :key="index"
            :class="[
              'd-flex flex-column justify-center my-2 ',
              item.from == host
                ? 'align-end ml-5'
                : item.from === MessageFrom.HD
                ? 'align-center mx-5'
                : 'align-start mr-5'
            ]"
          >
            <span class="mx-2 mx-sm-5 caption">{{
              item.from == host
                ? getTitleCase(hostName)
                : item.from == MessageFrom.HD
                ? 'Hairdreams'
                : getTitleCase(guestName)
            }}</span>
            <span
              v-if="item.from == host"
              class="bubble blue--bubble px-5 mr-1 mr-sm-5 text-caption text-sm-body-1"
              >{{ item.msg }}</span
            >
            <!-- <v-avatar :color="item.from == host ? 'indigo' : 'red'" size="36">
              <span class="white--text">{{
                item.from == host
                  ? hostName[0]
                  : item.from == MessageFrom.HD
                  ? 'HD'
                  : guestName[0]
              }}</span>
            </v-avatar> -->
            <span
              v-if="item.from != host"
              class="bubble ml-2 ml-sm-5 px-5 text-caption text-sm-body-1"
              :class="
                item.from == MessageFrom.HD ? 'orange--bubble' : 'grey--bubble'
              "
              >{{ item.msg }}</span
            >
            <span v-if="item.createdAt" class="caption px-5">{{
              item.createdAt | localeDate(true)
            }}</span>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-footer class="chat-footer" v-if="showSend">
      <v-container class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col>
            <vue-scroll>
              <div class="d-flex flex-row align-center">
                <v-textarea
                  auto-grow
                  rows="1"
                  class="text-caption text-sm-body-1"
                  ref="input"
                  @blur="$emit('blurFocus')"
                  v-model="msg"
                  placeholder="Type Message"
                ></v-textarea>
                <v-btn
                  icon
                  class="ml-4"
                  @click="handleSend"
                  :disabled="isDisabledSend"
                  :loading="isSending"
                >
                  <v-icon small>mdi-send</v-icon>
                </v-btn>
              </div>
            </vue-scroll>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
  import { MessageFrom, MessageTypes } from '@/config/enums'
  import { mapActions } from 'vuex'
  import { getTitleCase } from '@/utils/helpers'

  const showMessage = (minutes = 15) => {
    let show = false
    const messageTime = localStorage.getItem('message-time')
    if (!messageTime) {
      show = true
    } else {
      const messageTimeMS = new Date(messageTime).getTime()
      const timeNowMS = new Date().getTime()
      const timePassedMS = timeNowMS - messageTimeMS
      show = timePassedMS > minutes * 60 * 1000
    }
    localStorage.setItem('message-time', new Date())
    return show
  }

  export default {
    props: {
      isModerator: {
        type: Boolean,
        default: false
      },
      showSend: {
        type: Boolean,
        default: true
      },
      messages: {
        type: Array,
        required: true
      },
      host: { type: String, required: true },
      hostName: { type: String, required: true },
      guestName: { type: String, required: true },
      focus: { type: Boolean, default: false }
    },
    data() {
      return {
        getTitleCase,
        isSending: false,
        MessageFrom,
        chat: [],
        msg: null
      }
    },
    computed: {
      isDisabledSend() {
        return this.isSending || !this.msg || !this.msg.trim()
      }
    },
    methods: {
      ...mapActions(['send_c_s']),
      handleSend() {
        this.chat.push({
          from: this.isModerator ? this.MessageFrom.HD : this.host,
          msg: this.msg.trim(),
          createdAt: new Date()
        })
        const msg = {
          type: MessageTypes.C_S,
          from: this.isModerator ? this.MessageFrom.HD : this.host,
          body: this.msg,
          salon: this.$route.params.salonId,
          consumer: this.$route.params.consumerId
        }
        if (this.host === MessageFrom.S) {
          msg.salonRead = true
        }
        if (this.host === MessageFrom.C) {
          msg.salonArchived = false
        }
        this.msg = null
        // this.isSending = true
        this.send_c_s(msg)
        // this.isSending = false
        this.addReply()
      },
      addReply() {
        if (this.isModerator) return
        // note: check for 1 min (also on backend)
        if (!showMessage(1)) return

        this.chat.push({
          from: this.MessageFrom.HD,
          msg: `Thank you. We'll alert you when ${
            this.host === MessageFrom.S
              ? this.guestName.split(' ')[0]
              : this.getTitleCase(this.guestName)
          } replies.`
        })
      }
    },
    created() {
      this.chat = this.messages
        .map(m => {
          return { msg: m.body, from: m.from, createdAt: m.createdAt }
        })
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
      // // add first message for wf3
      // if (
      //   this.host === MessageFrom.S &&
      //   !this.isModerator &&
      //   this.messages.filter(m => m.from === MessageFrom.S).length < 1
      // ) {
      //   this.chat.unshift({
      //     msg: 'Reminder: End Goal = Consultation! 😉',
      //     from: MessageFrom.HD
      //   })
      // }

      // add first message for wf6 if only one message
      if (
        this.host === MessageFrom.C &&
        !this.isModerator &&
        this.messages.length === 1
      ) {
        this.chat.push({
          msg: `Your message has been sent to ${this.guestName}. We will notify you when the salon replies, which is usually within 24h.`,
          from: MessageFrom.HD
        })
      }
    },
    watch: {
      focus() {
        if (this.focus) {
          this.$refs.input.focus()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .box {
    transition: 1.5s all cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  // .chat {
  //   min-height: 200px;
  // }
  .chat-footer {
    margin-top: 40px;
  }
  .bubble {
    border-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    max-width: 90%;
  }
  .blue--bubble {
    background-color: #1982fc;
    color: white;
  }
  .grey--bubble {
    background-color: lightgrey;
    color: black;
  }
  .orange--bubble {
    background-color: orange;
    color: white;
  }
</style>
