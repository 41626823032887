<template>
  <v-container class="px-0 px-sm-3 pt-0">
    <v-card
      class="elevation-12 mx-auto"
      max-width="550"
      v-if="
        !$store.state.consumers.loading &&
        !$store.state.messages.loading &&
        !$store.state.salons.loading
      "
    >
      <v-toolbar dark color="user_primary" height="50">
        <div @click="onMoreInfo" class="info-icon pl-0 pr-3 pt-5">
          <!-- <v-icon>mdi-arrow-left</v-icon> -->
          <v-icon @click="onMoreInfo" class="info-icon pr-1">mdi-phone</v-icon>
        </div>
        <v-toolbar-title class="text-caption text-sm-h5 mx-auto d-flex pt-5">
          <!-- <v-icon @click="onMoreInfo" class="info-icon pr-1">mdi-phone</v-icon> -->

          {{ c_s.salonName }}
        </v-toolbar-title>
        <div @click="onMoreInfo" class="info-icon pl-3 pr-3 pt-5">
          <v-icon>mdi-information-outline</v-icon>
        </div>
      </v-toolbar>
      <v-toolbar dark color="user_primary" height="40">
        <v-btn
          @click="() => (showDiscountPopUp = true)"
          rounded
          light
          x-small
          class="mx-auto mb-sm-2 mb-0"
          >Get 10% off</v-btn
        >
      </v-toolbar>

      <!-- MESSAGES -->
      <Chat
        :messages="$store.state.messages.c_s.allMessages"
        :host="MessageFrom.C"
        :hostName="c_s.firstName + ' ' + c_s.lastName"
        moderator="HairDreams"
        :guestName="c_s.salonName"
      />
    </v-card>
    <DiscountPopUp
      :addedBy="CONSULTATION_ADDED_BY.CONSUMER"
      title="Get 10% off"
      color="user_primary"
      showScreen1
      v-if="showDiscountPopUp"
      :showDialog="showDiscountPopUp"
      @close="() => (showDiscountPopUp = false)"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { MessageFrom, CONSULTATION_ADDED_BY } from '@/config/enums'
  import Chat from '@/components/user/Chat'
  import DiscountPopUp from '@/components/DiscountPopUp.vue'

  export default {
    components: {
      Chat,
      DiscountPopUp
    },
    data() {
      return {
        CONSULTATION_ADDED_BY,
        MessageFrom,
        showDiscountPopUp: false
      }
    },
    computed: {
      c_s() {
        return this.$store.state.messages.c_s
      }
    },
    methods: {
      ...mapActions(['fetch_c_s']),
      onMoreInfo() {
        this.$router.push({
          name: 'GreatNews',
          params: {
            consumerId: this.$route.params.consumerId,
            salonId: this.$route.params.salonId
          }
        })
      }
    },
    async created() {
      const salonId = this.$route.params.salonId
      const consumerId = this.$route.params.consumerId
      await this.fetch_c_s({ salonId, consumerId })
      if (this.$store.state.messages.error) {
        this.$router.push({ name: 'NotFound' })
      }
    }
  }
</script>

<style scoped>
  .info-icon {
    cursor: pointer;
  }
</style>
