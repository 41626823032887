import api from '@/services/api.service'
import { ConsumerSources } from '../../config/enums'

// state
export const state = {
  list: [],
  totalCount: 0,
  totalConsultationsCount: 0,
  loading: false,
  error: '',
  consumer: null,
  consultations: []
}

// mutations
export const mutations = {
  // USER
  consumerFetched: (state, consumer) => (state.consumer = consumer),
  // ADMIN
  consumersFetched: (state, consumers) => {
    state.list = consumers
  },
  totalCountFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  consumersEmailPromotionFetched: (state, consumers) => {
    state.list = consumers
  },
  totalCountEmailPromotionFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  consumersSmsPromotionFetched: (state, consumers) => {
    state.list = consumers
  },
  totalCountSmsPromotionFetched: (state, totalCount) => {
    state.totalCount = totalCount
  },
  totalConsultationsCountFetched: (state, totalConsultationsCount) => {
    state.totalConsultationsCount = totalConsultationsCount
  },
  consultationsFetched: (state, consultations) => {
    state.consultations = consultations
  },
  consumerEdited: (state, editedConsumer) => {
    state.list = state.list.map(consumer => {
      return consumer._id === editedConsumer._id
        ? { ...editedConsumer }
        : { ...consumer }
    })
  },
  consumerRemoved: (state, id) =>
    (state.list = state.list.filter(s => s._id !== id)),
  loadingConsumersSet: state => (state.loading = true),
  loadingConsumersCleared: state => (state.loading = false),
  errorConsumersSet: (state, error) => (state.error = error),
  errorConsumersCleared: state => (state.error = '')
}

// helper:
const errorHandling = (commit, error) => {
  // console.log(error)
  commit(
    'errorConsumersSet',
    error?.response?.data?.message || 'Something went wrong...'
  )
  commit('loadingConsumersCleared')
  setTimeout(() => {
    commit('errorConsumersCleared')
  }, 4000)
}

// actions
export const actions = {
  getConsumersCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postConsumersCsv(payload)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  getConsumersEmailPromotionCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postConsumersEmailPromotionCsv(payload)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  getConsumersSmsPromotionCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postConsumersSmsPromotionCsv(payload)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  getConsultationsCsv: async ({ commit }, payload) => {
    try {
      const response = await api.postConsultationsCsv(payload)
      return response.data
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addConsumer: async ({ commit }, consumer) => {
    // commit('loadingConsumersSet')
    try {
      const response = await api.postConsumer(consumer)
      commit('consumerFetched', response.data.data)
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  clearConsumers: ({ commit }) => {
    commit('consumersFetched', [])
  },
  fetchConsumers: async ({ commit }, payload = {}) => {
    commit('loadingConsumersSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}

    try {
      const response = await api.getConsumers(limit, page, search)

      commit(
        'consumersFetched',
        response.data.data.filter(
          c => c.source !== ConsumerSources.contact_page
        )
      )
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersTableData: async ({ commit }, payload = {}) => {
    commit('loadingConsumersSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getConsumersTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('consumersFetched', response.data.data)
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersEmailPromotionTableData: async ({ commit }, payload = {}) => {
    commit('loadingConsumersSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getConsumersEmailPromotionTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('consumersEmailPromotionFetched', response.data.data)
      commit('totalCountEmailPromotionFetched', response.data.totalCount)
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersSmsPromotionTableData: async ({ commit }, payload = {}) => {
    commit('loadingConsumersSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getConsumersSmsPromotionTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('consumersSmsPromotionFetched', response.data.data)
      commit('totalCountSmsPromotionFetched', response.data.totalCount)
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumersByFirstNameLastNameAndSalon: async (
    { commit },
    payload = {}
  ) => {
    commit('loadingConsumersSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getConsumersByFirstNameLastNameAndSalon(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('consumersFetched', response.data.data)
      commit('totalCountFetched', response.data.totalCount)
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // archive
  fetchConsultations: async ({ commit }) => {
    commit('loadingConsumersSet')
    try {
      const response = await api.getConsultations()

      commit(
        'consultationsFetched',
        response.data.data.filter(
          c => c.source !== ConsumerSources.contact_page
        )
      )
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsultationsTableData: async ({ commit }, payload = {}) => {
    commit('loadingConsumersSet')

    const limit = payload.limit || 100000000
    const page = payload.page || 1
    const search = payload.search || {}
    const sortBy = payload.sortBy
    const sortDesc = payload.sortDesc

    try {
      const response = await api.getConsultationsTableData(
        limit,
        page,
        search,
        sortBy,
        sortDesc
      )

      commit('consultationsFetched', response.data.data)
      commit('totalConsultationsCountFetched', response.data.totalCount)
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  fetchConsumer: async ({ commit }, id) => {
    commit('loadingConsumersSet')
    try {
      const response = await api.getConsumer(id)
      commit('consumerFetched', response.data.data)
      commit('loadingConsumersCleared')
    } catch (error) {
      // console.log(error)
      commit('loadingConsumersCleared')
      // errorHandling(commit, error)
    }
  },
  clearConsumer: async ({ commit }) => {
    commit('consumerFetched', null)
  },
  removeConsumer: async ({ commit }, id) => {
    commit('loadingConsumersSet')
    try {
      await api.deleteConsumer(id)
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  addSalonsByDistance: async ({ commit }, payload) => {
    const { id, distance } = payload
    // commit('loadingConsumersSet')
    try {
      const response = await api.addSalonsByDistance(id, distance)
      commit('consumerFetched', response.data.data)
      // commit('loadingConsumersCleared')
      return response.data.salonCount
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  saveForLater: async ({ commit }, data) => {
    // commit('loadingConsumersSet')
    try {
      await api.saveForLater(data)
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  clearConsumer({ commit }) {
    commit('consumerFetched', null)
  },
  revealCode: async ({ commit }, payload) => {
    // commit('loadingConsumersSet')
    const { data, salonId, consumerId } = payload
    try {
      const response = await api.revealCode(consumerId, salonId, data)
      commit('consumerFetched', response.data.data)
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  // use it for deactivate also (send from component active:false)
  editConsumer: async ({ commit }, consumer) => {
    commit('loadingConsumersSet')

    try {
      const { data } = await api.patchConsumer(consumer)
      // delete
      if (consumer.active === false) {
        commit('consumerRemoved', consumer.id)
      }
      // edit
      else {
        commit('consumerEdited', data.data)
      }
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendDiscountMail: async ({ commit }, payload) => {
    // commit('loadingConsumersSet')
    const { consumerId, salonId } = payload
    try {
      await api.sendDiscountMail(consumerId, salonId)
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendFollowUpMail: async ({ commit }, payload) => {
    // commit('loadingConsumersSet')
    const { consumerId, salonId } = payload
    try {
      await api.sendFollowUpMail(consumerId, salonId)
      // commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  sendVerifyConsumerSms: async ({ commit }, formattedPhone) => {
    try {
      await api.sendVerifyConsumerSms(formattedPhone)
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  createConsultationByAdmin: async ({ commit }, payload) => {
    commit('loadingConsumersSet')

    try {
      const response = await api.createConsultationByAdmin(payload)
      commit('consumerFetched', response.data.data)
      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  },
  createConsultationBySalon: async ({ commit }, payload) => {
    commit('loadingConsumersSet')

    try {
      await api.createConsultationBySalon(payload)

      commit('loadingConsumersCleared')
    } catch (error) {
      errorHandling(commit, error)
    }
  }
}
