<template>
  <v-container fluid>
    <ConsultationWizard
      v-if="showConsultationWizard"
      :show="showConsultationWizard"
      @close="handleConsultationWizardClose"
    />
    <!-- <DiscountPopUp
      :tag="DEFERRED_EMAIL.WF4H_CODE"
      title="Add a New Consultation"
      color="primary"
      :setMinDate="false"
      v-if="showDiscountPopUp"
      :showDialog="showDiscountPopUp"
      @close="handleCloseDiscountPopUp"
    /> -->
    <v-data-table
      dense
      :headers="headers"
      :items="consultations"
      item-key="code"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      class="elevation-1 ml-3"
      @page-count="pageCount = $event"
      :server-items-length="totalConsultations"
      :loading="isLoading"
      @update:options="handleUpdateOptions"
    >
      <template v-slot:top>
        <v-snackbar
          centered
          top
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
          >{{ notification }}</v-snackbar
        >
        <v-toolbar flat>
          <v-toolbar-title>Consultations</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <span class="title"
            >Count: {{ $store.state.consumers.totalConsultationsCount }}</span
          >
          <v-spacer></v-spacer>
          <v-btn @click="handleExportData" :loading="isExportingData">
            <v-icon class="mx-1">mdi-download</v-icon>
            Export CSV
          </v-btn>
          <!-- edit -->
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title class="bgd-color-grey">
                <span class="headline">Edit Consultation</span>
              </v-card-title>

              <v-card-text class="bgd-color-grey">
                <v-container class="mp-5">
                  <v-row class="bgd-color-white pb-5 my-5">
                    <v-card-text class="headline py-1">Consumer</v-card-text>
                    <input v-model="editedItem.consumerId" type="hidden" />
                    <v-col cols="12" sm="12" md="8">
                      <v-text-field
                        v-model="editedItem.consumer"
                        disabled
                        label="Full Name"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                      <v-text-field
                        v-model="editedItem.zip"
                        disabled
                        label="Zip"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.phone"
                        disabled
                        label="Phone"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.source"
                        disabled
                        label="Source"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.email"
                        disabled
                        label="E-mail"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.system"
                        disabled
                        label="Initial System"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="bgd-color-white my-5 pb-5">
                    <v-card-text class="headline py-1">Salon</v-card-text>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.salon"
                        disabled
                        label="Salon"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.navid"
                        disabled
                        label="Salon navid"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="bgd-color-white my-5 pb-5">
                    <v-card-text class="headline py-1"
                      >Discount Code</v-card-text
                    >
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.code"
                        disabled
                        label="Discount code"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.expiringDate"
                        disabled
                        label="Discount code expires"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="bgd-color-white my-5 pb-5">
                    <v-card-text class="headline py-1 bgd-color-blue"
                      >Consultation</v-card-text
                    >
                    <!-- Date -->
                    <v-col cols="12" sm="6"
                      ><v-text-field
                        v-if="editedItem.consumerId"
                        label="Date"
                        type="date"
                        :value="
                          editedItem.date
                            ? moment(editedItem.date).format('yyyy-MM-DD')
                            : null
                        "
                        @change="handleDate($event, 'date')"
                        hide-details
                    /></v-col>
                    <!-- Time -->
                    <v-col cols="12" sm="6">
                      <v-dialog
                        ref="dialog"
                        v-model="modal2"
                        :return-value.sync="editedItem.time"
                        persistent
                        width="290px"
                        color="white"
                        dark
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details
                            :value="formatedTime"
                            label="Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          format="ampm"
                          v-if="modal2"
                          v-model="editedItem.time"
                          full-width
                          dark
                        >
                          <v-spacer></v-spacer>
                          <v-btn text color="white" @click="modal2 = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="white"
                            @click="$refs.dialog.save(editedItem.time)"
                          >
                            OK
                          </v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :items="Object.values(CONSULTATION_COMPLETED_OPTIONS)"
                        v-model="editedItem.consultationCompleted"
                        label="Consultation completed?"
                        hide-details
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row class="bgd-color-white my-5 pb-5">
                    <v-card-text class="headline py-1 bgd-color-blue"
                      >Application</v-card-text
                    >
                    <v-col cols="12">
                      <v-select
                        :items="Object.values(APPLICATION_BOOKED_OPTIONS)"
                        v-model="editedItem.applicationBooked"
                        label="Application booked?"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="editedItem.applicationBooked === 'yes'"
                    >
                      <v-select
                        :items="systemBookedOptions"
                        v-model="editedItem.systemBooked"
                        label="System booked"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-if="editedItem.applicationBooked === 'yes'"
                    >
                      <v-text-field
                        type="date"
                        v-if="editedItem.consumerId"
                        :value="
                          editedItem.applicationDate
                            ? moment(editedItem.applicationDate).format(
                                'yyyy-MM-DD'
                              )
                            : null
                        "
                        @change="handleDate($event, 'applicationDate')"
                        label="Application Date"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                      v-if="editedItem.applicationBooked === 'yes'"
                    >
                      <v-select
                        :items="['First Time Client', 'Conversion Client']"
                        v-model="editedItem.customerType"
                        label="Customer Type"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="6"
                      v-if="editedItem.applicationBooked === 'yes'"
                    >
                      <v-text-field
                        v-model="editedItem.quote"
                        label="Quote"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row
                    class="bgd-color-white my-5 pb-5"
                    v-if="editedItem.applicationBooked === 'yes'"
                  >
                    <v-card-text class="headline py-1 bgd-color-blue"
                      >Order Info</v-card-text
                    >
                    <v-col cols="12" sm="12" md="7">
                      <v-text-field
                        type="date"
                        v-if="editedItem.consumerId"
                        :value="
                          editedItem.salonRedeemedCodeDate
                            ? moment(editedItem.salonRedeemedCodeDate).format(
                                'yyyy-MM-DD'
                              )
                            : null
                        "
                        @change="handleDate($event, 'salonRedeemedCodeDate')"
                        label="Salon redeemed code date"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.orderNumber"
                        label="Order Number"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.subtotal"
                        label="Subtotal"
                        type="number"
                        prefix="$"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-col cols="12" sm="12" md="12" class="mt-5">
                    <v-textarea
                      background-color="white"
                      v-model="editedItem.memo"
                      label="Memo"
                      auto-grow
                      outlined
                      rows="3"
                      row-height="25"
                      shaped
                      filled
                      hide-details
                    ></v-textarea>
                  </v-col>
                </v-container>
              </v-card-text>

              <v-card-actions class="bgd-color-grey">
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="handleSave"
                  :loading="isSending"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline"
                >Are you sure you want to delete this consumer?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>

        <!-- choose date filter -->
        <v-toolbar flat>
          <v-radio-group v-model="dateChoice" row class="ml-4">
            <v-radio label="Code Revealed Date" value="issuingDate"></v-radio>
            <v-radio label="Application Date" value="applicationDate"></v-radio>
          </v-radio-group>
          <v-spacer></v-spacer>
          <v-btn
            color="#ff5e6c"
            class="white--text"
            @click="() => (showConsultationWizard = true)"
            >Add a new consultation</v-btn
          >
        </v-toolbar>

        <!-- search by date -->
        <v-toolbar flat>
          <DateFilter @change="handleDateChange" :limit="false" />
        </v-toolbar>
      </template>

      <!-- custom filters -->
      <template v-slot:body.prepend>
        <tr>
          <td v-for="header in headers" :key="header.value">
            <FilterField
              v-if="header.toFilter"
              :type="header.filterType"
              :options="header.filterOptions"
              @change="handleFilterChange($event, header)"
              :value="filters[header.value]"
            />
            <span v-else />
          </td>
        </tr>
      </template>

      <!-- for edit action -->
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!-- <v-icon v-if="$store.getters.isUserFull" small @click="deleteItem(item)">
              mdi-delete
            </v-icon> -->
      </template>

      <!-- promotion -->
      <template v-slot:item.sendPromotion="{ item }">
        <v-chip
          small
          text-color="white"
          :color="item.sendPromotion === 'yes' ? 'green' : 'red'"
          >{{ item.sendPromotion }}</v-chip
        >
      </template>

      <!-- system -->
      <template v-slot:item.system="{ item }">
        <v-chip x-small class="ma-2" color="pink" label text-color="white"
          ><v-icon x-small left> mdi-label </v-icon>{{ item.system }}</v-chip
        >
      </template>

      <!-- system booked -->
      <template v-slot:item.systemBooked="{ item }">
        <v-chip
          v-if="item.systemBooked && item.systemBooked !== 'N/A'"
          x-small
          class="ma-2"
          color="pink"
          label
          text-color="white"
          ><v-icon x-small left> mdi-label </v-icon
          >{{ item.systemBooked }}</v-chip
        >
        <span v-else>{{ item.systemBooked }}</span>
      </template>

      <!-- issuing date -->
      <template v-slot:item.issuingDate="{ item }">
        <span v-if="item.issuingDate">{{
          item.issuingDate | localeDate(true)
        }}</span>
        <span v-else></span>
      </template>

      <!-- application date -->
      <template v-slot:item.applicationDate="{ item }">
        <span v-if="item.applicationDate">{{
          item.applicationDate | localeDate(false)
        }}</span>
        <span v-else></span>
      </template>

      <!-- expiring date -->
      <template v-slot:item.expiringDate="{ item }">
        <span v-if="item.expiringDate">{{
          item.expiringDate | localeDate(false)
        }}</span>
        <span v-else></span>
      </template>

      <!-- date and time date -->
      <template v-slot:item.dateAndTime="{ item }">
        <span v-if="item.dateAndTime">{{
          item.dateAndTime | localeDate(true)
        }}</span>
        <span v-else></span>
      </template>

      <!-- date -->
      <template v-slot:item.date="{ item }">
        <span v-if="item.date">{{ item.date | localeDate(false) }}</span>
        <span v-else></span>
      </template>

      <!-- time -->
      <template v-slot:item.time="{ item }">
        <span v-if="item.time">{{ item.time }}</span>
        <span v-else></span>
      </template>

      <!-- subtotal -->
      <template v-slot:item.subtotal="{ item }">
        <span v-if="item.subtotal">{{ '$' + item.subtotal }}</span>
        <span v-else></span>
      </template>

      <!-- salon redeemed code date -->
      <template v-slot:item.salonRedeemedCodeDate="{ item }">
        <v-chip
          :color="item.salonRedeemedCodeDate ? 'green' : 'yellow'"
          small
          :text-color="item.salonRedeemedCodeDate ? 'white' : 'grey'"
          >{{ item.salonRedeemedCodeDate ? 'Yes' : 'No Answer' }}</v-chip
        >
        {{
          item.salonRedeemedCodeDate
            ? moment(new Date(item.salonRedeemedCodeDate)).format('YYYY-MM-DD')
            : ''
        }}
      </template>

      <!-- deferred emails -->
      <template v-slot:item.deferredEmails="{ item }">
        <v-tooltip
          top
          max-width="200"
          :color="
            mail === DEFERRED_EMAIL.FOLLOW_UP
              ? 'green'
              : mail === DEFERRED_EMAIL.DISCOUNT
              ? 'orange'
              : mail === 'none'
              ? 'grey'
              : 'blue'
          "
          v-for="(mail, index) in item.deferredEmails"
          :key="index"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="ma-1"
              :color="
                mail === DEFERRED_EMAIL.FOLLOW_UP
                  ? 'green'
                  : mail === DEFERRED_EMAIL.DISCOUNT
                  ? 'orange'
                  : mail === 'none'
                  ? 'grey'
                  : 'blue'
              "
              x-small
              text-color="white"
              >{{ mail }}</v-chip
            >
          </template>
          <span>{{ tooltipText(mail) }}</span>
        </v-tooltip>
      </template>

      <!-- consultation completed  -->
      <template v-slot:item.consultationCompleted="{ item }">
        <span v-if="!item.consultationCompleted"></span>
        <span v-else>
          <v-chip
            class="ma-1"
            :color="
              item.consultationCompleted === CONSULTATION_COMPLETED_OPTIONS.YES
                ? 'green'
                : item.consultationCompleted ===
                  CONSULTATION_COMPLETED_OPTIONS.RESCHEDULED
                ? 'yellow'
                : 'red'
            "
            x-small
            :text-color="
              item.consultationCompleted ===
              CONSULTATION_COMPLETED_OPTIONS.RESCHEDULED
                ? 'grey'
                : 'white'
            "
            >{{ item.consultationCompleted }}</v-chip
          ></span
        >
      </template>

      <!-- application booked  -->
      <template v-slot:item.applicationBooked="{ item }">
        <span v-if="!item.applicationBooked"></span>
        <span v-else>
          <v-chip
            class="ma-1"
            :color="
              item.applicationBooked === APPLICATION_BOOKED_OPTIONS.YES
                ? 'green'
                : 'red'
            "
            x-small
            text-color="white"
            >{{ item.applicationBooked }}</v-chip
          ></span
        >
      </template>

      <!-- ADDED BY -->
      <template v-slot:item.addedBy="{ item }">
        <template v-if="item.addedBy && item.addedBy.length > 0">
          <v-chip
            v-for="ab in item.addedBy"
            :key="ab"
            x-small
            class="ma-2"
            :color="addedByColor(ab)"
            label
            text-color="white"
            >{{ ab }}</v-chip
          >
        </template>
        <span v-else>{{ item.addedBy }}</span>
      </template>
    </v-data-table>
    <!-- NOTE: PAGINATION -->
    <div
      class="text-center pt-2"
      v-if="consultations && consultations.length > 0"
    >
      <div class="text-center">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination
                  v-model="page"
                  class="my-4"
                  :length="pageCount"
                  circle
                ></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-container>
</template>

<script>
  import {
    APPLICATION_BOOKED_OPTIONS,
    AdditionalSystemOptions,
    CONSULTATION_COMPLETED_OPTIONS,
    DEFERRED_EMAIL,
    SystemOptions
  } from '@/config/enums'
  import { getConsultationsTableInfo } from '@/config/tables.helper'
  import { mapActions } from 'vuex'

  import { ampmTime } from '@/utils/filters'
  import moment from 'moment'
  // import DiscountPopUp from '@/components/DiscountPopUp.vue'
  import DateFilter from '@/components/DateFilter.vue'
  import FilterField from '@/components/FilterField.vue'
  import ConsultationWizard from '@/components/user/consultation-wizard/Index.vue'
  import { CONSULTATION_ADDED_BY } from '../../config/enums'

  export default {
    data() {
      return {
        showConsultationWizard: false,
        // pagintaion
        page: 1,
        itemsPerPage: 10,
        pageCount: 0,
        // sorting
        sortBy: 'issuingDate',
        sortDesc: false,
        // search
        search: {},
        // other
        isExportingData: false,
        filters: getConsultationsTableInfo(this).filters,
        headers: [],
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        notification: '',
        isLoading: false,
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: getConsultationsTableInfo(this).item,
        defaultItem: getConsultationsTableInfo(this).item,
        // showDiscountPopUp: false,
        CONSULTATION_COMPLETED_OPTIONS,
        APPLICATION_BOOKED_OPTIONS,
        DEFERRED_EMAIL,
        dateChoice: 'issuingDate',
        ampmTime,
        moment,
        modal2: false,
        isSending: false
      }
    },
    computed: {
      consultations() {
        return this.$store.state.consumers.consultations
      },
      totalConsultations() {
        return this.$store.state.consumers.totalConsultationsCount
      },
      systemBookedOptions() {
        return [
          ...Object.values(SystemOptions),
          ...Object.values(AdditionalSystemOptions)
        ]
      },
      formatedTime() {
        if (this.editedItem.time) {
          return this.ampmTime(this.editedItem.time)
        }
        return this.editedItem.time
      }
    },
    methods: {
      ...mapActions([
        'fetchConsultationsTableData',
        'getConsultationsCsv',
        'editConsumer',
        'clearConsumer',
        'fetchConsumer'
      ]),
      addedByColor(addedBy) {
        switch (addedBy) {
          case CONSULTATION_ADDED_BY.SALON:
            return 'blue'
          case CONSULTATION_ADDED_BY.ADMIN:
            return 'green'
          case CONSULTATION_ADDED_BY.CONSUMER:
            return 'orange'
          default:
            return 'grey'
        }
      },
      tooltipText(mail) {
        switch (mail) {
          case DEFERRED_EMAIL.WF4S_CODE:
            return 'Salon created consultation.'
          case DEFERRED_EMAIL.NO_ACT_24:
            return "Consumer has salons, but didn't message or call within 24 hours. We sent an email to encourage them to message the salon."
          case DEFERRED_EMAIL.NO_ACT_48_DISCOUNT:
            return "Consumer has salons, but didn't message or call within 48 hours. We sent an email telling them they can get 10% on their 1st HD Application."
          case DEFERRED_EMAIL.DISCOUNT:
            return 'Consumer has messaged the salon and Salon messaged or called back within 24 hours. Alternatively, consumer called the salon. They received the email to tell us their consultation date and time and to generate their discount code. If there are multiple “discount” tags that means Consumer messaged more than 1 salon.'
          case DEFERRED_EMAIL.FOLLOW_UP:
            return "Consumer has messaged the salon, but Salon didn't message or call them through our app. If there are multiple “follow-up” tags that means Consumer messaged more than 1 salon."
          case DEFERRED_EMAIL.WF4H_CODE:
            return 'Consumer has generated a discount code from the WF6 chat page and told us their consultation date and time. Alternatively, We aka the Admin has generated the code for them from the Consultation database or admin chat page.'
          case 'none':
            return 'Consumer has no salons.'
          default:
            return ''
        }
      },
      async handleUpdateOptions(options) {
        this.sortBy = options.sortBy[0] ? options.sortBy[0] : 'issuingDate'
        this.sortDesc = options.sortDesc
        await this.updateData()
      },
      async updateData() {
        this.isLoading = true
        await this.fetchConsultationsTableData({
          page: this.page,
          limit: this.itemsPerPage,
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
          search: this.search
        })
        this.pageCount = Math.ceil(
          this.$store.state.consumers.totalConsultationsCount /
            this.itemsPerPage
        )
        this.isLoading = false
      },
      async handleDateChange(dates = null) {
        this.page = 1
        if (dates) {
          this.search['startDate'] = dates.startDate
          this.search['endDate'] = dates.endDate
        }
        this.search['dateType'] = this.dateChoice
        await this.updateData()
      },
      async handleFilterChange(value, header) {
        this.page = 1
        this.search[header.value] = value
        await this.updateData()
      },
      async handleExportData() {
        this.isExportingData = true
        let transform = {}
        getConsultationsTableInfo(this).headers.forEach(h => {
          if (h.csv !== false) {
            transform[h.text] = h.value
          }
        })
        const data = await this.getConsultationsCsv({ transform })
        const blob = new Blob([data], { type: 'text/csv' })
        saveAs(blob, 'consultations.csv')
        this.isExportingData = false
      },
      // async handleCloseDiscountPopUp(hasCreatedDiscount) {
      //   // only fetch if create new discount
      //   if (hasCreatedDiscount) {
      //     await this.updateData()
      //   }
      //   this.clearConsumer()

      //   this.showDiscountPopUp = false
      // },
      // CRUD
      handleDate(value, prop) {
        this.editedItem[prop] = value
      },
      editItem(item) {
        this.editedIndex = this.consultations.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem(item) {
        this.editedIndex = this.consultations.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      async deleteItemConfirm() {
        this.consultations.splice(this.editedIndex, 1)
        this.closeDelete()
        // dispatch -> edit with active:false
        await this.editConsumer({ id: this.editedItem.id, active: false })
        if (!this.$store.state.consumers.error) {
          this.snackbarColor = 'red'
          this.notification = `Consumer deleted.`
          this.snackbar = true
        }
      },
      handleConsultationWizardClose(status) {
        if (status === 'success') this.updateData()

        this.showConsultationWizard = false
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async handleSave() {
        if (this.editedIndex > -1) {
          this.isSending = true
          // find old discounts on consumer and replace updated discount
          await this.fetchConsumer(this.editedItem.consumerId)
          const discount = {
            ...this.$store.state.consumers.consumer.discounts.find(
              d => d.salon === this.editedItem.salonId
            ),
            date: this.editedItem.date,
            time: this.editedItem.time,
            applicationBooked: this.editedItem.applicationBooked,
            systemBooked: this.editedItem.systemBooked,
            applicationDate: this.editedItem.applicationDate,
            salonRedeemedCodeDate: this.editedItem.salonRedeemedCodeDate,
            customerType: this.editedItem.customerType,
            quote: this.editedItem.quote,
            orderNumber: this.editedItem.orderNumber,
            subtotal: this.editedItem.subtotal,
            consultationCompleted: this.editedItem.consultationCompleted
          }

          const discounts =
            this.$store.state.consumers.consumer.discounts.filter(
              d => d.salon !== this.editedItem.salonId
            )

          discounts.push(discount)

          // set state
          Object.assign(this.consultations[this.editedIndex], this.editedItem)
          // set state memo
          this.consultations.forEach(cons => {
            if (cons.consumerId === this.editedItem.consumerId) {
              cons.memo = this.editedItem.memo
            }
          })

          // dispatch
          await this.editConsumer({
            id: this.editedItem.consumerId,
            discounts,
            memo: this.editedItem.memo
          })
          if (!this.$store.state.consumers.error) {
            this.snackbarColor = 'green'
            this.notification = 'Consultation saved.'
            this.snackbar = true
          }
        }
        this.isSending = false
        this.close()
      }
    },
    watch: {
      async page(newValue, oldValue) {
        await this.updateData()
      },
      async dateChoice(newValue, oldValue) {
        await this.handleDateChange()
      }
    },
    created() {
      this.headers = getConsultationsTableInfo(this).headers
    },
    components: {
      // DiscountPopUp,
      DateFilter,
      FilterField,
      ConsultationWizard
    }
  }
</script>
