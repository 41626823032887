var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',{staticClass:"elevation-12 mx-auto pb-5 pt-0",attrs:{"max-width":"400"}},[_c('v-toolbar',{attrs:{"dark":"","color":"user_primary"}},[_c('v-toolbar-title',{staticClass:"text-sm-h5 mx-auto"},[_vm._v("Contact Corporate")])],1),_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"width":"90%","rounded":""},on:{"click":function($event){return _vm.$router.push({
                name: 'NewConsumerContact',
                query: _vm.$route.query
              })}}},[_vm._v("I'm a Salon Client")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"width":"90%","rounded":""},on:{"click":function($event){return _vm.$router.push({
                name: 'stylist-other-contact',
                query: _vm.$route.query
              })}}},[_vm._v("I’m a Salon Pro")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }