var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isLoading)?_c('v-card',{staticClass:"elevation-0 mx-auto",attrs:{"max-width":"550"}},[_c('Title',{attrs:{"text":_vm.source.title}}),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ImageBox',{attrs:{"options":_vm.source.items[1]},on:{"action":_vm.handleImageAction}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ImageBox',{attrs:{"options":_vm.source.items[2]},on:{"action":_vm.handleImageAction}}),_c('ImageBox',{staticClass:"mt-4",attrs:{"options":_vm.source.items[3]},on:{"action":_vm.handleImageAction}})],1)],1),_c('TextBox',{attrs:{"isCanada":_vm.isCanada,"text":{
        ..._vm.source.textBox,
        paragraphHTML:
          _vm.settings[_vm.StylistsCertifications.FS_IN_PERSON]?.paragraph,
        buttonText: _vm.settings[_vm.StylistsCertifications.FS_IN_PERSON]?.buttonText,
        buttonLink: _vm.settings[_vm.StylistsCertifications.FS_IN_PERSON]?.buttonLink
      },"promo-price":_vm.settings[_vm.StylistsCertifications.FS_IN_PERSON]?.promoPrice,"list-price":_vm.settings[_vm.StylistsCertifications.FS_IN_PERSON]?.listPrice}}),_c('v-divider',{staticClass:"my-5"}),_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"text-h5 font-weight-bold breakword color-blue px-0"},[_c('v-spacer'),_c('span',{staticClass:"color-blue text-center font-weight-medium"},[_vm._v("Why FreeStyler?")]),_c('v-spacer')],1),_c('v-expansion-panel-content',{staticClass:"accordion"},[_c('FreestylerView',{attrs:{"source":_vm.stylistSoruces[_vm.SystemOptions.FREESTYLER],"action":false,"cert-page":_vm.StylistsCertifications.FS_IN_PERSON}})],1)],1)],1),_c('CertPopUp',{attrs:{"show":_vm.showPopUp,"options":_vm.options},on:{"close":_vm.handleClosePopUp}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }