<template>
  <div
    class="d-flex justify-center align-center"
    style="height: 236px"
    v-if="isLoading"
  >
    <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
  </div>
  <div class="d-flex justify-center align-center" v-else>
    <div class="item">
      <span> {{ total }}</span> Consumers - total
    </div>
    <div class="item">
      <span> {{ emptySalonsNear }} </span> Consumers - no salon
    </div>
    <div class="item">
      <span> {{ contactedSalon }}</span> Consumers - Contacted salon
    </div>
    <div class="item">
      <span> {{ hasSmsPhoneValidated }}</span> Consumers - SMS
    </div>
  </div>
  <!-- <span class=""> Consumers - no salon within 100 mi:
        {{ emptySalonsNearChosenDistance100 }} </span>  -->
  <!-- <v-card-text class="subtitle-1"
        >Consumers - SMS: {{ hasSmsPhoneValidated }}</v-card-text
      > -->
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    props: {
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      total() {
        return this.$store.state.analytics.consumersNumbers?.total
      },
      emptySalonsNear() {
        return this.$store.state.analytics.consumersNumbers?.emptySalonsNear
      },
      emptySalonsNearChosenDistance100() {
        return this.$store.state.analytics.consumersNumbers
          ?.emptySalonsNearChosenDistance100
      },
      contactedSalon() {
        return this.$store.state.analytics.advancedConsumersContacted
      },
      hasSmsPhoneValidated() {
        return this.$store.state.analytics.consumersNumbers
          ?.hasSmsPhoneValidated
      }
    },
    methods: {
      ...mapActions([
        'fetchConsumersNumbers',
        'fetchAdvancedConsumersContacted'
      ])
    },
    watch: {
      async startDate(newValue, oldValue) {
        this.isLoading = true

        await Promise.all([
          this.fetchConsumersNumbers({
            startDate: this.startDate,
            endDate: this.endDate
          }),
          this.fetchAdvancedConsumersContacted({
            startDate: this.startDate,
            endDate: this.endDate
          })
        ])
        this.isLoading = false
      },
      async endDate(newValue, oldValue) {
        this.isLoading = true

        await Promise.all([
          this.fetchConsumersNumbers({
            startDate: this.startDate,
            endDate: this.endDate
          }),
          this.fetchAdvancedConsumersContacted({
            startDate: this.startDate,
            endDate: this.endDate
          })
        ])
        this.isLoading = false
      }
    },
    async created() {
      await Promise.all([
        this.fetchConsumersNumbers({
          startDate: this.startDate,
          endDate: this.endDate
        }),
        this.fetchAdvancedConsumersContacted({
          startDate: this.startDate,
          endDate: this.endDate
        })
      ])
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    padding: 20px 35px;
    text-align: center;
  }
  .item span {
    text-align: center;
    line-height: 50px;
    font-size: 50px;
    font-weight: 700;
  }
</style>
