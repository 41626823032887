<template>
  <v-dialog
    v-model="open"
    @click:outside="() => $emit('close')"
    @keydown.esc="() => $emit('close')"
    max-width="500"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-capitalize"
          >Review {{ num }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container>
        <v-row>
          <v-col class="mb-0 pb-0">
            <v-card-text class="text-subtitle-1 mb-0 text-center">
              What kind of review is this?
            </v-card-text></v-col
          ><samp></samp>
        </v-row>
        <v-row class="px-3 pt-0 justify-center">
          <v-col class="d-flex justify-end">
            <v-btn
              rounded
              :dark="reviewType === ReviewTypes.FB"
              :x-small="detectXS() ? true : false"
              :small="detectXS() ? false : true"
              @click="onFbChange(ReviewTypes.FB)"
              >Facebook</v-btn
            ></v-col
          >
          <v-col class="d-flex justify-start">
            <v-btn
              rounded
              :x-small="detectXS() ? true : false"
              :small="detectXS() ? false : true"
              :dark="
                reviewType === ReviewTypes.OTHER ||
                reviewType === ReviewTypes.YELP ||
                reviewType === ReviewTypes.GOOGLE
              "
              @click="reviewType = ReviewTypes.OTHER"
              >Other Type</v-btn
            ></v-col
          >
        </v-row>
        <v-row class="pa-3">
          <v-col v-if="reviewType === ReviewTypes.FB">
            <v-text-field
              hide-details
              v-model="fbContent"
              :label="'Review ' + num"
              placeholder="Insert facebook embed code"
            ></v-text-field>
          </v-col>
          <v-col
            v-if="
              reviewType === ReviewTypes.OTHER ||
              reviewType === ReviewTypes.YELP ||
              reviewType === ReviewTypes.GOOGLE
            "
          >
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  v-model="name"
                  label="Name"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  hide-details
                  v-model="date"
                  label="Date"
                  type="date"
                  outlined
                  dense
                  :max="getTodayString()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="content"
                  filled
                  outlined
                  prepend-inner-icon="mdi-comment"
                  hide-details
                  auto-grow
                  label="Content"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col class="d-flex justify-center"
                ><v-btn
                  :x-small="detectXS() ? true : false"
                  :small="detectXS() ? false : true"
                  rounded
                  @click="onOtherChange(ReviewTypes.YELP)"
                  :dark="reviewType === ReviewTypes.YELP"
                  >Yelp Review</v-btn
                ></v-col
              >
              <v-col class="d-flex justify-center"
                ><v-btn
                  :x-small="detectXS() ? true : false"
                  :small="detectXS() ? false : true"
                  rounded
                  @click="onOtherChange(ReviewTypes.GOOGLE)"
                  :dark="reviewType === ReviewTypes.GOOGLE"
                  >Google Review</v-btn
                ></v-col
              >
              <v-col class="d-flex justify-center"
                ><v-btn
                  :x-small="detectXS() ? true : false"
                  :small="detectXS() ? false : true"
                  rounded
                  @click="onOtherChange(ReviewTypes.OTHER)"
                  :dark="reviewType === ReviewTypes.OTHER"
                  >Other Type</v-btn
                ></v-col
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn rounded @click="() => $emit('close')"> Cancel </v-btn>
        <v-btn rounded color="primary" :disabled="!isValid" @click="onClose">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { ReviewTypes } from '@/config/enums'
  import { detectXS, getTodayString } from '@/utils/helpers'
  export default {
    props: ['open', 'num', 'review'],
    data() {
      return {
        getTodayString,
        detectXS,
        ReviewTypes,
        reviewType: null,
        name: '',
        date: '',
        content: '',
        fbContent: ''
      }
    },
    computed: {
      isValid() {
        let valid = false
        if (this.reviewType === this.ReviewTypes.FB) {
          valid = !!this.fbContent
        } else {
          valid =
            !!this.content && !!this.reviewType && !!this.name && this.date
        }
        return valid
      }
    },
    methods: {
      onClose() {
        if (this.reviewType === this.ReviewTypes.FB) {
          this.$emit('fb-change', {
            content: this.fbContent,
            reviewType: this.reviewType,
            num: this.num
          })
        } else {
          this.$emit('other-change', {
            name: this.name,
            date: this.date,
            content: this.content,
            reviewType: this.reviewType,
            num: this.num
          })
        }
        this.$emit('close')
      },
      onFbChange(reviewType) {
        this.reviewType = reviewType
      },
      onOtherChange(reviewType) {
        this.reviewType = reviewType
      }
    },
    mounted() {
      if (this.review?.reviewType === this.ReviewTypes.FB) {
        this.fbContent = this.review.content
        this.reviewType = this.review.reviewType
      } else {
        this.name = this.review?.name
        this.date = this.review?.date
        this.content = this.review?.content
        this.reviewType = this.review?.reviewType
      }
    }
  }
</script>
