<template>
  <v-container fluid class="container">
    <v-card-title>Sources of Traffic Analytics</v-card-title>
    <v-row
      ><v-col cols="12" v-if="tab === 0"
        ><WhereAreWeGoing :startDate="startDate" :endDate="endDate" /></v-col
      ><v-col cols="12" v-if="tab === 0"
        ><TopConsumerSources :startDate="startDate" :endDate="endDate" /></v-col
      ><v-col cols="12" v-if="tab === 0"
        ><TopStylistSources :startDate="startDate" :endDate="endDate" /></v-col
    ></v-row>
  </v-container>
</template>

<script>
  import WhereAreWeGoing from '@/components/admin/analytics/traffic/WhereAreWeGoing.vue'
  import TopConsumerSources from '@/components/admin/analytics/traffic/TopConsumerSources.vue'
  import TopStylistSources from '@/components/admin/analytics/traffic/TopStylistSources.vue'

  export default {
    components: {
      WhereAreWeGoing,
      TopConsumerSources,
      TopStylistSources
    },
    props: {
      tab: {
        type: Number
      },
      startDate: {
        type: String
      },
      endDate: {
        type: String
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    background-color: #eee;
  }
</style>
