import { render, staticRenderFns } from "./LbFullView.vue?vue&type=template&id=77aebbf8&scoped=true&"
import script from "./LbFullView.vue?vue&type=script&lang=js&"
export * from "./LbFullView.vue?vue&type=script&lang=js&"
import style0 from "./LbFullView.vue?vue&type=style&index=0&id=77aebbf8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77aebbf8",
  null
  
)

export default component.exports