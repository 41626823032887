<template>
  <v-dialog
    v-model="show"
    max-width="300"
    @click:outside="close"
    @keydown.esc="() => $emit('close')"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title class="text-uppercase">Success</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="mt-3 text-center"
        >Your request was submitted successfully</v-card-text
      >
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: false
      }
    },

    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
</script>
