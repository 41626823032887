<template>
  <v-card class="elevation-0 mt-2"
    ><v-card-title
      class="justify-center text-center pb-3 breakword"
      :class="bigText && 'text-sm-h5'"
      v-html="text"
    >
    </v-card-title>
    <v-card-actions class="justify-center pt-0">
      <v-btn
        @click="handleClick"
        rounded
        depressed
        class="btn-resetcase px-5"
        color="primary"
        >{{ btnText }}</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        default: ''
      },
      btnText: {
        type: String,
        default: ''
      },
      bigText: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      handleClick() {
        this.$emit('click')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .breakword {
    word-break: break-word;
  }
</style>
