<template>
  <v-container>
    <v-row class="justify-center">
      <v-col cols="12" class="pl-0 pb-0">
        <v-card-title class="breakword pb-0"
          ><span class="font-weight-bold">Hair Loss Clients<br /></span>How much
          do you feel your client would be willing to spend per month on
          Microlines Topper salon maintenance services?
        </v-card-title></v-col
      >
      <v-card-text class="text-center subtitle pt-0">Choose one</v-card-text>
      <v-col
        v-for="option in options"
        :key="option.value"
        class="d-flex justify-center flex-column align-center py-0"
        :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-3'"
        cols="6"
        @click="handleSelect(option.value)"
      >
        <v-btn
          depressed
          :color="answer === option.value ? 'user_cta' : 'user_btn'"
          :dark="answer === option.value"
          class="text-none mt-1"
          width="100%"
          >{{ option.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        answer: '',
        options: [
          {
            text: '$50 or less',
            value: '$50 or less'
          },
          {
            text: '$50 to $100',
            value: '$50 to $100'
          },
          {
            text: '$100 to $200',
            value: '$100 to $200'
          },
          {
            text: '$200 to $400',
            value: '$200 to $400'
          }
          // {
          //   text: '$400+',
          //   value: '$400+'
          // }
        ],
        field: 'microlinesMaintenanceSpend',
        screen: 'stylist-restoration3',
        nextScreen: 'stylist-restoration4'
      }
    },
    methods: {
      ...mapActions([
        'setApplicationData',
        'setCurrentScreen',
        'setNextScreen',
        'setIsValid'
      ]),
      handleNextScreen() {
        this.setNextScreen(this.nextScreen)
      },
      handleSelect(answer) {
        this.answer = answer
        // 1. set data
        this.setApplicationData({ field: this.field, answer })
        // 2. set next screen depending on this component logic
        this.handleNextScreen()
        // 3. set valid depending on this component logic
        this.setIsValid(true)
        // 4. go to next screen
        this.$emit('next')
      }
    },
    created() {
      // 1. set current screen
      this.setCurrentScreen(this.screen)
      // 2. set answer, sent next screen and valid if data exists
      if (this.$store.state.applications.application[this.field]) {
        this.answer = this.$store.state.applications.application[this.field]
        this.handleNextScreen()
        this.setIsValid(true)
      }
      // 3. set valid if data doesn't exist
      else {
        this.setIsValid(false)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .system-img {
    cursor: pointer;
  }
  .btn-caption {
    color: #7d7d7d;
    font-size: 10px;
  }
  .breakword {
    word-break: break-word;
  }
</style>
