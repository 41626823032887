<template>
  <v-container class="pt-0">
    <v-card class="elevation-12 mx-auto" max-width="400">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >WF2.3: Welcome</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="pa-5">
        <v-row class="justify-center px-0">
          <v-col cols="12" class="d-flex justify-center px-0">
            <v-btn
              :small="detectXS()"
              @click="() => $router.push({ name: 'userType' })"
            >
              I am looking for a salon near me
            </v-btn>
          </v-col>

          <v-col cols="12" class="d-flex justify-center px-0">
            <v-btn
              :small="detectXS()"
              @click="() => $router.push({ name: 'NewConsumerContact' })"
            >
              Other
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { detectXS } from '@/utils/helpers'
  export default {
    data() {
      return {
        detectXS
      }
    }
  }
</script>
