<template>
  <div class="px-3">
    <v-card-text>
      <!-- NOTE: CONSULTATION -->
      <v-row class="bgd-color-white my-5 pb-5">
        <v-card-text class="headline py-1 bgd-color-blue"
          >Consultation</v-card-text
        >
        <!-- Date -->
        <v-col cols="12" sm="6"
          ><v-text-field
            :disabled="dateAndTimeUnknown"
            label="Date"
            type="date"
            :value="moment(date).format('yyyy-MM-DD')"
            @change="handleDate($event, 'date')"
            hide-details
        /></v-col>
        <!-- Time -->
        <v-col cols="12" sm="6">
          <v-dialog
            ref="dialog"
            v-model="modal2"
            :return-value.sync="time"
            persistent
            width="290px"
            color="white"
            dark
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :disabled="dateAndTimeUnknown"
                hide-details
                :value="formatedTime"
                label="Time"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-time-picker
              format="ampm"
              v-if="modal2"
              v-model="time"
              full-width
              dark
            >
              <v-spacer></v-spacer>
              <v-btn text color="white" @click="modal2 = false"> Cancel </v-btn>
              <v-btn text color="white" @click="$refs.dialog.save(time)">
                OK
              </v-btn>
            </v-time-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-checkbox
            hide-details
            v-model="dateAndTimeUnknown"
            label="Date and time unknown"
          />
        </v-col>
        <v-col cols="12">
          <v-select
            :items="Object.values(CONSULTATION_COMPLETED_OPTIONS)"
            v-model="consultationCompleted"
            label="Consultation completed?"
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <!-- NOTE: APPLICATION -->
      <v-row class="bgd-color-white my-5 pb-5">
        <v-card-text class="headline py-1 bgd-color-blue"
          >Application</v-card-text
        >
        <v-col cols="12">
          <v-select
            :items="Object.values(APPLICATION_BOOKED_OPTIONS)"
            v-model="applicationBooked"
            label="Application booked?"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" v-if="applicationBooked === 'yes'">
          <v-select
            :items="systemBookedOptions"
            v-model="systemBooked"
            label="System booked"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="6" v-if="applicationBooked === 'yes'">
          <v-text-field
            type="date"
            v-if="consumerId"
            :value="
              applicationDate
                ? moment(applicationDate).format('yyyy-MM-DD')
                : null
            "
            @change="handleDate($event, 'applicationDate')"
            label="Application Date"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6" v-if="applicationBooked === 'yes'">
          <v-select
            :items="['First Time Client', 'Conversion Client']"
            v-model="customerType"
            label="Customer Type"
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="12" md="6" v-if="applicationBooked === 'yes'">
          <v-text-field
            v-model="quote"
            label="Quote"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- NOTE: ORDER INFO -->
      <v-row
        class="bgd-color-white my-5 pb-5"
        v-if="applicationBooked === 'yes'"
      >
        <v-card-text class="headline py-1 bgd-color-blue"
          >Order Info</v-card-text
        >
        <v-col cols="12" sm="12" md="7">
          <v-text-field
            type="date"
            :value="
              salonRedeemedCodeDate
                ? moment(salonRedeemedCodeDate).format('yyyy-MM-DD')
                : null
            "
            @change="handleDate($event, 'salonRedeemedCodeDate')"
            label="Salon redeemed code date"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model="orderNumber"
            label="Order Number"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            v-model="subtotal"
            label="Subtotal"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions class="pb-3">
      <v-btn small @click="handleConsultationBack" rounded>Back</v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="!isValid"
        color="#ff5e6c"
        :loading="$store.state.consumers.loading"
        class="white--text"
        rounded
        @click="handleSubmit"
        >Submit</v-btn
      >
    </v-card-actions>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import moment from 'moment'
  import {
    SystemOptions,
    AdditionalSystemOptions,
    CONSULTATION_COMPLETED_OPTIONS,
    CONSULTATION_ADDED_BY,
    APPLICATION_BOOKED_OPTIONS
  } from '@/config/enums'
  import { ampmTime } from '@/utils/filters'

  export default {
    props: {
      salonId: {
        type: String,
        required: true
      },
      consumerId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        dateAndTimeUnknown: false,
        CONSULTATION_COMPLETED_OPTIONS,
        APPLICATION_BOOKED_OPTIONS,
        date: null,
        time: null,
        consultationCompleted: '',
        applicationBooked: '',
        systemBooked: '',
        applicationDate: null,
        customerType: '',
        quote: '',
        orderNumber: '',
        subtotal: '',
        salonRedeemedCodeDate: null,
        ampmTime,
        moment,
        modal2: false
      }
    },
    computed: {
      systemBookedOptions() {
        return [
          ...Object.values(SystemOptions),
          ...Object.values(AdditionalSystemOptions)
        ]
      },
      formatedTime() {
        if (this.time) {
          return this.ampmTime(this.time)
        }
        return this.time
      },
      isValid() {
        return (this.time && this.date) || this.dateAndTimeUnknown
      }
    },
    methods: {
      ...mapActions(['createConsultationByAdmin']),

      handleDate(value, prop) {
        this[prop] = value
      },
      handleConsultationBack() {
        this.$emit('onBack')
      },

      async handleSubmit() {
        const discount = {
          addedBy: CONSULTATION_ADDED_BY.ADMIN,
          consumerId: this.consumerId,
          salonId: this.salonId,
          applicationBooked: this.applicationBooked,
          systemBooked: this.systemBooked,
          applicationDate: this.applicationDate,
          salonRedeemedCodeDate: this.salonRedeemedCodeDate,
          customerType: this.customerType,
          quote: this.quote,
          orderNumber: this.orderNumber,
          subtotal: this.subtotal,
          consultationCompleted: this.consultationCompleted
        }

        // dodaj date and time ako nije oznacio da je nepoznato
        if (!this.dateAndTimeUnknown) {
          const minutes_time =
            Number(this.time.split(':')[0]) * 60 +
            Number(this.time.split(':')[1])

          const dateAndTime = moment(this.date).add(minutes_time, 'minutes')

          discount.date = this.date
          discount.time = this.time
          discount.dateAndTime = dateAndTime.format('YYYY-MM-DD HH:mm')
        }

        await this.createConsultationByAdmin(discount)

        if (!this.$store.state.consumers.error) {
          this.$emit('onSubmitSuccess')
        }
      }
    },
    watch: {
      dateAndTimeUnknown(newValue, oldValue) {
        if (newValue) {
          this.time = null
          this.date = null
        }
      }
    }
  }
</script>
