<template>
  <v-container class="px-0 px-sm-3 pt-0">
    <template
      v-if="
        !$store.state.messages.loading &&
        !$store.state.setups.loading &&
        !$store.state.salons.loading
      "
    >
      <!-- note: if terms not accepted -->
      <TermsPopUp
        @accept="handleTermsAccept"
        :salon="salon"
        v-if="!termsAccepted"
      />

      <!-- note: if terms accepted -->
      <v-card class="elevation-12 mx-auto" max-width="680" v-else>
        <!-- note: title -->
        <!-- <v-toolbar dark color="user_primary">
          <v-toolbar-title class="text-sm-h5 mx-auto"
            >New Client Inquiry
          </v-toolbar-title>
        </v-toolbar> -->

        <!-- ALL MESSAGES -->
        <v-card-text class="ml-1 pb-0">
          <MessagesBadge
            :salonId="$route.params.salonId"
            @onClick="
              () =>
                $router.push({
                  name: 'SalonMessages',
                  params: { salonId: $route.params.salonId }
                })
            "
          />
        </v-card-text>

        <!-- INFO -->
        <v-card class="mx-5 mt-5 info-card" color="#5d91da">
          <!-- note: accordion MORE INFO -->
          <v-expansion-panels
            accordion
            flat
            v-model="panel"
            multiple
            class="panel"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                color="#5d91da"
                class="color-blue pt-2 pt-sm-4 pb-5 pb-sm-6 px-2 px-sm-3"
                hide-actions
              >
                <template v-slot:default>
                  <v-row>
                    <v-card-text class="text-h6 text-sm-h4 pb-1 color-white">{{
                      c_s.firstName + ' ' + c_s.lastName
                    }}</v-card-text>
                    <v-card-text class="color-white py-0"
                      >Interested in
                      {{ ShortSystemOptions(c_s.system) }}</v-card-text
                    >
                  </v-row>

                  <!-- see more/less -->
                  <v-btn
                    :small="detectMob()"
                    depressed
                    rounded
                    color="white"
                    dark
                    class="text-capitalize text-color mr-3 pa-5 pa-sm-5 mt-2"
                    >See {{ panel.length > 0 ? 'less' : 'more' }}</v-btn
                  >
                  <!-- phone - mobile -->
                  <template v-if="isMobile">
                    <v-btn
                      class="mt-2 px-3"
                      fab
                      depressed
                      rounded
                      :small="detectXS() ? true : false"
                      @click.stop="onCallMobile"
                    >
                      <v-icon dark color="#4269a7"> mdi-phone </v-icon>
                    </v-btn>
                  </template>

                  <!-- phone - desktop -->
                  <template v-else>
                    <v-btn
                      class="pa-5 mt-2"
                      depressed
                      @click.stop="onCallDesktop"
                      rounded
                      :fab="!showPhone"
                      :small="!showPhone"
                    >
                      <span v-if="showPhone" class="text-color">{{
                        c_s.smsPhone.phoneNumber
                      }}</span>
                      <span v-else>
                        <v-icon color="#4269a7" dark> mdi-phone </v-icon>
                      </span>
                    </v-btn>
                  </template>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="py-2" color="#5d91da">
                <!-- note: basic info -->
                <v-row>
                  <v-col
                    class="text-subtitle-1 py-0 box--wrapper color-white"
                    cols="12"
                    sm="6"
                  >
                    <span>System</span>
                    <!-- microlines -> filter+check -->
                    <div
                      v-if="c_s.system === SystemOptions.MICROLINES"
                      class="box"
                    >
                      <span v-if="c_s.question4 === getQ4Info().answers[0]"
                        >Selected
                        <span class="font-weight-bold">{{
                          c_s.system | shortSystem
                        }}</span
                        >, may only need
                        <span class="font-weight-bold">Secrets</span></span
                      >
                      <span v-else-if="c_s.question4 === getQ4Info().answers[1]"
                        >Selected <span class="font-weight-bold">Secrets</span>,
                        may only need
                        <span class="font-weight-bold">{{
                          c_s.system | shortSystem
                        }}</span></span
                      >
                      <span v-else
                        >Selected
                        <span class="font-weight-bold">{{
                          c_s.system | shortSystem
                        }}</span
                        >, may only need
                        <span class="font-weight-bold">Secrets</span></span
                      >
                    </div>
                    <!-- not microlines -> use filter only -->
                    <div v-else class="font-weight-bold box">
                      {{ c_s.system | shortSystem }}
                    </div>
                  </v-col>
                  <v-col class="text-subtitle-1 pt-0 box--wrapper">
                    <span class="color-white">Potential Profit</span>
                    <div class="font-weight-bold box">
                      <span class="green--text">${{ c_s.productPrice }}*</span>
                      <v-card-text
                        v-if="c_s.system === SystemOptions.MICROLINES"
                        class="pa-0 caption color-white"
                        >based on MicroLines suggested pricing</v-card-text
                      >
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- note: q1 -->
                  <v-col
                    class="text-subtitle-1 py-0 box--wrapper color-white"
                    cols="12"
                    sm="6"
                  >
                    <span>{{ q1.question }}</span>
                    <div
                      class="font-weight-bold box fix-height d-flex align-center"
                      v-if="isMicrolines"
                    >
                      <v-row class="justify-center">
                        <v-col
                          cols="5"
                          v-for="a in q1.answer"
                          :key="a"
                          class="pa-1 ma-1 text-capitalize small-box"
                          >{{ a }}</v-col
                        >
                      </v-row>
                    </div>
                    <div class="font-weight-bold box fix-height" v-else>
                      <v-img
                        class="img"
                        :src="require(`@/assets/${q1.img}`)"
                        :lazy-src="require(`@/assets/${q1.img}`)"
                      />
                      <span class="caption">{{ q1.answer }}</span>
                    </div>
                  </v-col>
                  <!-- note: q2 -->
                  <v-col class="text-subtitle-1 py-0 box--wrapper color-white">
                    <span>{{ q2.question }}</span>

                    <div class="font-weight-bold box fix-height">
                      <v-img class="img" :src="require(`@/assets/${q2.img}`)" />
                      <span class="caption">{{ q2.answer }}</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="!isMicrolines && q3.answer">
                  <!-- note: q3 -->
                  <v-col class="text-subtitle-1 py-0 box--wrapper color-white">
                    <span>{{ q3.question }}</span>
                    <div class="box">
                      {{ q3.answer }}
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>

        <!-- <v-card-text class="headline ml-2 mt-3 mb-0 pb-0"
          >Hair Preferences</v-card-text
        > -->

        <!-- note: MESSAGES -->
        <v-card-text class="headline ml-2 mt-5 mb-0 pb-0">Messages</v-card-text>
        <v-card class="elevation-12 mx-5 mt-2">
          <Chat
            :focus="messageFocus"
            @blurFocus="messageFocus = false"
            :messages="$store.state.messages.c_s.allMessages"
            :host="MessageFrom.S"
            :hostName="c_s.salonName"
            :guestName="c_s.firstName + ' ' + c_s.lastName"
          />
        </v-card>

        <!-- feedback -->
        <!-- <v-card-text class="d-flex justify-center pt-4">
        <v-btn @click="showFeedbackModal = true" color="primary"
          >Feedback</v-btn
        >
      </v-card-text> -->
        <v-card-text class="ml-2 text-caption mt-5 mb-2"
          >*The profit earnings are based on Hairdreams recommended price for
          service and maintenance over the course of 6 months and does not
          include hair or product.</v-card-text
        >
        <SalonBottomMenu />
      </v-card>
    </template>

    <FeedbackModal
      :salonId="$route.params.salonId"
      :show="showFeedbackModal"
      @close="showFeedbackModal = false"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import TermsPopUp from '@/components/user/TermsPopUp.vue'
  import Chat from '@/components/user/Chat'
  import { getTermsStatus } from '@/utils/helpers'

  import {
    CallFrom,
    CallTypes,
    MessageFrom,
    NewSystemOptions,
    SystemOptions,
    ShortSystemOptions
  } from '@/config/enums'
  import {
    getQ1MInfo,
    getQ1Info,
    getQ2Info,
    getQ3Info,
    getQ4Info,
    detectMob,
    detectXS
  } from '@/utils/helpers'

  import SalonBottomMenu from '@/components/user/SalonBottomMenu'
  import FeedbackModal from '@/components/user/FeedbackModal'
  import MessagesBadge from '@/components/user/salon-messages/MessagesBadge.vue'

  export default {
    components: {
      SalonBottomMenu,
      TermsPopUp,
      Chat,
      FeedbackModal,
      MessagesBadge
    },
    data() {
      return {
        detectMob,
        ShortSystemOptions,
        salon: '',
        panel: [],
        getQ4Info,
        SystemOptions,
        showFeedbackModal: false,
        detectXS,
        isMobile: false,
        showPhone: false,
        messageFocus: false,
        MessageFrom,
        termsAccepted: false
      }
    },
    computed: {
      c_s() {
        return this.$store.state.messages.c_s
      },
      isMicrolines() {
        return this.c_s.system === NewSystemOptions.MICROLINES
      },
      q1() {
        let question, answer, img
        if (this.isMicrolines) {
          question = getQ1MInfo(this.c_s.question1).shortQuestion
          answer = getQ1MInfo(this.c_s.question1).answerArray
        } else {
          question = getQ1Info(this.c_s.question1).shortQuestion
          img = getQ1Info(this.c_s.question1).img
          answer = getQ1Info(this.c_s.question1).answer
        }

        return { question, answer, img }
      },
      q2() {
        const { shortQuestion, answer, img } = getQ2Info(this.c_s.question2)
        return { question: shortQuestion, answer, img }
      },
      // note: proveravam u template da li nije microlines i samo tada prikazujem
      q3() {
        const { question, answer, shortQuestion, shortAnswer } = getQ3Info(
          this.c_s.question3
        )
        return { question: shortQuestion, answer: shortAnswer }
      }
    },
    methods: {
      ...mapActions([
        'fetchSalonMessagesUnreadCount',
        'fetchSalon',
        'fetch_c_s',
        'call_c_s',
        'editSalonFeedback',
        'fetchSetups',
        'editMessage'
      ]),
      handleTermsAccept() {
        this.termsAccepted = true
      },
      onMessage() {
        this.messageFocus = true
      },
      async onCallDesktop() {
        this.showPhone = true
        await this.call_c_s({
          salon: this.$route.params.salonId,
          consumer: this.$route.params.consumerId,
          type: CallTypes.C_S,
          from: CallFrom.S
        })
      },
      async onCallMobile() {
        await this.call_c_s({
          salon: this.$route.params.salonId,
          consumer: this.$route.params.consumerId,
          type: CallTypes.C_S,
          from: CallFrom.S
        })
        document.location.href = 'tel:' + this.c_s.smsPhone.formattedNumber
      }
    },
    async created() {
      const salonId = this.$route.params.salonId
      const consumerId = this.$route.params.consumerId

      await Promise.all([
        this.fetchSetups(),
        this.fetch_c_s({ salonId, consumerId }),
        this.fetchSalon(salonId)
      ])

      if (
        this.$store.state.messages.error ||
        this.$store.state.salons.error ||
        this.$store.state.consumers.error
      ) {
        return this.$router.push({ name: 'NotFound' })
      }

      this.salon = this.$store.state.salons.selectedSalon

      // ako je neaktivan odmah mu prikazi opt out, ne damo pristup ovoj strani
      if (!this.salon.active)
        return this.$router.replace({
          name: 'OptOut'
        })

      // set terms
      // -> isti text poslednjeg agreementa
      // +
      // -> status accepted
      if (
        this.$store.getters['lastAgreement']?.termsDate ==
          this.salon?.terms?.date &&
        getTermsStatus(this.salon?.terms) === 'accepted'
      ) {
        this.termsAccepted = true
      }

      this.isMobile = detectMob()

      if (this.termsAccepted && this.salon.active) {
        const foundMessages = this.c_s.allMessages.filter(m => !m.salonRead)
        await Promise.all(
          foundMessages.map(async m => {
            await this.editMessage({ id: m._id, salonRead: true })
          })
        )
        this.fetchSalonMessagesUnreadCount(salonId)
      }
    }
  }
</script>

<style scoped>
  .info-card {
    border-radius: 15px;
  }
  .panel {
    overflow: hidden;
    border-radius: 15px !important;
  }
  .color-white {
    color: #fff;
  }
  .fix-height {
    height: 150px;
  }
  .box--wrapper {
    width: 40%;
    text-align: center;
  }
  .box {
    padding: 20px;
    background-color: #4269a7;
    border-radius: 20px;
  }
  .small-box {
    background-color: #5d91da;
    border-radius: 10px;
  }
  .img {
    height: 100px;
    width: 100px;
    margin: 0 auto;
  }
  .call-now {
    color: blue;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-color {
    color: #4269a7;
  }
</style>
