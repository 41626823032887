<template>
  <v-container v-if="!$store.state.salons.loading">
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="400" class="mx-auto mt-5">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title>Validate Code</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="text-left pl-5"
        >Please provide the security code we just sent to your
        email</v-card-text
      >
      <v-card-text class="pa-5 pt-0">
        <v-form class="d-flex flex-column">
          <v-btn
            text
            small
            class="text-capitalize pr-0 align-self-end"
            color="#19b3cc"
            @click="handleDidntGetCode"
            >Didn't get the code?</v-btn
          >
          <v-text-field
            :rules="[rules.required]"
            v-model="code"
            type="text"
            label="Security code"
            prepend-icon="mdi-lock"
            class="pt-0 mt-0"
          />
          <v-card-actions>
            <v-btn
              block
              color="user_primary"
              class="white--text"
              rounded
              :disabled="disableBtn"
              @click="handleNext"
              :loading="$store.state.auth.loading"
            >
              Next</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
    <SimpleModal
      max-width="400"
      :showDialog="showSimpleModal"
      title="Contact Us"
      :message="contactText"
      @closeSimpleModal="closeSimpleModal"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    data() {
      return {
        code: '',
        rules: {
          required: value => !!value || 'Required.'
        },
        loading: false,
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red',
        showSimpleModal: false,
        contactText:
          'If you have further questions or need immediate assistance, please feel free to contact us at <a href="mailto:hairdreams@bht-usa.com?subject=Salon Finder Assistance Needed">hairdreams@bht-usa.com</a>.'
      }
    },
    computed: {
      disableBtn() {
        return !this.code
      }
    },
    methods: {
      ...mapActions(['validateForgotPassword']),
      closeSimpleModal() {
        this.showSimpleModal = false
      },
      handleDidntGetCode() {
        this.showSimpleModal = true
      },
      async handleNext() {
        await this.validateForgotPassword(this.code)

        if (!this.$store.state.auth.error) {
          this.$router.replace({
            name: 'stylist-change-password',
            query: this.$route.query
          })
        } else {
          this.errorMessage = this.$store.state.auth.error
          this.snackbar = true
        }
      }
    }
  }
</script>
