<template>
  <v-container class="d-flex justify-center" fluid>
    <v-card width="400" class="py-5 elevation-1">
      <v-card-title
        class="d-flex justify-center text-h5 text-sm-h4 breakword font-weight-bold"
        >Hairdreams Partner</v-card-title
      >
      <v-card-subtitle
        class="d-flex justify-center text-h6 text-sm-h5 font-weight-bold breakword"
        >Application Steps</v-card-subtitle
      >
      <v-card-text>
        <v-img
          contain
          src="@/assets/stylist-steps.png"
          lazy-src="@/assets/stylist-steps.png"
        ></v-img>
      </v-card-text>
      <v-card-text class="d-flex justify-center text-body-1 pb-1"
        >Sign up and take a 5 min questionnaire</v-card-text
      >
      <v-card-actions class="d-flex justify-center mx-6">
        <v-btn
          @click="handleSignUp"
          block
          rounded
          depressed
          class="btn-resetcase btn"
          color="primary"
          >Sign up</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    methods: {
      handleSignUp() {
        this.$router.push({ name: 'stylist-signup', query: this.$route.query })
      }
    }
  }
</script>

<style scoped>
  .breakword {
    word-break: break-word;
  }
</style>
