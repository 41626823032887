import { render, staticRenderFns } from "./SalonHome.vue?vue&type=template&id=a8696624&scoped=true"
import script from "./SalonHome.vue?vue&type=script&lang=js"
export * from "./SalonHome.vue?vue&type=script&lang=js"
import style0 from "./SalonHome.vue?vue&type=style&index=0&id=a8696624&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a8696624",
  null
  
)

export default component.exports