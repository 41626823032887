import moment from 'moment'
import { NewSystemOptions } from '../config/enums'
import { ampmTimeForHours } from './filters'

export const getQ1MText = answer => {
  let info
  // 1. string
  if (typeof answer === 'string') {
    if (answer === 'a') info = 'front'
    else if (answer === 'b') info = 'top'
    else if (answer === 'c') info = 'side'
    else if (answer === 'd') info = 'back'
  }
  // 2. array
  else {
    info = answer.map(a => {
      if (a === 'a') return 'front'
      if (a === 'b') return 'top'
      if (a === 'c') return 'side'
      if (a === 'd') return 'back'
    })
  }
  return info.toString().replaceAll(',', ', ')
}

export const getQ1andQ2Text = answer => {
  if (answer === 'a') return 'above shoulder'
  if (answer === 'b') return 'below shoulders'
  if (answer === 'c') return 'mid-back'
}

export const getQ3Text = answer => {
  if (answer === 'a') return 'yes'
  if (answer === 'b') return 'no'
}

export const getWearExtensionsBeforeText = answer => {
  if (answer === 'yes') return 'Conversion'
  else if (answer === 'no') return 'First Time'
  else return ''
}

export const getWantAgainAndLifestyleExtensionsText = answer => {
  if (answer === NewSystemOptions.BONDED_EXTENSIONS) return 'Bonded'
  else if (answer === NewSystemOptions.TAPE) return 'Tape-Ins'
  else if (answer === 'either') return 'Either'
  else if (answer === 'i am not sure') return 'Not sure'
  else return ''
}

export const getTodayString = () => {
  const dtToday = new Date()

  let month = dtToday.getMonth() + 1
  let day = dtToday.getDate()
  let year = dtToday.getFullYear()

  if (month < 10) month = '0' + month.toString()
  if (day < 10) day = '0' + day.toString()

  const date = year + '-' + month + '-' + day
  return date
}

export const arraysEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export const getTitleCase = str => {
  str = str.toLowerCase().split(' ')
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
  }
  return str.join(' ')
}

export const detectMob = () => {
  return window.innerWidth <= 800
}

export const detectXS = () => {
  return window.innerWidth <= 599
}

export const detect399 = () => {
  return window.innerWidth <= 399
}

export const getQ1MInfo = answerValue => {
  let options = [
    { text: 'front', value: 'a' },
    { text: 'top', value: 'b' },
    { text: 'side', value: 'c' },
    { text: 'back', value: 'd' }
  ]

  let answer = ''
  let answerArray = []
  const question = 'Where would you like to see more hair?'
  const shortQuestion = 'Current Hair Loss Areas'

  if (answerValue) {
    // one answer
    if (typeof answerValue === 'string') {
      answer = options.find(o => o.value === answerValue).text
      answerArray.push(options.find(o => o.value === answerValue).text)
    }
    // array of answers
    else {
      answerValue.forEach(a => {
        answer += options.find(o => o.value === a).text + ', '
        answerArray.push(options.find(o => o.value === a).text)
      })
      answer = answer.substring(0, answer.length - 1)
      answer = answer.substring(0, answer.length - 1)
    }
  }

  return {
    question,
    shortQuestion,
    answerArray,
    answer: answer ? answer.toUpperCase() : 'N/A',
    options
  }
}

export const getQ1Info = answerValue => {
  let options = [
    { text: 'Above shoulders', value: 'a', img: 'BEFORE SHORT.png' },
    { text: 'Past shoulders', value: 'b', img: 'BEFORE MID.png' },
    { text: 'Mid back', value: 'c', img: 'BEFORE LONG.png' }
  ]

  let answer = ''
  let img = ''
  if (answerValue) {
    answer = options.find(o => o.value === answerValue)?.text
    img = options.find(o => o.value === answerValue)?.img
  }

  const question = 'About how long is your hair?'
  const shortQuestion = 'Current Length'
  return {
    question,
    img,
    shortQuestion,
    answer: answer ? answer.toUpperCase() : 'N/A',
    options
  }
}

export const getQ2Info = answerValue => {
  let options = [
    { text: 'At shoulders', value: 'a', img: 'AFTER SHORT.png' },
    { text: 'Past shoulders', value: 'b', img: 'AFTER MID.png' },
    { text: 'Mid back or longer', value: 'c', img: 'AFTER LONG.png' }
  ]

  let answer = ''
  let img = ''

  if (answerValue) {
    answer = options.find(o => o.value === answerValue)?.text
    img = options.find(o => o.value === answerValue)?.img
  }

  const question = 'About how long do you want it to be?'
  const shortQuestion = 'Desired Length'
  return {
    question,
    shortQuestion,
    img,
    answer: answer ? answer.toUpperCase() : 'N/A',
    options
  }
}

export const getQ3Info = answerValue => {
  let options = [
    { text: 'Yes', value: 'a' },
    { text: 'No', value: 'b' }
  ]

  let answer = ''
  let shortAnswer
  if (answerValue) {
    answer = options.find(o => o.value === answerValue)?.text
  }
  if (answerValue === 'a') {
    shortAnswer =
      'Wants extra coverage and volume on top with extensions (Secrets Tape-Ins)'
  }

  const question =
    'Would you like extra coverage and volume on top with extensions?'
  const shortQuestion = 'Additional details'

  return {
    question,
    shortQuestion,
    shortAnswer,
    answer: answer ? answer.toUpperCase() : 'N/A',
    options
  }
}

export const getQ4Info = () => {
  const question = 'What are you more interested in?'
  const answers = ['MicroLines Topper', 'Secrets Invisible Extensions']

  return { question, answers }
}

export const getHours = hours => {
  let arr = []

  for (const day in hours) {
    hours[day].forEach(d => {
      if (d.isOpen) {
        arr.push({
          day: getTitleCase(day),
          time: [ampmTimeForHours(d.open) + ' - ' + ampmTimeForHours(d.close)]
        })
      } else {
        arr.push({
          day: getTitleCase(day),
          time: 'Closed'
        })
      }
    })
  }

  let days = []
  arr.forEach(d => {
    if (d.time !== 'Closed') {
      // check if more than one time for one day
      if (!days.find(nd => nd.day === d.day)) {
        days.push(d)
      } else {
        days.forEach(nd => {
          if (nd.day === d.day) {
            nd.time.push(d.time[0])
          }
        })
      }
    } else {
      days.push(d)
    }
  })

  return days
}

export function getTermsStatus(terms) {
  let status
  if (!terms?.accept && !terms?.decline) {
    status = 'no answer'
  } else if (terms?.accept && !terms?.decline) {
    status = 'accepted'
  } else if (!terms?.accept && terms?.decline) {
    status = 'declined'
  } else if (terms?.accept && terms?.decline) {
    if (moment(terms.decline).isAfter(terms.accept, 'second')) {
      status = 'declined'
    } else {
      status = 'accepted'
    }
  }

  return status
}

export function getDatesForDateFilter(startString, endString) {
  const offset = new Date().getTimezoneOffset()

  let start, end, startLocal, endLocal

  if (startString) {
    start = moment(startString).startOf('day').toISOString()
    startLocal = moment.utc(startString).startOf('day').local().toISOString()
  }
  if (endString) {
    end = moment(endString).endOf('day').toISOString()
    endLocal = moment.utc(endString).endOf('day').local().toISOString()
  }

  return {
    start,
    startLocal,
    end,
    endLocal,
    offset
  }
}

// Utility function to get a cookie by name
export function getCookie(name) {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + value + expires + '; path=/'
}

export function getThreeMonthsAgo() {
  return moment().subtract(3, 'months').format('YYYY-MM-DD')
}
