import { StylistsCertifications } from '../../../../../config/enums'

export default {
  [StylistsCertifications.LB_HALF]: {
    title:
      '<span class="text-center"><strong>Laserbeamer</strong> Nano <br/>  Start Kit and Certification</span>',
    textBox: {
      title: 'Start Kit + Online Class',
      subtitle: '#LB101'
    },

    items: {
      1: {
        id: 1,
        img: '1-certifications-lb_half.png',
        popUpImgs: [
          {
            name: '1. NANO, CASE AND ACCESSORIES.jpg',
            text: 'Laserbeamer Nano Machine | Carrying Case | Nano Cleaning/Accessory Kit'
          },
          {
            name: '2. NANO HAIR 3 PACKS.jpg',
            text: '3 Packs 16" 5 Star Nano Hair (75 Nano Strands for half head application on Mannequin Head)'
          },
          {
            name: '3. BLONDE MANNEQUIN HEAD.jpg',
            text: 'Mannequin Head | 1 Bundle Practice Strands (25 Strands)'
          },
          {
            name: '4. COLOR RING.jpg',
            text: 'Laserbeamer/Tapes Color Ring'
          },
          {
            name: '5. WETLINE and brush.jpg',
            text: 'Wet line assortment | Tri-Bristle Extension Brush'
          },
          {
            name: '6. REMOVAL KIT.jpg',
            text: 'Removal Tool | Removal Solution'
          },
          {
            name: '7. BROCHURES AND MANUAL.jpg',
            text: 'Style Book | Brochure | Care Pass | Training Manual'
          },
          {
            name: '8. PHONE MOUNT.jpg',
            text: 'Phone Head Mount (for Zoom class with trainer)'
          }
        ],
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-certifications-lb_half.png',
        popUpText: {
          title1: 'Class Description',
          subtitle1: 'Laserbeamer Nano Automatic Extensions',
          subtitle2: 'Online class',
          htmlText1:
            '<span>This comprehensive course combines an in-depth web module training + a live Zoom hands-on class where you will apply, cut, and style Laserbeamer Nano extensions to one half of a mannequin head. Training additionally covers cutting, styling, removal, maintenance, consultation and marketing. A Live Zoom training date will be scheduled once the online training module is completed.<br/> <br/> The Start Kit can only certify <strong>one</strong> licensed cosmetologist.</span>'
        },
        hasAction: true
      },
      3: {
        id: 3,
        img: '3-certifications-lb_half.png',
        popUpText: {
          title1: 'Upgrade options',
          title2: 'Available upon request',
          subtitle1: 'Laserbeamer Nano Automatic Extensions',
          subtitle2: 'In-salon class',
          htmlText1:
            '<span>One day In-salon training <br/> <br/> Add-on price: $1400 + tax (where applicable) / C$1900 <br/> <i>*Scheduling restrictions apply</i></span>'
        },
        hasAction: true
      }
    }
  },
  [StylistsCertifications.LB_FULL]: {
    title:
      '<span class="text-center"><strong>Laserbeamer</strong> Nano <br/>  Start Kit and Certification</span>',
    textBox: {
      title: 'Start Kit + Online Class',
      subtitle: '#LB102'
    },
    items: {
      1: {
        id: 1,
        img: '1-certifications-lb_full.png',
        popUpImgs: [
          {
            name: '1. NANO, CASE AND ACCESSORIES.jpg',
            text: 'Laserbeamer Nano Machine | Carrying Case | Nano Cleaning/Accessory Kit'
          },
          {
            name: '2. NANO HAIR 5 PACKS.jpg',
            text: '5 Packs 16" 5 Star Nano Hair (125 Nano Strands for half head application on Mannequin Head)'
          },
          {
            name: '3. BLONDE MANNEQUIN HEAD.jpg',
            text: 'Mannequin Head | 1 Bundle Practice Strands (25 Strands)'
          },
          {
            name: '4. COLOR RING.jpg',
            text: 'Laserbeamer/Tapes Color Ring'
          },
          {
            name: '5. WETLINE and brush.jpg',
            text: 'Wet line assortment | Tri-Bristle Extension Brush'
          },
          {
            name: '6. REMOVAL KIT.jpg',
            text: 'Removal Tool | Removal Solution'
          },
          {
            name: '7. BROCHURES AND MANUAL.jpg',
            text: 'Style Book | Brochure | Care Pass | Training Manual'
          },
          {
            name: '8. PHONE MOUNT.jpg',
            text: 'Phone Head Mount (for Zoom class with trainer)'
          }
        ],
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-certifications-lb_full.png',
        popUpText: {
          title1: 'Class Description',
          subtitle1: 'Laserbeamer Nano Automatic Extensions',
          subtitle2: 'Online class',
          htmlText1:
            '<span>This comprehensive course combines an in-depth web module training + a live Zoom hands-on class where you will apply, cut, and style a full head of Laserbeamer Nano extensions to a mannequin head. Training additionally covers removal, maintenance, consultation and marketing. Live Zoom training date will be scheduled once the online training module is completed. <br/> <br/> The Start Kit can only certify <strong>one</strong> licensed cosmetologist.'
        },
        hasAction: true
      },
      3: {
        id: 3,
        img: '3-certifications-lb_full.png',
        popUpText: {
          title1: 'Upgrade options',
          title2: 'Available upon request',
          subtitle1: 'Laserbeamer Nano Automatic Extensions',
          subtitle2: 'In-salon class',
          htmlText1:
            '<span>One day In-salon training <br/> <br/> Add-on price: $1400 + tax (where applicable) / C$1900 <br/> <i>*Scheduling restrictions apply</i></span>'
        },
        hasAction: true
      }
    }
  },
  [StylistsCertifications.ML_REGULAR]: {
    title:
      '<span class="text-center"><strong>MicroLines</strong> <br/>  Start Kit and Certification</span>',
    textBox: {
      title: 'Start Kit + In-person Class',
      subtitle: '#ML101'
    },
    items: {
      1: {
        id: 1,
        img: '1-certifications-ml_reg.png',
        popUpImgs: [
          {
            name: '1. FREESTYLER, STAND,CLIPS, BONDING RING.jpg',
            text: 'FreeStyler Machine | Magnetic Stand | Bonding Rings | Crochet Tool/Clips'
          },
          {
            name: '2. MICROLINES PIECES.jpg',
            text: '1 Full MicroLines Topper Piece (for live model/client application)'
          },
          {
            name: '3. MANNEQUIN HEAD - BLACK.jpg',
            text: 'Mannequin Head | Brunette "Half Hair" Practice Piece'
          },
          {
            name: '4. COLOR RING.jpg',
            text: 'MicroLines Color Ring'
          },
          {
            name: '5. TAPES SAMPLER.jpg',
            text: 'Tapes Sample Kit'
          },
          {
            name: '6. HOMECARE, CAREPASS, HOOK.jpg',
            text: 'Client Home Care Kit | Care Pass | Crochet Tool (for model/client) '
          },
          {
            name: '7. WETLINE, BRUSH.jpg',
            text: 'Wet line assortment | Tri-Bristle Extension Brush'
          },
          {
            name: '8. REMOVAL KIT.jpg',
            text: 'Removal Tool | Removal Solution'
          },
          {
            name: '9. BROCHURE.jpg',
            text: '5 Style Books | | 5 Brochures | Training Manual'
          }
        ],
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-certifications-ml_reg.png',
        popUpText: {
          title1: 'Class Description',
          subtitle1: 'MicroLines Topper',
          subtitle2: 'Regional Class',
          htmlText1:
            '<span>This comprehensive course combines an in-depth web module training + an in person hands-on class where you will apply, cut, and style the MicroLines hair topper integration piece on a live model that you will need to provide. All models must be pre-approved. The kit and course also includes a special "Half Hair" practice piece where you will first learn the basics of application, cutting, removal, and reapplication on a mannequin head. Training additionally covers maintenance, consultation and marketing.<br/> <br/> The Start Kit can certify up to <strong>two</strong> licensed cosmetologists. <br/> <br/> <a href="https://iwanthairdreams.com/pages/training-calendar" target="_blank">See the Training Calendar for dates and locations.</a></span>'
        },
        hasAction: true
      },
      3: {
        id: 3,
        img: '3-certifications-ml_reg.png',
        popUpText: {
          title1: 'Upgrade options',
          title2: 'Available upon request',
          subtitle1: 'MicroLines Topper',
          subtitle2: 'In-salon Class',
          htmlText1:
            '<span>One day In-salon training option available upon request.<br/> <br/>Add-on price: $1400 + tax (where applicable) / C$1900<br/> <i>Scheduling restrictions apply.</i></span>',
          subtitle3: 'MicroLines Topper',
          subtitle4: 'Additional Stylist Certification ',
          htmlText2: `<ul>
              <li>Add up to 2 additional stylists</li> 
              <li>Upgrade MicroLines Kit (certifies up to two additional stylists)</li> 
              <li>Includes 1 full MicroLines Piece</li> 
              <li>1 "Half Hair" practice piece</li> 
              <li>Home Care Kit for Model</li> 
            </ul>
            <p class="mt-2">Add-on price: $1600 + tax (where applicable) / C$2160</p>
            `
        },
        hasAction: true
      }
    }
  },
  [StylistsCertifications.ML_CUTTING]: {
    title:
      '<span class="text-center"><strong>MicroLines</strong> <br/>  2-Part Certification</span>',

    textBox1: {
      title: 'Materials + In-person Class',
      subtitle: '#CT101'
    },

    textBox2: {
      title: 'Start Kit + In-person Class',
      subtitle: '#ML101'
    },

    items: {
      1: {
        id: 1,
        img: '1-certifications-ml_cut.png',
        popUpImgs: [
          {
            name: '1. MICROLINES CUT - START KIT - 5 PACK.jpg',
            html: `
              <span>
                5 Packs of 16" QUIKKIES Tape-in Extensions (15 pairs) | Blonde mannequin head for extension application/cutting/styling <br/> 
                <br/>
                Full Wide 30 MicroLines piece + Brunette Mannequin Head (pre-attached)
              </span>
            `
          }
        ],
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-certifications-ml_cut.png',
        popUpText: {
          title1: 'Class Description',
          subtitle1: 'Topper and Extensions Dry Cutting',
          subtitle2: 'In-person Class* ',
          htmlText1: `
              <span>This required course is a prerequisite for stylists with little to no hair extension experience/education. It focuses on dry cutting and blending, which is an essential skill for the MicroLines topper application. <br/> 
              <br/> 
              You will learn the best and most common types of cutting and blending techniques, working directly on your own MicroLines full topper piece attached to a mannequin head. Also covered will be integrating the clients own hair with the MicroLines hair and creating the final look. (Note: The MicroLines piece will be pre-applied to the mannequin head prior to class.) <br/> 
              <br/> 
              You will apply a full head of QUIKKIES Tape-ins on a mannequin head, which then will be cut and blended to create a full head lengthening application. <br/>
              <br/>
              *Classes are held at the Hairdreams USA Training facility in the Los Angeles area. Travel and accommodations are the responsibility of the attendees, but Hairdreams will provide recommendations once enrolled. <br/>
              <br/>
              <a href="https://iwanthairdreams.com/pages/microlines-cutting-training-calendar" target="_blank">See Cutting Class Training Schedule</a></span>
            `
        },
        hasAction: true
      },
      3: {
        id: 3,
        img: '3-certifications-ml_cut.png',
        popUpImgs: [
          {
            name: '1. FREESTYLER, STAND,CLIPS, BONDING RING.jpg',
            text: 'FreeStyler Machine | Magnetic Stand | Bonding Rings | Crochet Tool/Clips'
          },
          {
            name: '2. MICROLINES PIECES.jpg',
            text: '1 Full MicroLines Topper Piece (for live model/client application)'
          },
          {
            name: '3. MANNEQUIN HEAD - BLACK.jpg',
            text: 'Mannequin Head | Brunette "Half Hair" Practice Piece'
          },
          {
            name: '4. COLOR RING.jpg',
            text: 'MicroLines Color Ring'
          },
          {
            name: '5. TAPES SAMPLER.jpg',
            text: 'Tapes Sample Kit'
          },
          {
            name: '6. HOMECARE, CAREPASS, HOOK.jpg',
            text: 'Client Home Care Kit | Care Pass | Crochet Tool (for model/client) '
          },
          {
            name: '7. WETLINE, BRUSH.jpg',
            text: 'Wet line assortment | Tri-Bristle Extension Brush'
          },
          {
            name: '8. REMOVAL KIT.jpg',
            text: 'Removal Tool | Removal Solution'
          },
          {
            name: '9. BROCHURE.jpg',
            text: '5 Style Books | | 5 Brochures | Training Manual'
          }
        ],
        hasAction: true
      },
      4: {
        id: 4,
        img: '4-certifications-ml_cut.png',
        popUpText: {
          title1: 'Class Description',
          subtitle1: 'MicroLines Topper',
          subtitle2: 'Regional Class',
          htmlText1: `
              <span>
                This comprehensive course combines an in depth web module training + an in person hands-on class where you will apply, cut, and style the MicroLines hair topper integration piece on a live model that you will need to provide. All models must be pre-approved. The kit and course also includes a special "Half Hair" practice piece where you will first learn the basics of application, cutting, removal, and reapplication on a mannequin head. Training additionally covers maintenance, consultation and marketing. <br/> 
                <br/> 
                The Start Kit can certify up to <strong>two</strong> licensed cosmetologists. <br/> <br/> 
                <a href="https://iwanthairdreams.com/pages/training-calendar" target="_blank">See the Training Calendar for dates and locations.</a>
              </span>
          `
        },
        hasAction: true
      },
      5: {
        id: 5,
        img: '5-certifications-ml_cut.png',
        popUpText: {
          title1: 'Upgrade options',
          title2: 'Available upon request',
          subtitle1: 'MicroLines Topper',
          subtitle2: 'In-salon Class',
          htmlText1:
            '<span>One day In-salon training option available upon request.<br/> <br/> Add-on price: $1400 + tax (where applicable) / C$1900<br/> <i>Scheduling restrictions apply.</i></span>',
          subtitle3: 'MicroLines Topper',
          subtitle4: 'Additional Stylist Certification',
          htmlText2: `<ul>
              <li>Add up to 2 additional stylists</li> 
              <li>Upgrade MicroLines Kit (certifies up to two additional stylists)</li> 
              <li>Includes 1 full MicroLines Piece</li> 
              <li>1 "Half Hair" practice piece</li> 
              <li>Home Care Kit for Model</li> 
            </ul>
            <p class="mt-2">Add-on price: $1600 + tax (where applicable) / C$2160</p>
            `
        },
        hasAction: true
      }
    }
  },
  [StylistsCertifications.FS_ONLINE]: {
    title:
      '<span class="text-center"><strong>FreeStyler</strong> <br/>  Start Kit and Certification</span>',

    textBox: {
      title: 'Start Kit + Online Class',
      subtitle: '#FS101'
    },

    items: {
      1: {
        id: 1,
        img: '1-certifications-fs_online.png',
        popUpImgs: [
          {
            name: '1. FREESTYLER, STAND, SELECTOR CLIPS.jpg',
            text: 'FreeStyler Machine | Magnetic Stand | Selector Clips/Accessories'
          },
          {
            name: '2. 3 HAIR BUNDLES, TEST STRANDS.jpg',
            text: '3 Bundles 16" 5 Star Hair (75 Strands in 3 Bond Sizes for half head application on Mannequin Head)'
          },
          {
            name: '3. BLONDE MANNEQUIN HEAD.jpg',
            text: 'Mannequin Head | 1 Bundle Practice Strands (25 Strands)'
          },
          {
            name: '4. COLOR RING.jpg',
            text: 'Strand Color Ring'
          },
          {
            name: '5. WETLINE, BRUSH.jpg',
            text: 'Wet line assortment | Tri-Bristle Extension Brush'
          },
          {
            name: '6. REMOVAL KIT.jpg',
            text: 'Removal Tool | Removal Solution'
          },
          {
            name: '7. BROCHURES.jpg',
            text: 'Style Book | Care Pass | Training Manual'
          },
          {
            name: '8. PHONE MOUNT.jpg',
            text: 'Phone Head Mount (for Zoom class with trainer)'
          }
        ],
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-certifications-fs_online.png',
        popUpText: {
          title1: 'Class Description',
          subtitle1: 'FreeStyler Bonded Extensions',
          subtitle2: 'Online class',
          htmlText1:
            "<span>This comprehensive course combines an in-depth web module training + a live Zoom hands-on class where you will apply, cut, and style Hairdreams bonded extensions to one half of a mannequin head. The course provides the foundation for creating a strand-by-strand fusion application using Hairdreams' proprietary, fast, and efficient selector system. Training additionally covers removal, maintenance, consultation, and marketing. A live Zoom training date will be scheduled once the online training module is completed. <br/> <br/> The Start Kit can only certify <strong>one</strong> licensed cosmetologist. </span>"
        },
        hasAction: true
      },
      3: {
        id: 3,
        img: '3-certifications-fs_online.png',
        popUpText: {
          title1: 'Upgrade options',
          title2: 'Available upon request',
          subtitle1: 'FreeStyler Bonded Extensions',
          subtitle2: 'In-salon class',
          htmlText1:
            '<span>One day In-salon training <br/> <br/> Add-on price: $1400 + tax (where applicable) / C$1900 <br/> <i>*Scheduling restrictions apply</i></span>'
        },
        hasAction: true
      }
    }
  },
  [StylistsCertifications.FS_IN_PERSON]: {
    title:
      '<span class="text-center"><strong>FreeStyler</strong> <br/>  Start Kit and Certification</span>',

    textBox: {
      title: 'Start Kit + In-person Class',
      subtitle: '#FS101-IP'
    },

    items: {
      1: {
        id: 1,
        img: '1-certifications-fs_in-person.png',
        popUpImgs: [
          {
            name: '1. FREESTYLER, STAND, SELECTOR CLIPS.jpg',
            text: 'FreeStyler Machine | Magnetic Stand | Selector Clips/Accessories'
          },
          {
            name: '2. 7 HAIR BUNDLES, TEST STRANDS.jpg',
            text: '7 Bundles 14" 7 Star Hair (175 Strands in 3 Bond Sizes for full head application on live model/client)'
          },
          {
            name: '3. BLONDE MANNEQUIN HEAD.jpg',
            text: 'Mannequin Head | 2 Bundles Practice Strands (50 Strands)'
          },
          {
            name: '4. COLOR RING.jpg',
            text: 'Strand Color Ring'
          },
          {
            name: '5. HOMECARE, CAREPASS.jpg',
            text: 'Client Home Care Kit | Care Pass (for model/client) '
          },
          {
            name: '6. WETLINE, BRUSH.jpg',
            text: 'Wet line assortment | Tri-Bristle Extension Brush'
          },
          {
            name: '7. REMOVAL KIT.jpg',
            text: 'Removal Tool | Removal Solution'
          },
          {
            name: '8. BROCHURES.jpg',
            text: 'Style Book | Training Manual'
          }
        ],
        hasAction: true
      },
      2: {
        id: 2,
        img: '2-certifications-fs_in-person.png',
        popUpText: {
          title1: 'Class Description',
          subtitle1: 'FreeStyler Bonded Extensions',
          subtitle2: 'In-person Class*',
          htmlText1:
            "<span>This comprehensive course combines an in-depth web module training + a 1-day in-salon class. It includes all the equipment and extras needed, PLUS a full set of Hairdreams bonded hair extensions that you will apply on a live model, which you will need to provide. All models must be pre-approved. The live portion of the course is totally hands-on, giving up to <strong>two</strong> stylists the foundation for creating a strand-by-strand fusion lengthening application using Hairdreams' proprietary fast and efficient selector system. <br/> <br/> *The class will be held and scheduled at your salon location. All in-salon costs are included in the Start Kit price.</span>"
        },
        hasAction: true
      },
      3: {
        id: 3,
        img: '3-certifications-fs_in-person.png',
        popUpText: {
          title1: 'Upgrade options',
          title2: 'Available upon request',
          subtitle1: 'FreeStyler Bonded Extensions:',
          subtitle2: 'Additional Stylist Certification',
          htmlText1: `<ul>
              <li>Add up to 2 additional stylists</li> 
              <li>Upgrade FreeStyler Kit (certifies up to two additional stylists)</li> 
              <li>Includes 7 Bundles 14" 7 Star hair</li> 
              <li>1 bundle practice strands</li> 
              <li>Home Care Kit for model</li> 
            </ul>
            <p class="mt-2">Add-on price: $900 + tax (where applicable) / C$1215</p>
            `
        },
        hasAction: true
      }
    }
  }
}
