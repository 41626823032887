import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import BlowOutServices from '../components/custom-icons/BlowOutServices'
import ColorServices from '../components/custom-icons/ColorServices'
import FreeConsultation from '../components/custom-icons/FreeConsultation'
import FreeParking from '../components/custom-icons/FreeParking'
import HairExtensionSpecialist from '../components/custom-icons/HairExtensionSpecialist'
import HairLossSpecialist from '../components/custom-icons/HairLossSpecialist'
import HaircutEducator from '../components/custom-icons/HaircutEducator'
import HaircutServices from '../components/custom-icons/HaircutServices'
import VirtualEstimates from '../components/custom-icons/VirtualEstimates'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      iconfont: 'mdiSvg' || 'mdi' || 'md' || 'fa' || 'fa4' || 'faSvg',
      FreeConsultation: { component: FreeConsultation },
      FreeParking: { component: FreeParking },
      VirtualEstimates: { component: VirtualEstimates },
      ColorServices: { component: ColorServices },
      BlowOutServices: { component: BlowOutServices },
      HaircutServices: { component: HaircutServices },
      HaircutEducator: { component: HaircutEducator },
      HairLossSpecialist: { component: HairLossSpecialist },
      HairExtensionSpecialist: { component: HairExtensionSpecialist }
    }
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        user_primary: '#444444',
        user_cta: '#2b2b2b',
        user_btn: '#cecece',
        // user_primary: '#c2b1a1',
        user_secondary: '#ff5e6c',
        user_accent: colors.shades.black,
        user_error: colors.red.accent3
      }
    }
  }
})
