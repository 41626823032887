<template>
  <v-container>
    <v-card class="elevation-12 mx-auto" max-width="800">
      <v-img
        :contain="detectMob() ? true : false"
        :src="require(`@/assets/${getImg}`)"
        :lazy-src="require(`@/assets/${getImg}`)"
      ></v-img>
      <v-container class="px-0 px-sm-3">
        <!-- 1. LASERBEAMER -->
        <template v-if="isLaserbeamer">
          <v-card-text class="pb-0 body-1">
            ONE-ON-ONE CLASSES for the Laserbeamer Nano - The revolutionary
            automatic hair application system.</v-card-text
          >
          <v-card-text class="py-1 body-1">
            NOW get the fastest automatic fusion strand-by-strand hair extension
            system that’s a real time saver!
          </v-card-text>
          <v-card-text class="py-1 body-1">
            <ul>
              <li>Applies 5 strands at once in 35 sec</li>
              <li>Full head application 45 min</li>
              <li>Wears for 4-6 months</li>
              <li>No removal and re-application needed during wearing time</li>
              <li>Time-saving, hands-on-line training</li>
            </ul>
          </v-card-text>
          <v-card-text class="py-1 body-1">
            The
            <a @click="showLaserbeamerInfoModal = true">Laserbeamer Nano Kit</a>
            includes everything needed to get started.
          </v-card-text>

          <v-card-text class="body-1 px-0">
            <v-card-text class="body-1 py-0 font-weight-bold"
              >PRICE USD: 50% Off Special !!</v-card-text
            >
            <v-card-text class="body-1 py-0">List Price - $3500</v-card-text>
            <v-card-text class="body-1 py-0"
              ><span class="font-weight-bold">Promotional Price - $1750</span> +
              applicable sales tax</v-card-text
            >
            <v-card-text class="body-1 py-0">
              - $500 deposit/payment option available</v-card-text
            >
          </v-card-text>
          <v-card-text class="body-1">
            YOU MUST HAVE A CURRENT VALID COSMETOLOGY LICENSE TO REGISTER FOR
            THE COURSE.
          </v-card-text>
        </template>
        <!-- 2. FREESTYLER -->
        <template v-if="isFreestyler">
          <v-card-text class="py-1 body-1">
            NOW customize your every client's extension needs by hand
            ergonomically.
          </v-card-text>
          <v-card-text class="py-1 body-1">
            <ul>
              <li>Customizable bonds for any client's needs</li>
              <li>Super small attachment points</li>
              <li>
                Safe application with Hairdreams heat fusion crystal polymer
                bond technology
              </li>
              <li>Wears for 4-6 months</li>
              <li>No removal and re-application needed during wearing time</li>
              <li>
                FreeStyler is ergonomic, lightweight, and is auto-heat
                controlled
              </li>
            </ul>
          </v-card-text>
          <!-- <v-card-text class="py-1 body-1">
            The
            <a @click="showFreestylerInfoModal = true">FreeStyler Kit</a>
            includes everything needed to get started which includes our
            easy-to-use Online Training Module plus a 1 Day hands on in-person
            regional training.
            <a
              class="link"
              href="https://iwanthairdreams.com/pages/training-calendar-freestyler"
              >See Training Calendar</a
            >
          </v-card-text> -->
          <v-card-text class="py-1 body-1">
            Stylist who have a previous certification for fusion bond extensions
            from an approved vendor may be authorized to be trained virtually
            online with a FreeStyler Mini Start Kit. All others will require the
            purchase of a Full Start Kit where you will apply a full head of
            FreeStyler hand rolled extensions on an approved model/client that
            you will provide. We do not offer Regional Classes for the
            FreeStyler full training and can only offer an in-salon training for
            an additional fee. Inquire for details.
          </v-card-text>
          <v-card-text class="body-1 px-0">
            <v-card-text class="body-1 py-0 font-weight-bold"
              >PRICE USD:</v-card-text
            >
            <v-card-text class="body-1 py-0"
              >List Price In-Salon - $4900</v-card-text
            >
            <v-card-text class="body-1 py-0"
              ><span class="font-weight-bold">Promotional Price - $3150</span> +
              applicable sales tax</v-card-text
            >
            <v-card-text class="body-1 py-0">
              - $500 deposit/payment option available</v-card-text
            >
            <v-card-text class="body-1 py-0 font-italic"
              >Online class price available upon approval</v-card-text
            >
          </v-card-text>
          <v-card-text class="body-1">
            YOU MUST HAVE A CURRENT VALID COSMETOLOGY LICENSE TO REGISTER FOR
            THE COURSE.
          </v-card-text>
        </template>
        <!-- 3. MICROLINES -->
        <template v-if="isMicrolines">
          <!-- <v-card-text class="py-1 body-1">
            NOW get the most innovation hair integration system that’s a
            solution for all your client’s thinning hair needs!
          </v-card-text>
          <v-card-text class="py-1 body-1">
            <ul>
              <li>
                Virtually invisible micro-fine net is perfectly integrated into
                one's own hair
              </li>
              <li>
                Ideal for thinning hair and hair loss on the top of the head
              </li>
              <li>100 % top quality human hair</li>
              <li>Lightweight, durable and reusable</li>
              <li>
                Permanently attached and allows for a normal, active lifestyle
                which includes showering, swimming, or any other sports
              </li>
              <li>Exclusively applied by highly specialized stylist</li>
              <li>
                Wears up to 1 1/2 years with regular maintenance appointments
                and refresh services
              </li>
            </ul>
          </v-card-text> -->

          <v-card-text class="text-center headline pb-1"
            >MICROLINES START KIT AND CERTIFICATION</v-card-text
          >
          <v-card-text class="text-center headline pt-0"
            >EVERYTHING INCLUDED!</v-card-text
          >
          <v-card-text class="py-1 body-1">
            The MicroLines Start Kit includes everything needed to get started
            which includes our easy-to-use Online Training Module plus a 1 Day
            hands on in-person regional training.
          </v-card-text>
          <v-card-text class="py-1 body-1">
            MicroLines Start Kit:
            <a @click="showMicrolinesInfoModal = true">See what you get</a>
          </v-card-text>

          <!-- <v-card-text class="py-1 body-1">
            For a limited time get the system that increases your income and
            changes lives for 50% off list!
          </v-card-text> -->
          <v-card-text class="body-1 px-0">
            <v-card-text class="body-1 py-0 font-weight-bold"
              >PRICE USD: 50% Off Special !!</v-card-text
            >
            <v-card-text class="body-1 py-0">List Price - $4600</v-card-text>
            <v-card-text class="body-1 py-0"
              ><span class="font-weight-bold">Promotional Price - $2300</span> +
              applicable sales tax</v-card-text
            >
            <v-card-text class="body-1 py-0">
              - $500 deposit/payment option available</v-card-text
            >
          </v-card-text>
          <v-card-text class="py-1 body-1">
            <a
              class="link"
              href="https://iwanthairdreams.com/pages/training-calendar"
              >See Training Calendar</a
            >
          </v-card-text>
          <v-card-text class="body-1 font-weight-bold">
            NOTE: You must have a current valid cosmetology license to get
            certified.
          </v-card-text>
        </template>
        <v-divider class="my-4"></v-divider>
        <v-card-text class="body-1" v-if="isMicrolines">
          Fill in your information below to receive more information on how to
          get started.
        </v-card-text>
        <!-- <v-card-text class="body-1" v-else>
          Fill in your information below to speak with a Hairdreams specialist
          for our extended 50% off price special!
        </v-card-text> -->
        <v-card-text class="px-0 px-sm-4">
          <v-row class="flex-row-reverse">
            <v-col cols="12" sm="6" class="pb-0 pb-sm-3"
              ><v-card-text class="title text-left text-sm-right pt-0 pt-sm-3"
                >*Required fields</v-card-text
              ></v-col
            >
            <v-col cols="12" sm="6" class="pt-0 pt-sm-3"
              ><v-card-text class="headline pt-0 pt-sm-3"
                >YOUR INFORMATION:</v-card-text
              ></v-col
            >
          </v-row>

          <v-card-text
            ><v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  auto-resize
                  hide-details
                  label="First name*"
                  v-model="stylist.firstName"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  hide-details
                  label="Last name*"
                  v-model="stylist.lastName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <VuePhoneNumberInput
                  show-code-on-list
                  v-model="stylist.phone"
                  no-example
                  default-country-code="US"
                  :only-countries="['US', 'CA']"
                  :size="detectMob() ? 'sm' : 'lg'"
                  color="#222"
                  valid-color="#222"
                  error-color="red"
                  height="100"
                  :no-flags="detectMob() ? true : false"
                  required
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  outlined
                  hide-details
                  label="Email*"
                  v-model="stylist.email"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text class="headline">SALON INFORMATION:</v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  required
                  outlined
                  auto-resize
                  hide-details
                  label="Salon name*"
                  v-model="stylist.salon"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  hide-details
                  label="Salon address*"
                  v-model="stylist.salonAddress"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  outlined
                  auto-resize
                  hide-details
                  label="Salon zip code*"
                  v-model="stylist.salonZip"
                >
                </v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  outlined
                  hide-details
                  label="Salon website"
                  v-model="stylist.website"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <v-radio-group v-model="stylist.doHair" hide-details>
                  <p v-if="isMicrolines">
                    Do you currently do hair extensions and/or hair
                    restoration?*
                  </p>
                  <p v-else>Do you currently do hair extensions?*</p>
                  <v-radio label="No" value="No"></v-radio>
                  <v-radio label="Yes" value="Yes"></v-radio></v-radio-group
              ></v-col>
              <v-col cols="12" v-if="stylist.doHair === 'Yes'">
                <v-text-field
                  outlined
                  auto-resize
                  hide-details
                  label="Which brands do you use?*"
                  v-model="stylist.brands"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="headline">REQUEST:</v-card-text>
          <v-card-text class="pt-0">
            <v-row>
              <v-col class="pt-0" cols="12">
                <v-checkbox
                  hide-details
                  v-model="stylist.sendPromotion"
                  label="I want to receive educational and promotional emails"
                ></v-checkbox
              ></v-col>
            </v-row>
            <!-- <v-row class="justify-center py-0 align-start">
              <v-col cols="12" class="py-0 pb-0 d-flex align-start">
                <v-checkbox
                  class="checkbox pt-0 mt-0"
                  v-model="stylist.notice"
                  :ripple="false"
                />

                <v-col class="pa-0" style="line-height: 14px">
                  <v-card-text class="pa-0 pb-1 checkbox body-1 grey-color"
                    >I understand the
                    <a
                      href="https://iwanthairdreams.com/pages/notice-of-collection-of-personal-information"
                      target="_blank"
                    >
                      Notice of Collection of Personal Information</a
                    >
                  </v-card-text>
                </v-col>
              </v-col>
            </v-row> -->

            <v-row>
              <v-col>
                <v-textarea
                  outlined
                  hide-details
                  prepend-inner-icon="mdi-comment"
                  auto-grow
                  v-model="stylist.message"
                  label="Message"
                ></v-textarea></v-col
            ></v-row>
          </v-card-text>
          <v-card-text class="pb-0 pt-1"
            >By clicking SUBMIT you are agreeing to the
            <a
              href="https://iwanthairdreams.com/pages/notice-of-collection-of-personal-information"
              target="_blank"
              >Notice of Collection of Personal Information</a
            ></v-card-text
          >
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer></v-spacer>

          <v-btn
            :width="detectMob() ? '90%' : '50%'"
            class="white--text"
            rounded
            color="user_primary"
            @click="handleSubmit"
            :disabled="!isValid"
            :loading="isSending"
            >Submit
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-container>
    </v-card>

    <LaserbeamerInfoModal
      v-if="showLaserbeamerInfoModal"
      :show="showLaserbeamerInfoModal"
      @close="showLaserbeamerInfoModal = false"
    />
    <FreestylerInfoModal
      v-if="showFreestylerInfoModal"
      :show="showFreestylerInfoModal"
      @close="showFreestylerInfoModal = false"
    />
    <MicrolinesInfoModal
      v-if="showMicrolinesInfoModal"
      :show="showMicrolinesInfoModal"
      @close="showMicrolinesInfoModal = false"
    />
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { SystemOptions } from '@/config/enums'
  import { detectMob } from '@/utils/helpers'
  import LaserbeamerInfoModal from '@/components/user/stylist/LaserbeamerInfoModal.vue'
  import FreestylerInfoModal from '@/components/user/stylist/FreestylerInfoModal.vue'
  import MicrolinesInfoModal from '@/components/user/stylist/MicrolinesInfoModal.vue'

  export default {
    data() {
      return {
        SystemOptions,
        stylist: { sendPromotion: true, system: '' },
        showLaserbeamerInfoModal: false,
        showFreestylerInfoModal: false,
        showMicrolinesInfoModal: false,
        detectMob,
        isSending: false
      }
    },
    computed: {
      system() {
        return this.$route.query.system
      },
      isMicrolines() {
        return this.system === SystemOptions.MICROLINES
      },
      isLaserbeamer() {
        return this.system === SystemOptions.LASERBEAMER
      },
      isFreestyler() {
        return this.system === SystemOptions.FREESTYLER
      },
      getImg() {
        if (this.$route.query.system === SystemOptions.FREESTYLER)
          return this.detectMob()
            ? 'stylist-freestyler.webp'
            : 'stylist-freestyler-desktop.webp'
        else if (this.$route.query.system === SystemOptions.MICROLINES)
          return this.detectMob()
            ? 'stylist-microlines.webp'
            : 'stylist-microlines-desktop.webp'
        else
          return this.detectMob()
            ? 'stylist-laserbeamer.webp'
            : 'stylist-laserbeamer-desktop.webp'
      },
      isValid() {
        return (
          !!this.stylist.firstName &&
          !!this.stylist.lastName &&
          !!this.stylist.phone &&
          !!this.stylist.email &&
          !!this.stylist.salon &&
          !!this.stylist.salonAddress &&
          !!this.stylist.salonZip &&
          // !!this.stylist.notice &&
          (this.stylist.doHair === 'No' ||
            (this.stylist.doHair === 'Yes' && !!this.stylist.brands))
        )
      }
    },
    methods: {
      ...mapActions(['addStylist']),
      async handleSubmit() {
        if (this.isValid) {
          this.isSending = true
          await this.addStylist(this.stylist)
          if (!this.$store.state.stylists.error) {
            this.stylist = { sendPromotion: true }
            this.$router.replace({
              name: 'WF11_Success'
            })
          }
          this.isSending = false
        }
      }
    },
    created() {
      this.stylist.system = this.system
      this.stylist.source = this.$route.query['source'] || ''
    },
    components: {
      LaserbeamerInfoModal,
      FreestylerInfoModal,
      MicrolinesInfoModal
    }
  }
</script>

<style scoped>
  .link {
    text-decoration: underline;
    font-style: italic;
  }
  .grey-color {
    color: #696969;
    line-height: 20px;
  }
</style>
