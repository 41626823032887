<template>
  <v-row>
    <v-col cols="6">
      <!-- input -->
      <v-text-field
        v-if="type === 'text'"
        v-model="item[prop]"
        :label="label"
        hide-details
      ></v-text-field>

      <!-- object -->
      <v-text-field
        disabled
        :label="label"
        v-if="type === 'object'"
        v-model="item[prop][propToShow]"
        hide-details
      ></v-text-field>

      <!-- checkbox -->
      <v-checkbox
        v-if="type === 'checkbox'"
        v-model="item[prop]"
        :label="label"
        hide-details
      ></v-checkbox>

      <!-- select -->
      <v-select
        clearable
        v-if="type === 'select'"
        v-model="item[prop]"
        :items="options"
        :label="label"
        :multiple="multiple"
        small-chips
        :hint="hint"
        persistent-hint
      >
        <template #selection="{ item }">
          <v-chip label color="success" x-small
            ><v-icon left small> mdi-label </v-icon
            >{{
              prop === 'highlights' || prop === 'additionalHighlight'
                ? item.text
                : item
            }}</v-chip
          >
        </template>
      </v-select>
    </v-col>
    <v-divider vertical></v-divider>
    <template v-if="showTemp">
      <!-- input -->
      <v-col cols="5">
        <v-text-field
          v-if="type === 'text'"
          class="bcc-yellow"
          v-model="item[prop + 'Temp']"
          hide-details
        ></v-text-field>

        <!-- object -->
        <v-text-field
          :label="label"
          disabled
          v-if="type === 'object'"
          class="bcc-yellow"
          v-model="item[prop + 'Temp'][propToShow]"
          hide-details
        ></v-text-field>

        <!-- checkbox -->
        <v-checkbox
          v-if="type === 'checkbox'"
          class="bcc-yellow"
          v-model="item[prop + 'Temp']"
          :label="label"
          hide-details
        ></v-checkbox>

        <!-- select -->
        <v-select
          v-if="type === 'select'"
          v-model="item[prop + 'Temp']"
          :items="options"
          :label="label"
          multiple
          small-chips
          disabled
        >
          <template #selection="{ item }">
            <v-chip label color="success" x-small
              ><v-icon left small> mdi-label </v-icon
              >{{
                prop === 'highlights' || prop === 'additionalHighlight'
                  ? item.text
                  : item
              }}</v-chip
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="1" class="d-flex flex-column justify-center">
        <v-btn
          rounded
          class="mb-1"
          small
          color="success"
          @click="$emit('confirm')"
          ><v-icon small>mdi-check</v-icon></v-btn
        >
        <v-btn rounded small color="error" @click="$emit('deny')"
          ><v-icon small>mdi-close</v-icon></v-btn
        >
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    props: {
      type: {
        type: String,
        default: 'text'
      },
      options: {
        type: Array
      },
      hint: {
        type: String
      },
      item: {
        type: Object,
        required: true
      },
      prop: {
        type: String,
        required: true
      },
      propToShow: {
        type: String
      },
      label: {
        type: String,
        default: 'Item'
      },
      multiple: {
        type: Boolean,
        default: false
      },
      showTemp: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bcc-yellow {
    background-color: yellow;
  }
</style>
