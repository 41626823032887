<template>
  <v-container fluid class="elevation-0 pa-5">
    <div class="d-flex justify-center" v-if="isLoading">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <v-snackbar
        centered
        top
        v-model="snackbar"
        :color="snackbarColor"
        :timeout="snackbarTimeout"
        >{{ notification }}</v-snackbar
      >
      <v-row>
        <v-col cols="12" md="6" lg="4" class="mx-auto"
          ><v-card
            ><v-card-title class="d-flex justify-center"
              >Edit Salon Home</v-card-title
            >

            <!-- url type -->
            <v-card-text class="pb-0">
              <v-select
                label="Choose URL type"
                outlined
                :items="[
                  { text: 'Picture URL', value: 'picture-url' },
                  { text: 'Video URL', value: 'video-url' }
                ]"
                v-model="item.urlType"
              />

              <!-- url -->
              <v-text-field v-model="item.url" outlined label="Enter URL" />

              <!-- title -->
              <v-text-field v-model="item.title" outlined label="Enter Title" />

              <!-- paragraph -->
              <v-card-text class="pa-0 pb-1">Enter Paragraph</v-card-text>
              <VueEditor class="mb-5" v-model="item.paragraph" />
              <!-- <v-textarea
                v-model="item.paragraph"
                outlined
                label="Enter Paragraph"
              /> -->

              <!-- button text -->
              <v-text-field
                v-model="item.buttonText"
                outlined
                label="Enter Button text"
              />

              <!-- button link -->
              <v-text-field
                v-model="item.buttonLink"
                outlined
                label="Enter Button link"
              />

              <!-- hide promo -->
              <v-switch v-model="item.hidePromo" label="Hide Promo"></v-switch>
            </v-card-text>

            <v-card-actions class="d-flex justify-end px-4 pb-4">
              <v-btn color="success" @click="handleSave" :loading="isSending"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
  import { decode } from 'html-entities'
  import { VueEditor } from 'vue2-editor'
  import { mapActions } from 'vuex'

  export default {
    components: {
      VueEditor
    },
    data() {
      return {
        item: {},
        isLoading: true,
        isSending: false,
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'blue',
        notification: ''
      }
    },
    computed: {
      salonHomePageSettings() {
        return this.$store.getters.salonHomePageSettings
      }
    },
    methods: {
      ...mapActions(['fetchSettings', 'editSettings']),
      async updateData() {
        this.isLoading = true
        await this.fetchSettings()

        this.item.urlType = this.salonHomePageSettings?.settings?.urlType || ''
        this.item.url = this.salonHomePageSettings?.settings?.url || ''
        this.item.title = this.salonHomePageSettings?.settings?.title || ''
        this.item.paragraph = this.salonHomePageSettings?.settings?.paragraph
          ? decode(this.salonHomePageSettings?.settings?.paragraph)
          : ''
        this.item.buttonText =
          this.salonHomePageSettings?.settings?.buttonText || ''
        this.item.buttonLink =
          this.salonHomePageSettings?.settings?.buttonLink || ''
        this.item.hidePromo =
          this.salonHomePageSettings?.settings?.hidePromo || false

        this.isLoading = false
      },
      async handleSave() {
        this.isSending = true
        if (this.item.hidePromo === false) {
          this.item.ids = []
        }
        await this.editSettings({
          id: this.salonHomePageSettings?._id,
          settings: this.item
        })
        this.isSending = false
        if (!this.$store.state.settings.error) {
          this.notification = 'Salon home page info saved.'
          this.snackbarColor = 'green'
          this.snackbar = true
        }
      }
    },

    async created() {
      await this.updateData()
    }
  }
</script>
