<template>
  <v-container
    class="px-0 px-sm-3 pt-0"
    v-if="!$store.state.consumers.loading && consumer"
  >
    <v-card class="elevation-0 mx-auto" max-width="680">
      <!-- <v-toolbar dark color="user_primary">
        <v-toolbar-title class="mx-auto text-sm-h5"
          >WF2: Great news {{ consumer.firstName }}</v-toolbar-title
        >
      </v-toolbar> -->
      <!-- <Notification
        :count="consumer.salonsNear.length"
        :system="consumer.system"
        class="pb-0"
      /> -->
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :value="'tab-' + item.salon._id"
            v-for="(item, index) in consumer.salonsNear"
            :key="item.salon._id"
          >
            <SalonItem
              :salon="item.salon"
              :system="consumer.system"
              :distance="item.distance"
              :bestMatch="index === 0"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-tabs
        v-model="tab"
        background-color="user_primary"
        centered
        icons-and-text
        class="salon-tabs mb-3"
        v-if="
          this.consumer.salonsNear.length > 1 ||
          this.consumer.chosenDistance < 100
        "
        dark
        color="white"
      >
        <v-tabs-slider></v-tabs-slider>

        <template v-if="consumer.salonsNear.length > 1">
          <v-tab
            v-for="(item, index) in consumer.salonsNear"
            :key="item._id"
            :href="'#tab-' + item.salon._id"
            @click="changeSalon(item)"
            width="50"
          >
            Salon {{ index + 1 }}
            <v-icon :small="detectXS() ? true : false"
              >mdi-hair-dryer-outline
            </v-icon>
          </v-tab>
        </template>
        <v-btn
          rounded
          v-if="consumer.salonsNear.length < 3 && consumer.chosenDistance < 100"
          :x-small="detectXS() ? true : false"
          class="align-self-center ml-auto"
          @click="showFindMoreSalons = true"
          >More Salons</v-btn
        >
      </v-tabs>
    </v-card>
    <!-- note: FIND MORE SALONS POP UP -->
    <v-dialog
      max-width="500"
      v-model="showFindMoreSalons"
      @click:outside="showFindMoreSalons = false"
    >
      <FindMoreSalons
        @close="showFindMoreSalons = false"
        :consumer="consumer"
        @found="showFindMoreSalons = false"
        @not-found="showFindMoreSalons = false"
        toolbar
        toolbarTitle="Find More Salons"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  // import Notification from '@/components/user/show-salons/Notification'
  import FindMoreSalons from '@/components/FindMoreSalons'
  import SalonItem from '@/views/user/WF2/show-salons/SalonItem'

  import { detectXS } from '@/utils/helpers'
  import { mapActions } from 'vuex'

  export default {
    name: 'GreatNews',

    components: {
      // Notification,
      SalonItem,
      FindMoreSalons
    },
    data() {
      return {
        detectXS,
        tab: null,
        showFindMoreSalons: false
      }
    },
    computed: {
      consumer() {
        return this.$store.state.consumers.consumer
      },
      selectedSystem() {
        return this.$store.state.consumers?.consumer?.system
      }
    },
    methods: {
      ...mapActions(['fetchConsumer']),
      changeSalon(item) {
        if (
          this.$route.params.consumerId !== this.consumer._id ||
          this.$route.params.salonId !== item.salon._id
        ) {
          this.$router.replace({
            name: 'GreatNews',
            params: { consumerId: this.consumer._id, salonId: item.salon._id }
          })
        }
      }
    },
    async created() {
      await this.fetchConsumer(this.$route.params.consumerId)
      if (this.$store.state.consumers.error)
        this.$router.push({ name: 'NotFound' })

      this.tab = 'tab-' + this.$route.params.salonId
    }
  }
</script>

<style lang="scss" scoped>
  .fixed-height {
    height: 42px;
    display: flex;
    align-items: center;
  }

  .salon-tabs {
    bottom: 0;
    max-width: 100%;
    position: sticky;
    z-index: 100;
  }
</style>
