<template>
  <v-img
    contain
    src="@/assets/Hairdreams Educator.svg"
    viewBox="0 0 64 64"
    width="34"
    height="34"
  />
</template>
<script>
  export default {
    name: 'HaircutEducator'
  }
</script>
