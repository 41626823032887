var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-card-title',{staticClass:"breakword title pb-0"},[_vm._v("What extensions would work better with your lifestyle?")]),_c('v-card-text',{staticClass:"py-0"},[_c('v-row',{staticClass:"justify-center"},[_c('v-card-text',{staticClass:"text-center pb-0"},[_vm._v("Choose one")]),_c('v-col',{staticClass:"d-flex justify-center flex-column align-center",attrs:{"cols":"12"}},[_c('div',{class:_vm.answer === _vm.NewSystemOptions.BONDED_EXTENSIONS
              ? 'custom-btn-selected'
              : 'custom-btn',on:{"click":function($event){return _vm.handleSelect(_vm.NewSystemOptions.BONDED_EXTENSIONS)}}},[_c('span',{class:_vm.answer === _vm.NewSystemOptions.BONDED_EXTENSIONS
                ? 'custom-btn-selected__main-txt'
                : 'custom-btn__main-txt'},[_vm._v("Permanent")]),_c('span',{class:_vm.answer === _vm.NewSystemOptions.BONDED_EXTENSIONS
                ? 'custom-btn-selected__caption-txt'
                : 'custom-btn__caption-txt'},[_vm._v("aka Nanos, Bonded, Keratin, NMU, Lazy Girl")])]),_c('v-card-text',{staticClass:"pl-1 my-0 pt-2",attrs:{"caption":""}},[_vm._v("One single wear lasting 4-6 months "),_c('br'),_vm._v(" Low in-salon maintenance"),_c('br'),_vm._v(" Can't be reused")])],1),_c('v-col',{staticClass:"pt-0 d-flex justify-center flex-column align-center pb-0",attrs:{"cols":"12"}},[_c('div',{class:_vm.answer === _vm.NewSystemOptions.TAPE
              ? 'custom-btn-selected'
              : 'custom-btn',on:{"click":function($event){return _vm.handleSelect(_vm.NewSystemOptions.TAPE)}}},[_c('span',{class:_vm.answer === _vm.NewSystemOptions.TAPE
                ? 'custom-btn-selected__main-txt'
                : 'custom-btn__main-txt'},[_vm._v("Semi-permanent")]),_c('span',{class:_vm.answer === _vm.NewSystemOptions.TAPE
                ? 'custom-btn-selected__caption-txt'
                : 'custom-btn__caption-txt'},[_vm._v("aka Tape-ins, Quikkies, Secrets, Baby Secrets")])]),_c('v-card-text',{staticClass:"pl-1 my-0 pt-2",attrs:{"caption":""}},[_vm._v("Can be reused/worn 3 times "),_c('br'),_vm._v(" Each reuse/wear last 6-8 weeks"),_c('br'),_vm._v(" More in-salon maintenance")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }