<template>
  <div>
    <div class="d-flex justify-center align-center" v-if="isLoading">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple" />
    </div>
    <template v-else>
      <GChart :type="type" :data="chartData" :options="options"
    /></template>
  </div>
</template>

<script>
  import { GChart } from 'vue-google-charts/legacy'
  import { mapActions } from 'vuex'
  export default {
    components: {
      GChart
    },
    data() {
      return {
        isLoading: true,
        type: 'PieChart',
        options: {
          colors: ['#69ce4a', 'orange', '#808080'],
          title: '',
          height: 600,
          animation: { startup: true, duration: 500 },
          legend: { position: 'bottom' }
        }
      }
    },
    computed: {
      salonsSmsPhones() {
        return this.$store.state.analytics.salonsSmsPhones
      },
      chartData() {
        return [
          ['Status', 'Count'],
          ['Yes', this.salonsSmsPhones?.['Yes']],
          [
            "Added number, but didn't confirm",
            this.salonsSmsPhones?.["Added number, but didn't confirm"]
          ],
          ["Didn't add number", this.salonsSmsPhones?.["Didn't add number"]]
        ]
      },
      signedUpSalonsCount() {
        const total = Object.values(this.salonsSmsPhones).reduce(
          (a, item) => a + item,
          0
        )
        return ((this.salonsSmsPhones['Yes'] / total) * 100).toFixed(1)
      }
    },
    methods: {
      ...mapActions(['fetchSalonsSmsPhones'])
    },
    async created() {
      await this.fetchSalonsSmsPhones()
      this.options.title = `Salon SMS sign-up rate: ${this.signedUpSalonsCount}%`
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped></style>
