<template>
  <v-container class="pt-0">
    <v-card-title class="breakword title"
      >Did you wear hair extensions before?</v-card-title
    >
    <v-card-text class="pt-0">
      <v-row class="justify-center">
        <v-col cols="12" class="d-flex justify-center"
          ><v-btn
            class="text-none"
            depressed
            width="100%"
            :color="answer === 'yes' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'yes'"
            @click="handleSelect('yes')"
            >Yes</v-btn
          ></v-col
        >
        <v-col cols="12" class="d-flex justify-center pt-0"
          ><v-btn
            class="text-none"
            depressed
            width="100%"
            :color="answer === 'no' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'no'"
            @click="handleSelect('no')"
            >No</v-btn
          ></v-col
        >
      </v-row></v-card-text
    >
  </v-container>
</template>

<script>
  import { SystemOptions } from '@/config/enums'

  export default {
    data() {
      return {
        screen: 'wearExtensionsBefore',
        question: 'wearExtensionsBefore',
        answer: ''
      }
    },
    computed: {
      nextScreen() {
        if (this.answer === 'no') return 'lifestyleExtensions'
        else return 'consumerPickOther'
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false, systemInfo = '') {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          systemInfo,
          goNext
        })
      },
      handleSelect(answer) {
        this.answer = answer
        const systemInfo =
          this.answer === 'no' ? SystemOptions.TAPE : 'not-sure'
        this.emitSelect(true, systemInfo)
      }
    },
    created() {
      this.answer = this.$route.query[this.question] || ''
      this.emitSelect(
        false,
        this.answer === 'no' ? SystemOptions.TAPE : 'not-sure'
      )
    }
  }
</script>

<style lang="scss" scoped></style>
