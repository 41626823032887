var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-card-text',{staticClass:"breakword title pb-0"},[_vm._v("If you've worn any of the types below please select the type you would like to wear again")]),_c('v-card-text',{staticClass:"pt-0"},[_c('v-row',{staticClass:"justify-center"},[_c('v-card-text',{staticClass:"text-center pb-0"},[_vm._v("Choose one")]),_c('v-col',{staticClass:"d-flex justify-center flex-column align-center",attrs:{"cols":"6"}},[_c('div',{class:_vm.answer === _vm.NewSystemOptions.BONDED_EXTENSIONS
              ? 'custom-btn-selected'
              : 'custom-btn',on:{"click":function($event){return _vm.handleSelect(_vm.NewSystemOptions.BONDED_EXTENSIONS)}}},[_c('span',{class:_vm.answer === _vm.NewSystemOptions.BONDED_EXTENSIONS
                ? 'custom-btn-selected__main-txt'
                : 'custom-btn__main-txt'},[_vm._v("Bonded")]),_c('span',{class:_vm.answer === _vm.NewSystemOptions.BONDED_EXTENSIONS
                ? 'custom-btn-selected__caption-txt'
                : 'custom-btn__caption-txt'},[_vm._v("aka individual, fusion keratin")])])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('div',{class:_vm.answer === _vm.NewSystemOptions.TAPE
              ? 'custom-btn-selected'
              : 'custom-btn',on:{"click":function($event){return _vm.handleSelect(_vm.NewSystemOptions.TAPE)}}},[_c('span',{class:_vm.answer === _vm.NewSystemOptions.TAPE
                ? 'custom-btn-selected__main-txt'
                : 'custom-btn__main-txt'},[_vm._v("Tape-Ins")])])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('div',{class:_vm.answer === 'either' ? 'custom-btn-selected' : 'custom-btn',on:{"click":function($event){return _vm.handleSelect('either')}}},[_c('span',{class:_vm.answer === 'either'
                ? 'custom-btn-selected__main-txt'
                : 'custom-btn__main-txt'},[_vm._v("Either")])])]),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('div',{class:_vm.answer === 'i am not sure' ? 'custom-btn-selected' : 'custom-btn',on:{"click":function($event){return _vm.handleSelect('i am not sure')}}},[_c('span',{class:_vm.answer === 'i am not sure'
                ? 'custom-btn-selected__main-txt'
                : 'custom-btn__main-txt'},[_vm._v("I am not sure")])])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }