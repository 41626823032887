<template>
  <v-dialog v-model="show" max-width="400" @click:outside="close">
    <v-card class="pb-5">
      <v-toolbar dark :color="color">
        <v-toolbar-title class="text-uppercase"
          >Confirm Consultation</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <div class="text-subtitle-1 my-3">
          Your consultation at {{ salonName }} is on {{ date | localeDate }} at
          {{ time }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn rounded @click="handleBack"> Go back </v-btn>
        <v-btn
          rounded
          :color="color"
          dark
          @click="handleConfirm"
          :loading="isSending"
        >
          Confirm & get code
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import moment from 'moment'
  import { mapActions } from 'vuex'
  import { CONSULTATION_ADDED_BY } from '../../config/enums'
  export default {
    props: {
      color: { type: String, default: 'user_primary' },
      addedBy: {
        type: String,
        required: false
      },
      show: {
        type: Boolean,
        required: false
      },
      salonId: {
        type: String,
        required: true
      },
      salonName: {
        type: String,
        required: true
      },
      consumerId: {
        type: String,
        required: true
      },
      date: {
        type: String,
        required: true
      },
      time: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isSending: false,
        moment
      }
    },
    methods: {
      ...mapActions(['revealCode', 'createConsultationByAdmin']),
      close(isSuccess = false) {
        this.$emit('close', isSuccess)
      },
      handleBack() {
        this.close()
      },
      async handleConfirm() {
        this.isSending = true

        const minutes_time =
          Number(this.time.split(':')[0]) * 60 + Number(this.time.split(':')[1])

        const dateAndTime = moment(this.date).add(minutes_time, 'minutes')

        // 1. pravi consumer
        if (this.addedBy !== CONSULTATION_ADDED_BY.ADMIN) {
          await this.revealCode({
            salonId: this.salonId,
            consumerId: this.consumerId,
            data: {
              addedBy: this.addedBy,
              date: this.date,
              time: this.time,
              dateAndTime: dateAndTime.format('YYYY-MM-DD HH:mm')
            }
          })
        }

        // 2. pravi admin
        else {
          await this.createConsultationByAdmin({
            salonId: this.salonId,
            consumerId: this.consumerId,
            addedBy: this.addedBy,
            date: this.date,
            time: this.time,
            dateAndTime: dateAndTime.format('YYYY-MM-DD HH:mm')
          })
        }

        if (!this.$store.state.consumers.error) {
          this.close(true)
        }
        this.isSending = false
      }
    }
  }
</script>
