<template>
  <v-container class="pt-0">
    <v-card class="elevation-12 mx-auto pb-5" max-width="700">
      <v-toolbar dark color="user_primary">
        <v-toolbar-title class="text-sm-h5 mx-auto"
          >CCPA Compliance</v-toolbar-title
        >
      </v-toolbar>
      <v-card-text class="pa-5">
        <!-- account info -->
        <v-row>
          <v-card-title class="text-uppercase">Data Rectification</v-card-title>
          <v-card-text
            >You can use the link below to update your account data if it is not
            accurate.
          </v-card-text>
          <v-btn
            plain
            @click="isOpenAccountInfoModal = true"
            class="text-capitalize text-decoration-underline"
            >Edit your account information
          </v-btn>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <!-- Data Portability CCPA -->
        <v-row>
          <v-card-title class="text-uppercase">Data Portability</v-card-title>
          <v-card-text
            >You can use the links below to request all the data we store and
            use for a better experience in our store.
          </v-card-text>
          <v-col cols="12" class="pa-0">
            <v-btn
              plain
              @click="handleOpenModal('data_portability_ccpa')"
              class="text-capitalize text-decoration-underline"
              >CCPA requests
            </v-btn></v-col
          >
          <v-col cols="12" class="pa-0">
            <v-btn
              plain
              @click="handleOpenModal('data_portability_personal')"
              class="text-capitalize text-decoration-underline"
              >Personal Information
            </v-btn></v-col
          >
        </v-row>
        <v-divider class="my-5"></v-divider>
        <!-- personal data -->
        <v-row>
          <v-card-title class="text-uppercase"
            >Access to Personal Data</v-card-title
          >
          <v-card-text
            >You can use the link below to request a report which will contain
            all personal information that we store for you.
          </v-card-text>
          <v-btn
            plain
            @click="handleOpenModal('personal_data')"
            class="text-capitalize text-decoration-underline"
            >Request a report
          </v-btn>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <!-- do not sell -->
        <v-row>
          <v-card-title class="text-uppercase"
            >Do not Sell My Personal Information</v-card-title
          >
          <v-card-text
            >You can submit a request to let us know that you do not agree for
            your personal information to be collected or sold.
          </v-card-text>
          <v-btn
            plain
            @click="handleOpenModal('do_not_sell')"
            class="text-capitalize text-decoration-underline"
            >Do not sell my personal information
          </v-btn>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <!-- forgotten -->
        <v-row>
          <v-card-title class="text-uppercase"
            >Right to be Forgotten</v-card-title
          >
          <v-card-text
            >Use this option if you want to remove your personal and other data
            from our store. Keep in mind that this process will delete your
            account, so you will no longer be able to access or use it anymore.
          </v-card-text>
          <v-btn
            plain
            @click="handleOpenModal('forgotten_right')"
            class="text-capitalize text-decoration-underline"
            >Request personal data deletion
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- modals -->
    <CCPA_AccountInfo
      v-if="isOpenAccountInfoModal"
      :show="isOpenAccountInfoModal"
      @close="
        value => handlePopUpAccountInfoClose(value, 'isOpenAccountInfoModal')
      "
    />
    <CCPA_Other
      :title="currentTitle"
      :requestType="requestType"
      v-if="modals[currentModal]"
      :show="modals[currentModal]"
      @close="value => handlePopUpOtherClose(value, currentModal)"
    />
    <CCPA_Success
      v-if="showSuccess"
      :show="showSuccess"
      @close="showSuccess = false"
    />
  </v-container>
</template>

<script>
  import CCPA_AccountInfo from '@/components/user/CCPA_AccountInfo.vue'
  import CCPA_Other from '@/components/user/CCPA_Other.vue'
  import CCPA_Success from '@/components/user/CCPA_Success.vue'
  import { CCPA_RequsetTypes } from '@/config/enums'

  export default {
    data() {
      return {
        currentModal: '',
        currentTitle: '',
        isOpenAccountInfoModal: false,
        modals: {
          account_info: false,
          data_portability_ccpa: false,
          data_portability_personal: false,
          personal_data: false,
          do_not_sell: false,
          forgotten_right: false
        },
        showSuccess: false
      }
    },
    methods: {
      handlePopUpAccountInfoClose(value) {
        this.isOpenAccountInfoModal = false
        if (value === 'submit') this.showSuccess = true
      },
      handlePopUpOtherClose(value, prop) {
        this.modals[prop] = false
        if (value === 'submit') this.showSuccess = true
      },
      handleOpenModal(prop) {
        this.currentModal = prop
        this.requestType = CCPA_RequsetTypes[prop]
        this.currentTitle = CCPA_RequsetTypes[prop]
        this.modals[prop] = true
      }
    },
    components: {
      CCPA_AccountInfo,
      CCPA_Other,
      CCPA_Success
    }
  }
</script>
