<template>
  <v-container class="pt-0"
    ><v-row class="justify-center">
      <v-row>
        <v-col cols="12" class="pl-0 pb-0">
          <v-card-title class="pb-0">Select a type</v-card-title>
        </v-col>
        <v-col
          class="d-flex justify-center flex-column align-center system-img pb-0"
          cols="6"
          @click="handleSelect(SystemOptions.LASERBEAMER)"
        >
          <v-img src="@/assets/NANO.jpg" lazy-src="@/assets/NANO.jpg" />
          <v-btn
            depressed
            :color="
              answer === SystemOptions.LASERBEAMER ? 'user_cta' : 'user_btn'
            "
            :dark="answer === SystemOptions.LASERBEAMER"
            class="text-none mt-1"
            width="100%"
            >Laserbeamer Nano</v-btn
          >
          <v-card-text class="text-center pt-0 px-0 btn-caption" caption
            >Automatic bonded extensions</v-card-text
          > </v-col
        ><v-col
          class="d-flex justify-center flex-column align-center system-img pb-0"
          cols="6"
          @click="handleSelect(SystemOptions.FREESTYLER)"
        >
          <v-img
            lazy-src="@/assets/FREESTYLER.jpg"
            src="@/assets/FREESTYLER.jpg"
          />
          <v-btn
            depressed
            :color="
              answer === SystemOptions.FREESTYLER ? 'user_cta' : 'user_btn'
            "
            :dark="answer === SystemOptions.FREESTYLER"
            class="text-none mt-1"
            width="100%"
            >FreeStyler</v-btn
          >
          <v-card-text class="text-center pt-0 px-0 btn-caption" caption
            >Custom bonded extensions</v-card-text
          >
        </v-col>
        <v-col
          class="d-flex justify-center flex-column align-center system-img pt-0"
          cols="6"
          @click="handleSelect(SystemOptions.TAPE)"
        >
          <v-img lazy-src="@/assets/TAPES.jpg" src="@/assets/TAPES.jpg" />

          <v-btn
            depressed
            :color="answer === SystemOptions.TAPE ? 'user_cta' : 'user_btn'"
            :dark="answer === SystemOptions.TAPE"
            class="text-none mt-1"
            width="100%"
            >Tape-Ins</v-btn
          >
          <v-card-text class="text-center pt-0 px-0 btn-caption" caption
            >Featuring BABY SECRETS</v-card-text
          > </v-col
        ><v-col
          class="d-flex justify-center flex-column align-center system-img pt-0"
          cols="6"
          @click="handleSelect('not sure')"
        >
          <v-img lazy-src="@/assets/NOT SURE.jpg" src="@/assets/NOT SURE.jpg" />
          <v-btn
            depressed
            :color="answer === 'not-sure' ? 'user_cta' : 'user_btn'"
            :dark="answer === 'not-sure'"
            class="text-none mt-1"
            width="100%"
            >Not sure</v-btn
          ><v-card-text class="text-center pt-0 px-0 btn-caption" caption
            >Help me find what's best for me</v-card-text
          ></v-col
        >
      </v-row>
    </v-row>
  </v-container>
</template>

<script>
  import { SystemOptions } from '@/config/enums'
  export default {
    data() {
      return {
        SystemOptions,
        screen: 'consumerPick',
        answer: '',
        question: 'systemInfo',
        systemPickedBy: ''
      }
    },
    computed: {
      nextScreen() {
        if (this.systemPickedBy === 'sf') return 'hairLongIs'
        else return 'systemInfo'
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false, systemPickedBy = '') {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          systemPickedBy,
          goNext
        })
      },
      handleSelect(answer) {
        this.answer = answer
        if (this.answer === 'not sure') this.systemPickedBy = 'sf'
        else this.systemPickedBy = 'c'

        this.emitSelect(true, this.systemPickedBy)
      }
    },
    created() {
      this.answer = this.$route.query[this.question] || ''
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped>
  .system-img {
    cursor: pointer;
  }
  .btn-caption {
    color: #7d7d7d;
    font-size: 10px;
  }
</style>
