<template>
  <div>
    <input
      class="time-input"
      :class="[anyError ? 'has-error' : '']"
      type="text"
      :list="datalistID"
      :placeholder="defaultText"
      @change="inputEventHandler"
      :value="formattedTime"
    />
    <datalist :id="datalistID">
      <option v-if="isFirstRow(index)">{{ localization.t24hours }}</option>
      <option v-for="time in filteredTimes" :key="time">
        {{ time | formatTime(hourFormat24) }}
      </option>
      <option v-if="showMidnightOption">{{ localization.midnight }}</option>
    </datalist>
    <input :name="optionName" type="hidden" :value="selected" />
  </div>
</template>

<script>
  import { helperMixin } from '../mixins/helperMixin'
  import { formFieldMixin } from '../mixins/formFieldMixin'
  export default {
    name: 'BusinessHoursDatalist',
    mixins: [helperMixin, formFieldMixin],
    props: {
      anyError: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      formattedTime: function () {
        return this.frontendInputFormat(this.selected)
      },
      datalistID: function () {
        return (
          this.name.replace('_', '-') +
          '-' +
          this.day +
          '-' +
          this.index +
          '-' +
          this.whichTime
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  .time-input.has-error {
    border: solid #e3342f 1px;
  }

  .flex-table {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: 0.75em 0;
    height: 45px;
    width: 100%;
  }

  .flex-row {
    width: 20%;
  }
  .flex-row input,
  .flex-row select {
    margin: 1px;
    padding: 3px 5px;
    width: 110px;
    height: 28px;
    font-size: 14px;
    line-height: 28px;
    vertical-align: middle;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
  }
  .flex-row input:focus,
  .flex-row select:focus {
    border: none;
  }

  @media only screen and (max-width: 600px) {
    .flex-row.day {
      width: 100%;

      text-align: center;
    }
    .flex-row.day div {
      margin-bottom: 10px;
    }
    .flex-table {
      display: flex;
      flex-flow: row wrap;
      height: 100%;
    }
    .flex-row.add {
      width: 100%;
    }

    .flex-row.hours,
    .flex-row.toggle {
      width: 20%;
    }
    .flex-row.toggle {
      margin-right: 50px;
    }
    .flex-row input {
      width: 100%;
    }
  }
</style>
