<template>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card rounded>
      <v-toolbar dark color="user_primary" v-if="title">
        <v-toolbar-title class="text-uppercase">{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="pa-7">
        <slot name="content"></slot>
      </v-card-text>

      <v-card-actions v-if="showActions">
        <v-spacer></v-spacer>
        <v-btn rounded color="user_primary" dark @click="close"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SimpleModal',
    props: {
      maxWidth: {
        type: String,
        default: '300'
      },
      show: {
        type: Boolean,
        required: false
      },
      title: {
        type: String
      },
      message: {
        type: String,
        required: true
      },
      showActions: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      close() {
        this.$emit('close', false)
      }
    }
  }
</script>
