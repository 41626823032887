<template>
  <v-container>
    <!-- NOTE: q1 -->
    <v-row
      v-for="item in config.filter(item => item.show)"
      :key="item.num"
      class="px-0 item"
    >
      <v-card-text v-for="q in item.questions.filter(q => q.show)" :key="q.num">
        <v-card-text class="text-center title">{{ q.question }}</v-card-text>
        <v-row class="justify-center">
          <v-col cols="3" md="2" class="d-flex justify-center"
            ><v-btn
              rounded
              :dark="q.answer === q.answers.YES"
              @click="handleAnswer(item.num, q.num, q.answers.YES)"
              >{{ q.answers.YES.text }}</v-btn
            ></v-col
          >
          <v-col cols="3" md="2" class="d-flex justify-center"
            ><v-btn
              rounded
              :dark="q.answer === q.answers.NO"
              @click="handleAnswer(item.num, q.num, q.answers.NO)"
              >{{ q.answers.NO.text }}
            </v-btn></v-col
          >
        </v-row>
        <v-divider class="mt-5"></v-divider>
      </v-card-text>
    </v-row>
  </v-container>
</template>

<script>
  //  :dark="form5.btn2" @click="onF5B2"
  import { SystemQuestions, SystemQuestionAnswers } from '@/config/enums'

  export default {
    props: ['salon'],
    data() {
      return {
        SystemQuestionAnswers,
        SystemQuestions,
        questionsForBackend: [],
        config: []
      }
    },
    computed: {
      systems() {
        return this.salon.systems
      }
    },
    methods: {
      setConfig() {
        this.config = []
        this.salon.systems.forEach(system => {
          if (this.SystemQuestions[system]) {
            this.config.push({
              system,
              num: this.config.length + 1,
              show: false,
              questions: [
                {
                  num: 1,
                  question: this.SystemQuestions[system][0].question,
                  answers: this.SystemQuestions[system][0].answers,
                  show: false,
                  answer: ''
                },
                {
                  num: 2,
                  question: this.SystemQuestions[system][1].question,
                  answers: this.SystemQuestions[system][1].answers,
                  show: false,
                  answer: ''
                }
              ]
            })
          }
          if (this.config.length > 0) {
            this.config[0].show = true
            this.config[0].questions[0].show = true
          }
        })
      },
      handleAnswer(itemNum, qNum, answer) {
        // 1. set answer
        this.config
          .find(item => item.num === itemNum)
          .questions.find(q => q.num === qNum).answer = answer

        // 2. handle questions

        // *if yes hide this system q2 and remove answer
        if (answer.text === SystemQuestionAnswers.YES && qNum === 1) {
          this.config.find(
            item => item.num === itemNum
          ).questions[1].show = false
          this.config.find(item => item.num === itemNum).questions[1].answer =
            ''
        }

        // 2.1) if yes or second question -> open next system
        if (answer.text === SystemQuestionAnswers.YES || qNum === 2) {
          // return if no next system
          if (!this.config.find(item => item.num === itemNum + 1)) return
          // next system
          this.config.find(item => item.num === itemNum + 1).show = true
          // next system q1
          this.config
            .find(item => item.num === itemNum + 1)
            .questions.find(q => q.num === 1).show = true
        }

        // 2.2) if first q and no -> open question 2
        if (answer.text === SystemQuestionAnswers.NO && qNum === 1) {
          // next question
          this.config
            .find(item => item.num === itemNum)
            .questions.find(q => q.num === qNum + 1).show = true
        }
      }
    },
    created() {
      this.setConfig()
    },
    watch: {
      systems() {
        this.setConfig()
      },
      config: {
        handler(newValue, oldValue) {
          if (!newValue) return
          // 1. emit values
          // 1.a) add used systems
          this.questionsForBackend = newValue.map(item => {
            return {
              system: item.system,
              question1: item.questions[0].question,
              question2: item.questions[1].question,
              answer1:
                item.questions[0].answer?.text ||
                SystemQuestionAnswers.NOT_ASKED,
              answer2:
                item.questions[1].answer?.text ||
                SystemQuestionAnswers.NOT_ASKED,
              accepted:
                item.questions[0].answer.text === SystemQuestionAnswers.YES ||
                item.questions[1].answer?.text === SystemQuestionAnswers.NO
            }
          })

          // 1.b) add other empty systems
          for (const system in SystemQuestions) {
            if (
              !this.questionsForBackend.find(
                backItem => backItem.system === system
              )
            ) {
              this.questionsForBackend.push({
                system,
                question1: SystemQuestions[system][0].question,
                question2: SystemQuestions[system][1].question,
                answer1: SystemQuestionAnswers.NOT_ASKED,
                answer2: SystemQuestionAnswers.NOT_ASKED,
                accepted: false
              })
            }
          }

          // 1.c) emit systemQuestions
          this.$emit('change', {
            prop: 'systemQuestions',
            value: this.questionsForBackend
          })

          // 2. emit validation
          const lastItemQ1Yes =
            this.config[this.config.length - 1].questions[0].answer.text ===
            SystemQuestionAnswers.YES
          const lastItemQ2Answered =
            !!this.config[this.config.length - 1].questions[1].answer.text
          const isValid = lastItemQ1Yes || lastItemQ2Answered

          this.$emit('valid', { step: 2, isValid })
        },
        deep: true
      }
    }
  }
</script>

<style scoped>
  .item {
    border: 3px solid lightgrey;
    margin-bottom: 1px;
    border-radius: 15px;
  }
</style>
