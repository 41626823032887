<template>
  <div @click="handleAction" :class="options.hasAction && 'actionable'">
    <v-img
      contain
      :lazy-src="require(`@/assets/apple-style/${options.img}`)"
      :src="require(`@/assets/apple-style/${options.img}`)"
    />
  </div>
</template>

<script>
  export default {
    props: {
      height: {
        type: Number,
        default: 1
      },
      options: {
        type: Object,
        default: () => ({
          img: '',
          hasAction: false,
          video: '',
          popUpImg: '',
          text: '',
          popUpBtnText: ''
        })
      }
    },
    methods: {
      handleAction() {
        if (this.options.hasAction) {
          this.$emit('action', this.options)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .actionable {
    cursor: pointer;
  }
</style>
