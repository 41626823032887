<template>
  <v-container class="pt-0">
    <v-row>
      <v-card-title class="breakword d-flex justify-center"
        >Your starting price for a MicroLines application is $2000 (not
        including additional monthly maintenance)</v-card-title
      >
      <v-col class="d-flex justify-center pt-0"
        ><v-btn
          width="100%"
          rounded
          depressed
          color="user_cta"
          dark
          @click="handleSelect"
          class="text-none"
          >Continue finding a salon</v-btn
        ></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
  import { SystemOptions } from '@/config/enums'
  import { mapActions } from 'vuex'

  export default {
    data() {
      return {
        SystemOptions,
        screen: 'microlinesPrice',
        question: '',
        answer: 'go'
      }
    },
    computed: {
      nextScreen() {
        return 'consumerInfo'
      },
      chosenSystem() {
        return this.$route.query.systemInfo
      },
      vuexLog() {
        return this.$store.getters.log
      }
    },

    methods: {
      ...mapActions(['addLog', 'editLog']),
      // helper
      emitSelect(goNext = false) {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          goNext
        })
      },
      async handleSelect() {
        if (this.chosenSystem === SystemOptions.MICROLINES) {
          if (!this.vuexLog) {
            const log = {
              source: this.$route.query['source'] || 'salon_finder',
              system: this.chosenSystem,
              pricePage: true,
              priceVisible: true,
              lastQuestionPage: true
            }
            await this.addLog(log)
          } else {
            await this.editLog({
              id: this.vuexLog._id,
              priceVisible: true,
              pricePage: true
            })
          }
        }
        this.emitSelect(true)
      }
    },
    created() {
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped>
  .breakword {
    word-break: break-word;
  }
</style>
