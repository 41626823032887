<template>
  <v-container v-if="!isLoading">
    <v-snackbar
      centered
      top
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      >{{ errorMessage }}</v-snackbar
    >
    <v-card width="400" class="mx-auto mt-5">
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>Login</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="my-0 py-0 text-center">
        Don't have an account?
        <v-btn
          plain
          link
          class="text-capitalize pa-0 ma-0"
          color="#19b3cc"
          @click="handleSignUp"
          >Sign Up</v-btn
        >
      </v-card-text>

      <v-card-text class="pa-5 pt-0 d-flex flex-column">
        <v-text-field
          prepend-icon="mdi-email"
          :rules="[rules.required, rules.email]"
          label="Email"
          v-model="email"
          class="pt-0 mt-0"
        />

        <v-text-field
          :rules="[rules.required]"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          class="pt-0 mt-0"
        />
        <v-btn
          text
          small
          class="text-capitalize pr-0 align-self-end mb-0 pb-0"
          color="#19b3cc"
          @click="handleForgotPassword"
          >Forgot Password?</v-btn
        >

        <v-card-actions>
          <v-btn
            block
            color="user_primary"
            class="white--text"
            rounded
            :disabled="disableBtn"
            @click="handleLogin"
            :loading="this.$store.state.auth.loading"
          >
            Log In</v-btn
          >
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { mapActions } from 'vuex'
  import { UserRoles } from '../../../../config/enums'
  export default {
    data() {
      return {
        isLoading: true,
        UserRoles,
        email: '',
        password: '',
        rules: {
          required: value => !!value || 'Required.',
          email: value => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        },
        showPassword: false,
        errorMessage: '',
        snackbar: false,
        snackbarTimeout: 2000,
        snackbarColor: 'red'
      }
    },
    computed: {
      disableBtn() {
        return !this.email || !this.password
      }
    },
    methods: {
      ...mapActions(['login', 'fetchStylistAccount']),
      handleSignUp() {
        this.$router.push({
          name: 'stylist-signup',
          query: this.$route.query
        })
      },

      async handleLogin() {
        const userData = {
          email: this.email,
          password: this.password,
          role: UserRoles.STYLIST
        }

        await this.login(userData)

        if (
          !this.$store.state.auth.error &&
          this.$store.getters.stylistLoggedIn
        ) {
          await this.fetchStylistAccount(this.$store.state.auth.user.stylistId)
          // if error, redirect to login
          if (this.$store.state.stylistsAccount.error) {
            if (this.$route.name !== 'stylist-login') {
              this.$router.push({
                name: 'stylist-login',
                query: this.$route.query
              })
            }
          }
          const hasAdditionalInfo =
            !!this.$store.state.stylistsAccount.stylist.salon
          // if stylist has no additional info, redirect to additional info
          if (!hasAdditionalInfo) {
            if (this.$route.name !== 'stylist-additional-info') {
              this.$router.push({
                name: 'stylist-additional-info',
                params: { stylistId: this.$store.getters.user.stylistId },
                query: this.$route.query
              })
            }
          }
          // if stylist has additional info, redirect to applications
          else {
            if (this.$route.name !== 'stylist-applications') {
              this.$router.push({
                name: 'stylist-applications',
                params: {
                  stylistId: this.$store.getters.user.stylistId
                },
                query: this.$route.query
              })
            }
          }
        } else {
          this.errorMessage =
            this.$store.state.auth.error || 'Wrong credentials.'
          this.snackbar = true
        }
      },
      handleForgotPassword() {
        this.$router.push({
          name: 'stylist-forgot-password',
          query: this.$route.query
        })
      }
    },
    created() {
      let isLoggedIn = this.$store.getters.stylistLoggedIn

      // to not wait to clear user data
      if (this.$route.query.from === 'stylist-change-password') {
        isLoggedIn = false
      }

      const hasPrimarySystem = !!this.$store.getters.stylistPrimarySystem
      const hasAdditionalInfo =
        !!this.$store.state.stylistsAccount.stylist?.salon
      if (isLoggedIn) {
        // 1. logged in and no primary system -> redirect to primary system
        if (!hasPrimarySystem) {
          this.$router.push({
            name: 'stylist-primary-system',
            query: this.$route.query
          })
        }
        // 2. logged in and no additional info -> redirect to additional info
        else if (!hasAdditionalInfo) {
          this.$router.push({
            name: 'stylist-additional-info',
            params: { stylistId: this.$store.getters.user.stylistId },
            query: this.$route.query
          })
        }
        // 3. logged in and has primary system and has additional info -> redirect to applications
        else {
          this.$router.push({
            name: 'stylist-applications',
            params: {
              stylistId: this.$store.getters.user.stylistId
            },
            query: this.$route.query
          })
        }
      }
      this.isLoading = false
    }
  }
</script>
