<template>
  <v-container class="pt-0 px-0">
    <v-card-text class="px-0 breakword text-center title">{{
      getQ1MInfo(answer).question
    }}</v-card-text>
    <v-card-text class="text-center subtitle py-0">Choose multiple</v-card-text>
    <v-row class="form">
      <v-col cols="6" class="d-flex justify-end">
        <v-btn
          width="50"
          class="text-none"
          depressed
          small
          @click="handleSelect('a')"
          :dark="answer.includes('a')"
          :color="answer.includes('a') ? 'user_cta' : 'user_btn'"
          >{{ getQ1MInfo(answer).options[0].text }}</v-btn
        >
      </v-col>
      <v-col cols="6" class="d-flex justify-start">
        <v-btn
          width="50"
          class="text-none"
          depressed
          small
          @click="handleSelect('b')"
          :dark="answer.includes('b')"
          :color="answer.includes('b') ? 'user_cta' : 'user_btn'"
          >{{ getQ1MInfo(answer).options[1].text }}</v-btn
        >
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn
          width="50"
          class="text-none"
          depressed
          small
          @click="handleSelect('c')"
          :dark="answer.includes('c')"
          :color="answer.includes('c') ? 'user_cta' : 'user_btn'"
          >{{ getQ1MInfo(answer).options[2].text }}</v-btn
        >
      </v-col>
      <v-col cols="6" class="d-flex justify-start">
        <v-btn
          width="50"
          class="text-none"
          depressed
          small
          @click="handleSelect('d')"
          :dark="answer.includes('d')"
          :color="answer.includes('d') ? 'user_cta' : 'user_btn'"
          >{{ getQ1MInfo(answer).options[3].text }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { getQ1MInfo } from '@/utils/helpers'
  export default {
    data() {
      return {
        getQ1MInfo,
        screen: 'moreHair',
        nextScreen: 'hairLongWant',
        question: 'moreHair',
        answer: [],
        option: ''
      }
    },
    methods: {
      // helper
      emitSelect(goNext = false) {
        this.$emit('select', {
          screen: this.screen,
          nextScreen: this.nextScreen,
          question: this.question,
          answer: this.answer,
          goNext
        })
      },
      handleSelect(option) {
        if (this.answer.includes(option))
          this.answer = this.answer.filter(q => q !== option)
        else this.answer.push(option)

        this.emitSelect()
      }
    },
    created() {
      this.answer = this.$route.query[this.screen] || []
      this.emitSelect()
    }
  }
</script>

<style lang="scss" scoped>
  .form {
    margin: 10px 0;
  }
</style>
