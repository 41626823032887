<template>
  <v-dialog
    v-model="showDialog"
    :max-width="maxWidth"
    @click:outside="handleClose"
  >
    <v-card>
      <v-toolbar dark color="user_primary">
        <v-spacer></v-spacer>
        <v-toolbar-title>Terms and Conditions</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text class="text-subtitle-1 mt-3 text-center">
        By declining Terms and Conditions you will be automatically opted out as
        a Hairdreams referral salon
      </v-card-text>

      <v-card-actions class="pb-5 pt-0">
        <v-spacer></v-spacer>
        <v-btn rounded color="blue" class="mr-1" dark @click="handleClose"
          >Go back</v-btn
        >
        <v-btn class="ml-1" rounded @click="handleDecline">I Understand</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      salon: {
        type: Object,
        required: true
      },
      maxWidth: {
        type: String,
        default: '350'
      },
      showDialog: {
        type: Boolean,
        required: false
      }
    },
    methods: {
      handleClose() {
        this.$emit('close')
      },
      handleDecline() {
        this.$emit('decline')
        this.$router.replace({
          name: 'OptOut'
        })
        this.$emit('close')
      }
    }
  }
</script>
