<template>
  <v-dialog
    v-model="show"
    :max-width="maxWidth"
    :max-height="maxWidth"
    @click:outside="close"
  >
    <v-card height="600">
      <MglMap
        :accessToken="accessToken"
        :mapStyle.sync="mapStyle"
        :center="start"
        :minZoom="minZoom"
      >
        <MglMarker :coordinates="start" color="blue"> </MglMarker>
        <MglMarker :coordinates="end" color="green"> </MglMarker>
        <MglGeojsonLayer
          :sourceId="geoJsonSource.data.id"
          :source="geoJsonSource"
          layerId="end"
          :layer="geoJsonLayer"
          ref="pathLayer"
        />
      </MglMap>
    </v-card>
  </v-dialog>
</template>

<script>
  import Mapbox from 'mapbox-gl'
  import {
    MglMap,
    MglMarker,
    MglGeojsonLayer
    // MglAttributionControl,
    // MglNavigationControl,
    // MglGeolocateControl,
    // MglScaleControl
  } from 'vue-mapbox'
  import 'mapbox-gl/dist/mapbox-gl.css'

  export default {
    components: {
      MglMap,
      MglMarker,
      MglGeojsonLayer
    },
    props: {
      show: {
        type: Boolean,
        required: false
      },
      route: {
        type: Array
      },
      maxWidth: {
        type: String,
        default: '600'
      },
      start: {
        type: Array,
        default() {
          return [13.8022177, 51.0069449]
        }
      },
      end: {
        type: Array,
        default() {
          return [13.8022177, 51.0069449]
        }
      }
    },
    data() {
      return {
        mapbox: null,
        accessToken: process.env.VUE_APP_MAP_TOKEN,
        mapStyle: 'mapbox://styles/mapbox/streets-v11?optimize=true',
        center: [13.8022177, 51.0069449],
        minZoom: 10,
        geoJsonSource: {
          type: 'geojson',
          data: {
            id: 'testData',
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: []
            }
          }
        },
        geoJsonLayer: {
          id: 'end',
          type: 'line',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#3887be',
            'line-width': 5,
            'line-opacity': 0.75
          }
        }
      }
    },
    methods: {
      close() {
        this.$emit('close', false)
      }
    },
    created() {
      this.mapbox = Mapbox
    },
    watch: {
      route() {
        this.geoJsonSource.data.geometry.coordinates = this.route
      }
    }
  }
</script>

<style lang="scss" scoped>
  #map {
    width: 100%;
    // height: 500px;
  }
  .mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
