<template>
  <div v-if="!$store.state.messages.loading">
    <v-badge
      v-if="unreadMessages > 0"
      color="red"
      :content="unreadMessages"
      offset-x="10"
      offset-y="10"
    >
      <v-btn small class="text-capitalize" @click="() => $emit('onClick')">
        <v-icon small>mdi-arrow-left</v-icon>All messages</v-btn
      >
    </v-badge>
    <v-btn v-else small class="text-capitalize" @click="() => $emit('onClick')">
      <v-icon small>mdi-arrow-left</v-icon>All messages</v-btn
    >
  </div>
</template>

<script>
  export default {
    computed: {
      unreadMessages() {
        return this.$store.state.messages.salonMessagesUnreadCount
      }
    }
  }
</script>
